import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { EventManagementService } from '../../../ui-services/eventmanagement.service';

@Component({
  selector: 'mm-rmsfilter',
  templateUrl: './rmsfilter.component.html',
  styleUrls: ['./rmsfilter.component.css']
})
export class RmsfilterComponent implements OnInit {

  showFilter;
  analysisFilter = false;
  analysisGraphTypes = [{label:"Revenue",value:'0'},{label:"Occupancy",value:'1'},{label:"Average Room Rate",value:'2'}]
  selectedGraphType = '0';
  analysisGroupBy = [{label:"Day",value:'2'},{label:"Week",value:'1'},{label:"Day of Week",value:'3'},{label:"Month",value:'0'}];
  selectedGroupBy = '2';
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter();
  constructor(private eventManager:EventManagementService, private router : Router) { 
  }

  ngOnInit() {
  }

  setupRmsFilter(setupData){
    if(setupData){
      this.showFilter = setupData["showFilter"];
      this.analysisFilter = setupData["analytsisFilter"]?true:false;
      this.selectedGraphType = setupData["graphType"];
      this.selectedGroupBy = setupData["groupBy"];
    }
  }
  updateGraphType(event,value){
    this.selectedGraphType = value;
    this.handleOnChange(event);
  }

  updateGroupBy(event,value){
    this.selectedGroupBy = value;
    this.handleOnChange(event);
  }

  handleOnChange(event){
    let data = {
      graphType:this.selectedGraphType,
      groupBy:this.selectedGroupBy
    }
    this.onFilterChange.emit(data);
  }

}
