import {Component, ViewChild, ElementRef} from "@angular/core";

import {ICellRendererParams} from "ag-grid";
import {ICellRendererAngularComp} from "ag-grid-angular";
import { RRPCellFilter, RatecalendarService } from "../ui-services/ratecalendar.service";
import { Utilities } from "../ui-services/utilities";
import { AuthenticationService } from "../ui-services/authentication.service";
import { StorageService } from "../ui-services/storage.service";

@Component({
    selector: 'roomrateplanfields',
    templateUrl: './roomrateplanfields.component.html'
})
export class RoomRatePlanFieldsComponent implements ICellRendererAngularComp {
    public data;
    public remoteData;
    public fieldName: String;
    public isGrouped;
    public rrpFieldsMap;
    public rrpFilter: RRPCellFilter;
    public outOfSyncThreshold = 0;
    public occupancyLinkingEnable = false;
    public linkedOccupancy =[];
    isClosed = false;
    isRateMgrLock = false;
    isManual = false;
    isRateRule = false;
    pricingModel = 0;
    maxOccupancy = 0;
    baseOccupancy = 0;
    isRowPinnedTop = false;
    outOfSyncMessage = "Out Of Sync (";
    channelType;
    formatNumber = "1.0-0";
    constructor(private rateCalendarService:RatecalendarService, private authenticationService:AuthenticationService,
        private storageService:StorageService){
        this.rrpFieldsMap = Utilities.rrpFieldsMap;
        this.rrpFilter = Utilities.clone(rateCalendarService.rrpFilter);
        let hotelId = storageService.get(StorageService.userHotelContext).HotelId;
        let domainHotel = authenticationService.getDomainHotel(hotelId);
        this.outOfSyncThreshold = parseFloat(domainHotel.OutOfSyncThreshold);
    }
    agInit(params: ICellRendererParams): void {
        this.fieldName = params.colDef.field;
        this.isGrouped = params.node.group; 
        this.isRowPinnedTop = params.node.rowPinned == "top";
        if(params.data && !this.isRowPinnedTop){
            this.data = params.data.roomRatePlans[params.colDef.colId];
            this.pricingModel = params.data.pricingModel;
            this.maxOccupancy = params.data.maxOccupancy;
            this.baseOccupancy = params.data.baseOccupancy;
            this.channelType = params.data.channelType;
            if(params.data.channelType != "MAX"){
                this.outOfSyncMessage += "Extranet Value: ";                
            } else {
                if(this.data) this.isRateMgrLock = this.data.RateMgrLock; 
            }
            this.remoteData = params.data.roomRatePlansRemote[params.colDef.colId];
            if(this.data){
                this.isClosed = this.data.IsClosed;
                this.isManual = this.data.Source == 'Manual';
                this.isRateRule = this.data.Source == 'RateManager';
            }
            if(params.node && params.node.data){
                this.pricingModel = params.node.data.pricingModel;
                this.maxOccupancy = params.node.data.maxOccupancy;
                this.baseOccupancy = params.node.data.baseOccupancy;
                this.occupancyLinkingEnable = params.node.data.occupancyLinking;
                this.linkedOccupancy = params.node.data.linkedOccupancies;
            }
        } else {
            if(this.fieldName.indexOf("roomRatePlanFields") >= 0){
                this.data = "Day Availability";
            } else {
                if(params.data)
                    this.data = params.data[params.colDef.colId];
                if(this.data == null || this.data == undefined) this.data = 0;
            }
        } 

        // if(this.occupancyLinkingEnable || (this.linkedOccupancy && this.linkedOccupancy.length >0)){
        //     if(this.occupancyLinkingEnable){
        //         this.rrpFilter.perDay = false;
        //         this.rrpFilter.perExtraPerson = false;
        //         this.rrpFilter.extraChildren = false;
        //     }
        //     for(var i = 0; i<this.rrpFilter.occupancy.length; i++){
        //         this.rrpFilter.occupancy[i] = (this.linkedOccupancy.indexOf(i+1) >=0 || this.occupancyLinkingEnable)?false:this.rrpFilter.occupancy[i];
        //     }
        // }
    }

    refresh(params: any): boolean {
        console.log("Refresh method called: " +params);
        return true;
    }

    isEmptyObject(object){
        if(!object) return true;
        return JSON.stringify(object) === JSON.stringify({});
    }

    checkOutOfSync(localValue,remoteValue,index=0){
        if(!index){
            return Math.abs(localValue - remoteValue) > this.outOfSyncThreshold;
        } else {
            if(localValue == remoteValue) return false;
            if(localValue && localValue[index] && remoteValue && remoteValue[index]) 
            return Math.abs(localValue[index] - remoteValue[index]) > this.outOfSyncThreshold;
        }
    }
}