import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from '../../ui-services/authentication.service';
import { Utilities } from '../../ui-services/utilities';
import { MessageService } from 'primeng/api';
import { NotificationService } from '../../ui-services/notification.service';
import {DialogModule} from 'primeng/dialog';
@Component({
  selector: 'app-notification-card-collapsed',
  templateUrl: './notification-card-collapsed.component.html',
  styleUrls: ['./notification-card-collapsed.component.css']
})
export class NotificationCardCollapsedComponent implements OnInit {
  @Input() data;
  @Input() clicked;
  @Output() openDialog = new EventEmitter();
  displayExpandedDialog: boolean = false;
  expand: boolean = false;
  constructor(private authService:AuthenticationService,
    private messageService:MessageService,
    private notificationService:NotificationService) { }
  notificationData;
  ngOnInit() {
    this.notificationData = this.data;
  }
  getColor(n){
    if(n.severity == "high") return 'red';
    if(n.severity == "medium") return 'orange';
    if(n.severity == "low") return 'yellow';
    if(n.severity == "error") return 'blue';
    console.log(n);
  }
  getNotificationTimestamp(notificationTimestamp){
    let serverTimestamp = this.authService.getTimestamp();
    return Utilities.toDateString(new Date(notificationTimestamp),new Date(serverTimestamp));
  }
  onRejectNotification($event,id){
    this.messageService.add({key:'reject',severity:'warn',sticky:true, summary:'Reject Notification?', detail:'This will reject the notification permanently.',data:id});
    console.log(event);
  }
  onRejectConfirm(id){
    this.notificationService.isRejected(id);
    this.messageService.clear()
  }
  onApproveNotification($event,id){
    this.messageService.add({key:'approve',severity:'success',life:3000, summary:'Notification approved', detail:'This notification is approved successfully.',data:id});
    console.log(event);
    this.notificationService.isApproved(id);
  }
  onActionNotification($event,notificationData){
    this.displayExpandedDialog=true;
    this.openDialog.emit(notificationData);
  }
  isTask(n){
    if(n.type == "Channel Follow-up Alert") return true;
    else return false;
  }
}
