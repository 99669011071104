import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LZStringService } from 'ng-lz-string';

declare var LZString: any; 

@Injectable()
export class StorageService {

  public static currentUser = "CURRENT_USER";
  public static userHotelContext = "USER_HOTEL_CONTEXT";
  public static userDomainContext = "USER_DOMAIN_CONTEXT";
  public static userContext = "USER_CONTEXT";
  public static userTheme = "USER_THEME";
  public static serverTimestamp = "SERVER_TIMESTAMP";
  //THIS IS TO STORE LOGGED IN USER CONTEXT
  public static loggedInUser = "LOGGED_IN_USER";
  //THIS IS TO STORE LOGGED IN SWITCHED USER CONTEXT
  public static switchedUser = "SWITCHED_USER";
  //LOGIN USER ID
  public static loginUserId = "LOGIN_USER_ID";

  public static lastLoginTime = "LAST_LOGIN_TIME";
  public static tasks = "TASKS";
  public static expire = 30; //60 mins is the actual expire

  private userContextSource = new Subject<any>();
  private roomtypeContextSource = new Subject<any>();
  public static postLoginMessageDisplayed = "POST_LOGIN_MSG_DISPLAYED";
  public reloadHotelContextRequired = false;
  // Observable string streams
  userContextListener$ = this.userContextSource.asObservable();
  roomtypeContextListener$ = this.roomtypeContextSource.asObservable();

  constructor(private lz: LZStringService) { }

  //Local Storage to store User Session
  //Store Hotel Context
  get(storageKey, persist: boolean = false): any {
    try{
      if (persist) {
        if (localStorage.getItem(storageKey))
          return JSON.parse(LZString.decompressFromUTF16(localStorage.getItem(storageKey)));
      }
      else {
        if (sessionStorage.getItem(storageKey))
          return JSON.parse(LZString.decompressFromUTF16(sessionStorage.getItem(storageKey)));
      }
    }catch(e){
      return null;
    }

  }

  set(storageKey, data: any, persist: boolean = false) {
    if (persist)
      localStorage.setItem(storageKey, LZString.compressToUTF16(JSON.stringify(data)));
    else
      sessionStorage.setItem(storageKey, LZString.compressToUTF16(JSON.stringify(data)));
  }

  remove(storageKey, persist: boolean = false) {
    if (persist)
      localStorage.removeItem(storageKey);
    else
      sessionStorage.removeItem(storageKey);
  }

  userContextChanged(sessionContext) {
    this.userContextSource.next(sessionContext);
  }
  roomtypeContextChanged(tabName) {
    this.roomtypeContextSource.next(tabName);
  }

  persistData() {
    //Read from SessionStorage and Store in Local Storage.
    this.set(StorageService.userContext, this.get(StorageService.userContext), true);
    this.set(StorageService.currentUser, this.get(StorageService.currentUser), true);
    this.set(StorageService.loggedInUser, this.get(StorageService.loggedInUser), true);
    this.set(StorageService.loginUserId, this.get(StorageService.loginUserId), true);
    this.set(StorageService.userHotelContext, this.get(StorageService.userHotelContext), true);
    this.set(StorageService.userDomainContext, this.get(StorageService.userDomainContext), true);
    this.set(StorageService.lastLoginTime, this.get(StorageService.lastLoginTime), true);
    this.set(StorageService.userTheme, this.get(StorageService.userTheme), true);
  }

  restoreData() {
    //Read from Local Storage and Store in SessionStorage.
    this.set(StorageService.userContext, this.get(StorageService.userContext, true));
    this.set(StorageService.currentUser, this.get(StorageService.currentUser, true));
    this.set(StorageService.loggedInUser, this.get(StorageService.loggedInUser, true));
    this.set(StorageService.loginUserId, this.get(StorageService.loginUserId, true));
    this.set(StorageService.userHotelContext, this.get(StorageService.userHotelContext, true));
    this.set(StorageService.userDomainContext, this.get(StorageService.userDomainContext, true));
    this.set(StorageService.lastLoginTime, this.get(StorageService.lastLoginTime, true));
    this.set(StorageService.userTheme, this.get(StorageService.userTheme, true));
  }
}