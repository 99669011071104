import { Component, OnInit, ComponentFactoryResolver, ViewContainerRef, ViewChild, HostListener } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { MenuItem } from 'primeng/primeng';
import { HotelManagementService } from '../ui-services/hotelmanagement.service';
import { StorageService } from '../ui-services/storage.service';
import { Router } from '@angular/router';
import { Channel } from 'mantras-api';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';
import * as jQuery from 'jquery';
import { Utilities } from '../ui-services/utilities';
import { ChannelCatalogDetailsComponent } from '../channelcatalog/channelcatalogdetails.component';
import { Subscription } from 'rxjs';
import { UserService } from '../ui-services/user.service';

@Component({
  selector: 'app-channelcatalog',
  templateUrl: './channelcatalog.component.html',
  styleUrls: ['./channelcatalog.component.css']
})
export class ChannelCatalogComponent implements OnInit {
  subscription: Subscription;
  columnDefs: any[];
  rowData: any[];
  gridOptions: GridOptions;
  rowSelection: any;
  icons;
  channelCatalogMenuItems: MenuItem[];
  rowGroupPanelShow: any;
  domainChannels;
  showDetailPage = false;

  constructor(private hotelMgmtService: HotelManagementService, private storageService: StorageService, private router: Router
    , private resolver: ComponentFactoryResolver,private userService: UserService) {
    this.columnDefs = [
      { headerName: "Channel ID", field: "Id", tooltipField: "Channel Id", width: 165, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Channel Name", field: "Name", tooltipField: "Channel Name", width: 165, menuTabs: [], cellClass: 'gridcol', sort: "asc" },
      { headerName: "Assembly", field: "Config.Assembly", tooltipField: "Assembly", width: 270, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Provider", field: "Config.Provider", tooltipField: "Provider", width: 320, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Url", field: "Config.Url", tooltipField: "Url", width: 320, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Is Active", field: "IsActive", tooltipField: "Status", width: 70, menuTabs: [], cellClass: 'gridcol', cellRenderer: params => { return `<input type='checkbox' readonly onclick='return false;' ${params.value ? 'checked' : ''} value=${params.value ? 1 : 0}/>`; } }
    ];

    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      showToolPanel: false,
      rowDeselection: true,
      localeText: { noRowsToShow: "No channels(s) found" },
      sortingOrder: ["desc", "asc"]
    };
    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });

  }

  ngOnInit() {
    this.loadMenu();
    this.findDomainChannels();
  }

  loadMenu() {
    this.channelCatalogMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', command: (event) => this.createNew() },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
      { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o',visible:this.userService.checkAccess("Admins"), command: (event) => this.confirmDelete(event, true) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
    ];
  }

  onRowClick(params) {
    true
    if (params.data) {
      let disabledDelete = false;
      if (params.data.Id && params.data.Id.toLowerCase() == this.storageService.get(StorageService.loginUserId)) disabledDelete = true;
      this.channelCatalogMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
        { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', command: (event) => this.createNew() },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
        { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o',visible:this.userService.checkAccess("Admins"), command: (event) => this.confirmDelete(event , true) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
      ];
    }
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true));
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  onClickGridRow(params) {
    if (params.data)
      this.createNew(params.data);
  }

  //Create New RoomTypes
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  detailComponent;
  isNew = false;
  createNew(data?) {
    this.container.clear();
    let factory = this.resolver.resolveComponentFactory(ChannelCatalogDetailsComponent);
    this.detailComponent = <ChannelCatalogDetailsComponent>this.container.createComponent(factory).instance;
    if (data) {
      this.detailComponent.channel = data;
      this.isNew = false;
    } else {
      this.detailComponent.channel = new Channel();
      this.detailComponent.channel.Config = [];
      this.isNew = true;
    }
    this.detailComponent.loadData(data);
    this.detailComponent.onClose.subscribe(this.onDetailsClose);
    this.showDetailPage = true;
    this.detailComponent.setHeight();
  }

  async findDomainChannels(bforced = false) {
    let response = await this.hotelMgmtService.getDomainChannels(bforced).then(
      async response => {
        this.domainChannels = response;
        this.gridOptions.api.setRowData(response);
        this.gridOptions.api.sizeColumnsToFit();
        this.onGridReady(this);
      });
  }

  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    //this.rowGroupPanelShow = "always";
    let setSelected = false;
    this.gridOptions.api.forEachNode(node => {
      if (!node.group && !setSelected) {
        node.setSelected(true);
        this.onRowClick(node);
        setSelected = true;
      }
    });
    if (!setSelected) this.loadMenu();
    //this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }
  onGridSizeChanged($event) {
    this.gridOptions.api.sizeColumnsToFit();
    //this.rowGroupPanelShow = "always";
  }

  onRefresh($event) {
    this.findDomainChannels(true);
  }

  //open Channel
  open($event) {
    this.createNew(this.gridOptions.api.getSelectedRows()[0]);
  }

  //Delete Channel
  @ViewChild('showConfirmationDialog') showConfirmationDialog;
  showDeleteConfirmation = false;
  deleteId;
  confirmDelete(params, fromGrid?) {
    if (!fromGrid) {
      if (params.node.data) {
        this.deleteId = params.node.data.Id;
        this.showDeleteConfirmation = true;
      }
    } else {
      let selectedRow = this.gridOptions.api.getSelectedRows();
      if (selectedRow && selectedRow.length > 0) {
        this.deleteId = selectedRow[0].Id;
        this.showDeleteConfirmation = true;
      }
    }
  }
  closeDialog(event) {
    this.deleteId = null;
    this.showDeleteConfirmation = false;
    this.setHeight();
  }

  async delete(event){
    this.showDeleteConfirmation = false;
    if (this.deleteId != null) {
      let finalResponse = await this.hotelMgmtService.deleteDomainChannel(this.deleteId).then(
        localresp => { 
          this.storageService.remove("SYSTEMCHANNELS");
          return localresp; });
    }
    this.onRefresh(event);
  }


  onClose() {
    this.router.navigate(["/settings"]);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onDetailsClose = async (event) => {
    this.showDetailPage = false;
    if (event) {
      await this.findDomainChannels(event);
      if (this.isNew) {
        let ch = this.domainChannels.find(d => d.ChannelCode == event.ChannelCode && d.Name == event.Name
          && d.Credentials.UserId == event.Credentials.UserId);
        if (ch) {
          if (ch.IsActive) this.hotelMgmtService.getChannelHotelInfo(ch, null);
        }
      }
    }
    this.setHeight();
  }

  filteredResponse;
  searchText(event) {
    this.filteredResponse = [];
    var query = event.target.value.toLowerCase();
    let searchItems = this.domainChannels;
    if (query == "") this.filteredResponse = searchItems;
    else {
      searchItems.forEach(searchItem => {
        let isMatch = false;
        let searchString = JSON.stringify(searchItem);
        isMatch = searchString.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        if (isMatch) this.filteredResponse.push(searchItem);
      });
    }
    this.gridOptions.api.setRowData(this.filteredResponse);
    this.gridOptions.api.sizeColumnsToFit();
    this.onGridReady(this);
    this.setHeight();
  }
}
