import {Component, ViewChild, ElementRef} from "@angular/core";

import {ICellRendererParams} from "ag-grid";
import {ICellRendererAngularComp} from "ag-grid-angular";
import { StorageService } from "../ui-services/storage.service";
import { Utilities } from "../ui-services/utilities";
import { RatecalendarService, GridCellData } from "../ui-services/ratecalendar.service";

@Component({
    selector: 'groupinglabel',
    template: `
    <div *ngIf="isRowPinnedTop" title="{{label}}" [class]="pinnedRowStyle" style="padding-left:2px;padding-top:2px;">{{label}}</div>
    <span *ngIf="!isRowPinnedTop" title="{{label}}" style="padding-left:2px;">{{label}}</span>
    `
})
export class GroupingLabelComponent implements ICellRendererAngularComp {
    public label;
    public fieldName: String;
    public isGrouped = false;
    public isRowPinnedTop = false;
    public pinnedRowStyle = "";
    constructor(private storageService:StorageService){
    }

    agInit(params: ICellRendererParams): void {
        if(params.colDef && params.colDef.field) 
            this.fieldName = params.colDef.field;
        else
            this.fieldName = params.node.field;
        this.isRowPinnedTop = params.node.rowPinned == "top";
        if(params.value && !this.isRowPinnedTop){
            this.label = params.value;
            if(this.fieldName == 'channelType'){
                this.label = this.getChannelType(params.value);
            } else if(this.fieldName == 'ratePlanId' || this.fieldName == 'masterRatePlanId'){
                let gcd:GridCellData;
                if(params.node.allChildrenCount > 0) gcd = params.node.allLeafChildren[0].data;
                this.label = this.getRatePlanLabel(gcd.masterRatePlanId);
                if(!gcd && params.node.data) gcd = params.node.data; 
                if(gcd && gcd.ratePlanName && gcd.ratePlanName != "" && this.fieldName != 'masterRatePlanId')
                    this.label += "{"+gcd.ratePlanName+"}"
            } else if(this.fieldName == 'roomTypeId' || this.fieldName == 'masterRoomTypeId'){
                let gcd:GridCellData;
                if(params.node.allChildrenCount > 0) gcd = params.node.allLeafChildren[0].data;
                this.label = this.getRoomTypeLabel(gcd.masterRoomTypeId);
                if(!gcd && params.node.data) gcd = params.node.data; 
                if(gcd.roomName && gcd.roomName != "" && this.fieldName != 'masterRoomTypeId')
                    this.label += "{"+gcd.roomName+"}";
            } else {
                let fieldlabel = Utilities.rrpFieldsMap[this.label];
                if(fieldlabel)
                    this.label = fieldlabel;
            }
        } else {
            this.pinnedRowStyle = "pinnedRowLabel"
            this.label = "Day Availability";
        }
    }

    public getChannelType(fieldId){
        return Utilities.findId(Utilities.channelTypes,fieldId,fieldId);
    }

    public getRatePlanLabel(fieldId){
        let hotelContext = this.storageService.get(StorageService.userHotelContext);
        return Utilities.findId(hotelContext.RatePlans, fieldId);
    }

    public getRoomTypeLabel(fieldId){
        let hotelContext = this.storageService.get(StorageService.userHotelContext);
        return Utilities.findId(hotelContext.RoomTypes, fieldId);
    }

    refresh(params: any): boolean {
        console.log("Refresh method called: " +params);
        return true;
    }

}