import { Component, OnInit, AfterViewInit, HostListener, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/primeng';
import { EventManagementService } from '../ui-services/eventmanagement.service';
import * as jQuery from 'jquery';
import { Utilities } from '../ui-services/utilities';
import { LoadingService } from '../ui-services/loading.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit , AfterViewInit {
  constructor(private router:Router,private route: ActivatedRoute, private eventManager:EventManagementService,
    private loadingService:LoadingService) { 
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  async routePage(navigationLink){
    this.router.navigate([navigationLink], {relativeTo: this.route });
  }
  
  @HostListener('window:resize') setHeight() {
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(false,true));
  }
}