import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren, ViewContainerRef, 
  HostListener, AfterViewInit } from '@angular/core';
import { RatePlan, HotelContext, RoomType } from '../model/model';
import { Promotion, BlackoutPeriod, DomainContext, PromotionType, DaysOfWeek, PromotionStatus } from 'mantras-api';
import { discountTypesExpedia, PromotionService, promoType } from '../ui-services/promotion.service';
import { StorageService } from '../ui-services/storage.service';
import { Utilities } from '../ui-services/utilities';
import { DateRange } from 'mantras-api';
import { ValueType } from 'mantras-api';
import { Calendar, MenuItem, SelectItem } from 'primeng/primeng';
import { DatePipe } from '@angular/common';
import { UserService } from '../ui-services/user.service';

import * as jQuery from 'jquery';

@Component({
  selector: 'mm-promotiondetails',
  templateUrl: './promotiondetails.component.html',
  styleUrls: ['./promotiondetails.component.css']
})
export class PromotionDetailsComponent implements OnInit, AfterViewInit {
  @Input() promotion: Promotion;
  @Input() hotelContext?: HotelContext;
  @Input() domainContext?: DomainContext;
  @Output() onClose = new EventEmitter<boolean>();
  promotionChannelName: string;

  promotionMenuItems: MenuItem[] = [
    { title: 'Save', label: 'Save', disabled: true, icon: 'action-bar-menu-icon fa fa-floppy-o', command: (event) => this.showSaveAndPublishDialog(event) },
    { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }
  ];

  //#region Reference Items

  promotionTypes: SelectItem[] = [
    { label: "Basic Promotion", value: PromotionType.Simple }, 
    { label: "Min Stay Promotion", value: PromotionType.MinStay },
    { label: "Early Bird Promotion", value: PromotionType.EarlyBird }, 
    { label: "Last Minute Promotion", value: PromotionType.LastMinute }
  ];

  discountTypes: SelectItem[] = [
    { label: "Simple Discount", value: 'Expedia.SingleDiscount' }, 
    { label: 'Day-Of-Week Discount', value: "Expedia.DayOfWeekDiscount" },
    { label: "Multi-Night Discount", value: 'Expedia.MultiNightDiscount' }
  ];

  daysOfWeek : SelectItem[] = [ 
    { label: "Sunday", value: DaysOfWeek.Sun }, 
    { label: "Monday", value: DaysOfWeek.Mon }, 
    { label: "Tuesday", value: DaysOfWeek.Tue }, 
    { label: "Wednesday", value: DaysOfWeek.Wed }, 
    { label: "Thursday", value: DaysOfWeek.Thu }, 
    { label: "Friday", value: DaysOfWeek.Fri }, 
    { label: "Saturday", value: DaysOfWeek.Sat }
  ];

  //#endregion Reference Items

  isReadOnly = false;
  isActive: boolean;
  hotelRoomTypes: SelectItem[];
  promoRoomTypes: SelectItem[];
  hotelRatePlans: SelectItem[];
  promoRatePlans: SelectItem[];

  isTypeDisabled = false;

  bookingDateRange: Date[];
  stayDateRange: Date[];
  selectedBookingDaysOfWeek: SelectItem[];
  selectedStayDaysOfWeek: SelectItem[];
  
  channels = [];
  selectedTargets = [];
  selectedDays = [];
  selectedDiscount = 'Expedia.SingleDiscount';
  tagsValues;
  expDisc;
  multiNightDisc;
  memberOnlyAdditionalValue;
  applicableNight; isRecurring = false;
  mon; tues; wednes; thurs; fri; sat; sun;
  hotelWebOnly = false;
  membersDisc = false;
  @ViewChild('promotionexpdetails') promotionexpdetails;

  @ViewChild('clone') rateTemplate;
  @ViewChild('container', { read: ViewContainerRef }) tableContainer;
  @ViewChildren('blackoutDates') blackoutDates;
  @ViewChild('bookStartDate') bookStartDate;
  @ViewChild('bookEndDate') bookEndDate;
  @ViewChild('styStartDate') styStartDate;
  @ViewChild('styEndDate') styEndDate;
  @ViewChild('bookDate') bookDate;
  @ViewChild('stayDate') stayDate;
  componentReference = new Array();

  constructor(private promotionService: PromotionService,
      private storageService: StorageService, private userService: UserService) 
  {
    this.isReadOnly = userService.isReadOnly();
    this.discountTypes = [{ label: "Simple Discount", value: 'Expedia.SingleDiscount' }, { label: 'Day-Of-Week Discount', value: "Expedia.DayOfWeekDiscount" },
    { label: "Multi-Night Discount", value: 'Expedia.MultiNightDiscount' }];

  }

  ngOnInit() {
    let channelCode = this.promotion.ChannelCode;
    let obj = Utilities.channelTypes.find(o => o.Id === channelCode);
    this.promotionChannelName = (obj) ? obj.Name : channelCode;

    this.isActive = this.promotion.Status == PromotionStatus.Active; 

    this.hotelRatePlans = [];
    this.hotelContext.RatePlans.forEach(element => {
      this.hotelRatePlans.push({ label: element.Name, value: element.Id });
    });
    this.hotelRoomTypes = [];
    this.hotelContext.RoomTypes.forEach(element => {
      this.hotelRoomTypes.push({ label: element.Name, value: element.Id });
    });

    this.promoRatePlans = [];
    if (this.promotion.RatePlans && this.promotion.RatePlans.length > 0) {
      this.promotion.RatePlans.forEach(id => {
        let obj = this.hotelContext.RatePlans.find(o => (channelCode === 'MAX' && o.Id === id) || o.ChannelRatePlans.find(c => c.ChannelCode === channelCode && c.Id === id) != null);
        if (obj) {
          this.promoRatePlans.push({ label: obj.Name, value: obj.Id });
        }
      });
    }
    this.promoRoomTypes = [];
    if (this.promotion.RoomTypes && this.promotion.RoomTypes.length > 0) {
      this.promotion.RoomTypes.forEach(id => {
        let obj = this.hotelContext.RoomTypes.find(o => (channelCode === 'MAX' && o.Id === id) || o.ChannelRooms.find(c => c.ChannelCode === channelCode && c.Id === id) != null);
        if (obj) {
          this.promoRoomTypes.push({ label: obj.Name, value: obj.Id });
        }
      });
    }

    this.stayDateRange = [ Utilities.getUTCDate(new Date(this.promotion.StayDates.Start)),
      Utilities.getUTCDate(new Date(this.promotion.StayDates.End))
    ];
    this.selectedStayDaysOfWeek = [];
    this.daysOfWeek.forEach(x => {
      if (x.value & this.promotion.StayDates.DaysOfWeek) this.selectedStayDaysOfWeek.push({label: x.label, value: x.value });
    });

    this.bookingDateRange = [ Utilities.getUTCDate(new Date(this.promotion.BookingDates.Start)),
      Utilities.getUTCDate(new Date(this.promotion.BookingDates.End)),
    ];

    this.setHeight();
    // Utilities.channelTypes.forEach(ch => {
    //   this.channels.push({ label: ch.Name, value: ch.Id });
    // });
    // this.channels.sort(Utilities.sort_label_asc);
  }

  ngAfterViewInit(): void {
    if (this.promotion.BlackoutDates && this.promotion.BlackoutDates.length > 0) {
      this.promotion.BlackoutDates.forEach(blackOutPeriod => {
        this.addMoreBlackout();
        if (this.componentReference.length > 0) {
          let dateFormat = new DatePipe('en-US');
          this.componentReference[this.componentReference.length - 1]._view.nodes.forEach(node => {
            if (node && node.renderElement && node.renderElement.nodeName == "P-CALENDAR") {
              node.componentView.component.value = [];
              node.componentView.component.value[0] = Utilities.getUTCDate(new Date(blackOutPeriod.Start));
              node.componentView.component.value[1] = Utilities.getUTCDate(new Date(blackOutPeriod.End));
              node.componentView.component.inputFieldValue = dateFormat.transform(Utilities.getUTCDate(new Date(blackOutPeriod.Start)), "dd-MMM-yyyy") + " - " +
                dateFormat.transform(Utilities.getUTCDate(new Date(blackOutPeriod.End)), "dd-MMM-yyyy");
              //   node.componentView.component.inputFieldValue = dateFormat.transform(Utilities.getUTCDate(new Date(blackOutPeriod.End)), "dd-MMM-yyyy");
            }
          });
        }
      });
    }
    let currDate = new Date();
    // this.bookDate.minDate = (Utilities.dateDifference(this.bookingStartDate, currDate) > 0) ? this.bookingStartDate : currDate;
    // this.stayDate.minDate = (Utilities.dateDifference(this.stayStartDate, currDate) > 0) ? this.stayStartDate : currDate;
    this.setHeight();
  }

  loadData(selectedChannelType, isNew) {
    /*
    this.hotelContext = this.storageService.get(StorageService.userHotelContext);
    this.domainContext = this.storageService.get(StorageService.userDomainContext);
    if (this.promotion.Status)
      this.promotionStatus = (this.promotion.Status == 0);
    if (this.promotion.BookingDates) {
      this.bookingStartDate = Utilities.getUTCDate(new Date(this.promotion.BookingDates.Start));
      this.bookingEndDate = Utilities.getUTCDate(new Date(this.promotion.BookingDates.End));
      this.bookingDates = [];
      this.bookingDates[0] = this.bookingStartDate;
      this.bookingDates[1] = this.bookingEndDate;
      this.selectedBookingDaysOfWeek = Utilities.convertDecimalToDaysOfWeek(this.promotion.BookingDates["DaysOfWeek"]);
    }
    if (this.promotion.StayDates) {
      this.stayStartDate = Utilities.getUTCDate(new Date(this.promotion.StayDates.Start));
      this.stayEndDate = Utilities.getUTCDate(new Date(this.promotion.StayDates.End));
      this.stayDates = [];
      this.stayDates[0] = this.stayStartDate;
      this.stayDates[1] = this.stayEndDate;
      this.selectedStayDaysOfWeek = Utilities.convertDecimalToDaysOfWeek(this.promotion.StayDates["DaysOfWeek"]);
    }
    if (!this.promotion.ChannelCode) {
      if (selectedChannelType == "EXP") {
        this.promotion.ChannelCode = "EXP";
      }
      else {
        this.promotion.ChannelCode = "MAX";
      }
    }
    this.promotionChannelName = Utilities.findId(Utilities.channelTypes, this.promotion.ChannelCode, this.promotion.ChannelCode);

    if (!this.promotion.Type) {
      this.promotion.Type = 0;
    }

    if (this.promotion.ChannelCode != "MAX" && this.promotion.ChannelCode != "EXP") {
      this.isReadOnly = true;
      let readOnly = this.userService.isReadOnly();
      this.promotionMenuItems = [
        { title: 'Save', label: 'Save', disabled: readOnly, icon: 'action-bar-menu-icon fa fa-floppy-o', command: (event) => this.showSaveAndPublishDialog(event) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }];
      if (this.promotion.RatePlans && this.promotion.RatePlans.length > 0) {
        this.promoRatePlans = [];
        this.promotion.RatePlans.forEach(ratePlanId => {
          let ratePlanObj = Utilities.findMasterRateType(ratePlanId, this.promotion.ChannelCode, this.hotelContext.RatePlans);
          if (ratePlanObj) {
            this.promoRatePlans.push(ratePlanObj.Id);
            this.selectedRatePlansName += ratePlanObj.Name + ", ";
          }
        });
      }
      if (this.promotion.RoomTypes && this.promotion.RoomTypes.length > 0) {
        this.promoRoomTypes = [];
        this.promotion.RoomTypes.forEach(roomTypeId => {
          let roomTypeObj = Utilities.findMasterRoomType(roomTypeId, this.promotion.ChannelCode, this.hotelContext.RoomTypes);
          if (roomTypeObj) {
            this.promoRoomTypes.push(roomTypeObj.Id);
            this.selectedRoomTypesName += roomTypeObj.Name + ", ";
          }
        });
      }
      this.selectedRoomTypesName = this.selectedRoomTypesName.substring(0, this.selectedRoomTypesName.length - 2);
      this.selectedRatePlansName = this.selectedRatePlansName.substring(0, this.selectedRatePlansName.length - 2);
    } else {
      let readOnly = this.userService.isReadOnly();
      this.promotionMenuItems = [
        { title: 'Save & Publish', label: 'Save & Publish', disabled: readOnly, icon: 'action-bar-menu-icon fa fa-floppy-o', command: (event) => this.showSaveAndPublishDialog(event) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }];
      // if (this.promotion.RatePlans) this.promoRatePlans = this.promotion.RatePlans;
      // if (this.promotion.RoomTypes) this.promoRoomTypes = this.promotion.RoomTypes;
      if (this.promotion.Id) {
        this.isTypeDisabled = true;
      } else {
        this.promotion.IsRefundable = true;
      }
    }
    this.selectedPromoFor = selectedChannelType;
    if (this.selectedPromoFor == 'EXP' && !isNew) {
      if (this.promotion.Tags['Expedia.SingleDiscount']) {
        this.selectedDiscount = 'Expedia.SingleDiscount';
        this.tagsValues = JSON.parse(this.promotion.Tags['Expedia.SingleDiscount']);
        this.expDisc = this.tagsValues.value;
        this.memberOnlyAdditionalValue = this.tagsValues.memberOnlyAdditionalValue;
      }
      if (this.promotion.Tags['Expedia.DayOfWeekDiscount']) {
        this.selectedDiscount = 'Expedia.DayOfWeekDiscount';
        this.tagsValues = JSON.parse(this.promotion.Tags['Expedia.DayOfWeekDiscount']);
        this.mon = this.tagsValues.monday;
        this.tues = this.tagsValues.tuesday;
        this.wednes = this.tagsValues.wednesday;
        this.thurs = this.tagsValues.thursday;
        this.fri = this.tagsValues.friday;
        this.sat = this.tagsValues.saturday;
        this.sun = this.tagsValues.sunday;
      }
      if (this.promotion.Tags['Expedia.MultiNightDiscount']) {
        this.selectedDiscount = 'Expedia.MultiNightDiscount';
        this.tagsValues = JSON.parse(this.promotion.Tags['Expedia.MultiNightDiscount']);
        this.multiNightDisc = this.tagsValues.value;
        this.applicableNight = this.tagsValues.applicableNight;
        this.memberOnlyAdditionalValue = this.tagsValues.memberOnlyAdditionalValue;
        this.isRecurring = this.tagsValues.isRecurring;
      }
      this.selectedTargets = [];
      if (this.promotion.PromoCode && this.promotion.PromoCode != null)
        this.selectedTargets.push('hotelWebSiteOnly');
      if (this.tagsValues.memberOnlyAdditionalValue)
        this.selectedTargets.push('members');
      this.selectedDays = [];
      if (this.promotion.MinStay && this.promotion.MaxStay)
        this.selectedDays.push('lengthOfStay');
      if (this.promotion.MinTimeBforeArrival && this.promotion.MaxTimeBforeArrival)
        this.selectedDays.push('advPurchase');
    }
    */
    this.setHeight();
  }

  // public static findMasterRoomType(collection: RoomType[], channelCode: string, channelRoomTypeId: string) {
  //   return collection.find(o => o.ChannelRooms.find(c => c.ChannelCode === channelType && c.Id === channelRoomTypeId) != null);
  // }
  // public static findMasterRateType(channelRoomTypeId, channelType, collection) {
  //   return collection.find(o => o.ChannelRatePlans.find(c => c.ChannelCode === channelType && c.Id === channelRoomTypeId) != null);
  // }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true));
  }

  onBookingDatesChange(selectedDate: Date[]) {
    this.promotion.BookingDates.Start = selectedDate[0];
    this.promotion.BookingDates.End = (selectedDate[1]) ? selectedDate[1] : selectedDate[0];
    // this.bookEndDate.minDate = selectedDate.value;
    // if(Utilities.dateDifference(this.bookingEndDate,selectedDate.value)>0) this.bookingEndDate = selectedDate.value;
    // this.bookEndDate.ngOnInit();
    // this.stayDate.minDate = selectedDate[0];
    // if (this.stayDates && this.stayDates[0] && Utilities.dateDifference(this.stayDates[0], selectedDate[0]) > 0) this.stayDates[0] = selectedDate[0];
    // if (this.stayDates && this.stayDates[1] && Utilities.dateDifference(this.stayDates[1], selectedDate[0]) > 0) this.stayDates[1] = selectedDate[0];
  }
  onCloseBookingDates(selectedDate: Date[]) {
    this.promotion.BookingDates.Start = selectedDate[0];
    this.promotion.BookingDates.End = (selectedDate[1]) ? selectedDate[1] : selectedDate[0];
  }
  onStayStartDateChange(selectedDate: Date[]) {
    this.promotion.StayDates.Start = selectedDate[0];
    this.promotion.StayDates.End = (selectedDate[1]) ? selectedDate[1] : selectedDate[0];
  }
  onCloseStayDates(selectedDate: Date[]) {
    this.promotion.StayDates.Start = selectedDate[0];
    this.promotion.StayDates.End = (selectedDate[1]) ? selectedDate[1] : selectedDate[0];
  }
  onCancel(event: boolean) {
    this.onClose.emit(event);
  }
  //#region Blackouts Handling
  addMoreBlackout() {
    let compRef = this.tableContainer.createEmbeddedView(this.rateTemplate);
    this.componentReference.push(compRef);
    return false; // This is to stop navigation.
  }

  removeBlackOut(event) {
    let el = (event.target || event.srcElement);
    let clickedComp = el.parentElement.parentElement.parentElement.parentElement;
    if (clickedComp && this.componentReference.length > 0) {
      for (let i = 0; i < this.componentReference.length; i++) {
        if (this.componentReference[i].rootNodes.indexOf(clickedComp) >= 0) {
          let compRef = this.componentReference[i];
          this.componentReference.splice(i, 1);
          compRef.destroy();
        }
      }
    }
    return false;
  }
  //#endregion Blackouts Handling

  //#region Sync Dialog
  syncModeOption = "publish";
  selectedSyncChannels = [];
  syncChannelTypes = [];
  syncErrorMsg;
  promotionErrorMsg = "";
  showSyncPromotionDialog = false;
  showSaveAndPublishDialog(event) {
    let isValid = this.validateDetails();
    if (isValid) {
      let channelTypes = this.hotelContext.ChannelCodes;
      this.syncModeOption = "publish";
      this.syncErrorMsg = "";
      this.syncChannelTypes = [];
      channelTypes.forEach(element => {
        if (PromotionService.supportedChannelTypes.indexOf(element) >= 0)
          this.syncChannelTypes.push({ value: element, label: Utilities.findId(Utilities.channelTypes, element, element) });
      });
      this.syncChannelTypes.sort(Utilities.sort_label_asc);
      this.selectedSyncChannels = [];
      this.showSyncPromotionDialog = true;
    }
  }

  async onSync(event) {
    /*
    if (this.selectedPromoFor != "EXP") {
      let response = await this.promotionService.saveAndPublishPromotion(this.hotelContext.HotelId, this.promotion, this.selectedSyncChannels).then(
        async res => {
          return res;
        });
    }
    if (this.selectedPromoFor == "EXP") {
      let tgtSource = 0;
      let response = await this.promotionService.SavePromotions2(this.hotelContext.HotelId, this.promotion, tgtSource).then(
        async res => {
          return res;
        });
    }
    */
    this.showSyncPromotionDialog = false;
    this.onCancel(false);
  }
  validateDetails(): boolean {
    let isValid = false;
    this.promotionErrorMsg = "";
    if (!this.promotion.Description || this.promotion.Description.trim() == "") {
      this.promotionErrorMsg = "Please enter description";
      return false;
    }
    if (Utilities.dateDifference(this.promotion.BookingDates.Start, this.promotion.BookingDates.End) < 0) {
      this.promotionErrorMsg = "Booking start date must be greater than end date. ";
      return false;
    }
    if (Utilities.dateDifference(this.promotion.StayDates.Start, this.promotion.StayDates.End) < 0) {
      this.promotionErrorMsg = "Stay start date must be greater than end date. ";
      return false;
    }
    if (this.promotion.BookingDates.DaysOfWeek == 0) {
      this.promotionErrorMsg = "Please select atleast one day of week for booking date range";
      return false;
    }
    if (this.promotion.StayDates.DaysOfWeek == 0) {
      this.promotionErrorMsg = "Please select atleast one day of week for stay date range";
      return false;
    }
    if (this.promotion.ChannelCode != 'EXP') {
      if (this.promoRatePlans.length == 0) {
        this.promotionErrorMsg = "Please select atleast one rate plan";
        return isValid;
      }
    }
    if (this.promotion.ChannelCode != 'EXP') {
      if (this.promoRoomTypes.length == 0) {
        this.promotionErrorMsg = "Please select atleast one room type";
        return isValid;
      }
    }
    if (this.promotion.ChannelCode != 'EXP') {
      if (!this.promotion.Discount || this.promotion.Discount == 0 || this.promotion.Discount > 75) {
        this.promotionErrorMsg = "Please specify discount less than 75%.";
        return isValid;
      }
    }
      if (this.promotion.Type == PromotionType.EarlyBird && this.promotion.MinTimeBforeArrival > this.promotion.MaxTimeBforeArrival) {
        this.promotionErrorMsg = "Maximum Advance Purchase days must be greater than or equal to Minimum Advance Purchase days."
        return false;
      }
    if (this.promotion.Type == PromotionType.EarlyBird && this.promotion.MinTimeBforeArrival > this.promotion.MaxTimeBforeArrival) {
      this.promotionErrorMsg = "Maximum Advance Purchase days must be greater than or equal to Minimum Advance Purchase days."
      return isValid;
    }
    let blackoutDateRange = [];
    if (this.componentReference.length > 0 && this.blackoutDates.length > 0) {
      for (let c = 0; c < this.componentReference.length; c++) {
        let blackStartDate = this.blackoutDates.toArray()[c].value[0];
        let blackEndDate = this.blackoutDates.toArray()[c].value[1];
        if (blackStartDate && blackEndDate && blackStartDate != "" && blackEndDate != "") {
          // if(Utilities.dateDifference(blackStartDate,blackEndDate) < 0){
          //   this.promotionErrorMsg = "Blackout start date must be greater than end date. ";
          //   return isValid;
          // } else {
          let blackOutRange = new BlackoutPeriod();
          blackOutRange.Start = Utilities.convertToUTC(blackStartDate);
          blackOutRange.End = Utilities.convertToUTC(blackEndDate);
          blackOutRange.OnStayDate = true;
          blackoutDateRange.push(blackOutRange);
          //}
        }
      }
    }

    /*
    //this.promotion.Status = this.promotionStatus ? 0 : 1;
    if (!this.promotion.BookingDates) this.promotion.BookingDates = new DateRange();
    this.promotion.BookingDates.Start = Utilities.convertToUTC(this.bookingStartDate);
    this.promotion.BookingDates.End = Utilities.convertToUTC(this.bookingEndDate);
    this.promotion.BookingDates["DaysOfWeek"] = Utilities.convertDaysOfWeekToDecimal(this.selectedBookingDaysOfWeek);
    if (!this.promotion.StayDates) this.promotion.StayDates = new DateRange();
    this.promotion.StayDates.Start = Utilities.convertToUTC(this.stayStartDate);
    this.promotion.StayDates.End = Utilities.convertToUTC(this.stayEndDate);
    this.promotion.StayDates["DaysOfWeek"] = Utilities.convertDaysOfWeekToDecimal(this.selectedStayDaysOfWeek);
    this.promotion.RoomTypes = this.promoRoomTypes;
    this.promotion.RatePlans = this.promoRatePlans;
    this.promotion.DiscountType = ValueType.Percent;
    */
    if (!this.promotion.HotelId) {
      this.promotion.HotelId = this.hotelContext.HotelId;
    }
    if (!this.promotion.DomainId) {
      this.promotion.DomainId = this.domainContext.CurrentDomainId;
    }
    this.promotion.BlackoutDates = blackoutDateRange;
    if (this.promotion.ChannelCode == 'EXP') {
      let discTypesExpedia = new discountTypesExpedia();
      discTypesExpedia.applicableNight = this.applicableNight;
      discTypesExpedia.value = this.expDisc;
      discTypesExpedia.isRecurring = this.isRecurring;
      discTypesExpedia.monday = this.mon;
      discTypesExpedia.tuesday = this.tues;
      discTypesExpedia.wednesday = this.wednes;
      discTypesExpedia.thursday = this.thurs;
      discTypesExpedia.friday = this.fri;
      discTypesExpedia.saturday = this.sat;
      discTypesExpedia.sunday = this.sun;
      discTypesExpedia.memberOnlyAdditionalValue = this.memberOnlyAdditionalValue;
      // let discType = this.selectedDiscount.slice(this.selectedDiscount.lastIndexOf('.') + 1)
      // discTypesExpedia.type = promoType[discType];
      if (this.selectedDiscount == 'Expedia.SingleDiscount') {
        discTypesExpedia.type = 2;
        this.promotion.Discount = this.expDisc;
      }
      if (this.selectedDiscount == 'Expedia.DayOfWeekDiscount') discTypesExpedia.type = 0;
      if (this.selectedDiscount == 'Expedia.MultiNightDiscount') {
        discTypesExpedia.type = 1;
        this.promotion.Discount = this.expDisc;
      }
      discTypesExpedia.unit = 0;

      var newObj = {};
      var clone = JSON.parse(JSON.stringify(discTypesExpedia))
      for (var prop in clone)
        if (clone[prop] == null || clone[prop] == undefined)
          delete clone[prop];
      newObj = JSON.stringify(clone);

      if (this.promotion.Tags == undefined)
        this.promotion.Tags = {};
      this.promotion.Tags[this.selectedDiscount] = newObj;
    }

    return true;
    //promotion.Status
  }
  //#endregion Sync Dialog
}
