import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { StorageService } from '../ui-services/storage.service';
import { UserCriteria, AppService, HotelInformation, HotelSearchCriteria, TaskCriteria, TaskCriteria2, DateRange, DomainProfileCriteria } from 'mantras-api';
import { Utilities } from '../ui-services/utilities';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from '../ui-services/authentication.service';
import { LoadingService } from '../ui-services/loading.service';


@Component({
    selector: 'taskscriteria',
    templateUrl: './taskscriteria.component.html',
    styleUrls: ['./taskscriteria.component.css']
})
export class TasksCriteriaComponent implements OnInit {
    domains = [];
    users = [];
    tasksName = [];
    channels = [];
    status = [];
    selectedDomains;
    selectedUsers;
    tasksCriteriaRunDateTo;
    tasksCriteriaRunDateFrom;
    hotels: any[];
    selectedtasksName;
    selectedHotel;
    selectedChannel;
    selectedStatus;
    startDate: any;
    endDate: any;
    @Output('onsearch') onChange: EventEmitter<any>;

    constructor(private storageService: StorageService, private loadingService: LoadingService, private authenticationService: AuthenticationService, private mantras: AppService, private messageService: MessageService) {
        this.onChange = new EventEmitter();
        Utilities.tasksName.forEach(name => {
            this.tasksName.push({ label: name.name, value: name.value });
        });
        this.channels.push({ label: 'All', value: 'All' })
        Utilities.channelTypes.forEach(ch => {
            this.channels.push({ label: ch.Name, value: ch.Id });
        });

        this.status.push({ label: "All", value: 6 });
        this.status.push({ label: "Initial", value: 0 });
        this.status.push({ label: "Running", value: 1 });
        this.status.push({ label: "Complete", value: 2 });
        this.status.push({ label: "Cancelled", value: 3 });
        this.status.push({ label: "Error", value: 4 });
        this.status.push({ label: "Complete With Warnings", value: 5 });
    }

    async ngOnInit() {
        this.domains = [];
        //let domainCriteria = new DomainProfileCriteria();
        let domainList = await this.authenticationService.findDomains(null,true);
        domainList.forEach(domain => {
            if (domain.Id != "System")
                this.domains.push({ label: domain.Name, value: domain.Id });
        });
        //set initial date
        let startDate = new Date();
        this.tasksCriteriaRunDateFrom = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
        this.tasksCriteriaRunDateTo = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 0);
    }



    onSearch() {

        if (!this.buildCriteria()) {
            this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'Error', detail: 'Please enter some criteria' });
        }
        else {
            this.onChange.emit(this.taskCriteria);
        }
    }


    taskCriteria;
    public buildCriteria() {
        let returnVal = false;
        this.taskCriteria = new TaskCriteria();
        if (this.selectedDomains && this.selectedDomains.length > 0) {
            this.taskCriteria.DomainIdList = [];
            for (let index = 0; index < this.selectedDomains.length; index++) {
                const element = this.selectedDomains[index];
                this.taskCriteria.DomainIdList.push(element);
            }
            returnVal = true;
        }
        else {
            this.taskCriteria.DomainIdList = null;
        }
        if (this.selectedUsers && this.selectedUsers.length > 0) {
            this.taskCriteria.UserIdList = [];
            for (let index = 0; index < this.selectedUsers.length; index++) {
                const element = this.selectedUsers[index];
                this.taskCriteria.UserIdList.push(element);
            }
            returnVal = true;
        }
        else {
            this.taskCriteria.UserIdList = null;
        }
        if (this.selectedtasksName && this.selectedtasksName != "") {
            this.taskCriteria.Name = this.selectedtasksName;
            returnVal = true;
        }
        else {
            this.taskCriteria.Name = null;
        }
        if (this.selectedChannel && this.selectedChannel != "") {
            this.taskCriteria.ChannelCode = this.selectedChannel;
            returnVal = true;
        }
        else {
            this.taskCriteria.ChannelCode = null;
        }
        if (this.selectedHotel && this.selectedHotel != "") {
            this.taskCriteria.HotelId = this.selectedHotel;
            returnVal = true;
        }
        else {
            this.taskCriteria.HotelId = null;
        }
        if (this.tasksCriteriaRunDateFrom != null && this.tasksCriteriaRunDateTo != null) {
            this.taskCriteria.RunDate = new DateRange();
            this.startDate = new Date(this.tasksCriteriaRunDateFrom.getFullYear(), this.tasksCriteriaRunDateFrom.getMonth(), this.tasksCriteriaRunDateFrom.getDate(),
                this.tasksCriteriaRunDateFrom.getHours(), this.tasksCriteriaRunDateFrom.getMinutes(), this.tasksCriteriaRunDateFrom.getSeconds());
            this.endDate = new Date(this.tasksCriteriaRunDateTo.getFullYear(), this.tasksCriteriaRunDateTo.getMonth(), this.tasksCriteriaRunDateTo.getDate(),
                this.tasksCriteriaRunDateTo.getHours(), this.tasksCriteriaRunDateTo.getMinutes(), this.tasksCriteriaRunDateTo.getSeconds());
            this.taskCriteria.RunDate.Start = this.startDate;
            this.taskCriteria.RunDate.End = this.endDate;
            returnVal = true;
        }
        else {
            this.taskCriteria.RunDate = null;
        }
        if (this.selectedStatus && this.selectedStatus != '6') {
            this.taskCriteria.Status = this.selectedStatus;
            returnVal = true;
        }
        else {
            this.taskCriteria.Status = null;
        }
        return returnVal;
    }

    bindCriteria(criteria) {
        if (criteria.RunDate) {
            this.tasksCriteriaRunDateFrom = criteria.RunDate.Start;
            this.tasksCriteriaRunDateTo = criteria.RunDate.End;
        }
        this.selectedtasksName = criteria.Name;
        this.selectedChannel = criteria.ChannelCode;
        this.selectedDomains = criteria.DomainIdList;
        this.selectedUsers = criteria.UserIdList;
        this.selectedHotel = criteria.HotelId;
    }



    async onChangeDomain(event) {
        //fetch hotels list from domain
        let hotelCriteria = new HotelSearchCriteria();
        this.hotels = [];
        hotelCriteria.DomainIds = [];
        for (let index = 0; index < this.selectedDomains.length; index++) {
            const element = this.selectedDomains[index];
            hotelCriteria.DomainIds.push(element);
        }
        this.mantras.FindHotelInfo(hotelCriteria).then(response => {
            response.forEach(hotel => {
                this.hotels.push({ label: hotel.Name, value: hotel.Id });
            });
        });

        //To fetch user list from domain      
        let userCriteria = new UserCriteria();
        this.users = [];
        this.selectedDomains.forEach(async domainId => {
            userCriteria.DomainId = domainId;
            let response = await this.mantras.FindDomainUsers(userCriteria);
            response.forEach(user => {
                this.users.push({ label: user.FirstName + user.LastName, value: user.Id });
            });
        });
    }

    onClear() {
        this.selectedDomains = null;
        this.selectedUsers = null;
        this.selectedtasksName = null;
        this.selectedStatus = '6';
        this.selectedChannel = null;
        this.selectedHotel = null;
    }
}

