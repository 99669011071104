import { Component, OnInit, HostListener, ViewChild, ViewContainerRef, ComponentFactoryResolver,Output,EventEmitter } from '@angular/core';
import { StorageService } from '../../ui-services/storage.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/primeng';
import { GridOptions } from 'ag-grid';
import { Subscription } from 'rxjs';
import { HotelManagementService } from '../../ui-services/hotelmanagement.service';
import { TaxPolicy, HotelCriteria } from 'mantras-api';
import { AnalyticsService } from '../../ui-services/analytics.service';
import { AuthenticationService } from '../../ui-services/authentication.service';

import * as jQuery from 'jquery';
import { DatexPipe } from '../../components/transformer/datexpipe.transformer';
import { UserService } from '../../ui-services/user.service';
import { Utilities } from '../../ui-services/utilities';
import { CustomReuseStrategy } from '../../ui-services/reuse-routes';

@Component({
  selector: 'competitors',
  templateUrl: './competitors.component.html',
  styleUrls: ['./competitors.component.css']
})
export class CompetitorsComponent implements OnInit {
  breadCrumbItems: MenuItem[];
  competitorsMenuItems: MenuItem[];
  columnDefs: any[];
  rowData: any[];
  competitors = [];
  gridOptions: GridOptions;
  rowSelection: any;
  rowGroupPanelShow: any;
  taxPolicies;
  showAddCompDialog = false;
  criteria = new HotelCriteria();

  showDetailPage = false;
  icons;
  isReadOnly = false;
  subscription: Subscription;
  hotelContext;
  domainContext;
  domainHotel;
  hotelCity;
  selectedComp;
  @Output() onChangeMenuItems: EventEmitter<any>  = new EventEmitter();
  constructor(private resolver: ComponentFactoryResolver, private storageService: StorageService, private router: Router,
    private hotelMgmtService: HotelManagementService, private analyticsService: AnalyticsService, private authenticationService: AuthenticationService,private userService:UserService) {
      this.isReadOnly = userService.isReadOnly();
    this.breadCrumbItems = [{ label: "Settings", icon: 'fa fa-wrench', routerLink: ["/settings"] },
    { label: "Competitors", routerLink: ["/settings/competitors"] }];
    this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    this.columnDefs = [
      { headerName: "Name", field: "Name", tooltipField: "Name", width: 120, menuTabs: [], cellClass: 'gridcol', sort: "asc" },
      { headerName: "Street", field: "Address.Street", tooltipField: "Address.Street", width: 100, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "City", field: "Address.City", tooltipField: "Address.City", width: 50, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "State", field: "Address.State", tooltipField: "Address.State", width: 100, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Country", field: "Address.Country", tooltipField: "Address.Country", width: 100, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Phone", field: "Phone.Number", tooltipField: "Phone.Number", width: 100, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "", width: 30, menuTabs: [] }
    ];

    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      showToolPanel: false,
      localeText: { noRowsToShow: "No competitors found" }
    };
    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });
    this.domainContext = this.storageService.get(StorageService.currentUser).DomainContext;
  }
  async findCompetitors(force = false) {

    this.domainHotel = await this.authenticationService.getDomainHotel(this.hotelContext.HotelId);
    this.competitors = this.domainHotel.CompetitorHotels;
    //TODO: This is to remove the target hotel id which is duplicated in the list.
    if (this.competitors && this.competitors.length >= 2) {
      //Check first and last index hotel id and remove last if its same.
      if (this.competitors[0] == this.competitors[this.competitors.length - 1]) {
        this.competitors.pop();
      }
    }
    let response = await this.analyticsService.findHotels(this.competitors).then(
      async response => {
        response.forEach(element => {
          if (element.Id == this.hotelContext.HotelId) {
            this.criteria.City = element.Address.City;
          }
        });
        this.taxPolicies = response;
        this.gridOptions.api.setRowData(response);
        this.gridOptions.api.sizeColumnsToFit();
        this.onGridReady(this);
      });
  }

  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
    let setSelected = false;
    this.gridOptions.api.forEachNode(node => {
      if (!node.group && !setSelected) { node.setSelected(true); this.onRowClick(node); setSelected = true; }
    });
    if (!setSelected) this.loadMenu();
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }
  onGridSizeChanged($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
  }
  ngOnInit() {
    this.loadMenu();
    this.findCompetitors();
  }

  loadMenu() {
    this.competitorsMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'Add', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.onShowAddCompDialog(event) },
      { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: this.isReadOnly, command: (event) => this.confirmDelete(event, true) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
    ];
    this.getMenuItemData();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true,true,false,false));
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  onRowClick(params) {
    if (params.data) {
      let disabledDelete = false;
      if (params.data.Id.toLowerCase() == this.storageService.get(StorageService.loginUserId)) disabledDelete = true;
      this.competitorsMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
        { title: 'New', label: 'Add', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => { this.onShowAddCompDialog(event) } },
        { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: this.isReadOnly || disabledDelete, command: (event) => this.confirmDelete(event, true) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
      ];
    }
  }

  //#region Add Competitor Dialog

  hotels = [];
  isAddButtonDisabled = true;
  dialogGridOptions = <GridOptions>{
    enableSorting: true,
    animateRows: true,
    groupDefaultExpanded: -1,
    rowHeight: 24,
    suppressDragLeaveHidesColumns: true,
    localeText: { noRowsToShow: "No Competitor Found. Try Searching.." },
    suppressContextMenu: true,
  };

  async onShowAddCompDialog(params) {
    this.hotels = [];
    this.showAddCompDialog = true;
    this.dialogGridOptions.api.setColumnDefs(this.addCompDialogColumnDef());
    this.dialogGridOptions.api.setRowData(this.hotels);
    this.onDialogGridReady(null);
  }

  addCompDialogColumnDef() {
    let promoColDef = new Array();
    promoColDef.push({ headerName: "Property Name", cellClass: 'gridcol', width: 375, tooltipField: "Name", field: "Name", menuTabs: [] });
    promoColDef.push({ headerName: "Street", cellClass: 'gridcol', width: 375, tooltipField: "UserId", field: "Address.Street", menuTabs: [] });
    promoColDef.push({ headerName: "City", cellClass: 'gridcol', width: 375, tooltipField: "UserId", field: "Address.City", menuTabs: [] });
    promoColDef.push({ headerName: "Country", cellClass: 'gridcol', width: 375, tooltipField: "UserId", field: "Address.Country", menuTabs: [] });


    return promoColDef;
  }
  async onSearch() {
    let hotelInfo;
    if (this.criteria.City || this.criteria.FreeText)
      hotelInfo = await this.hotelMgmtService.findHotels(this.criteria, true);
    if (hotelInfo) {
      this.hotels = [];
      hotelInfo.forEach(element => {
        if (!this.competitors.includes(element.Id)) {
          this.hotels.push(element)
        }
      });
    }

    this.dialogGridOptions.api.setColumnDefs(this.addCompDialogColumnDef());
    this.dialogGridOptions.api.setRowData(this.hotels);
    this.onDialogGridReady(null);
  }
  onGridSelectionChanged() {
    var selectedRows = this.dialogGridOptions.api.getSelectedRows();
    this.isAddButtonDisabled = selectedRows.length === 0;
  }
  onDialogGridReady(event) {
    this.dialogGridOptions.api.sizeColumnsToFit();
  }

  onPromoLogsGridSizeChanged(evnet) {
    this.dialogGridOptions.api.sizeColumnsToFit();
  }
  //#endregion Add Competitor Dialog

  //Refresh Promotion Grid
  onRefresh(event) {
    this.findCompetitors();
  }
  //OnClose
  onClose() {
    this.router.navigate(["/settings"]);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }


  //Delete Promotion
  @ViewChild('showConfirmationDialog') showConfirmationDialog;
  showDeleteConfirmation = false;
  deleteId;
  confirmDelete(params, fromGrid?) {
    if (!fromGrid) {
      if (params.node.data) {
        this.deleteId = params.node.data.Id;
        this.showDeleteConfirmation = true;
      }
    } else {
      let selectedRow = this.gridOptions.api.getSelectedRows();
      if (selectedRow && selectedRow.length > 0) {
        this.deleteId = selectedRow[0].Id;
        this.showDeleteConfirmation = true;
      }
    }
  }
  async saveCompetitor(event) {
    this.selectedComp = this.dialogGridOptions.api.getSelectedRows();
    this.selectedComp.forEach(c => {
      this.competitors.push(c.Id);
    });
    this.domainHotel.CompetitorHotels = this.competitors;
    await this.hotelMgmtService.saveDomainHotel(this.domainHotel);
    this.showAddCompDialog = false;
    this.hotels = [];
    await this.findCompetitors();
  }
  closeDialog(event) {
    this.deleteId = null;
    this.showDeleteConfirmation = false;
    this.setHeight();
  }

  async delete(event) {
    this.showDeleteConfirmation = false;
    if (this.deleteId != null) {
      this.domainHotel.CompetitorHotels.splice(this.domainHotel.CompetitorHotels.indexOf(this.deleteId), 1)
      await this.hotelMgmtService.saveDomainHotel(this.domainHotel);
    }
    this.hotels = [];
    this.onRefresh(event);
  }
  getMenuItemData(){
    let data = [];
    data.push({'source':'competitor'});
    data.push(this.competitorsMenuItems);
    this.onChangeMenuItems.emit(data);
  }
}
