import { Component, OnInit, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { StorageService } from '../ui-services/storage.service';
import { Utilities } from '../ui-services/utilities';
import { DateRange } from 'mantras-api';
import { MenuItem, MessageService } from 'primeng/primeng';
import { Pivotviewer2Component } from '../components/pivotviewer2/pivotviewer2.component';
import { BookingAnalyticsCriteria } from 'mantras-api';
import { AnalyticsService } from '../ui-services/analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventManagementService } from '../ui-services/eventmanagement.service';
import * as jQuery from 'jquery';
import { LoadingService } from '../ui-services/loading.service';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';

@Component({
  selector: 'bookinganalytics',
  templateUrl: './bookinganalytics.component.html',
  styleUrls: ['./bookinganalytics.component.css']
})
export class BookinganalyticsComponent implements OnInit, AfterViewInit {
  bookingCriteria = new BookingAnalyticsCriteria();
  displayPivotViewer;
  hotels = [];
  channels = [];
  status = [];
  rateformat = [];
  bookingStatus = null;
  guestName: string;
  creationDateRange=[];
  selectedCreatedDateFrom: Date;
  selectedCreatedDateTo: Date;
  checkInDateRange;
  selectedCheckinFrom: Date;
  selectedCheckinTo: Date;
  filteredChannels = [];
  criteraChannels = [];
  items: MenuItem[];
  bookingAnalyticsMenuItems: MenuItem[];
  isResultsRendered = false;
  subscription: Subscription;
  showCriteria = true;
  pivotviewerTemplateData;
  @ViewChild('criteriaDiv') criteriaDiv;
  @ViewChild('pivotViewer2') pivotViewer2: Pivotviewer2Component;

  constructor(private storageService: StorageService, private analyticsService:AnalyticsService,
    private router:Router, private eventManager:EventManagementService,private loadingService:LoadingService,
    private messageService:MessageService){
    let statusMap = { 0: "UnSpecified", 1: "Provisional", 2: "Confirmed", 3: "Cancelled", 4: "Modified", 5: "WaitingList", 6: "NoShow" };
    let domains = this.storageService.get(StorageService.currentUser).DomainContext.Domains;
    domains.forEach(domain => {
      if(domain.DomainHotels){
        domain.DomainHotels.forEach(domainHotel => {
          this.hotels.push({ label: domainHotel.Name, value: domainHotel.Id });
        });
      }
    });

    this.status.push({ label: "All", value: null });
    this.status.push({ label: "Provisional", value: 1 });
    this.status.push({ label: "Confirmed", value: 2 });
    this.status.push({ label: "Cancelled", value: 3 });

    let nowDate = new Date();
    this.selectedCreatedDateTo = Utilities.getUTCDate(nowDate);
    nowDate.setMonth(nowDate.getMonth()-1);
    nowDate.setDate(nowDate.getDate()+1);
    this.selectedCreatedDateFrom = Utilities.getUTCDate(nowDate);
    this.creationDateRange.push(this.selectedCreatedDateFrom);
    this.creationDateRange.push(this.selectedCreatedDateTo);
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    this.filteredChannels = [];
    hotelContext.ChannelCodes.forEach(channel => {
      this.filteredChannels.push({ value: channel, label: Utilities.findId(Utilities.channelTypes, channel, channel) })
    });
    this.filteredChannels.sort(Utilities.sort_label_asc);
    this.criteraChannels = hotelContext.ChannelCodes;
    
    this.items = [{label: "Analytics",icon:'fa fa-line-chart', routerLink:["/analytics"]},
    {label: "Booking Analytics", routerLink:["/analytics/bookinganalytics"]}];

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
    });

    this.renderMenu();
  }
  
  buildCriteria() {
    let returnVal=false;
    this.bookingCriteria = new BookingAnalyticsCriteria();
    if (!this.bookingCriteria.HotelIds) {
      let hotelId = this.storageService.get(StorageService.currentUser).HotelContext.HotelId;
      this.bookingCriteria.HotelIds = [];
      this.bookingCriteria.HotelIds.push(hotelId);
    }

    if (this.creationDateRange) {
      this.bookingCriteria.CreateDate = new DateRange();
      let end = new Date(this.creationDateRange[1] == null ? this.creationDateRange[0] : this.creationDateRange[1]);
      end.setDate(end.getDate() + 1);
      this.bookingCriteria.CreateDate.End = (end);
      this.bookingCriteria.CreateDate.Start = (new Date(this.creationDateRange[0]));
      returnVal=true;
    }
    
    if (this.checkInDateRange) {
      this.bookingCriteria.InDates = new DateRange();
      let endDate = new Date(this.checkInDateRange[1] == null ? this.checkInDateRange[0] : this.checkInDateRange[1]);
      endDate.setDate(endDate.getDate() + 1);
      console.log(endDate);
      this.bookingCriteria.InDates.Start = Utilities.convertToUTC(this.checkInDateRange[0]);
      this.bookingCriteria.InDates.End = new Date(endDate);
      returnVal=true;
    }

    if (this.criteraChannels && this.criteraChannels.length > 0) {
      if(this.criteraChannels.length != this.filteredChannels.length)
        this.bookingCriteria.ChannelCodes = this.criteraChannels;
      else
        this.bookingCriteria.ChannelCodes = null; 
    }

    if(this.guestName)
      this.bookingCriteria.TravelerName = this.guestName;
    if(this.bookingStatus != null && this.bookingStatus != undefined)
      this.bookingCriteria.Status = this.bookingStatus;
      return returnVal;
  }

  toUtcDate(date: Date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }

  async onSearch() {
    if(!this.buildCriteria()){
      this.messageService.add({key:'error', severity: 'error',life:2000, summary: 'Error',detail:'Please enter Booking Dates or Checkin Dates' });
    }else{
      this.displayPivotViewer="block";
      this.isResultsRendered = false;
      let analyticsResponse = await this.analyticsService.getBookingAnalyticsReport(this.bookingCriteria).then(response =>{
        if(response != null && response != undefined) this.isResultsRendered = true;
        return response;
      });
      if(analyticsResponse && analyticsResponse.Items.Item.length>0) this.showCriteria=false;
      this.pivotViewer2.loadPivotViewerScriptWithData(analyticsResponse);
      this.renderMenu();
      this.setHeight();
    }
  }

  renderMenu(){
    this.bookingAnalyticsMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onSearch() },
      { title: 'Export to excel', label: 'Export', icon: 'action-bar-menu-icon fa fa-file-excel-o',disabled:!this.isResultsRendered, command: (event) => this.onExport()},
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
    ];
  }

  ngOnInit() {
    this.displayPivotViewer="none";
    this.pivotviewerTemplateData = this.analyticsService.getBookingAnalyticsTemplates();
  }

  onExport(){
    this.analyticsService.exportBookingAnalyticsReport(this.bookingCriteria);
  }

  onClose(){
    this.router.navigate(["/analytics"]);
  } 

  @HostListener('window:resize') setHeight() {
    let additionalHeight=0;
    if(this.criteriaDiv.nativeElement.offsetHeight!=0) additionalHeight = this.criteriaDiv.nativeElement.offsetHeight;
    if(!this.showCriteria) additionalHeight = 0;
    else{
      if(additionalHeight == 0) additionalHeight = 79
    }
    jQuery('.mainsection').css('height',Utilities.getScrollHeight(true,false,false,false,additionalHeight));
    }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  hideShowCriteria(){
    this.showCriteria = !this.showCriteria;
    this.setHeight();
  }
  onClear(){
    this.criteraChannels = this.storageService.get(StorageService.currentUser).HotelContext.ChannelCodes;
    this.creationDateRange = null;
    this.checkInDateRange = null;
    this.guestName = null;
    this.bookingStatus = null;
  }
}