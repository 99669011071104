import { Component, OnInit, HostListener, ChangeDetectionStrategy, Input } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { Router, ActivatedRoute } from '@angular/router';
import { EventManagementService } from '../ui-services/eventmanagement.service';
import { StorageService } from '../ui-services/storage.service';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { Subscription } from 'rxjs';
import { DomainUser } from 'mantras-api';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  isRoleAccSupAccMan: boolean;
  cloneUserRoles;
  isRoleAdOp = false;
  
  @Input() user = new DomainUser();
  subscription: Subscription;
  isCurrentDomainSystem: boolean = false;
  selectedDomain: any;
  currentUser: any;
  items: MenuItem[];
  hasSystemAccess = false;
  constructor(private router: Router, private route: ActivatedRoute, private eventManager: EventManagementService,
    private storageService: StorageService) {
    this.items = [{ label: 'Settings', routerLink: ['/settings'] }];
    this.hasSystemAccess = Utilities.checkSystemAccess(this.storageService.get(StorageService.currentUser).DomainContext.Domains);
       this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        this.ngOnInit();
      });

  }

  async routePage(navigationLink) {
    this.router.navigate([navigationLink], { relativeTo: this.route });
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true));
  }
  ngOnInit() { 
    this.isRoleAdOp = false;
    this.isRoleAccSupAccMan = false
    this.currentUser = this.storageService.get(StorageService.currentUser);
        this.selectedDomain = this.currentUser.DomainContext.CurrentDomainId;
        this.cloneUserRoles = Object.assign([],this.user.Roles);
    if (this.selectedDomain == "System") {
      this.isCurrentDomainSystem = true;
      //this.cloneUserRoles.forEach(element => {
        this.currentUser.User.Roles.forEach(element => {
        if(element == "Operator" || element == "Admins"){
          this.isRoleAdOp = true;
        }
        if(element == "Account Supervisor" || element == "Account Manager")
        {
          this.isRoleAccSupAccMan == true;
        }
      });
    }
    else{
      this.isCurrentDomainSystem = false;
    }
    
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
