import {Component, ViewChild, ElementRef} from "@angular/core";

import {ICellRendererParams} from "ag-grid";
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'group-renderer',
    template: `<div *ngIf="data" class="ui-g-12 ui-g-nopad">
               <div><input type="text" disabled="true" #perday value="{{this.perDay}}"/></div>
               <div><input type="text" disabled="true" #single value="{{this.singleOccupancy}}"/></div>
               <div><input type="text" disabled="true" #double value="{{this.doubleOccupancy}}"/></div>
               </div>`
})
export class BestAvailableRateComponent implements ICellRendererAngularComp {
    public perDay;
    public singleOccupancy;
    public doubleOccupancy;
    @ViewChild('perday') perDayInput: ElementRef;
    @ViewChild('single') singleInput: ElementRef;
    @ViewChild('double') doubleInput: ElementRef;
    public data;

    agInit(params: ICellRendererParams): void {
        this.data = params.data;
        console.log("Row Index:" + params.rowIndex + " Data: " + params.data +"get value: "+params.getValue())
        console.log("Row Def "+ params.colDef.colId);
        if(params.getValue() && params.getValue().length) {
            this.perDay = params.getValue()[params.colDef.colId].RoomRates["1"];
            this.singleOccupancy = params.getValue()[params.colDef.colId].RoomRates["2"];
            this.doubleOccupancy = params.getValue()[params.colDef.colId].RoomRates["3"];
        }
    }

    refresh(params: any): boolean {
        console.log("Refresh method called: " +params);
        if(params.getValue() && params.getValue().length) {
            this.perDay = params.getValue()[params.colDef.colId].RoomRates["1"];
            this.singleOccupancy = params.getValue()[params.colDef.colId].RoomRates["2"];
            this.doubleOccupancy = params.getValue()[params.colDef.colId].RoomRates["3"];
        }
        return true;
    }

}