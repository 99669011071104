import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { DomainUser, DomainProfileCriteria, AppService } from 'mantras-api';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { UserDomains, UserService } from '../ui-services/user.service';
import { LoadingService } from '../ui-services/loading.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'usersmanagementdetails',
  templateUrl: './usersmanagementdetails.component.html',
  styleUrls: ['./usersmanagementdetails.component.css']
})
export class UsersManagementDetailsComponent implements OnInit {
  userMenuItems;
  roles;
  associaionRoles;
  domainsListFlag = false;
  @Input() user = new UserDomains();
  @Output('onClose') onClose = new EventEmitter<boolean>();

  constructor(private mantras: AppService, private loadingService: LoadingService, private userService: UserService,
    private messageService: MessageService) {

  }


  ngOnInit() {
    this.userMenuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', command: (event) => this.onSave("save") },
      { title: 'Save and Close', label: 'Save and Close', icon: 'action-bar-menu-icon fa fa-floppy-o', command: (event) => this.onSave("saveAndClose") },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) },
      { title: 'Change Password', label: 'Change Password', icon: 'action-bar-menu-icon fa fa-key', command: (event) => this.showChangePassword() }]
  }

  domains = [];
  associationDomains = [];
  associationSelectedDomains = [];
  assoDomainsFlag = false;
  selectedRoles; description; selectedDomain; firstName;
  lastName; isActive;
  domainIds; isSystemFlag;
  domainInfos = new Map<string, string>();
  domainDetailsMap = new Map<string, object>();
  unModifiedUserDomains;
  userRoles = [{label:"Admins",value:"Admins"},{label:"ReadOnlyUser",value:"ReadOnlyUser"},{label:"Bookings",value:"Bookings"},{label:"Analytics",value:"Analytics"}, 
    {label:"Rate Calendar",value:"Rate Calendar"}, {label:"Promotions",value:"Promotions"}, {label:"Control Panel",value:"Control Panel"},{label:"Inventory Only",value:"InventoryOnly"},
    { label: "Property Manager (legacy)", value: "Property Manager" }, { label: "Rate Manager (legacy)", value: "Rate Manager" }, { label: "Revenue Manager (legacy)", value: "Revenue Manager" }];
  sysUserRoles = [{label:"Admins",value:"Admins"},{label:"Account Supervisor",value:"Account Supervisor"},{label:"Account Manager",value:"Account Manager"},{label:"Operator",value:"Operator"},{label:"ReadOnlyUser",value:"ReadOnlyUser"},
  { label: "Property Manager (legacy)", value: "Property Manager" }, { label: "Rate Manager (legacy)", value: "Rate Manager" }, { label: "Revenue Manager (legacy)", value: "Revenue Manager" }];

  selectedTab = 0;
  async loadData() {
    if (this.domainDetailsMap.has(this.user.userid)) {
      //if(this.usageDetailsMap.has(this.dataId)){
      this.domainDetailsMap.get(this.user.userid);
    }
    this.isSystemFlag = false;
    this.firstName = this.user.DomainUsers[0].FirstName;
    this.lastName = this.user.DomainUsers[0].LastName;
    this.isActive = this.user.DomainUsers[0].IsActive;
    this.selectedRoles = this.user.DomainUsers[0].Roles;
    this.description = this.user.DomainUsers[0].Description;
    this.domainIds = [];
    this.user.DomainUsers.forEach(element => {
      if (!this.domainIds.includes(element.DomainId))
        this.domainIds.push(element.DomainId);
    });
    let domainCriteria = new DomainProfileCriteria();
    domainCriteria.Ids = this.domainIds;
    await this.loadingService.interceptor(this.mantras.FindDomains(domainCriteria)).then(response => {
      response.forEach(resp => {
        this.domainInfos.set(resp.Id, resp.Name)
      });
    });
    this.domainIds.forEach(element => {
      let domainName = this.domainInfos.get(element);
      if (domainName && domainName != undefined) {
        if (element == "System") {
          this.isSystemFlag = true;
        }
        this.domains.push({ label: domainName, value: element });
        this.associationDomains.push({ label: domainName, value: element });
      }
      if (domainName == undefined) {
        this.associationDomains.push({ label: element, value: element });
      }
    });
  
    this.associaionRoles = [{ label: "Any", value: "Any" }, {label:"Admins",value:"Admins"},{label:"ReadOnlyUser",value:"ReadOnlyUser"}, {label:"Bookings",value:"Bookings"},{label:"Analytics",value:"Analytics"}, 
    {label:"Rate Calendar",value:"Rate Calendar"}, {label:"Promotions",value:"Promotions"}, {label:"Control Panel",value:"Control Panel"},{label:"Inventory Only",value:"InventoryOnly"}, { label: "Operator", value: "Operator" },
    { label: "Property Manager (legacy)", value: "Property Manager" }, { label: "Rate Manager (legacy)", value: "Rate Manager" }, { label: "Revenue Manager (legacy)", value: "Revenue Manager" }];

    this.isRoleAnyFlag = true;
    this.selectedDomain = this.user.DomainUsers[0].DomainId;
    if(this.selectedDomain == "System") this.roles = this.sysUserRoles;
    else this.roles = this.userRoles;
    this.associationSelectedDomains = this.domainIds;
    this.assoDomainsFlag = true;
    this.unModifiedUserDomains = Utilities.clone(this.user.DomainUsers);
    this.loadMenu();
  }

  userManagementDetails;
  onSave(event) {
    if(this.selectedTab == 0){
      this.userManagementDetails = this.user.DomainUsers.find(d => d.DomainId == this.selectedDomain);
      this.userManagementDetails.FirstName = this.firstName;
      this.userManagementDetails.LastName = this.lastName;
      this.userManagementDetails.Description = this.description;
      this.userManagementDetails.IsActive = this.isActive;
      this.userManagementDetails.Roles = this.selectedRoles;
      if(this.userManagementDetails.Roles.indexOf("Administrators") < 0) this.userManagementDetails.Roles.push("Administrators")
      this.userManagementDetails.IsTempPassword = true;
      this.userService.saveDomainUser(this.userManagementDetails);
    } else {
      //Save all domains
      for(let c = 0; c < this.unModifiedUserDomains.length; c++){
        let origDomainUser = this.unModifiedUserDomains[c];
        let modiDomainUser = this.user.DomainUsers[c];
        if(!Utilities.compare(origDomainUser, modiDomainUser)){
          this.userService.saveDomainUser(modiDomainUser);
        }
      }
    }
    if (event == "saveAndClose") {
      this.onCancel(true);
    }
  }

  onTabChange(e){
    this.selectedTab = e.index;
  }

  loadMenu() {
    this.userMenuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', command: (event) => this.onSave("save") },
      { title: 'Save and Close', label: 'Save and Close', icon: 'action-bar-menu-icon fa fa-floppy-o', command: (event) => this.onSave("saveAndClose") },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) },
      { title: 'Change Password', label: 'Change Password', icon: 'action-bar-menu-icon fa fa-key', command: (event) => this.showChangePassword() }];
  }

  usrIdObj;
  onChangeDomain(e) {
    let index = 0;
    for (let i = 0; i <= this.user.DomainUsers.length; i++) {
      if (this.selectedDomain == this.user.DomainUsers[i].DomainId) {
        index = i;
        break;
      }
    }
    if(this.selectedDomain == "System") this.roles = this.sysUserRoles;
    else this.roles = this.userRoles;
    this.firstName = this.user.DomainUsers[index].FirstName;
    this.lastName = this.user.DomainUsers[index].LastName;
    this.isActive = this.user.DomainUsers[index].IsActive;
    this.selectedRoles = this.user.DomainUsers[index].Roles;
    this.description = this.user.DomainUsers[index].Description;
  }

  selectedAssoRole = "Any";
  isRoleAnyFlag = true;
  tempAssociationSelectedDomains = [];
  onChangeRole(e) {
    if (this.selectedAssoRole == "Any") {
      this.associationSelectedDomains = this.domainIds;
      this.isRoleAnyFlag = true;
      return;
    } else {
      this.isRoleAnyFlag = false;
    }
    this.associationSelectedDomains = [];
    this.tempAssociationSelectedDomains = [];
    this.user.DomainUsers.forEach(element => {
      if (element.Roles.includes(this.selectedAssoRole)) {
        this.associationSelectedDomains.push(element.DomainId);
        this.tempAssociationSelectedDomains.push(element.DomainId);
      }
    })
  }

  fieldTextType;
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  onCancel(event?) {
    this.onClose.emit(event);
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true, true));
  }

  handleOnChange(e){
    //console.log("Associated Domain Change for Role :" + this.selectedAssoRole+ " - Domains: " +this.associationSelectedDomains);
    if(this.tempAssociationSelectedDomains.length > this.associationSelectedDomains.length){
      //Removed
      var removedDomain;
      for(let i = 0; i< this.tempAssociationSelectedDomains.length; i++) {
        if(this.associationSelectedDomains.indexOf(this.tempAssociationSelectedDomains[i]) < 0) {
          removedDomain = this.tempAssociationSelectedDomains[i];
          this.tempAssociationSelectedDomains.splice(i,1);
          break;
        }
      }
      var removedRoleDomainUser = this.user.DomainUsers.find(d => d.DomainId == removedDomain);
      removedRoleDomainUser.Roles.splice(removedRoleDomainUser.Roles.indexOf(this.selectedAssoRole),1);
    } else {
      //Added
      var addedRoleDomainUser = this.user.DomainUsers.find(d => d.DomainId == this.associationSelectedDomains[this.associationSelectedDomains.length-1]);
      this.tempAssociationSelectedDomains.push(addedRoleDomainUser.DomainId);
      addedRoleDomainUser.Roles.push(this.selectedAssoRole);
    }
  }

  showChangePassworDialog = false;
  newPassword;
  confirmPassword;
  showPassword=false;
  showChangePassword(){
    this.newPassword = "";
    this.confirmPassword = "";
    this.showChangePassworDialog = true;
  }
  onShowPassword(){
    this.showPassword=!this.showPassword;
  }
  changePassword(){
    if(!this.newPassword || !this.confirmPassword || this.newPassword != this.confirmPassword) return; 
    this.userService.changePassword(this.user.userid,this.confirmPassword)
    .then(result => {this.messageService.addAll([{severity:'success', summary:'Success', detail:"Password updated successfully."}]);});
    this.showChangePassworDialog = false;
  }
}
