import { Component, OnInit, ViewChild, HostListener, ViewContainerRef, SimpleChanges, OnChanges } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { BookingService, FormattedBooking } from "../ui-services/booking.service";
import { DateRange, BookingCriteria, HotelBooking, BookingConfirmation, BookingStatus, Range, DomainProfileCriteria, HotelCriteria } from 'mantras-api';
import { StorageService } from '../ui-services/storage.service';
import { Subscription } from 'rxjs';
import { Utilities } from '../ui-services/utilities';
import { MenuItem, MessageService } from 'primeng/primeng';
import { EventManagementService } from '../ui-services/eventmanagement.service';
import { TaskbarService } from '../ui-services/taskbar.service';
import { LoadingService } from '../ui-services/loading.service';
import * as jQuery from 'jquery';
import { DatePipe } from '@angular/common';
import {InputTextareaModule} from 'primeng/inputtextarea';
import { AuthenticationService } from '../ui-services/authentication.service';
import { HotelManagementService } from '../ui-services/hotelmanagement.service';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css'],
  providers: [BookingService],
})

export class BookingsComponent implements OnInit, OnChanges {
  isCurrentDomainIsSystem: boolean;
  selectedDomain: any;
  filteredResponse: any[];
  columnDefs: any[];
  rowData: any[];
  rowGroupPanelShow: any;
  gridOptions: GridOptions;
  icons;
  bookingCriteria: BookingCriteria;
  bookingData: FormattedBooking[];
  subscription: Subscription;
  rowSelection: 'multiple';
  isDetailsPage: boolean;
  selectedBooking: FormattedBooking;
  disableConfirm: boolean;
  disableNoShow: boolean;
  disableMail: boolean;
  bookingMenuItems: MenuItem[];
  bookings: HotelBooking[];
  adminEmailIds: string;
  taskCompleteNotificationSubscription: Subscription;
  bookingFilterData;
  filterValue;
  bccBtn = false;
  options = [{ label: "Today's Bookings", value: '0' }, { label: 'Last 7 Days', value: '6' }, { label: 'Last 30 Days', value: '29' }, { label: 'This Month', value: '99' }, { label: "Today's Arrivals", value: '1' }];
  exportParams = {
    skipGroups: true,
    allColumns: true,
  }
  isBookingsFound = false;
  defaultGroupSortComparator;
  hasSystemAccess = false;
  datePipe = new DatePipe('en-US');
  isXchangeConfigured = false;
  constructor(private hotelService: HotelManagementService, private service: BookingService, private messageService: MessageService, private storageService: StorageService, private eventManager: EventManagementService, private taskbarService: TaskbarService, private loadingService: LoadingService) {
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    let domains = this.storageService.get(StorageService.currentUser).DomainContext.Domains;
    this.selectedDomain = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    if (this.selectedDomain != "System") {
      this.filterValue = { label: "Today's Bookings", value: '0' };
    } else {
      this.filterValue = null;
    }
    domains.forEach(domain => {
      if(domain.Id == "System") this.hasSystemAccess = true;
    });
    try{
      this.service.getHotelChannels(hotelContext.HotelId).then(async result => {
        result.forEach(r => {
          if(r == "XCG"){
            this.isXchangeConfigured = true;
            this.populateHotelInfo();
            this.gridOptions.api.setColumnDefs(this.columnDefs);
            this.gridOptions.api.sizeColumnsToFit();
          }
        })
      });
    }catch(e){}
    let statusMap = { 0: "UnSpecified", 1: "Provisional", 2: "Confirmed", 3: "Cancelled", 4: "Modified", 5: "WaitingList", 6: "NoShow" };
    let paymentStatusMap = { 0: "Prepaid", 1: "Pay@Hotel", 2: "Direct" };
    this.populateHotelInfo();
    this.defaultGroupSortComparator = function (nodeA, nodeB) {
      if (nodeA.field.indexOf("RoomCount") >= 0 || nodeA.field.indexOf("Duration") >= 0 || nodeA.field.indexOf("GuestCount") >= 0) {
        if (parseFloat(nodeA.key) < parseFloat(nodeB.key)) {
          return -1;
        } else if (parseFloat(nodeA.key) > parseFloat(nodeB.key)) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (nodeA.key.toLowerCase() < nodeB.key.toLowerCase()) {
          return -1;
        } else if (nodeA.key.toLowerCase() > nodeB.key.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      }
    };

    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    this.rowGroupPanelShow = "always";
    this.rowSelection = "multiple";
    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        this.rowData = Array();
        this.hotels = [];
        this.isXchangeConfigured = false;
        hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
        this.selectedDomain = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
        try{
          this.service.getHotelChannels(hotelContext.HotelId).then(async result => {
            result.forEach(r => {
              if(r == "XCG"){
                this.isXchangeConfigured = true;
                this.populateHotelInfo();
                this.gridOptions.api.setColumnDefs(this.columnDefs);
                this.gridOptions.api.sizeColumnsToFit();
              }
            })
          });
        }catch(e){}
        this.populateHotelInfo();
        if (this.selectedDomain != "System") {
          this.filterValue = { label: "Today's Bookings", value: '0' };
          this.findBooking();
          this.gridOptions.api.setColumnDefs(this.columnDefs);
          this.gridOptions.api.sizeColumnsToFit();
        } else {
          this.filterValue = null;
          this.gridOptions.api.setColumnDefs(this.columnDefs);
          this.gridOptions.api.sizeColumnsToFit();
          this.gridOptions.api.setRowData([]);
          this.isFilterEnabled = false;
        }
      });

    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      icons: this.icons,
      localeText: { noRowsToShow: "No booking(s) found" },
      sortingOrder: ["desc", "asc"]
    };
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;

    this.taskCompleteNotificationSubscription = taskbarService.taskCompleteListener$.subscribe(
      tasks => {
        let taskStatus = this.taskbarService.getTaskStatus(tasks, TaskbarService.Booking);
        if (taskStatus) {
          this.onSearch(this.bookingCriteria);
        }
      }
    );
  }

  populateHotelInfo() {
    this.columnDefs = [
      { headerName: "", cellClass: 'gridcol', showRowGroup: true, width: 50, menuTabs: [] },
      { headerName: "Id", field: "Id", tooltipField: "Id", width: 120, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Hotel", field: "Hotel", width: 200, enableRowGroup: true, menuTabs: [], tooltipField: "Hotel", cellClass: 'gridcol' },
      { headerName: "Channel", field: "Channel", width: 150, enableRowGroup: true, menuTabs: [], tooltipField: "Channel", cellClass: 'gridcol' },
      { headerName: "Domain", field: "Domain", width: 200, enableRowGroup: true, menuTabs: [], hide: !this.isCurrentDomainSystem(), tooltipField: "Domain", cellClass: 'gridcol' },
      {
        headerName: "BookedOn", field: "Booking.CreatedOn", width: 190, menuTabs: [], tooltipField: "CreatedOn", enableRowGroup: true, cellClass: 'gridcol', sort: 'desc', valueFormatter: createdOnFormatter,
        // icons: {
        //   sortAscending: '<img src="C:/Users/Administrator/Desktop/small-down.svg">',
        //   sortDescending: '<i class="fas fa-angle-down"/>',
        // },
      },
      { headerName: "RoomType", field: "RoomName", width: 200, menuTabs: [], enableRowGroup: true, cellClass: 'gridcol' },
      { headerName: "Check-In", field: "Booking.StayInfo.InDate", width: 110, menuTabs: [], tooltipField: "BasicDetails.CheckIn", enableRowGroup: true, cellClass: 'gridcol', valueFormatter: checkInFormatter },
      { headerName: "Room(s)", field: "BasicDetails.RoomCount", width: 100, menuTabs: [], tooltipField: "BasicDetails.RoomCount", enableRowGroup: true, cellClass: 'gridcol rightalign' },
      { headerName: "Night(s)", field: "BasicDetails.Duration", width: 100, menuTabs: [], tooltipField: "BasicDetails.Duration", enableRowGroup: true, cellClass: 'gridcol rightalign' },
      { headerName: "Guest Name", field: "GuestDetails.Name", width: 130, menuTabs: [], tooltipField: "GuestDetails.Name", cellClass: 'gridcol', comparator: stringComparator },
      { headerName: "Guests", field: "BasicDetails.GuestCount", width: 90, menuTabs: [], tooltipField: "BasicDetails.GuestCount", enableRowGroup: true, cellClass: 'gridcol rightalign' },
      { headerName: "Currency", field: "Booking.Currency", width: 100, menuTabs: [], tooltipField: "Booking.Currency", cellClass: 'gridcol' },
      { headerName: "Amount", field: "TotalAmt.Total", width: 100, menuTabs: [], tooltipField: "TotalAmt.Total", cellClass: 'gridcol rightalign', valueFormatter: currencyFormatter },
      { headerName: "Status", field: "Status", width: 100, menuTabs: [], tooltipField: "Status", enableRowGroup: true, cellClass: 'gridcol' },
      { headerName: "Payment Status", headerTooltip: "Payment Status", field: "PaymentStatus", width: 100, menuTabs: [], tooltipField: "PaymentStatus", enableRowGroup: true, cellClass: 'gridcol' }
    ];
    if(this.hasSystemAccess && this.isXchangeConfigured){
      this.columnDefs.push({ headerName: "PMS Status", headerTooltip: "PMS Status", field: "PmsPort", width: 50, menuTabs: [], tooltipField: "PMS Port Status", enableRowGroup: true, cellClass: 'gridcol', cellRenderer: params => { return params?(params.IsSuccess? `<i class="fa fa-check"></i>`:`<i class="fa fa-exclamation-triangle"></i>`):`<i class="fa fa-minus"></i>` }});
    }
    this.columnDefs.push({ headerName: "", width: 30, menuTabs: [] });
  }

  @ViewChild('bookingdetails') BookingDetails;
  @ViewChild('criteria', { read: ViewContainerRef }) bookingCriteriaChild;
  async findBooking(criteria?: BookingCriteria) {
    if (!criteria) {
      criteria = this.createBookingCriteria();
      this.bookingCriteriaChild._data.componentView.component.bindCriteria(criteria);
    }
    this.bookingCriteria = criteria;
    let response = await this.service.findBookings(criteria).then(
      async response => {
        this.bookings = response;
        this.filteredResponse = response;
        this.gridOptions.api.setRowData(response);
        this.setupBookingFilters(response);
        this.onGridReady(this);
        if (response && response.length > 0) {
          this.isBookingsFound = true;
        } else {
          this.isBookingsFound = false;
          this.disableConfirm = true;
        }
      });
    if (this.bookings.length > 15) {
      this.showCriteria = false;
      this.setHeight();
    }
    this.loadMenu();
  }
  loadMenu() {
    if (this.bookings.length > 0) {
      this.disableMail = false;
    }
    else {
      this.disableMail = true;
    }
    this.bookingMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onSearch(this.bookingCriteria) },
      { title: 'Export to excel', label: 'Export', icon: 'action-bar-menu-icon fa fa-file-excel-o', disabled: !this.isBookingsFound, command: (event) => this.onExport() },
      { title: 'Sync', label: 'Sync', icon: 'action-bar-menu-icon fa icon ion-md-sync', visible: !this.isCurrentSysAndAdmin(), command: (event) => this.showSyncDialog(event) },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', disabled: true, command: (event) => this.onClickGridRow(event) },
      { title: 'Confirm Booking', label: 'Confirm', icon: 'action-bar-menu-icon fa fa-check', visible: !this.isCurrentSysAndAdmin(), disabled: this.disableConfirm, command: (event) => this.showConfirmationDialog(event) },
      { title: 'Mark NoShow', label: 'Mark NoShow', icon: 'action-bar-menu-icon fa fa-ban', visible: !this.isCurrentSysAndAdmin(), disabled: this.disableNoShow, command: (event) => this.showNoShowConfirmation(event) },
      { title: 'Email', label: 'Email', icon: 'action-bar-menu-icon fa fa-envelope-o', command: (event) => this.sendVoucherConfirmation(), visible: this.isCurrentUserAdmin(), disabled: this.disableMail },
    ];
  }
  createBookingCriteria() {
    let criteria = new BookingCriteria();
    let startDate = new Date();
    criteria.BookingDates = new Range<Date>();
    criteria.DomainIds = [];
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    let domainContext = this.storageService.get(StorageService.currentUser).DomainContext;
    if (hotelContext != null && hotelContext.HotelId != null)
      criteria.HotelIds = [hotelContext.HotelId];
    if (domainContext != null && domainContext.DomainId != null)
      criteria.DomainIds.push(domainContext.DomainId)
    criteria.BookingDates.Start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
    criteria.BookingDates.End = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 0);
    return criteria;
  }
  onSearch(event) {
    this.findBooking(event);
  }

  isCurrentDomainSystem() {
    this.selectedDomain = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    if (this.selectedDomain == "System") {
      return true;
    } else {
      return false;
    }
  }

  currentUser;
  isCurrentSysAndAdmin() {
     this.currentUser = this.storageService.get(StorageService.currentUser);
    this.selectedDomain = this.currentUser.DomainContext.CurrentDomainId;
    if (this.selectedDomain == "System" && this.currentUser.User.Roles.includes("Admins")) {
      return true;
    } else {
      return false;
    }

  }

  isCurrentUserAdmin() {
    let currentUser = this.storageService.get(StorageService.currentUser);
    if (currentUser.User.Roles.includes("Admins")) {
      return true;
    } else {
      return false;
    }
  }

  onSelectionChanged(event) {
    let selectedRow = this.gridOptions.api.getSelectedRows();
    this.disableConfirm = true;
    this.disableNoShow = true;
    let otherSelected = false;
    selectedRow.forEach(row => {
      if (row.Booking.ChannelCode == "GIB" || row.Booking.ChannelCode == "MMT" || row.Booking.ChannelCode == "EXP") this.disableConfirm = false;
      else if (row.Booking.ChannelCode == "BDC") this.disableNoShow = false;
      else otherSelected = true;
    });
    if (otherSelected || (!this.disableConfirm && !this.disableNoShow)) {
      this.disableNoShow = true;
      this.disableConfirm = true
    }
    //this.populateHotelInfo();
    //this.gridOptions.api.setColumnDefs(this.columnDefs);
    this.gridOptions.api.sizeColumnsToFit();
    if (this.bookings.length > 0) {
      this.disableMail = false;
    }
    else {
      this.disableMail = true;
    }
    this.bookingMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onSearch(this.bookingCriteria) },
      { title: 'Export to excel', label: 'Export', icon: 'action-bar-menu-icon fa fa-file-excel-o', disabled: !this.isBookingsFound, command: (event) => this.onExport() },
      { title: 'Sync', label: 'Sync', icon: 'action-bar-menu-icon fa icon ion-md-sync', visible: !this.isCurrentSysAndAdmin(), command: (event) => this.showSyncDialog(event) },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', disabled: (selectedRow.length > 1), command: (event) => this.onClickGridRow(event) },
      { title: 'Confirm Booking', label: 'Confirm', icon: 'action-bar-menu-icon fa fa-check', visible: !this.isCurrentSysAndAdmin(), disabled: this.disableConfirm, command: (event) => this.showConfirmationDialog(event) },
      { title: 'Mark NoShow', label: 'Mark NoShow', icon: 'action-bar-menu-icon fa fa-ban', visible: !this.isCurrentSysAndAdmin(), disabled: this.disableNoShow, command: (event) => this.showNoShowConfirmation(event) },
      { title: 'Email', label: 'Email', icon: 'action-bar-menu-icon fa fa-envelope-o', command: (event) => this.sendVoucherConfirmation(), visible: this.isCurrentUserAdmin(), disabled: this.disableMail },
    ];

  }
  @ViewChild('bookingFilter') bookingFilter;
  setupBookingFilters(bookings: FormattedBooking[]) {
    if (bookings && bookings.length) {
      let bookingFilterChannelTypes = [];
      let bookingFilterPaymentStatus = [];
      let bookingFilterRoomType = [];
      bookings.forEach(booking => {
        if (bookingFilterChannelTypes.indexOf(booking.Booking.ChannelCode) < 0) bookingFilterChannelTypes.push(booking.Booking.ChannelCode);
        if (bookingFilterPaymentStatus.indexOf(booking.Booking.PaymentType) < 0) bookingFilterPaymentStatus.push(booking.Booking.PaymentType);
        booking.Booking.Rooms.forEach(room => {
          if (bookingFilterRoomType.indexOf(room.RoomName) < 0) bookingFilterRoomType.push(room.RoomName);
        })
      });
      this.bookingFilterData = { channelTypes: bookingFilterChannelTypes, paymentStatus: bookingFilterPaymentStatus, roomTypes: bookingFilterRoomType };
      this.bookingFilter.setupBookingFilter(this.bookingFilterData);
      this.isFilterEnabled = true;
    } else {
      this.isFilterEnabled = false;
    }
  }
  onCriteriaChange() {
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    let domainContext = this.storageService.get(StorageService.currentUser).DomainContext;
    let criteria = new BookingCriteria();
    criteria.BookingDates = new Range<Date>();
    let startDate: Date = new Date();
    let todaysDate: Date = new Date();
    todaysDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate(), 23, 59, 59);
    criteria.BookingDates.End = todaysDate;
    startDate = new Date(todaysDate);
    if (this.filterValue["value"] != '99') {
      startDate.setDate(todaysDate.getDate() - parseInt(this.filterValue.value));
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
      criteria.BookingDates.Start = startDate;
    }
    else {
      criteria.BookingDates.Start = new Date(startDate.getFullYear(), startDate.getMonth(), 1, 0, 0, 0);
    }
    if (this.filterValue["value"] == '1') {
      criteria = new BookingCriteria();
      criteria.CheckInDates = new Range<Date>();
      criteria.CheckInDates.Start = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate(), 0, 0, 0);
      criteria.CheckInDates.End = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate(), 23, 59, 0);
    }
    if (this.bookingCriteria)
      criteria.BookingIds = this.bookingCriteria.BookingIds;
    criteria.HotelIds = [];
    if (hotelContext != null && hotelContext.HotelId != null)
      criteria.HotelIds.push(hotelContext.HotelId);
    criteria.DomainIds = [];
    if (domainContext != null && domainContext.DomainId != null)
      criteria.DomainIds.push(domainContext.DomainId)
    this.bookingCriteriaChild._data.componentView.component.bindCriteria(criteria);
    this.findBooking(criteria);
    this.populateHotelInfo();

  }
  ngOnInit() {
    let currentUser = this.storageService.get(StorageService.currentUser);
    this.selectedDomain = currentUser.DomainContext.CurrentDomainId;
    if (this.selectedDomain == "System") {
      this.isCurrentDomainIsSystem = true;
    } else {
      this.isCurrentDomainIsSystem = false;
    }
    this.disableConfirm = true;
    this.disableNoShow = true;
    this.disableMail = true;
    this.bookingMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onSearch(null) },
      { title: 'Export to excel', label: 'Export', icon: 'action-bar-menu-icon fa fa-file-excel-o', disabled: !this.isBookingsFound, command: (event) => this.onExport() },
      { title: 'Sync', label: 'Sync', icon: 'action-bar-menu-icon fa icon ion-md-sync', visible: !this.isCurrentSysAndAdmin(), command: (event) => this.showSyncDialog(event) },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', disabled: !this.isBookingsFound, command: (event) => this.onClickGridRow(event) },
      { title: 'Confirm Booking', label: 'Confirm', icon: 'action-bar-menu-icon fa fa-check', visible: !this.isCurrentSysAndAdmin(), disabled: this.disableConfirm },
      { title: 'Mark NoShow', label: 'Mark NoShow', icon: 'action-bar-menu-icon fa fa-ban', visible: !this.isCurrentSysAndAdmin(), disabled: this.disableNoShow },
      { title: 'Email', label: 'Email', icon: 'action-bar-menu-icon fa fa-envelope-o', command: (event) => this.sendVoucherConfirmation(), visible: this.isCurrentUserAdmin(), disabled: this.disableMail },
    ];
    if (!this.isCurrentDomainIsSystem) {
      this.findBooking();
      this.setHeight();
      // this.gridOptions.api.setColumnDefs(this.columnDefs);
      // this.gridOptions.api.sizeColumnsToFit();
    } else {
      let criteria = new BookingCriteria();
      let startDate = new Date();
      criteria.BookingDates = new Range<Date>();
      criteria.BookingDates.Start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
      criteria.BookingDates.End = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 0);
      this.filterValue = null;
      this.gridOptions.api.setColumnDefs(this.columnDefs);
      this.gridOptions.api.sizeColumnsToFit();
      this.gridOptions.api.setRowData([]);
      this.isFilterEnabled = false;
    }
    this.populateHotelInfo();
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.taskCompleteNotificationSubscription.unsubscribe();
  }
  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
    this.isDetailsPage = false;
    this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));
    this.gridOptions.suppressDragLeaveHidesColumns = true;
    this.setHeight();
  }
  onGridSizeChanged($event) {
    //this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
  }
  onClickGridRow($event) {
    this.isDetailsPage = true;
    this.selectedBooking = new FormattedBooking();
    this.selectedBooking = this.gridOptions.api.getSelectedRows()[0];
    this.BookingDetails.setHeight();
    this.BookingDetails.loadHeaderOptions();
  }
  onClose($event) {
    this.isDetailsPage = false;
  }
  filterResponse(filterChannels, filterPaymentStatus, filterRoomTypes, bookings) {
    let filteredResponse = [];
    if (filterChannels.length > 0) {
      for (let idx = 0; idx < bookings.length; idx++) {
        let booking = bookings[idx];
        if (filterChannels.indexOf(booking.Booking.ChannelCode) >= 0) filteredResponse.push(booking);
      }
    }
    else filteredResponse = [];
    if (filterPaymentStatus.length > 0) {
      for (let idx = 0; idx < filteredResponse.length; idx++) {
        let booking = filteredResponse[idx];
        if (filterPaymentStatus.indexOf(booking.Booking.PaymentType) < 0) {
          filteredResponse.splice(idx, 1);
          idx--;
        }
      }
    } else filteredResponse = [];
    if (filterRoomTypes.length > 0) {
      for (let idx = 0; idx < filteredResponse.length; idx++) {
        let flag = false;
        let booking = filteredResponse[idx];
        if (booking.Booking.Rooms.length > 1) {
          console.log(booking.Booking.Rooms)
        }
        booking.Booking.Rooms.forEach(room => {
          if (filterRoomTypes.indexOf(room.RoomName) < 0) {
            flag = true;
          }
        });
        if (flag) {
          filteredResponse.splice(idx, 1);
          idx--;
        }
      }
    } else filteredResponse = [];
    this.filteredResponse = filteredResponse;
  }

  renderBookings() {
    this.rowGroupPanelShow = "always";
    this.isDetailsPage = false;
    this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true))
    this.gridOptions.api.setColumnDefs(this.columnDefs);
    this.gridOptions.api.setRowData(this.filteredResponse);
    this.gridOptions.api.sizeColumnsToFit();
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }
  showSyncBookingDialog;
  defaultDateSetting;
  customDateSetting;
  syncChannelTypes = [];
  selectedChanneltypes = [];
  selectedHotel: any;
  dateRanges = [];
  criteriaDateRange;
  rangeSelectionMinDate;
  hotels = [];
  showSyncDialog($event) {
    this.defaultDateSetting = "true";
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 1);
    let endDate = new Date();
    this.dateRanges = [startDate, new Date(Date.now())];
    let domainContext = this.storageService.get(StorageService.currentUser).DomainContext;
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    if (hotelContext != null && hotelContext.HotelId != null)
      this.selectedHotel = hotelContext.HotelId;
    this.onHotelSelect({ value: this.selectedHotel });
    let currentDomain = Utilities.findObject(domainContext.Domains, domainContext.CurrentDomainId);
    currentDomain.DomainHotels.forEach(domainHotel => {
      this.hotels.push({ label: domainHotel.Name, value: domainHotel.Id });
    });
    this.showSyncBookingDialog = true;
  }
  onHotelSelect(event) {
    this.syncChannelTypes = [];
    this.selectedChanneltypes = [];
    let result = this.service.getHotelChannels(event.value).then(async result => {
      result.forEach(r => {
        this.syncChannelTypes.push({ label: Utilities.findId(Utilities.channelTypes, r, r), value: r });
      })
    });
  }

  onSync($event) {
    if (this.defaultDateSetting == "true") {
      let startDate = new Date();
      startDate.setDate(Utilities.getUTCDate(startDate).getDate() - 30);
      this.dateRanges[0] = startDate;
      this.dateRanges[1] = Utilities.getUTCDate(new Date());
      this.service.syncBookings(this.selectedHotel, Utilities.convertToUTC(this.dateRanges[0]), Utilities.convertToUTC(this.dateRanges[1]), this.selectedChanneltypes)
      this.showSyncBookingDialog = false;
    }
    else {
      let endDate = this.dateRanges[0];
      if (this.dateRanges.length > 1 && this.dateRanges[1] != null)
        endDate = this.dateRanges[1];
      this.service.syncBookings(this.selectedHotel, Utilities.convertToUTC(this.dateRanges[0]), Utilities.convertToUTC(endDate), this.selectedChanneltypes)
      this.showSyncBookingDialog = false;
    }

  }

  onExport() {
    this.service.saveFile(this.bookingCriteria);
  }
  onTempExport() {
    this.gridOptions.api.exportDataAsCsv(this.exportParams);
  }
  //Dynamically Set the height of the grid on resize as well as on load
  criteriaDivHeight = 100
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    if (this.bookingCriteriaChild._data.renderElement.firstChild.offsetHeight != 0)
      this.criteriaDivHeight = this.bookingCriteriaChild._data.renderElement.firstChild.offsetHeight;
    if (this.showCriteria == false) this.criteriaDivHeight = 0;
    else {
      if (this.criteriaDivHeight == 0) {
        this.criteriaDivHeight = 111;
      }
    }
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true, false, false, false, this.criteriaDivHeight));
    jQuery('.filterSection').css('height', Utilities.getScrollHeight(true, false, false, false, this.criteriaDivHeight));

  }

  showCriteria = true;
  hideShowCriteria() {
    this.showCriteria = !this.showCriteria;
    this.setHeight();
  }

  confirmBookingsOptions = <GridOptions>{
    enableSorting: true,
    animateRows: true,
    groupDefaultExpanded: -1,
    rowHeight: 24,
    groupUseEntireRow: true,
    suppressDragLeaveHidesColumns: true,
    localeText: { noRowsToShow: "No Bookings selected" },
    suppressContextMenu: true,
    getRowClass: function (params) {
      if (params.node.group && params.node.level == 0) {
        return 'group-background';
      }
    }
  };

  showNoShowConfirmationDialog = false;
  confirmationDialog = false;
  onConfirmBookingsGridReady(event) {
    this.confirmBookingsOptions.api.sizeColumnsToFit();
  }

  onConfirmBookingsGridSizeChanged(evnet) {
    this.confirmBookingsOptions.api.sizeColumnsToFit();
  }

  showConfirmationDialog(event) {
    let selectedRows = this.gridOptions.api.getSelectedRows();
    if (selectedRows.length > 0) {
      this.confirmBookingsOptions.api.setColumnDefs(this.getConfirmBookingColumnDef());
      this.confirmBookingsOptions.api.setRowData(JSON.parse(JSON.stringify(selectedRows)));
      this.onConfirmBookingsGridReady(null);
      this.confirmationDialog = true;
    }
  }

  getConfirmBookingColumnDef() {
    return [
      { headerName: "", cellClass: 'gridcol', showRowGroup: true, width: 50, menuTabs: [] },
      { headerName: "Channel", field: "Channel", width: 150, hide: true, rowGroup: true, menuTabs: [], tooltipField: "Channel", cellClass: 'gridcol' },
      { headerName: "Id", field: "Id", tooltipField: "Id", width: 120, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "BookedOn", field: "Booking.CreatedOn", valueFormatter: createdOnFormatter, width: 190, menuTabs: [], tooltipField: "CreatedOn", cellClass: 'gridcol', sort: 'desc' },
      { headerName: "Check-In", field: "Booking.StayInfo.InDate", valueFormatter: checkInFormatter, width: 110, menuTabs: [], tooltipField: "BasicDetails.CheckIn", cellClass: 'gridcol' },
      { headerName: "Guest Name", field: "GuestDetails.Name", width: 130, menuTabs: [], tooltipField: "GuestDetails.Name", cellClass: 'gridcol' },
      { headerName: "Amount", field: "TotalAmt.Total", width: 100, menuTabs: [], tooltipField: "TotalAmt.Total", cellClass: 'gridcol rightalign', valueFormatter: currencyFormatter },
      { headerName: "Confirmation Code", headerTooltip: "Confirmation Code", field: "Code", width: 100, menuTabs: [], tooltipField: "Confirmation Code", cellClass: 'gridcol inputborder', editable: true },
      { headerName: "", width: 30, menuTabs: [] }
    ];
  }

  showNoShowConfirmation(event) {
    let selectedRows = this.gridOptions.api.getSelectedRows();
    if (selectedRows.length > 0)
      this.showNoShowConfirmationDialog = true;
  }

  markNoShow(event) {
    let selectedRows = this.gridOptions.api.getSelectedRows();
    if (selectedRows.length > 0) {
      let confirmations = new Array();
      selectedRows.forEach(booking => {
        let bookingConfimration = new BookingConfirmation();
        bookingConfimration.BookingId = booking.Booking.Id;
        bookingConfimration.ConfirmedBy = this.storageService.get(StorageService.loginUserId);
        bookingConfimration.InDate = booking.Booking.StayInfo.InDate;
        bookingConfimration.Status = BookingStatus.NoShow;
        confirmations.push(bookingConfimration);
      });
      let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
      if (hotelContext != null && hotelContext.HotelId != null)
        this.service.confirmBookings(hotelContext.HotelId, "BDC", confirmations);

    }
    this.showNoShowConfirmationDialog = false;
  }

  sendVoucherConfirmationDialog = false;
  async sendVoucherConfirmation() {
    this.adminEmailIds = '';
    let hotelIds = [];
    let selectedBooking = this.gridOptions.api.getSelectedRows();
    selectedBooking.forEach(booking => {
      hotelIds.push(booking.Booking.HotelId);
    });

    let hotelCriteria = new HotelCriteria();
    hotelCriteria.Ids = hotelIds;
    await this.hotelService.findHotels(hotelCriteria).then(hotelList => {
      hotelList.forEach(hotel => {
        if (this.adminEmailIds == '' || this.adminEmailIds == null || this.adminEmailIds == undefined) {
          this.adminEmailIds = hotel.Email;
        }
        else {
          this.adminEmailIds = this.adminEmailIds + "," + hotel.Email;
        }
      });
    });

    if (selectedBooking.length > 0)
      this.sendVoucherConfirmationDialog = true;
  }

  async createVoucher(event) {
    let BookingIds = [];
    let selectedBooking = this.gridOptions.api.getSelectedRows();
    selectedBooking.forEach(booking => {
      BookingIds.push(booking.Id);
    });
    await this.service.SendBookingVoucher(BookingIds).then(result => {
      { this.messageService.addAll([{ severity: 'success', summary: 'Success', detail: "Sent successfully." }]); }
    });
    this.sendVoucherConfirmationDialog = false;
  }

  confirmBooking(event) {
    this.confirmBookingsOptions.api.stopEditing();
    let confirmationMap = new Map();
    this.confirmBookingsOptions.api.forEachNode(node => {
      if (!node.group && node.data.Code != null && node.data.Code != undefined && node.data.Code != "") {
        let confirmations = confirmationMap.get(node.data.Booking.ChannelCode);
        if (confirmations == null) {
          confirmations = new Array();
          confirmationMap.set(node.data.Booking.ChannelCode, confirmations);
        }
        let bookingConfirmation = new BookingConfirmation();
        bookingConfirmation.BookingId = node.data.Booking.Id;
        bookingConfirmation.Code = node.data.Code;
        bookingConfirmation.ConfirmedBy = this.storageService.get(StorageService.loginUserId);
        bookingConfirmation.InDate = node.data.Booking.StayInfo.InDate;
        bookingConfirmation.Status = BookingStatus.Confirmed;
        confirmations.push(bookingConfirmation);
      }
    });
    if (confirmationMap.size > 0) {
      let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
      Array.from(confirmationMap.keys()).forEach(key => {
        if (hotelContext != null && hotelContext.HotelId != null)
          this.service.confirmBookings(hotelContext.HotelId, key, confirmationMap.get(key));

      });
    }
    this.confirmationDialog = false;
  }

  isFilterEnabled = false;
  showFilter = false;
  hideShowFilterCriteria() {
    if (this.isFilterEnabled) {
      this.showFilter = !this.showFilter;
    }
  }
  searchBookingText(event) {
    let filteredResponseTemp = this.filteredResponse;
    this.filteredResponse = [];
    var query = event.target.value.toLowerCase();
    let bookings = this.bookings;
    if (query == "") this.filteredResponse = this.bookings;
    else {
      bookings.forEach(booking => {
        let isMatch = false;
        if (booking["Hotel"].toLowerCase().indexOf(query) >= 0) {
          isMatch = true;
        }
        if (booking["Booking"].Id.indexOf(query) >= 0) {
          isMatch = true;
        }
        if (booking["Booking"].ContactInfo.FirstName.toLowerCase().indexOf(query) >= 0) {
          isMatch = true;
        }
        if (booking["Booking"].ChannelCode && Utilities.findId(Utilities.channelTypes, booking["Booking"].ChannelCode, booking["Booking"].ChannelCode).toLowerCase().indexOf(query) >= 0) {
          isMatch = true;
        }
        if (booking["Booking"].Rooms.length > 0) {
          booking["Booking"].Rooms.forEach(room => {
            if (room.RoomName != null) {
              if (room.RoomName.toLowerCase().indexOf(query) >= 0) isMatch = true;
            }
            else {
              if (room.RoomId.toLowerCase().indexOf(query) >= 0) isMatch = true;
            }
          });
        }
        if (booking["Booking"].Status && Utilities.findId(Utilities.statusMap, booking["Booking"].Status, booking["Booking"].Status).toLowerCase().indexOf(query) >= 0) {
          isMatch = true;
        }
        if (booking["Booking"].PaymentType >= 0 && Utilities.findId(Utilities.paymentStatus, booking["Booking"].PaymentType, booking["Booking"].PaymentType).toLowerCase().indexOf(query) >= 0) {
          isMatch = true;
        }

        if (isMatch) this.filteredResponse.push(booking);
      });
    }
    this.renderBookings();
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
  }
  onBookingFilterChange(filterData) {
    let filterChannels = filterData["channelTypes"];
    let filterPaymentStatus = filterData["paymentStatus"];
    let filterRoomTypes = filterData["roomTypes"];
    if (filterChannels || filterPaymentStatus || filterRoomTypes) {
      this.filterResponse(filterChannels, filterPaymentStatus, filterRoomTypes, this.bookings);
      this.renderBookings();
    }
  }

  onBccBtn(){
    this.bccBtn = true;
  }
}

function formatCreatedOn(param) {
  return param.data.CreatedOn;
}
function formatCheckin(param) {
  return param.data.BasicDetails.CheckIn;
}
function currencyFormatter(param) {
  return param.value.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
}

function createdOnKeyCreator(params) {
  return params.value.key;
}

let datePipe = new DatePipe('en-US');
function createdOnValueGetter(params) {
  if (params.data)
    return { value: params.data.CreatedOn, key: params.data.Booking.CreatedOn.split("T")[0] };
  else
    return params.node.key;
}

function createdOnFormatter(params) {
  if (!params.data) {
    var date = new DatePipe('en-US');
    return date.transform(params.CreatedOn, 'dd-MMM-y, hh:mm:ss aa');
  } else {
    var date = new DatePipe('en-US');
    return date.transform(params.data.CreatedOn, 'dd-MMM-y, hh:mm:ss aa');
  }

}

function checkInKeyCreator(params) {
  return params.value.key;
}

function checkInValueGetter(params) {
  if (params.data && params.data.Booking.StayInfo != null && params.data.Booking.StayInfo.InDate != null)
    return { value: params.data.BasicDetails.CheckIn, key: params.data.Booking.StayInfo.InDate.split("T")[0] };
  else
    return params.node.key;
}

function checkInFormatter(params) {
  if (params.value != null) {
    if (params.value.value)
      return params.value.value;
    else
      return datePipe.transform(new Date(params.value), "dd-MMM-yyyy");
  }
  else return "-";
}

function getRoomName(param) {
  let rooms = param.value;
  if (rooms && rooms.length > 0)
    if (rooms.length > 1) return rooms[0].RoomName + "...";
    else return rooms[0].RoomName;
}

function stringComparator(data1, data2) {
  if (data1 === null && data2 === null || data1 === undefined && data2 === undefined) return 0;
  if (data1 === null || data1 === undefined) return -1;
  if (data2 === null || data2 === undefined) return 1;
  if (data1.trim().toLowerCase() < data2.trim().toLowerCase()) {
    return -1;
  } else if (data1.trim().toLowerCase() > data2.trim().toLowerCase()) {
    return 1;
  } else {
    return 0;
  }
}