import { Component, OnInit, ViewChild, ViewContainerRef, HostListener, ComponentFactoryResolver } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { MenuItem, MessageService } from 'primeng/primeng';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { HotelCriteria, HotelInfo, CatalogOption } from 'mantras-api';
import { AuthenticationService } from '../ui-services/authentication.service';
import { HotelManagementService } from '../ui-services/hotelmanagement.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { StorageService } from '../ui-services/storage.service';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';
import { HotelCatalogDetailsComponent } from '../hotelcatalog/hotelcatalogdetails.component';

@Component({
  selector: 'hotelcatalog',
  templateUrl: './hotelcatalog.component.html',
  styleUrls: ['./hotelcatalog.component.css']
})
export class HotelCatalogComponent implements OnInit {
  hotelCatalog: any;
  columnDefs: any[];
  icons;
  gridOptions: GridOptions;
  rowData: any[];
  rowSelection: any;
  hotelcatalogdetails: any;
  hotelCatalogMenuItems: MenuItem[];
  subscription: Subscription;
  hotelCriteria: HotelCriteria;
  isDetailsPage = false;
  rowGroupPanelShow: any;
  isFilterEnabled = false;

  constructor(private hotelService: HotelManagementService, private messageService: MessageService, private router: Router, private storageService: StorageService, private resolver: ComponentFactoryResolver) {
    this.columnDefs = [
      { headerName: "ID", field: "Id", cellClass: 'gridcol', showRowGroup: true, width: 200, menuTabs: [], sort: 'asc' },
      { headerName: "Hotel Name", field: "Name", width: 200, enableRowGroup: true, menuTabs: [], tooltipField: "Hotel", cellClass: 'gridcol' },
      { headerName: "Street", field: "Address.Street", width: 80, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "City", field: "Address.City", width: 80, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "State", field: "Address.State", width: 80, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Country", field: "Address.Country", width: 80, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Zip", field: "Address.ZipCode", width: 80, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Latitude", field: "Address.GeoCode.Latitude", width: 80, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Longitude", field: "Address.GeoCode.Longitude", width: 80, menuTabs: [], cellClass: 'gridcol' }
    ];
    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    //this.rowGroupPanelShow = "always";
    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      localeText: { noRowsToShow: "No Hotel Catalog(s) found" },
      //sortingOrder: ["desc", "asc"]
    };
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });

  }
  @ViewChild('hotelcatalogcriteria', { read: ViewContainerRef }) hotelcatalogcriteria: ViewContainerRef;
  ngOnInit() {
    this.loadMenu();
  }

  onSearch(event) {
    this.onSearchCriteria(event);
  }

  oldCriteria;
  async onSearchCriteria(criteria?: HotelCriteria, force = false) {
    if (!criteria) {
      let hotelCriteria = new HotelCriteria();
      await this.hotelService.findHotels(hotelCriteria, force).then(hotelList => {
        this.gridOptions.api.setRowData(hotelList);
        this.gridOptions.api.sizeColumnsToFit();
        this.onGridReady(this);
        this.setHeight();
        if (hotelList.length > 1) {
          this.isFilterEnabled = true;
        }
        else {
          this.isFilterEnabled = false;
        }
        if(hotelList.length > 0){
          this.showCriteria = false;
        }
      });
    } else {
      this.hotelCriteria = criteria;
      this.oldCriteria = criteria;
      this.hotelcatalogdetails = await this.hotelService.findHotels(criteria, force).then(hotelList => {
        this.hotelCatalog = hotelList;
        this.gridOptions.api.setRowData(hotelList);
        this.gridOptions.api.sizeColumnsToFit();
        this.onGridReady(this);
        this.setHeight();
        if (hotelList.length > 1) {
          this.isFilterEnabled = true;
        }
        else {
          this.isFilterEnabled = false;
        }
        // if(hotelList.length > 0){
        //   this.showCriteria = false;
        // }
      });
    }
    this.loadMenu();
  }

  loadMenu() {
    this.hotelCatalogMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', command: (event) => this.createNew(event) },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', disabled: true, command: (event) => this.open(event) },
      { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: true, visible: this.isCurrentUserAdmin(), command: (event) => this.confirmDelete(event) },
      { title: 'Export to excel', label: 'Export', icon: 'action-bar-menu-icon fa fa-file-excel-o', disabled: true, command: (event) => this.onExport() },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
    ];
  }

  onRowClick(params) {
    true
    if (params.data) {
      this.hotelCatalogMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
        { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', command: (event) => this.createNew(event) },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
        { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', visible: this.isCurrentUserAdmin(), command: (event) => this.confirmDelete(event, true) },
        { title: 'Export to excel', label: 'Export', icon: 'action-bar-menu-icon fa fa-file-excel-o', command: (event) => this.onExport() },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
      ];
    }
  }

  onRefresh(event) {
    this.onSearchCriteria(event);
  }

  //Create New Domain
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  detailComponent;
  isNew = false;
  createNew(data?) {
    this.container.clear();
    let factory = this.resolver.resolveComponentFactory(HotelCatalogDetailsComponent);
    this.detailComponent = <HotelCatalogDetailsComponent>this.container.createComponent(factory).instance;
    if (data.Id) {
      this.detailComponent.hotelcatalogdetails = data;
      this.isNew = false;
    } else {
      this.detailComponent.hotelcatalogdetails = new HotelInfo();
      this.detailComponent.hotelcatalogdetails.Address = [];
      this.isNew = true;
    }
    this.detailComponent.loadData(data);
    this.detailComponent.onClose.subscribe(this.onDetailsClose);
    this.isDetailsPage = true;
    this.detailComponent.setHeight();
  }

  open(event) {
    this.createNew(this.gridOptions.api.getSelectedRows()[0]);
  }
  @ViewChild('showConfirmationDialog') showConfirmationDialog;
  showDeleteConfirmation = false;
  deleteId;
  confirmDelete(params, fromGrid?) {
    if (!fromGrid) {
      if (params.node.data) {
        this.deleteId = params.node.data.Id;
        this.showDeleteConfirmation = true;
      }
    } else {
      let selectedRow = this.gridOptions.api.getSelectedRows();
      if (selectedRow) {
        this.deleteId = selectedRow[0].Id;
        this.showDeleteConfirmation = true;
      }
    }
  }

  closeDialog(event) {
    this.deleteId = null;
    this.showDeleteConfirmation = false;
    this.setHeight();
  }

  async delete(event) {
    this.showDeleteConfirmation = false;
    if (this.deleteId != null) {
      var resp = await this.hotelService.deleteHotel(this.deleteId).then(res => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: "Deleted successfully." });
      });
    }
    this.onRefresh(this.hotelCriteria);
  }

  async onExport() {
    let options = CatalogOption.HotelMapping | CatalogOption.ChannelCatalog;
    await this.hotelService.saveFile(this.hotelCriteria, options);
    //this.messageService.add({ severity: 'success', summary: 'Success', detail: "Exported successfully." });
  }
  onClose() {
    this.router.navigate(["/settings"]);
  }

  isCurrentUserAdmin() {
    let currentUser = this.storageService.get(StorageService.currentUser);
    if (currentUser.User.Roles.includes("Admins")) {
      return true;
    } else {
      return false;
    }
  }

  filteredResponse: any[];
  searchHotelCatalogText(event) {
    this.filteredResponse = [];
    var query = event.target.value.toLowerCase();
    let hotelCatalogs = this.hotelCatalog;
    if (query == "") this.filteredResponse = this.hotelCatalog;
    else {
      hotelCatalogs.forEach(hotelCatalog => {
        let isMatch = false;
        if (hotelCatalog["Id"].indexOf(query) >= 0) {
          isMatch = true;
        }
        if (hotelCatalog["Address"].City.toLowerCase().indexOf(query) >= 0) {
          isMatch = true;
        }
        if (hotelCatalog["Address"].Country.toLowerCase().indexOf(query) >= 0) {
          isMatch = true;
        }
        if (hotelCatalog["Address"].Street != null) {
          if (hotelCatalog["Address"].Street.toLowerCase().indexOf(query) >= 0) {
            isMatch = true;
          }
        }
        if (hotelCatalog["Address"].State.toLowerCase().indexOf(query) >= 0) {
          isMatch = true;
        }
        // if (hotelCatalog["Address"].ZipCode.indexOf(query) >= 0) {
        //   isMatch = true;
        // }
        if (hotelCatalog["Name"].toLowerCase().indexOf(query) >= 0) {
          isMatch = true;
        }
        // if (hotelCatalog["Address"].GeoCode != null) {
        //   if (hotelCatalog["Address"].GeoCode.Latitude != null) {
        //     if (hotelCatalog["Address"].GeoCode.Latitude.indexOf(query) >= 0) {
        //       isMatch = true;
        //     }
        //   }
        //   if (hotelCatalog["Address"].GeoCode.Longitude != null) {
        //     if (hotelCatalog["Address"].GeoCode.Longitude.indexOf(query) >= 0) {
        //       isMatch = true;
        //     }
        //   }
        // }
        if (isMatch) this.filteredResponse.push(hotelCatalog);
      });
    }
    this.renderhoelCatalog();

  }

  renderhoelCatalog() {
    this.rowGroupPanelShow = "always";
    this.isDetailsPage = false;
    this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true))
    this.gridOptions.api.setColumnDefs(this.columnDefs);
    this.gridOptions.api.setRowData(this.filteredResponse);
    this.gridOptions.api.sizeColumnsToFit();
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }


  // onDetailsClose = async (event) => {
  //   this.isDetailsPage = false;
  //   if (this.isNew) {
  //     let hotelCriteria = new HotelCriteria();
  //     hotelCriteria.Ids = [event.Id];
  //     this.onRefresh(hotelCriteria);
  //   }
  //   else{
  //     this.onRefresh( this.oldCriteria);
  //   }
  //   this.setHeight();
  // }

  onDetailsClose = async (event) => {
    this.isDetailsPage = false;
  }

  showCriteria = true;
  hideShowCriteria() {
    this.showCriteria = !this.showCriteria;
    this.setHeight();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true));
  }

  onClickGridRow(params) {
    if (params.data)
      this.createNew(params.data);
  }

  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }
  onGridSizeChanged($event) {
    this.gridOptions.api.sizeColumnsToFit();
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
