import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { map } from 'rxjs/operators'
export class Notification{
    id:number;
    type:string;
    message:string;
    severity:string;
    action:string;
    timestamp:string;
    dueTimestamp:string;
    isSeen:string;
    img:string;
    status:string
}
@Injectable()
export class NotificationService{
    tempArray : Notification[] = [];
    imagetype;
    constructor(private http: Http){
        this.tempArray = [
            {"id":1,"type":"Price Recommendation","message":"Increase base rate by 6%(Rs. 3411). You can do this by clicking on the rate calendar. ","severity":"high","action":"","timestamp": new Date().toUTCString() ,"dueTimestamp":"2019-02-01T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-recommendation-icon.png","status":null},
            {"id":2,"type":"Competitor price change","message":"Hotel Regina reduced base rate by 5% for Fri, Jan 25. Click Approve to increase the rate and publish it to extranet.","severity":"low","action":"","timestamp":"2019-02-01T09:24:29.5570574Z","dueTimestamp":"2019-02-01T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-change-icon.png","status":null},
            {"id":3,"type":"Channel Follow-up Alert","message":"MakeMyTrip reduced sales by 15% for Fri, Jan 25","severity":"medium","action":"","timestamp":"2019-03-11T09:24:29.5570574Z","dueTimestamp":"2019-03-11T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/follow-up-icon.png","status":null},
            {"id":4,"type":"Price Recommendation","message":"Increase base rate by 6%(Rs. 3411) ","severity":"high","action":"","timestamp":"2019-02-01T05:34:29.5570574Z","dueTimestamp":"2019-02-01T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-recommendation-icon.png","status":null},
            {"id":5,"type":"Competitor price change","message":"Hotel Regina reduced base rate by 5% for Fri, Jan 25","severity":"low","action":"","timestamp":"2019-01-21T07:11:29.5570574Z","dueTimestamp":"2019-01-21T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-change-icon.png","status":null},
            {"id":6,"type":"Channel Follow-up Alert","message":"MakeMyTrip reduced sales by 15% for Fri, Jan 25","severity":"medium","action":"","timestamp":"2019-02-11T15:45:29.5570574Z","dueTimestamp":"2018-11-11T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/follow-up-icon.png","status":null},
            {"id":7,"type":"Price Recommendation","message":"Increase base rate by 6%(Rs. 3411) ","severity":"high","action":"","timestamp":"2019-02-01T03:01:29.5570574Z","dueTimestamp":"2019-02-01T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-recommendation-icon.png","status":null},
            {"id":8,"type":"Competitor price change","message":"Hotel Regina reduced base rate by 5% for Fri, Jan 25","severity":"low","action":"","timestamp":"2019-01-21T09:24:29.5570574Z","dueTimestamp":"2019-01-21T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-change-icon.png","status":null},
            {"id":9,"type":"Channel Follow-up Alert","message":"MakeMyTrip reduced sales by 15% for Fri, Jan 25","severity":"medium","action":"","timestamp":"2019-01-11T19:47:29.5570574Z","dueTimestamp":"2018-11-11T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/follow-up-icon.png","status":null},
            {"id":10,"type":"Price Recommendation","message":"Increase base rate by 6%(Rs. 3411) ","severity":"high","action":"","timestamp":"2018-11-11T09:24:29.5570574Z","dueTimestamp":"2018-11-11T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-recommendation-icon.png","status":null},
            {"id":11,"type":"Competitor price change","message":"Hotel Regina reduced base rate by 5% for Fri, Jan 25","severity":"low","action":"","timestamp":"2019-01-21T09:24:29.5570574Z","dueTimestamp":"2019-01-21T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-change-icon.png","status":null},
            {"id":12,"type":"Channel Follow-up Alert","message":"MakeMyTrip reduced sales by 15% for Fri, Jan 25","severity":"medium","action":"","timestamp":"2018-11-11T09:24:29.5570574Z","dueTimestamp":"2018-11-11T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/follow-up-icon.png","status":null},
            // {"type":"Price Recommendation","message":"Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411)","severity":"high","action":"","timestamp":"2018-11-11T09:24:29.5570574Z","dueTimestamp":"2018-11-11T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-recommendation-icon.png"},
            // {"type":"Channel Follow-up Alert","message":"MakeMyTrip reduced sales by 15% for Fri, Jan 25 MakeMyTrip reduced sales by 15% for Fri, Jan 25 ","severity":"medium","action":"","timestamp":"2019-01-31T09:24:29.5570574Z","dueTimestamp":"2019-01-31T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/follow-up-icon.png"},
            // {"type":"Competitor price change","message":"Hotel Regina reduced base rate by 5% for Fri, Jan 25","severity":"low","action":"","timestamp":"2019-01-21T09:24:29.5570574Z","dueTimestamp":"2019-01-21T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-change-icon.png"},
            // {"type":"Price Recommendation","message":"Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411)","severity":"high","action":"","timestamp":"2019-01-01T09:24:29.5570574Z","dueTimestamp":"2019-01-01T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-recommendation-icon.png"},
            // {"type":"Channel Follow-up Alert","message":"MakeMyTrip reduced sales by 15% for Fri, Jan 25 MakeMyTrip reduced sales by 15% for Fri, Jan 25 ","severity":"medium","action":"","timestamp":"2019-01-04T09:24:29.5570574Z","dueTimestamp":"2019-01-04T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/follow-up-icon.png"},
            // {"type":"Competitor price change","message":"Hotel Regina reduced base rate by 5% for Fri, Jan 25","severity":"low","action":"","timestamp":"2019-01-21T09:24:29.5570574Z","dueTimestamp":"2019-01-21T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-change-icon.png"},
            // {"type":"Price Recommendation","message":"Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411)","severity":"high","action":"","timestamp":"2019-01-06T09:24:29.5570574Z","dueTimestamp":"2019-01-06T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-recommendation-icon.png"},
            // {"type":"Channel Follow-up Alert","message":"MakeMyTrip reduced sales by 15% for Fri, Jan 25 MakeMyTrip reduced sales by 15% for Fri, Jan 25 ","severity":"medium","action":"","timestamp":"2019-01-16T09:24:29.5570574Z","dueTimestamp":"2019-01-16T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/follow-up-icon.png"},
            // {"type":"Competitor price change","message":"Hotel Regina reduced base rate by 5% for Fri, Jan 25","severity":"low","action":"","timestamp":"2019-01-21T09:24:29.5570574Z","dueTimestamp":"2019-01-21T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-change-icon.png"},
            // {"type":"Price Recommendation","message":"Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411)","severity":"high","action":"","timestamp":"2019-01-11T09:24:29.5570574Z","dueTimestamp":"2019-01-11T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-recommendation-icon.png"},
            // {"type":"Channel Follow-up Alert","message":"MakeMyTrip reduced sales by 15% for Fri, Jan 25 MakeMyTrip reduced sales by 15% for Fri, Jan 25 ","severity":"medium","action":"","timestamp":"2018-01-26T09:24:29.5570574Z","dueTimestamp":"2018-01-26T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/follow-up-icon.png"},
            // {"type":"Competitor price change","message":"Hotel Regina reduced base rate by 5% for Fri, Jan 25","severity":"low","action":"","timestamp":"2019-01-21T09:24:29.5570574Z","dueTimestamp":"2019-01-21T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-change-icon.png"},
            // {"type":"Price Recommendation","message":"Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411)","severity":"high","action":"","timestamp":"2019-01-15T09:24:29.5570574Z","dueTimestamp":"2019-01-15T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-recommendation-icon.png"},
            // {"type":"Channel Follow-up Alert","message":"MakeMyTrip reduced sales by 15% for Fri, Jan 25 MakeMyTrip reduced sales by 15% for Fri, Jan 25 ","severity":"medium","action":"","timestamp":"2019-01-21T09:24:29.5570574Z","dueTimestamp":"2019-01-21T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/follow-up-icon.png"},
            // {"type":"Competitor price change","message":"Hotel Regina reduced base rate by 5% for Fri, Jan 25","severity":"low","action":"","timestamp":"2019-01-21T09:24:29.5570574Z","dueTimestamp":"2019-01-21T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-change-icon.png"},
            // {"type":"Price Recommendation","message":"Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411)","severity":"high","action":"","timestamp":"2019-01-19T09:24:29.5570574Z","dueTimestamp":"2019-01-19T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-recommendation-icon.png"},
            // {"type":"Channel Follow-up Alert","message":"MakeMyTrip reduced sales by 15% for Fri, Jan 25 MakeMyTrip reduced sales by 15% for Fri, Jan 25 ","severity":"medium","action":"","timestamp":"2019-01-18T09:24:29.5570574Z","dueTimestamp":"2019-01-18T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/follow-up-icon.png"},
            // {"type":"Competitor price change","message":"Hotel Regina reduced base rate by 5% for Fri, Jan 25","severity":"low","action":"","timestamp":"2019-01-21T09:24:29.5570574Z","dueTimestamp":"2019-01-21T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-change-icon.png"},
            // {"type":"Price Recommendation","message":"Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411)","severity":"high","action":"","timestamp":"2019-01-22T09:24:29.5570574Z","dueTimestamp":"2019-01-22T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-recommendation-icon.png"},
            // {"type":"Channel Follow-up Alert","message":"MakeMyTrip reduced sales by 15% for Fri, Jan 25 MakeMyTrip reduced sales by 15% for Fri, Jan 25 ","severity":"medium","action":"","timestamp":"2019-01-14T09:24:29.5570574Z","dueTimestamp":"2019-01-14T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/follow-up-icon.png"},
            // {"type":"Competitor price change","message":"Hotel Regina reduced base rate by 5% for Fri, Jan 25","severity":"low","action":"","timestamp":"2019-01-21T09:24:29.5570574Z","dueTimestamp":"2019-01-21T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-change-icon.png"},
            // {"type":"Price Recommendation","message":"Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411)","severity":"high","action":"","timestamp":"2019-01-26T09:24:29.5570574Z","dueTimestamp":"2019-01-26T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-recommendation-icon.png"},
            // {"type":"Channel Follow-up Alert","message":"MakeMyTrip reduced sales by 15% for Fri, Jan 25 MakeMyTrip reduced sales by 15% for Fri, Jan 25 ","severity":"medium","action":"","timestamp":"2019-01-11T09:24:29.5570574Z","dueTimestamp":"2019-01-11T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/follow-up-icon.png"},
            // {"type":"Competitor price change","message":"Hotel Regina reduced base rate by 5% for Fri, Jan 25","severity":"low","action":"","timestamp":"2019-01-21T09:24:29.5570574Z","dueTimestamp":"2019-01-21T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-change-icon.png"},
            // {"type":"Price Recommendation","message":"Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411) Mon, Jan 21 : Increase base rate by 6%(Rs. 3411)","severity":"high","action":"","timestamp":"2019-01-29T09:24:29.5570574Z","dueTimestamp":"2019-01-29T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-recommendation-icon.png"},
            // {"type":"Channel Follow-up Alert","message":"MakeMyTrip reduced sales by 15% for Fri, Jan 25 MakeMyTrip reduced sales by 15% for Fri, Jan 25 ","severity":"medium","action":"","timestamp":"2019-01-26T09:24:29.5570574Z","dueTimestamp":"2019-01-26T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/follow-up-icon.png"},
            // {"type":"Competitor price change","message":"Hotel Regina reduced base rate by 5% for Fri, Jan 25","severity":"low","action":"","timestamp":"2019-01-21T09:24:29.5570574Z","dueTimestamp":"2019-01-08T09:24:29.5570574Z","isSeen":"-1","img":"../../assets/img/price-change-icon.png"}
    ];
    this.imagetype = [
        {"type":"Header","img-src":"src\assets\img\ratecalendar.png"},
        {"type":"Header","img-src":"src\assets\img\bookings.png"}
    ]
    }
    getCount(){
        let count = 0;
        this.tempArray.forEach(e => {
            if(e.isSeen == "-1") count++;
        })
        return count;
    }
    getData(){
        // this.http.get('https://api.myjson.com/bins/pa9ps').subscribe(
        //     data => {
        //         if(data){
        //             data.foreach(e = {
                        
        //             })    
        //             }
        //         },
        //     error => {

        //     }
        // )
            //change index after sort
        this.tempArray = this.tempArray.sort((val1, val2)=> {return <any>new Date(val2.timestamp) - <any>new Date(val1.timestamp)});
        let count=0;
        this.tempArray.forEach((temp)=>{
          temp["index"] = count;
          count++;
        }
        );
        return this.tempArray;
    }
    getPostLoginMessages(){
        return this.http.get("assets/Notifications.json")
                        .pipe(map((response: any) => response.json()));
                        
    }
    isClosed(i){
        this.tempArray[i].isSeen="0";
        return this.tempArray;
    }
    isRejected(id){
        this.tempArray.forEach((temp)=>{
            if(temp.id == id){
                temp.isSeen="0";
            }
        });
        return this.tempArray;
    }
    isApproved(id){
        this.tempArray.forEach((temp)=>{
            if(temp.id == id){
                temp.isSeen="1";
            }
        });
        return this.tempArray;
    }
    getImageType(){
        return this.imagetype;
    }
}

export class NotificationSettings{
    IsEnabled: boolean;
    EmailIds: string[];
    FrequencyDays: number; //Float Number of days
    RateInventoryNotificationEnabled:boolean;
    RateInventoryNotification: RateInventoryNotification;
    BookingNotificationEnabled:boolean;
    BookingNotification:BookingNotification;
    RevenueManagementNotificationEnabled:boolean;
    RevenueManagementNotification: RevenueManagementNotification;
    AnalyticsNotificationEnabled:boolean;
    AnalyticsNotification:AnalyticsNotification;
}

export class RateInventoryNotification {
    RateLevelEnabled: boolean;
    InventoryLevelEnable:boolean;
    MissingRateInventroyEnable:boolean;
    RateLevelConfig:Map<string,string>;
    InventoryLevelConfig:Map<string,string>;
    MissingRateInventoryConfig:Map<string,string>;
}
export class BookingNotification {
    StatusChangeCheckEnabled: boolean;
    StatusChangeCheckConfig:Map<string,string>;
    DailySummaryEnabled:boolean;
}

export class RevenueManagementNotification {
    PriceRecommendationEnabled: boolean;
    PriceRecommendationConfig:Map<string,string>;
    ChannelIssuesEnabled:boolean;
    ChannelIssuesConfig:Map<string,string>;
    PaceDifferentialEnabled:boolean;
    PaceDifferentialConfig:Map<string,string>;
    CompIndexLevelEnabled:boolean;
    CompIndexLevelConfig:Map<string,string>;
}

export class AnalyticsNotification {
    RateParityCheckEnabled: boolean;
    RateParityCheckConfig:Map<string,string>;
    MissingChannelInventoryEnabled:boolean;
    MissingChannelInventoryConfig:Map<string,string>;
    CompIndexEnabled:boolean;
    CompIndexConfig:Map<string,string>;
    CompPriceChangeEnable:boolean;
    CompPriceChangeConfig:Map<string,string>;
}

export class NotificationEventRule
{
    DomainId:string;
    HotelId:string;
    Id:string;
    Name:string;
    Description:string;
    IsActive:boolean;
    Category:EventCategory;

    Conditions:Condition[];
    Actions:any [];
    ValidConditions:Condition[];
}

export class Function
{
    FuncName:string;
    Args:Arg[];
}
export class Condition extends Function
{
    EventType:number;
    OpType:RuleOpType;
    Tags:any;
}
export class Arg
{
    Name:string
    Type:string;
    Value:any;
}

export enum RuleOpType { And = 1, Or = 2 }
export enum EventCategory { RateCalendar = 1, Booking = 2, ChanelRates = 3, Revenue = 4, Scheduled = 99 }