import {AfterViewInit, Component, ViewChild, ViewContainerRef, ElementRef} from "@angular/core";

import {ICellEditorAngularComp} from "ag-grid-angular";

@Component({
    selector: 'numeric-cell',
    template: `<div class="ui-g-12 ui-g-nopad" #container >
    <div><input #perDayRate type="number" min="1000" max="15000" style="width:100%" (keydown)="onKeyDown($event)" [(ngModel)]="perDay" /></div>
    <div><input #singleRate type="number" min="1000" max="20000" style="width:100%" (keydown)="onKeyDown($event)" [(ngModel)]="single" /></div>
    <div><input #doubleRate type="number" min="1000" max="25000" style="width:100%" (keydown)="onKeyDown($event)" [(ngModel)]="double" /></div></div>`
})
export class RateCalendarEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private rateCalParam: any;
    public single: number;
    public perDay: number;
    public double: number;
    private cancelBeforeStart: boolean = false;

    @ViewChild('container') container; 
    @ViewChild('perDayRate') perDayRate: ElementRef;
    @ViewChild('singleRate') singleRate: ElementRef;
    @ViewChild('doubleRate') doubleRate: ElementRef;


    agInit(params: any): void {
        console.log("Inside AgInit value: " + params.value);
        this.rateCalParam = params;
        this.perDay = this.rateCalParam.value[params.column.colId].RoomRates[1];
        this.single = this.rateCalParam.value[params.column.colId].RoomRates[2];
        this.double = this.rateCalParam.value[params.column.colId].RoomRates[3];
    }

    getValue(): any {
        console.log("Inside getValue()");
        this.rateCalParam.value[this.rateCalParam.column.colId].RoomRates[1] = this.perDayRate.nativeElement.value;
        this.rateCalParam.value[this.rateCalParam.column.colId].RoomRates[2] = this.singleRate.nativeElement.value;
        this.rateCalParam.value[this.rateCalParam.column.colId].RoomRates[3] = this.doubleRate.nativeElement.value;
        return this.rateCalParam.value;
    }

    isCancelBeforeStart(): boolean {
        return this.cancelBeforeStart;
    }

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    isCancelAfterEnd(): boolean {
        return this.perDay > 1000000;
    };

    onKeyDown(event): void {
       //TODO : Might need to implement this method
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
        console.log("In ngAfterViewInit method");
    }

    private getCharCodeFromEvent(event): any {
        event = event || window.event;
        return (typeof event.which == "undefined") ? event.keyCode : event.which;
    }

    private isCharNumeric(charStr): boolean {
        return !!/\d/.test(charStr);
    }

    private isKeyPressedNumeric(event): boolean {
        const charCode = this.getCharCodeFromEvent(event);
        const charStr = event.key ? event.key : String.fromCharCode(charCode);
        return this.isCharNumeric(charStr);
    }
}