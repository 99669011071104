import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../ui-services/authentication.service';
import { Router } from '@angular/router';
import { Message } from 'primeng/components/common/message';
import {FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { StorageService } from '../ui-services/storage.service';
import { LoadingService } from '../ui-services/loading.service';
import * as Crypt from 'crypt';
import { Utilities } from '../ui-services/utilities';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  // projectForm: FormGroup;
  msgs: Message[];
  emailInput;
  passwordInput;
  pageLoaded = false;
  constructor(private fb: FormBuilder,
  private authService: AuthenticationService,
  private router: Router,
  private storageService:StorageService, private loadingService:LoadingService) { 
    this.pageLoaded =false;
  }

  ngOnInit() {
    // this.projectForm = this.fb.group({
    //   username: ['', [Validators.required, Validators.minLength(5)]],
    //   password: ['', [Validators.required, Validators.minLength(2)]]
    // });

    this.storageService.set(StorageService.postLoginMessageDisplayed,false);
    this.msgs = new Array<Message>();
    var parseURL = this.router.parseUrl(this.router.url)
    var userName;
    var passwd;
    var auth = parseURL.queryParams["a"];
    if(auth){
      auth = Utilities.bytesToString(Crypt.base64ToBytes(auth));
      var split = auth.split(":");
      if(split && split.length == 2){
        userName = split[0];
        passwd = split[1];
      }
    }
    if(userName && passwd){
      //this.loadingService.showLoading = true;
      this.loginToPortal(userName,passwd,true);
    } else {
      this.pageLoaded = true;
    }
  }

  // hasFormErrors() {
  //   return !this.projectForm.valid;
  // }
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required
  ]);
  matcher = new MyErrorStateMatcher();
  login() {
    this.loginToPortal(this.emailInput,this.passwordInput);
  }

  loginToPortal(userName,password,isPasswordEncrypted=false) {
    this.authService.login(userName, password, isPasswordEncrypted).then(
      result => {
        //Handling to capure System User
        if(result != null && result.DomainContext.CurrentDomainId.toLowerCase() == "system"){
          this.msgs = new Array();
          this.msgs.push({severity:'error', summary:'User has only System access!!!'});
        } else { 
          let returnUrl = this.router.parseUrl(this.router.url).queryParams["returnUrl"];
          if(!returnUrl) returnUrl = "/"
          this.router.navigate([returnUrl]);
        }
      }
    ).catch(
      result => {console.log("Exception occurred: " + result);
      this.msgs = new Array();
      this.msgs.push({severity:'error', summary:'Invalid credentials.'});
      }
    );
  }

}