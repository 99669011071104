import { AppService, TaxPolicy, Range, TaxApplicationType, ValueType } from "mantras-api";
import { HotelManagementService } from '../../ui-services/hotelmanagement.service';

export class TaxPolicyUtils {
    defaultTaxPolicy: TaxPolicy[];

    constructor(private hotelManagementService: HotelManagementService) { }

    public async createCountrySpecificTaxPolicy(countryCode: string, userId: string, domainId: string, hotelId: string) {
        if (countryCode == "IN") {
            this.defaultTaxPolicy = [];
            // Updated Tax Policies as per resolution passed from 1st Oct 2019.
            this.defaultTaxPolicy.push(this.createGstSlab(0, 0, 1000, userId, domainId, hotelId));
            this.defaultTaxPolicy.push(this.createGstSlab(12, 1001, 7500, userId, domainId, hotelId));
            this.defaultTaxPolicy.push(this.createGstSlab(18, 7501, 70000000.00, userId, domainId, hotelId));

            return this.defaultTaxPolicy;
        }
    }
    public async saveTaxPolicy(domainId,hotelId){
        await this.hotelManagementService.saveTaxpolicies(domainId,hotelId,this.defaultTaxPolicy);
    }
    createGstSlab(taxPercent: number, minAmt: number, maxAmt: number, userId: string, domainId: string, hotelId: string) {
        let taxPolicy = new TaxPolicy();
        taxPolicy.AmountRange = new Range<number>();
        taxPolicy.AmountRange.Start = minAmt;
        taxPolicy.AmountRange.End = maxAmt;

        taxPolicy.ApplicationType = TaxApplicationType.Ovverride;
        taxPolicy.CreatedBy = userId;
        taxPolicy.CreatedOn = new Date();
        taxPolicy.DomainId = domainId;
        taxPolicy.HotelId = hotelId;
        taxPolicy.IsActive = true;
        taxPolicy.LastModifiedBy = userId;
        taxPolicy.LastModifiedOn = new Date();
        taxPolicy.Name = "GST(" + taxPercent + "%)";
        taxPolicy.TaxType = ValueType.Percent;
        taxPolicy.ValidityPeriod = new Range<Date>();
        taxPolicy.ValidityPeriod.Start = new Date();
        taxPolicy.ValidityPeriod.End = new Date(2099, 11, 31);
        taxPolicy.Value = taxPercent;
        return taxPolicy;
    }
}