import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mm-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.css']
})
export class ProgressbarComponent implements OnInit {

  constructor() { }

  @Input("current") current:number;
  @Input("target") target:number;
  @Input("actual") actual:number;
  @Input("targetlabel") targetLabel;
  @Input("currentlabel") currentLabel;
  @Input("color") color;
  @Input("minval") minval =0;
  @Input("style") style;
  currPercent;
  tarPercent;
  actPercent;
  ngOnInit() {
    this.tarPercent = 75;
    this.currPercent = this.current/this.target * 75;
    if(this.current/this.target > 4/3)
      this.currPercent = 100;
    this.actPercent = this.actual/this.target * 75;
    if(this.actual/this.target > 4/3)
      this.actPercent = 100;
  }

  SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

  abbreviateNumber(number){

    // what tier? (determines SI symbol)
    let tier = Math.log10(number) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier == 0){
      if(number % 0 == 0)
        return number;
      else return number.toFixed(2);
    }
    // get suffix and determine scale
    let suffix = this.SI_SYMBOL[tier];
    let scale = Math.pow(10, tier * 3);

    // scale the number
    let scaled = number / scale;

    // format number and add suffix
    //Skip Zeros:
    if((parseFloat(scaled.toFixed(1))*10) % 10 == 0)
      return scaled.toFixed(0) + suffix;
    else 
      return scaled.toFixed(1) + suffix;
  }

  getCurrentLabelPosition(){
    if(this.currPercent < 95)
      return "calc("+this.currPercent +"% - "+ this.currentLabel.length/2*6 +"px)";
    else
      return "calc(100% - "+ this.currentLabel.length*6 +"px)";
  }

  getTargetLabelPosition() {
    if(this.tarPercent < 95)
      return "calc("+this.tarPercent +"% - "+ this.targetLabel.length/2*6 +"px)";
    else
      return "calc(100% - "+ this.targetLabel.length*6 +"px)";
  }

  getCurrentPercentage(){
    this.ngOnInit();
    return this.currPercent+"%";
  }
  getTargetPercentage(){
    this.ngOnInit();
    return this.tarPercent+"%";
  }
  getActualPercentage(){
    this.ngOnInit();
    return this.actPercent+"%";
  }
}
