import { Component, OnInit, Output, EventEmitter, Input, HostListener, AfterViewInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { BookingService, FormattedBooking } from "../ui-services/booking.service";
import { BookingCriteria, DomainProfileCriteria, HotelBooking, HotelCriteria } from 'mantras-api';
import { StorageService } from '../ui-services/storage.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Utilities } from '../ui-services/utilities';
import { forEach } from '@angular/router/src/utils/collection';
import { ButtonModule, MenuItem, AccordionModule, Accordion, Fieldset, MessageService } from 'primeng/primeng';

import * as jQuery from 'jquery';
import { AuthenticationService } from '../ui-services/authentication.service';
import { HotelManagementService } from '../ui-services/hotelmanagement.service';

@Component({
  selector: 'bookingdetails',
  templateUrl: './bookingdetails.component.html',
  styleUrls: ['./bookingdetails.component.css'],
  providers: [BookingService],
})

export class BookingDetails implements OnInit, AfterViewInit {
  @Output('onClose') onChange: EventEmitter<any>;
  @Input('selectedBooking') selectedBooking: FormattedBooking;
  @Input('filteredResponse') filteredResponse: any[];
  bookingMenuItems: MenuItem[];
  disableMail: boolean;
  activeRooms = [0];
  constructor(private messageService: MessageService,private service: BookingService, private storageService: StorageService,private hotelService: HotelManagementService) {
    this.onChange = new EventEmitter();
  }
  onCloseDetailsPage($event) {
    this.onChange.emit();
  }

  @ViewChild("rooms") rooms: Accordion;
  @ViewChild("bookingTags") bookingTags;
  onPrintClick(event) {
    this.rooms.tabs.forEach(tab => {
      try {
        if (!tab.selected) tab.toggle(event);
      } catch (e) { //NO ERROR IS RAISED
      }
    });
    try {
      if (this.bookingTags) this.bookingTags.expand(event);
    } catch (e) { }
    window.setTimeout(this.openPrintDialog, 1000);
  }

  openPrintDialog() {
    let printContents, popupWin, printHeaderContent;
    printHeaderContent = document.getElementById("bookingHeader").outerHTML;
    printContents = document.getElementById("printable").innerHTML;
    let headContent = document.getElementsByTagName('head')[0].innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`<html><head>
      ${headContent}
      <style>@media print {
        body {-webkit-print-color-adjust: exact;}
        }</style
  </head><body onload="window.print();window.close()">${printHeaderContent}<div>${printContents}</div></body>
    </html>`);
    popupWin.document.close();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true, false, false, false, 30));
  }

  ngOnInit(): void {
    // this.bookingMenuItems = [
    //   //{ title: 'Save', label: 'Save', icon: 'fa fa-floppy-o',disabled:true,visible:false },
    //   { title: 'Print', label: 'Print', icon: 'action-bar-menu-icon fa fa-print', command: (event) => this.onPrintClick(event) },
    //   { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCloseDetailsPage(event) },
    //   { title: 'Previous', label: 'Previous', icon: 'action-bar-menu-icon fa fa-arrow-left',disabled:false,command:(event) => this.onPrevious() },
    //   { title: 'Next', label: 'Next', icon: 'action-bar-menu-icon fa fa-arrow-right', disabled: false, command: (event) => this.onNext() },
    //   { title: 'SyncPMS', label: 'SyncPMS', icon: 'action-bar-menu-icon fa icon ion-md-sync', disabled: this.pmsStatus, command: (event) => this.onSyncPMS() },

    // ];
    if (!this.selectedBooking) {
      this.selectedBooking = new FormattedBooking();
    }
    this.loadHeaderOptions();
    this.setHeight();
  }

  loadHeaderOptions() {
    if (this.selectedBooking.PushedToPMS == "NO") {
      this.pmsStatus = false;
    } else {
      this.pmsStatus = true;
    }
    this.disableMail = true;
    this.bookingMenuItems = [
      //{ title: 'Save', label: 'Save', icon: 'fa fa-floppy-o',disabled:true,visible:false },
      { title: 'Print', label: 'Print', icon: 'action-bar-menu-icon fa fa-print', command: (event) => this.onPrintClick(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCloseDetailsPage(event) },
      { title: 'Previous', label: 'Previous', icon: 'action-bar-menu-icon fa fa-arrow-left', disabled: false, command: (event) => this.onPrevious() },
      { title: 'Next', label: 'Next', icon: 'action-bar-menu-icon fa fa-arrow-right', disabled: false, command: (event) => this.onNext() },
      { title: 'SyncPMS', label: 'SyncPMS', icon: 'action-bar-menu-icon fa icon ion-md-sync', disabled: this.pmsStatus, command: (event) => this.syncPMSConfirmation(), visible: this.isCurrentUserAdmin() },
      { title: 'Email', label: 'Email', icon: 'action-bar-menu-icon fa fa-envelope-o', command: (event) => this.sendVoucherConfirmation(), visible: this.isCurrentUserAdmin()},
    ];
  }

  pmsStatus;
  onNext() {
    let index = this.filteredResponse.findIndex(x => x.Id == this.selectedBooking.Id);
    if (index + 1 == this.filteredResponse.length) {
      this.selectedBooking = new FormattedBooking();
      this.selectedBooking = this.filteredResponse[0];
    }
    else {
      this.selectedBooking = new FormattedBooking();
      this.selectedBooking = this.filteredResponse[index + 1];
    }
    if (this.selectedBooking.PushedToPMS == "NO") {
      this.pmsStatus = false;
      this.loadHeaderOptions();
    } else {
      this.pmsStatus = true;
      this.loadHeaderOptions();
    }
  }

  onPrevious() {
    let index = this.filteredResponse.findIndex(x => x.Id == this.selectedBooking.Id);
    if (index == 0) {
      this.selectedBooking = new FormattedBooking();
      this.selectedBooking = this.filteredResponse[this.filteredResponse.length - 1];
    }
    else {
      this.selectedBooking = new FormattedBooking();
      this.selectedBooking = this.filteredResponse[index - 1];
    }
    if (this.selectedBooking.PushedToPMS == "NO") {
      this.pmsStatus = false;
      this.loadHeaderOptions();
    } else {
      this.pmsStatus = true;
      this.loadHeaderOptions();
    }
  }

  syncPMSConfirmationDialog = false;
  syncPMSConfirmation() {
    this.syncPMSConfirmationDialog = true;
  }

  onSyncPMS(event) {
    //this.selectedBooking.Tags["FailureCount"]= 0;
    this.messageService.addAll([{ severity: 'info', summary: 'Info', detail: "Coming Soon.." }]);
    this.syncPMSConfirmationDialog = false;
  }

  selectedDomain: any;
  isCurrentUserAdmin() {
    let currentUser = this.storageService.get(StorageService.currentUser);
    this.selectedDomain = currentUser.DomainContext.CurrentDomainId;
    if (currentUser.User.Roles.includes("Admins")) {
      return true;
    } else {
      return false;
    }
  }

  adminEmailIds : string;
  sendVoucherConfirmationDialog = false;
  async sendVoucherConfirmation() {
    this.adminEmailIds = '';
    let hotelIds = [];
    hotelIds.push(this.selectedBooking.Booking.HotelId);

    let hotelCriteria = new HotelCriteria();
    hotelCriteria.Ids = hotelIds;
    await this.hotelService.findHotels(hotelCriteria).then(hotelList => {
      hotelList.forEach(hotel => {
        if (this.adminEmailIds == '' || this.adminEmailIds == null || this.adminEmailIds == undefined) {
          this.adminEmailIds = hotel.Email;
        }
        else {
          this.adminEmailIds = this.adminEmailIds + "," + hotel.Email;
        }
      });
    });

      this.sendVoucherConfirmationDialog = true;
  }

  async createVoucher(event) {
    let BookingIds = [];
    BookingIds.push(this.selectedBooking.Id);
    await this.service.SendBookingVoucher(BookingIds).then(result => {
      { this.messageService.addAll([{ severity: 'success', summary: 'Success', detail: "Sent successfully." }]); }
    });
    this.sendVoucherConfirmationDialog = false;
  }


  ngAfterViewInit(): void {
    this.setHeight();
  }

}