import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserCriteria, DomainProfileCriteria } from 'mantras-api';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from '../ui-services/authentication.service';

@Component({
  selector: 'usersmanagementcriteria',
  templateUrl: './usersmanagementcriteria.component.html',
  styleUrls: ['./usersmanagementcriteria.component.css']
})
export class UsersManagementCriteriaComponent implements OnInit {
  usrMngtCriteria = new UserCriteria();
  @Output('onsearch') onChange: EventEmitter<any>;
  @Output ('refreshOnChange') refreshOnChange :EventEmitter<any>;
  user;
  domain;
  isRefresh = false;
  filteredDomains: any[] = new Array();

  constructor(private messageService: MessageService,private authService: AuthenticationService,) { 
    this.onChange = new EventEmitter();
    this.refreshOnChange = new EventEmitter();

  }
  ngOnInit() {
  }

  async filterDomains(event) {
    let query = event.query;
    let domainCriteria = new DomainProfileCriteria();
    domainCriteria.Name = "*" + query + "*";
    this.filteredDomains = await this.authService.findDomains(domainCriteria).then(domainList => {
      console.log("Filterd Domains:", domainList);
      return domainList.filter(function (d) { return d.Id != "System" });
    });
  }


  public buildCriteria() {
    let returnVal = false;
    this.usrMngtCriteria = new UserCriteria();
    if (this.user) {
      this.usrMngtCriteria.Id = this.user;
      returnVal = true;
    }
    else {
      this.usrMngtCriteria.Id = null;
    }
    if (this.domain) {
      this.usrMngtCriteria.DomainId = this.domain.Id;
      returnVal = true;
    }
    else {
      this.usrMngtCriteria.DomainId = null;
    }
    return returnVal;  
  }

  onSearch(){
    if (!this.buildCriteria()) {
      this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'Error', detail: 'Please enter some criteria' });
    }
    else {
      this.onChange.emit(this.usrMngtCriteria);
    }
  }

  refreshChange(){
    this.refreshOnChange.emit(this.isRefresh);
  }

  onClear(){
    this.user = null;
    this.domain = null;
    this.isRefresh = false;
    this.onChange.emit(null);
  }

}
