import { AfterViewInit, Component, ViewChild, ViewContainerRef, ElementRef, Renderer } from "@angular/core";

import { ICellEditorAngularComp } from "ag-grid-angular";
import { RRPCellFilter, RatecalendarService } from "../ui-services/ratecalendar.service";
import { StorageService } from "../ui-services/storage.service";
import { Utilities } from "../ui-services/utilities";
import { AuthenticationService } from "../ui-services/authentication.service";
import { UserService } from '../ui-services/user.service';

@Component({
    selector: 'roomrateplanfields-celleditor',
    templateUrl: 'roomrateplanfieldsEditor.component.html'
})
export class RoomRatePlanFieldsEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private rateCalParam: any;
    private cancelBeforeStart: boolean = false;

    @ViewChild('perDay') perDay: ElementRef;
    @ViewChild('singleOccupancy') singleOccupancy: ElementRef;
    @ViewChild('doubleOccupancy') doubleOccupancy: ElementRef;
    @ViewChild('tripleOccupancy') tripleOccupancy: ElementRef;
    @ViewChild('quadraOccupancy') quadraOccupancy: ElementRef;
    @ViewChild('pentaOccupancy') pentaOccupancy: ElementRef;
    @ViewChild('hexaOccupancy') hexaOccupancy: ElementRef;
    @ViewChild('heptaOccupancy') heptaOccupancy: ElementRef;
    @ViewChild('octaOccupancy') octaOccupancy: ElementRef;
    @ViewChild('nonaOccupancy') nonaOccupancy: ElementRef;
    @ViewChild('decaOccupancy') decaOccupancy: ElementRef;

    @ViewChild('availability') availability: ElementRef;
    @ViewChild('soldRooms') soldRoom: ElementRef;
    @ViewChild('totalInventory') totalInventory: ElementRef;
    @ViewChild('extraChildren') extraChildren: ElementRef;
    @ViewChild('perExtraPerson') perExtraPerson: ElementRef;
    @ViewChild('minStay') minStay: ElementRef;
    @ViewChild('maxStay') maxStay: ElementRef;
    @ViewChild('minAdvPurchaseDays') minAdvPurchaseDays: ElementRef;
    @ViewChild('maxAdvPurchaseDays') maxAdvPurchaseDays: ElementRef;
    @ViewChild('CTA') CTA: ElementRef;
    @ViewChild('CTD') CTD: ElementRef;
    @ViewChild('container') container;

    public rrpFilter: RRPCellFilter;
    public fieldName: String;
    public data;
    public occupancyLinkingEnable = false;
    public domainHotel;
    public minVal; maxVal;
    public hotelContext;
    public linkedOccupancy =[];
    public pricingModel;
    public maxOccupancy;
    public baseOccupancy;
    public inventoryOnly = false;
    todaysDate = new Date();
    constructor(private renderer: Renderer, private storageService: StorageService, private rateCalendarService: RatecalendarService,
        private authenticationService: AuthenticationService, private userService:UserService) {
        this.rrpFilter = rateCalendarService.rrpFilter;
        this.inventoryOnly = userService.isInventoryOnly();
    }
    agInit(params: any): void {
        this.rateCalParam = params;
        this.todaysDate = new Date(Date.UTC(this.todaysDate.getFullYear(), this.todaysDate.getMonth(), this.todaysDate.getDate()));
        if (params.value) {
            this.data = params.value[params.column.colDef.colId];
            if (this.data) {
                this.hotelContext = this.storageService.get(StorageService.userHotelContext);
                let response = this.authenticationService.getDomainHotel(this.hotelContext.HotelId);
                this.domainHotel = response;
                if (this.domainHotel) {
                    this.minVal = this.domainHotel.MinRate;
                    this.maxVal = this.domainHotel.MaxRate;
                }
            }
        }
        if(params.node && params.node.data){
            this.pricingModel = params.node.data.pricingModel;
            this.maxOccupancy = params.node.data.maxOccupancy;
            this.baseOccupancy = params.node.data.baseOccupancy;
            this.occupancyLinkingEnable = params.node.data.occupancyLinking;
            this.linkedOccupancy = params.node.data.linkedOccupancies;
        }
    }

    isCancelBeforeStart() {
        if (this.data === undefined || this.data.ChannelCode != "MAX") return true;
        if(this.rateCalParam != undefined && this.rateCalParam.context.thisComponent && this.rateCalParam.context.thisComponent.displayDates){
            let selectionDate = this.rateCalParam.context.thisComponent.displayDates[this.rateCalParam.column.colDef.colId];
            if(Utilities.dateDifference(selectionDate,this.todaysDate)>1) return true;
        }
        else return false;
    }

    getValue(): any {
        let rateCal = this.rateCalParam.value[this.rateCalParam.column.colDef.colId];
        if (rateCal) {
            if (this.pricingModel != 2 && this.rrpFilter.perDay) {
                rateCal.RoomRate.PerDay = Utilities.checkUpdatedField(rateCal.RoomRate.PerDay, this.perDay.nativeElement.value);
                if(this.baseOccupancy > 0 && Object.keys(rateCal.RoomRate.PerOccupancy).length >= this.baseOccupancy)
                    rateCal.RoomRate.PerOccupancy[this.baseOccupancy] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[this.baseOccupancy], this.perDay.nativeElement.value);
            }
            if (this.pricingModel != 1 && this.maxOccupancy >= 1 && this.rrpFilter.occupancy[0]){
                if(this.singleOccupancy && this.singleOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {}; 
                    rateCal.RoomRate.PerOccupancy[1] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[1], this.singleOccupancy.nativeElement.value);
                }
            }
            if (this.pricingModel != 1 && this.maxOccupancy >= 2 && this.rrpFilter.occupancy[1]){
                if(this.doubleOccupancy && this.doubleOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {}; 
                    rateCal.RoomRate.PerOccupancy[2] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[2], this.doubleOccupancy.nativeElement.value);
                }
            }
            if (this.pricingModel != 1 && this.maxOccupancy >= 3 && this.rrpFilter.occupancy[2]){ 
                if(this.tripleOccupancy && this.tripleOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {}; 
                    rateCal.RoomRate.PerOccupancy[3] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[3], this.tripleOccupancy.nativeElement.value);
                }
            }
            if (this.pricingModel != 1 && this.maxOccupancy >= 4 && this.rrpFilter.occupancy[3]){
                if(this.quadraOccupancy && this.quadraOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {};  
                    rateCal.RoomRate.PerOccupancy[4] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[4], this.quadraOccupancy.nativeElement.value);
                }
            }
            if (this.pricingModel != 1 && this.maxOccupancy >= 5 && this.rrpFilter.occupancy[4]){
                if(this.pentaOccupancy && this.pentaOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {}; 
                    rateCal.RoomRate.PerOccupancy[5] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[5], this.pentaOccupancy.nativeElement.value);
                }
            }
            if (this.pricingModel != 1 && this.maxOccupancy >= 6 && this.rrpFilter.occupancy[5]){
                if(this.hexaOccupancy && this.hexaOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {}; 
                    rateCal.RoomRate.PerOccupancy[6] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[6], this.hexaOccupancy.nativeElement.value);
                }
            }
            if (this.pricingModel != 1 && this.maxOccupancy >= 7 && this.rrpFilter.occupancy[6]){
                if(this.heptaOccupancy && this.heptaOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {}; 
                    rateCal.RoomRate.PerOccupancy[7] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[7], this.heptaOccupancy.nativeElement.value);
                }
            }
            if (this.pricingModel != 1 && this.maxOccupancy >= 8 && this.rrpFilter.occupancy[7]){
                if(this.octaOccupancy && this.octaOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {}; 
                    rateCal.RoomRate.PerOccupancy[8] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[8], this.octaOccupancy.nativeElement.value);
                }
            } 
            if (this.pricingModel != 1 && this.maxOccupancy >= 9 && this.rrpFilter.occupancy[8]){
                if(this.nonaOccupancy && this.nonaOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {}; 
                    rateCal.RoomRate.PerOccupancy[9] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[9], this.nonaOccupancy.nativeElement.value);
                }
            } 
            if (this.pricingModel != 1 && this.maxOccupancy >= 10 && this.rrpFilter.occupancy[9]) rateCal.RoomRate.PerOccupancy[10] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[10], this.decaOccupancy.nativeElement.value);
            
            if (this.rrpFilter.perExtraPerson) rateCal.RoomRate.PerExtraPerson = Utilities.checkUpdatedField(rateCal.RoomRate.PerExtraPerson, this.perExtraPerson.nativeElement.value);
            // if (this.rrpFilter.perExtraPerson && (!this.isEmptyObject(rateCal.RoomRate.ExtraPerson) || this.perExtraPerson.nativeElement.value != "0.00")) {
            //     if (!rateCal.RoomRate.ExtraPerson)
            //         rateCal.RoomRate.ExtraPerson = {};
            //     rateCal.RoomRate.ExtraPerson = { "1": Utilities.checkUpdatedField(rateCal.RoomRate.ExtraPerson[1], this.perExtraPerson.nativeElement.value) }
            // }

            if (this.rrpFilter.extraChildren && (!this.isEmptyObject(rateCal.RoomRate.ExtraChildren) || this.extraChildren.nativeElement.value != "0.00")) {
                if (!rateCal.RoomRate.ExtraChildren)
                    rateCal.RoomRate.ExtraChildren = {};
                rateCal.RoomRate.ExtraChildren = { "1": Utilities.checkUpdatedField(rateCal.RoomRate.ExtraChildren[1], this.extraChildren.nativeElement.value) }
            }

            if (this.rrpFilter.availability) rateCal.Availability = Utilities.checkUpdatedField(rateCal.Availability, this.availability.nativeElement.value);
            if (this.rrpFilter.minStay) rateCal.MinStay = Utilities.checkUpdatedField(rateCal.MinStay, this.minStay.nativeElement.value);
            if (this.rrpFilter.maxStay) rateCal.MaxStay = Utilities.checkUpdatedField(rateCal.MaxStay, this.maxStay.nativeElement.value);
            if (this.rrpFilter.minAdvPurchaseDays) rateCal.MinAdvPurchaseDays = Utilities.checkUpdatedField(rateCal.MinAdvPurchaseDays, this.minAdvPurchaseDays.nativeElement.value);
            if (this.rrpFilter.maxAdvPurchaseDays) rateCal.MaxAdvPurchaseDays = Utilities.checkUpdatedField(rateCal.MaxAdvPurchaseDays, this.maxAdvPurchaseDays.nativeElement.value);
        }
        return this.rateCalParam.value;
    }

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    // isCancelAfterEnd(): boolean {
    //     //return this.perDay > 1000000;
    //     return false;
    // };

    onKeyDown(event): void {
        let key = event.which || event.keyCode;
        if (key == 37 ||  // left
            key == 38 ||  // up
            key == 39 ||  // right
            key == 40 ||  // bottom
            key == 9) {  // tab
            event.stopPropagation();
        }
    }

    onValueChange(event, oldValue,decimalPlace=2) {
        if (event.target) {
            console.log("MAX: ", event.target.max);
            console.log("MIN: ", event.target.min);
            if (event.target.max && Number.parseFloat(event.target.value) > Number.parseFloat(event.target.max)) {
                alert("Entered value is greater than maximum value : " + event.target.max);
                event.target.value = oldValue;
            }
            if (event.target.min && Number.parseFloat(event.target.value) < Number.parseFloat(event.target.min)) {
                alert("Entered value is lesser than minumum value : " + event.target.min);
                event.target.value = oldValue;
            }
            event.target.value = parseFloat(event.target.value).toFixed(decimalPlace);
        }
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
        if(this.data){
            if (this.pricingModel != 2 && this.rrpFilter.perDay) this.perDay.nativeElement.value = parseFloat(this.perDay.nativeElement.value).toFixed(2);
            if (this.pricingModel != 1 && this.maxOccupancy >= 1 && this.rrpFilter.occupancy[0] && this.singleOccupancy.nativeElement.value != "") this.singleOccupancy.nativeElement.value = parseFloat(this.singleOccupancy.nativeElement.value).toFixed(2);
            if (this.pricingModel != 1 && this.maxOccupancy >= 2 && this.rrpFilter.occupancy[1] && this.doubleOccupancy.nativeElement.value != "") this.doubleOccupancy.nativeElement.value = parseFloat(this.doubleOccupancy.nativeElement.value).toFixed(2);
            if (this.pricingModel != 1 && this.maxOccupancy >= 3 && this.rrpFilter.occupancy[2] && this.tripleOccupancy.nativeElement.value != "") this.tripleOccupancy.nativeElement.value = parseFloat(this.tripleOccupancy.nativeElement.value).toFixed(2);
            if (this.pricingModel != 1 && this.maxOccupancy >= 4 && this.rrpFilter.occupancy[3] && this.quadraOccupancy.nativeElement.value != "") this.quadraOccupancy.nativeElement.value = parseFloat(this.quadraOccupancy.nativeElement.value).toFixed(2);
            if (this.pricingModel != 1 && this.maxOccupancy >= 5 && this.rrpFilter.occupancy[4] && this.pentaOccupancy.nativeElement.value != "") this.pentaOccupancy.nativeElement.value = parseFloat(this.pentaOccupancy.nativeElement.value).toFixed(2);
            if (this.pricingModel != 1 && this.maxOccupancy >= 6 && this.rrpFilter.occupancy[5] && this.hexaOccupancy.nativeElement.value != "") this.hexaOccupancy.nativeElement.value = parseFloat(this.hexaOccupancy.nativeElement.value).toFixed(2);
            if (this.pricingModel != 1 && this.maxOccupancy >= 7 && this.rrpFilter.occupancy[6] && this.heptaOccupancy.nativeElement.value != "") this.heptaOccupancy.nativeElement.value = parseFloat(this.heptaOccupancy.nativeElement.value).toFixed(2);
            if (this.pricingModel != 1 && this.maxOccupancy >= 8 && this.rrpFilter.occupancy[7] && this.octaOccupancy.nativeElement.value != "") this.octaOccupancy.nativeElement.value = parseFloat(this.octaOccupancy.nativeElement.value).toFixed(2);
            if (this.pricingModel != 1 && this.maxOccupancy >= 9 && this.rrpFilter.occupancy[8] && this.nonaOccupancy.nativeElement.value != "") this.nonaOccupancy.nativeElement.value = parseFloat(this.nonaOccupancy.nativeElement.value).toFixed(2);
            if (this.pricingModel != 1 && this.maxOccupancy >= 10 && this.rrpFilter.occupancy[9] && this.decaOccupancy.nativeElement.value != "") this.doubleOccupancy.nativeElement.value = parseFloat(this.decaOccupancy.nativeElement.value).toFixed(2);

            if (this.rrpFilter.perExtraPerson && this.perExtraPerson.nativeElement.value != "") {
                this.perExtraPerson.nativeElement.value = parseFloat(this.perExtraPerson.nativeElement.value).toFixed(2);
            }
            if (this.rrpFilter.extraChildren && this.extraChildren.nativeElement.value != "") {
                this.extraChildren.nativeElement.value = parseFloat(this.extraChildren.nativeElement.value).toFixed(2);
            }

            if(this.rateCalParam.context.thisComponent && this.rateCalParam.context.thisComponent.cellEvent){
                let targetEvent = (this.rateCalParam.context.thisComponent.cellEvent.target || this.rateCalParam.context.thisComponent.cellEvent.srcElement);
                let focusElementName = targetEvent["name"];
                if(this.perDay && this.perDay.nativeElement.name == focusElementName) { setTimeout(() => this.perDay.nativeElement.select(), 0);}
                if(this.singleOccupancy && this.singleOccupancy.nativeElement.name == focusElementName) { setTimeout(() => this.singleOccupancy.nativeElement.select(), 0);}
                if(this.doubleOccupancy && this.doubleOccupancy.nativeElement.name == focusElementName) { setTimeout(() => this.doubleOccupancy.nativeElement.select(), 0);}
                if(this.tripleOccupancy && this.tripleOccupancy.nativeElement.name == focusElementName) { setTimeout(() => this.tripleOccupancy.nativeElement.select(), 0);}
                if(this.quadraOccupancy && this.quadraOccupancy.nativeElement.name == focusElementName) { setTimeout(() => this.quadraOccupancy.nativeElement.select(), 0);}
                if(this.pentaOccupancy && this.pentaOccupancy.nativeElement.name == focusElementName) { setTimeout(() => this.pentaOccupancy.nativeElement.select(), 0);}
                if(this.hexaOccupancy && this.hexaOccupancy.nativeElement.name == focusElementName) { setTimeout(() => this.hexaOccupancy.nativeElement.select(), 0);}
                if(this.heptaOccupancy && this.heptaOccupancy.nativeElement.name == focusElementName) { setTimeout(() => this.heptaOccupancy.nativeElement.select(), 0);}
                if(this.octaOccupancy && this.octaOccupancy.nativeElement.name == focusElementName) { setTimeout(() => this.octaOccupancy.nativeElement.select(), 0);}
                if(this.nonaOccupancy && this.nonaOccupancy.nativeElement.name == focusElementName) { setTimeout(() => this.nonaOccupancy.nativeElement.select(), 0);}
                if(this.decaOccupancy && this.decaOccupancy.nativeElement.name == focusElementName) { setTimeout(() => this.decaOccupancy.nativeElement.select(), 0);}
                if(this.availability && this.availability.nativeElement.name == focusElementName) { setTimeout(() => this.availability.nativeElement.select(), 0);}
                if(this.perExtraPerson && this.perExtraPerson.nativeElement.name == focusElementName) { setTimeout(() => this.perExtraPerson.nativeElement.select(), 0);}
                if(this.extraChildren && this.extraChildren.nativeElement.name == focusElementName) { setTimeout(() => this.extraChildren.nativeElement.select(), 0);}
                if(this.minStay && this.minStay.nativeElement.name == focusElementName) { setTimeout(() => this.minStay.nativeElement.select(), 0);}
                if(this.maxStay && this.maxStay.nativeElement.name == focusElementName) { setTimeout(() => this.maxStay.nativeElement.select(), 0);}
                if(this.minAdvPurchaseDays && this.minAdvPurchaseDays.nativeElement.name == focusElementName) { setTimeout(() => this.minAdvPurchaseDays.nativeElement.select(), 0);}
                if(this.maxAdvPurchaseDays && this.maxAdvPurchaseDays.nativeElement.name == focusElementName) { setTimeout(() => this.maxAdvPurchaseDays.nativeElement.select(), 0);}
            }
        }
    }

    private getCharCodeFromEvent(event): any {
        event = event || window.event;
        return (typeof event.which == "undefined") ? event.keyCode : event.which;
    }

    private isCharNumeric(charStr): boolean {
        return !!/\d/.test(charStr);
    }

    private isKeyPressedNumeric(event): boolean {
        const charCode = this.getCharCodeFromEvent(event);
        const charStr = event.key ? event.key : String.fromCharCode(charCode);
        return this.isCharNumeric(charStr);
    }
    isEmptyObject(object) {
        if (!object) return true;
        return JSON.stringify(object) === JSON.stringify({});
    }
}