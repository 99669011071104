// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment_live = {
  production: true,
  name:"production",
  vendor: "MAXIMOJO",
  watermarkCSS: null,
  service_uri: "https://api.platform.maximojo.com",
  airbnb_auth_url: "https://secure.maximojo.com/mantrasdirect/authorize?domainId={domainId}&channelId={channelId}",
  version: require('../../package.json').version,
  theme: "blue",
  logo: "Maximojo",
  privacyLink: "http://www.maximojo.com/privacy/",
  termsOfUseLink: "http://www.maximojo.com/terms-of-use/"
};

export const environment = {
  production: false,
  name:"staging",
  vendor: "MAXIMOJO",
  watermarkCSS: "stage",
  service_uri: "https://staging.api.platform.maximojo.com",
  airbnb_auth_url: "https://staging.secure.maximojo.com/mantrasdirect-stage/mantrasdirect/authorize?domainId={domainId}&channelId={channelId}",
  version: require('../../package.json').version,
  theme: "grape",
  logo: "Maximojo",
  privacyLink: "http://www.maximojo.com/privacy/",
  termsOfUseLink: "http://www.maximojo.com/terms-of-use/"  
};