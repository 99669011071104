import { Injectable } from "@angular/core";
import { TaskStatus, Task, TaskCriteria } from "mantras-api";
import { Utilities } from "./utilities";
import { AppService } from "mantras-api";
import { Subject } from "rxjs";
import { StorageService } from "./storage.service";
import { AppConfigService } from "../app-config.service";

@Injectable()
export class TaskbarService {
    isTaskRunning: boolean = false;
    showTaskDialog = false;
    tasks: Map<string,any> = new Map();
    taskInfos: Map<string,any> = new Map();
    taskProgress = 0;
    successfullyCompleted = 0;

    //Task Manager UI listener
    taskSource = new Subject<any>();
    // Observable string streams
    taskObserver$ = this.taskSource.asObservable();

    //Task Complete Notifiation
    taskCompleteNotificationSource = new Subject<any>();
    // Observable string streams
    taskCompleteListener$ = this.taskCompleteNotificationSource.asObservable();

    public static Promotion = "promotion";
    public static Booking = "booking";
    public static RateCalendar = "ratecalendar";

    //mantras:AppService;
    constructor(private mantras:AppService,private appConfService:AppConfigService,private utilities:Utilities,private storageService:StorageService){
        Utilities.initApp(mantras,appConfService);
        this.loadTasks();
    }

    loadTasks(){
        let userId = this.storageService.get(StorageService.loginUserId);
        let taskIds = this.storageService.get(StorageService.tasks+"_"+userId);
        if(taskIds && taskIds != "")
            this.addTasks(taskIds,null);
    }

    saveTasks(){
        let userId = this.storageService.get(StorageService.loginUserId);
        this.storageService.set(StorageService.tasks+"_"+userId,Array.from(this.tasks.keys()));
    }

    taskCompleteNotification(tasks:Task[]) {
        this.taskCompleteNotificationSource.next(tasks);
    }

    addTasks(taskIds: string[],taskInfo=null){
        if(taskIds && taskIds.length > 0){
            let tIdx = 0;
            taskIds.forEach(taskId => {
                if(!this.tasks.has(taskId)){
                    this.tasks.set(taskId,null);
                    this.taskInfos.set(taskId,taskInfo);
                }
                tIdx++;
            });
            if(this.tasks.size > 0){
                this.saveTasks();
                this.startService();
            }
        }
    }

    removeCompleted(){
        if(this.tasks.size >0){
            for (let key of Array.from(this.tasks.keys())) {
                let task:Task = this.tasks.get(key);
                if(task != null && !(task.Status == TaskStatus.Running || task.Status == TaskStatus.Initial)){
                    this.tasks.delete(key);
                }
            }
        }
    }

    computeProgress(){
        let progress = 0
        let completionCount = 0;
        if(this.tasks.size >0){
            for (let key of Array.from(this.tasks.keys())) {
                let task:Task = this.tasks.get(key);
                if(task != null) {
                    progress += task.Progress;
                    if(task.Status == TaskStatus.Complete || task.Status == TaskStatus.CompleteWithWarnings){
                        completionCount++;
                    }
                }
            }
            this.successfullyCompleted = completionCount;
            this.taskProgress = Math.round(progress/this.tasks.size);
            this.taskSource.next(this);
        }
    }

    interval;
    startService(){
        if(!this.isTaskRunning){
            this.isTaskRunning=true;
            this.interval = setInterval(() => {
                this.findTask(Array.from(this.tasks.keys()));
                this.computeProgress();
                if(this.taskProgress == 100){
                    this.showTaskDialog = true;
                    clearInterval(this.interval);
                    this.taskCompleteNotificationSource.next(this.tasks);
                }
            }, 5000);
        }
    }

    async findTask(taskIds:string[]){
        let taskCriteria = new TaskCriteria();
        taskCriteria.IdList = taskIds;
        let responseTasks = await this.mantras.findtasks(taskCriteria).then
        (response => {
            return response;
        });
        responseTasks.forEach(task => {
            this.tasks.set(task.Id,task);
        });
    }

    onTaskDetailDialogClose(){
        this.showTaskDialog = false;
        if(this.taskProgress == 100){
            this.tasks.clear();
            this.taskProgress = 0;
            this.successfullyCompleted = 0;
            this.isTaskRunning = false;
            let userId = this.storageService.get(StorageService.loginUserId);
            this.storageService.remove(StorageService.tasks+"_"+userId);
        }
    }

    getTaskStatus(taskMap, taskType){
        let status = false;
        for (let key of Array.from(taskMap.keys())) {
            let task:Task = taskMap.get(key);
            if(task != null) {
                if(task.Name && task.Name.toLowerCase().indexOf(taskType) >=0 && 
                (task.Status == TaskStatus.Complete || task.Status == TaskStatus.CompleteWithWarnings)){
                    status = true;
                    break;
                }
            }
        }
        return status;
    }

    resetTask(){
        if(this.interval) clearInterval(this.interval);
        this.tasks.clear();
        this.taskProgress = 0;
        this.successfullyCompleted = 0;
        this.isTaskRunning = false;
        this.showTaskDialog = false;
    }
}

export class TaskInfo{
    Start: Date;
    End: Date;
}