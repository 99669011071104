import { Component, OnInit, HostListener, ViewChild, ComponentFactoryResolver, ViewContainerRef, EventEmitter, Input } from '@angular/core';
import { AfterViewInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { ViewChildren } from '@angular/core';
import { Calendar, OverlayPanel, MessageService } from 'primeng/primeng';
import { StorageService } from '../../ui-services/storage.service';
import { BulkRate, BulkRateCalendarItems, RoomRate } from 'mantras-api';
import { DateRange } from 'mantras-api';
import { RatecalendarService } from '../../ui-services/ratecalendar.service';
import { ConfirmationService } from 'primeng/components/common/confirmationservice';
import { Utilities } from '../../ui-services/utilities';
import { AuthenticationService } from '../../ui-services/authentication.service';
import { UserService } from '../../ui-services/user.service';
import { CheckboxgroupComponent } from '../checkboxgroup/checkboxgroup.component';

@Component({
  selector: 'mm-bulkedit',
  templateUrl: './bulkedit.component.html',
  styleUrls: ['./bulkedit.component.css'],
  outputs: ['onClose']
})
export class BulkeditComponent implements OnInit, AfterViewInit {
  @Input() rrpInstance: any;
  ratePlans = [];
  roomTypes = [];
  selectedRoomTypes = [];
  selectedRatePlans = [];
  selectedStopSell = "";
  selectedCTA = "";
  selectedCTD = "";
  selectedPerDay = "";
  selectedSingle = "";
  selectedDouble = "";
  selectedTriple = "";
  selectedQuarda = "";
  selectedPenta = "";
  selectedHexa = "";
  selectedHepta = "";
  selectedOcta = "";
  selectedNona = "";
  selectedDeca = "";
  selectedAvailability = "";
  selectedMinStay = "";
  selectedMaxStay = "";
  // selectedMinStayArrival = "";
  // selectedMaxStayArrival = "";
  selectedMinAdvPurchaseDays = "";
  selectedMaxAdvPurchaseDays = "";
  selectedExtraPerson = "";
  selectedExtraChildren = "";
  bulkEditMenuItems;
  errorMessage = null;
  hotelId = null;
  onClose = new EventEmitter<boolean>();
  rangeSelectionMinDate;
  rangeSelectionMaxDate;
  initialLoad = true;
  domainHotel;
  inventoryOnly = false;
  onChannelsOnly: boolean = false;
  @ViewChild('chkChannelTypes') chkChannelTypes : CheckboxgroupComponent;


  constructor(private resolver: ComponentFactoryResolver, private storageService: StorageService,
    public rateCalendarService: RatecalendarService, private confirmationService: ConfirmationService,
    private authenticationService: AuthenticationService, private messageService: MessageService, private userService: UserService) {
    this.rangeSelectionMinDate = new Date(Date.now());
  }

  ngOnInit() {
    let hotelContext = this.storageService.get(StorageService.userHotelContext);
    this.domainHotel = this.authenticationService.getDomainHotel(hotelContext.HotelId);
    this.inventoryOnly = this.userService.isInventoryOnly();
    this.ratePlans = [];
    hotelContext.RatePlans.forEach(element => {
      this.ratePlans.push({ label: element.Name, value: element.Id });
    });
    this.roomTypes = [];
    hotelContext.RoomTypes.forEach(element => {
      this.roomTypes.push({ label: element.Name, value: element.Id });
    });
    this.hotelId = hotelContext.HotelId;
    console.log("RatePlans:", this.ratePlans);
    console.log("RoomTypes:", this.roomTypes);
    this.bulkEditMenuItems = [
      { title: 'Save & Sync', label: 'Save, Sync & Close', icon: 'action-bar-menu-icon fa fa-floppy-o', command: (event) => this.onSave(event, true) },
      { title: 'Save', label: 'Save & Close', icon: 'action-bar-menu-icon fa fa-floppy-o', command: (event) => this.onSave(event, false) },
      { title: 'Cancel', label: 'Cancel', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }
    ];
    this.onChannelsOnly = false;
    // 1.3. Load Channel Types
    this.rateCalendarService.getChannelTypes().then(response => {
      this.availableChannelTypes = response;
      this.availableChannelTypes.push({ value: "MAX", label: Utilities.findId(Utilities.channelTypes, "MAX") });
      this.availableChannelTypes.reverse();

      this.selectedChannelTypes = [];
      this.availableChannelTypes.forEach(item => this.selectedChannelTypes.push(item.value));
      this.chkChannelTypes.value = this.selectedChannelTypes;
      });
  }

  ngAfterViewInit() {
    this.removeComponents();
    if (this.rrpInstance) {
      for (let cnt = 1; cnt < this.rrpInstance.length; cnt++)
        this.addRow();
    }
    setTimeout(() => {
      this.errorMessage = null;
      for (let i = 0; i < this.cals.length; i++) {
        this.loadCalendar(this.cals.toArray()[i], i + 1);
      }
      this.selectedDaysOfWeek = ['0', '1', '2', '3', '4', '5', '6'];
      this.rangeDates = null;
      if (this.rrpInstance)
        this.bindData();
      else if (!this.initialLoad)
        this.resetData();
      this.initialLoad = false;
      //this.selectedChannelTypes = [];
      //this.showConfirmationDialog.hide();

      this.showConfirmationDialog = false;
    });
  }

  bindData() {
    //this.removeComponents();
    console.log("Binding Data:", this.rrpInstance);
    console.log("RoomTypes: ", this.rrpInstance.RoomTypeId);
    for (var ins = 0; ins < this.rrpInstance.length; ins++) {
      if (ins != 0) this.populateRowDetails(ins - 1, this.rrpInstance[ins]);
      else {
        let rrpInst = this.rrpInstance[ins];
        this.selectedRatePlans = [];
        this.selectedRoomTypes = [];
        this.selectedRatePlans.push(rrpInst.RatePlanId);
        this.selectedRoomTypes.push(rrpInst.RoomTypeId);
        this.selectedPerDay = rrpInst.RoomRate.PerDay;
        this.selectedAvailability = rrpInst.Availability;
        this.selectedSingle = (rrpInst.RoomRate.PerOccupancy && rrpInst.RoomRate.PerOccupancy[1]) ? rrpInst.RoomRate.PerOccupancy[1] : "";
        this.selectedDouble = (rrpInst.RoomRate.PerOccupancy && rrpInst.RoomRate.PerOccupancy[2]) ? rrpInst.RoomRate.PerOccupancy[2] : "";
        this.selectedTriple = (rrpInst.RoomRate.PerOccupancy && rrpInst.RoomRate.PerOccupancy[3]) ? rrpInst.RoomRate.PerOccupancy[3] : "";
        this.selectedQuarda = (rrpInst.RoomRate.PerOccupancy && rrpInst.RoomRate.PerOccupancy[4]) ? rrpInst.RoomRate.PerOccupancy[4] : "";
        this.selectedPenta = (rrpInst.RoomRate.PerOccupancy && rrpInst.RoomRate.PerOccupancy[5]) ? rrpInst.RoomRate.PerOccupancy[5] : "";
        this.selectedHexa = (rrpInst.RoomRate.PerOccupancy && rrpInst.RoomRate.PerOccupancy[6]) ? rrpInst.RoomRate.PerOccupancy[6] : "";
        this.selectedHepta = (rrpInst.RoomRate.PerOccupancy && rrpInst.RoomRate.PerOccupancy[7]) ? rrpInst.RoomRate.PerOccupancy[7] : "";
        this.selectedOcta = (rrpInst.RoomRate.PerOccupancy && rrpInst.RoomRate.PerOccupancy[8]) ? rrpInst.RoomRate.PerOccupancy[8] : "";
        this.selectedNona = (rrpInst.RoomRate.PerOccupancy && rrpInst.RoomRate.PerOccupancy[9]) ? rrpInst.RoomRate.PerOccupancy[9] : "";
        this.selectedDeca = (rrpInst.RoomRate.PerOccupancy && rrpInst.RoomRate.PerOccupancy[10]) ? rrpInst.RoomRate.PerOccupancy[10] : "";
        // this.selectedExtraPerson = (rrpInst.RoomRate.ExtraPerson && rrpInst.RoomRate.ExtraPerson[1])?rrpInst.RoomRate.ExtraPerson[1]:"";
        this.selectedExtraPerson = rrpInst.RoomRate.PerExtraPerson;
        //TODO:EXTRACHILD
        this.selectedExtraChildren = (rrpInst.RoomRate.ExtraChildren && rrpInst.RoomRate.ExtraChildren[1]) ? rrpInst.RoomRate.ExtraChildren[1] : "";
        this.selectedMinStay = rrpInst.MinStay;
        this.selectedMaxStay = rrpInst.MaxStay;
        this.selectedMinAdvPurchaseDays = rrpInst.MinAdvPurchaseDays;
        this.selectedMaxAdvPurchaseDays = rrpInst.MaxAdvPurchaseDays;
        this.selectedCTA = (typeof rrpInst.CTA == "boolean") ? rrpInst.CTA.toString() : "";
        this.selectedCTD = (typeof rrpInst.CTD == "boolean") ? rrpInst.CTD.toString() : "";
        // this.selectedMinStayArrival = rrpInst.MinStayArrival;
        // this.selectedMaxStayArrival = rrpInst.MaxStayArrival;
        this.selectedStopSell = (typeof rrpInst.IsClosed == "boolean") ? rrpInst.IsClosed.toString() : "";
      }
    }
  }

  resetData() {
    this.removeComponents();
    this.selectedRatePlans = [];
    this.selectedRoomTypes = [];
    this.selectedPerDay = "";
    this.selectedAvailability = "";
    this.selectedSingle = "";
    this.selectedDouble = "";
    this.selectedTriple = "";
    this.selectedQuarda = "";
    this.selectedPenta = "";
    this.selectedHexa = "";
    this.selectedHepta = "";
    this.selectedOcta = "";
    this.selectedNona = "";
    this.selectedDeca = "";
    this.selectedExtraPerson = "";
    //TODO:EXTRACHILD
    this.selectedExtraChildren = "";
    this.selectedMinStay = "";
    this.selectedMaxStay = "";
    // this.selectedMinStayArrival = "";
    // this.selectedMaxStayArrival = "";
    this.selectedMinAdvPurchaseDays = "";
    this.selectedMaxAdvPurchaseDays = "";
    this.selectedCTA = "";
    this.selectedCTD = "";
    this.selectedStopSell = "";
  }

  removeComponents() {
    while (this.componentReference.length > 0) {
      let compRef = this.componentReference[0];
      this.componentReference.splice(0, 1);
      compRef.destroy();
    }
  }

  @ViewChildren('rooms') rooms;
  @ViewChildren('rates') rates;
  @ViewChildren('perDay') perDays;
  @ViewChildren('single') singles;
  @ViewChildren('double') doubles;
  @ViewChildren('triple') triples;
  @ViewChildren('quadra') quadras;
  @ViewChildren('penta') pentas;
  @ViewChildren('hexa') hexas;
  @ViewChildren('hepta') heptas;
  @ViewChildren('octa') octas;
  @ViewChildren('nona') nonas;
  @ViewChildren('decas') decas;
  @ViewChildren('availability') availabilities;
  @ViewChildren('stopSell') stopSells;
  @ViewChildren('extraPerson') extraPersons;
  //TODO:EXTRACHILD
  @ViewChildren('extraChildren') extraChildren;
  @ViewChildren('minStay') minStays;
  @ViewChildren('maxStay') maxStays;
  // @ViewChildren('minStayArrival') minStaysArrival;
  // @ViewChildren('maxStayArrival') maxStaysArrival;
  @ViewChildren('minAdvPurchaseDays') minAdvPurchasesDays;
  @ViewChildren('maxAdvPurchaseDays') maxAdvPurchasesDays;
  @ViewChildren('cta') ctas;
  @ViewChildren('ctd') ctds;
  // @ViewChild('onChannelsOnly') onChannelsOnly;
  selectedDateRanges;
  selectedBulkRates;

  // #region Select ChannelTypes
  selectedChannelTypes: string[] = [];
  availableChannelTypes = [];
  showDialog = false;
  //@ViewChild('showConfirmationDialog') showConfirmationDialog : OverlayPanel;
  showConfirmationDialog = false;
  isSync = false;

  closeDialog(event) {
    //this.showConfirmationDialog.toggle(event);
    this.selectedChannelTypes = [];
    this.showConfirmationDialog = false;
  }

  confirmBulkEditRequest(sync) {
    // this.isSync = this.isSync;
    this.showConfirmationDialog = false;

    if (this.selectedChannelTypes.length > 0) {
      //this.showConfirmationDialog.toggle(event);
      // this.showConfirmationDialog = true;
      // this.errorMessage = await this.sendBulkEditRequest(this.selectedDateRanges,this.selectedBulkRates,this.selectedChannelTypes,this.isSync);
      // if(this.errorMessage) this.messageService.add({key:'error', severity: 'error',life:2000, summary: 'Error',detail:this.errorMessage });
    } else {
      // this.errorMessage = await this.sendBulkEditRequest(this.selectedDateRanges,this.selectedBulkRates,null,this.isSync);
      // if(this.errorMessage) this.messageService.add({key:'error', severity: 'error',life:2000, summary: 'Error',detail:this.errorMessage });
    }
  }

  // #endregion Select ChannelTypes


  onCancel(refresh) {
    if (this.onClose)
      this.onClose.emit(refresh);
  }

  async onSave(event, sync) {
    this.isSync = sync;
    this.errorMessage = "";

    // 1.1. Load BulkRates
    let bulkRates = new Array<BulkRate>();
    for (let i = 0; i < this.rooms.length; i++) {
      let selectedRoom = (this.rooms.toArray()[i].value) ? this.rooms.toArray()[i].value : "";
      let selectedRatePlan = (this.rates.toArray()[i].value) ? this.rates.toArray()[i].value : "";
      let availability = this.availabilities.toArray()[i].nativeElement.value;
      let perDay = "", single = "", double = "", triple = "", quadra = "", penta = "",
        hexa = "", hepta = "", octa = "", nona = "", deca = "";
      if (this.perDays && this.perDays.length) perDay = this.perDays.toArray()[i].nativeElement.value;
      if (this.singles && this.singles.length) single = this.singles.toArray()[i].nativeElement.value;
      if (this.doubles && this.doubles.length) double = this.doubles.toArray()[i].nativeElement.value;
      if (this.triples && this.triples.length) triple = this.triples.toArray()[i].nativeElement.value;
      if (this.quadras && this.quadras.length) quadra = this.quadras.toArray()[i].nativeElement.value;
      if (this.pentas && this.pentas.length) penta = this.pentas.toArray()[i].nativeElement.value;
      if (this.hexas && this.hexas.length) hexa = this.hexas.toArray()[i].nativeElement.value;
      if (this.heptas && this.heptas.length) hepta = this.heptas.toArray()[i].nativeElement.value;
      if (this.octas && this.octas.length) octa = this.octas.toArray()[i].nativeElement.value;
      if (this.nonas && this.nonas.length) nona = this.nonas.toArray()[i].nativeElement.value;
      if (this.decas && this.decas.length) deca = this.decas.toArray()[i].nativeElement.value;
      let extraPerson = this.extraPersons.toArray()[i].nativeElement.value;
      //TODO:EXTRACHILD
      let extraChildren = this.extraChildren.toArray()[i].nativeElement.value;
      let minStay = this.minStays.toArray()[i].nativeElement.value;
      let maxStay = this.maxStays.toArray()[i].nativeElement.value;
      // let minStayArrival = this.minStaysArrival.toArray()[i].nativeElement.value;
      // let maxStayArrival = this.maxStaysArrival.toArray()[i].nativeElement.value;
      let minAdvPurchaseDays = this.minAdvPurchasesDays.toArray()[i].nativeElement.value;
      let maxAdvPurchaseDays = this.maxAdvPurchasesDays.toArray()[i].nativeElement.value;
      if (minAdvPurchaseDays < 0 || maxAdvPurchaseDays < 0) {
        this.errorMessage = "Please insert value greater than equal to 0";
        this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'Error', detail: this.errorMessage });
        return;
      }
      let cta = (this.ctas.toArray()[i].value != "") ? this.ctas.toArray()[i].value : "";
      let ctd = (this.ctds.toArray()[i].value != "") ? this.ctds.toArray()[i].value : "";
      let stopSell = (this.stopSells.toArray()[i].value != "") ? this.stopSells.toArray()[i].value : "";
      if (selectedRoom != "" && selectedRatePlan != "" &&
        (perDay != "" || availability != "" || single != "" || double != "" || triple != "" || quadra != ""
          || penta != "" || hexa != "" || hepta != "" || octa != "" || nona != "" || deca != ""
          || extraPerson != "" || extraChildren != "" || minAdvPurchaseDays != "" || maxAdvPurchaseDays != ""
          || minStay != "" || maxStay != "" || cta != "" || ctd != "" || stopSell != ""))
      //TODO:EXTRACHILD
      {
        let bulkRate = new BulkRate();
        bulkRate.RoomTypes = selectedRoom;
        bulkRate.RatePlans = selectedRatePlan;
        if (availability != "") bulkRate.Availability = availability;
        if (stopSell != "") bulkRate.IsClosed = "true" == stopSell;
        if (minStay != "") bulkRate.MinStay = parseFloat(minStay);
        if (maxStay != "") bulkRate.MaxStay = parseFloat(maxStay);
        if (minAdvPurchaseDays != "") bulkRate.MinAdvPurchaseDays = parseFloat(minAdvPurchaseDays);
        if (maxAdvPurchaseDays != "") bulkRate.MaxAdvPurchaseDays = parseFloat(maxAdvPurchaseDays);
        if (cta != "") bulkRate.CTA = "true" == cta;
        if (ctd != "") bulkRate.CTD = "true" == ctd;
        // if(minStayArrival != "") bulkRate.MinStayArrival = parseFloat(minStayArrival);
        // if(maxStayArrival != "") bulkRate.MaxStayArrival = parseFloat(maxStayArrival);
        if (perDay != "" || extraPerson != "" || single != "" || double != "" || triple != "" || quadra != ""
          || penta != "" || hexa != "" || hepta != "" || octa != "" || nona != "" || deca != "" || extraChildren != "") {
          bulkRate.RoomRate = new RoomRate();
          if (perDay != "") bulkRate.RoomRate.PerDay = parseFloat(perDay);
          // if(extraPerson != ""){
          //   bulkRate.RoomRate.ExtraPerson = new Map();
          //   bulkRate.RoomRate.ExtraPerson.set(1,extraPerson);
          // }
          if (extraPerson != "") bulkRate.RoomRate.PerExtraPerson = parseFloat(extraPerson);
          //TODO:EXTRACHILD
          if (extraChildren != "") {
            bulkRate.RoomRate.ExtraChildren = {};
            bulkRate.RoomRate.ExtraChildren[1] = parseFloat(extraChildren);
          }
          if (single != "" || double != "" || triple != "" || quadra != ""
            || penta != "" || hexa != "" || hepta != "" || octa != "" || nona != "" || deca != "")
            bulkRate.RoomRate.PerOccupancy = {};
          if (single != "") bulkRate.RoomRate.PerOccupancy[1] = parseFloat(single);
          if (double != "") bulkRate.RoomRate.PerOccupancy[2] = parseFloat(double);
          if (triple != "") bulkRate.RoomRate.PerOccupancy[3] = parseFloat(triple);
          if (quadra != "") bulkRate.RoomRate.PerOccupancy[4] = parseFloat(quadra);
          if (penta != "") bulkRate.RoomRate.PerOccupancy[5] = parseFloat(penta);
          if (hexa != "") bulkRate.RoomRate.PerOccupancy[6] = parseFloat(hexa);
          if (hepta != "") bulkRate.RoomRate.PerOccupancy[7] = parseFloat(hepta);
          if (octa != "") bulkRate.RoomRate.PerOccupancy[8] = parseFloat(octa);
          if (nona != "") bulkRate.RoomRate.PerOccupancy[9] = parseFloat(nona);
          if (deca != "") bulkRate.RoomRate.PerOccupancy[10] = parseFloat(deca);
        }
        console.log("BulkEdit Data:", bulkRate);
        bulkRates.push(bulkRate);
      }
    }
    if (bulkRates.length == 0) {
      this.errorMessage = "Please set Room Types/ Rate Plans/ Missing fields which need to be updated.";
      this.messageService.add({ key: 'error', severity: 'error', life: 3000, summary: 'Error', detail: this.errorMessage });
      return;
    }
    // 1.2. Load DateRanges
    let selectedDates = this.getSelectedDates();
    if (selectedDates.length == 0) {
      this.errorMessage = "Please select dates which need to be updated.";
      this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'Error', detail: this.errorMessage });
      return;
    }
    let dateRanges = this.getDateRanges(selectedDates);
    console.log("Date Ranges: ", dateRanges);
    this.selectedBulkRates = bulkRates;
    this.selectedDateRanges = dateRanges;

    // 2. Do Action (Call Service)

    let bulkTask = new BulkRateCalendarItems();
    bulkTask.BulkRates = this.selectedBulkRates;
    bulkTask.DateRanges = this.selectedDateRanges;
    await this.rateCalendarService.bulkUpdateRateCalendarTask(this.hotelId, [bulkTask], this.selectedChannelTypes, this.isSync)
      .then(async response => { return response; })
      .catch(error => { this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'Error', detail: error }); });

    // 3. Close BulkEdit dialog
    this.onCancel(false);

  }


  async sendBulkEditRequest(dateRanges, bulkRates, channels, sync) {
    //TODO this will change in the implementation done later
    let bulkTask = new BulkRateCalendarItems();
    bulkTask.BulkRates = bulkRates;
    bulkTask.DateRanges = dateRanges;
    return await this.rateCalendarService.bulkUpdateRateCalendarTask(this.hotelId, [bulkTask], channels, sync).then(
      async response => {
        let refrehRC = true;
        // if(this.isSync){
        //   let domainContext = this.storageService.get(StorageService.userDomainContext);
        //   if(channels == null) channels = this.storageService.get(StorageService.currentUser).HotelContext.ChannelCodes;
        //   if(channels.indexOf("MAX") >= 0) channels.splice(channels.indexOf("MAX"),1);
        //   if(channels.length > 0){
        //     refrehRC = false;
        //     let syncResponse = await this.rateCalendarService.syncRateCalendar(this.hotelId, domainContext.CurrentDomainId,
        //       this.syncDateRange, "twoway", false, channels).then(
        //       async response => {
        //         return response;
        //     });
        //   }
        // }
        this.onCancel(refrehRC);
        return response;
      }).catch(response => {
        return response;
      });
  }



  // #region Calendar (Date-Rangs) Component
  showAdvance = false;

  @ViewChildren('cal') cals;
  ctrlKeyPress = false;
  startDate = null;
  startCal = null;
  startCalNo;
  @HostListener('document:keydown', ['$event'])
  public handleKeyDownKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Control')
      this.ctrlKeyPress = true;
  }

  @HostListener('document:keyup', ['$event'])
  public handleKeyUpKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Control') {
      this.ctrlKeyPress = false;
      this.startDate = null;
    }
  }


  handleSelect(calNo) {
    if (this.ctrlKeyPress) {
      let cal = this.cals.toArray()[calNo - 1];
      if (!cal.value) cal.value = new Array();
      console.log("Ctrl ket press", this.ctrlKeyPress);
      if (!this.startDate) {
        this.startDate = new Date(cal.value[cal.value.length - 1]);
        this.startCal = cal;
        this.startCalNo = calNo;
      } else {
        let endDate = new Date(cal.value[cal.value.length - 1]);
        let month = this.startDate.getMonth();
        while (endDate > this.startDate) {
          this.startDate.setDate(this.startDate.getDate() + 1);
          let selectedDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate());
          if (selectedDate.getMonth() != month) {
            this.startCal = this['cals'].toArray()[this.startCalNo];
            this.startCalNo++;
            month = selectedDate.getMonth();
          }
          if (!this.startCal.value) this.startCal.value = new Array();
          if (this.startCal.value.map(Number).indexOf(+selectedDate) < 0) {
            this.startCal.value.push(new Date(selectedDate));
          }
        }
        this.startDate = null;
        this.startCal = null;
        this.startCalNo = null;
      }
    }
  }

  loadCalendar(comp: Calendar, month) {
    let defDate = new Date();
    let maxDate = new Date(defDate.getFullYear(), defDate.getMonth(), defDate.getDate());
    maxDate.setDate(1);
    maxDate.setMonth(maxDate.getMonth() + month);
    maxDate.setDate(maxDate.getDate() - 1);
    let minDate = new Date(defDate.getFullYear(), defDate.getMonth(), defDate.getDate());
    if (month > 1) {
      minDate.setDate(1);
      minDate.setMonth(minDate.getMonth() + month - 1);
    }
    maxDate.setHours(23);
    maxDate.setMinutes(59);
    maxDate.setSeconds(59);
    comp.defaultDate = minDate;
    comp.maxDate = maxDate;
    this.rangeSelectionMaxDate = maxDate;
    comp.minDate = minDate;
    comp.value = [];
    comp.ngOnInit();
  }

  public getSelectedDates() {
    let dates = new Array();
    for (let i = 0; i < this.cals.length; i++) {
      let val = this.cals.toArray()[i].value;
      if (val && val.length > 0) {
        dates = dates.concat(val.sort(Utilities.date_sort_asc));
      }
    }
    console.log("Sorted Dates: ", dates);
    return dates;
  }

  @ViewChild('clone') rateTemplate;
  @ViewChild('container', { read: ViewContainerRef }) tableContainer;
  componentReference = new Array();
  addRow() {
    let compRef = this.tableContainer.createEmbeddedView(this.rateTemplate);
    this.componentReference.push(compRef);
  }

  removeRow(tableRow) {
    let el = (tableRow.target || tableRow.srcElement);
    let index = el.parentElement.parentElement.rowIndex - 2;
    console.log("Index: ", index);
    //tableRow.srcElement.parentElement.parentElement.parentElement.removeChild(tableRow.srcElement.parentElement.parentElement);
    let compRef = this.componentReference[index];
    this.componentReference.splice(index, 1);
    compRef.destroy();
  }

  //Converted Sorted dates array DateRange array
  syncDateRange: DateRange;
  getDateRanges(selectedDates) {
    let dateRanges = new Array<DateRange>();
    this.syncDateRange = new DateRange();
    dateRanges.push(new DateRange());
    dateRanges[dateRanges.length - 1].Start = Utilities.convertToUTC(selectedDates[0]);
    this.syncDateRange.Start = Utilities.convertToUTC(selectedDates[0]);
    let endDate = new Date(selectedDates[0].getFullYear(), selectedDates[0].getMonth(), selectedDates[0].getDate());
    for (let idx = 1; idx < selectedDates.length; idx++) {
      endDate.setDate(endDate.getDate() + 1);
      if (Utilities.date_sort_asc(endDate, selectedDates[idx]) != 0) {
        dateRanges[dateRanges.length - 1].End = Utilities.convertToUTC(selectedDates[idx - 1]);
        dateRanges.push(new DateRange());
        dateRanges[dateRanges.length - 1].Start = Utilities.convertToUTC(selectedDates[idx]);
        endDate = new Date(selectedDates[idx].getFullYear(), selectedDates[idx].getMonth(), selectedDates[idx].getDate());
      }
    }
    dateRanges[dateRanges.length - 1].End = Utilities.convertToUTC(selectedDates[selectedDates.length - 1]);
    this.syncDateRange.End = Utilities.convertToUTC(selectedDates[selectedDates.length - 1]);
    return dateRanges;
  }

  clearDateRanges(event) {
    for (let i = 0; i < this.cals.length; i++) {
      this.cals.toArray()[i].value = [];
    }
    this.rangeDates = null;
  }

  selectedDaysOfWeek = ['0', '1', '2', '3', '4', '5', '6'];
  rangeDates = null;
  setDateRange(event) {
    console.log("Range Selection: ", this.rangeDates);
    console.log("Days Selection: ", this.selectedDaysOfWeek);
    if (this.rangeDates.length > 0 && this.selectedDaysOfWeek.length > 0) {
      let startDate = this.rangeDates[0];
      let endDate = (this.rangeDates.length == 2) ? this.rangeDates[1] : startDate;
      for (let i = 0; i < this.cals.length; i++) {
        let currCal = this.cals.toArray()[i];
        if (Utilities.date_sort_asc(currCal.minDate, startDate) != 1 &&
          Utilities.date_sort_asc(currCal.maxDate, startDate) != -1) {
          if (!currCal.value) currCal.value = [];
          let selDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
          let currMonth = startDate.getMonth();
          do {
            if (this.selectedDaysOfWeek.indexOf(selDate.getDay().toString()) >= 0 && currCal.value.map(Number).indexOf(+selDate) < 0) {
              currCal.value.push(new Date(selDate));
            }
            selDate.setDate(selDate.getDate() + 1);
            if (selDate.getMonth() != currMonth && ++i < this.cals.length) {
              currCal = this.cals.toArray()[i];
              currMonth = selDate.getMonth();
            }
          } while (Utilities.date_sort_asc(selDate, endDate) != 1)
          break;
        }
      }
    }
  }
  @ViewChild('anchor') anchor;
  anchorText = "more";
  showAllFields = false;
  showHideAll(event) {
    this.showAllFields = !this.showAllFields;
    if (this.showAllFields) this.anchorText = "less";
    else this.anchorText = "more"
    return false;
  }

  populateRowDetails(rowId, rrpDetails) {
    this.rooms.toArray()[rowId].value = [rrpDetails.RoomTypeId];
    this.rooms.toArray()[rowId].updateLabel();
    this.rates.toArray()[rowId].value = [rrpDetails.RatePlanId];
    this.rates.toArray()[rowId].updateLabel();
    this.availabilities.toArray()[rowId].nativeElement.value = rrpDetails.Availability;
    this.perDays.toArray()[rowId].nativeElement.value = rrpDetails.RoomRate.PerDay;
    if (this.singles && this.singles.length > 0) this.singles.toArray()[rowId].nativeElement.value = (rrpDetails.RoomRate.PerOccupancy && rrpDetails.RoomRate.PerOccupancy[1]) ? rrpDetails.RoomRate.PerOccupancy[1] : "";
    if (this.doubles && this.doubles.length > 0) this.doubles.toArray()[rowId].nativeElement.value = (rrpDetails.RoomRate.PerOccupancy && rrpDetails.RoomRate.PerOccupancy[2]) ? rrpDetails.RoomRate.PerOccupancy[2] : "";
    if (this.triples && this.triples.length > 0) this.triples.toArray()[rowId].nativeElement.value = (rrpDetails.RoomRate.PerOccupancy && rrpDetails.RoomRate.PerOccupancy[3]) ? rrpDetails.RoomRate.PerOccupancy[3] : "";
    if (this.quadras && this.quadras.length > 0) this.quadras.toArray()[rowId].nativeElement.value = (rrpDetails.RoomRate.PerOccupancy && rrpDetails.RoomRate.PerOccupancy[4]) ? rrpDetails.RoomRate.PerOccupancy[4] : "";
    if (this.pentas && this.pentas.length > 0) this.pentas.toArray()[rowId].nativeElement.value = (rrpDetails.RoomRate.PerOccupancy && rrpDetails.RoomRate.PerOccupancy[5]) ? rrpDetails.RoomRate.PerOccupancy[5] : "";
    if (this.hexas && this.hexas.length > 0) this.hexas.toArray()[rowId].nativeElement.value = (rrpDetails.RoomRate.PerOccupancy && rrpDetails.RoomRate.PerOccupancy[6]) ? rrpDetails.RoomRate.PerOccupancy[6] : "";
    if (this.heptas && this.heptas.length > 0) this.heptas.toArray()[rowId].nativeElement.value = (rrpDetails.RoomRate.PerOccupancy && rrpDetails.RoomRate.PerOccupancy[7]) ? rrpDetails.RoomRate.PerOccupancy[7] : "";
    if (this.octas && this.octas.length > 0) this.octas.toArray()[rowId].nativeElement.value = (rrpDetails.RoomRate.PerOccupancy && rrpDetails.RoomRate.PerOccupancy[8]) ? rrpDetails.RoomRate.PerOccupancy[8] : "";
    if (this.nonas && this.nonas.length > 0) this.nonas.toArray()[rowId].nativeElement.value = (rrpDetails.RoomRate.PerOccupancy && rrpDetails.RoomRate.PerOccupancy[9]) ? rrpDetails.RoomRate.PerOccupancy[9] : "";
    if (this.decas && this.decas.length > 0) this.decas.toArray()[rowId].nativeElement.value = (rrpDetails.RoomRate.PerOccupancy && rrpDetails.RoomRate.PerOccupancy[10]) ? rrpDetails.RoomRate.PerOccupancy[10] : "";
    this.extraPersons.toArray()[rowId].nativeElement.value = rrpDetails.RoomRate.PerExtraPerson;
    this.minStays.toArray()[rowId].nativeElement.value = rrpDetails.MinStay;
    this.maxStays.toArray()[rowId].nativeElement.value = rrpDetails.MaxStay;
    this.minAdvPurchasesDays.toArray()[rowId].nativeElement.value = rrpDetails.minAdvPurchaseDays;
    this.maxAdvPurchasesDays.toArray()[rowId].nativeElement.value = rrpDetails.maxAdvPurchaseDays;
    this.ctas.toArray()[rowId].value = rrpDetails.CTA.toString();
    this.ctds.toArray()[rowId].value = rrpDetails.CTD.toString();
    // this.minStaysArrival.toArray()[rowId].nativeElement.value = rrpDetails.MinStayArrival;
    // this.maxStaysArrival.toArray()[rowId].nativeElement.value = rrpDetails.MaxStayArrival;
    this.stopSells.toArray()[rowId].value = rrpDetails.IsClosed.toString();
    this.stopSells.toArray()[rowId].updateSelectedOption(rrpDetails.IsClosed.toString());
    this.ctas.toArray()[rowId].updateSelectedOption(rrpDetails.CTA.toString());
    this.ctds.toArray()[rowId].updateSelectedOption(rrpDetails.CTD.toString());
  }

  advanceText = "Show Advance"
  onAdvanceClick(event) {
    this.showAdvance = !this.showAdvance;
    if (this.showAdvance) this.advanceText = "Hide Advance";
    else {
      this.advanceText = "Show Advance";
      for (let i = 0; i < this.cals.length; i++) {
        this.cals.toArray()[i].value = [];
      }
      if (this.rangeDates != null && this.rangeDates.length > 0)
        this.setDateRange(null);
    }
    return false;
  }

  selectDateRange() {
    if (!this.showAdvance) {
      for (let i = 0; i < this.cals.length; i++) {
        this.cals.toArray()[i].value = [];
      }
      this.setDateRange(null);
    }
  }
  // #endregion Calendar (Date-Rangs) Component
}
