import { Injectable } from '@angular/core';
import { AppService, RMSBookingCriteria, RMSHotelCriteria, RevenueCalendarCriteria, Range } from 'mantras-api';
import { StorageService } from './storage.service';
import { LoadingService } from './loading.service';
import { Utilities } from './utilities';
import { TaskbarService } from './taskbar.service';
import { AppConfigService } from '../app-config.service';

@Injectable()
export class RmsService {

  constructor(public mantras:AppService,private storageService: StorageService, private loadingService: LoadingService, private utilities: Utilities,
    private taskService: TaskbarService,private appConfService:AppConfigService) {
    Utilities.initApp(mantras,appConfService);
  }

  getDateWiseBookings(rmsBookingCriteria: RMSBookingCriteria) {
    return this.loadingService.interceptor(this.mantras.GetDateWiseBookings(rmsBookingCriteria)
        .then(result => {
            return result;
        }).catch(error => {
            this.loadingService.showErrorDialog(error);
            throw error;
        }),true);
  }

  findRevenueAnalytics(criteria: RevenueCalendarCriteria, bForce=false) {
    let criteriaStringKey = JSON.stringify(criteria);
    let rmsResponse = this.storageService.get(criteriaStringKey);
    if(!rmsResponse || bForce){ 
        return this.loadingService.interceptor(this.mantras.FindRevenueCalendar(criteria)
            .then(result => {
                this.storageService.set(criteriaStringKey,result);
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }),true);
    } else {
        return Promise.resolve(rmsResponse);
    }
  }

  findRMSHotel(criteria: RMSHotelCriteria, bForce=false) {
    let criteriaStringKey = JSON.stringify(criteria);
    let rmsResponse = this.storageService.get(criteriaStringKey);
    if(!rmsResponse || bForce){
        return this.loadingService.interceptor(this.mantras.FindRMSHotel(criteria)
            .then(result => {
                this.storageService.set(criteriaStringKey,result);
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }),true);
    } else {
        return Promise.resolve(rmsResponse);
    }
  }

  getForecast(hotelId, stayDates: Range<Date>, channels) {
    return this.loadingService.interceptor(this.mantras.GetForecast(hotelId,stayDates,channels)
        .then(result => {
            return result;
        }).catch(error => {
            this.loadingService.showErrorDialog(error);
            throw error;
        }),true);
  }
}
