import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import * as Crypt from 'crypt';
import * as SHA1 from 'sha1';

import { Utilities } from './utilities';
import { StorageService } from './storage.service';
import { AppService, DomainProfileCriteria } from 'mantras-api';
import { DomainContext, Session, UserCriteria } from 'mantras-api';
import { LoadingService } from './loading.service';
import { TaskbarService } from './taskbar.service';
import { UserService } from './user.service';
import { CustomReuseStrategy } from './reuse-routes';
import { AppConfigService } from '../app-config.service';

@Injectable()
export class AuthenticationService {
    hotels: any;
    hotelId: any;
    selectedDomain: any;
    currentUser: any;
    timestamp = null;
    //mantras: AppService;
    constructor(private mantras: AppService, private storageService: StorageService, private utilities: Utilities
        , private loadingService: LoadingService, private taskbarService: TaskbarService,private appConfService:AppConfigService, private userService: UserService) {
        //this.mantras = appService;
        Utilities.initApp(mantras,appConfService);
    }

    login(username: string, password: string, isPwdEncrypted = false): Promise<Session> {
        let session = null;
        var modifiedPwd = password;
        if (!isPwdEncrypted) {
            //Converted the password string to UTF16 bytes array
            let passwordToValidate = Utilities.stringToBytes(password);
            console.log("Password to validate: ", passwordToValidate);
            console.log("SHA1 Encryption: ", SHA1(passwordToValidate, { "asBytes": "true" }));
            console.log("bytesBase64 Encryption: ", Crypt.bytesToBase64(SHA1(passwordToValidate, { "asBytes": "true" })));
            modifiedPwd = Crypt.bytesToBase64(SHA1(passwordToValidate, { "asBytes": "true" }));
        }
        // 1. Login //"udVNP7oVgbyR0WJRiDWQ0PlvosM="
        //return this.mantras.login(username, password).then(
        return this.loadingService.interceptor(this.mantras.login(username, modifiedPwd).then(
            async result => {
                if (result.Timestamp) {
                    this.timestamp = result.Timestamp;
                    this.storageService.set(StorageService.serverTimestamp, this.timestamp);
                }
                //Restore Data from LocalStorage to SessionStorage
                this.storageService.restoreData();
                //Push Login User to storage.
                this.storageService.set(StorageService.loginUserId, username.toLowerCase());
                //Check last domain switch for the user to last active domain if exist.
                let userContext = this.storageService.get(StorageService.userContext);
                let lastHotelContext = this.storageService.get(StorageService.userHotelContext);
                let lastDomainContext = this.storageService.get(StorageService.userDomainContext);
                //let newUserContext = result.User; 
                let switchDomainResp = "", isError = false;
                let hotelId;
                let domainId;
                if ((userContext != undefined && userContext != null) && userContext.Id.toLowerCase() == username && lastHotelContext && lastDomainContext) {
                    hotelId = lastHotelContext.HotelId;
                    domainId = lastDomainContext.CurrentDomainId;
                    if (hotelId && domainId) {
                        let domain = Utilities.findObject(result.DomainContext.Domains, domainId);
                        if (domain) {
                            let hotel = Utilities.findObject(domain.DomainHotels, hotelId);
                            if (hotel) {
                                switchDomainResp = await this.switchDomain(domainId, hotelId).then(
                                    async response => {
                                        return response;
                                    }).catch(
                                    async response => {
                                        isError = true;
                                        return response;
                                    });
                            } else {
                                hotelId = null;
                            }
                        } else {
                            domainId = null;
                        }
                    }
                }
                if (!hotelId || !domainId) {
                    if (result.Timestamp) {
                        this.timestamp = result.Timestamp;
                        this.storageService.set(StorageService.serverTimestamp, this.timestamp);
                    }
                    //Handling if the previous domain context is unavailable in storage.
                    domainId = result.DomainContext.CurrentDomainId;
                    //System User : DomainContext.CurrentDomainId = System
                    if (domainId.toLowerCase() != "system") {
                        if (result.DomainContext.Domains.length > 0) {
                            let domains = result.DomainContext.Domains;
                            for (let d = 0; d < domains.length; d++) {
                                let hotels = domains[d].DomainHotels;
                                domainId = domains[d].Id;
                                if (hotels.length > 0) {
                                    for (let h = 0; h < hotels.length; h++) {
                                        hotelId = hotels[h].Id;
                                        break;
                                    }
                                    if (domainId && domainId != "" && hotelId && hotelId != "")
                                        break;
                                }
                            }
                        }
                        switchDomainResp = await this.switchDomain(domainId, hotelId).then(
                            async response => {
                                return response;
                            }).catch(
                            async response => {
                                isError = true;
                                return response;
                            });
                    } else {
                        return result;
                        //throw ({Text: "User has only System User access."});
                    }
                }
                if ((switchDomainResp == "" && !isError) || (switchDomainResp != "" && isError)) {
                    console.log("Switch domain failed.");
                    //Save  Hotel Context:
                    this.storageService.set(StorageService.currentUser, result);
                    this.storageService.set(StorageService.userHotelContext, result.HotelContext);
                    //Save  Domain Context:
                    this.storageService.set(StorageService.userDomainContext, result.DomainContext);
                }
                if (switchDomainResp["Timestamp"]) {
                    this.timestamp = switchDomainResp["Timestamp"];
                    this.storageService.set(StorageService.serverTimestamp, this.timestamp);
                }
                //Save User Context:
                //this.storageService.set(StorageService.userContext,result.User);
                this.storageService.set(StorageService.lastLoginTime, new Date());
                //Remove Switched User Context
                this.storageService.remove(StorageService.switchedUser);
                //Load pending Tasks for the user:
                this.taskbarService.loadTasks();
                return result;
            }
        ).catch(
            result => {
                console.log("Login failed");
                return result;
            }
            ));
    }

    logout() {
        // remove user from local storage to log user out
        this.taskbarService.resetTask();
        this.storageService.remove(StorageService.switchedUser);
        this.storageService.remove(StorageService.currentUser);
        this.storageService.persistData();
    }

    getDomaisHotelsList() {
        let domainContext: DomainContext = this.storageService.get(StorageService.userDomainContext);
        return domainContext.Domains;
    }

    switchDomain(domainId: string, hotelId: string): Promise<Session> {
        return this.loadingService.interceptor(this.mantras.switchSessionDomainContext(domainId, hotelId).then(
            result => {
                if (result.Timestamp) {
                    this.timestamp = result.Timestamp;
                    this.storageService.set(StorageService.serverTimestamp, this.timestamp);
                }
                ////Add Hotel to System domain
                // result.DomainContext.Domains.forEach(domain => {
                //     if(domain.Id == 'System'){
                //         domain.DomainHotels = [];
                //         domain.DomainHotels.push({Id: "System", Name: "System"});
                //     }
                // });

                this.storageService.set(StorageService.currentUser, result);
                let userId = this.storageService.get(StorageService.loginUserId);
                //TODO: Remove this null check, User must be present.
                if (result.User == null || userId == result.User.Id.toLowerCase()) {
                    if (result.User != null)
                        this.storageService.set(StorageService.loggedInUser, result);
                } else {
                    this.storageService.set(StorageService.switchedUser, result);
                }
                //Get Domain Hotel list:
                this.storageService.set(StorageService.userHotelContext, result.HotelContext);
                //Save  Domain Context:
                this.storageService.set(StorageService.userDomainContext, result.DomainContext);
                this.storageService.set(StorageService.userContext, result.User);
                CustomReuseStrategy.deactivateAllHandles();
                //if(domainId != "System"){
                this.storageService.userContextChanged(this.mantras.session);
                //}
                this.storageService.reloadHotelContextRequired = false;
                return this.mantras.session;
            }
        ).catch(
            result => {
                console.log("Login failed");
                throw result;
            }
            ));
    }

    getDomainHotel(hotelId: string, force?: boolean) {
        let domainHotel = this.storageService.get("DOMAIN_HOTEL_" + hotelId);
        if ((!domainHotel && domainHotel == null) || force) {
            let response = this.loadingService.interceptor(this.mantras.getdomainHotel(hotelId).then(async response => {
                if (response != null) {
                    this.storageService.set("DOMAIN_HOTEL_" + hotelId, response);
                    return response;
                }
            }), true);
            domainHotel = response;
        }
        return domainHotel;
    }

    getHotelChannels(hotelId: string, force?: boolean) {
        let hotelChannels = this.storageService.get("HOTELCHANNELS_" + hotelId);
        if (!hotelChannels && hotelChannels == null || force) {
            let response = this.mantras.getHotelChannels(hotelId).then(async response => {
                this.storageService.set("HOTELCHANNELS_" + hotelId, response);

                return response;
            }
            );
            hotelChannels = response;
        }
        return hotelChannels;
    }

    findDomains(domainCriteria,isLoading =false) {
        let fetchAllDomain = false;
        if(domainCriteria == null){
            let domains = this.storageService.get("ALLDOMAINS");
            if(domains != null) return domains;
            domainCriteria = new DomainProfileCriteria();
            fetchAllDomain = true;
        }
        let response = null;
        if(isLoading){
            response = this.loadingService.interceptor(this.mantras.FindDomains(domainCriteria).then(response => { 
                if(fetchAllDomain) this.storageService.set("ALLDOMAINS",response);
                return response; 
            }),true);
        } else {
            response = this.mantras.FindDomains(domainCriteria).then(response => { 
                if(fetchAllDomain) this.storageService.set("ALLDOMAINS",response);
                return response; 
            });
        }
        return response;
    }

    findDomainCatalog(domainCriteria) {
        let response = this.mantras.FindDomainCatalog(domainCriteria).then(response => { return response; });
        return response;
    }

    getDomainProfile(domainId: string, force = false) {
        let domainProfile = this.storageService.get("DOMAIN_PROFILE_" + domainId);
        if (!domainProfile && domainProfile == null || force) {
            domainProfile = this.loadingService.interceptor(this.mantras.GetDomainProfile(domainId)
                .then(async result => {
                    this.storageService.set("DOMAIN_PROFILE_" + domainId, result);
                    return result;
                }).catch(error => {
                    this.loadingService.showErrorDialog(error);
                    throw error;
                }), true);
        }
        return domainProfile;
    }

    findDomainProfiles(domainCriteria) {
        var domainProfiles = this.loadingService.interceptor(this.mantras.FindDomainProfiles(domainCriteria)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
        return domainProfiles;
    }

    saveDomain(domainProfile) {
        return this.loadingService.interceptor(this.mantras.SaveDomain(domainProfile)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    registerDomain(domainProfile,userId) {
        return this.loadingService.interceptor(this.mantras.RegisterDomain(domainProfile,userId)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    approveDomain(domainId) {
        return this.loadingService.interceptor(this.mantras.ApproveDomain(domainId)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    deleteDomain(domainId) {
        return this.loadingService.interceptor(this.mantras.DeleteDomain(domainId)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    findDomainUser(userCriteria) {
        let response = this.mantras.FindDomainUsers(userCriteria).then(response => { return response; });
        return response;
    }

    findHotelInfo(hotelCriteria) {
        var hotelInfo = this.loadingService.interceptor(this.mantras.FindHotelInfo(hotelCriteria)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
        return hotelInfo;
    }

    

    async switchSessionUserContex(domainId, userId, hotelId?) {
        let response = await this.loadingService.interceptor(this.mantras.switchSessionUserContex(domainId, userId).then(async response => {
            if (response != null) {
                console.log("Switching User");
                this.storageService.set(StorageService.userDomainContext, response.DomainContext);
                //Handling if the previous domain context is unavailable in storage.
                if (response.DomainContext.Domains.length > 0 && (hotelId == undefined || hotelId == null)) {
                    let domains = response.DomainContext.Domains;
                    for (let d = 0; d < domains.length; d++) {
                        if (domainId == domains[d].Id) {
                            if(domainId == "System"){
                                this.hotels = "System";
                            }
                           else{ this.hotels = domains[d].DomainHotels;}
                            if (this.hotels.length > 0) {
                                for (let h = 0; h < this.hotels.length; h++) {
                                    hotelId = this.hotels[h].Id;
                                    break;
                                }
                                if (domainId && domainId != "" && hotelId && hotelId != "")
                                    break;
                            }
                        }
                    }
                }
                let switchDomainResp = await this.switchDomain(domainId, hotelId).then(
                    async resp => {
                        return resp;
                    }).catch(
                    async resp => {
                        return resp;
                    });
            }
            return response;
        }));
        return response;
    }
    isSystemUser() {
        let currentUserDomains = this.storageService.get(StorageService.currentUser).DomainContext.Domains;
        if (Utilities.checkSystemAccess(currentUserDomains)) {
            return true;
        }
        else {
            return false;
        }
    }
    async isUserBlocked(): Promise<any> {
        if (this.isSystemUser()) {
            return false;
        }
        let hotelId = this.storageService.get(StorageService.userHotelContext).HotelId;
        let domainHotel = await this.getDomainHotel(hotelId);
        let serverTimestamp = this.timestamp ? this.timestamp : this.storageService.get(StorageService.serverTimestamp);
        if (domainHotel.BlockingDate == null) {
            return false;
        }
        if (serverTimestamp > domainHotel.BlockingDate) {
            return true;
        }
        else {
            return false;
        }
    }

    async isUserBlockedCount(): Promise<any> {
        let unblockDaysOffset = 0;
        let unblockDetails = null;
        this.currentUser = this.storageService.get(StorageService.currentUser);
        this.selectedDomain = this.currentUser.DomainContext.CurrentDomainId;
        if (this.selectedDomain == "System") {
            this.hotelId = "System";
         } else {
             this.hotelId = this.storageService.get(StorageService.userHotelContext).HotelId;
        }
        let serverTimestamp = this.timestamp ? this.timestamp : this.storageService.get(StorageService.serverTimestamp);
        let domainHotel = await this.getDomainHotel(this.hotelId);
        if (domainHotel.BlockingDate != null) {
            let displayDate = domainHotel.BlockingDate.split('T')[0];
            unblockDaysOffset = Math.floor((Date.parse(serverTimestamp) - Date.parse(domainHotel.BlockingDate)) / (1000 * 60 * 60 * 24));
            if (unblockDaysOffset >= 0) {
                unblockDetails = " Your access to this property is blocked due to Non-Payment, if already paid please contact your respective Account Manager or email at support@maximojo.com";
            } else if (unblockDaysOffset < 0) {
                unblockDaysOffset = Math.abs(unblockDaysOffset);
                unblockDetails = "Your Payment is due. Please pay to avoid discontinuation of your service on this property before " + displayDate + ", if already paid please contact your respective Account Manager or email at support@maximojo.com";
                if (unblockDaysOffset > 10) {
                    unblockDetails = null;
                }
            }
        }
        else unblockDetails = null;
        return unblockDetails;
    }
    getTimestamp() {
        if (this.timestamp) return new Date(this.timestamp);
        if (this.storageService.get(StorageService.serverTimestamp)) return new Date(this.storageService.get(StorageService.serverTimestamp));
        return null;
    }

}