import { Component, OnInit, ComponentFactoryResolver, ApplicationRef, Injector, ViewChild, ViewContainerRef, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { StorageService } from '../ui-services/storage.service';
import { Router } from '@angular/router';
import { EventManagementService } from '../ui-services/eventmanagement.service';
import { UserService } from '../ui-services/user.service';
import { LoadingService } from '../ui-services/loading.service';
import { GridOptions } from 'ag-grid';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { AutomationruledetailComponent } from './automationruledetail.component';
import { AlertsService } from '../ui-services/alerts.service';
import { AlertRuleCriteria } from 'mantras-api';

@Component({
  selector: 'app-automationrule',
  templateUrl: './automationrule.component.html',
  styleUrls: ['./automationrule.component.css']
})
export class AutomationruleComponent implements OnInit {

  menuItems;
  selectedStatus = '0';
  gridOptions;
  icons;
  columnDef;
  showDetailPage = false;
  subscription: Subscription;
  //filterSubscription: Subscription;
  channelMasterRoomMapping;
  channelMasterRateTypeMapping;
  taskCompleteNotificationSubscription: Subscription;
  isReadOnly = false;
  breadCrumbItems;
  constructor(private resolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector,
    public storageService: StorageService, private router:Router, private eventManager: EventManagementService,
    private userService:UserService,private loadingService:LoadingService, private alertsService: AlertsService) {
      // this.breadCrumbItems = [{label: "Settings", routerLink:["/settings"]},
      // {label: "Automation Rules", routerLink:["/settings/automationrule"]}];
      // loadingService.loadBreadCrumbs(this.breadCrumbItems);
    this.gridOptions = <GridOptions>{
      enableSorting: true,
      animateRows: true,
      groupDefaultExpanded: -1,
      rowHeight: 24,
      groupUseEntireRow: true,
      singleClickEdit: true,
      groupRowRendererParams: { suppressCount: true },
      suppressDragLeaveHidesColumns:  true,
      suppressContextMenu: true,
      localeText:{noRowsToShow:"No alerts rule configured. Please click on New to create new alerts rule"}
    };
    this.gridOptions.suppressLoadingOverlay = true;
    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    //Set the current class context (this) in gridOption context
    this.gridOptions.context = { thisComponent: this };
    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });
    
    this.isReadOnly = userService.isReadOnly();
  }

  ngOnInit() {
    //Initializing Menu Items 
    this.loadMenu();
    this.getAlerts();
  }

  loadMenu(){
    this.menuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', disabled: true, command: (event) => this.open(event) },
      { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: true, command: (event) => this.confirmDelete(event, true) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
    ];
  }
  
  alertsResponse;
  async getAlerts() {
    //Default
    let alertCriteria = new AlertRuleCriteria();
    alertCriteria.DomainId = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    alertCriteria.HotelId = this.storageService.get(StorageService.userHotelContext).HotelId;
    let finalResponse = [];
    finalResponse = await this.alertsService.findAlertRules(alertCriteria).then(
      localresp => {
        return localresp;
    });
    this.alertsResponse = finalResponse;
    this.renderAlerts();
  }
  
  renderAlerts() {
    this.populateCloumnDef();
    this.gridOptions.api.setColumnDefs(this.columnDef);
    this.gridOptions.api.setRowData(this.alertsResponse);
    this.gridOptions.api.sizeColumnsToFit();
    let setSelected = false;
    this.gridOptions.api.forEachNode(node => {
      if (!node.group && !setSelected) { node.setSelected(true); this.onRowClick(node); setSelected = true; }
    });
    if(!setSelected) this.loadMenu();
  }

  populateCloumnDef() {
    this.columnDef = [];
    this.columnDef.push({ headerName: "Name", cellClass: 'gridcol', field: "Name", menuTabs: [] });
    this.columnDef.push({ headerName: "Type", cellClass: 'gridcol', field: "Type", menuTabs: [] });
    this.columnDef.push({ headerName: "Enabled", width: 100, cellClass: 'gridcol', field: "IsEnabled", menuTabs: [],cellRenderer: params => {return `<input type='checkbox' readonly onclick='return false;' ${params.value ? 'checked' : ''} value=${params.value?1:0}/>`;}});
    this.columnDef.push({ headerName: "", cellClass: 'gridcol', menuTabs: [], width:20 });
  }

  onGridSizeChanged(event) {
    this.gridOptions.api.sizeColumnsToFit();
  }
  //
  onGridReady(event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.setHeight();
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true));
  }

  alertDetailsComp;
  onClickGridRow(params) {
    if (params.data)
      this.createNew(params.data);
  }

  onRowClick(params) {
    if (params.data) {
      this.menuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
        { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
        { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: this.isReadOnly, command: (event) => this.confirmDelete(event, true) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
      ];
    }
  }

  //Refresh Promotion Grid
  onRefresh(event) {
    this.getAlerts();
  }
  //OnClose
  onClose(){
    this.router.navigate(["/settings"]);
  }

  //Create New Promotion
  @ViewChild('details', { read: ViewContainerRef }) containerDetail: ViewContainerRef;
  createNew(data?) {
    this.containerDetail.clear();
    let factory = this.resolver.resolveComponentFactory(AutomationruledetailComponent);
    this.alertDetailsComp = <AutomationruledetailComponent>this.containerDetail.createComponent(factory).instance;
    if (data) this.alertDetailsComp.alertRule = data;
    else this.alertDetailsComp.alertRule = {};// = new v5AlertRuleBase();
    this.alertDetailsComp.loadData();
    this.alertDetailsComp.onClose.subscribe(this.onDetailsClose);
    this.showDetailPage = true;
    this.alertDetailsComp.setHeight();
  }
  //Open Promotion
  open(event) {
    this.createNew(this.gridOptions.api.getSelectedRows()[0]);
  }
  //Delete Promotion
  @ViewChild('showConfirmationDialog') showConfirmationDialog;
  showDeleteConfirmation = false;
  deleteId;
  confirmDelete(params, fromGrid?) {
    if (!fromGrid) {
      if (params.node.data) {
        this.deleteId = params.node.data.Id;
        this.showDeleteConfirmation = true;
      }
    } else {
      let selectedRow = this.gridOptions.api.getSelectedRows();
      if(selectedRow && selectedRow.length > 0){
        this.deleteId = selectedRow[0].Id;
        this.showDeleteConfirmation = true;
      }
    }
  }

  closeDialog(event) {
    this.deleteId = null;
    this.showDeleteConfirmation = false;
    this.setHeight();
  }

  async delete(event) {
    this.showDeleteConfirmation = false;
    if (this.deleteId != null) {
      // let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
      // let alertCriteria = new AlertRuleCriteria();
      // alertCriteria.Id = this.deleteId;
      // alertCriteria.HotelId = hotelContext.HotelId;
      // alertCriteria.DomainId = hotelContext.DomainId;
      // let finalResponse = await this.alertsService.deleteAlertRules(alertCriteria).then(
      //   localresp => { return localresp; });
    }
    this.onRefresh(event);
  }

  onDetailsClose = (event): void => {
    this.showDetailPage = false;
    if (event) this.onRefresh(event);
    else this.setHeight();
  }

  getChannelRoom(roomId, channelType) {
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    if (channelType == "MAX") {
      return Utilities.findObject(hotelContext.RoomTypes, roomId);
    } else {
      return Utilities.findMasterRoomType(roomId, channelType, hotelContext.RoomTypes);
    }
  }

  getChannelRatePlan(ratePlanId, channelType) {
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    if (channelType == "MAX") {
      return Utilities.findObject(hotelContext.RatePlans, ratePlanId);
    } else {
      return Utilities.findMasterRateType(ratePlanId, channelType, hotelContext.RatePlans);
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    //this.filterSubscription.unsubscribe();
  }

}
