import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AuthenticationService } from '../ui-services/authentication.service';
import { HotelCatalogComponent } from '../hotelcatalog/hotelcatalog.component';
import { HotelCriteria, GeoCode } from 'mantras-api';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'hotelcatalogcriteria',
    templateUrl: './hotelcatalogcriteria.component.html',
    styleUrls: ['./hotelcatalogcriteria.component.css']
})
export class HotelCatalogCriteriaComponent implements OnInit {
    //hotelcatalog:HotelCatalogComponent;
    hotelCatalogCriteria = new HotelCriteria();
    hotelCatalogName;
    hotelCatalogId;
    city;
    distance;
    latitude;
    longitude;
    chkVal =true;
    //searchButton =false;
    @Output('onsearch') onChange: EventEmitter<any>;

    constructor(private messageService: MessageService) {
        this.onChange = new EventEmitter();
    }

    ngOnInit() { }

    onSearch() {
        if (!this.buildCriteria()) {
            if(this.chkVal)
           this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'Error', detail: 'Please enter some criteria' });
        }
        else {
            this.onChange.emit(this.hotelCatalogCriteria);
           // this.searchButton = false;
        }
    }

    public buildCriteria() {
        this.chkVal = true;
        let returnVal = false;
        this.hotelCatalogCriteria = new HotelCriteria();
        if (this.hotelCatalogName && this.hotelCatalogName != "") {
            this.hotelCatalogCriteria.FreeText = this.hotelCatalogName;
            returnVal = true;
        }
        else {
            this.hotelCatalogCriteria.FreeText = null;
        }
        if (this.hotelCatalogId && this.hotelCatalogId != "") {
            this.hotelCatalogCriteria.Ids = [];
            this.hotelCatalogCriteria.Ids = this.hotelCatalogId.split(',', 500);
            returnVal = true;
        }
        else {
            this.hotelCatalogCriteria.Ids = null;
        }
        if (this.city && this.city != "") {
            this.hotelCatalogCriteria.City = this.city;
            returnVal = true;
        }
        else {
            this.hotelCatalogCriteria.City = null;
        }
        if (this.distance && this.distance != "" || this.latitude && this.latitude != "" || this.longitude && this.longitude != "") {
            if(this.distance == undefined || this.distance == "")
            { this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'Error', detail:"Please insert value for Distance"}); }
            if(this.latitude == undefined || this.latitude == "")
            { this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'Error', detail:"Please insert value for Latitude"});}
            if(this.longitude == undefined || this.longitude == "")
            { this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'Error', detail:"Please insert value for Longitude"});
            }   
            this.chkVal = false;         
        }

        if (this.distance && this.distance != "" && this.latitude && this.latitude != "" && this.longitude && this.longitude != "") {
            this.hotelCatalogCriteria.GeoCode = new GeoCode();            
            this.hotelCatalogCriteria.Distance = this.distance;
            this.hotelCatalogCriteria.GeoCode.Latitude = this.latitude;
            this.hotelCatalogCriteria.GeoCode.Longitude = this.longitude;
            this.chkVal = true;
            returnVal = true;
        }
        else {
            this.hotelCatalogCriteria.Distance = 0;
        }
        return returnVal;
    }

    onClear(){
        this.hotelCatalogName = null;
        this.hotelCatalogId = null;
        this.city = null;
        this.distance = null;
        this.latitude =null;
        this.longitude = null;
      }


}
