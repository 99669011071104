import { Component, OnInit, ViewChild, ViewContainerRef, HostListener, ComponentFactoryResolver } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { Subscription } from 'rxjs';
import { StorageService } from '../ui-services/storage.service';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';
import { MenuItem } from 'primeng/api';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { Router } from '@angular/router';
import { UserCriteria, DomainUser, DomainProfileCriteria, AppService } from 'mantras-api';
import { UserService, UserDomains } from '../ui-services/user.service';
import { UsersManagementDetailsComponent } from './usersmanagementdetails.component';


@Component({
  selector: 'usersmanagement',
  templateUrl: './usersmanagement.component.html',
  styleUrls: ['./usersmanagement.component.css']
})
export class UsersManagementComponent implements OnInit {
  columnDefs: any[];
  icons;
  gridOptions: GridOptions;
  rowData: any[];
  rowSelection: any;
  subscription: Subscription;
  userMgntMenuItems: MenuItem[];
  isDetailsPage = false;
  usrMngtDetails; usrIdObj;
  listUserDomains: string[] = [];
  userDomainObj; userList; userDetailsList; inAciveCount;
  isRefresh = false;

  constructor(private storageService: StorageService, private mantras: AppService, private router: Router, private service: UserService, private resolver: ComponentFactoryResolver, ) {
    this.columnDefs = [
      { headerName: "Login Id", field: "userid",headerTooltip:"Login ID", width: 70, menuTabs: [], cellClass: 'gridcol', sort: 'asc' },
      { headerName: "Name", field: "firstname",headerTooltip:"Name", width: 80, menuTabs: [], cellClass: 'gridcol', valueFormatter: formatUserName },
      { headerName: "Is Active", field: "isactive",headerTooltip:"Status", width: 50, menuTabs: [], cellClass: 'gridcol', cellRenderer: params => { return `<input type='checkbox' readonly onclick='return false;' ${params.value ? 'checked' : ''} value=${params.value ? 1 : 0}/>`; } },
      { headerName: "Domain Count", field: "DomainUsers", headerTooltip:"Domain Count", width: 80, menuTabs: [], cellClass: 'gridcol', valueFormatter: domaincount },
    ];

    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }

    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      localeText: { noRowsToShow: "No User(s) found" },
      //sortingOrder: ["desc", "asc"]
    };
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });

  }

    @ViewChild('usersmanagementcriteria', { read: ViewContainerRef }) usersmanagementcriteria;
  async onSearchCriteria(criteria?: UserCriteria) {
    await this.service.findDomainUsers(criteria).then(
      async response => {
        this.usrIdObj = [];
        this.userList = [];
        var usrDomainObject;
        this.listUserDomains = [];
        for(let i = 0 ; i < response.length ; i++) {
          let element = response[i];
          let index = this.usrIdObj.indexOf(element.Id);
          if (index >= 0) {
            usrDomainObject = this.listUserDomains[index];
            usrDomainObject.isactive = usrDomainObject.isactive || element.IsActive;
            usrDomainObject.inActiveDomain += element.IsActive ? 0 : 1;
            usrDomainObject.DomainUsers.push(element);
          } else {
            usrDomainObject = new UserDomains();
            usrDomainObject.inActiveDomain = 0;
            usrDomainObject.DomainUsers = [];
            usrDomainObject.userid = element.Id;
            usrDomainObject.firstname = element.FirstName;
            usrDomainObject.lastname = element.LastName;
            if (criteria.DomainId != null && criteria.Id == null) {
              let domCriteria = new UserCriteria();
              domCriteria.Id = element.Id;
               let resp = await this.service.findDomainUsers(domCriteria);
                resp.forEach(userDomains => {
                  usrDomainObject.DomainUsers.push(userDomains);
                  usrDomainObject.isactive = usrDomainObject.isactive || userDomains.IsActive;
                  usrDomainObject.inActiveDomain += userDomains.IsActive ? 0 : 1;
                });
            }
            else{
              usrDomainObject.DomainUsers.push(element);
            }
            this.listUserDomains.push(usrDomainObject);
            this.usrIdObj.push(element.Id);
          }
        }
        this.gridOptions.api.setRowData(this.listUserDomains);
        this.gridOptions.api.sizeColumnsToFit();
        this.onGridReady(this);
        this.setHeight();
        // if (response.length > 0) {
        //   this.showCriteria = false;
        // }
      });
    
    this.loadMenu();
  }

  @ViewChild('usersmanagementdetails', { read: ViewContainerRef }) usersmanagementdetails: ViewContainerRef;
  usersmanagementdetailsComp;
  createNew(data?) {
    this.usersmanagementdetails.clear();
    let factory = this.resolver.resolveComponentFactory(UsersManagementDetailsComponent);
    this.usersmanagementdetailsComp = <UsersManagementDetailsComponent>this.usersmanagementdetails.createComponent(factory).instance;
    if (data) this.usersmanagementdetailsComp.user = data;
    else this.usersmanagementdetailsComp.user = new DomainUser();
    this.usersmanagementdetailsComp.loadData(data);
    this.usersmanagementdetailsComp.onClose.subscribe(this.onDetailsClose);
    this.isDetailsPage = true;
    this.usersmanagementdetailsComp.setHeight();
  }

  ngOnInit() {
    this.loadMenu();
  }

  loadMenu() {
    this.userMgntMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
    ];
  }
  onRowClick(params) {
    if (params.data) {
      this.userMgntMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
      ];
    }
  }

  onRefreshChk(event){
    this.isRefresh = event;
  }

  onRefresh(event) {
    if(this.usageCriteria)
      this.onSearchCriteria(this.usageCriteria);
  }

  onDetailsClose = (event): void => {
    this.isDetailsPage = false;
    if (this.isRefresh && event){
       this.onRefresh(event);
    }
    else this.setHeight();
  }

  onClickGridRow(params) {
    if (params.data)
      this.createNew(params.data);
  }


  open(event) {
    this.createNew(this.gridOptions.api.getSelectedRows()[0]);
  }

  onClose() {
    this.router.navigate(["/settings"]);
  }

  usageCriteria;
  onSearch(event) {
    this.usageCriteria = event;
    if(this.usageCriteria)
      this.onSearchCriteria(event);
  }

  showCriteria = true;
  hideShowCriteria() {
    this.showCriteria = !this.showCriteria;
    this.setHeight();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true));
  }

  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }
  onGridSizeChanged($event) {
    this.gridOptions.api.sizeColumnsToFit();
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
function formatUserName(param) {
  let firstName = param.value;
  if (param.data.lastname) {
    let lastName = param.data.lastname;
    return firstName + " " + lastName;
  }
  else return firstName;
}

function domaincount(param) {
  if(param.data.inActiveDomain > 0)
    return param.data.DomainUsers.length + " " +"("+param.data.inActiveDomain+" "+"Inactive)";
  else
    return param.data.DomainUsers.length;
}