import { Component, OnInit, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { RatePlanDetail } from 'mantras-api';
import { HotelManagementService } from '../../ui-services/hotelmanagement.service';
import { StorageService } from '../../ui-services/storage.service';
import { Utilities } from '../../ui-services/utilities';
import * as jQuery from 'jquery';
import { UserService } from '../../ui-services/user.service';
import { AuthenticationService } from '../../ui-services/authentication.service';

@Component({
  selector: 'rateplandetails',
  templateUrl: './rateplandetails.component.html',
  styleUrls: ['./rateplandetails.component.css']
})
export class RateplandetailsComponent implements OnInit {

  @Input() ratePlan: RatePlanDetail = new RatePlanDetail();
  @Input() rateplans: any;
  @Output('onClose') onClose = new EventEmitter<boolean>();
  isReadOnly = false;
  errorMsg = "";
  rateplanMenuItems = [];
  valueTypes = [{ label: "Amount", value: 0 }, { label: "Percent", value: 1 }];
  mealPlans = Utilities.mealPlans;
  selectedMealPlan;
  selectedOTAPartners = [];
  OTAPartners = Utilities.OTAPartners;

  isRefundable = false;
  isLinked = false;
  hotelContext;
  sortOrder;
  linkedRateplanId;
  ratePlans = [];
  @Output() dataEmitter : EventEmitter<any> = new EventEmitter();
  constructor(private storageService: StorageService, private hotelMgmtService: HotelManagementService,private userService:UserService,private authService:AuthenticationService) {
    this.isReadOnly = userService.isReadOnly();
    this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;

  }

  ngOnInit() {
    this.rateplanMenuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', disabled: this.isReadOnly, command: (event) => this.onSave(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }];
  }

  loadData() {
    this.ratePlans.push({ label: "None", value: null });
    if (this.ratePlan.Tags) {
      this.sortOrder = this.ratePlan.Tags["Display Order"];
      var isRefund = this.ratePlan.Tags["IsRefundable"];
      if(isRefund && isRefund.toLowerCase() == "true"){
        this.isRefundable = true;
      }
      this.selectedMealPlan = this.ratePlan.Tags["Meal Plan Code"];
      if (this.ratePlan.Tags["Partners Code"])
        this.selectedOTAPartners = this.ratePlan.Tags["Partners Code"].split(";");
      if (this.ratePlan.LinkedRatePlanId) this.isLinked = true;

    }
    if (this.rateplans) {
      this.rateplans.forEach(rp => {
        if (this.ratePlan.Id != rp.Id)
          this.ratePlans.push({ label: rp.Name, value: rp.Id });
      });
    }
    this.getMenuItemData();
    if(!this.ratePlan.Id) this.ratePlan.IsActive = true;
    this.setHeight();
  }

  onCancel(event?) {
    this.onClose.emit(event);
  }

  onSave(event) {
    if (!this.ratePlan.Tags) this.ratePlan.Tags = new Map<string, string>();
    this.ratePlan.Tags["Display Order"] = this.sortOrder;
    this.ratePlan.Tags["IsRefundable"] = this.isRefundable;
    if (this.selectedMealPlan)
      this.ratePlan.Tags["Meal Plan Code"] = this.selectedMealPlan;
    if (this.selectedOTAPartners && Object.keys(this.selectedOTAPartners).length != 0)
      this.ratePlan.Tags["Partners Code"] = this.selectedOTAPartners.toString().replace(/,/gi, ";");

    if(!this.isLinked) this.ratePlan.LinkedRatePlanId = null;
    if (this.ratePlan.LinkedRatePlanId == null) {
      this.ratePlan.LinkedAmount = 0;
    }
    if (this.ratePlan.AdvancePurchase == null) {
      this.ratePlan.AdvancePurchase = 0;
    }
    this.ratePlan.ChannelCode = "MAX";
    if(!this.ratePlan.Id || this.ratePlan.Id === null)this.ratePlan.Id = Utilities.generateGUID();
    this.hotelMgmtService.saveRatePlan(this.hotelContext.HotelId, [this.ratePlan]).then(resp => {
      this.onCancel(true);
    });
  }
  displayOtaPartners(){
    if(this.authService.isSystemUser()){
      return 'block';
  }
  else return 'none';
  }
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.ratePlanSection').css('height', Utilities.getScrollHeight(true,true,false,false));
  }
  getMenuItemData(){
    this.rateplanMenuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', disabled: this.isReadOnly, command: (event) => this.onSave(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }];
      let menuData = [];
      menuData.push({'source':'roomPlanDetails'});
      menuData.push(this.rateplanMenuItems);
      this.dataEmitter.emit(menuData);
  }
}
