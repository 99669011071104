import { Component, OnInit, Input, EventEmitter, Output, HostListener } from '@angular/core';

@Component({
  selector: 'mm-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  @Input() title: string;
  @Input() scrollHeight=200;
  @Output('onclose') onClose : EventEmitter<any>;
  constructor() {
    this.onClose = new EventEmitter();
   }

  ngOnInit() {
  }

  emitEvent(event){
    this.onClose.emit(event);
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(event){
      let key = event.which || event.keyCode;
      if (key == 27)
      this.emitEvent(event);
    }
  }
}
