import { Component, OnInit, AfterViewInit } from '@angular/core';
/* Imports */
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/animated";
import { DataProviderService } from '../../ui-services/data-provider.service';
import * as moment from 'moment';
import { Utilities } from '../../ui-services/utilities'
/* Chart code */
// Themes begin
// am4core.useTheme(am4themes_kelly);
// Themes end

@Component({
  selector: 'app-dashboard-charts',
  templateUrl: './dashboard-charts.component.html',
  styleUrls: ['./dashboard-charts.component.css']
})
export class DashboardChartsComponent implements OnInit,AfterViewInit {
  channelTypes = [
    { Name: 'Agoda', Id: 0 },
    { Name: 'Booking', Id: 1 },
    { Name: 'Cleartrip', Id: 2 },
    { Name: 'Expedia', Id: 3 },
    { Name: 'Goibibo', Id: 4 },
    { Name: 'MMT', Id: 5 },
    { Name: 'Yatra', Id: 6 }];
  revData;
  revMonthlyData;
  invSnapshotData;
  saveEventDetails;
  showSaveEvent;
  hasEvents=false;
  displayMonthView=false;
  constructor(private dataProviderService:DataProviderService) { }

  ngOnInit() {
    this.showSaveEvent = false;
    this.revData = this.dataProviderService.getRevenueChartData();
    this.revMonthlyData = this.dataProviderService.getMonthlyRevenue();
    this.invSnapshotData = this.dataProviderService.getInvSnapshotData();
  }
  ngAfterViewInit(){  
    //Booking Analytics call
    this.getYearChart();
    //Booking Analytics call
    this.getMonthChart();
    //Service call - getRateCalendarSnapshot with parameters Hotel ID,StartDate, End date
    //Chart Type - Column Stacked Chart
    this.getInventoryRateChart();
    // this.getPictorialColumnChart()
    this.getInventorySnapshot();
  }
  getYearChart(){
    let chart = am4core.create("dashboardRevChart", am4charts.XYChart);
    
    chart.data = this.revData;
    
    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 60;
    
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;

    // Create series
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = "Total";
    series.dataFields.dateX = "date";
    series.tooltipText = "";
    series.strokeWidth = 2;
    series.stacked = true;//new
    series.minBulletDistance = 15;
    series.fill = am4core.color("#0078D7");
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.label.interactionsEnabled = true;
    series.adapter.add('tooltipHTML', (text, target) => {
      let data = target.tooltipDataItem.dataContext;
      let returnData='';
      if (target.tooltipDataItem.dataContext) {
        returnData+='<center><strong>'+this.convertDateToString((new Date(data['date'])).toISOString())+'</strong></center>';
        returnData+='<table>'
        for(var d in data){
          if(d!='date' && d!='aY' && d!='Total'){
               returnData+='<tr>'
               +'<th align="left">'+d+'</th>'
               +'<td>'+this.numberWithCommas(data[d])+'</td>'
             +'</tr>';
          }
        }
        // returnData+='<h2 >'+'Total'+' : '+data['Total']+'</h2><hr/>';
        returnData+='<tr>'
        +'<th align="left">'+'Total'+'</th>'
        +'<td>'+this.numberWithCommas(data['Total'])+'</td>'
      +'</tr>';
      returnData+='</table>'
        return returnData;
      }
      else {
        return text;
      }
    });
    // Drop-shaped tooltips
    series.tooltip.background.cornerRadius = 20;
    series.tooltip.background.strokeOpacity = 0;
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.label.minWidth = 40;
    series.tooltip.label.minHeight = 40;
    series.tooltip.label.textAlign = "middle";
    series.tooltip.label.textValign = "middle";
    
    
    // Make bullets grow on hover
    var bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.strokeWidth = 2;
    bullet.circle.radius = 4;
    bullet.circle.fill = am4core.color("#fff");
    //Add click(hit) event on chart
    bullet.events.on("hit",ev => {
      var item = ev.target.dataItem.component.tooltipDataItem.dataContext;
      this.showSaveEvent = true;
      this.saveEventDetails = item;
//      alert("Date: " + this.convertDateToString(Utilities.convertToUTC(new Date(item["date"])).toISOString())+"\nTotal Revenue: " + item["Total"]);
    });

    var bullethover = bullet.states.create("hover");
    bullethover.properties.scale = 1.3;
 
    series.tooltip.pointerOrientation = "vertical";
    
    //Check if events are available or not
    //If yes, display them
    if(this.hasEvents){
      // Create series
      var lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "aY";
      lineSeries.dataFields.dateX = "date";
      lineSeries.strokeOpacity = 0;
      lineSeries.tooltipHTML = '<h3>Event on : {dateX}</h3>';
      // Add a bullet
      var eventBullet = lineSeries.bullets.push(new am4charts.Bullet());

      // Add a triangle to act as am arrow
      var arrow = eventBullet.createChild(am4core.Triangle);
      arrow.horizontalCenter = "middle";
      arrow.verticalCenter = "middle";
      arrow.strokeWidth = 0;
      arrow.fill = chart.colors.getIndex(0);
      arrow.direction = "top";
      arrow.width = 48;
      arrow.height = 48;
    }
    // Make a panning cursor
    chart.cursor = new am4charts.XYCursor();
    // chart.cursor.behavior = "panXY";
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = series;
    chart.cursor.events.on("dragstart", function(ev) {
      console.log("x: ", ev.target.xPosition);
      console.log("y: ", ev.target.yPosition);
    });
    chart.cursor.events.on("dragstop", function(ev) {
      console.log("x: ", ev.target.xPosition);
      console.log("y: ", ev.target.yPosition);
    });
    // Create vertical scrollbar and place it before the value axis
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.parent = chart.leftAxesContainer;
    chart.scrollbarY.toBack();

    // Create a horizontal scrollbar and place it underneath the date axis
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    
    chart.scrollbarX["series"].push(series);
    // Add scrollbar
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    chart.events.on("ready", function () {
      dateAxis.zoom({start:0.85, end:1});
    });
  }

getMonthChart(){
this.displayMonthView = !this.displayMonthView;
// Create chart instance
var chart = am4core.create("lineChartMonthly", am4charts.XYChart3D);

chart.data = this.revMonthlyData;

// Create axes
var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "month";
categoryAxis.title.text = "Months";
categoryAxis.renderer.minGridDistance = 20;

var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.title.text = "Revenue";
valueAxis.tooltip.disabled = true;

// Create series
var series = chart.series.push(new am4charts.ColumnSeries());
series.dataFields.valueY = "Total";
series.dataFields.categoryX = "month";
series.name = "Revenue";
series.tooltipText = "{name}: [bold]{valueY}[/]";
series.fill = am4core.color("#0078D7");
series.columns.template.events.on("hit", function(ev) {
  let pieChartData = this.getPieChartData(ev.target.dataItem.dataContext);
  console.log("clicked on ", ev.target.dataItem.dataContext);
  //https://www.amcharts.com/docs/v4/concepts/event-listeners/
  this.getPieChart(pieChartData,ev.target.dataItem.dataContext);
}, this);

// Add cursor
chart.cursor = new am4charts.XYCursor();
}
getPieChartData(inputData){
  let data = [];
  for(var key in inputData){
    if(key != 'Total' && key != 'month'){
      data.push({ota:key,revenue:inputData[key]});
    }
  }
  data.sort((a, b) => {
    if(a.revenue < b.revenue) {
      return 1;
    } else if(a.revenue > b.revenue) {
      return -1;
    } else {
      return 0;
    }
  });
  return data;
}
getPieChart(data,rawData){
  this.displayMonthView = !this.displayMonthView;
  let chart = am4core.create("pieChartThreeD", am4charts.PieChart);
  // Add data
  chart.data = data;
  chart.innerRadius = am4core.percent(40);
  chart.readerTitle = rawData.month;
  //Set center label
  chart.innerRadius = 60;
  var label = chart.seriesContainer.createChild(am4core.Label);
  label.text = 'Revenue\n'+this.numberWithCommas(rawData.Total);
  label.horizontalCenter = "middle";
  label.verticalCenter = "middle";
  label.fontSize = 20;

  // Add and configure Series
  let pieSeries = chart.series.push(new am4charts.PieSeries());
  pieSeries.dataFields.value = "revenue";
  pieSeries.dataFields.category = "ota";
  // Add a legend
  chart.legend = new am4charts.Legend();
}
getInventoryRateChart(){
  let chart = am4core.create("rateInventoryChart", am4charts.XYChart);

  // Add data
  chart.data = this.dataProviderService.getRateInventoryData();

  // Create axes
  let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  dateAxis.renderer.grid.template.location = 0;
  dateAxis.renderer.minGridDistance = 30;


  let valueAxis1 = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis1.title.text = "Inventory";
  valueAxis1.renderer.opposite = true;
  valueAxis1.renderer.grid.template.disabled = true;

  let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
  valueAxis2.title.text = "Rate";
  // valueAxis2.min= 0;

  // Create series
  let series1 = chart.series.push(new am4charts.ColumnSeries());
  series1.dataFields.valueY = "soldRooms";
  series1.dataFields.dateX = "date";
  series1.yAxis = valueAxis1;
  series1.name = "Sold Room Nights";
  series1.tooltipText = "{name}\n[bold font-size: 20]{valueY}";
  series1.fill = am4core.color("#0078D7");
  series1.strokeWidth = 0;
  series1.clustered = false;
  series1.columns.template.width = am4core.percent(40);

  let series2 = chart.series.push(new am4charts.ColumnSeries());
  series2.dataFields.valueY = "totalInventory";
  series2.dataFields.dateX = "date";
  series2.yAxis = valueAxis1;
  series2.name = "Total Inventory";
  series2.tooltipText = "{name}\n[bold font-size: 20]{valueY}";
  series2.fill = am4core.color("#0078D7").lighten(0.5);
  series2.strokeWidth = 0;
  series2.clustered = false;
  series2.toBack();

  let series3 = chart.series.push(new am4charts.LineSeries());
  series3.dataFields.valueY = "currentRate";
  series3.dataFields.dateX = "date";
  series3.name = "Rate";
  series3.strokeWidth = 2;
  series3.tensionX = 0.7;
  series3.yAxis = valueAxis2;
  series3.tooltipText = "{name}\n[bold font-size: 20]{valueY}";

  let bullet3 = series3.bullets.push(new am4charts.CircleBullet());
  bullet3.circle.radius = 3;  
  bullet3.circle.strokeWidth = 2;
  bullet3.circle.fill = am4core.color("#fff");


  // Add cursor
  chart.cursor = new am4charts.XYCursor();

  // Add legend
  chart.legend = new am4charts.Legend();
  // chart.legend.position = "top";

  // Add scrollbar
  chart.scrollbarX = new am4charts.XYChartScrollbar();
  chart.scrollbarX["series"].push(series1);
  chart.scrollbarX["series"].push(series3);
  chart.scrollbarX.parent = chart.bottomAxesContainer;

}
getPictorialColumnChart(){
  let icon1 = "data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTc2IiBoZWlnaHQ9Ijg4OSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNODcuODIuMDY2djc2LjQzNmwtNC4wNjUgMi45ODR2NjUuNDE4bC0yLjkwNCAzLjQ0My0uNzc0IDYzLjEyMy01LjIyOCA1LjczOHY2MS45NzVsLTIuMzIzIDQuODItLjc3NCAxNzguNTgtOS42OCAyLjI5Ni0uNzc1IDEyOC41NC05LjEgMy4yMTR2MTE5LjU4OWwtNS42MTQgMS4xNDgtLjc3NCA4NC4wMS03LjU1IDEuMTQ4djQ5LjM1aC04LjMyNnYxMS4wMThoLTYuOTd2OC4yNjRILjd2MjAuNjU4aDE3NC40MzdWODcxLjE2aC0xNy40MjR2LTguMjY0aC0xMS4yM3YtOS42NGwtNy4zNTYtLjIzLS45NjgtNzMuNjgxLTcuNTUtLjkxOC45NjctOTguOTMtNy45MzgtMS44MzctLjc3NC0xMjIuMTE0LTYuOTctMS44MzYtMS4zNTUtMTM4LjE4Mi01LjYxNC03LjU3NFYzMDcuMTg3bC02LjAwMi05LjQxMXYuMjMtMzkuOTRsLTQuMjYtMy42NzMtLjc3NC0zNi45NTVoLS45Njh2LTQ2LjU5NmwtMy44NzItNi44ODZ2LTYwLjEzOWwtMy42NzgtMi45ODQtLjM4OC0yMS4xMTdWLjA2NmgtMS4xNjF6IiBmaWxsPSIjMDAwMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=";

let icon2 = "data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTAzIiBoZWlnaHQ9IjU3NSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAzIDU3Ny45VjI4OC41YzAtMS4xLTEuMi0yLjEtMi4yLTIuMWgtOFYxODIuMWMwLTEtMS4xLTIuMS0yLjEtMi4xSDc0LjRWOTguNWMwLTEtMS0yLTIuMS0ySDU5LjJzLjEtMS45LjEtMy0xLjEtMS4xLTEuMS0xLjFWNDIuN2gtMi4xVjhoLTN2ODQuNGgtMlY0Ni44aC0xLjl2NDUuOEgzNC45di00N2gtMS4ydjQ2LjloLTIuOVYuNWgtNC4ydjkyaC01LjF2NGgtOC4xYy0xLjEgMC0yIDEtMiAydjE4OEgyLjJjLTEuMSAwLTIuMSAxLTIuMSAydjI4OS40SDEwM3oiIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==";

let icon3 = "data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iODMiIGhlaWdodD0iNTU2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0zOS44Ljh2NTYuNWwtNS4xIDEuM3Y1aDN2M2gxLjh2MmgtMy44Yy0uOSAwLTIuMSAxLTIuMSAydjE2LjloLTRzLjkgMTUuOS45IDE2LjljMCAxIDEuMSAyIDIuMSAyaDF2MTYuOWgtNy4xYy0xIDAtMiAxLTIgMnY5aC04LjFjLTEuMSAwLTIgMS0yIDJ2MS45aC0ybDQuMSAzOC43aC0zbDMuOSAzNy45aC0zLjlsMy43IDM3LjdoLTMuOWw0LjQgMzcuN2gtNC4zbDMuOSAzNi44aC00bDQuMiAzNi45aC00LjNsNC40IDM4LjdoLTQuM2w0IDM5LjhzLTEuNy0uMS0yLjUgMGMtLjggMC0xLjEgMS4xLTEuMyAxLjgtLjIuNy0xMy4xIDExNS0xMy4xIDExNWw0MS0uMmguN2w0MSAuMlM3MC4yIDQ0NC45IDcwIDQ0NC4yYy0uMi0uNy0uNS0xLjgtMS4zLTEuOC0uOC0uMS0yLjUgMC0yLjUgMGw0LTM5LjhoLTQuM2w0LjQtMzguN0g2Nmw0LjItMzYuOWgtNGwzLjktMzYuOGgtNC4zbDQuNC0zNy43aC0zLjlsMy43LTM3LjdoLTMuOWwzLjktMzcuOWgtM2w0LjEtMzguN2gtMnYtMS45YzAtMS4xLS45LTItMi0ySDU5di05YzAtMS0xLTItMi0yaC03LjF2LTE2LjloMWMxIDAgMi4xLTEgMi4xLTJzLjktMTYuOS45LTE2LjloLTRWNzAuNmMwLTEtMS4xLTItMi4xLTJINDR2LTJoMS44di0zaDN2LTVsLTUuMS0xLjNWLjhoLTMuOXoiIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==";

let icon4 = "data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTgwIiBoZWlnaHQ9IjQ5MyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMzAuNy40djQwLjhoLTJ2NmgydjIuNWwtMSAzLjVoLTFjLTEgMC0yIDEtMiAydjJoLTFjLS45IDAtMiAxLTIgMnY1LjloLTFjLTEuMSAwLTIuMSAxLTIuMSAydjhoLTFjLTEgMC0yIDEtMiAyVjg2aC0xYy0xIDAtMi4xIDEtMi4xIDJ2MTYuOWgtMi4xYy0xIDAtMiAuOS0yIDJ2MjMuOWgtMmMtMSAwLTIgMS0yIDJ2NDAuN2gtMmMtMSAwLTIgMS0yIDJ2NTEuN2MwIC45LTIuMSAxLjItMi4xIDJ2MjY2LjJsMzEuOCAxaDFsMjguOC0uOWg1Ni4ybDI4LjguOWgxbDMxLjgtMVYyMjkuMmMwLS44LTIuMS0xLjEtMi4xLTJ2LTUxLjdjMC0xLTEtMi0yLTJoLTJ2LTQwLjdjMC0uOS0xLjEtMi0yLjEtMmgtMnYtMjMuOWMwLTEtMS4xLTItMi0yaC0yLjFWODhjMC0xLTEtMi0yLjEtMmgtMXYtOC45YzAtMS0xLTItMi0yaC0xdi04YzAtMS0xLTItMi0yaC0xLjF2LTUuOWMwLTEtMS4xLTItMi0yaC0xdi0yYzAtMS0xLTItMi0yaC0xbC0xLTMuNXYtMi41aDJ2LTZoLTJWLjRoLTMuOHY0MC44aC0ydjZoMnYyLjVsLTEgMy41aC0xYy0xIDAtMiAxLTIgMnYyaC0xYy0uOSAwLTIgMS0yIDJ2NS45aC0xYy0xLjEgMC0yLjEgMS0yLjEgMnY4aC0xYy0xIDAtMiAxLTIgMlY4NmgtMWMtMSAwLTIuMSAxLTIuMSAydjE2LjloLTIuMWMtMSAwLTIgLjktMiAydjIzLjloLTJjLTEgMC0yIDEtMiAydjQwLjdoLTJjLTEgMC0yIDEtMiAydjUxLjdjMCAuOS0yLjEgMS4yLTIuMSAydjczLjVINjV2LTczLjVjMC0uOC0yLjEtMS4xLTIuMS0ydi01MS43YzAtMS0xLTItMi0yaC0ydi00MC43YzAtLjktMS4xLTItMi4xLTJoLTJ2LTIzLjljMC0xLTEuMS0yLTItMmgtMi4xVjg4YzAtMS0xLTItMi4xLTJoLTF2LTguOWMwLTEtMS0yLTItMmgtMXYtOGMwLTEtMS0yLTItMmgtMS4xdi01LjljMC0xLTEuMS0yLTItMmgtMXYtMmMwLTEtMS0yLTItMmgtMWwtMS0zLjV2LTIuNWgydi02aC0yVi40aC0zLjh6TTY1IDMxMy42aDIzLjRsLjEuMUw2NSAzNTkuMXYtNDUuNXptMjYuNyAwaDIzLjZ2NDUuOGwtMjMuNi00NS44em0tMS43IDMuM2wyNS4yIDQ5Ljd2MTA2LjNINjVWMzY2LjJsMjUtNDkuM3oiIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==";

let icon5 = "data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMTQ0IiBoZWlnaHQ9IjQ4NSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNjkuOS4zdjQwSDY5Yy0uOCAwLTIuMSAxLjEtMi4xIDIuMXYyOC43YzAgMS4xLTEuMi45LTEuMiAyLjF2MjUuOWMwIC45LTEgMS4xLTEgMnY5LjRsLTcuMiAxOS40aC00Yy0xIDAtMi4xIDEtMi4xIDJ2NWgtM2MtMSAwLTIuMSAxLTIuMSAydjI3LjhoLTNjLTEgMC0yLjEuOS0yLjEgMnYzMy44YzAgMS40LTMgMi4xLTMgM3YxNjIuMWgtNWMtMSAwLTIuMSAxLTIuMSAydjM1LjhIMjBjLTEgMC0yLjEgMS0yLjEgMnY1My43SDIuN2MtMSAwLTIgMS0yIDJ2MjQuNWw3MS4xLjdoLjZsNzEuMS0uN3YtMjQuNWMwLTEtMS0yLTItMmgtMTUuMnYtNTMuN2MwLTEtMS0yLTIuMS0yaC0xMS4xdi0zNS44YzAtMS0xLTItMi4xLTJoLTVWMjA1LjVjMC0xLTMuMS0xLjYtMy4xLTN2LTMzLjhjMC0xLTEtMi0yLTJoLTN2LTI3LjhjMC0uOS0xLTItMi4xLTJoLTN2LTVjMC0xLTEtMi0yLTJoLTRsLTcuMi0xOS40di05LjRjMC0uOS0xLTEuMS0xLTJWNzMuMmMwLTEuMi0xLjItMS0xLjItMi4xVjQyLjRjMC0xLTEuMi0yLjEtMi4xLTIuMWgtLjlWLjNoLTQuNXoiIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==";

// Create chart instance
let chart = am4core.create("pictorialColumnChart", am4charts.XYChart);
chart.hiddenState.properties.opacity = 0;
chart.defaultState.transitionDuration = 5000;

// Add data
chart.data = [{
  "category": "Burj Khalifa",
  "height": 828,
  "ratio": 1 / 5.12,
  "icon": icon1
}, {
  "category": "Willis Tower",
  "height": 527,
  "ratio": 1 / 5.06,
  "icon": icon2
}, {
  "category": "Taipei 101",
  "height": 508,
  "ratio": 1 / 6.73,
  "icon": icon3
}, {
  "category": "Petronas Towers",
  "height": 452,
  "ratio": 1 / 2.76,
  "icon": icon4
}, {
  "category": "Empire State Building",
  "height": 449,
  "ratio": 1 / 3.41,
  "icon": icon5
}];

// Create axes
let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
categoryAxis.dataFields.category = "category";
categoryAxis.renderer.grid.template.location = 0;
categoryAxis.renderer.grid.template.disabled = true;
categoryAxis.renderer.labels.template.fill = am4core.color("#ffffff");
categoryAxis.renderer.labels.template.fillOpacity = 0.5;
categoryAxis.renderer.inside = true;

chart.paddingBottom = 0;

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.min = 0;
valueAxis.max = 1000;
valueAxis.strictMinMax = true;
valueAxis.renderer.grid.template.strokeDasharray = "4,4";
valueAxis.renderer.minLabelPosition = 0.05;

// Create series
let series = chart.series.push(new am4charts.ColumnSeries());
series.dataFields.valueY = "height";
series.dataFields.categoryX = "category";
series.columns.template.disabled = true;

let bullet = series.bullets.push(new am4charts.Bullet());
bullet.defaultState.properties.opacity = 0.5;

let hoverState = bullet.states.create("hover");
hoverState.properties.opacity = 0.9;

let image = bullet.createChild(am4core.Image);
image.horizontalCenter = "middle";
image.verticalCenter = "top";

image.propertyFields.href = "icon";
image.height = am4core.percent(100);
image.propertyFields.widthRatio = "ratio";

bullet.events.on("positionchanged", (event)=>{
    event.target.deepInvalidate();
});

let label = series.bullets.push(new am4charts.LabelBullet());
label.label.text = "{height} metres";
label.dy = -15;

let gradient = new am4core.LinearGradient();
gradient.addColor(am4core.color("#f0b24f"));
gradient.addColor(am4core.color("#ca6c46"));
gradient.addColor(am4core.color("#0c0524"));
gradient.rotation = 90;
chart.background.fill = gradient;
}
getInventorySnapshot(){
  let chart = am4core.create("invSnapshotChart", am4charts.RadarChart);
chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

let label = chart.createChild(am4core.Label);
label.text = "Drag slider to change radius";
label.exportable = false;

chart.data = this.invSnapshotData;
chart.radius = am4core.percent(95);
chart.startAngle = 270 - 180;
chart.endAngle = 270 + 180;
chart.innerRadius = am4core.percent(60);

let dateAxis = chart.xAxes.push((new am4charts.DateAxis<am4charts.AxisRendererCircular>()));
// dateAxis.renderer.labels.template.location = 0.5;
// dateAxis.renderer.grid.template.strokeOpacity = 0.1;
// dateAxis.renderer.axisFills.template.disabled = true;
// dateAxis["mouseEnabled"] = false;

let valueAxis = chart.yAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererRadial>());
valueAxis.tooltip.disabled = true;
valueAxis.renderer.grid.template.strokeOpacity = 0.05;
valueAxis.renderer.axisFills.template.disabled = true;
valueAxis.renderer.axisAngle = 260;
valueAxis.renderer.labels.template.horizontalCenter = "right";
valueAxis.min = 0;

let uniqueRoomTypes = [];
for(let data of this.invSnapshotData){
  for(var key in data){
    if(key != 'date')
    uniqueRoomTypes.push(key);
  }
}
uniqueRoomTypes = Array.from(new Set(uniqueRoomTypes));

for(let roomTypes of uniqueRoomTypes){
  let series = chart.series.push(new am4charts.RadarColumnSeries());
  series.columns.template.radarColumn.strokeOpacity = 1;
  series.name = roomTypes;
  series.dataFields.dateX = "date";
  series.columns.template.tooltipText = "{name}: {valueY.value}";
  series.dataFields.valueY = roomTypes;
  series.stacked = true;
}
chart.seriesContainer.zIndex = -1;

let slider = chart.createChild(am4core.Slider);
slider.start = 0;
slider.exportable = false;
slider.events.on("rangechanged", () => {
  let start = slider.start;

  chart.startAngle = 270 - start * 179 - 1;
  chart.endAngle = 270 + start * 179 + 1;

  valueAxis.renderer.axisAngle = chart.startAngle;
});
}
numberWithCommas(x) {
  x = x.toFixed(0);
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

convertDateToString(dateToBeConverted: string) {
  return moment(dateToBeConverted, "YYYY-MM-DD HH:mm:ss").format("DD-MMM-YYYY");
}
saveEvent(event){
  console.log(this.saveEventDetails);
  for(let data of this.revData){
    if(new Date(data.date).getTime() == new Date(this.saveEventDetails.date).getTime()){
      this.hasEvents = true;
      // data["aX"]=new Date(data.date);
      data["aY"]=1;
    }
  }
  this.getYearChart()
  this.showSaveEvent = false;
}
closeDialog(event){
  this.showSaveEvent = false;
}
}
