import { Component, OnInit, ViewChild, ViewContainerRef, HostListener, ComponentFactoryResolver } from '@angular/core';
import { MenuItem } from 'primeng/components/common/menuitem';
import { Router } from '@angular/router';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { GridOptions } from 'ag-grid';
import { Subscription } from 'rxjs';
import { UsageCriteria2, DateRange, UsageItem, UsageType, AppService } from 'mantras-api';
import { AuthenticationService } from '../ui-services/authentication.service';
import { StorageService } from '../ui-services/storage.service';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { DatePipe } from '@angular/common';
import { CommonService } from '../ui-services/common.service';
import { DialogModule, Dialog } from 'primeng/dialog';
import { Http, Headers } from '@angular/http';
import { ArrayBuffer } from '@angular/http/src/static_request';
import { saveAs } from 'file-saver/dist/FileSaver';
import { OverlayPanel, MessageService } from 'primeng/primeng';
import * as Crypt from 'crypt';
import * as SHA1 from 'sha1';
import { string } from '@amcharts/amcharts4/core';



@Component({
  selector: 'usage',
  templateUrl: './usage.component.html',
  styleUrls: ['./usage.component.css'],
})
export class UsageComponent implements OnInit {
  Tags: any;
  TimeStamp: any;
  JsonToXml = require('jsontoxml');

  hostName: any;
  usageMenuItems: MenuItem[];
  filterValue;
  timeOptions = [{ label: "Last 30 mins", value: '30' }, { label: 'Last 1 Hour', value: '1' }, { label: 'Last 3 Hours', value: '3' }];
  columnDefs: any[];
  icons;
  gridOptions: GridOptions;
  rowData: any[];
  rowSelection = "multiple";
  subscription: Subscription;
  isDetailsPage = false;
  selectedUsage = new UsageItem();
  Type;
  IsSuccess;
  criteriaUsage = new UsageCriteria2();
  defaultGroupSortComparator;
  rowGroupPanelShow: any;

  @ViewChild('usageDetailsDialog') usageDetailsDialog: Dialog;
  @ViewChild('panel') panel: OverlayPanel;
  @ViewChild('filterValueUpdt') filterValueUpdt;

  constructor(private http: Http, private messageService: MessageService, private mantras: AppService, private router: Router, private commService: CommonService, private storageService: StorageService, private resolver: ComponentFactoryResolver) {

    this.columnDefs = [
      {
        headerName: "Status", field: "IsSuccess", cellClass: 'gridcol', showRowGroup: true, width: 20, menuTabs: [],
        cellRenderer: params => { return `<${params.value ? '' : 'i class="fa fa-exclamation-triangle" style="color:red"'}/>`; }
      },
      { headerName: "Time Stamp", field: "TimeStamp", headerTooltip: "Time Stamp", width: 70, enableRowGroup: true, menuTabs: [], cellClass: 'gridcol', sort: 'desc', valueFormatter: TimeStamp },
      { headerName: "Method", field: "Method", headerTooltip: "Method", width: 80, enableRowGroup: true, menuTabs: [], cellClass: 'gridcol', valueFormatter: formatMethod, valueGetter: methodValueGetter, keyCreator: methodKeyCreator },
      { headerName: "User Id", field: "UserId", headerTooltip: "User Id", width: 80, enableRowGroup: true, menuTabs: [], cellClass: 'gridcol',valueGetter: valueGetterUser },
      { headerName: "Domain", field: "DomainId", headerTooltip: "Domain", width: 80, enableRowGroup: true, menuTabs: [], cellClass: 'gridcol',valueGetter: valueGetterDomainName },
      { headerName: "Duration(ms)", field: "Duration", headerTooltip: "Duration", width: 50, menuTabs: [], cellClass: 'gridcol', valueFormatter: Duration },
      { headerName: "Ip Address", field: "IpAddress", headerTooltip: "IP Address", width: 80, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Site Name", field: "Tags.SiteName", headerTooltip: "SiteName", width: 80, menuTabs: [], cellClass: 'gridcol' },

    ];

    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    this.rowGroupPanelShow = "always";
    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      localeText: { noRowsToShow: "No usage(s) found" },
      context: { thisComponent: this },
      sortingOrder: ["desc", "asc"]
    };
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;
    //Set the current class context (this) in gridOption context
    this.gridOptions.context = { thisComponent: this };

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });

    this.defaultGroupSortComparator = function (nodeA, nodeB) {
        if (nodeA.field == "Method") {
          if (nodeA.key) {
            if (nodeA.key.includes("/")) {
              nodeA.key = nodeA.key.split("/").pop();
            }
          }
          if (nodeB.key) {
            if (nodeB.key.includes("/")) {
              nodeB.key = nodeB.key.split("/").pop();
            }
          }
        }
      if (nodeA.key < nodeB.key) {
        return -1;
      } else if (nodeA.key > nodeB.key) {
        return 1;
      } else {
        return 0;
      }
    };

  }
  cellEvent;
  captureEvent(params) {
    this.cellEvent = params.event;
  }

  getContextMenuItems = function getContextMenuItems(params, event?) {
    let selectedRows = params.api.getSelectedRows();
    if (selectedRows.length > 0) {
      var result = [];
      if (selectedRows.length > 1) {
        result.push({
          name: "Save All",
          action: function () {
            params.context.thisComponent.saveSelectedRowsDetails(selectedRows);
            params.api.redrawRows({});
          }
        });
      }
      else {
        result.push({
          name: "Save",
          action: function () {
            params.context.thisComponent.saveSelectedRowsDetails(selectedRows);
            params.api.redrawRows({});
          }
        });
      }
    }
    return result;
  }

  saveOnRighClick;
  details = [];
  async saveSelectedRowsDetails(selectedRowsDetails) {
    this.details = [];
    for (let i = 0; i < selectedRowsDetails.length; i++) {
      if (this.usageDetailsMap.has(selectedRowsDetails[i].Id)) {
        var usageItem = this.usageDetailsMap.get(selectedRowsDetails[i].Id);
        this.details.push(usageItem);
      } else {
        await this.getUsageDetails(selectedRowsDetails[i].Id).then(resp => {
          this.details.push(this.selectedUsage);
        });
      }
    }
    this.saveFileFromSelectedRows();
  }

  saveFileFromSelectedRows() {
    let byteChars = "<Data>";
    this.details.forEach(element => {
      byteChars = byteChars + "\n" + "<UsageItem" + element.Id + ">";
      if (element.Request && element.Request.startsWith("<")) byteChars += element.Request;
      else byteChars += "<Request>" + element.Request + "</Request>";
      if (element.Response && element.Response.startsWith("<")) byteChars += element.Response;
      else byteChars += "<Response>" + element.Request + "</Response>";
      byteChars += "</UsageItem" + element.Id + ">";
    });
    byteChars = byteChars + "\n" + "</Data>";
    let byteNumbers = new Array(byteChars.length);
    for (var i = 0; i < byteChars.length; i++) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/xml' });
    saveAs(blob, "usage.xml");
  }


  usageDetails;
  showUsageItem = false;
  dataId;
  usageDetailsMap = new Map<string, UsageItem>();
  onClickGridRow(params) {
    if (this.openFlag || this.saveOnRighClick) {
      this.dataId = params.Id;
    } else {
      this.dataId = params.data.Id;
    }

    if (this.usageDetailsMap.has(this.dataId)) {
      this.selectedUsage = this.usageDetailsMap.get(this.dataId);
      this.showUsageItem = true;
    }
    else {
      this.getUsageDetails(this.dataId).then(resp => {
        this.showUsageItem = true;
      });
    }
    this.openFlag = false;
  }

  byteToString(bytes) {
    for (var str = [], i = 0; i < bytes.length; i++)
      str.push(String.fromCharCode(bytes[i]));
    return str.join('');
  }

  domainInfos;
  @ViewChild('usagecriteria', { read: ViewContainerRef }) usagecriteria;
  async onSearchCriteria(criteria?: UsageCriteria2) {
    if (!criteria) {
      criteria = this.createUsageCriteria();
      this.usagecriteria._data.componentView.component.bindCriteria(criteria);
    }
    this.criteriaUsage = criteria;
    if (criteria.Type == 2) {
      criteria.Type = 0;
    }
    this.domainInfos = new Map<string, string>();
    await this.commService.findUsage(criteria, this.domainInfos).then(usageList => {
      this.gridOptions.api.setRowData(usageList);
      this.gridOptions.api.sizeColumnsToFit();
      this.onGridReady(this);
      this.setHeight();
      this.usageDetails = usageList;
      // if (usageList.length > 0) {
      //   this.showCriteria = false;
      // }
    });
    this.loadMenu();
    this.usageDetailsMap.clear();
  }

  ngOnInit() {
    this.loadMenu();
    this.gridOptions.api.setRowData([]);

  }

  loadMenu() {
    this.usageMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
    ];
  }

  rowIndexCount = [];
  onRowClick(params) {
    //this.rowIndexCount.push(params.rowIndex);
    if (params.data) {
      this.usageMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
      ];
    }
  }

  onRefresh(event) {
    this.filterValue = null;
    let criteria = new UsageCriteria2();
    if (this.usagecriteria._data.componentView.component.buildCriteria()) {
      criteria = this.usagecriteria._data.componentView.component.usageCriteria;
    }
    this.onSearchCriteria(criteria);
  }
  openFlag = false;
  open(event) {
    this.openFlag = true;
    this.onClickGridRow(this.gridOptions.api.getSelectedRows()[0]);
  }

  onClose() {
    this.router.navigate(["/settings"]);
  }

  onSearch(event) {
    this.onSearchCriteria(event);
  }
  bindUsage() {
    var resp = this.selectedUsage;
    this.selectedUsage.DomainId = this.domainInfos.get(resp.DomainId);
    this.selectedUsage.Duration = Duration(resp);
    this.IsSuccess = formatStatus(resp.IsSuccess);
    if (resp.Method.includes("/")) {
      this.selectedUsage.Method = resp.Method.split("/").pop();
    } if (resp.Request && resp.Request != null) {
      if (resp.Request.startsWith("<")) {
        this.selectedUsage.Request = Utilities.formatXml("<Request>" + resp.Request + "</Request>", '');
      } else if (resp.Request.startsWith("77u/") || resp.Request.startsWith("P")) {
        this.selectedUsage.Request = this.byteToString(Crypt.base64ToBytes(resp.Request.replace("77u/", "")));
      }
    }
    if (resp.Response && resp.Response != null) {
      if (resp.Response.startsWith("<")) {
        this.selectedUsage.Response = Utilities.formatXml("<Response>" + resp.Response + "</Response>", '');
      } else if (resp.Response.startsWith("77u/") || resp.Response.startsWith("P")) {
        this.selectedUsage.Response = this.byteToString(Crypt.base64ToBytes(resp.Response.replace("77u/", "")));
      }
    }
    this.selectedUsage.SessionId = resp.SessionId;
    let index = 0;
    if (resp.Tags) {
      this.Tags = (resp.Tags["HostName"]) ? resp.Tags["HostName"] : "";
    }
    this.TimeStamp = TimeStamp(resp);
    this.Type = Type(resp.Type);
  }

  getUsageDetails(usageId) {
    return this.commService.getUsageDetails(usageId).then(resp => {
      this.selectedUsage = resp;
      this.bindUsage();
      this.usageDetailsMap.set(this.selectedUsage.Id, this.selectedUsage);
    });
  }

  onCriteriaChange() {
    let criteria = new UsageCriteria2();
    if (this.usagecriteria._data.componentView.component.buildCriteria()) {
      criteria = this.usagecriteria._data.componentView.component.usageCriteria;
    }
    criteria.Period = new DateRange();
    let startDate: Date = new Date();
    let endDate: Date = new Date();
    let todaysDate: Date = new Date();
    todaysDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate(),
      todaysDate.getHours(), todaysDate.getMinutes(), todaysDate.getSeconds(), todaysDate.getMilliseconds());
    endDate.setDate(todaysDate.getDate())
    endDate.setHours(todaysDate.getHours())
    endDate.setMinutes(todaysDate.getMinutes() + 1)
    endDate.setSeconds(todaysDate.getSeconds())
    endDate.setMilliseconds(todaysDate.getMilliseconds());
    criteria.Period.End = endDate;
    startDate = new Date(todaysDate);
    if (this.filterValue["value"] != '30') {
      startDate.setDate(todaysDate.getDate());
      startDate.setHours(todaysDate.getHours() - parseInt(this.filterValue.value));
      startDate.setMinutes(todaysDate.getMinutes());
      startDate.setSeconds(todaysDate.getSeconds());
      startDate.setMilliseconds(todaysDate.getMilliseconds());
      criteria.Period.Start = startDate;
    }
    else {
      startDate.setDate(todaysDate.getDate());
      startDate.setHours(todaysDate.getHours());
      startDate.setMinutes(todaysDate.getMinutes() - parseInt(this.filterValue.value));
      startDate.setSeconds(todaysDate.getSeconds());
      startDate.setMilliseconds(todaysDate.getMilliseconds());
      criteria.Period.Start = startDate;
    }
    // criteria.Type = 0;
    this.usagecriteria._data.componentView.component.bindCriteria(criteria);
    this.onSearchCriteria(criteria);
    this.filterValueUpdt.updateSelectedOption(null);
  }

  createUsageCriteria() {
    let criteria = new UsageCriteria2();
    criteria.Period = new DateRange();
    let startDate = new Date();
    criteria.Period.Start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
    criteria.Period.End = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 0);
    criteria.Type = 0;
    return criteria;
  }

  saveFile() {
    let byteChars = "<Usage>" + this.JsonToXml(JSON.stringify(this.selectedUsage)) + "</Usage>";
    let byteNumbers = new Array(byteChars.length);
    for (var i = 0; i < byteChars.length; i++) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/xml' });

    saveAs(blob, "usageDetails.xml");
  }


  copyFunction(textToCopy) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (textToCopy.innerText));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    document.removeEventListener('copy', null);
    this.messageService.add({ severity: 'info', summary: 'Copied' });
  }

  // copyStringToClipboard(string) {
  //   function handler(event) { 
  //     event.clipboardData.setData('text/plain', string); 
  //     event.preventDefault(); 
  //     document.removeEventListener('copy', handler, true); 
  //   } 
  //   document.addEventListener('copy', handler, true); 
  //   document.execCommand('copy');
  // }

  showCriteria = true;
  hideShowCriteria() {
    this.showCriteria = !this.showCriteria;
    this.setHeight();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    if(this.showCriteria){
    jQuery('.gridHeight').css('height', (Utilities.getScrollHeight(true))-125);
    }else{
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true));
    }
  }

  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
    this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }
  onGridSizeChanged($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  filteredResponse;
  searchText(event) {
    this.filteredResponse = [];
    var query = event.target.value.toLowerCase();
    let usages = this.usageDetails;
    if (query == "") this.filteredResponse = usages;
    else {
      usages.forEach(usage => {
        let isMatch = false;
        let usageString = JSON.stringify(usage);
        isMatch = usageString.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        if (isMatch) this.filteredResponse.push(usage);
      });
    }
    this.gridOptions.api.setRowData(this.filteredResponse);
    this.gridOptions.api.sizeColumnsToFit();
    this.onGridReady(this);
    this.setHeight();
  }
}
function TimeStamp(params) {
  if (!params.data) {
    var date = new DatePipe('en-US');
    return date.transform(params.TimeStamp, 'dd-MMM-y, hh:mm:ss aa');
  } else {
    var date = new DatePipe('en-US');
    return date.transform(params.data.TimeStamp, 'dd-MMM-y, hh:mm:ss aa');
  }
}

function Duration(params) {
  if (!params.data) {
    var dur = Math.round(params.Duration);
  } else
    var dur = Math.round(params.data.Duration);
  return dur;
}

function Type(params) {
  return UsageType[params];
}

function formatStatus(params) {
  if (params == true) {
    return "Success";
  }
  else return "Error";
}

function formatMethod(params) {
  if (params.data) {
    if (params.data.Method.includes("/")) {
      return params.data.Method.split("/").pop();
    } else
      return params.data.Method;
  } else {
    return params.value;
  }
}

function methodKeyCreator(params) {
  return params.value;
}

function methodValueGetter(params) {
  if (params.node.data.Method.includes("/")) {
    return params.node.data.Method.split("/").pop();
  } else
    return params.node.data.Method;
}

function valueGetterUser(params) {
  if (params.data && params.data.UserId != null && params.data.UserId != null)
    return params.data.UserId;
  else
    return "";
}

function valueGetterDomainName(params) {
  if (params.data && params.data.DomainId != null && params.data.DomainId != null)
    return params.data.DomainId;
  else
    return "";
}