import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BookingService } from '../ui-services/booking.service';
import { BookingCriteria } from 'mantras-api';
import { StorageService } from '../ui-services/storage.service';
import { DateRange } from 'mantras-api';
import { Utilities } from '../ui-services/utilities';

@Component({
  selector: 'app-graphslidervariation',
  templateUrl: './graphslidervariation.component.html',
  styleUrls: ['./graphslidervariation.component.css']
})
export class GraphslidervariationComponent implements OnInit {
  @Input('data') graphData;
  @Input('title') title:string;
  @Input('label') graphLabel;
  graphSliderData;
  graphOptions;

  sliderValue = 0;
  minValue = 0;
  maxValue =1;
  step = 1

  constructor(private bookingService:BookingService, private storageService:StorageService) {
    if(this.graphData == null){
      this.populateData();
    }
    this.renderGraph();
  }

  ngOnInit() {
  }

  formatData(data){
    return [{data:data}];
  }

  formatLabel(labels){
    return labels;
  }

  populateData(){
    this.graphData = [];
    this.graphData[0] = [1,3,4,8,8,7,7,7,10,12,14,18,20,25];
    this.graphData[1] = [3,4,8,8,7,7,7,10,12,14,18,20,25,30];
    this.graphData[2] = [4,8,8,7,7,7,10,12,14,18,20,25,30,32];
    this.graphData[3] = [8,8,7,7,7,10,12,14,18,20,25,30,33,35];
    this.graphData[4] = [8,7,7,7,10,12,14,18,20,25,30,33,36,38];
    this.graphData[5] = [7,7,7,10,12,14,18,20,25,30,33,34,38,40];
    this.graphData[6] = [8,9,10,12,14,18,20,25,30,33,34,38,40,43];
    this.graphData[7] = [10,12,14,18,20,25,30,33,36,38,43,35,47,49];
    this.graphData[8] = [14,18,20,25,31,35,39,43,44,48,50,51,55,57];
    this.graphLabel = [];
    this.maxValue = this.graphData.length -1;
    this.graphLabel[0] = ["1 AUG","2 AUG","3 AUG","4 AUG","5 AUG","6 AUG","7 AUG","8 AUG","9 AUG","10 AUG","11 AUG","12 AUG","13 AUG","14 AUG",]
    this.populateBookingData();
  }

  async populateBookingData(){
    let bookingCriteria = new BookingCriteria();
    bookingCriteria.HotelIds = [this.storageService.get(StorageService.userHotelContext).HotelId];
    bookingCriteria.DomainIds = [this.storageService.get(StorageService.userDomainContext).CurrentDomainId];
    bookingCriteria.StayDates = new DateRange(); 
    bookingCriteria.StayDates.Start = new Date("2018-08-24");
    bookingCriteria.StayDates.End = new Date("2018-08-25");
    let bookingData = await this.bookingService.findBookings(bookingCriteria);
    console.log("Booking Data: ",bookingData);
    //return bookingData;
  }

  renderGraph(){
    this.graphOptions = {
      title: {
        display: true,
        text: this.title,
        fontSize: 15
      },
      legend: {
        position: 'bottom'
      }
    };
    this.graphSliderData = {};
    let index = this.sliderValue;
    //if(this.graphLabel.length > 1){
      //this.graphSliderData["labels"] = this.formatLabel(this.graphLabel[index]);
    //} else {
      this.graphSliderData["labels"] = this.formatLabel(this.graphLabel[0]);
    //}
    this.graphSliderData["datasets"] = this.formatData(this.graphData[index]);
  }

  handleSliderChange(event){
    console.log("Slider Change event: ",event.value);
    this.renderGraph();
  }

  slideRight(){
    this.sliderValue = (this.sliderValue == this.graphData.length -1)?this.sliderValue: this.sliderValue+1;
    this.renderGraph();  
  }

  slideLeft(){
    this.sliderValue = (this.sliderValue == 0)?this.sliderValue: this.sliderValue-1;
    this.renderGraph();  
  }

  isPlay=false;
  playInterval;
  play(){
    if(this.isPlay) return;
    this.isPlay = true;
    this.playInterval = setInterval(() => {
          this.sliderValue = (this.sliderValue == this.graphData.length -1)?0:this.sliderValue+1;
          this.renderGraph();
      }, 2000);
  }

  pause(){
    clearInterval(this.playInterval);
    this.isPlay = false;
  }
}
