import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid";
import { TaskStatus } from "mantras-api";

@Component({
    selector: 'progressbarrenderer',
    template: `<p-progressBar class="{{cssClass}}" value="{{progress}}"></p-progressBar>`
})
export class ProgressBarRendererComponent implements ICellRendererAngularComp {
    constructor(){
    }
    progress = 0;
    cssClass=""
    agInit(params: ICellRendererParams): void {
        if(params && params.value){
            this.progress = params.value;
            if(params.node.data.Status == TaskStatus.Error) this.cssClass = "error";
        }
    }

    refresh(params: any): boolean {
        return true;
    }
}