import { Component, OnInit, AfterViewInit, Inject, Renderer2, Input } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { Http } from '@angular/http';
import * as jQuery from 'jquery';

@Component({
  selector: 'mm-pivotviewer',
  templateUrl: './pivotviewer.component.html',
  styleUrls: ['./pivotviewer.component.css']
})
export class PivotviewerComponent implements OnInit , AfterViewInit {

  pivotScriptTag;
  @Input('styleClass') styleClass;
  isLoaded = false;
  constructor(private _renderer2: Renderer2, @Inject(DOCUMENT) private _document,private http: Http) { 

    this.loadCSS("mm-css-leaflet","/assets/html5pivotviewer/lib/leaflet/leaflet.css");
    this.loadCSS("mm-css-timeline-bundle","/assets/html5pivotviewer/lib/simile-timeline/timeline_js/timeline-bundle.css");
    this.loadCSS("mm-css-graphics","/assets/html5pivotviewer/lib/simile-timeline/timeline_ajax/styles/graphics.css");
    let pivotViewerCss = this.loadCSS("mm-css-pivotviewer","/assets/html5pivotviewer/style/pivotviewer.css");
    
    if(pivotViewerCss["readyState"]) {  //IE
      pivotViewerCss["onreadystatechange"] = () => {
        if ( pivotViewerCss["readyState"] === "loaded" || pivotViewerCss["readyState"] === "complete" ) {
          this.pivotScriptTag["onreadystatechange"] = null;
          console.log("Loading Text script, readyState");
          this.loadStaticScripts();
          this.loadPivotViewerScriptWithData();
        }
      };
    } else {  //Others
      pivotViewerCss.onload = () => {
        console.log("Loading Text script, onload");
        this.loadStaticScripts();
        this.loadPivotViewerScriptWithData();
      };
    }
  }

  public loadStaticScripts(){
    this.loadScript("mm-ll-jquery-script","/assets/html5pivotviewer/lib/jquery/jquery-1.11.0.min.js")
    //this.loadScript("mm-ll-timeline-api","/assets/html5pivotviewer/lib/simile-timeline/timeline_js/timeline-api.js");
    // this.loadScript("mm-ll-text-script0","",`var Timeline = window.Timeline, debug = false;
    //     Timeline_ajax_url="/assets/html5pivotviewer/lib/simile-timeline/timeline_ajax/simile-ajax-api.js";
    //     Timeline_urlPrefix='/assets/html5pivotviewer/lib/simile-timeline/timeline_js/';
    //     Timeline_parameters='bundle=true';`);
    // this.loadScript("mm-ll-simile-ajax-api","/assets/html5pivotviewer/lib/simile-timeline/timeline_ajax/simile-ajax-api.js");
    // this.loadScript("mm-ll-simile-ajax-bundle","/assets/html5pivotviewer/lib/simile-timeline/timeline_ajax/simile-ajax-bundle.js");
    // this.loadScript("mm-ll-signal","/assets/html5pivotviewer/lib/simile-timeline/timeline_ajax/scripts/signal.js");
    // this.loadScript("mm-ll-timeline-bundle","/assets/html5pivotviewer/lib/simile-timeline/timeline_js/timeline-bundle.js");
    // this.loadScript("mm-ll-timeline","/assets/html5pivotviewer/lib/simile-timeline/timeline_js/scripts/l10n/en/timeline.js");
    //this.loadScript("mm-ll-labellers","/assets/html5pivotviewer/lib/simile-timeline/timeline_js/scripts/l10n/en/labellers.js");
    //this.loadScript("mm-ll-jquery-1.11.0.min","/assets/html5pivotviewer/lib/jquery/jquery-1.11.0.min.js");
    this.loadScript("mm-ll-jquery-ui-1.10.4.custom.min","/assets/html5pivotviewer/lib/jquery-ui/jquery-ui-1.10.4.custom.min.js");
    this.loadScript("mm-ll-modernizr.custom.93916","/assets/html5pivotviewer/lib/modernizr/modernizr.custom.93916.js");
    this.loadScript("mm-ll-easing","/assets/html5pivotviewer/lib/easing/easing.js");
    //this.loadScript("mm-ll-jquery.mousewheel.min","/assets/html5pivotviewer/lib/jquery.mousewheel/jquery.mousewheel.min.js");
    //this.loadScript("mm-ll-jquery.tooltipster.min","/assets/html5pivotviewer/lib/jquery.tooltipster/jquery.tooltipster.min.js");
    this.loadScript("mm-ll-colResizable-1.3.min","/assets/html5pivotviewer/lib/colResizable/colResizable-1.3.min.js");
    this.loadScript("mm-ll-purl","/assets/html5pivotviewer/lib/purl-master/purl.js");
    this.loadScript("mm-ll-leaflet-0.6.4","/assets/html5pivotviewer/lib/leaflet/leaflet-0.6.4.js");
    this.loadScript("mm-ll-wicket.min","/assets/html5pivotviewer/lib/wicket/wicket.min.js");
    this.loadScript("mm-ll-wicket-leaflet.min","/assets/html5pivotviewer/scripts/wicket-leaflet.min.js");
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.isLoaded = true;
  }

  //Load javascripts files required for pivot viewer
  public loadScript(id,url,text?) {
    //Remove old script before loading again.
    if(document.getElementById(id)) document.getElementById(id).remove();
    let node = document.createElement('script');
    node.setAttribute("id", id);
    node.type = 'text/javascript';
    if(url != null && url != "")
      node.src = url;
    if(text && text != null)
      node.text = text;
    document.head.appendChild(node);
    return node;
  }

  //Load inline javascript to load pivotview
  loadTextScript(loadData?:any){
    let doc = document;
    if(!loadData || loadData == null){
      loadData = { "CollectionName": "No records loaded",
      "FacetCategories": {"FacetCategory": []},
      "Items": { "Item": [{"Id":1,"Img": "","Facets": {"Facet": []}}]}};
    }
    if(doc.getElementById("mm-ls-main-script")) doc.getElementById("mm-ls-main-script").remove();
    let s = doc.createElement('script');
    s.setAttribute("id", "mm-ls-main-script");
    s.type=`text/javascript`;
    s.text = `
      var debug = false;
      $(document).ready(function () { 
          var jsonData = JSON.parse('${JSON.stringify(loadData).replace(/\'/g, "\\'")}');
          $('#pivotviewer').innerHTML = "";
          $('#pivotviewer').PivotViewer({ 
              Loader: new PivotViewer.Models.Loaders.JSONLoader("/assets/html5pivotviewer/samples/data/bookingAnalytics.json",null,jsonData),
              ImageController: new PivotViewer.Views.SimpleImageController()
          });
      });`;
    doc.head.appendChild(s);
  }

  loadPivotViewerScriptWithData(loadData?){
    this.pivotScriptTag = this.loadScript("mm-ll-pivotviewer.min","/assets/html5pivotviewer/pivotviewer.min.js");
    if(this.pivotScriptTag["readyState"]) {  //IE
      this.pivotScriptTag["onreadystatechange"] = () => {
        if ( this.pivotScriptTag["readyState"] === "loaded" || this.pivotScriptTag["readyState"] === "complete" ) {
          this.pivotScriptTag["onreadystatechange"] = null;
          console.log("Loading Text script, readyState");
          this.loadTextScript(loadData);
        }
      };
    } else {  //Others
      this.pivotScriptTag.onload = () => {
        console.log("Loading Text script, onload");
        this.loadTextScript(loadData);
      };
    }
  }

  //Loading CSS Files
  loadCSS(id,url) {
    //Remove old css before loading again.
    if(document.getElementById(id)) document.getElementById(id).remove();
    let node = document.createElement('link');
    node.setAttribute("id", id);
    node.type = 'text/css';
    node.rel = 'stylesheet';
    node.setAttribute("href",url);
    document.head.appendChild(node);
    return node;
  }

  getStaticResponse() {
    console.log("Inside the getBookingAnalyticsResoponse.");
  return this.http.get('/assets/html5pivotviewer/samples/data/bookingAnalytics.json')
      .subscribe((response) => {
          // login successful if there's a jwt token in the response
          let bookingAnalytics = response.json();
          return bookingAnalytics;
      });
  }

  unloadScript(id){
    if(document.getElementById(id)) document.getElementById(id).remove();
  }

  ngOnDestroy(){
    this.unloadScript("mm-css-pivotviewer");
    this.unloadScript("mm-css-leaflet");
    this.unloadScript("mm-css-timeline-bundle");
    this.unloadScript("mm-css-graphics");
  }
}
