import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StorageService } from '../../../ui-services/storage.service';
import { EventManagementService } from '../../../ui-services/eventmanagement.service';
import { AuthenticationService } from '../../../ui-services/authentication.service';
import { BookingService } from '../../../ui-services/booking.service';
import { Utilities } from '../../../ui-services/utilities';

@Component({
  selector: 'mm-bookingfilter',
  templateUrl: './bookingfilter.component.html',
  styleUrls: ['./bookingfilter.component.css'],
  providers: [BookingService]
})
export class BookingfilterComponent implements OnInit {

  selectedChannelTypes = [];
  selectedPaymentStatus = [];
  selectedRoomTypes = [];
  channelTypes: any[];
  paymentStatus: any[];
  roomTypes: any[];
  hotelContext;
  options = [{ label: "Today's Bookings", value: '1' }, { label: 'Last 7 Days', value: '7' }, { label: 'Last 30 Days', value: '30' }, { label: 'This Month', value: '0' }];
  filtervalue = 7;
  criteria: number;
  isDetailsPage:boolean;
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter();
  @Output('valueChange') onChange: EventEmitter<any>;
  constructor(private storageService: StorageService, private eventManager: EventManagementService,
    private authenticationService: AuthenticationService, private bookingService: BookingService) {
    this.hotelContext = storageService.get(StorageService.currentUser).HotelContext;
  }
  ngOnInit() {
  }
  handleOnChange(event, field?: string) {
    let data = { channelTypes: this.selectedChannelTypes,paymentStatus:this.selectedPaymentStatus,roomTypes:this.selectedRoomTypes };
    this.onFilterChange.emit(data);
  }
  ngAfterViewInit() {
    setTimeout(() => {
        this.channelTypes;
        this.selectedChannelTypes;
    });
  }
setupBookingFilter(setupData){
    if (setupData) {
      this.selectedChannelTypes = setupData["channelTypes"];
      this.selectedPaymentStatus = setupData["paymentStatus"];
      this.selectedRoomTypes = setupData["roomTypes"];
    }
    this.channelTypes = [];
    this.paymentStatus = [];
    this.roomTypes = [];
    if (this.selectedChannelTypes && this.selectedChannelTypes.length > 0) {
      this.selectedChannelTypes.forEach(channel => {
        this.channelTypes.push({ value: channel, label: Utilities.findId(Utilities.channelTypes, channel, channel) });
        this.channelTypes.sort(Utilities.sort_label_asc)
      });
    }
    if (this.selectedPaymentStatus && this.selectedPaymentStatus.length > 0) {
      this.selectedPaymentStatus.forEach(status => {
        this.paymentStatus.push({ value: status,label: Utilities.findId(Utilities.paymentStatus, status, status) });
      });
    }
    if (this.selectedRoomTypes && this.selectedRoomTypes.length > 0) {
      this.selectedRoomTypes.forEach(roomType => {
        this.roomTypes.push({ value: roomType,label: roomType });
      });
    }
}
}
