import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StorageService } from '../../../ui-services/storage.service';
import { EventManagementService } from '../../../ui-services/eventmanagement.service';
import { Utilities } from '../../../ui-services/utilities';
import { RRPCellFilter, RatecalendarService } from '../../../ui-services/ratecalendar.service';
import { AuthenticationService } from '../../../ui-services/authentication.service';
import { HotelManagementService } from '../../../ui-services/hotelmanagement.service';

@Component({
  selector: 'mm-rcfilter',
  templateUrl: './rcfilter.component.html',
  styleUrls: ['./rcfilter.component.css']
})
export class RcfilterComponent implements OnInit {

  channelTypes: any[];
  selectedChannelTypes = ["MAX"];
  selectedRatePlans = [];
  selectedRooms = [];
  selectedrrpFields = [];
  rooms;
  rateplans;
  rrpFields;
  roomRatePlanFilters: RRPCellFilter; 
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter();
  constructor(private storageService: StorageService, private eventManager:EventManagementService,
    private rateCalendarService:RatecalendarService, private authenticationService:AuthenticationService,
    private hotelMgmtService:HotelManagementService) { 
  }

  async setUpRateCalendarFilter(filterChannels){
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    this.channelTypes = await this.rateCalendarService.getChannelTypes(filterChannels);
    if(filterChannels.length > 0){
      this.selectedChannelTypes = filterChannels;
    }else{
      this.selectedChannelTypes = ["MAX"];
    }
    //Add Master to channelTypes;
    this.channelTypes.push({label:Utilities.findId(Utilities.channelTypes,"MAX"),value:"MAX"});
    this.channelTypes.reverse();

    let roomTypes = hotelContext.RoomTypes;
    roomTypes = roomTypes.sort(Utilities.sortByMutipleFields("sortOrder","Name"));
    this.rooms = [];
    this.selectedRooms = [];
    roomTypes.forEach(room => {
      this.rooms.push({label:room.Name,value:room.Id});
      this.selectedRooms.push(room.Id);
    });
    this.rateplans = []
    this.selectedRatePlans = [];
    let rates = hotelContext.RatePlans;
    rates = rates.sort(Utilities.sortByMutipleFields("sortOrder","Name"));
    rates.forEach(rate => {
      this.rateplans.push({label:rate.Name,value:rate.Id});
      this.selectedRatePlans.push(rate.Id);
    });
    //This is commented.
    //this.rateCalendarService.rrpFilter.setToDefault();// Set rrp fields to default after property switch.
    this.roomRatePlanFilters = this.rateCalendarService.rrpFilter;
    this.rrpFields = [];
    this.selectedrrpFields = [];
    Object.keys(this.roomRatePlanFilters).map((field) => {
      if(field != "occupancy"){
        this.rrpFields.push({label:Utilities.rrpFieldsMap[field],value:field});
        if(this.roomRatePlanFilters[field]){
          this.selectedrrpFields.push(field);
        }
      } else if(field == "occupancy"){
        let occupancyArray = this.roomRatePlanFilters[field];
        for(let idx=0; idx < occupancyArray.length; idx++){
          this.rrpFields.push({label:Utilities.rrpFieldsMap[field+idx],value:field+idx});
          if(occupancyArray[idx]){
            this.selectedrrpFields.push(field+idx);
          } 
        }  
      }
    });
  }
  
  setupRcFilter(setUpFilter){
    if(setUpFilter){
      let channelFilter = (setUpFilter.filterChannels)?setUpFilter.filterChannels:[];
      let rrpFieldFilter = (setUpFilter.rrpFilter)?setUpFilter.rrpFilter:[];
      this.setUpRateCalendarFilter(channelFilter);
    }
  }

  ngOnInit() {
  }
  handleOnChange(event,field?:string){
    if(field == 'rrpfield'){
      Object.keys(this.roomRatePlanFilters).map((key) => {
        if(key.startsWith("occupancy")){
          let occupancy = this.roomRatePlanFilters[key];
          for(let idx=0; idx < occupancy.length; idx++) 
            occupancy[idx] = (this.selectedrrpFields.indexOf(key+idx) >= 0);
        } else {
          this.roomRatePlanFilters[key] = (this.selectedrrpFields.indexOf(key) >= 0);
        }
      });
    }
    let sortedChannelTypes = []; 
    this.channelTypes.forEach(channelType => {
      if(this.selectedChannelTypes.indexOf(channelType.value)>=0)
        sortedChannelTypes.push(channelType.value);
    });
    let data = {ChannelTypes:sortedChannelTypes,Rooms:this.selectedRooms,
      RatePlans:this.selectedRatePlans,RRPFilter:this.roomRatePlanFilters};
    this.onFilterChange.emit(data);
  }

  ngOnDestroy(){
  }
}
