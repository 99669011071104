import { Component, OnInit, HostListener, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { MenuItem } from 'primeng/components/common/menuitem';
import { Router } from '@angular/router';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { GridOptions } from 'ag-grid';
import { Subscription } from 'rxjs';
import { ExceptionCriteria2, DateRange, ExceptionType, ExceptionItem } from 'mantras-api';
import { StorageService } from '../ui-services/storage.service';
import { AuthenticationService } from '../ui-services/authentication.service';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';
import { CommonService } from '../ui-services/common.service';
import { ExceptionsDetailsComponent } from '../exceptions/exceptionsdetails.component';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'exceptions',
  templateUrl: './exceptions.component.html',
  styleUrls: ['./exceptions.component.css']
})
export class ExceptionsComponent implements OnInit {
  exceptionMenuItems: MenuItem[];
  filterValue;
  timeOptions = [{ label: "Last 30 mins", value: '30' }, { label: 'Last 1 Hour', value: '1' }, { label: 'Last 3 Hours', value: '3' },
  { label: 'Last 6 Hours', value: '6' }, { label: 'Last 12 Hours', value: '12' }, { label: 'Last 24 Hours', value: '24' }];
  columnDefs: any[];
  icons;
  gridOptions: GridOptions;
  rowData: any[];
  rowSelection: any;
  subscription: Subscription;
  isDetailsPage: boolean;
  rowGroupPanelShow: any;
  defaultGroupSortComparator;
  isFilterEnabled = false;


  constructor(private router: Router, private commService: CommonService, private storageService: StorageService, private resolver: ComponentFactoryResolver) {
    this.columnDefs = [
      { headerName: "Time Stamp", field: "TimeStamp", headerTooltip: "Time Stamp", width: 80, enableRowGroup: true, menuTabs: [], sort: 'desc', cellClass: 'gridcol', valueFormatter: TimeStamp },
      { headerName: "Method", field: "Method", headerTooltip: "Method", width: 80, menuTabs: [], enableRowGroup: true, cellClass: 'gridcol', valueFormatter: formatMethod },
      { headerName: "Domain", field: "DomainId", headerTooltip: "Domain", width: 80, menuTabs: [], enableRowGroup: true, cellClass: 'gridcol', valueGetter: valueGetterDomainName },
      { headerName: "User Id", field: "UserId", headerTooltip: "User Id", width: 80, menuTabs: [], enableRowGroup: true, cellClass: 'gridcol', valueGetter: valueGetterUser },
      // { headerName: "Error Code", field: "ErrorCode",tooltipField: "Error Code", width: 30, menuTabs: [], enableRowGroup: true, cellClass: 'gridcol' },
      { headerName: "Type", field: "Type", headerTooltip: "Type", width: 50, menuTabs: [], enableRowGroup: true, cellClass: 'gridcol', valueFormatter: Type },
      { headerName: "Message", field: "Text", headerTooltip: "Message", width: 180, menuTabs: [], enableRowGroup: true, cellClass: 'gridcol' },
      { headerName: "Site Name", field: "Tags.SiteName", headerTooltip: "SiteName", width: 80, menuTabs: [], cellClass: 'gridcol' },

    ];
    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    this.rowGroupPanelShow = "always";
    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      sortingOrder: ["desc", "asc"],
      localeText: { noRowsToShow: "No Exception(s) found" },
      suppressContextMenu: true,
    };
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;

    this.defaultGroupSortComparator = function (nodeA, nodeB) {
      if (nodeA.field == "Method") {
        if (nodeA.key) {
          if (nodeA.key.includes("/")) {
            nodeA.key = nodeA.key.split("/").pop();
          }
        }
        if (nodeB.key) {
          if (nodeB.key.includes("/")) {
            nodeB.key = nodeB.key.split("/").pop();
          }
        }
      }
      if (nodeA.key < nodeB.key) {
        return -1;
      } else if (nodeA.key > nodeB.key) {
        return 1;
      } else {
        return 0;
      }
    };

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });
  }

  @ViewChild('filterValueUpdt') filterValueUpdt;

  domainInfos;
  exceptionResponse;

  @ViewChild('exceptionscriteria', { read: ViewContainerRef }) exceptionscriteria;
  async onSearchCriteria(criteria?: ExceptionCriteria2) {
    if (!criteria) {
      criteria = this.createExceptionCriteria();
      this.exceptionscriteria._data.componentView.component.bindCriteria(criteria);
    }
    this.domainInfos = new Map<string, string>();
    await this.commService.findException(criteria, this.domainInfos).then(exceptionList => {
      this.exceptionResponse = exceptionList;
      this.gridOptions.api.setRowData(exceptionList);
      this.gridOptions.api.sizeColumnsToFit();
      this.onGridReady(this);
      this.filteredResponse = exceptionList;
      if (exceptionList.length > 1) {
        this.isFilterEnabled = true;
      }
      else {
        this.isFilterEnabled = false;
      }
    });
    // if (this.exceptionResponse.length > 15) {
    //   this.showCriteria = false;
    // }

    this.setHeight();
    this.loadMenu();
  }


  //Create New Domain
  detailComponent;
  selectedException;
  isNew = false;
  args = [];
  @ViewChild('exceptionsdetails') exceptionsdetails;
  onClickGridRow($event) {
    this.selectedException = new ExceptionItem();
    let selectedRow = this.gridOptions.api.getSelectedRows();
    this.selectedException = selectedRow[0];
    this.isDetailsPage = true;
    this.exceptionsdetails.onClose.subscribe(this.onDetailsClose);
    this.exceptionsdetails.setHeight();
  }

  selectedRow;
  onDetailsClose = async (event) => {
    this.selectedRow = this.gridOptions.api.getSelectedRows();
    this.isDetailsPage = false;
    this.setHeight();
  }

  onClose() {
    this.router.navigate(["/settings"]);
  }


  ngOnInit() {
    this.loadMenu();
  }

  loadMenu() {
    this.exceptionMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh() },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.onClickGridRow(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
    ];
  }

  onRowClick(params) {
    true
    if (params.data) {
      this.exceptionMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh() },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.onClickGridRow(event) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
      ];
    }
  }

  onRefresh() {
    let criteria = new ExceptionCriteria2();
    if (this.exceptionscriteria._data.componentView.component.buildCriteria()) {
      criteria = this.exceptionscriteria._data.componentView.component.exceptionCriteria;
    }
    this.onSearchCriteria(criteria);
  }

  onSearch(event) {
    this.onSearchCriteria(event);
  }


  showExceptionItem = false;
  exceptionDetailsMap = new Map<string, object>();


  onCriteriaChange() {
    let criteria = new ExceptionCriteria2();
    if (this.exceptionscriteria._data.componentView.component.buildCriteria()) {
      criteria = this.exceptionscriteria._data.componentView.component.exceptionCriteria;
    }
    criteria.Period = new DateRange();
    let startDate: Date = new Date();
    let endDate: Date = new Date();
    let todaysDate: Date = new Date();
    todaysDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate(),
      todaysDate.getHours(), todaysDate.getMinutes(), todaysDate.getSeconds(), todaysDate.getMilliseconds());
    endDate.setDate(todaysDate.getDate())
    endDate.setHours(todaysDate.getHours())
    endDate.setMinutes(todaysDate.getMinutes() + 1)
    endDate.setSeconds(todaysDate.getSeconds())
    endDate.setMilliseconds(todaysDate.getMilliseconds());
    criteria.Period.End = endDate;
    startDate = new Date(todaysDate);
    if (this.filterValue["value"] != '30') {
      startDate.setDate(todaysDate.getDate());
      startDate.setHours(todaysDate.getHours() - parseInt(this.filterValue.value));
      startDate.setMinutes(todaysDate.getMinutes());
      startDate.setSeconds(todaysDate.getSeconds());
      startDate.setMilliseconds(todaysDate.getMilliseconds());
      criteria.Period.Start = startDate;
    }
    else {
      startDate.setDate(todaysDate.getDate());
      startDate.setHours(todaysDate.getHours());
      startDate.setMinutes(todaysDate.getMinutes() - parseInt(this.filterValue.value));
      startDate.setSeconds(todaysDate.getSeconds());
      startDate.setMilliseconds(todaysDate.getMilliseconds());
      criteria.Period.Start = startDate;
    }

    this.exceptionscriteria._data.componentView.component.bindCriteria(criteria);
    this.onSearchCriteria(criteria);
    this.filterValueUpdt.updateSelectedOption(null);
  }

  createExceptionCriteria() {
    let criteria = new ExceptionCriteria2();
    criteria.Period = new DateRange();
    let startDate = new Date();
    criteria.Period.Start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 1, 0);
    criteria.Period.End = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 0);
    criteria.Type = 0;
    return criteria;
  }


  filteredResponse: any[];
  searchExceptionText(event) {
    this.filteredResponse = [];
    var query = event.target.value.toLowerCase();
    let exceptions = this.exceptionResponse;
    if (query == "") this.filteredResponse = this.exceptionResponse;
    else {
      exceptions.forEach(exceptionResponse => {
        let isMatch = false;
        if (exceptionResponse.Method && exceptionResponse.Method != undefined) {
          if (exceptionResponse["Method"].toLowerCase().indexOf(query) >= 0) {
            isMatch = true;
          }
        }
        if (exceptionResponse.DomainId && exceptionResponse.DomainId != undefined) {
          if (exceptionResponse["DomainId"].toLowerCase().indexOf(query) >= 0) {
            isMatch = true;
          }
        }
        if (exceptionResponse.UserId && exceptionResponse.UserId != undefined) {
          if (exceptionResponse["UserId"].indexOf(query) >= 0) {
            isMatch = true;
          }
        }
        if (exceptionResponse.Text && exceptionResponse.Text != undefined) {
          if (exceptionResponse["Text"].toLowerCase().indexOf(query) >= 0) {
            isMatch = true;
          }
        }
        if (exceptionResponse.Type && exceptionResponse.Type != undefined) {
          if (ExceptionType[exceptionResponse["Type"]].toLowerCase().indexOf(query) >= 0) {
            isMatch = true;
          }
        }
        if (isMatch) this.filteredResponse.push(exceptionResponse);
      });
    }
    this.renderExceptions();

  }

  renderExceptions() {
    this.rowGroupPanelShow = "always";
    this.isDetailsPage = false;
    this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true))
    this.gridOptions.api.setColumnDefs(this.columnDefs);
    this.gridOptions.api.setRowData(this.filteredResponse);
    this.gridOptions.api.sizeColumnsToFit();
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }


  showCriteria = true;
  hideShowCriteria() {
    this.showCriteria = !this.showCriteria;
    this.setHeight();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    if(this.showCriteria){
      jQuery('.gridHeight').css('height', (Utilities.getScrollHeight(true))-155);
      }else{
      jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true));
      }
  }



  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
    this.isDetailsPage = false;
    this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));
    this.gridOptions.suppressDragLeaveHidesColumns = true;
    this.setHeight();
  }
  onGridSizeChanged($event) {
    this.rowGroupPanelShow = "always";
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
function TimeStamp(params) {
  if (!params.data) {
    var date = new DatePipe('en-US');
    return date.transform(params.TimeStamp, 'dd-MMM-y, hh:mm:ss aa');
  } else {
    var date = new DatePipe('en-US');
    return date.transform(params.data.TimeStamp, 'dd-MMM-y, hh:mm:ss aa');
  }
}

function Type(params) {
  if (params.data)
    return ExceptionType[params.data.Type];
  else
    return ExceptionType[params.value];
}

function formatMethod(params) {
  if (params.data) {
    if (params.data.Method) {
      if (params.data.Method.includes("/")) {
        return params.data.Method.split("/").pop();
      } else
        return params.data.Method;
    }
  } else {
    return params.value;
  }
}

function valueGetterDomainName(params) {
  if (params.data && params.data.DomainId != null && params.data.DomainId != null)
    return params.data.DomainId;
  else
    return "";
}

function valueGetterUser(params) {
  if (params.data && params.data.UserId != null && params.data.UserId != null)
    return params.data.UserId;
  else
    return "";
}
