import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mm-revenuecardcircular',
  templateUrl: './revenuecardcircular.component.html',
  styleUrls: ['./revenuecardcircular.component.css']
})
export class RevenuecardcircularComponent implements OnInit {

  @Input("current") current:number;
  @Input("target") target:number;
  @Input("actual") actual:number;
  @Input("color") color;
  @Input("centerlabel") centerLabel;
  @Input("centervalue") centerValue;
  @Input("centersublabel") centerSubLabel;
  @Input("rightlabel") rightLabel;
  @Input("leftlabel") leftLabel;
  @Input("topleftlabel") topLeftLabel;
  @Input("toprightlabel") topRightLabel;
  @Input("bottomrightvalue") bottomRightValue;
  @Input("bottomleftvalue") bottomLeftValue;
  //@Input("minval") minval =0;
  @Input("prefix") prefix = "";
  @Input("style") style;
  @Input("width") width;

  currentPercent;
  targetPercent;
  actualPercent;
  constructor() { 
  }

  ngOnInit() {
    this.populateDonut();
  }
  populateDonut(){
    this.currentPercent = (this.current/this.target)*270.0;
    this.targetPercent = 270;
    if(this.current/this.target > 4/3) this.currentPercent = 360;
    this.actualPercent = (this.actual/this.target)*270.0;
  }

  displayLabel = false;
  showLabel(value){
    this.displayLabel = value;
  }
}
