import { Component, OnInit, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { DomainUser } from 'mantras-api';
import { StorageService } from '../ui-services/storage.service';
import { UserService } from '../ui-services/user.service';
import { Utilities } from '../ui-services/utilities';
import { MessageService } from 'primeng/components/common/messageservice';

import * as jQuery from 'jquery';

@Component({
  selector: 'userdetails',
  templateUrl: './userdetails.component.html',
  styleUrls: ['./userdetails.component.css']
})
export class UserdetailsComponent implements OnInit {
  selectedDomain: any;
  currentUser: any;
  isCurrentDomainSystem : boolean = false;
  @Input() user = new DomainUser();
  @Output('onClose') onClose = new EventEmitter<boolean>();
  cloneUserRoles;
  isReadOnly = false;
  userMenuItems;
  errorMsg;
  showPassword=false;
  roles = [{label:"Admins",value:"Admins"},{label:"ReadOnlyUser",value:"ReadOnlyUser"},{label:"Bookings",value:"Bookings"},{label:"Analytics",value:"Analytics"}, 
  {label:"Rate Calendar",value:"Rate Calendar"}, {label:"Promotions",value:"Promotions"}, {label:"Control Panel",value:"Control Panel"},{label:"Inventory Only",value:"InventoryOnly"}];
  properties = [];
  domainContext;
  isNewUser = false;
  columnCount = 4;
  hasAdminAccess = false;

  constructor(private storageService:StorageService, private userService:UserService, private messageService:MessageService) { 
    this.isReadOnly = userService.isReadOnly();
    this.domainContext = storageService.get(StorageService.userDomainContext);
    if(this.domainContext.Domains && this.domainContext.Domains.length > 0){
      let domain = Utilities.findObject(this.domainContext.Domains,this.domainContext.CurrentDomainId);
      if(domain && domain.DomainHotels){
        domain.DomainHotels.forEach(domainHotel => {
          this.properties.push({label:domainHotel.Name, value:domainHotel.Id});
        });
        this.properties.sort(Utilities.sort_label_asc);
      }
      if(this.properties.length <= 1) this.columnCount = 1;
      else if(this.properties.length <= 5) this.columnCount = 2;
      else if(this.properties.length <= 10) this.columnCount = 3;
      else this.columnCount = 4;
    }
    this.currentUser = this.storageService.get(StorageService.currentUser);
    this.selectedDomain = this.currentUser.DomainContext.CurrentDomainId;
    if(this.selectedDomain == "System"){
      this.isCurrentDomainSystem = true;
      this.roles = [{label:"Admins",value:"Admins"},{label:"Account Supervisor",value:"Account Supervisor"},{label:"Account Manager",value:"Account Manager"},{label:"Operator",value:"Operator"},{label:"ReadOnlyUser",value:"ReadOnlyUser"}];       
    }
  }

  ngOnInit() {
    this.userMenuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o',disabled:this.isReadOnly, command: (event) => this.onSave(event)},
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) },
      { title: 'Change Password', label: 'Change Password', icon: 'action-bar-menu-icon fa fa-key', disabled: this.isNewUser || this.isReadOnly, command: (event) => this.showChangePassword() }];
  }

  loadData(){
    //Nothing to load for user.
    console.log("UserObject:",this.user);
    if(!this.user.DomainId){
      this.user.DomainId = this.domainContext.CurrentDomainId;
      this.isNewUser = true;
      this.cloneUserRoles = null
      this.user.IsActive = true;
      this.ngOnInit();
    } else {
      this.cloneUserRoles = Object.assign([],this.user.Roles);
    }
    let currUser = this.storageService.get(StorageService.currentUser);
    //Check if user has Admins Access to manage other users
    this.hasAdminAccess = this.userService.checkAccess("Admins");
  }

  onSave(event){
    if(!this.validateForm()) return; 
    if(!this.user.Roles) this.user.Roles = [];
    if(!this.isNewUser){
      this.cloneUserRoles.forEach(element => {
        if((element == "Rate Manager" || 
        element == "Property Manager" ||
        element == "Revenue Manager" ||
        element ==  "Operator" ||
        element == "Account Supervisor" || element == "Account Manager") &&
        this.user.Roles.indexOf(element) < 0 && this.user.DomainId != "System"
        )
        this.user.Roles.push(element);
      });
    }
    if(this.isNewUser)
    {
      this.user.Id = this.user.Id.replace(/\s/g, "");
    }
    // Adding Administrators role manaully.
    if(this.user.Roles.indexOf("Administrators") < 0) this.user.Roles.push("Administrators");
      this.userService.saveDomainUser(this.user).then(result => {this.onCancel(true);});
  }

  showChangePassworDialog = false;
  newPassword;
  confirmPassword;
  showChangePassword(){
    this.newPassword = "";
    this.confirmPassword = "";
    this.showChangePassworDialog = true;
  }
  onShowPassword(){
    this.showPassword=!this.showPassword;
  }
  changePassword(){
    if(!this.newPassword || !this.confirmPassword || this.newPassword != this.confirmPassword) return; 
    this.userService.changePassword(this.user.Id,this.confirmPassword)
    .then(result => {this.messageService.addAll([{severity:'success', summary:'Success', detail:"Password updated successfully."}]);});
    this.showChangePassworDialog = false;
  }

  validateForm(){
    this.errorMsg = null;
    return true;
  }

  onCancel(event?){
    this.onClose.emit(event);
  }
  
  @HostListener('window:resize') setHeight(){
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height',  Utilities.getScrollHeight(true,true));
  }
}
