import { Component, OnInit, HostListener,Output,EventEmitter } from '@angular/core';
import { HotelInfo, HotelCriteria, CancellationPolicy, CancellationPolicyItem } from 'mantras-api';
import { HotelManagementService } from '../../ui-services/hotelmanagement.service';
import { StorageService } from '../../ui-services/storage.service';
import { Utilities } from '../../ui-services/utilities';
import { Image } from 'mantras-api';
import { Subscription } from 'rxjs';
import { UserService } from '../../ui-services/user.service';
import * as jQuery from 'jquery';
import { Router } from '@angular/router';
import { CustomReuseStrategy } from '../../ui-services/reuse-routes';
@Component({
  selector: 'mantrasxchange',
  templateUrl: './mantrasxchange.component.html',
  styleUrls: ['./mantrasxchange.component.css']
})
export class MantrasxchangeComponent implements OnInit {

  mantrasMenuItems;
  errorMsg;
  isReadOnly = false;
  propertyImages;
  timezones = Utilities.timeZone;
  checkintime;
  checkouttime;
        
  property = new HotelInfo();
  hotelContext;
  hotelAmenities = Utilities.hotelAmenities;
  roomAmenities = Utilities.roomAmenities;
  childAges;
  infantAges;
  childAge = 1;
  infantAge = 10;
  otaPartners = Utilities.OTAPartners;
  selectedOTAPartners = [];
  partnerId;
  rooms;
  roomImages : any[];
  airbnbNames;
  cancellationPolicies = [];
  usp;
  houseRules = [{label:"Smoking Allowed",value:0}, {label:"Pets Allowed", value:1}, 
  {label:"Party Allowed", value:2}, {label:"Non-Veg Allowed", value:3}, {label:"Children Allowed", value: 4}, 
  {label:"Infant Allowed", value:5}, {label:"Unmarried Couple Allowed", value:6}];
  roomTypes = [{label:"Bed Room",value:1},{label:"Living room",value:0}];
  roomNumbers = [{label:"1",value:1},{label:"1",value:1},{label:"2",value:2},{label:"3",value:3},{label:"4",value:4},{label:"5",value:5},{label:"6",value:6},{label:"7",value:7}];
  bedTypes = [{label:"King",value:"king_bed"},{label:"Queen",value:"queen_bed"},{label:"Double",value:"double_bed"},{label:"Single",value:"single_bed"},{label:"Sofa",value:"sofa_bed"},{label:"Couch",value:"couch"},{label:"Air mattress",value:"air_mattress"},{label:"Bunk",value:"bunk_bed"},
    {label:"Floor mattress",value:"floor_mattress"},{label:"Toddler bed",value:"toddler_bed"},{label:"Crib",value:"crib"},{label:"Water bed",value:"water_bed"},{label:"Hammock",value:"hammock"},]; 
  roomBeds = [];
  propertyTypes = Utilities.HotelType;
  roomTypeCategories = Utilities.RoomTypeCategories;
  customHouseRules;
  airbnbCancellationPolicies = [{value:"flexible", label:"Full refund 1 day prior to arrival, except fees"},
  {value:"moderate", label:"Full refund 5 days prior to arrival, except fees"},
  {value:"strict_14_with_grace_period", label:"Full refund within 48 hours of booking if before 14 days of checkin. After, 50% refund up until 1 week prior to arrival"},
  {value:"super_strict_30", label:"50% refund up until 30 days prior to arrival, except fees"},
  {value:"super_strict_60", label:"50% refund up until 60 days prior to arrival, except fees"}];
  bnbCancellationPolicy = [];
  cleaningFees = [];
  servicePath;
  contentUpdateChannels = [];
  roomTypesSubscription:Subscription;
  roomCategories = Utilities.RoomCategories;
  subscription: Subscription;
  bookingEngineCodes = Utilities.bookingEngineCodes;
  bookingEngineCode = null;
  paymentGatewayCodes = Utilities.paymentGatewayCodes;
  paymentGatewayCode = ["airpay"]; //Default set to Airpay
  @Output() onChangeMenuItems: EventEmitter<any>  = new EventEmitter();
  constructor(private hotelMgmtService: HotelManagementService, private storageService:StorageService,private userService:UserService, 
    private router:Router) { 
    this.isReadOnly=userService.isReadOnly();
    this.hotelContext = storageService.get(StorageService.userHotelContext);
    this.childAges = [{label:"1yr",value:1},{label:"2yr",value:2},{label:"3yr",value:3},{label:"4yr",value:4},{label:"5yr",value:5},{label:"6yr",value:6},
    {label:"7yr",value:7},{label:"8yr",value:8},{label:"9yr",value:9},{label:"10yr",value:10},{label:"11yr",value:11},{label:"12yr",value:12},
    {label:"13yr",value:13},{label:"14yr",value:14},{label:"15yr",value:15}];
    this.infantAges = [{label:"1yr",value:1},{label:"2yr",value:2},{label:"3yr",value:3},{label:"4yr",value:4},{label:"5yr",value:5}];

    this.servicePath = this.hotelMgmtService.mantras.proxy["_baseUrl"] + "/" + this.hotelMgmtService.mantras.proxy["_mantrasSvc"];
    this.loadData();

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
    });

    this.roomTypesSubscription = this.storageService.roomtypeContextListener$.subscribe(event =>{
      if(event == "Roomtypes"){
        this.loadData();
      }
    })
  }

  async loadData(force=false){
    let hotelCriteria = new HotelCriteria();
    hotelCriteria.Ids = [this.hotelContext.HotelId];
    let resp = await this.hotelMgmtService.findHotels(hotelCriteria,force);
      if(resp.length >= 1){
        this.property = resp[0];
        this.propertyImages = [];
        if(this.property.Pictures != null){
          this.property.Pictures.forEach(picture => {
            this.propertyImages.push({image:picture.Url,thumbImage:picture.Url,title:picture.Text?picture.Text:""});
          });
        }
        if(this.property.Tags){
          this.infantAge = this.property.Tags["Max Infant Age"];
          this.childAge = this.property.Tags["Max Child Age"];
          if(this.property.Tags["Partners Enabled"])
          this.selectedOTAPartners = this.property.Tags["Partners Enabled"].split(";");
          if(this.property.Tags["PaymentGateway"]) this.paymentGatewayCode = this.property.Tags["PaymentGateway"].split(",");
          this.partnerId = this.property.Tags["Partner Id"];
          this.usp = this.property.Tags["USP"];
          this.customHouseRules = this.property.Tags["CUSTOM_HOUSE_RULES"];
          if(this.selectedOTAPartners.length > 0) {
            this.bookingEngineCode = this.selectedOTAPartners.find(x => this.bookingEngineCodes.find(b => (b.value == x) ) != null);
            if(this.bookingEngineCode) this.selectedOTAPartners.splice(this.selectedOTAPartners.indexOf(this.bookingEngineCode),1);
          }
        }
        this.cancellationPolicies = [];
        if(this.property.CancellationPolicies && this.property.CancellationPolicies.length > 0)
          this.property.CancellationPolicies.forEach(cancelPolicy => {
            this.cancellationPolicies.push({label:cancelPolicy.Name,value:cancelPolicy});
          });
        else {
          //Non Refundable
          let nonRefund = new CancellationPolicy();
          nonRefund.Id = "NON-REFUNDABLE";
          nonRefund.Name = "NON-REFUNDABLE";
          nonRefund.Text = "Non-refundable"
          nonRefund.Items = [];
          let nonRefundItem = new CancellationPolicyItem();
          nonRefundItem.Amount = 100
          nonRefundItem.AmountType = 1;
          nonRefundItem.DaysOut = -1;
          nonRefund.Items.push(nonRefundItem);
          //Free Cancellation
          let freeCancellation = new CancellationPolicy();
          freeCancellation.Id = "FREE-CANCELLATION";
          freeCancellation.Name = "FREE-CANCELLATION";
          freeCancellation.Text = "No charge on cancellation"
          freeCancellation.Items = [];
          let freeCancellationItem = new CancellationPolicyItem();
          freeCancellationItem.Amount = 0
          freeCancellationItem.AmountType = 0;
          freeCancellationItem.DaysOut = -1;
          freeCancellation.Items.push(freeCancellationItem);

          if(!this.property.CancellationPolicies) this.property.CancellationPolicies = [];
          this.property.CancellationPolicies.push(nonRefund);
          this.property.CancellationPolicies.push(freeCancellation);
        }
        this.checkintime = new Date();
        this.checkouttime = new Date();
        let startTime = this.property.CheckInTime.split(":");
        let endTime = this.property.CheckOutTime.split(":");
        this.checkintime.setHours(parseInt(startTime[0]),parseInt(startTime[1]),parseInt(startTime[2]));
        this.checkouttime.setHours(parseInt(endTime[0]),parseInt(endTime[1]),parseInt(endTime[2]));
        this.updateRoomTypeCategory();
      }

    let response = await this.hotelMgmtService.findRoomTypes(this.hotelContext.HotelId,force);
        this.rooms = response;
        let index = 0;
        this.roomImages = [];
        this.airbnbNames = [];
        this.bnbCancellationPolicy = [];
        this.cleaningFees = [];
        this.roomBeds = [];
        this.rooms.forEach(room => {
          let images = [];
          room.Pictures.forEach(pic => {
            images.push(this.getImageObj(pic.Url,pic.Text?pic.Text:""));
          });
          this.roomImages[index] = images;
          if(room.Tags == undefined || room.Tags == null) room.Tags = new Map<string,string>();
          this.airbnbNames[index] = (room.Tags["AIRBNB_NAME"])?room.Tags["AIRBNB_NAME"]:"";
          this.bnbCancellationPolicy[index] = (room.Tags["AIRBNB_CANCELLATION_POLICY"])?room.Tags["AIRBNB_CANCELLATION_POLICY"]:"";
          this.cleaningFees[index] = (room.Tags["CLEANING_FEES"])?room.Tags["CLEANING_FEES"]:"";
          let bedRoomCount = 1;
          let roomBed = [];
          try{
            //Living Room
            if(room.Tags["ROOM_0"]){
              let beds = this.parseRoomBeds(JSON.parse(room.Tags["ROOM_0"]));
              roomBed[0] = beds;
            }
            //Bed Rooms
            while(bedRoomCount){
              if(room.Tags["ROOM_"+bedRoomCount]){
                let beds = this.parseRoomBeds(JSON.parse(room.Tags["ROOM_"+bedRoomCount]));
                roomBed[bedRoomCount] = beds;
                if(bedRoomCount == 10) break;
                bedRoomCount++
              } else break;
            }
            this.roomBeds.push(roomBed);
            
          }catch(ex){
            console.log("Error in parsing ROOM beds");
          }
          index++;
        });
      this.contentUpdateChannels = [];
      this.hotelMgmtService.findHotelChannels(this.hotelContext.HotelId).then(resp =>{
        resp.forEach(hotelChannel => {
          //TODO : Need to add channels which support content sync.
          if(hotelChannel.ChannelCode == "ANB"){
            this.contentUpdateChannels.push({value:hotelChannel.ChannelCode,label:Utilities.findId(Utilities.channelTypes, hotelChannel.ChannelCode, hotelChannel.ChannelCode)});
          }
          if(hotelChannel.ChannelCode == "BDC"){
            this.contentUpdateChannels.push({value:hotelChannel.ChannelCode,label:Utilities.findId(Utilities.channelTypes, hotelChannel.ChannelCode, hotelChannel.ChannelCode)});
          }
        });
        this.loadMenu();
    });
  }
  addRoom(roomIndex, roomNumber, bedType, numberOfBed, numberOfToliet){
    let beds = this.roomBeds[roomIndex];
    if(!beds) beds = [];
    if(beds.length <= roomNumber || !beds[roomNumber])
      beds[roomNumber] = [{"quantity":numberOfBed, "type":bedType, "en_suite_bathroom":numberOfToliet}];
    else 
      beds[roomNumber].push({"quantity":numberOfBed, "type":bedType, "en_suite_bathroom":numberOfToliet});
    this.roomBeds[roomIndex] = beds;
  }

  removeRoom(roomIndex,roomNumber,bedIndex){
    let roomBed = this.roomBeds[roomIndex];
    let beds = roomBed[roomNumber];
    if(beds.length < 2)
      roomBed[roomNumber] = null;
    else {
      beds.splice(bedIndex,1);
    } 
  }

  parseRoomBeds(bedsData){
    let beds = bedsData["beds"];
    let amenities = bedsData["room_amenities"];
    if(!beds) beds =[];
    if(amenities && amenities.length > 0){
      let bed = (beds.length > 0)?beds[0]:{};
      bed["en_suite_bathroom"] = amenities[0]["quantity"];
      beds[0] = bed; 
    }
    return beds;
  }

  stringifyRoomBeds(roomIndex,roomNumber){
    let jsonObj;
    if(this.roomBeds[roomIndex] && this.roomBeds[roomIndex].length > 0 &&
      this.roomBeds[roomIndex][roomNumber] && this.roomBeds[roomIndex][roomNumber].length >0){
      jsonObj = new BedTypeCountAmenities();
      let numberOfBathrooms = 0;
      this.roomBeds[roomIndex][roomNumber].forEach(bed => {
        if(bed["quantity"] > 0){
          if(!jsonObj.beds) jsonObj.beds = [];
          jsonObj.beds.push({"quantity":bed["quantity"],"type":bed["type"]});
        }
        if(bed["en_suite_bathroom"] > 0){
          numberOfBathrooms += parseInt(bed["en_suite_bathroom"]);
        }
      });
      if(numberOfBathrooms > 0){
        jsonObj.room_amenities = [];
        jsonObj.room_amenities.push({"quantity":numberOfBathrooms,"type":"en_suite_bathroom"});
      }
    }
    return (jsonObj)?JSON.stringify(jsonObj):null;
  }

  getImageObj(url,text?){
    return {image:url,thumbImage:url,title:text};
  }
  loadMenu(){
    this.mantrasMenuItems = [];
    this.mantrasMenuItems.push({ title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o',disabled:this.isReadOnly, command: (event) => this.onSave(event)});
    if(this.contentUpdateChannels.length > 0)
      this.mantrasMenuItems.push({ title: 'Sync Content', label: 'Sync Content', icon: 'action-bar-menu-icon fa icon ion-md-sync',disabled:this.isReadOnly, command: (event) => this.showSyncChannelList()});
    this.getMenuItemData();  
  }

  ngOnInit() {
    this.setHeight(); 
  }
  ngOnDestroy(){
    this.subscription.unsubscribe();
    this.roomTypesSubscription.unsubscribe();
  }

  async onSave(event){
    //HotelInfo Properties:
    this.updateTags(this.property,"USP",this.usp);
    this.updateTags(this.property,"Max Infant Age",this.infantAge);
    this.updateTags(this.property,"Max Child Age",this.childAge);
    if(this.selectedOTAPartners || this.bookingEngineCode){
      if(this.selectedOTAPartners == null || this.selectedOTAPartners == undefined) this.selectedOTAPartners =[];
      if(this.bookingEngineCode) this.selectedOTAPartners.push(this.bookingEngineCode);
      this.updateTags(this.property,"Partners Enabled",this.selectedOTAPartners.toString().replace(/,/gi,";"));
    }
    if(this.paymentGatewayCodes) this.updateTags(this.property,"PaymentGateway",this.paymentGatewayCode.toString());
    this.updateTags(this.property,"Partner Id", this.partnerId);
    this.updateTags(this.property,"CUSTOM_HOUSE_RULES", this.customHouseRules);
    this.property.CheckInTime = this.checkintime.getHours()+":"+this.checkintime.getMinutes()+":"+this.checkintime.getSeconds();
    this.property.CheckOutTime = this.checkouttime.getHours()+":"+this.checkouttime.getMinutes()+":"+this.checkouttime.getSeconds();
    
    //Rooms Properties:
    let idx = 0;
    this.rooms.forEach(room => {
      let roomName = this.airbnbNames[idx];
      if(room.Tags){
        this.updateTags(room,"AIRBNB_NAME",this.airbnbNames[idx]);
        this.updateTags(room,"AIRBNB_CANCELLATION_POLICY", this.bnbCancellationPolicy[idx]);
        this.updateTags(room,"CLEANING_FEES", this.cleaningFees[idx]);
      }
      //Bed Rooms, Bed Types and Count parsing
      try{
        //Living Room
        let roomBed = this.stringifyRoomBeds(idx,0);
        if(room.Tags["ROOM_0"] || roomBed){
          this.updateTags(room, "ROOM_0", roomBed);
        }
        let bedRoomCount = 1;
        //Bed Rooms
        while(bedRoomCount){
          let roomBed = this.stringifyRoomBeds(idx,bedRoomCount);
          if(room.Tags["ROOM_"+bedRoomCount] || roomBed){
            this.updateTags(room, "ROOM_"+bedRoomCount, roomBed);
            bedRoomCount++
          } else break;
        }
      }catch(ex){
        console.log("Error in parsing ROOM beds");
      }
      idx++;
    });
    console.log("Property Data: ",JSON.stringify(this.property));
    console.log("Rooms Details: ",JSON.stringify(this.rooms));
    await this.hotelMgmtService.saveHotel(this.property);
    await this.hotelMgmtService.saveRoomTypes(this.hotelContext.HotelId, this.rooms,"MantrasXchange");
    this.loadData();
  }

  updateTags(field, key,value){
    if(!field.Tags || field.Tags == null) field.Tags = new Map<string,string>(); 
    if(value == null || value == "") delete field.Tags[key]; 
    else field.Tags[key] = value;
  }

  showChannelList = false;
  selectedChannels = [];
  SyncContent(event){
    //TODO: Currently this is made specific to Airbnb.
    let domainId = this.storageService.get(StorageService.userDomainContext).CurrentDomainId;
    this.selectedChannels.forEach(chCode =>
      this.hotelMgmtService.syncHotelData(domainId, this.hotelContext.HotelId, chCode)
    );
    this.showChannelList = false;
  }

  showSyncChannelList(){
    this.selectedChannels=[];
    this.contentUpdateChannels.forEach(c => this.selectedChannels.push(c.value)); 
    this.showChannelList = true;
  }
  showAddRoomDialog = false;
  dialogRoomNumber = 1;
  dialogBedType;
  dialogNosBed;
  dialogNosBathroom;
  dialogRoomIndex;
  addRoomDialog(roomIndex){
    this.dialogRoomIndex = roomIndex;
    this.dialogBedType = "single_bed";
    this.dialogNosBed = 1;
    this.dialogNosBathroom = 0;
    this.dialogRoomNumber = 0;
    this.showAddRoomDialog = true;
  }

  addRoomEvent(){
    this.addRoom(this.dialogRoomIndex,this.dialogRoomNumber,this.dialogBedType,this.dialogNosBed,this.dialogNosBathroom);  
    this.showAddRoomDialog = false;
  }

  updateRoomTypeCategory(){
    let propertyType = Utilities.HotelType.find(p => p.value == this.property.PropertyType);
    if(propertyType){
      this.roomTypeCategories = [];
      Utilities.RoomTypeCategories.forEach(rtc => {
        if(propertyType.supportedroomtypes.indexOf(rtc.value)>=0)
          this.roomTypeCategories.push(rtc);
      });
    }
  }

  amountTypes = [{label:"Amount",value:0},{label:"Percent",value:1},{label:"Room Nights",value:2}];
  showAddEditPolicy = false;
  selectedPolicy = new CancellationPolicy();
  selectedPolicyClone;
  addEditPolicy(policyId?){
    if(policyId){
        this.selectedPolicyClone = this.property.CancellationPolicies.find(p => p.Id == policyId);
        this.selectedPolicy = JSON.parse(JSON.stringify(this.selectedPolicyClone));
    } else {
      this.selectedPolicy = new CancellationPolicy();
      this.selectedPolicy.Items = new Array();
      let defaultItem = new CancellationPolicyItem();
      defaultItem.DaysOut = -1;
      defaultItem.AmountType = 1;
      this.selectedPolicy.Items.push(defaultItem);
      this.selectedPolicyClone = null;
    }
    this.showAddEditPolicy = true;
    return false; // This is to stop navigation.
  }

  addPolicyItem(){
    let cp = new CancellationPolicyItem();
    cp.DaysOut=0;
    cp.AmountType=1;
    cp.Amount=0;
    this.selectedPolicy.Items.push(cp);
    return false; // This is to stop navigation.
  }

  removePolicy(index){
    if(index > 0 && this.selectedPolicy.Items.length > index){
      this.selectedPolicy.Items.splice(index,1);
    }
    return false;
  }

  deletePolicy(policyId){
    let selPol = this.property.CancellationPolicies.find(pol => pol.Id === policyId);
    let indexToRemove = this.property.CancellationPolicies.indexOf(selPol);
    this.property.CancellationPolicies.splice(indexToRemove,1);
  }

  saveCancellationPolicy(){
    if(this.selectedPolicy.Items.length >0){
      for(let i = 0; i < this.selectedPolicy.Items.length;){
        if(this.selectedPolicy.Items[i].Amount == undefined || this.selectedPolicy.Items[i].Amount == null){
          if(i == 0) { this.selectedPolicy.Items[i].Amount = 0; i++ }
          else this.selectedPolicy.Items.splice(i,1);
        } else i++;
      }
      if(this.selectedPolicy.Id == null){
        this.selectedPolicy.Id = Utilities.generateUUID();
        if(!this.property.CancellationPolicies) this.property.CancellationPolicies = [];
        this.property.CancellationPolicies.push(this.selectedPolicy);
        this.selectedPolicy.Text = this.getPoliyText(this.selectedPolicy.Items); 
      } else {
        this.selectedPolicyClone.Name = this.selectedPolicy.Name;
        this.selectedPolicyClone.Items = this.selectedPolicy.Items;
        if(this.selectedPolicyClone.Id != "FREE-CANCELLATION" && this.selectedPolicyClone.Id != "NON-REFUNDABLE")
        this.selectedPolicyClone.Text = this.getPoliyText(this.selectedPolicy.Items); 
      }
    }
    this.showAddEditPolicy = false;
  }

  getPoliyText(policyItems){
    let textString = "";
    if(policyItems && policyItems.length > 0){
      let itemClone = JSON.parse(JSON.stringify(policyItems));
      itemClone.sort(Utilities.sortByMutipleFields("!DaysOut"));
      itemClone.forEach(item => {
        var prefix = "If cancelled "+item.DaysOut+" day(s) before date of arrival ";
        if (item.DaysOut < 0) prefix = "In case of no-show ";
        if (item.AmountType == 2)
            textString += prefix +  item.Amount+ " night will be charged. ";
        else if (item.AmountType == 1)
                textString += prefix + item.Amount+"% of the total booking amount will be charged. ";
        else 
            textString += prefix + "an amount of "+item.Amount+" will be charged. ";
      });
    }
    return textString;
  }

  afterUploadPropertyPicture(file){
    console.log("UploadFile: ", file);
    if(file.xhr.status == 200){
      if(!this.property.Pictures) this.property.Pictures = [];
      let pic = new Image();
      pic.Url = file.xhr.response.replace(/\"/gi,"");
      this.property.Pictures.push(pic);
      this.propertyImages = [];
      this.property.Pictures.forEach(picture => {
        this.propertyImages.push({image:picture.Url,thumbImage:picture.Url,title:picture.Text?picture.Text:""});
      });
    }
  }

  afterUploadImages(file){
    console.log("UploadFile: ", file);
    if(file.xhr.status == 200){
      if(!this.images) this.images = [];
      let pic = new Image();
      pic.Url = file.xhr.response.replace(/\"/gi,"");
      this.images.push(pic);
    }
  }

  roomId = null;
  manageImages(pictures,roomId?){
    this.roomId = (roomId)?roomId:null;
    if(pictures)
      this.images = JSON.parse(JSON.stringify(pictures));
    else {
      pictures = this.images = []; 
    }
    this.showManagePicture = true;
  }

  images;
  showManagePicture =false;
  removeImg(index){
    this.images.splice(index,1);
  }
  saveImages(){
    if(this.roomId == null){
      this.property.Pictures = this.images;
      this.propertyImages = [];
      this.property.Pictures.forEach(pic => {
        this.propertyImages.push(this.getImageObj(pic.Url,pic.Text?pic.Text:""));
      });
    } else {
      let roomIdx = 0;
      let found = false;
      this.rooms.forEach(room => {
        if(room.Id == this.roomId || found) found = true;
        else roomIdx++;
      });
      this.rooms[roomIdx].Pictures = this.images;
      this.roomImages[roomIdx] = [];
      this.images.forEach(pic => {
        this.roomImages[roomIdx].push(this.getImageObj(pic.Url,pic.Text?pic.Text:""));
      });
    }
    this.showManagePicture = false;
  }

  moveBottom(imgIndex){
    if(this.images.length-1 != imgIndex) {
      if(imgIndex+1 == this.images.length-1) this.swap(this.images,imgIndex,this.images.length-1);
      else {
        let item = this.images.splice(imgIndex,1);
        this.images = this.images.concat(item);
      }
    }
  }

  moveDown(imgIndex){
    if(imgIndex+1 < this.images.length) this.swap(this.images,imgIndex,imgIndex+1);
  }

  moveUp(imgIndex){
    if(imgIndex != 0) this.swap(this.images,imgIndex,imgIndex-1);
  }

  moveTop(imgIndex){
    if(imgIndex != 0){
      if(imgIndex == 1) this.swap(this.images,imgIndex,0);
      else {
        let item = this.images.splice(imgIndex,1);
        this.images = item.concat(this.images);
      }
    }
  }

  swap(target,idx1, idx2){
    let tmp = target[idx1];
    target[idx1] = target[idx2];
    target[idx2] = tmp;
  }
  getMenuItemData(){
    let data = [];
    data.push({'source':'mantrasXchange'});
    data.push(this.mantrasMenuItems);
    this.onChangeMenuItems.emit(data);
  }
  @HostListener('window:resize') setHeight(){
    let windowHeight = window.innerHeight;
    jQuery('.propertyDetailSection').css('height', Utilities.getScrollHeight(true,true,false,false));
  }
  //OnClose
  onClose() {
    this.router.navigate(["/settings"]);
  }
}

class BedTypeCountAmenities{
  beds:any[];
  room_amenities:any[];
}
