import {AfterViewInit, Component, ViewChild, ViewContainerRef, ElementRef} from "@angular/core";

import {ICellEditorAngularComp} from "ag-grid-angular";
import { StorageService } from "../ui-services/storage.service";
import { Utilities } from "../ui-services/utilities";
import { DomainHotel } from "mantras-api";
import { AuthenticationService } from "../ui-services/authentication.service";
import { UserService } from '../ui-services/user.service';

@Component({
    selector: 'roomrateplanfield-celleditor',
    templateUrl: 'roomrateplanfieldEditor.component.html'
})
export class RoomRatePlanFieldEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private rateCalParam: any;
    private cancelBeforeStart: boolean = false;

    @ViewChild('perDay') perDay: ElementRef;
    @ViewChild('singleOccupancy') singleOccupancy: ElementRef;
    @ViewChild('doubleOccupancy') doubleOccupancy: ElementRef;
    @ViewChild('tripleOccupancy') tripleOccupancy: ElementRef;
    @ViewChild('quadraOccupancy') quadraOccupancy: ElementRef;
    @ViewChild('pentaOccupancy') pentaOccupancy: ElementRef;
    @ViewChild('hexaOccupancy') hexaOccupancy: ElementRef;
    @ViewChild('heptaOccupancy') heptaOccupancy: ElementRef;
    @ViewChild('octaOccupancy') octaOccupancy: ElementRef;
    @ViewChild('nonaOccupancy') nonaOccupancy: ElementRef;
    @ViewChild('decaOccupancy') decaOccupancy: ElementRef;
    @ViewChild('availability') availability: ElementRef;
    @ViewChild('soldRooms') soldRoom: ElementRef;
    @ViewChild('totalInventory') totalInventory: ElementRef;
    @ViewChild('perExtraPerson') perExtraPerson: ElementRef;
    @ViewChild('extraChildren') extraChildren: ElementRef;
    @ViewChild('minStay') minStay: ElementRef;
    @ViewChild('maxStay') maxStay: ElementRef;
    @ViewChild('CTA') CTA: ElementRef;
    @ViewChild('CTD') CTD: ElementRef;
    @ViewChild('minAdvPurchaseDays') minAdvPurchaseDays: ElementRef;
    @ViewChild('maxAdvPurchaseDays') maxAdvPurchaseDays: ElementRef;

    public fieldName: String;
    public data;
    public occupancyLinkingEnable;
    public domainHotel;
    public minVal; maxVal;
    todaysDate = new Date();
    public inventoryOnly = false;
    constructor(private storageService:StorageService,private authenticationService:AuthenticationService, userService:UserService){
        this.inventoryOnly = userService.isInventoryOnly();
    }
    agInit(params: any): void {
        this.todaysDate = new Date(Date.UTC(this.todaysDate.getFullYear(), this.todaysDate.getMonth(), this.todaysDate.getDate()));
        this.rateCalParam = params;
        if(params.node.data){
            this.fieldName = params.node.data.roomRatePlanFields;
            this.data = params.node.data.roomRatePlans[params.column.colDef.colId];
            this.occupancyLinkingEnable = params.node.data.occupancyLinking;
            let linkedOccupancies = params.node.data.linkedOccupancies;
            if(!this.occupancyLinkingEnable && this.fieldName.startsWith("occupancy")){
                let index = parseInt(this.fieldName.substring(9)) + 1;
                if(!this.occupancyLinkingEnable && params.node.data.linkedOccupancies && params.node.data.linkedOccupancies.length){
                    if(params.node.data.linkedOccupancies.indexOf(index) >=0)
                        this.occupancyLinkingEnable = true;
                }
            }
        }
        if(params.value){
            this.data = params.value[params.column.colDef.colId];   
            let hotelContext = this.storageService.get(StorageService.userHotelContext);
            // //Rate plan level linking
            // let linkId = Utilities.findByName(hotelContext.RatePlans, this.data.RatePlanId, "LinkedId");
            // if(linkId) this.occupancyLinkingEnable = true;
            // //Room level linking
            // linkId = Utilities.findByName(hotelContext.RoomTypes,this.data.RoomTypeId, "LinkedId");
            // if(linkId) this.occupancyLinkingEnable = true;
            // if(this.fieldName.startsWith("occupancy") && !this.occupancyLinkingEnable){
            //     let index = parseInt(this.fieldName.substring(9)) + 1; 
            //     let linking = hotelContext.RoomRatePlans.find(linkRRP => linkRRP.RoomId == this.data.RoomTypeId 
            //         && linkRRP.RatePlanId == this.data.RatePlanId);
            //     if(linking && linking.LinkedOccupancy && linking.LinkedOccupancy.length){
            //         if(linking.LinkedOccupancy.indexOf(index) >=0)
            //             this.occupancyLinkingEnable = true;
            //     }
            // }
            this.domainHotel = this.authenticationService.getDomainHotel(hotelContext.HotelId);
            if(this.domainHotel){
                this.minVal = this.domainHotel.MinRate;
                this.maxVal = this.domainHotel.MaxRate;
            }
        }
    }

    getValue(): any {
        let rateCal = this.rateCalParam.value[this.rateCalParam.column.colDef.colId];
        if(rateCal){
            if(this.fieldName == "perDay") rateCal.RoomRate.PerDay = Utilities.checkUpdatedField(rateCal.RoomRate.PerDay, this.perDay.nativeElement.value);
            if(this.fieldName == "occupancy0"){
                if(this.singleOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {};
                    rateCal.RoomRate.PerOccupancy[1] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[1], this.singleOccupancy.nativeElement.value);
                }
            }
            if(this.fieldName == "occupancy1"){
                if(this.doubleOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {};
                    rateCal.RoomRate.PerOccupancy[2] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[2], this.doubleOccupancy.nativeElement.value);
                }
            }
            if(this.fieldName == "occupancy2"){
                if(this.tripleOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {};
                    rateCal.RoomRate.PerOccupancy[3] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[3],this.tripleOccupancy.nativeElement.value);
                } 
            }
            if(this.fieldName == "occupancy3"){
                if(this.quadraOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {};
                    rateCal.RoomRate.PerOccupancy[4] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[4],this.quadraOccupancy.nativeElement.value);
                }
            } 
            if(this.fieldName == "occupancy4"){ 
                if(this.pentaOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {};
                    rateCal.RoomRate.PerOccupancy[5] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[5],this.pentaOccupancy.nativeElement.value);
                }
            }
            if(this.fieldName == "occupancy5"){
                if(this.hexaOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {};
                    rateCal.RoomRate.PerOccupancy[6] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[6],this.hexaOccupancy.nativeElement.value);
                }
            } 
            if(this.fieldName == "occupancy6"){
                if(this.heptaOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {};
                    rateCal.RoomRate.PerOccupancy[7] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[7],this.heptaOccupancy.nativeElement.value);
                }
            } 
            if(this.fieldName == "occupancy7"){
                if(this.octaOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {};
                    rateCal.RoomRate.PerOccupancy[8] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[8],this.octaOccupancy.nativeElement.value);
                }
            } 
            if(this.fieldName == "occupancy8"){
                if(this.nonaOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {};
                    rateCal.RoomRate.PerOccupancy[9] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[9],this.nonaOccupancy.nativeElement.value);
                }
            } 
            if(this.fieldName == "occupancy9"){
                if(this.decaOccupancy.nativeElement.value != ""){
                    if(!rateCal.RoomRate.PerOccupancy) rateCal.RoomRate.PerOccupancy = {};
                    rateCal.RoomRate.PerOccupancy[10] = Utilities.checkUpdatedField(rateCal.RoomRate.PerOccupancy[10],this.decaOccupancy.nativeElement.value);
                }
            }
            
            // if(this.fieldName == "perExtraPerson" && (!this.isEmptyObject(rateCal.RoomRate.ExtraPerson) || this.perExtraPerson.nativeElement.value != "0")) 
            // {if(!rateCal.RoomRate.ExtraPerson) 
            //     rateCal.RoomRate.ExtraPerson = {}; 
            // rateCal.RoomRate.ExtraPerson = {"1":Utilities.checkUpdatedField(rateCal.RoomRate.ExtraPerson[1], this.perExtraPerson.nativeElement.value)}};
            
            if(this.fieldName == "extraChildren" && (!this.isEmptyObject(rateCal.RoomRate.ExtraChildren) || this.extraChildren.nativeElement.value != "0.00")) 
            {if(!rateCal.RoomRate.ExtraChildren) 
                rateCal.RoomRate.ExtraChildren = {}; 
            rateCal.RoomRate.ExtraChildren = {"1":Utilities.checkUpdatedField(rateCal.RoomRate.ExtraChildren[1], this.extraChildren.nativeElement.value)}};

            if(this.fieldName == "perExtraPerson") rateCal.RoomRate.PerExtraPerson = Utilities.checkUpdatedField(rateCal.RoomRate.PerExtraPerson, this.perExtraPerson.nativeElement.value);
            if(this.fieldName == "availability") rateCal.Availability = Utilities.checkUpdatedField(rateCal.Availability,this.availability.nativeElement.value);
            if(this.fieldName == "minStay") rateCal.MinStay = Utilities.checkUpdatedField(rateCal.MinStay, this.minStay.nativeElement.value);
            if(this.fieldName == "maxStay") rateCal.MaxStay = Utilities.checkUpdatedField(rateCal.MaxStay, this.maxStay.nativeElement.value);
            if(this.fieldName == "minAdvPurchaseDays") rateCal.MinAdvPurchaseDays = Utilities.checkUpdatedField(rateCal.MinAdvPurchaseDays, this.minAdvPurchaseDays.nativeElement.value);
            if(this.fieldName == "maxAdvPurchaseDays") rateCal.MaxAdvPurchaseDays = Utilities.checkUpdatedField(rateCal.MaxAdvPurchaseDays, this.maxAdvPurchaseDays.nativeElement.value);
        }
        return this.rateCalParam.value;
    }

    onValueChange(event,oldValue, decimalPlace=2){
        if(event.target){
            console.log("MAX: ",event.target.max);
            console.log("MIN: ",event.target.min);
            if(event.target.max && Number.parseFloat(event.target.value) > Number.parseFloat(event.target.max)){
                alert("Entered value is greater than maximum value : "+ event.target.max);
                event.target.value = oldValue;
            }
            if(event.target.min && Number.parseFloat(event.target.value) < Number.parseFloat(event.target.min)){
                alert("Entered value is lesser than minimum value : "+ event.target.min);
                event.target.value = oldValue;
            }
            event.target.value = parseFloat(event.target.value).toFixed(decimalPlace);
        }
    }

    isCancelBeforeStart(): boolean {
        if(this.data === undefined || this.data.ChannelCode != "MAX") return true;
        if(this.rateCalParam != undefined && this.rateCalParam.context.thisComponent && this.rateCalParam.context.thisComponent.displayDates){
            let selectionDate = this.rateCalParam.context.thisComponent.displayDates[this.rateCalParam.column.colDef.colId];
            if(Utilities.dateDifference(selectionDate,this.todaysDate)>1) return true;
        }
        else return false;
    }

    // will reject the number if it greater than 1,000,000
    // not very practical, but demonstrates the method.
    // isCancelAfterEnd(): boolean {
    //     //return this.perDay > 1000000;
    //     return false;
    // };

    onKeyDown(event): void {
        let key = event.which || event.keyCode;
        if (key == 37 ||  // left
            key == 39) {  // right
            //this.toggleMood();
            event.stopPropagation();
        }
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
        if(this.data){
            if(this.fieldName == "perDay") { this.perDay.nativeElement.value = parseFloat(this.perDay.nativeElement.value).toFixed(2); setTimeout(() => this.perDay.nativeElement.select(), 0);}
            if(this.fieldName == "occupancy0") { this.singleOccupancy.nativeElement.value = parseFloat(this.singleOccupancy.nativeElement.value).toFixed(2); setTimeout(() => this.singleOccupancy.nativeElement.select(), 0);}
            if(this.fieldName == "occupancy1") { this.doubleOccupancy.nativeElement.value = parseFloat(this.doubleOccupancy.nativeElement.value).toFixed(2); setTimeout(() => this.doubleOccupancy.nativeElement.select(), 0);}
            if(this.fieldName == "occupancy2") { this.tripleOccupancy.nativeElement.value = parseFloat(this.tripleOccupancy.nativeElement.value).toFixed(2); setTimeout(() => this.tripleOccupancy.nativeElement.select(), 0);}

            if(this.fieldName == "occupancy3") { this.quadraOccupancy.nativeElement.value = parseFloat(this.quadraOccupancy.nativeElement.value).toFixed(2); setTimeout(() => this.quadraOccupancy.nativeElement.select(), 0);}
            if(this.fieldName == "occupancy4") { this.pentaOccupancy.nativeElement.value = parseFloat(this.pentaOccupancy.nativeElement.value).toFixed(2); setTimeout(() => this.pentaOccupancy.nativeElement.select(), 0);}
            if(this.fieldName == "occupancy5") { this.hexaOccupancy.nativeElement.value = parseFloat(this.hexaOccupancy.nativeElement.value).toFixed(2); setTimeout(() => this.hexaOccupancy.nativeElement.select(), 0);}
            if(this.fieldName == "occupancy6") { this.heptaOccupancy.nativeElement.value = parseFloat(this.heptaOccupancy.nativeElement.value).toFixed(2); setTimeout(() => this.heptaOccupancy.nativeElement.select(), 0);}
            if(this.fieldName == "occupancy7") { this.octaOccupancy.nativeElement.value = parseFloat(this.octaOccupancy.nativeElement.value).toFixed(2); setTimeout(() => this.octaOccupancy.nativeElement.select(), 0);}
            if(this.fieldName == "occupancy8") { this.nonaOccupancy.nativeElement.value = parseFloat(this.nonaOccupancy.nativeElement.value).toFixed(2); setTimeout(() => this.nonaOccupancy.nativeElement.select(), 0);}
            if(this.fieldName == "occupancy9") { this.decaOccupancy.nativeElement.value = parseFloat(this.decaOccupancy.nativeElement.value).toFixed(2); setTimeout(() => this.decaOccupancy.nativeElement.select(), 0);}
            
            if(this.fieldName == "perExtraPerson") { this.perExtraPerson.nativeElement.value = parseFloat(this.perExtraPerson.nativeElement.value).toFixed(2); setTimeout(() => this.perExtraPerson.nativeElement.select(), 0);}
            if(this.fieldName == "extraChildren") { this.extraChildren.nativeElement.value = parseFloat(this.extraChildren.nativeElement.value).toFixed(2); setTimeout(() => this.extraChildren.nativeElement.select(), 0);}

            if(this.fieldName == "availability") { setTimeout(() => this.availability.nativeElement.select(), 0);}
            if(this.fieldName == "minStay") { setTimeout(() => this.minStay.nativeElement.select(), 0);}
            if(this.fieldName == "maxStay") { setTimeout(() => this.maxStay.nativeElement.select(), 0);}
            if(this.fieldName == "minAdvPurchaseDays") { setTimeout(() => this.minAdvPurchaseDays.nativeElement.select(), 0);}
            if(this.fieldName == "maxAdvPurchaseDays") { setTimeout(() => this.maxAdvPurchaseDays.nativeElement.select(), 0);}
        }
    }

    private getCharCodeFromEvent(event): any {
        event = event || window.event;
        return (typeof event.which == "undefined") ? event.keyCode : event.which;
    }

    private isCharNumeric(charStr): boolean {
        return !!/\d/.test(charStr);
    }

    private isKeyPressedNumeric(event): boolean {
        const charCode = this.getCharCodeFromEvent(event);
        const charStr = event.key ? event.key : String.fromCharCode(charCode);
        return this.isCharNumeric(charStr);
    }
    isEmptyObject(object){
        if(!object) return true;
        return JSON.stringify(object) === JSON.stringify({});
    }
}