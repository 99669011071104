import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dev-lab',
  templateUrl: './dev-lab.component.html',
  styleUrls: ['./dev-lab.component.css']
})
export class DevLabComponent implements OnInit {

  constructor(private router:Router,private route:ActivatedRoute) { }

  ngOnInit() {
  }
  async routePage(navigationLink){
    this.router.navigate([navigationLink], {relativeTo: this.route });
  }
}
