import {Component, ViewChild, ElementRef} from "@angular/core";

import {ICellRendererParams} from "ag-grid";
import {ICellRendererAngularComp} from "ag-grid-angular";
import { RRPCellFilter } from "../ui-services/ratecalendar.service";
import { AuthenticationService } from "../ui-services/authentication.service";
import { StorageService } from "../ui-services/storage.service";

@Component({
    selector: 'roomrateplanfield',
    templateUrl: './roomrateplanfield.component.html'
})
export class RoomRatePlanFieldComponent implements ICellRendererAngularComp {
    public data;
    public remoteData;
    public fieldName: String;
    public isGrouped;
    public isClosed;
    public isManual = false;
    public isRateRule = false;
    isRateMgrLock = false;
    public outOfSyncThreshold = 0;
    isRowPinnedTop = false;
    outOfSyncMessage = "Out Of Sync (";
    public occupancyLinkingEnable;
    channelType;
    formatNumber = "1.0-0";
    constructor(private storageService:StorageService, private authenticationService:AuthenticationService){
        let hotelId = storageService.get(StorageService.userHotelContext).HotelId;
        let domainHotel = authenticationService.getDomainHotel(hotelId);
        this.outOfSyncThreshold = parseFloat(domainHotel.OutOfSyncThreshold);
    }

    agInit(params: ICellRendererParams): void {
        this.fieldName = params.colDef.field;
        this.isGrouped = params.node.group;
        this.isRowPinnedTop = params.node.rowPinned == "top";
        if(params.data && !this.isRowPinnedTop){
           this.data = params.data.roomRatePlans[params.colDef.colId];
           this.remoteData = params.data.roomRatePlansRemote[params.colDef.colId];
           this.channelType = params.data.channelType; 
           if(params.data.channelType != "MAX"){
                this.outOfSyncMessage += "Extranet Value: ";
            } else {
                if(this.data) this.isRateMgrLock = this.data.RateMgrLock; 
            }
           this.fieldName = params.data.roomRatePlanFields;
           if(this.data) {
              this.isClosed = this.data.IsClosed;
              this.isManual = this.data.Source == 'Manual';
              this.isRateRule = this.data.Source == 'RateManager';
           }
           this.occupancyLinkingEnable = params.node.data.occupancyLinking;
            let linkedOccupancies = params.node.data.linkedOccupancies;
            if(!this.occupancyLinkingEnable && this.fieldName.startsWith("occupancy")){
                let index = parseInt(this.fieldName.substring(9)) + 1;
                if(!this.occupancyLinkingEnable && linkedOccupancies && linkedOccupancies.length){
                    if(linkedOccupancies.indexOf(index) >=0)
                        this.occupancyLinkingEnable = true;
                }
            }
        } else {
            if(params.data)
                this.data = params.data[params.colDef.colId];
        }
    }

    refresh(params: any): boolean {
        console.log("Refresh method called: " +params);
        return true;
    }

    isEmptyObject(object){
        if(!object) return true;
        return JSON.stringify(object) === JSON.stringify({});
    }

    checkOutOfSync(localValue,remoteValue,index=0){
        if(!index){
            return Math.abs(localValue - remoteValue) > this.outOfSyncThreshold;
        } else {
            if(localValue == remoteValue) return false;
            if(localValue && localValue[index] && remoteValue && remoteValue[index]) 
            return Math.abs(localValue[index] - remoteValue[index]) > this.outOfSyncThreshold;
        }
    }

}