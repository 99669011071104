import { Component, OnInit, HostListener } from '@angular/core';
import * as jQuery from 'jquery';
import { Utilities } from '../ui-services/utilities';
import { NotificationSettings, RateInventoryNotification, BookingNotification, RevenueManagementNotification, AnalyticsNotification } from '../ui-services/notification.service';
import { Router } from '@angular/router';

@Component({
  selector: 'notificationsettings',
  templateUrl: './notificationsettings.component.html',
  styleUrls: ['./notificationsettings.component.css']
})
export class NotificationsettingsComponent implements OnInit {
  frequencyTypes;
  notificationsChecked;
  ratesAndInvChecked;
  bookingsChecked;
  analyticsChecked;
  revenueMgmtChecked;
  notifSetMenuItem;
  isReadOnly;
  notificationSettings:NotificationSettings;
  rateLevelShow=true;
  inventoryLevelShow=true;
  missingRateLevelShow=true;
  statusChangeShow = true;
  priceRecomShow=true;
  channelIssueShow =true;
  paceDiffShow = true;
  compIndexShow = true;
  compIndexAnalysisShow = true;
  compPriceChangeShow = true;
  missChannelInvShow = true;
  missRateInvRatePlans=true;
  channelDisparityShow=true;
  rangeValues=[0,100];
  roomTypes = [{label:'All',value:null},{label:'Room 1',value:"room1"},{label:'Room 2',value:'room2'}, {label:'Room 3',value:'room3'}];
  ratePlans = [{label:'All',value:null},{label:'Rate Plan 1',value:"rateplan1"},{label:'Rate Plan 2',value:'rateplan2'}, {label:'Rate Plan 3',value:'rateplan3'}];
  bookingStatus= [{ label: "New", value: 2 },{ label: "Modified", value: 4 },{ label: "Cancelled", value: 3 },{ label: "NoShow", value: 6 }];
  durations = [{label:'15 Days',value:15},{label:'30 Days',value:30},{label:'60 Days',value:60},{label:'90 Days',value:90},{label:'120 Days',value:120},{label:'180 Days',value:180},{label:'365 Days',value:365}]
  constructor(private router: Router) { 
    this.notificationSettings = new NotificationSettings();
    this.notificationSettings.RateInventoryNotification = new RateInventoryNotification();
    this.notificationSettings.BookingNotification = new BookingNotification();
    this.notificationSettings.RevenueManagementNotification = new RevenueManagementNotification();
    this.notificationSettings.AnalyticsNotification = new AnalyticsNotification();
  }

  loadMenu(){
    this.notifSetMenuItem = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o',disabled:this.isReadOnly, command: (event) => this.onSave(event)},
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(false) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose(false) }];
  }

  ngOnInit() {
    this.frequencyTypes =[{label:"Once a day",value:0},
    {label:"Every six hours",value:1}, 
    {label:"Every three hours",value:2}];

    this.loadMenu();
    this.setHeight();
  }

  @HostListener('window:resize') setHeight(){
    let windowHeight = window.innerHeight;
    jQuery('.notificationSection').css('height', Utilities.getScrollHeight(true,false,false,false));
  }

  onClose(event) {
    this.router.navigate(["/settings"]);
  }

  onSave(event){
    //Save Notification Settings
  }

  onRefresh(event){
    //Reload Notification Settings
    this.notificationSettings = new NotificationSettings();
  }

}
