import { Component, OnInit,AfterViewInit, ChangeDetectorRef,HostListener } from '@angular/core';
import { StorageService } from '../../ui-services/storage.service';
import { BookingService } from '../../ui-services/booking.service';
import { AuthenticationService } from '../../ui-services/authentication.service';
import { AnalyticsService } from '../../ui-services/analytics.service';
import { Utilities } from '../../ui-services/utilities'
import { BookingCriteria,Range,DateRange,SyncLogCriteria } from 'mantras-api';
import { forkJoin } from 'rxjs';
//AM charts imports
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import { chart } from 'highcharts';
import { DataProviderService } from '../../ui-services/data-provider.service';
import * as moment from 'moment';
import * as jQuery from 'jquery';
import { MessageService } from 'primeng/api';

//custom theme
function am4themes_myTheme(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [//Dark to light colors
      am4core.color("#1BA68D"),
      // am4core.color("#217378"),
      am4core.color("#65348F"),
      am4core.color("#3B378B"),
      // am4core.color("#4670B4"),
      am4core.color("#D21E75"),
      am4core.color("#E67436"),
      am4core.color("#44B44F"),
      am4core.color("#E5B032"),
      am4core.color("#CADA3D"),
      am4core.color("#F7C9B0")
    ];
  }
}
// am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_myTheme);


// am4core.useTheme(am4themes_animated);
// am4core.useTheme(am4themes_kelly);
@Component({
  selector: 'app-competitor-chart',
  templateUrl: './competitor-chart.component.html',
  styleUrls: ['./competitor-chart.component.css']
})
export class CompetitorChartComponent implements OnInit {

  bookingcriteria : BookingCriteria;
  bookings;
  bookingsStayDates;
  competitors;
  competitorResponse; 
  pieChartData = [];
  hotelContext;
  hotelInfo;
  channels = ["AGD"];
  selectedChannel = 'AGD';
  competitorGraphData;
  competitorHistoryData=[];
  displayDate;
  bookingDate;
  bookingDateStart;
  bookingDateStartPrint;
  bookingDateEnd;
  stayDateStart;
  stayDateEnd;
  bookingDataResponseRate;
  bookingDataResponseCount;
  minDate;
  maxDate;
  sliderValue;
  numberSelected;
  minNumber;
  maxNumber;
  maxRoomCount=0;
  maxBookingCountPerDay=0;
  bookingDaysCount=14;
  constructor(private storageService:StorageService,
    private bookingService: BookingService,
    private authenticationService: AuthenticationService,
    private analyticsService: AnalyticsService,
    private dataProviderService:DataProviderService,
    private messageService:MessageService) {
      this.sliderValue = this.convertDateToString(Utilities.convertToUTC((new Date())).toISOString());
   } 

  async ngOnInit() {
    this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    let domainHotel = this.authenticationService.getDomainHotel(this.hotelContext.HotelId);
    this.competitors = domainHotel.CompetitorHotels;
    //TODO: This is to remove the target hotel id which is duplicated in the list.
    if(this.competitors && this.competitors.length >= 2){
      //Check first and last index hotel id and remove last if its same.
      if(this.competitors[0] == this.competitors[this.competitors.length - 1]){
        this.competitors.pop();
      }
    }
     this.initialize();
     this.hotelInfo = await this.analyticsService.findHotels(this.competitors);
     this.competitorHistoryData = await this.getCompHistoryData();
     this.bookings = await this.getBookings();
     this.bookingsStayDates = await this.getBookingsStayDatesCriteria();
     this.processData();
  }
  async ngAfterViewInit(){
    // this.hotelInfo = await this.analyticsService.findHotels(this.competitors);
    // this.handleChange(new Date());
    this.setHeight();
  }
  @HostListener('window:resize') setHeight() {
    jQuery('.divHeight').css('height', Utilities.getScrollHeight());
  }
  initialize(stayDateStart?){
    let todayDate = new Date();
    if(stayDateStart)
      this.stayDateStart = new Date(stayDateStart);
    else
      this.stayDateStart = new Date();
    this.stayDateEnd = new Date(this.stayDateStart);
    this.stayDateEnd.setDate(this.stayDateEnd.getDate()+14);
    this.stayDateStart = new Date(this.stayDateStart.getFullYear(),this.stayDateStart.getMonth(),this.stayDateStart.getDate(),0,0,0);
    // this.stayDateEnd = new Date(this.stayDateStart.getTime()+(1000*60*60*24)*14);
    this.stayDateEnd = new Date(this.stayDateEnd.getFullYear(),this.stayDateEnd.getMonth(),this.stayDateEnd.getDate(),23,59,0);
    this.bookingDateEnd = new Date(todayDate.getFullYear(),todayDate.getMonth(),todayDate.getDate(),0,0,0);
    this.bookingDate = new Date(todayDate.getFullYear(),todayDate.getMonth(),todayDate.getDate());
    this.sliderValue = this.convertDateToString(Utilities.convertToUTC(new Date(this.bookingDate)).toISOString());
    this.bookingDateStart = new Date(this.bookingDateEnd);
    this.bookingDateStart.setDate(this.bookingDateEnd.getDate()-this.bookingDaysCount);
    this.bookingDateStartPrint = this.convertDateToString(Utilities.convertToUTC((this.bookingDateStart)).toISOString());
    // this.bookingDate = new Date(this.bookingDateStart.getFullYear(),this.bookingDateStart.getMonth(),this.bookingDateStart.getDate());
    // this.sliderValue = this.convertDateToString(Utilities.convertToUTC(new Date(this.bookingDate)).toISOString());
    this.numberSelected=this.bookingDaysCount;
    this.minNumber=0;
    this.maxNumber=this.bookingDaysCount;
  }

  async getBookings(){
    let bookings;
    let criteria = new BookingCriteria();
    criteria.BookingDates = new Range<Date>();
    criteria.BookingDates.Start = new Date(this.bookingDateStart.getFullYear(),this.bookingDateStart.getMonth(),this.bookingDateStart.getDate(),0,0,0);
    criteria.BookingDates.End = new Date(this.bookingDateEnd.getFullYear(),this.bookingDateEnd.getMonth(),this.bookingDateEnd.getDate(),23,59,0);
    criteria.StayDates = new Range<Date>();
    criteria.DomainIds = [this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId];
    criteria.HotelIds = [this.storageService.get(StorageService.currentUser).HotelContext.HotelId];
    criteria.StayDates.Start = new Date(this.stayDateStart.getFullYear(),this.stayDateStart.getMonth(),this.stayDateStart.getDate(),0,0,0);
    criteria.StayDates.End = new Date(this.stayDateEnd.getFullYear(),this.stayDateEnd.getMonth(),this.stayDateEnd.getDate(),23,59,0);
    await this.bookingService.findBookings(criteria).then(
      async response => {
        bookings = response; 
      });
      return bookings;
  }
  async getBookingsStayDatesCriteria(){
    let bookings;
    let criteria = new BookingCriteria();
    criteria.StayDates = new Range<Date>();
    criteria.DomainIds = [this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId];
    criteria.HotelIds = [this.storageService.get(StorageService.currentUser).HotelContext.HotelId];
    criteria.StayDates.Start = new Date(this.stayDateStart.getFullYear(),this.stayDateStart.getMonth(),this.stayDateStart.getDate(),0,0,0);
    criteria.StayDates.End = new Date(this.stayDateEnd.getFullYear(),this.stayDateEnd.getMonth(),this.stayDateEnd.getDate(),23,59,0);
    await this.bookingService.findBookings(criteria).then(
      async response => {
        bookings = response; 
      });
      return bookings;
  }
  processData(param?){
    let p = param;
    // this.bookingDataResponseRate = this.getChartBookingDataRate(this.bookings);
    this.bookingDataResponseCount = this.getChartBookingDataCount(this.bookingsStayDates);
    this.maxRoomCount = this.getMaxRoomCount(this.bookingsStayDates);
    this.competitorGraphData = this.processChannelRatesHistory(this.competitorHistoryData,this.bookingDate); 
    // this.renderCompetitorGraphWithPerDayBookingsRate(this.bookingDataResponseRate,this.competitorGraphData);
    this.renderCompetitorGraphWithPerDayBookingsCount(p);
    this.setHeight();
  }
  getChartBookingDataRate(bookings){
    let bookingResponse = [];
    for(let date = new Date(this.stayDateStart);date<=this.stayDateEnd;date.setDate(date.getDate()+1)){
      let roomCount = 0;
      let avgNetRate = 0;
      let bookingRate = [];
      if(bookings){
        for(let booking of bookings){
          let creationDate = new Date(booking.Booking.CreatedOn);
          creationDate.setHours(0,0,0,0);
          if(this.bookingDate.getTime() == creationDate.getTime() && date >= new Date(booking.BasicDetails.CheckIn) && date < new Date(booking.BasicDetails.Checkout)){
            roomCount += booking.Rooms.length;
            avgNetRate += booking.TotalAmt.NetAmt/(Utilities.dateDifference(new Date(booking.Booking.StayInfo.InDate),new Date(booking.Booking.StayInfo.OutDate)) * booking.Booking.Rooms.length);
            bookingRate.push({"date":new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,0),"rate":booking.TotalAmt.NetAmt/(Utilities.daysDifference(new Date(booking.Booking.StayInfo.InDate),new Date(booking.Booking.StayInfo.OutDate)) * booking.Booking.Rooms.length)})
          }
        }
      }
      if(roomCount>this.maxRoomCount) this.maxRoomCount = roomCount;
      if(avgNetRate == 0) avgNetRate = null;
      bookingResponse.push({"date":new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,0),"roomCount":roomCount,"avgNetRate":avgNetRate,"bookingRate":bookingRate});
    }
    return bookingResponse;
  }
  getChartBookingDataCount(bookings){
    let bookingResponse = [];
    for(let date = new Date(this.stayDateStart);date<=this.stayDateEnd;date.setDate(date.getDate()+1)){
      let roomCount = 0;
      let avgNetRate = 0;
      let totalEligibleBookings = 0;
      if(bookings){
        for(let booking of bookings){
          let creationDate = new Date(booking.Booking.CreatedOn);
          creationDate.setHours(0,0,0,0);
          if(creationDate.getTime() > this.bookingDateStart.getTime() && creationDate.getTime() < this.bookingDate.getTime()){
            if(date >= new Date(booking.BasicDetails.CheckIn) && date < new Date(booking.BasicDetails.Checkout)){
              roomCount += booking.Rooms.length;
              totalEligibleBookings+=1;
              avgNetRate += booking.TotalAmt.NetAmt/(Utilities.dateDifference(new Date(booking.Booking.StayInfo.InDate),new Date(booking.Booking.StayInfo.OutDate)) * booking.Rooms.length);
            }
          }
        }
      }
      if(roomCount>this.maxRoomCount) this.maxRoomCount = roomCount;
      if(avgNetRate == 0) avgNetRate = null;
      if(avgNetRate>0 && totalEligibleBookings>0){
        avgNetRate = avgNetRate/totalEligibleBookings;
        avgNetRate = Number(avgNetRate.toFixed(0));
      }
      bookingResponse.push({"date":new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,0),"roomCount":roomCount,"avgNetRate":avgNetRate});
    }
    return bookingResponse;
  }
  getMaxRoomCount(bookings){
    let maxRoomCount=0;
    for(let date = new Date(this.stayDateStart);date<=this.stayDateEnd;date.setDate(date.getDate()+1)){
      let roomCount = 0;
      if(bookings){
        for(let booking of bookings){
          let creationDate = new Date(booking.Booking.CreatedOn);
          creationDate.setHours(0,0,0,0);
          if(creationDate.getTime() > this.bookingDateStart.getTime() && creationDate.getTime() < this.bookingDateEnd.getTime()){
            if(date >= new Date(booking.BasicDetails.CheckIn) && date < new Date(booking.BasicDetails.Checkout)){
              roomCount += booking.Rooms.length;
            }
          }
        }
      }
      if(roomCount>maxRoomCount) maxRoomCount = roomCount;
      }
      return maxRoomCount;
    }
  processChannelRatesHistory(historyData,bookingDate){
    let hotelCompetitorData = [];
    for(let compId of this.competitors){
      let hotelData = historyData.filter(data => data.hotelID == compId);
      if(hotelData.length>0)
        hotelCompetitorData.push({id:compId,data:this.processHotelData(hotelData[0].data,bookingDate)});
    }
    return hotelCompetitorData;
  }
  processHotelData(data,bookingDate){
    let returnData=[];
    for(let date = new Date(this.stayDateStart);date<=this.stayDateEnd;date.setDate(date.getDate()+1)){
      let stayDateData = data.filter(d => (new Date(d.StayDate)).getTime() == date.getTime() )
      let syncTimestamp;
      let rate;
      let flag = false;
      if(stayDateData){
        for(let stayData of stayDateData){
          if(!syncTimestamp && (new Date(stayData.SyncTimestamp)).getTime()<bookingDate.getTime()){
            flag=true;
            syncTimestamp = stayData.SyncTimestamp;
            rate = stayData.Rate;
          }else if((new Date(stayData.SyncTimestamp)).getTime() > (new Date(syncTimestamp)).getTime() && (new Date(stayData.syncTimestamp)).getTime() < this.bookingDate.getTime()){
            syncTimestamp = stayData.syncTimestamp;
            rate = stayData.Rate;
          }
        }
      }
      if(flag==true)
       returnData.push({date:new Date(date),Rate:rate})
    }
    return returnData;
  }
  getHotelData(hotelId){
    let data = [];
    this.competitorResponse.forEach(response => {
      if(response.HotelId == hotelId){
        let date = new Date(response.StayDate);
        data.push({date:new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,0),Rate:response.Rate});
      }
    });
    return data;
  }
  interval;
  renderCompetitorGraphWithPerDayBookingsCount(param?){
    var chart = am4core.create("lineChartWithGaps3", am4charts.XYChart);
    chart.paddingRight = 20;

    if(param && (param.source == 'sliderEnd' || param.source == 'bookingDateChange')){
      let data;
      data = this.getChartData();
      chart.data = []
      chart.addData(data);
      clearInterval(this.interval);
    }else{
      let that = this; 
      this.interval = setInterval(function() {
        if(that.numberSelected<0 || that.numberSelected>this.bookingDaysCount) {
          that.completeChart();
          clearInterval(that.interval);
        }
        let data;
        data = that.getChartData();
        chart.data = []
        chart.addData(data);
        if(that.bookingDate.getTime()<that.bookingDateEnd.getTime() && that.bookingDate.getTime()>=that.bookingDateStart.getTime())
          {
            that.bookingDate.setDate(that.bookingDate.getDate()+1);
            that.sliderValue = that.convertDateToString(Utilities.convertToUTC(that.bookingDate).toISOString());
            that.numberSelected = that.numberSelected + 1;
          }
        },2000);
    }
    /* Create a cursor */
    chart.cursor = new am4charts.XYCursor();
    chart.legend = new am4charts.Legend();

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.title.text = "Dates";
    dateAxis.tooltip.disabled = true;

    // Create value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Rate";
    valueAxis.tooltip.disabled = true;

    var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.title.text = "Room Nights";
    valueAxis2.renderer.opposite = true;
    valueAxis2.renderer.grid.template.disabled = true;
    valueAxis2.min = 0;
    if(this.maxRoomCount>0) valueAxis2.max = this.maxRoomCount+2;
    valueAxis2.maxPrecision=0; //only int values on y axis
    valueAxis2.tooltip.disabled = true;

    for(let competitor of this.competitorGraphData){
    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = competitor.id;
    series.dataFields.dateX = "date";
    series.name = this.findHotelName(competitor.id);
    series.strokeWidth = 2;
    series.tensionX = 0.8;
    let seriesHoverState = series.states.create("hover");
    seriesHoverState.properties.opacity = 1;
    
    let bullet = series.bullets.push(new am4charts.CircleBullet());  
    bullet.circle.radius = 2;
    let hoverState = bullet.states.create("hover");
    hoverState.properties.scale = 1.7;

    if(competitor.id != this.hotelContext.HotelId){
      series.opacity = 0.3;
      bullet.fillOpacity = 0.3;
    }

    series.tooltipText = `{valueY}`;
    series.connect = false;
    }

    var series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.valueY = "roomCount";
    series1.dataFields.dateX = "date";
    series1.yAxis = valueAxis2;
    series1.name = "Room Nights";
    series1.tooltipText = "{name} : {valueY}[/]\nAvg. Net Rate : {avgNetRate}";
    series1.fill = chart.colors.getIndex(0);
    series1.fillOpacity = 0.5;
    series1.strokeWidth = 0;
    series1.clustered = false;
    series1.opacity = 0.5;
    series1.columns.template.width = am4core.percent(40);
    series1.adapter.add('tooltipText', (text, target) => {
      let data = target.tooltipDataItem.dataContext;
      let returnData='';
      if (target.tooltipDataItem.dataContext) {
        if(data["roomCount"] && data["roomCount"]!=0){
          returnData += "Room Nights : "+data["roomCount"]+"\n";
        }
      if(data["avgNetRate"] && data["avgNetRate"]!=0){
        returnData += "Avg. Net Rate : "+this.numberWithCommas(data["avgNetRate"])+"\n";
      }
      }
      return returnData;
    });
  }
  getChartData(){
    let data = [];
    this.bookingDataResponseCount = this.getChartBookingDataCount(this.bookingsStayDates);
    this.competitorGraphData = this.processChannelRatesHistory(this.competitorHistoryData,this.bookingDate);
        for(let date = new Date(this.stayDateStart);date<=this.stayDateEnd;date.setDate(date.getDate()+1)){
          data.push({date:new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,0)});
        }
        this.competitorGraphData.forEach(competitor => {
          let id = competitor.id;
          competitor.data.forEach(dateRatePair => {
            if(dateRatePair.Rate>0){
              data.forEach(data => { 
                if(data.date.getDate() == dateRatePair.date.getDate()){
                  data[competitor.id] = dateRatePair.Rate;
                }
              });
            }
        });
      });
        data.forEach(d => {
          let dataFound=false;
          if(this.bookingDataResponseCount){
            this.bookingDataResponseCount.forEach(bookingData => {
              if(bookingData["date"].getTime() == d["date"].getTime()){
                d["roomCount"]=bookingData["roomCount"];
                d["avgNetRate"]=bookingData["avgNetRate"];
                }
                dataFound=true;
            });
          }
          if(dataFound==false) {
            d["roomCount"]=0;
            d["avgNetRate"]=0;
          }
      });
      return data;
    }
    isPlay=false;
    playChart(){
      if(this.isPlay) return;
      this.messageService.add({severity:'success', summary:'Analysis started', detail:'Competitor rates analysis'});
      this.isPlay = true;
        if(this.numberSelected<0 || this.numberSelected >= this.bookingDaysCount) this.completeChart()
        else{
          this.renderCompetitorGraphWithPerDayBookingsCount();
        }
    }
  
    stopChart(){
      clearInterval(this.interval);
      this.messageService.add({severity:'error', summary:'Analysis pause', detail:'Competitor rates has been paused'});
      this.isPlay = false;
    }
    completeChart(){
      clearInterval(this.interval);
      this.messageService.add({severity:'success', summary:'Analysis completed', detail:'Competitor rates analysis completed'});
      this.isPlay = false;
    }
    restartChart(){
      clearInterval(this.interval);
      this.isPlay = false;
      this.numberSelected = 0;
      this.bookingDate = new Date(this.bookingDateStart);
      this.sliderValue = this.convertDateToString(Utilities.convertToUTC(this.bookingDate).toISOString());
      this.playChart();
    }
  findHotelName(hotelId:string){
    let name = Utilities.findId(this.hotelInfo,hotelId,hotelId);
    if(hotelId == this.hotelContext.HotelId){
      name += " (You)";
    }
    return name;
  }
  async getCompHistoryData(){ //Need to call on stay date change
    this.stayDateStart = new Date(this.stayDateStart.getFullYear(),this.stayDateStart.getMonth(),this.stayDateStart.getDate(),5,30,0);
    this.stayDateEnd = new Date(this.stayDateEnd.getFullYear(),this.stayDateEnd.getMonth(),this.stayDateEnd.getDate(),5,30,0);
    let competitorLogCriteria = new SyncLogCriteria();
    competitorLogCriteria.ChannelCode = this.selectedChannel;
    for(let hotelId of this.competitors){
      competitorLogCriteria.HotelId = hotelId;
      competitorLogCriteria.StayDate = new DateRange();
      competitorLogCriteria.StayDate.Start = new Date();
      competitorLogCriteria.StayDate.End = new Date();
      competitorLogCriteria.StayDate.Start = this.stayDateStart;
      competitorLogCriteria.StayDate.End = this.stayDateEnd;
      let competitorData = await this.analyticsService.findChannelSyncLog(competitorLogCriteria);
      this.competitorHistoryData.push({hotelID:hotelId,data:competitorData});
    }
    return this.competitorHistoryData;
  }
  sliderChange(event){
    let date = new Date();
    date.setDate(date.getDate() + event.value - this.maxNumber);
    this.sliderValue = this.convertDateToString(Utilities.convertToUTC(date).toISOString());
    this.bookingDate = new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,0);
  }

  sliderEnd(event){
    this.isPlay = false;
    this.processData({source: "sliderEnd"});
  }
  convertDateToString(dateToBeConverted: string) {
    return moment(dateToBeConverted, "YYYY-MM-DD HH:mm:ss").format("DD-MMM-YYYY");
  }
  async onChangeStayDates(input){
    this.competitorHistoryData = []
    let date = new Date(this.stayDateStart);
    if(input == 'prev')
      date.setDate(date.getDate()-14);
    else
      date.setDate(date.getDate()+14);
    this.initialize(new Date(date));
    this.competitorHistoryData = await this.getCompHistoryData();
    this.bookings = await this.getBookings();
    this.processData();
  }
  numberWithCommas(x) {
    x = x.toFixed(0);
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  bookingDateChange(option){
      if(option == 'prev' && this.numberSelected > 0 && this.numberSelected <= this.bookingDaysCount){
        this.isPlay = false;
        this.bookingDate.setDate(this.bookingDate.getDate()-1);
        this.numberSelected = this.numberSelected - 1;
      }
      if(option == 'next' && this.numberSelected >= 0 && this.numberSelected < this.bookingDaysCount)
      {
        this.isPlay = false;
        this.bookingDate.setDate(this.bookingDate.getDate()+1);
        this.numberSelected = this.numberSelected + 1;
      }
      this.sliderValue = this.convertDateToString(Utilities.convertToUTC(this.bookingDate).toISOString());
      this.processData({source: "bookingDateChange"});
  }
}

/*
 renderCompetitorGraphWithPerDayBookingsRate(bookingDataResponse,competitorGraphData){
    var chart = am4core.create("lineChartWithGaps2", am4charts.XYChart);
    chart.paddingRight = 20;
    chart.data = [];
    let i = 0;
    for(let date = new Date(this.stayDateStart);date<=this.stayDateEnd;date.setDate(date.getDate()+1)){
      chart.data.push({date:new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,0)});
    }
    this.competitorGraphData.forEach(competitor => {
      let id = competitor.id;
      competitor.data.forEach(dateRatePair => {
        if(dateRatePair.Rate>0){
          chart.data.forEach(data => { 
            if(data.date.getDate() == dateRatePair.date.getDate()){
              data[competitor.id] = dateRatePair.Rate;
            }
          });
        }
    });
  });
  let that = this;
    chart.data.forEach(d => {
      let dataFound=false;
      if(bookingDataResponse){
        bookingDataResponse.forEach(bookingData => {
          if(bookingData["date"].getTime() == d["date"].getTime()){
            d["roomCount"]=bookingData["roomCount"];
            d["avgNetRate"]=bookingData["avgNetRate"];
            if(bookingData["bookingRate"].length > 0){
              if(that.maxBookingCountPerDay<bookingData["bookingRate"].length) that.maxBookingCountPerDay = bookingData["bookingRate"].length;
              let i = 0;
              for(let booking of bookingData["bookingRate"]){
                d["booking"+(i+1)]=booking.rate.toFixed(0);
                i++;
              }
            }
            dataFound=true;
          }
        });
      }
      if(dataFound==false) {
        d["roomCount"]=0;
        d["avgNetRate"]=0;
      }
    });
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();
    
    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.title.text = "Dates";
    dateAxis.tooltip.disabled = true;

    // Create value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Rate";
    valueAxis.tooltip.disabled = true;

    // var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis2.title.text = "Number Of Bookings";
    // valueAxis2.renderer.opposite = true;
    // valueAxis2.renderer.grid.template.disabled = true;
    // valueAxis2.min = 0;
    // valueAxis2.max = this.maxRoomCount + 2;
    // valueAxis2.maxPrecision=0; //only int values on y axis
    // valueAxis2.tooltip.disabled = true;

    this.competitorGraphData.forEach(competitor => {
    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = competitor.id;
    series.dataFields.dateX = "date";
    series.name = this.findHotelName(competitor.id);
    series.strokeWidth = 3;
    series.tensionX = 0.8;
    series.bullets.push(new am4charts.CircleBullet());
    series.tooltipText = `{valueY}`;
    series.connect = false;
    });

    // var series1 = chart.series.push(new am4charts.ColumnSeries());
    // series1.dataFields.valueY = "roomCount";
    // series1.dataFields.dateX = "date";
    // series1.yAxis = valueAxis2;
    // series1.name = "Room Nights";
    // series1.tooltipText = "{name} : {valueY}[/]\nAvg. Net Rate : {avgNetRate}";
    // series1.fill = chart.colors.getIndex(0);
    // series1.fillOpacity = 0.5;
    // series1.strokeWidth = 0;
    // series1.clustered = false;
    // series1.opacity = 0.5;
    // series1.columns.template.width = am4core.percent(40);

    // Scattered series
    for(let i=0;i<this.maxBookingCountPerDay;i++){
      var lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = "booking"+(i+1);
      lineSeries.dataFields.dateX = "date";
      lineSeries.strokeOpacity = 0;
      lineSeries.tooltipText = "Rate : {valueY}"; 
      lineSeries.hiddenInLegend = true;
      // Add a bullet
      var bullet = lineSeries.bullets.push(new am4charts.Bullet());

      // Add a triangle to act as am arrow
      var arrow = bullet.createChild(am4core.Trapezoid);
      arrow.horizontalCenter = "middle";
      arrow.verticalCenter = "middle";
      arrow.strokeWidth = 0;
      arrow.fill = chart.colors.getIndex(0);
      // arrow.direction = "top";
      arrow.width = 12;
      arrow.height = 12;
    }

    // Create a cursor 
    chart.cursor = new am4charts.XYCursor();
    chart.legend = new am4charts.Legend();
  } 
*/