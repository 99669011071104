import { Component, OnInit, HostListener, ViewChild, ComponentFactoryResolver, ViewContainerRef, ApplicationRef, Injector, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { GridOptions, ColDef, MenuItemDef, RowDoubleClickedEvent } from 'ag-grid';
import { StorageService } from '../ui-services/storage.service';
import { DomainContext, HotelContext, PromotionStatus, hotel } from 'mantras-api';
import { PromotionService } from '../ui-services/promotion.service';
import { Utilities } from '../ui-services/utilities';
import { UserService } from '../ui-services/user.service';
import { Subscription } from 'rxjs';
import { PromotionCriteria, PromotionLogCriteria, Promotion } from 'mantras-api';
import { PromotionDetailsComponent } from './promotiondetails.component';
import { MenuItem, OverlayPanel, SelectItem } from 'primeng/primeng';
import { TaskbarService } from '../ui-services/taskbar.service';
import { DatexPipe } from '../components/transformer/datexpipe.transformer';
import { LoadingService } from '../ui-services/loading.service';

import * as jQuery from 'jquery';
import { PromotionViewType } from '../model/model';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit {
  @Input() hotelContext?: HotelContext;
  @Input() domainContext?: DomainContext;

  isReadOnly = false;
  promotionsMenuItems: MenuItem[] = [
    { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
    {
      title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o',
      items: [{ label: 'Other', icon: 'pi pi-fw pi-pencil', command: (event) => this.openNew('MAX') },
      { label: 'Expedia', icon: 'pi pi-fw pi-pencil', command: (event) => this.openNew('EXP') }]
    },
    // { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
    { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', disabled: true, command: (event) => this.openPromotion(event) },
    { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: true, command: (event) => this.confirmDelete(event, true) },
    { title: 'Sync', label: 'Sync', icon: 'action-bar-menu-icon fa icon ion-md-sync', disabled: this.isReadOnly, command: (event) => this.showSyncDialog(event) }
  ];
  status: SelectItem[] = [{ label: 'All', value: 'all' }, { label: 'Active', value: PromotionStatus.Active }, 
    { label: 'Inactive', value: PromotionStatus.Inactive }, 
    { label: 'Expired', value: PromotionStatus.Expired }
  ];
  selectedStatus = PromotionStatus.Active;
  promotionStatus = PromotionStatus.Active;
  //promotionStatusFilter;
  promotionViews = [{ label: 'Channel View', value: PromotionViewType.ChannelView }, { label: 'Promotion View', value: PromotionViewType.PromotionView }];
  selectedView = { label: 'Channel View', value: PromotionViewType.ChannelView };

  gridOptions: GridOptions;
  // See: https://www.ag-grid.com/angular-data-grid/custom-icons/#set-the-icons-through-gridoptions-javascript
  gridIcons: { [key: string]: string } = {
    groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
    groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
  };

  showDetailPage = false;
  subscription: Subscription;
  channelMasterRoomMapping;
  channelMasterRateTypeMapping;
  taskCompleteNotificationSubscription: Subscription;
  backgroundRefresh = false;
  // promotionFor;
  // selectedPromotion = new Promotion();
  selectedPromoChannelCode: string;

  @ViewChild('criteriaDiv') criteriaDiv;
  constructor(private resolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector,
    public storageService: StorageService, private promotionService: PromotionService, 
    private taskbarService: TaskbarService, private userService: UserService) {
    // this.promotionFor = [{ label: 'Other', value: 'MAX' }, { label: 'Expedia', value: 'EXP' }];
    this.gridOptions = <GridOptions>{
      enableSorting: true,
      animateRows: true,
      groupDefaultExpanded: -1,
      rowHeight: 24,
      groupUseEntireRow: true,
      singleClickEdit: true,
      groupRowRendererParams: { suppressCount: true },
      suppressDragLeaveHidesColumns: true,
      localeText: { noRowsToShow: "No promotions created. Please click on New to create new promotions" }
    };
    //Highlight first group row 
    this.gridOptions.getRowClass = function (params) {
      if (params.node.group && params.node.level == 0) {
        return 'group-background';
      }
    }
    this.gridOptions.suppressLoadingOverlay = true;
    //Set the current class context (this) in gridOption context
    this.gridOptions.context = { thisComponent: this };
    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        this.selectedStatus = PromotionStatus.Active;
        this.promotionStatus = PromotionStatus.Active;
        this.showDetailPage = false;
        this.isReadOnly = this.userService.isReadOnly();
        // this.loadMenu();
        this.getPromotions();

      });

    this.taskCompleteNotificationSubscription = taskbarService.taskCompleteListener$.subscribe(
      tasks => {
        let taskStatus = this.taskbarService.getTaskStatus(tasks, TaskbarService.Promotion);
        this.backgroundRefresh = this.showDetailPage;
        this.getPromotions();
      }
    );
    this.isReadOnly = userService.isReadOnly();
  }

  ngOnInit() {
    //Initializing Menu Items 
    // this.loadMenu();
    if(!this.hotelContext){
      this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    }
    if (!this.domainContext){
      this.domainContext = this.storageService.get(StorageService.userDomainContext);
    }
    
    this.getPromotions();
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.taskCompleteNotificationSubscription.unsubscribe();
  }

  loadMenu() {
    this.promotionsMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      {
        label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o',
        items: [{ label: 'Other', icon: 'pi pi-fw pi-pencil', command: (event) => this.openNew('MAX') },
          { label: 'Expedia', icon: 'pi pi-fw pi-pencil', command: (event) => this.openNew('EXP') }
        ]
      },
      // { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', disabled: true, command: (event) => this.openPromotion(event) },
      { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: true, command: (event) => this.confirmDelete(event, true) },
      { title: 'Sync', label: 'Sync', icon: 'action-bar-menu-icon fa icon ion-md-sync', disabled: this.isReadOnly, command: (event) => this.showSyncDialog(event) }
    ];
  }

  onPromotionViewChange() {
    // 1. Set ColDef

    let channelCodeGroup: boolean = true;
    let typeGroup: boolean = false;
    let view = this.selectedView.value;
    switch (view) {
      case PromotionViewType.ChannelView:
        channelCodeGroup = true;
        typeGroup = false;
        break;
      case PromotionViewType.PromotionView:
        channelCodeGroup = false;
        typeGroup = true;
        break;
    }
    let idx = this.columnDefs.findIndex(x => x.field === 'ChannelCode');
    if (idx !== -1) {  this.columnDefs[idx].rowGroup = channelCodeGroup; }
    idx = this.columnDefs.findIndex(x => x.field === 'Type');
    if (idx !== -1) {  this.columnDefs[idx].rowGroup = typeGroup; }

    this.gridOptions.api.setColumnDefs(this.columnDefs);

    this.renderPromotion();
  }

  //Grid Context Menu
  getContextMenuItems(params) : MenuItemDef[] {
    console.log("Inside ContextMenu", params);
    params.node.setSelected(true);
    params.context.thisComponent.onRowClick(params.node)
    let rowData = params.node.data;
    let result : MenuItemDef[] = [
      {
        name: "History",
        icon: '<span class="fa fa-history" />',
        action: () => { params.context.thisComponent.showPromotionHistory(params); },
      },
      {
        name: "Delete Promotion",
        icon: '<span class="fa fa-remove" />',
        disabled: (rowData.ChannelCode !== "MAX" && !params.context.thisComponent.isReadOnly), 
        action: () => { params.context.thisComponent.confirmDelete(params); params.api.redrawRows({}); },
      },
      {
        name: "Disable Promotion",
        icon: '<span class="fa fa-ban" />',
        action: () => { params.context.thisComponent.disablePromotion(params); params.api.redrawRows({}); },
      }
    ];
    return result;
  }

  promotionResponse;
  masterPromotions;
  promotions;
  filteredResponse;
  async getPromotions() {
    // 1. Fetch Items
    let promotionCriteria : PromotionCriteria = this.buildPromotionCriteria(this.promotionStatus, true);
    let finalResponse = Array<Promotion>();
    finalResponse = await this.promotionService.findPromotions(promotionCriteria, this.backgroundRefresh);
    this.backgroundRefresh = false;
    this.promotionResponse = finalResponse;
    this.filteredResponse = finalResponse;

    // this.setupPromotionFilters();
    // 2. Render Grid
    this.renderPromotion();
  }

  filterResponse(filterData) {
    let filteredResponse = [];
    let filterChannels = filterData["channelTypes"];
    let filterTypes = filterData["promotionTypes"];
    let filterStatus = filterData["promotionStatus"];
    let filterRooms = filterData["rooms"];
    let filterRatePlans = filterData["ratePlans"];

    if (filterChannels.length > 0 && filterTypes.length > 0 && filterRooms.length > 0 && filterRatePlans.length > 0) {
      for (let idx = 0; idx < this.promotionResponse.length; idx++) {
        let promo = this.promotionResponse[idx];
        if (filterChannels.indexOf(promo.ChannelCode) < 0) continue;
        if (filterTypes.indexOf(promo.Type) < 0) continue;
        if (filterStatus.indexOf(promo.Status) < 0) continue;
        let found = false;
        for (let x = 0; x < promo.RoomTypes.length; x++) {
          if (promo.ChannelCode == "MAX" && filterRooms.indexOf(promo.RoomTypes[x]) >= 0) {
            found = true; break;
          } else if (promo.ChannelCode != "MAX") {
            let masterRoom = this.channelMasterRoomMapping.get(promo.ChannelCode + "::" + promo.RoomTypes[x])
            if (masterRoom && filterRooms.indexOf(masterRoom.Id) >= 0) {
              found = true; break;
            }
          }
        }
        if (!found) continue;
        found = false;
        for (let x = 0; x < promo.RatePlans.length; x++) {
          if (promo.ChannelCode == "MAX" && filterRatePlans.indexOf(promo.RatePlans[x]) >= 0) {
            found = true; break;
          } else if (promo.ChannelCode != "MAX") {
            let masterRatePlan = this.channelMasterRateTypeMapping.get(promo.ChannelCode + "::" + promo.RatePlans[x])
            if (masterRatePlan && filterRatePlans.indexOf(masterRatePlan.Id) >= 0) {
              found = true; break;
            }
          }
        }
        if (!found) continue;
        filteredResponse.push(promo);
      }
    }
    this.filteredResponse = filteredResponse;
  }

  renderPromotion() {
    // this.populateCloumnDef();
    this.gridOptions.api.setColumnDefs(this.columnDefs);
    this.gridOptions.api.setRowData(this.filteredResponse);
    this.gridOptions.api.sizeColumnsToFit();
    let setSelected = false;
    this.gridOptions.api.forEachNode(node => {
      if (!node.group && !setSelected) { node.setSelected(true); this.onRowClick(node); setSelected = true; }
    });
    if (!setSelected) this.loadMenu();
  }

  buildPromotionCriteria(promotionStatus?: PromotionStatus, isLocal?: boolean, channelCode?: string): PromotionCriteria {
    let promotionCriteria = new PromotionCriteria();
    let hotelContext = this.storageService.get(StorageService.userHotelContext);
    promotionCriteria.HotelId = hotelContext.HotelId;
    promotionCriteria.DomainId = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    promotionCriteria.Islocal = (isLocal == null) ? true : isLocal ;
    if (channelCode) promotionCriteria.ChannelCode = channelCode;
    promotionCriteria.Status = (promotionStatus == null) ? PromotionStatus.Active : promotionStatus;
    // if (promotionStatus != 'all') 
    //   promotionCriteria.Status = parseInt(promotionStatus);
    return promotionCriteria;
  }

  columnDefs: ColDef[] = [
    { headerName: "", width: 50, cellClass: 'gridcol', showRowGroup: true, menuTabs: [] },
    { headerName: "Id", cellClass: 'gridcol', field: "MasterPromotionId", hide: true, suppressToolPanel: true },
    { headerName: "Channel", cellClass: 'gridcol', rowGroup: (this.selectedView.value == PromotionViewType.ChannelView), field: "ChannelCode", valueFormatter: this.getChannelCode, menuTabs: [] },
    { headerName: "Type", cellClass: 'gridcol', rowGroup: (this.selectedView.value == PromotionViewType.PromotionView), field: "Type", valueFormatter: getPromotionType, menuTabs: [] },
    { headerName: "Description", cellClass: 'gridcol', field: "Description", menuTabs: [] },
    { headerName: "Status", width: 100, cellClass: 'gridcol', field: "Status", valueFormatter: getPromotionStatus, menuTabs: [] },
    { headerName: "Room Type", cellClass: 'gridcol', field: "RoomTypes", valueFormatter: getRoomType, menuTabs: [] },
    { headerName: "Rate Plan", cellClass: 'gridcol', field: "RatePlans", valueFormatter: getRatePlanType, menuTabs: [] },
    { headerName: "Stay From", width: 125, cellClass: 'gridcol', field: "StayDates.Start", valueFormatter: formatDate, menuTabs: [] },
    { headerName: "Stay Till", width: 125, cellClass: 'gridcol', field: "StayDates.End", valueFormatter: formatDate, menuTabs: [] },
    { headerName: "Discount", width: 75, cellClass: 'gridcol rightalign', field: "Discount", valueFormatter: formatDiscount, menuTabs: [] },
    { headerName: "", width: 30, cellClass: 'gridcol', menuTabs: [] },
  ];

//#region OBSOLETE CODE 
  // Obsolete
  async getPromotions_old() {
    // Building Promotion Criteria
    //Default
    let promotionCriteria : PromotionCriteria = this.buildPromotionCriteria(this.promotionStatus, true, "MAX");
    let finalResponse = Array<Promotion>();
    finalResponse = await this.promotionService.findPromotions(promotionCriteria, this.backgroundRefresh).then(
      localresp => {
        finalResponse = finalResponse.concat(localresp);
        if (this.selectedPromoChannelCode != 'EXP')
          this.masterPromotions = localresp;
        if (this.selectedPromoChannelCode == 'EXP')
          this.promotions = localresp;
        return finalResponse;
      });
    promotionCriteria = this.buildPromotionCriteria(this.promotionStatus, true);
    finalResponse = await this.promotionService.findPromotions(promotionCriteria, this.backgroundRefresh)
      .then(localresp => { 
        finalResponse = finalResponse.concat(localresp); 
        return finalResponse
      }
    );

    this.backgroundRefresh = false;
    this.promotionResponse = finalResponse;
    this.filteredResponse = finalResponse;
    // this.setupPromotionFilters();
    this.renderPromotion();
  }
  populateCloumnDef2() {
    this.columnDefs = [];
    this.columnDefs.push({ headerName: "", cellClass: 'gridcol', showRowGroup: true, width: 50, menuTabs: [] });
    this.columnDefs.push({ headerName: "Channel", cellClass: 'gridcol', rowGroup: (this.selectedView.value == PromotionViewType.ChannelView), field: "ChannelCode", valueFormatter: this.getChannelCode, menuTabs: [] });
    this.columnDefs.push({ headerName: "Type", cellClass: 'gridcol', rowGroup: (this.selectedView.value == PromotionViewType.PromotionView), field: "Type", valueFormatter: getPromotionType, menuTabs: [] });
    this.columnDefs.push({ headerName: "Description", cellClass: 'gridcol', field: "Description", menuTabs: [] });
    this.columnDefs.push({ headerName: "Status", width: 100, cellClass: 'gridcol', field: "Status", valueFormatter: getPromotionStatus, menuTabs: [] });
    this.columnDefs.push({ headerName: "Room Type", cellClass: 'gridcol', field: "RoomTypes", valueFormatter: getRoomType, menuTabs: [] });
    this.columnDefs.push({ headerName: "Rate Plan", cellClass: 'gridcol', field: "RatePlans", valueFormatter: getRatePlanType, menuTabs: [] });
    this.columnDefs.push({ headerName: "Stay From", width: 125, cellClass: 'gridcol', field: "StayDates.Start", valueFormatter: formatDate, menuTabs: [] });
    this.columnDefs.push({ headerName: "Stay Till", width: 125, cellClass: 'gridcol', field: "StayDates.End", valueFormatter: formatDate, menuTabs: [] });
    this.columnDefs.push({ headerName: "Discount", width: 75, cellClass: 'gridcol rightalign', field: "Discount", valueFormatter: formatDiscount, menuTabs: [] });
    this.columnDefs.push({ headerName: "", width: 30, cellClass: 'gridcol', menuTabs: [] });
  }

  @ViewChild('promotionFilter') promotionFilter;
  setupPromotionFilters() {
    if (this.promotionResponse && this.promotionResponse.length) {
      let promoFilterStatus = [];
      let promoFilterTypes = [];
      let promoFilterChannelTypes = [];
      let promoFilterRooms = [];
      let promoFilterRatePlans = [];
      this.channelMasterRoomMapping = new Map();
      this.channelMasterRateTypeMapping = new Map();
      this.promotionResponse.forEach(promo => {
        if (promoFilterStatus.indexOf(promo.Status) < 0) promoFilterStatus.push(promo.Status);
        if (promoFilterTypes.indexOf(promo.Type) < 0) promoFilterTypes.push(promo.Type);
        if (promoFilterChannelTypes.indexOf(promo.ChannelCode) < 0) promoFilterChannelTypes.push(promo.ChannelCode);
        if (promo.ChannelCode == "MAX") {
          promo.RoomTypes.forEach(room => {
            if (promoFilterRooms.indexOf(room) < 0) promoFilterRooms.push(room);
          });
          promo.RatePlans.forEach(ratePlan => {
            if (promoFilterRatePlans.indexOf(ratePlan) < 0) promoFilterRatePlans.push(ratePlan);
          });
        } else {
          if (promo.RoomTypes && promo.RoomTypes.length > 0) {
            promo.RoomTypes.forEach(room => {
              if (!this.channelMasterRoomMapping.has(promo.ChannelCode + "::" + room)) {
                let masterRoom = this.getChannelRoom(room, promo.ChannelCode);
                this.channelMasterRoomMapping.set(promo.ChannelCode + "::" + room, masterRoom);
                if (masterRoom && promoFilterRooms.indexOf(masterRoom.Id) < 0) promoFilterRooms.push(masterRoom.Id);
              }
            });
          }
          if (promo.RatePlans && promo.RatePlans.length > 0) {
            promo.RatePlans.forEach(ratePlan => {
              if (!this.channelMasterRateTypeMapping.has(promo.ChannelCode + "::" + ratePlan)) {
                let masterRatePlan = this.getChannelRatePlan(ratePlan, promo.ChannelCode);
                this.channelMasterRateTypeMapping.set(promo.ChannelCode + "::" + ratePlan, masterRatePlan);
                if (masterRatePlan && promoFilterRatePlans.indexOf(masterRatePlan.Id) < 0) promoFilterRatePlans.push(masterRatePlan.Id);
              }
            });
          }
        }
      });
      this.promotionFilter.setupPromotionFilter({
        channelTypes: promoFilterChannelTypes, promotionTypes: promoFilterTypes,
        promotionStatus: promoFilterStatus, rooms: promoFilterRooms, ratePlans: promoFilterRatePlans
      });
      this.isFilterEnabled = true;
    } else {
      this.isFilterEnabled = false;
    }
  }
//#endregion OBSOLETE CODE

//#region EVENTS
  onPromotionFilterChange(filterData) {
    this.filterResponse(filterData);
    this.renderPromotion();
  }

  onSearch(event) {
    this.promotionStatus = this.selectedStatus;
    this.onRefresh(event);
  }

  onGridSizeChanged(event) {
    this.gridOptions.api.sizeColumnsToFit();
  }
  //
  onGridReady(event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.setHeight();
  }

//#endregion EVENTS

  ngAfterViewInit(): void {
    this.setHeight();
  }
  criteriaDivHeight = 40;
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    if (this.criteriaDiv.nativeElement.firstElementChild.offsetHeight != 0)
      this.criteriaDivHeight = this.criteriaDiv.nativeElement.firstElementChild.offsetHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true, false, false, false, (this.showCriteria ? this.criteriaDivHeight : 0)));
  }

  @ViewChild('containerPromoDetail', { read: ViewContainerRef }) containerPromotionDetail: ViewContainerRef;
  promotionDetailsComp;
  onClickGridRow(params: RowDoubleClickedEvent) {
    if (params.data) {
      this.selectedPromoChannelCode = params.data.ChannelCode;
      this.createNew(params.data);
    }
  }

  onRowClick(params) {
    if (params.data) {
      if (params.data.ChannelCode == "MAX") {
        this.promotionsMenuItems = [
          { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
          {
            label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o',
            items: [{ label: 'Other', icon: 'pi pi-fw pi-pencil', command: (event) => this.openNew('MAX') },
            { label: 'Expedia', icon: 'pi pi-fw pi-pencil', command: (event) => this.openNew('EXP') }]
          },
          // { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
          { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.openPromotion(event) },
          { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: this.isReadOnly, command: (event) => this.confirmDelete(event, true) },
          { title: 'Sync', label: 'Sync', icon: 'action-bar-menu-icon fa icon ion-md-sync', disabled: this.isReadOnly, command: (event) => this.showSyncDialog(event) }
        ];
      } else {
        this.promotionsMenuItems = [
          { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
          {
            label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o',
            items: [{ label: 'Other', icon: 'pi pi-fw pi-pencil', command: (event) => this.openNew('MAX') },
            { label: 'Expedia', icon: 'pi pi-fw pi-pencil', command: (event) => this.openNew('EXP') }]
          },
          // { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
          { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.openPromotion(event) },
          { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', command: (event) => this.confirmDelete(event, true), disabled: true },
          { title: 'Sync', label: 'Sync', icon: 'action-bar-menu-icon fa icon ion-md-sync', disabled: this.isReadOnly, command: (event) => this.showSyncDialog(event) }
        ];
      }
    }
  }

  //Refresh Promotion Grid
  onRefresh(event) {
    this.getPromotions();
  }
  //Create New Promotion
  createNew(data?: Promotion) {
    this.containerPromotionDetail.clear();
    let factory = this.resolver.resolveComponentFactory(PromotionDetailsComponent);
    this.promotionDetailsComp = <PromotionDetailsComponent>this.containerPromotionDetail.createComponent(factory).instance;
    if (data) this.promotionDetailsComp.promotion = data;
    else this.promotionDetailsComp.promotion = new Promotion();
    this.promotionDetailsComp.loadData(this.selectedPromoChannelCode, this.isNew);
    this.promotionDetailsComp.onClose.subscribe(this.onPromotionDetailsClose);
    this.showDetailPage = true;
    this.promotionDetailsComp.setHeight();
  }

  //Open Promotion
  promotionDetail : Promotion;
  onRowDoubleClicked(params: RowDoubleClickedEvent) {
    if (params.data) {
      this.promotionDetail = params.data;
      this.showDetailPage = true;
    }
  }

  openPromotion(event) {
    this.selectedPromoChannelCode = this.gridOptions.api.getSelectedRows()[0].ChannelCode;
    this.isNew = false;
    this.createNew(this.gridOptions.api.getSelectedRows()[0]);
  }

  //Open New Promohion Page
  isNew = false;
  openNew(selectedPromo) {
    this.selectedPromoChannelCode = selectedPromo;
    this.isNew = true;
    this.createNew();
  }

  onClosePromotionDetails(value: boolean){
    this.showDetailPage = false;
  }

  // onSelecedPromotionChannel(event){

  // }

  //Delete Promotion
  @ViewChild('showConfirmationDialog') showConfirmationDialog;
  showDeleteConfirmation = false;
  deletePromotionId;
  confirmDelete(params, fromGrid?) {
    if (!fromGrid) {
      if (params.node.data) {
        this.deletePromotionId = params.node.data.MasterPromotionId;
        this.showDeleteConfirmation = true;
      }
    } else {
      let selectedRow = this.gridOptions.api.getSelectedRows();
      if (selectedRow && selectedRow.length > 0) {
        this.deletePromotionId = this.gridOptions.api.getSelectedRows()[0].MasterPromotionId;
        this.showDeleteConfirmation = true;
      }
    }
  }

  closeDialog(event) {
    this.deletePromotionId = null;
    this.showDeleteConfirmation = false;
    this.setHeight();
  }

  async deletePromotion(event) {
    this.showDeleteConfirmation = false;
    if (this.deletePromotionId != null) {
      let finalResponse = await this.promotionService.deletePromotion(this.hotelContext.HotelId, this.deletePromotionId).then(
        localresp => { return localresp; });
    }
    this.onRefresh(event);
  }
  //#region Show Sync
  showSyncPromotionDialog = false;
  syncModeOption = "get"
  selectedSyncChannels = [];
  syncChannelTypes = [];
  showSyncDialog(event) {
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    let channelTypes = hotelContext.ChannelCodes;
    this.syncModeOption = "get";
    this.syncChannelTypes = [];
    channelTypes.forEach(element => {
      if (PromotionService.supportedChannelTypes.indexOf(element) >= 0)
        this.syncChannelTypes.push({ value: element, label: Utilities.findId(Utilities.channelTypes, element, element) });
    });
    this.syncChannelTypes.sort(Utilities.sort_label_asc);
    this.selectedSyncChannels = [];
    this.showSyncPromotionDialog = true;
  }

  async onSync(event) {
    if (this.selectedPromoChannelCode != "EXP") {
      let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
      if (this.syncModeOption == "get") {
        this.promotionService.getPromotion(hotelContext.HotelId, this.selectedSyncChannels)
      } else {
        if (this.selectedPromoChannelCode != "EXP")
          this.promotionService.putPromotion(hotelContext.HotelId, this.masterPromotions, this.selectedSyncChannels)
        else
          this.promotionService.putPromotion(hotelContext.HotelId, this.promotions, this.selectedSyncChannels)
      }
      this.showSyncPromotionDialog = false;
    }
  }
  //#endregion Show Sync


  //#region ContextMenu Events

  //#region Promotion History
  showPromotionHistoryDialog = false;
  promoLogsOptions = <GridOptions>{
    enableSorting: true,
    animateRows: true,
    groupDefaultExpanded: -1,
    rowHeight: 24,
    suppressDragLeaveHidesColumns: true,
    localeText: { noRowsToShow: "History not found" },
    suppressContextMenu: true,
  };

  async showPromotionHistory(params) {
    let cellData = params.node.data;
    let promotionLogCriteria = new PromotionLogCriteria();
    promotionLogCriteria.DomainId = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    promotionLogCriteria.HotelId = this.storageService.get(StorageService.currentUser).HotelContext.HotelId;
    promotionLogCriteria.ChannelCode = cellData.ChannelCode;
    promotionLogCriteria.Id = cellData.Id;
    this.promoLogsOptions.api.setColumnDefs(this.getPromotionLogsColumnDef());
    this.promoLogsOptions.api.setRowData(await this.promotionService.findPromotionHistory(promotionLogCriteria));
    this.onPromoLogsGridReady(null);
    this.showPromotionHistoryDialog = true;
  }

  getPromotionLogsColumnDef() {
    let promoColDef = new Array();
    promoColDef.push({
      headerName: "Date", width: 400, cellClass: 'gridcol', field: "Timestamp", sort: "desc", menuTabs: [], valueFormatter: function (data) {
        if (data.value) return new DatexPipe().transform(data.value, "DD-MMM-YY hh:mm:ss a");
      }
    });
    promoColDef.push({ headerName: "User", cellClass: 'gridcol', width: 375, tooltipField: "UserId", field: "UserId", menuTabs: [] });
    promoColDef.push({ headerName: "Action", cellClass: 'gridcol', field: "Action", valueFormatter: getPromotionAction, menuTabs: [] });
    promoColDef.push({ headerName: "Channel", cellClass: 'gridcol', field: "ChannelCode", valueFormatter: this.getChannelCode, menuTabs: [] });
    promoColDef.push({ headerName: "Type", cellClass: 'gridcol', width: 300, field: "Type", valueFormatter: getPromotionType, menuTabs: [] });
    promoColDef.push({ headerName: "Name", cellClass: 'gridcol', width: 500, tooltipField: "Description", field: "Description", menuTabs: [] });
    promoColDef.push({ headerName: "Status", cellClass: 'gridcol', field: "Status", valueFormatter: getPromotionStatus, menuTabs: [] });
    promoColDef.push({ headerName: "Stay From", cellClass: 'gridcol', width: 250, field: "StayDates.Start", valueFormatter: formatDate, menuTabs: [] });
    promoColDef.push({ headerName: "Stay Till", cellClass: 'gridcol', width: 250, field: "StayDates.End", valueFormatter: formatDate, menuTabs: [] });
    promoColDef.push({ headerName: "Discount", cellClass: 'gridcol rightalign', field: "Discount", valueFormatter: formatDiscount, menuTabs: [] });

    return promoColDef;
  }

  onPromoLogsGridReady(event) {
    this.promoLogsOptions.api.sizeColumnsToFit();
  }

  onPromoLogsGridSizeChanged(evnet) {
    this.promoLogsOptions.api.sizeColumnsToFit();
  }
  //#endregion Promotion History

  async disablePromotion(params) {
    if (params.node.data) {
      let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
      let finalResponse = await this.promotionService.disablePromotion(hotelContext.HotelId, params.node.data).then(
        localresp => { return localresp; });
    }
  }
  //#endregion ContextMenu Events

  onPromotionDetailsClose = (event): void => {
    this.selectedPromoChannelCode = '';
    this.showDetailPage = false;
    this.onRefresh(event);
    this.setHeight();
  }

  getChannelRoom(roomId, channelType) {
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    if (channelType == "MAX") {
      return Utilities.findObject(hotelContext.RoomTypes, roomId);
    } else {
      return Utilities.findMasterRoomType(roomId, channelType, hotelContext.RoomTypes);
    }
  }

  getChannelRatePlan(ratePlanId, channelType) {
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    if (channelType == "MAX") {
      return Utilities.findObject(hotelContext.RatePlans, ratePlanId);
    } else {
      return Utilities.findMasterRateType(ratePlanId, channelType, hotelContext.RatePlans);
    }
  }

  showCriteria = false;
  hideShowCriteria() {
    this.showCriteria = !this.showCriteria;
    this.setHeight();
  }

  isFilterEnabled = false;
  showFilter = false;
  hideShowFilterCriteria() {
    if (this.isFilterEnabled)
      this.showFilter = !this.showFilter;
  }

  getChannelCode(params) {
    return Utilities.findId(Utilities.channelTypes, params.value);
  }
  
}


function getPromotionType(params) {
  if (params.value || params.value == 0) {
    return PromotionService.promotionTypes[parseInt(params.value)];
  }
}

function getPromotionStatus(params) {
  if (params.value || params.value == 0)
    return PromotionService.promotionStatus[parseInt(params.value)];
}

function formatDate(params) {
  if (params.value) {
    return new DatePipe('en-US').transform(Utilities.getUTCDate(new Date(params.value)), "dd-MMM-yyyy");
  }
}

function formatDiscount(params) {
  if (params.value) {
    return parseFloat(params.value).toFixed(2) + "%";
  }
}

function getRoomType(params) {
  if (params.value) {
    if (params.value.length > 1) return "Multiple";
    else {
      if (params.context.thisComponent.channelMasterRoomMapping.has(params.data.ChannelCode + "::" + params.value[0])) {
        let masterRoom = params.context.thisComponent.channelMasterRoomMapping.get(params.data.ChannelCode + "::" + params.value[0]);
        if (masterRoom) return masterRoom.Name;
        else return params.value[0];
      } else {
        let roomTypeObj = params.context.thisComponent.getChannelRoom(params.value[0], params.data.ChannelCode);
        if (roomTypeObj) return roomTypeObj.Name;
        else return params.value[0];
      }
    }
  }
}

function getRatePlanType(params) {
  if (params.value) {
    if (params.value.length > 1) return "Multiple";
    else {
      if (params.context.thisComponent.channelMasterRateTypeMapping.has(params.data.ChannelCode + "::" + params.value[0])) {
        let masterRatePlan = params.context.thisComponent.channelMasterRateTypeMapping.get(params.data.ChannelCode + "::" + params.value[0]);
        if (masterRatePlan) return masterRatePlan.Name;
        else return params.value[0];
      } else {
        let ratePlanObj = params.context.thisComponent.getChannelRatePlan(params.value[0], params.data.ChannelCode);
        if (ratePlanObj) return ratePlanObj.Name;
        else return params.value[0];
      }
    }
  }
}

function getPromotionAction(params) {
  if (params.value || params.value == 0)
    return PromotionService.promotionActions[parseInt(params.value)];
}
