import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MenuItem, ContextMenu } from 'primeng/primeng';

@Component({
  selector: 'mm-checkboxgroup',
  templateUrl: './checkboxgroup.component.html',
  styleUrls: ['./checkboxgroup.component.css']
})
export class CheckboxgroupComponent implements OnInit {

  @Input('columns') columns = 1;
  @Input('options') options;
  @Input('value') value;
  @Input('styleClass') styleClass;
  @Input('style') style;
  @Input('label') label;
  @Input('disabled') disabled = false;
  @Output('valueChange') onChange : EventEmitter<any>;
  menuItem: MenuItem[];
  constructor() {
    this.onChange = new EventEmitter();
  }

  @Input()
  get getValue(){
    return this.value;
  }
  set setValue(value){
    this.value = value;
  }

  ngOnInit() {
    this.menuItem = [{label:"Unselect All But This",command:(event) => {this.unSelectAllButThis(event);}}];
    if((this.columns < 1 || this.columns > 6 || this.columns == 5) && this.columns != 12)
      this.columns = 1;
  }

  unSelectAllButThis(event){
    if(this.contextMenuValue){
      this.value = [this.contextMenuValue];
      this.emitEvent(event);
    }  
  }

  selectAll(flag:boolean){
    console.log("Select All/Clear all", flag);
    let selectedValues = [];
    if(flag){
      this.options.forEach(element => {
        selectedValues.push(element.value);
      });
    }
    this.value = selectedValues;
    this.onChange.emit(this.value);
    return false;
  }

  emitEvent(event){
    this.onChange.emit(this.value);
  }

  @ViewChild('contextMenu') contextMenu;
  contextMenuValue = null;
  showContextMenu(event,value?:string){
    this.contextMenuValue = value;
    this.contextMenu.show(event);
  }
}
