import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getTranslationProviders } from './app/i18n-providers';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// for enterprise customers
//import {LicenseManager} from "ag-grid-enterprise/main";
//LicenseManager.setLicenseKey("Your License Key");
//LicenseManager.setLicenseKey("FlexIT_2Devs_Expires_10-Jun-2018_MTUyODU4NTIwMDAwMA==89aad911f67c7b94f7a599b678485e45");
//"FlexIT_2Devs_Expires_10-Jun-2018_MTUyODU4NTIwMDAwMA==89aad911f67c7b94f7a599b678485e45"
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

//"FlexIT_2Devs_Expires_10-Jun-2018_MTUyODU4NTIwMDAwMA==89aad911f67c7b94f7a599b678485e45"
// getTranslationProviders().then(providers => {
//   const options = { providers };
//   platformBrowserDynamic().bootstrapModule(AppModule);
// });
