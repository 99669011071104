import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { AppService, TaxPolicy, CatalogOption } from "mantras-api";
import { LoadingService } from "./loading.service";
import { HotelCriteria, HotelInfo } from "mantras-api";
import { Utilities } from "./utilities";
import { DomainHotel, Channel, RatePlanDetail, RoomRatePlanDetail } from "mantras-api";
import { TaskbarService } from "./taskbar.service";
import { AppConfigService } from "../app-config.service";

@Injectable()
export class HotelManagementService {
    //mantras: AppService;
    constructor(public mantras:AppService,private appConfService:AppConfigService,private storageService: StorageService, private loadingService: LoadingService, private utilities: Utilities,
        private taskService: TaskbarService) {
        Utilities.initApp(mantras,appConfService);
    }

    async findHotels(hotelCriteria: HotelCriteria, forceLoad = false) {
        if (!forceLoad && hotelCriteria.Ids && hotelCriteria.Ids.length == 1) {
            let response = this.storageService.get("HOTEL_" + hotelCriteria.Ids[0]);
            if (response && response != null)
                return response;
        }
        return await this.loadingService.interceptor(this.mantras.FindHotels(hotelCriteria)
            .then(async result => {
                if (hotelCriteria.Ids && hotelCriteria.Ids.length == 1) {
                    this.storageService.set("HOTEL_" + hotelCriteria.Ids[0], result);
                }
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    async findRoomTypes(hotelId: string, forceLoad = false) {
        if (!forceLoad) {
            let response = this.storageService.get("ROOMTYPES_" + hotelId);
            if (response && response != null)
                return response;
        }
        return await this.loadingService.interceptor(this.mantras.getRoomTypeDetails(hotelId).then(res => {
            this.storageService.set("ROOMTYPES_" + hotelId, res);
            return res;
        }), true);
    }

    async findRatePlans(hotelId: string, forceLoad = false) {
        if (!forceLoad) {
            let response = this.storageService.get("RATEPLANS_" + hotelId);
            if (response && response != null)
                return response;
        }
        return await this.loadingService.interceptor(this.mantras.getRatePlanDetails(hotelId).then(res => {
            this.storageService.set("RATEPLANS_" + hotelId, res);
            return res;
        }), true);
    }

    async findRoomRatePlans(hotelId: string, forceLoad = false) {
        let roomRatePlans = this.storageService.get("ROOMRATEPLANS_" + hotelId);
        if ((!roomRatePlans && roomRatePlans == null) || forceLoad) {
            let response = await this.loadingService.interceptor(this.mantras.getRoomRatePlanDetails(hotelId).then(async response => {
                if (response != null) {
                    this.storageService.set("ROOMRATEPLANS_" + hotelId, response);
                    return response;
                }
            }), true);
            roomRatePlans = response;
        }
        return roomRatePlans;
    }

    async findTaxPolicies(domainId: string, hotelId: string, forceLoad = false) {
        if (!forceLoad) {
            let response = this.storageService.get("TAXPOLICIES_" + hotelId);
            if (response && response != null)
                return response;
        }
        return await this.loadingService.interceptor(this.mantras.getTaxPolicies(domainId, hotelId).then(res => {
            this.storageService.set("TAXPOLICIES_" + hotelId, res);
            return res;
        }), true);
    }

    async findHotelChannels(hotelId: string, forceLoad = false) {
        if (!forceLoad) {
            let response = this.storageService.get("HOTELCHANNELS_" + hotelId);
            if (response && response != null)
                return response;
        }
        return await this.loadingService.interceptor(this.mantras.getHotelChannels(hotelId).then(res => {
            this.storageService.set("HOTELCHANNELS_" + hotelId, res);
            return res;
        }), true);
    }
    async saveHotelChannel(hotelChannel, isNew = false, domainId = null) {
        return this.loadingService.interceptor(this.mantras.saveHotelChannel(hotelChannel)
            .then(async result => {
                this.storageService.remove("HOTELCHANNELS_" + hotelChannel.HotelId);
                if (isNew) {
                    this.storageService.remove("CHANNELHOTELINFO_" + hotelChannel.HotelId);
                }
                this.storageService.reloadHotelContextRequired = true;
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }));
    }
    saveRoomTypes(hotelId: string, roomTypeDetails, tabName: string) {
        return this.loadingService.interceptor(this.mantras.saveRoomTypeDetails(hotelId, roomTypeDetails)
            .then(async result => {
                this.storageService.remove("ROOMTYPES_" + hotelId);
                this.storageService.roomtypeContextChanged(tabName);
                this.storageService.reloadHotelContextRequired = true;
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    saveRatePlan(hotelId: string, ratePlans: RatePlanDetail[]) {
        return this.loadingService.interceptor(this.mantras.saveRatePlanDetails(hotelId, ratePlans)
            .then(async result => {
                this.storageService.remove("RATEPLANS_" + hotelId);
                this.storageService.reloadHotelContextRequired = true;
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }
    saveRoomRatePlan(hotelId: string, roomRatePlans: RoomRatePlanDetail[]) {
        return this.loadingService.interceptor(this.mantras.saveRoomRatePlanDetails(hotelId, roomRatePlans)
            .then(async result => {
                this.storageService.remove("ROOMRATEPLANS_" + hotelId);
                this.storageService.reloadHotelContextRequired = true;
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }
    saveTaxpolicies(domainId:string, hotelId:string,taxpolicies:TaxPolicy[]){
        return this.loadingService.interceptor(this.mantras.saveTaxPolicies(domainId,hotelId,taxpolicies)
            .then(async result => {
                this.storageService.remove("TAXPOLICIES_" + hotelId);
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }
    deleteRoomRatePlan(hotelId: string, roomId: string, rateplanId: string) {
        return this.loadingService.interceptor(this.mantras.deleteRoomRatePlanDetails(hotelId, roomId, rateplanId)
            .then(async result => {
                this.storageService.remove("ROOMRATEPLANS_" + hotelId);
                this.storageService.reloadHotelContextRequired = true;
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }));
    }
    saveHotel(hotelInfo: HotelInfo) {
        return this.loadingService.interceptor(this.mantras.SaveHotels([hotelInfo])
            .then(async result => {
                this.storageService.remove("HOTEL_" + hotelInfo.Id);
                this.storageService.reloadHotelContextRequired = true;
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    deleteHotel(hotelId) {
        return this.loadingService.interceptor(this.mantras.DeleteHotels(hotelId)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    saveDomainHotel(domainHotel: DomainHotel) {
        return this.loadingService.interceptor(this.mantras.SaveDomainHotel(domainHotel)
            .then(async result => {
                this.storageService.remove("DOMAIN_HOTEL_" + domainHotel.HotelId);
                this.storageService.reloadHotelContextRequired = true;
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    deleteDomainHotel(domainId: string, hotelId: string) {
        return this.loadingService.interceptor(this.mantras.DeleteDomainHotel(domainId, hotelId)
            .then(async result => {
                this.storageService.remove("DOMAIN_HOTEL_" + hotelId);
                this.storageService.reloadHotelContextRequired = true;
                return result;
            }).catch(error => {
                //this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    saveFile(criteria: HotelCriteria,options: CatalogOption) {
        this.mantras.ExportHotels(criteria,options).then(response => {
            Utilities.saveToFileSystem(response, "ExportHotelCatalog.xlsx");
        });
    }

    verifyDomainChannel(channel: Channel) {
        return this.loadingService.interceptor(this.mantras.verifyDomainChannel(channel)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    saveDomainChannel(channel: Channel) {
        return this.loadingService.interceptor(this.mantras.saveDomainChannel(channel)
            .then(async result => {
                this.storageService.remove("DOMAINCHANNELS_" + channel.DomainId);
                this.storageService.remove("CHANNELHOTELINFO_" + channel.DomainId);
                this.storageService.reloadHotelContextRequired = true;
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    deleteRoomTypes(hotelId, roomTypeId) {
        return this.mantras.deleteRoomTypeDetails(hotelId, roomTypeId).then(resp => {
            this.storageService.remove("ROOMTYPES_" + hotelId);
            this.storageService.reloadHotelContextRequired = true;
            return resp;
        });
    }

    deleteDomainChannel(channelId) {
        return this.mantras.deleteDomainChannel(channelId).then(resp => {
            let domainId = this.storageService.get(StorageService.userDomainContext).CurrentDomainId;
            this.storageService.remove("DOMAINCHANNELS_" + domainId);
            this.storageService.remove("CHANNELHOTELINFO_" + domainId);
            this.storageService.reloadHotelContextRequired = true;
            return resp;
        });
    }

    deleteRatePlan(hotelId, ratePlanId) {
        return this.mantras.deleteRatePlanDetails(hotelId, ratePlanId).then(resp => {
            this.storageService.remove("RATEPLANS_" + hotelId);
            this.storageService.reloadHotelContextRequired = true;
            return resp;
        });
    }

    deleteTaxPolicies(domainId, hotelId, policyIds) {
        return this.mantras.deleteTaxPolicies(domainId, hotelId, policyIds).then(resp => {
            this.storageService.remove("TAXPOLICIES_" + hotelId);
            return resp;
        });
    }
    deleteHotelChannel(hotelId, channelCode, domainId) {
        return this.mantras.deleteHotelChannel(hotelId, channelCode).then(resp => {
            this.storageService.remove("HOTELCHANNELS_" + hotelId);
            this.storageService.remove("CHANNELHOTELINFO_" + hotelId);
            this.storageService.remove("CHANNELHOTELINFO_" + domainId);
            this.storageService.reloadHotelContextRequired = true;
            return resp;
        });
    }

    async getDomainChannels(forceLoad = false) {
        let domainId = this.storageService.get(StorageService.userDomainContext).CurrentDomainId;

        let domainChannels = this.storageService.get("DOMAINCHANNELS_" + domainId);
        if ((!domainChannels && domainChannels == null) || forceLoad) {
            let response = await this.loadingService.interceptor(this.mantras.getDomainChannels().then(async response => {
                if (response != null) {
                    this.storageService.set("DOMAINCHANNELS_" + domainId, response);
                    return response;
                }
            }), true);
            domainChannels = response;
        }
        return domainChannels;
    }

    async getSystemChannels(forceLoad = false) {
        if (!forceLoad) {
            let response = this.storageService.get("SYSTEMCHANNELS");
            if (response && response != null)
                return response;
        }
        return await this.loadingService.interceptor(this.mantras.getSystemChannels()
            .then(async result => {
                if(result){
                    result = result.filter( function(item) {
                        return item.ChannelCode != 'AGD';
                    });
                    }
                this.storageService.set("SYSTEMCHANNELS", result);
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    syncHotelData(domainId: string, hotelId: string, channelCode: string) {
        return this.loadingService.interceptor(this.mantras.syncHotelData(domainId, hotelId, channelCode).then
            (response => {
                this.taskService.addTasks([response]);
                return response;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                return error;
            }));
    }
    async getChannelHotelInfo(channel: Channel, channelHotelId: string) {
        let taskIds: string[];
        taskIds = [];
        let result = await this.mantras.getChannelHotelInfo(channel, channelHotelId).then(result => {
            return result;
        }).catch(error => {
            this.loadingService.showErrorDialog(error);
            return null;
        });
        if (result) {
            taskIds.push(result);
        }
        this.taskService.addTasks(taskIds);
    }

    async findChannelHotelInfo(domainId: string, hotelId: string, forceLoad = false, isBackground = false) {
        let channelHotelInfo;
        if (hotelId && hotelId != null)
            channelHotelInfo = this.storageService.get("CHANNELHOTELINFO_" + hotelId);
        else channelHotelInfo = this.storageService.get("CHANNELHOTELINFO_" + domainId);
        if ((!channelHotelInfo && channelHotelInfo == null) || forceLoad) {
            if(!isBackground){
                let response = await this.loadingService.interceptor(this.mantras.findChannelHotelInfo(domainId, hotelId).then(response => {
                    if (response != null) {
                        if (hotelId && hotelId != null)
                            this.storageService.set("CHANNELHOTELINFO_" + hotelId, response);
                        else this.storageService.set("CHANNELHOTELINFO_" + domainId, response);
                        return response;
                    }
                }), true);
                channelHotelInfo = response;
            } else {
                channelHotelInfo = this.mantras.findChannelHotelInfo(domainId, hotelId).then(response => {
                    if (response != null) {
                        if (hotelId && hotelId != null)
                            this.storageService.set("CHANNELHOTELINFO_" + hotelId, response);
                        else this.storageService.set("CHANNELHOTELINFO_" + domainId, response);
                        return response;
                    }
                })
            }
        }
        return channelHotelInfo;
    }
    async lodInventory(hotelId:string,channelCodes:string[],startDate:Date,endDate:Date,manualOverride:boolean){
        return this.mantras.loadRateCalendar(hotelId,channelCodes,startDate,endDate,manualOverride);
    }
}