import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from 'mantras-api';
import { HotelBooking, BookingCriteria, BookingStatus } from 'mantras-api';
import { BookingService } from './booking.service';
import { Http } from '@angular/http';
import { StorageService } from './storage.service';
import { Utilities } from './utilities';
import { LoadingService } from './loading.service';
import { TaskbarService } from './taskbar.service';
import { Range } from 'mantras-api';
import { RatecalendarService } from './ratecalendar.service';
import { DateRange } from 'mantras-api';
import { AppConfigService } from '../app-config.service';


@Injectable()
export class DashboardService {
    hotelId: string;
    selectedDomain: any;
    currentUser: any;
    //mantras: AppService;

    constructor(private mantras: AppService, private http: Http, private storageService: StorageService, private utilities: Utilities,
        private loadingService: LoadingService, private bookingService: BookingService, private rateCalService: RatecalendarService,
        private appConfService:AppConfigService) {
        Utilities.initApp(mantras,appConfService);
    }

    findBooking(criteria: BookingCriteria) {
        let newBookings = [];
        let response = this.bookingService.findBookingsForDashboard(criteria).then(async response => {
            return response;
        }).catch(error => {
            throw error;
        });
        return response;
    }

    getTodaysArrival(date?) {
        let criteria = new BookingCriteria();
        criteria.HotelIds = [];
        this.currentUser = this.storageService.get(StorageService.currentUser);
        this.selectedDomain = this.currentUser.DomainContext.CurrentDomainId;
        if (this.selectedDomain == "System") {
            criteria.HotelIds.push("System");
         }
        else {
        criteria.HotelIds.push(this.storageService.get(StorageService.currentUser).HotelContext.HotelId);
        }
        criteria.CheckInDates = new Range<Date>();
        let today = new Date();
        if (date) today = date;
        criteria.CheckInDates.Start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
        criteria.CheckInDates.End = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 0);
        criteria.BookingStatus = [BookingStatus.Confirmed, BookingStatus.Modified, BookingStatus.Provisional];
        let todaysArrival = this.findBooking(criteria);
        return todaysArrival;
    }

    getTodaysBookings() {
        let criteria = new BookingCriteria();
        criteria.HotelIds = [];
        this.currentUser = this.storageService.get(StorageService.currentUser);
        this.selectedDomain = this.currentUser.DomainContext.CurrentDomainId;
        if (this.selectedDomain == "System") {
            criteria.HotelIds.push("System");
         }
        else {
            criteria.HotelIds.push(this.storageService.get(StorageService.currentUser).HotelContext.HotelId);
        }
        criteria.BookingDates = new Range<Date>();
        let today = new Date();
        criteria.BookingDates.Start = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
        criteria.BookingDates.End = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 0);
        let todaysBookings = this.findBooking(criteria);
        return todaysBookings;
    }

    async getRateCalendarInventory(currInvDate?) {
        let currDate = new Date()
        if (currInvDate) currDate = currInvDate;
        let range = new DateRange();
        range.Start = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate());
        range.End = new Date(currDate.setDate(currDate.getDate() + 6));
        if (currInvDate) new Date(currDate.setDate(currDate.getDate() - 6));
        this.currentUser = this.storageService.get(StorageService.currentUser);
        this.selectedDomain = this.currentUser.DomainContext.CurrentDomainId;
        if (this.selectedDomain == "System") {
            this.hotelId = "System";
        }
        else {
            this.hotelId = this.storageService.get(StorageService.currentUser).HotelContext.HotelId;
        }
        let response = await this.rateCalService.getRateCalendarSnapshot(this.hotelId, range).then(response => { return response; });
        let data = [];
        if (response && response.length > 0) {
            console.log("RateCalendarSnapshot: ", response);
        }
        return response;
    }

    async getRateCalendarOccupancy(currMonthDate?) {
        let currDate = new Date();
        if (currMonthDate) currDate = currMonthDate;
        let range = new DateRange();
        range.Start = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
        let endDate = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
        endDate.setMonth(endDate.getMonth() + 2);
        endDate.setDate(endDate.getDate() - 1);
        range.End = endDate;
        this.currentUser = this.storageService.get(StorageService.currentUser);
        this.selectedDomain = this.currentUser.DomainContext.CurrentDomainId;
        if (this.selectedDomain == "System") {
            this.hotelId = "System";
        }
        else {
        this.hotelId = this.storageService.get(StorageService.currentUser).HotelContext.HotelId;
        }
        let response = await this.rateCalService.getRateCalendarSnapshot(this.hotelId, range).then(response => { return response; });
        let data = [];
        if (response && response.length > 0) {
            console.log("RateCalendarSnapshot: ", response);
        }
        return response;
    }
}
