import { Component, OnInit, Input, EventEmitter, Output, HostListener, ChangeDetectorRef } from '@angular/core';
import { HotelChannel, Channel, PricingMap, RoomPricingModel, RateType, TaxType, DateRange } from 'mantras-api';
import { Utilities } from '../../ui-services/utilities';
import { HotelManagementService } from '../../ui-services/hotelmanagement.service';
import { StorageService } from '../../ui-services/storage.service';
import { ChannelHotelInfo } from 'mantras-api';
import * as jQuery from 'jquery';
import { UserService } from '../../ui-services/user.service';
import { AuthenticationService } from '../../ui-services/authentication.service';
import { RatecalendarService } from '../..//ui-services/ratecalendar.service';
@Component({
  selector: 'hotelchanneldetails',
  templateUrl: './hotelchanneldetails.component.html',
  styleUrls: ['./hotelchanneldetails.component.css']
})
export class HotelchanneldetailsComponent implements OnInit {

  @Input() hotelChannel: HotelChannel;
  @Output('onClose') onClose = new EventEmitter<boolean>();
  @Output() dataEmitter : EventEmitter<any> = new EventEmitter();
  menuItems = [];
  channeltype: string;
  hotelContext: any;
  errorMsg = "";
  hotelId = "";
  domainId = "";
  domainChannels: Channel[];
  channelsOptions = [];
  channelHotelInfo: ChannelHotelInfo;
  domainChannelChannelHotelInfo = [];
  mappedDomainChannel: Channel;
  hotelsList = [];
  masterRooms = [{ label: "None", value: null }, { label: "OTA Managed", value: "OTAManaged" }];
  masterRateplans = [{ label: "None", value: null }, { label: "OTA Managed", value: "OTAManaged" }];
  masterRoomsObject = [];
  currencies = [{ label: "None", value: null }];
  unmapped = [];
  mapped = [];
  flatRoomObject = [];
  emailIdSet = false;
  noChannelsUnmapped = false;
  syncHBDgrRates = false;
  masterRoomRateplans = [];
  availabilityRatio = 100;
  isReadOnly = false;
  manualOverride = false;
  rateType = [{ label: "Net Rate", value: 0 }, { label: "Sell Rate", value: 1 }];
  pricingModel = [{ label: "Per Day", value: 1 }, { label: "Per Occupancy", value: 2 }];
  taxType = [{ label: "Exclusive", value: 0 }, { label: "Inclusive", value: 1 }];
  isNew = false;
  stopSyncExtraPerson = false;
  syncExtraChildrenRate = false;
  constructor(private hotelService: HotelManagementService, private storageservice: StorageService,private userService:UserService, private authenticationService:AuthenticationService,
    private cdr: ChangeDetectorRef, private rateCalendarService:RatecalendarService, private authService:AuthenticationService) {
    this.isReadOnly = userService.isReadOnly();
    this.domainId = this.storageservice.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    this.hotelContext = this.storageservice.get(StorageService.currentUser).HotelContext;

    this.currencies = []
    Utilities.currencies.forEach(currency => {
      this.currencies.push({ value: currency.Code, label: currency.Code + "(" + currency.Symbol + ") - " + currency.Currency + " (" + currency.Country + ")" });
    });
  }

  ngOnInit() {
    this.channeltype = Utilities.findId(Utilities.channelTypes, this.hotelChannel.ChannelCode, this.hotelChannel.ChannelCode);
    this.menuItems = [
      { title: this.channeltype, label: this.channeltype, styleClass: "menu-label" },
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', disabled:this.isReadOnly,command: (event) => this.onSave(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }];
  }
  async loadData() {
    this.unmapped = await this.hotelService.findChannelHotelInfo(this.domainId, null);
    this.mapped = await this.hotelService.findChannelHotelInfo(this.domainId, this.hotelContext.HotelId);
    this.domainChannels = await this.hotelService.getDomainChannels();
    let hotelChannels = await this.hotelService.findHotelChannels(this.hotelContext.HotelId);
    this.masterRoomRateplans = await this.hotelService.findRoomRatePlans(this.hotelContext.HotelId);
    this.availabilityRatio = (this.hotelChannel.AvailabilityRatio) ? this.hotelChannel.AvailabilityRatio * 100 : 100;
    this.channelHotelInfo = this.mapped.filter(m => m.ChannelId === this.hotelChannel.ChannelId && m.ChannelHotelId === this.hotelChannel.ChannelHotelId)[0];
    
    this.mapped.forEach(chi => {
      let matching = this.unmapped.find(umap => umap.ChannelHotelId == chi.ChannelHotelId && umap.ChannelCode == chi.ChannelCode && umap.ChannelId == chi.ChannelId);
      if(matching) { this.unmapped.splice(this.unmapped.indexOf(matching),1) };
      this.unmapped.push(chi);
    });
    if (this.unmapped.length == 0 && !this.hotelChannel.ChannelHotelId) this.noChannelsUnmapped = true;
    // this.domainChannels.forEach(dc => {
    //   if (!dc.IsActive) return;
    //   if (this.unmapped.filter(m => m.ChannelCode == dc.ChannelCode && m.ChannelId === dc.Id).length > 0) {
    //     let cT = Utilities.findId(Utilities.channelTypes, dc.ChannelCode, dc.ChannelCode);
    //     this.channelsOptions.push({ label: cT + "-" + dc.Name, value: dc.Id });
    //   }
    // });
    this.unmapped.forEach(unmap => {
      if(hotelChannels.filter(hc => hc.ChannelCode == unmap.ChannelCode).length == 0){
          let dc = this.domainChannels.find(d => d.ChannelCode == unmap.ChannelCode && d.Id == unmap.ChannelId);
          let cT = Utilities.findId(Utilities.channelTypes, unmap.ChannelCode, unmap.ChannelCode);
          if(dc && cT){
            this.channelsOptions.push({ label: cT + "-" + dc.Name, value: dc.Id });
          }
      }
    });
    if (this.channelHotelInfo) {
      let ch = Utilities.findId(Utilities.channelTypes, this.hotelChannel.ChannelCode, this.hotelChannel.ChannelCode);
      this.mappedDomainChannel = this.domainChannels.filter(dc => dc.Id === this.hotelChannel.ChannelId)[0];
      let isPresent = this.channelsOptions.find(co => { return co.value === this.mappedDomainChannel.Id })
      if (!isPresent)
        this.channelsOptions.push({ label: ch + "-" + this.mappedDomainChannel.Name, value: this.mappedDomainChannel.Id })
    }
    if (!this.hotelChannel.ChannelId) {
      this.hotelChannel.ChannelId = this.channelsOptions[0].value;
      this.mappedDomainChannel = this.domainChannels.filter(dc => dc.Id === this.hotelChannel.ChannelId)[0];
      this.channelHotelInfo = this.unmapped.filter(m => m.ChannelId === this.hotelChannel.ChannelId)[0];
      this.hotelChannel.IsActive = true;
      this.hotelChannel.ChannelCode = this.mappedDomainChannel.ChannelCode;
      let domainHotel = this.authenticationService.getDomainHotel(this.hotelContext.HotelId);
      this.hotelChannel.Currency = (domainHotel.Currency)?domainHotel.Currency:"INR";
      this.hotelChannel.Tax = 0; 
      this.hotelChannel.SellMargin = 0;
      this.hotelChannel.PricingModel = RoomPricingModel.PerOccupancyPricing;
      this.hotelChannel.RateType = RateType.SellRate;
      this.hotelChannel.TaxType = TaxType.Exclusive;
      this.hotelChannel.TaxesAppliedOn =  RateType.SellRate;
      this.isNew =true;
    }

    this.domainChannelChannelHotelInfo = this.unmapped.filter(um => um.ChannelId === this.mappedDomainChannel.Id);
    if (this.channelHotelInfo) this.hotelsList.push({ label: this.channelHotelInfo.HotelName + "-" + this.channelHotelInfo.ChannelHotelId, value: this.channelHotelInfo.ChannelHotelId });
    this.domainChannelChannelHotelInfo.forEach(chi => {
      let alreadyAdded = this.hotelsList.find(hl => hl.value == chi.ChannelHotelId);
      if (chi.HotelName && chi.ChannelHotelId != null && !alreadyAdded)
        this.hotelsList.push({ label: chi.HotelName + "-" + chi.ChannelHotelId, value: chi.ChannelHotelId });
    });
    this.hotelId = this.hotelChannel.HotelId ? this.hotelChannel.HotelId : this.storageservice.get(StorageService.currentUser).HotelContext.HotelId;
   await this.hotelService.findRoomTypes(this.hotelId).then(response => {
      this.masterRoomsObject = response;
      response.forEach(rt => {
        if (!rt.IsActive) return;
        this.masterRooms.push({ label: rt.Name, value: rt.Id });
      });
    })
  await  this.hotelService.findRatePlans(this.hotelId).then(response => {
      response.forEach(rt => {
        if (!rt.IsActive) return;
        this.masterRateplans.push({ label: rt.Name, value: rt.Id });
      });
    })
    this.simplifyRooms();
    if (this.hotelChannel.Config) {
      this.emailIdSet = this.hotelChannel.Config["IsEmailIdSet"] ? this.hotelChannel.Config["IsEmailIdSet"].toLowerCase() == "true" : false;
      if (this.hotelChannel.ChannelCode == "HBD")
        this.syncHBDgrRates = this.hotelChannel.Config["SyncHotelbedsGRRates"] ? this.hotelChannel.Config["SyncHotelbedsGRRates"].toLowerCase() == "true" : false;
      if (this.hotelChannel.ChannelCode == 'XCG' || this.hotelChannel.ChannelCode == 'LUC' || this.hotelChannel.ChannelCode == 'WMS')
        this.manualOverride = this.hotelChannel.Config["ManualOverride"]?this.hotelChannel.Config["ManualOverride"].toLowerCase() == "true":false;
      this.stopSyncExtraPerson = this.hotelChannel.Config["StopSyncExtraPerson"] ? this.hotelChannel.Config["StopSyncExtraPerson"].toLowerCase() == "true" : false;
      this.syncExtraChildrenRate = this.hotelChannel.Config["SyncExtraChild"] ? this.hotelChannel.Config["SyncExtraChild"].toLowerCase() == "true" : false;
    }
    this.channeltype = Utilities.findId(Utilities.channelTypes, this.hotelChannel.ChannelCode, this.hotelChannel.ChannelCode);
    this.getMenuItemData();
  }
  findChannelHotelInfo() {
    this.hotelService.findChannelHotelInfo(this.domainId, null).then(
      res => { this.unmapped = res; }
    );
  }

  findChannelHotelInfoMapped() {
    this.hotelService.findChannelHotelInfo(this.domainId, this.hotelContext.HotelId).then(
      res => { this.mapped = res; }
    );
  }
  getDomainChannels() {
    this.hotelService.getDomainChannels().then(
      res => { this.domainChannels = res; }
    );
  }
  simplifyRooms() {
    this.flatRoomObject = [];
    let rrpMap = this.hotelChannel.RoomRatePlanMap;
    let pricingMaps = [];
    this.channelHotelInfo.RoomTypes.forEach(rt => {
      if (!rt.IsActive) return;
      let room = new FlatRoom();
      room.Room = new BaseObject();
      room.Room.Code = rt.Code + "(" + rt.Id + ")";
      room.Room.Id = rt.Id;
      room.Room.Name = rt.Name;
      if (rrpMap)
        room.Room.MasterId = this.findMasterRoomId(rrpMap, rt.Id);
      room.Rateplans = [];
      let rrps = this.channelHotelInfo.RoomRatePlans.filter(r => r.RoomTypeId === rt.Id);
      rrps.forEach(r => {
        let pricingMap = new PricingMap();
        let rateplan = this.channelHotelInfo.RatePlans.filter(rp => rp.Id === r.RatePlanId)[0];
        if(rateplan){
        if (this.hotelChannel.RoomRatePlanPricing)
           pricingMaps = this.hotelChannel.RoomRatePlanPricing.filter(pm => (pm.RoomId === room.Room.Id) && (pm.RatePlanId === rateplan.Id));
        if (pricingMaps && pricingMaps.length > 0) pricingMap = pricingMaps[0];
        if (rateplan) {
          let obj = new BaseObject();
          obj.Name = rateplan.Name;
          obj.Id = rateplan.Id;
          obj.Code = rateplan.Code + "(" + rateplan.Id + ")";
          if (rrpMap)
            obj.MasterId = this.findMasterRPId(rrpMap, rateplan.Id, room.Room.Id);
          if (obj.MasterId != "OTAManaged" && obj.MasterId != null) {
            let masterroom = this.masterRoomsObject.filter(mr => mr.Id === room.Room.MasterId)[0];
            if (masterroom) {
              obj.BaseO = pricingMap.BaseOccupancy ? pricingMap.BaseOccupancy : masterroom.BaseOccupancy;
              obj.MaxO = pricingMap.MaxOccupancy ? pricingMap.MaxOccupancy : masterroom.MaxOccupancy;
            }
            else {
              obj.BaseO = 0; obj.MaxO = 0;
            }
          }
          else { obj.BaseO = 0; obj.MaxO = 0; }
          room.Rateplans.push(obj);
        }
      }
      });
      room.Rateplans.sort(Utilities.sortByMutipleFields("Name"));
      room.RatePlanOptions = this.populateRateplanDropdown(room.Room.MasterId);
      this.flatRoomObject.push(room);
    });
    this.flatRoomObject.sort(Utilities.sortByDisplayOrder("Room", "Name"));
  }

  masterRoomChanged(room){
    let obj = this.flatRoomObject.find(fro =>fro.Room.Id == room.Room.Id); 
    let masterRoom = this.masterRoomsObject.find(mr => mr.Id == room.Room.MasterId);
    if(masterRoom){
      obj.Rateplans.forEach(rp => {
        rp.BaseO = masterRoom.BaseOccupancy;
        rp.MaxO = masterRoom.MaxOccupancy;
      });
    }
    obj.RatePlanOptions = this.populateRateplanDropdown(obj.Room.MasterId);
  }

  populateRateplanDropdown(event) {
    if (event === "OTAManaged") return [{ label: "OTA Managed", value: "OTAManaged" }];
    else {
      let ratePlans = [{ label: "None", value: null }, { label: "OTA Managed", value: "OTAManaged" }];
      let roomRrps = this.masterRoomRateplans.filter(rrp => rrp.RoomTypeId === event);
      roomRrps.forEach(rrrp => {
        let ratePlan = this.masterRateplans.filter(mrp => mrp.value === rrrp.RatePlanId)[0];
        if (ratePlan) {
          ratePlans.push({ label: ratePlan.label, value: rrrp.RatePlanId });
        }
      })
      return ratePlans;
    }
  }

  findMasterRoomId(obj: any, Id: string) {
    let masterId = "";
    Object.keys(obj).forEach(o => {
      if (o.startsWith(Id+"_")) {
        masterId = obj[o].split("_", 1)[0];
        if (masterId.includes("OTAManaged")) masterId = "OTAManaged";
      }
    })
    return masterId;
  }

  findMasterRPId(obj: any, Id: string, roomId: string) {
    let masterId = "";
    Object.keys(obj).forEach(o => {
      if (o === (roomId + '_' + Id)) {
        masterId = obj[o].split("_", 2)[1];
        if (masterId.includes("OTAManaged")) masterId = "OTAManaged";
      }
    })
    return masterId;
  }
  onSave(event) {
    if (this.hotelChannel.SellMargin == null || this.hotelChannel.Tax == null) {
      this.errorMsg = "Commission and Tax cannot be empty. Please provide correct setting to complete the operation."; 
      return; 
    }
    this.createRoomRatePlanMap();
    this.hotelChannel.AvailabilityRatio = this.availabilityRatio / 100;
    if (!this.hotelChannel.Config) this.hotelChannel.Config = {};
    
    this.hotelChannel.Config["IsEmailIdSet"] = String(this.emailIdSet);
    if (this.hotelChannel.ChannelCode == 'HBD')
      this.hotelChannel.Config["SyncHotelbedsGRRates"] = String(this.syncHBDgrRates);
    if (this.hotelChannel.ChannelCode == 'BDC')
      this.hotelChannel.Config["SupportChannelFields"] = String(true);
    if (this.hotelChannel.ChannelCode == 'XCG' || this.hotelChannel.ChannelCode == 'LUC' || this.hotelChannel.ChannelCode == 'WMS')
      this.hotelChannel.Config["ManualOverride"] = String(this.manualOverride);
    this.hotelChannel.Config["StopSyncExtraPerson"] = String(this.stopSyncExtraPerson);
    this.hotelChannel.Config["SyncExtraChild"] = String(this.syncExtraChildrenRate);

    if (!this.hotelChannel.DomainId) this.hotelChannel.DomainId = this.domainId;
    if (!this.hotelChannel.ChannelHotelId) this.hotelChannel.ChannelHotelId = this.channelHotelInfo.ChannelHotelId;
    if (!this.hotelChannel.HotelId) this.hotelChannel.HotelId = this.hotelContext.HotelId;
    this.hotelChannel.MappingErrors = null;
    this.hotelService.saveHotelChannel(this.hotelChannel, this.isNew, this.domainId).then(resp => {
      if(this.isNew){
        let startDate = new Date();
        startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
        let endDate = new Date();
        endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0, 0);
        endDate.setMonth(endDate.getMonth()+1);
        let range = new DateRange();
        range.Start = startDate;
        range.End = endDate;
        this.rateCalendarService.syncRateCalendar(this.hotelChannel.HotelId,this.hotelChannel.DomainId,range,"oneway",true,[this.hotelChannel.ChannelCode]);
      }
      this.onCancel(true);
    });
  }
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.hotelChannelsSection').css('height', Utilities.getScrollHeight(true,true,false,false));
  }

  onCancel(event) {
    this.onClose.emit(event);
  }

  createRoomRatePlanMap() {
    let rrpMap = {};
    let pMaps = [];
    this.flatRoomObject.forEach(room => {
      let isRoomOTAManaged = false;
      if ((room.Room.MasterId != null) && (room.Room.MasterId != "") && (room.Room.MasterId != "null")) {
        if (room.Room.MasterId == "OTAManaged"){ 
          room.Room.MasterId = room.Room.MasterId;
          isRoomOTAManaged = true;
        }

        room.Rateplans.forEach(rp => {
          if ((rp.MasterId != null) && (rp.MasterId != "") && (rp.MasterId != "null") || isRoomOTAManaged) {
            let map;
            if (this.hotelChannel.RoomRatePlanPricing && this.hotelChannel.RoomRatePlanPricing != null)
              map = this.hotelChannel.RoomRatePlanPricing.filter(rrpp => (rrpp.RoomId === room.Room.Id) && (rrpp.RatePlanId === rp.Id))[0];
            if (rp.MasterId == "OTAManaged" || isRoomOTAManaged) rp.MasterId = "OTAManaged" + rp.Id;
            rrpMap[room.Room.Id + "_" + rp.Id] = room.Room.MasterId + "_" + rp.MasterId;
            if (map) { map.BaseOccupancy = rp.BaseO; map.MaxOccupancy = rp.MaxO; }
            else {
              map = new PricingMap(); let masterRoom;
              map.RoomId = room.Room.Id;
              map.RatePlanId = rp.Id;
              if (rp.BaseO == 0) {
                masterRoom = this.masterRoomsObject.filter(mr => mr.Id === room.Room.Id);
                map.BaseOccupancy = masterRoom.BaseOccupancy;
                map.MaxOccupancy = masterRoom.MaxOccupancy;
              }
              else {
                map.BaseOccupancy = rp.BaseO;
                map.MaxOccupancy = rp.MaxO;
              }
            }
            pMaps.push(map);
          }
        })
      }
    });
    this.hotelChannel.RoomRatePlanMap = rrpMap;
    this.hotelChannel.RoomRatePlanPricing = pMaps;
  }

  onChangeChannel(event) {
    this.mappedDomainChannel = this.domainChannels.filter(dc => dc.Id === this.hotelChannel.ChannelId)[0];
    this.domainChannelChannelHotelInfo = this.unmapped.filter(um => um.ChannelId === this.mappedDomainChannel.Id);
    if (this.domainChannelChannelHotelInfo.length == 0) this.domainChannelChannelHotelInfo = this.mapped.filter(um => um.ChannelId === this.mappedDomainChannel.Id);
    this.hotelsList = [{ label: "None", value: null }];
    this.domainChannelChannelHotelInfo.forEach(chi => {
      if (chi.HotelName && (chi.ChannelHotelId != null))
        this.hotelsList.push({ label: chi.HotelName + "-" + chi.ChannelHotelId, value: chi.ChannelHotelId });
    });
    this.hotelChannel.ChannelCode = this.mappedDomainChannel.ChannelCode;
    this.channeltype = this.mappedDomainChannel.Name;
    this.getMenuItemData();
    this.ngOnInit();
  }
  onChangeHotel(event) {
    this.channelHotelInfo = this.unmapped.filter(m => m.ChannelId === this.hotelChannel.ChannelId && (m.ChannelHotelId === event.value))[0];
    if (!this.channelHotelInfo) this.channelHotelInfo = this.mapped.filter(m => m.ChannelId === this.hotelChannel.ChannelId && (m.ChannelHotelId === event.value))[0];
    this.simplifyRooms();
  }
  displayToSystemUser(){
    if(this.authService.isSystemUser()){
      return 'block';
  }
  else return 'none';
  }
  getMenuItemData(){
    this.menuItems = [
      { title: this.channeltype, label: this.channeltype, styleClass: "menu-label"},
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', disabled:this.isReadOnly,command: (event) => this.onSave(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }];
    let menuData = [];
    menuData.push({'source':'hotelChannelDetails'});
    menuData.push(this.menuItems);
    this.dataEmitter.emit(menuData);
  }
}
class BaseObject {
  Name: string;
  Code: string;
  Id: string;
  MasterId: string;
  BaseO: number;
  MaxO: number;
}
class FlatRoom {
  Room: BaseObject;
  Rateplans: BaseObject[];
  RatePlanOptions: any[];
}
