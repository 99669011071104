import { Component, OnInit, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { RMSBookingCriteria, Range, RevenueCalendarCriteria } from 'mantras-api';
import { RmsService } from '../../ui-services/rms.service';
import { StorageService } from '../../ui-services/storage.service';
import { Utilities } from '../../ui-services/utilities';
import { LoadingService } from '../../ui-services/loading.service';
import { EventManagementService } from '../../ui-services/eventmanagement.service';
import { DatePipe } from '@angular/common';

import * as jQuery from 'jquery';
import { Subscription } from 'rxjs';
import { CustomReuseStrategy } from '../../ui-services/reuse-routes';

@Component({
  selector: 'mm-gapanalysis',
  templateUrl: './gapanalysis.component.html',
  styleUrls: ['./gapanalysis.component.css']
})
export class GapanalysisComponent implements OnInit, AfterViewInit {

  items;
  hotelContext;
  domainContext;
  bookings = [];
  rmsGraphData;
  rmsGraphOption;
  rmsOverviewManuItems;
  viewOptions;
  selectedView = {label:"Day",value:'2'};
  graphTypes;
  selectedType = {label:"Revenue",value:'0'};
  selectedCheckinFrom;
  selectedCheckinTo;
  showCriteria = false;
  isFilterEnabled = false;
  filterSubscription: Subscription;
  periodOptions;
  subscription: Subscription;
  selectedPeriod = {label:"Last Month",value:'0'};
  constructor(private storageService:StorageService,private router:Router, private rmsService: RmsService,
    private loadingService:LoadingService,private eventManager:EventManagementService) { 
    this.domainContext = this.storageService.get(StorageService.currentUser).DomainContext;
    this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    
    this.viewOptions = [{label:"Month",value:'0'},{label:"Week",value:'1'},{label:"Day",value:'2'},{label:"Day of Week",value:'3'}];
    this.graphTypes = [{label:"Revenue",value:'0'},{label:"Occupancy",value:'1'},{label:"Average Room Rate",value:'2'}]
    this.periodOptions = [{label:"Last Month",value:'0'},{label:"Last Quarter",value:'1'},{label:"Last 6 month",value:'2'},{label:"Last Year",value:'3'},{label:"Custom",value:'4'}]
    
    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
    });
    this.onViewChange();
  }

  renderMenu(){
    this.rmsOverviewManuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.getBookings() },
      { title: 'Export to excel', label: 'Export', icon: 'action-bar-menu-icon fa fa-file-excel-o', command: (event) => this.ShowExportDialog()},
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
    ];
  }

  ngOnInit() {
    this.renderMenu();
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  onDateRangeChange(event){

  }

  onViewChange(){
    let fromDate = new Date();
    let toDate = new Date();
    toDate.setDate(toDate.getDate()-1);//Yesterday
    if(this.selectedPeriod.value == '0'){//Month
      fromDate.setMonth(fromDate.getMonth()-1);
      fromDate.setDate(fromDate.getDate()-1);
    } else if(this.selectedPeriod.value == '1'){//Quarter
      fromDate.setMonth(fromDate.getMonth()-3);
      fromDate.setDate(fromDate.getDate()-1);
    } else if(this.selectedPeriod.value == '2'){//Half Year
      fromDate.setMonth(fromDate.getMonth()-6);
      fromDate.setDate(fromDate.getDate()-1);
    } else if(this.selectedPeriod.value == '3'){//Year
      fromDate.setMonth(fromDate.getMonth()-12);
      fromDate.setDate(fromDate.getDate()-1);
    }
    if(this.selectedPeriod.value != '4') {
      this.selectedCheckinFrom = Utilities.getUTCDate(fromDate);
      this.selectedCheckinTo = Utilities.getUTCDate(toDate);
    }
    this.onSearch(false);
  }

  onRmsFilterChange(filterData){
    this.selectedType = this.graphTypes.find(o => o.value === filterData["graphType"]);
    this.selectedView = this.viewOptions.find(o => o.value === filterData["groupBy"]);
    this.renderGraph();
  }
  ShowExportDialog(){
  }

  onClose(){
    this.router.navigate(["/rms"]);
  }

  onSearch(isCustom:boolean=true){
    if(isCustom) this.selectedPeriod = {label:"Custom",value:'4'};
    this.getBookings();
  }
  showFilter = false;
  hideShowFilterCriteria(){
    if(this.isFilterEnabled)
      this.showFilter = !this.showFilter;
  }

  hideShowCriteria(){
    this.showCriteria = !this.showCriteria;
    this.setHeight()
  }

  async getBookings(){
    let criteria = new RevenueCalendarCriteria();
    criteria.HotelId = this.storageService.get(StorageService.userHotelContext).HotelId;
    criteria.StayDates = new Range<Date>();
    criteria.StayDates.Start = this.selectedCheckinFrom;
    criteria.StayDates.End = this.selectedCheckinTo;
    //this.bookings = await this.loadingService.interceptor(this.rmsService.getDateWiseBookings(rmsBookCriteria),true);
    this.bookings = await this.loadingService.interceptor(this.rmsService.findRevenueAnalytics(criteria),true);
    console.log("RMS Bookings:",this.bookings);
    this.bookings = this.bookings.sort(Utilities.sortByMutipleFields("StayDate"));
    this.isFilterEnabled = (this.bookings && this.bookings.length > 0);
    this.renderGraph();
  }

  renderGraph(){
    this.populateXAxis();
    this.parseBookings();
    this.onFilterChange();
    this.setHeight();
  }

  // ARR, Occupancy and Revenue
  // Days, Day Of week, Month and Year month wise
  xAxis = [];
  barGraphData = [];
  datePipe = new DatePipe('en-US');
  populateXAxis(){
    this.xAxis = [];
    //For Month View:
    let selDate = new Date(this.selectedCheckinFrom);
    let endDate = new Date(this.selectedCheckinTo);
    while(Utilities.dateDifference(selDate,endDate)>0){
      if(this.selectedView.value == '0'){ //Add Month 
        this.xAxis.push(this.datePipe.transform(selDate, "MMM-yy"));
        selDate.setMonth(selDate.getMonth()+1);
      } else if(this.selectedView.value == '1'){//Add Week
        this.xAxis.push(parseInt(this.datePipe.transform(selDate, "ww")));
        selDate.setDate(selDate.getDate()+7);
      } else if(this.selectedView.value == '2'){//Add Date
        this.xAxis.push(this.datePipe.transform(selDate, "dd-MMM-yyyy"));
        selDate.setDate(selDate.getDate()+1);
      } else if(this.selectedView.value == '3'){// Add Day of Week
        // let dow = ["Sun", "Mon", "Tue", "Wed", "Thru", "Fri", "Sat"];
        // this.xAxis.push(dow);
        // break;
        let dow = this.datePipe.transform(selDate, "EEE");
        if(this.xAxis.indexOf(dow) >= 0) break;
        this.xAxis.push(this.datePipe.transform(selDate, "EEE"));
        selDate.setDate(selDate.getDate()+1);
      }
    }
  }
  backgroundColor = Utilities.backgroundColor;
  parseBookings(){
    this.barGraphData = [];
    let totalOccupancy = [];
    let totalRevenue = [];
    this.bookings.forEach(analytics => {
      let key;
      if(this.selectedView.value == '0'){ //by Month
        key = this.datePipe.transform(analytics.StayDate, "MMM-yy"); 
      } else if(this.selectedView.value == '1'){ //by Week
        key = parseInt(this.datePipe.transform(analytics.StayDate, "ww"));
      } else if(this.selectedView.value == '2'){ //by Day
        key = this.datePipe.transform(new Date(analytics.StayDate),"dd-MMM-yyyy");
      } else if(this.selectedView.value == '3'){ //by Day of Week
        key = this.datePipe.transform(analytics.StayDate, "EEE");
      }
      if(this.xAxis.indexOf(key)>=0){
        let idx = this.xAxis.indexOf(key);
        let val = this.barGraphData[idx];
        if(this.selectedType.value == '0'){//Total Revenue
        val? val+=analytics.TotalRevenue: val=analytics.TotalRevenue;
        this.barGraphData[idx] = val;
        } else if(this.selectedType.value == '1') {//Occupancy
          val? val+=analytics.Occupancy: val=analytics.Occupancy;
          this.barGraphData[idx] = val;
        } else { // Avg room rate
          let occ = totalOccupancy[idx];
          let rev = totalRevenue[idx];
          occ? occ+=analytics.Occupancy: occ=analytics.Occupancy;
          rev? rev+=analytics.TotalRevenue: rev = analytics.TotalRevenue;
          totalOccupancy[idx] = occ;
          totalRevenue[idx] = rev;
        }
      }
    });
    if(this.selectedType.value == "2"){
      for(let i = 0; i < totalOccupancy.length; i++){
        let occ = totalOccupancy[i];
        if(occ){
          this.barGraphData[i] = (totalRevenue[i]/totalOccupancy[i]).toFixed(2);
        }
      }
    }
    this.rmsGraphData = {
      labels: this.xAxis,
      datasets:[{
        label:this.selectedType.label,
        borderColor: this.backgroundColor[0],
        backgroundColor: this.backgroundColor[0],
        data: this.barGraphData,
      }]
    };
    this.rmsGraphOption = {
      title: {
        display: true,
        text: this.getGraphLabel(),
        fontSize: 15
      }
    };
  }

  // parseBookings(){
  //   this.barGraphData = [];
  //   let totalOccupancy = [];
  //   let totalRevenue = [];
  //   this.bookings.forEach(rmsBooking => {
  //     let key = rmsBooking.Season_Month;
  //     if(this.selectedView.value == '0'){ //by Month
  //       key = rmsBooking.Season_Month; 
  //     } else if(this.selectedView.value == '1'){ //by Week
  //       key = rmsBooking.Season_WeekNo;
  //     } else if(this.selectedView.value == '2'){ //by Day
  //       key = this.datePipe.transform(new Date(rmsBooking.StayDate),"dd-MMM-yyyy");
  //     } else if(this.selectedView.value == '3'){ //by Day of Week
  //       key = rmsBooking.Season_DoW;
  //     }
  //     if(this.xAxis.indexOf(key)>=0){
  //       let idx = this.xAxis.indexOf(key);
  //       let val = this.barGraphData[idx];
  //       if(this.selectedType.value == '0'){
  //       val? val+=rmsBooking.ADR: val=rmsBooking.ADR;
  //       this.barGraphData[idx] = val;
  //       } else if(this.selectedType.value == '1') {
  //         val? val+=1: val=1;
  //         this.barGraphData[idx] = val;
  //       } else {
  //         let occ = totalOccupancy[idx];
  //         let rev = totalRevenue[idx];
  //         occ? occ+=1: occ=1;
  //         rev? rev+=rmsBooking.ADR: rev = rmsBooking.ADR;
  //         totalOccupancy[idx] = occ;
  //         totalRevenue[idx] = rev;
  //       }
  //     }
  //   });
  //   if(this.selectedType.value == "2"){
  //     for(let i = 0; i < totalOccupancy.length; i++){
  //       let occ = totalOccupancy[i];
  //       if(occ){
  //         this.barGraphData[i] = (totalRevenue[i]/totalOccupancy[i]).toFixed(2);
  //       }
  //     }
  //   }
  //   this.rmsGraphData = {
  //     labels: this.xAxis,
  //     datasets:[{
  //       label:this.selectedType.label,
  //       borderColor: this.backgroundColor[0],
  //       backgroundColor: this.backgroundColor[0],
  //       data: this.barGraphData,
  //     }]
  //   };
  //   this.rmsGraphOption = {
  //     title: {
  //       display: true,
  //       text: this.getGraphLabel(),
  //       fontSize: 15
  //     }
  //   };
  // }
  @ViewChild('rmsFilter') rmsFilter;
  onFilterChange(){
    this.isFilterEnabled = this.bookings && this.bookings.length >0;
    this.rmsFilter.setupRmsFilter({
      analysisFilter:true, showFilter: this.isFilterEnabled, groupBy: this.selectedView.value, graphType: this.selectedType.value});
  }

  getGraphLabel(){
    let label = this.selectedType.label+ " ";
    label += this.selectedView.label + " wise for Range: ";
    label += this.datePipe.transform(this.selectedCheckinFrom,"dd-MMM-yyyy")+" to "+this.datePipe.transform(this.selectedCheckinTo,"dd-MMM-yyyy");
    return label;
  }

  @ViewChild("criteriaDiv") criteriaDiv;
  @ViewChild("rmsChart") rmsChart;
  criteriaDivHeight = 95;
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    if(this.criteriaDiv.nativeElement.offsetHeight != 0)
      this.criteriaDivHeight = this.criteriaDiv.nativeElement.offsetHeight; 
    jQuery('.chartHeight').css('height', Utilities.getScrollHeight(true,false,false,false,(this.showCriteria?this.criteriaDivHeight:0)));
    if(this.rmsChart) this.rmsChart.refresh();
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }
}
