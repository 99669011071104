import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { RatecalendarService } from '../ui-services/ratecalendar.service';
import { BestAvailableRateComponent } from './bestavailablerate.component';
import { DatePipe } from '@angular/common';
import { RateCalendarEditorComponent } from './ratecalendarEditor.component';

@Component({
  selector: 'app-ratecalendar',
  templateUrl: './ratecalendar.component.html',
  styleUrls: ['./ratecalendar.component.css']
})
export class RatecalendarComponent implements OnInit {

  gridOptions : GridOptions;
  columnDefs : any[] = Array();
  rateCalendarData: any[] = Array();
  rowData: any[] = Array();
  
  constructor(private rateCalendarService: RatecalendarService) {
    //Initializing the GridOptions
    this.gridOptions = <GridOptions>{
      rowData: this.rateCalendarData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      groupDefaultExpanded:-1,
      rowHeight: 24,
      localeText:{noRowsToShow:"No rates found. Please load rates or pull from extranet"}
    };

    //Loading the data from service and updating the GridOptions
    this.getRateCalendar();  
   }

  ngOnInit() {
    
  }

  getRateCalendar(){
    return this.rateCalendarService.getRateCalendar().then(
      data => { 
        this.rateCalendarData = data;
        this.columnDefs = [
          {headerName: "Channel", field: "Channel", rowGroup: true, hide: true, enableValue: false},
          {headerName: "Room Type", field: "RoomType", rowGroup: true, hide: true, enableValue: false}
        ];
        //Run a loop on the rrp and push the headers in the columnDefs.
        if(this.rateCalendarData)
        {
          let index = 0;
          this.rateCalendarData[0].rrp.forEach(element => {
            let dateColumn = (element.StayDate)?new DatePipe('en-US').transform(new Date(element.StayDate),'EEE dd MMM'):'';
            this.columnDefs.push({headerName: dateColumn, field: "rrp", colId: index,
             cellRendererFramework: BestAvailableRateComponent, editable: (index>0), cellEditorFramework:RateCalendarEditorComponent });
            index++;
          });

        }
        console.log("Column Def: " + JSON.stringify(this.columnDefs));
        //This is to update the row height.
        this.gridOptions.getRowHeight = function(params){
          if (params.node.group) {
            return 24;
          } else {
            return 72;
          }
        };
        this.gridOptions.api.setColumnDefs(this.columnDefs);
        this.gridOptions.api.setRowData(this.rateCalendarData);
        this.gridOptions.api.sizeColumnsToFit();
        
      }
    );
  }

  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
  }


}
