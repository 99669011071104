import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventManagementService } from '../../ui-services/eventmanagement.service';
import { StorageService } from '../../ui-services/storage.service';
import { RmsService } from '../../ui-services/rms.service';
import { Utilities } from '../../ui-services/utilities';
import * as jQuery from 'jquery';

@Component({
  selector: 'false-revenuetools',
  templateUrl: './revenuetools.component.html',
  styleUrls: ['./revenuetools.component.css']
})
export class RevenuetoolsComponent implements OnInit, AfterViewInit {

  items;
  hasSystemAccess;
  constructor(private router:Router,private route: ActivatedRoute, private eventManager:EventManagementService,
    private storageService:StorageService, private rmsService: RmsService) {
      this.hasSystemAccess = Utilities.checkSystemAccess(this.storageService.get(StorageService.currentUser).DomainContext.Domains);
    }
    
    async routePage(navigationLink){
      this.router.navigate([navigationLink], {relativeTo: this.route });
    }
    
    ngAfterViewInit(): void {
      this.setHeight();
    }
  
    @HostListener('window:resize') setHeight() {
      let windowHeight = window.innerHeight;
      jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true));
    }
    ngOnInit() {
    }

}
