//Note - Current route is the source route while future route is the destination route
import { RouteReuseStrategy,DetachedRouteHandle,ActivatedRouteSnapshot } from '@angular/router'
import { ComponentRef, Inject } from '@angular/core';
import { EventManagementService } from './eventmanagement.service';

export class CustomReuseStrategy implements RouteReuseStrategy {
    public static handlers: {[key: string]: DetachedRouteHandle} = {};
    public static contextSwitched;
    constructor(@Inject(EventManagementService)private eventMgr:EventManagementService){
        CustomReuseStrategy.contextSwitched=false;
    }
    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        //Which route needs to be stored in handlers?
        //It is invoked when we leave the current route. 
        //If returns TRUE then the store method will be invoked:
        return true; // reuse all routes
    }
  
    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
      //if shouldDetach returns true current route snapshot will be stored in handler before switching to future route
        if(CustomReuseStrategy.contextSwitched == true){
            CustomReuseStrategy.deactivateOutlet(handle);
            CustomReuseStrategy.contextSwitched = false;
            return;
        }
      CustomReuseStrategy.handlers[route.url.join("/") || route.parent.url.join("/")] = handle;    
    }
  
    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        // This method is called for the route just opened when we land on the component of this route. 
        // Once component is loaded this method is called. 
        // If this method returns TRUE then retrieve method will be called, 
        // otherwise the component will be created from scratch
        this.eventMgr.breadCrumbsChange(route);
        return !!CustomReuseStrategy.handlers[route.url.join("/") || route.parent.url.join("/")];
        
    }
  
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        // This method is called if shouldAttach returns TRUE, provides as 
        // parameter the current route (we just land), and returns a stored RouteHandle.
      return CustomReuseStrategy.handlers[route.url.join("/") || route.parent.url.join("/")];    
    }
  
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        //If this method returns true routing will not happen
        //If it returns false then the routing happens and the rest of the methods are called.
      return future.routeConfig === curr.routeConfig;
    }

    public static deactivateAllHandles() {
        //remove current route from handler
        for (const key in CustomReuseStrategy.handlers) {
            if(CustomReuseStrategy.handlers[key]!=null)
                CustomReuseStrategy.deactivateOutlet(CustomReuseStrategy.handlers[key])
        }
        CustomReuseStrategy.handlers = {}
    }

    // Todo: we manually destroy the component view here.
    private static deactivateOutlet(handle: DetachedRouteHandle): void {
        const componentRef: ComponentRef<any> = handle['componentRef']
        if (componentRef) {
            componentRef.destroy()
        }
    }
}