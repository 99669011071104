import { Injectable } from "@angular/core";
import { MessageService } from "primeng/components/common/messageservice";
import { Message } from "primeng/components/common/api";
import { Router } from "@angular/router";
import { StorageService } from "./storage.service";
import * as jQuery from 'jquery';

@Injectable()
export class LoadingService {
    public pendingRequests: number = 0;
    public showLoading : boolean = false;
    public showExceptionDialog = false;
    public showBlockIcon = false;
    public showErrorIcon = false;
    public errorMessage;
    public errorHeader;
    public showCancel = false;
    public showFilter = false;
    public hideSideNav = false;
    constructor(private messageService:MessageService,private router: Router,
    private storageService:StorageService){

    }

    interceptor(promise:Promise<any>,isCancelable?:boolean):Promise<any>{
        this.pendingRequests++;
        this.showLoadingModel();
        this.showCancel = this.showCancel || isCancelable;
        return promise.then(response => {
            this.hideLoadingModel();
            return response;
        }).catch(error => {
            this.hideLoadingModel();
            this.parseErrorResponse(error);
            Promise.reject(error);
            throw error;
        });
    }

    showLoadingModel(){
        if(!this.showLoading){
            this.showLoading = true;
            try{
            jQuery('.loadercenter').css('padding-top',window.innerHeight/2);
            }catch(ex){}
        }
        this.showLoading = true;
    }
    showErrorMessage(blockMessage:string,type?:string,header?:string){
        let messageType;
        if(type) messageType = type; else messageType = "Error"
        this.errorMessage=blockMessage;
        if(header)  this.errorHeader=header;
        else this.errorHeader="Error";
        if(messageType == 'Block'){ this.showBlockIcon = true; this.showErrorIcon = false;}
        if(messageType == 'Error'){ this.showErrorIcon = true; this.showBlockIcon = false;}
        this.showExceptionDialog = true;
    }
    hideLoadingModel() {
        this.pendingRequests--;
        if (this.pendingRequests <= 0) {
            this.showLoading = false;
            this.showCancel = false;
        }
    }

    parseErrorResponse(errResp){
        console.log("ERROR RESPONSE >>> ",errResp);
        let errorMsg = "Internal Error";
        if(errResp){
            errorMsg = errResp.error["Text"]; 
        }
        this.messageService.addAll([{severity:'error', summary:'Error', detail:errorMsg}]);
    }

    showErrorDialog(error){
        let errorMessage = "Something went wrong. Please try after sometime.";
        let exceptionType = "";
        if(error && error.error && error.error.Text) {
            errorMessage = error.error.Text;
            exceptionType = error.error.ExceptionType;
        }
        // Handle Invalid Session and redirecting it to login page
        if(errorMessage == "Invalid session" && exceptionType == "System.AccessViolationException"){
            //this.messageService.addAll([{severity:'error', summary:'Error', detail: "Session Expired, Please re-login."}]);
            alert("Session Expired, Please re-login.");
            this.invalidateUserSession();
            return;
        }
        this.showErrorMessage(errorMessage);
    }

    showDialog(message:string){
        this.showErrorMessage(message);
    }

    invalidateUserSession(){
        this.storageService.remove(StorageService.currentUser);
        this.pendingRequests=0;
        this.hideLoadingModel();
        //this.router.navigate(['/login']);
        window.location.href = "/login.html";
    }

    cancelLoading(){
        this.pendingRequests = 0;
        this.showLoading = false;
        this.showCancel = false;
    }
}