import { Component, OnInit, HostListener, AfterViewInit, ViewChild } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';

import * as jQuery from 'jquery';
import { RmsService } from '../../ui-services/rms.service';
import { RMSHotelCriteria, Range, RevenueCalendarCriteria } from 'mantras-api';
import { StorageService } from '../../ui-services/storage.service';
import { Utilities } from '../../ui-services/utilities';
import { AuthenticationService } from '../../ui-services/authentication.service';
import { Router } from '@angular/router';
import { Dropdown } from 'primeng/primeng';
import { EventManagementService } from '../../ui-services/eventmanagement.service';

@Component({
  selector: 'mm-revenueoperations',
  templateUrl: './revenueoperations.component.html',
  styleUrls: ['./revenueoperations.component.css']
})
export class RevenueoperationsComponent implements OnInit, AfterViewInit {

  items;
  today: Date;
  datePipe = new DatePipe('en-US');
  calendarSection = [];
  rows = [0, 1, 2, 3, 4];
  totalDayOccupancy = 50;
  totalDayRevenue = 5000000;
  totalDayARR = 4000;
  currentSelected;
  domainHotel;
  hotelContext;
  currencySymbol;
  selectedPaceGraphData;
  selectedPaceGraphOption;
  selectedPaceType = 0;
  menuItems = [];
  facetViews = [];
  selectedView = '3';
  navigationDate;
  yearRange;
  paceGraphTypes = [{ label: "Occupancy", value: 0 }, { label: "Revenue", value: 1 }, { label: "ARR", value: 2 }];
  constructor(private rmsService: RmsService, private storageService: StorageService, private authService: AuthenticationService,
    private router:Router, private eventMgmt:EventManagementService) {
    this.today = new Date();
    this.today = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0, 0);
    this.navigationDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate(), 0, 0, 0, 0);
    let dt = new Date();
    this.yearRange = ""+dt.getFullYear();
    dt.setFullYear(dt.getFullYear()+2);
    this.yearRange += ":"+dt.getFullYear();
    this.hotelContext = this.storageService.get(StorageService.userHotelContext)
    this.domainHotel = authService.getDomainHotel(this.hotelContext.HotelId);
    this.currencySymbol = Utilities.findCurrencySymbol(this.domainHotel.Currency);
    // this.facetViews = [{label:"Revenue Target",value:'0'},{label:"Pricing",value:'1'},{label:"Channel Performance",value:'2'},
    this.facetViews = [{label:"Occupancy View",value:'3'},{label:"Revenue View",value:'5'},{label:"RevPar View",value:'4'}]
    this.loadMenu();
  }

  loadMenu() {
    this.menuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose(false) },];
  }

  changeView(dropdown: Dropdown){

  }

  async populateCalendarSection(startDate: Date, bForce=false) {
    let currDate = new Date(startDate);
    if (startDate.getDay() > 0)
      currDate.setDate(currDate.getDate() - (startDate.getDay()));
    currDate = new Date(currDate.getFullYear(), currDate.getMonth(), currDate.getDate(), 0, 0, 0, 0);
    let count = 35 + (startDate.getDay() > 4 ? 7 : 0);
    this.rows = (count > 35) ? [0, 1, 2, 3, 4, 5] : [0, 1, 2, 3, 4];
    let endDate = new Date(currDate);
    endDate.setDate(endDate.getDate() + count)

    let monthChange = false;
    let prevDayMonth = currDate.getMonth(); 
    //Find RMS Hotel
    let criteria = new RMSHotelCriteria();
    //criteria.DomainId = this.storageService.get(StorageService.userDomainContext).CurrentDomainId;
    criteria.HotelIds = [this.hotelContext.HotelId];
    this.rmsService.findRMSHotel(criteria,bForce).then( rmsHotels =>{
      console.log("RMS Hotel => ", rmsHotels);
      this.totalDayOccupancy = 50;
      this.totalDayRevenue = 5000000;
      this.totalDayARR = 4000;
      if (rmsHotels && rmsHotels != null && rmsHotels.length > 0) {
        let budget = (rmsHotels[0].Budgets && rmsHotels[0].Budgets.length > 0) ?
          rmsHotels[0].Budgets.find(b => b.IsCalculated == false && b.Year == this.today.getFullYear()) : null;
        if (budget != null) {
          this.totalDayRevenue = budget.Revenue/365;
          this.totalDayOccupancy = budget.Occupancy;
          this.totalDayARR = budget.ARR;
        }
      }
    
      //Find Revenue Analytics
      let analyticsCriteria = new RevenueCalendarCriteria();
      analyticsCriteria.HotelId = this.hotelContext.HotelId;
      analyticsCriteria.StayDates = new Range<Date>();
      analyticsCriteria.StayDates.Start = currDate;
      analyticsCriteria.StayDates.End = endDate;
      analyticsCriteria.Isforecasted = false;
      this.rmsService.findRevenueAnalytics(analyticsCriteria,bForce).then(analytics =>{
        if (!analytics) analytics = [];
        analyticsCriteria.Isforecasted = true;
        this.rmsService.findRevenueAnalytics(analyticsCriteria,bForce).then(forcast =>{
          if(!forcast) forcast = [];
          this.calendarSection = [];
          for (let ctx = 0; ctx < count; ctx++ , currDate.setDate(currDate.getDate() + 1)) {
            let data = analytics.find(x => new Date(x.StayDate).toLocaleDateString() == currDate.toLocaleDateString());
            let forecastData = forcast.find(x => new Date(x.StayDate).toLocaleDateString() == currDate.toLocaleDateString());
            if(ctx == 0 || prevDayMonth != currDate.getMonth()){
              monthChange = true;
              prevDayMonth = currDate.getMonth();
            } else {
              monthChange = false;
            }
            this.calendarSection[ctx] = {
              Date: new Date(currDate),
              FormatedDate: this.datePipe.transform(currDate, "dd-MMM-yyyy"),
              Day: currDate.getDate(),
              DayDate: this.datePipe.transform(currDate, "dd-MMM"),
              Disabled: (Utilities.date_sort_asc(currDate, this.today) == -1),
              Today: (Utilities.date_sort_asc(currDate, this.today) == 0),
              Data: data,
              ForecastData: forecastData,
              ShowMonth: monthChange,
              //SeasonPace: this.findSeasonPace(rmsHotels,new Date(currDate))
            };
          }
          console.log("calendarSection => ", this.calendarSection);
          if (this.calendarSection.length > 0 && this.calendarSection.length >= startDate.getDay())
            this.currentSelected = this.calendarSection[startDate.getDay()];
          this.populatePaceGarph();
        });
      });
    });
  }

  @ViewChild("dayDetails") dayDetails;
  selectedDayDetails = [];
  isShowDetail = false;
  @ViewChild("graphvariation") graphvariation;
  showDetails(calObj,isClick=false) {
    if(this.isShowDetail && !isClick) return;
    console.log("On Click Event:", calObj);
    if(isClick){
      if(this.currentSelected != calObj){
        this.currentSelected = calObj;
        this.populatePaceGarph();
        this.setHeight();
      }
      if(this.graphvariation) this.graphvariation.refresh();
      this.isShowDetail = true;
      this.eventMgmt.sideNavChange({"hideSideNav":true});
    }
  }

  hideDetails() {
    this.currentSelected = null;
    this.isShowDetail = false;
  }

  findSeasonPace(rmsHotels, selDate) {
    let pace;
    if (rmsHotels && rmsHotels.length > 0 && rmsHotels[0].RateSeasons && rmsHotels[0].RateSeasons.length > 0) {
      let rateSeason = rmsHotels[0].RateSeasons.find(rs => Utilities.isDateInRange(selDate, rs.Validity, true));
      if (rateSeason) pace = rateSeason.Pace;
    }
    return pace;
  }

  onPaceTypeChange() {
    this.populatePaceGarph();
  }

  ngOnInit() {
    this.populateCalendarSection(this.navigationDate);
    this.setHeight();
  }

  ngAfterViewInit() {
    this.setHeight();
  }

  backgroundColor = Utilities.backgroundColor;
  populatePaceGarph() {
    if (this.currentSelected != null && this.currentSelected.Data && this.currentSelected.Data.Pace && this.currentSelected.Data.Pace.length > 0) {
      let isForecastPresent = false;
      if(this.currentSelected.ForecastData && this.currentSelected.ForecastData.Pace && this.currentSelected.ForecastData.Pace.length > 0){
        isForecastPresent = true;
      }
      let paceLabel = [];
      let paceData = [];
      let paceDataWoCan = [];
      let forcastData = [];
      console.log("Selected Pace Type:", this.selectedPaceType);
      this.currentSelected.Data.Pace.forEach(pace => {
        paceLabel.push(pace.DaysOut);
        if (this.selectedPaceType == 0) {
          paceData.push(pace.Occupancy + pace.CanOccupancy);
          paceDataWoCan.push(pace.Occupancy);
        }
        else if (this.selectedPaceType == 1)
          paceData.push(pace.Revenue);
        else if (this.selectedPaceType == 2)
          paceData.push(pace.ARR);
      });
      if(isForecastPresent){
        this.currentSelected.ForecastData.Pace.forEach(pace => {
          if (this.selectedPaceType == 0) {
            forcastData.push(pace.Occupancy + pace.CanOccupancy);
          }
          else if (this.selectedPaceType == 1)
            forcastData.push(pace.Revenue);
          else if (this.selectedPaceType == 2)
            forcastData.push(pace.ARR);
        });
      }
      this.selectedPaceGraphData = {
        labels: paceLabel,
        datasets: [{
          label: "Actual",
          borderColor: this.backgroundColor[0],
          backgroundColor: this.backgroundColor[0],
          data: paceData,
          fill: false,
        }]
      };
      // if (paceDataWoCan && paceDataWoCan.length > 0) {
      //   this.selectedPaceGraphData.datasets.push({
      //     label: this.paceGraphTypes.find(x => x.value == this.selectedPaceType).label + " w/o Can.",
      //     borderColor: this.backgroundColor[1],
      //     backgroundColor: this.backgroundColor[1],
      //     data: paceDataWoCan,
      //     fill: false,
      //   });
      // }
      if(isForecastPresent && forcastData.length >0){
        this.selectedPaceGraphData.datasets.push({
          label: "Forecasted",
          borderColor: this.backgroundColor[2],
          backgroundColor: this.backgroundColor[2],
          data: forcastData,
          fill: false,
          borderDash:[2,3]
        });
      }
      this.selectedPaceGraphOption = {
        title: {
          display: false,
          text: "",
          fontSize: 15
        }
      };
    }
  }

  sectionHeight;
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.mainsection').css('height', Utilities.getScrollHeight(true));
    //jQuery('.detailsSection').css('height', windowHeight - 110);
    this.sectionHeight = Utilities.getScrollHeight(true);
  }

  onRefresh(event){
    this.populateCalendarSection(this.navigationDate,true);
    this.setHeight();
  }

  onClose(event){
    this.router.navigate(["/rms"]);
  }

  @ViewChild('navDate') navDate;
  dateFormat = new DatePipe('en-US');
  prevMonth(event){
    this.navigationDate.setDate(this.navigationDate.getDate()-14);
    this.navDate.inputFieldValue = this.dateFormat.transform(new Date(this.navigationDate), "dd-MMM-yyyy");
    this.navDate.defaultDate = this.navigationDate;
    this.navDate.ngOnInit();
    this.ngOnInit();
  }

  nextMonth(event){
    this.navigationDate.setDate(this.navigationDate.getDate()+14);
    this.navDate.inputFieldValue = this.dateFormat.transform(new Date(this.navigationDate), "dd-MMM-yyyy");
    this.navDate.defaultDate = this.navigationDate;
    this.navDate.ngOnInit();
    this.ngOnInit();
  }

  dateChange(event) {
    this.ngOnInit();
  }

  getPercentageRevPar(revCalObj){
    let percentageChange = 0;
    if(revCalObj.ForecastData && revCalObj.ForecastData.RevPar > 0){
      let totalRevPar = this.getActualRevPar(revCalObj);
      percentageChange = ((revCalObj.Data.RevPar - totalRevPar)/totalRevPar * 100);
    }
    return (percentageChange<0?"":"+")+(new DecimalPipe("en-US").transform(percentageChange,"1.0-0"));
  }

  getPercentageARR(revCalObj){
    let percentageChange = 0;
    if(revCalObj.ForecastData && revCalObj.ForecastData.ARR > 0){
      let totalARR = this.getActualARR(revCalObj);;
      percentageChange = ((revCalObj.Data.ARR - totalARR)/totalARR * 100);
    }
    return (percentageChange<0?"":"+")+(new DecimalPipe("en-US").transform(percentageChange,"1.0-0"));
  }

  getPercentageRevenue(revCalObj){
    let percentageChange = 0;
    if(revCalObj.ForecastData && revCalObj.ForecastData.ARR > 0){
      let totalRevenue = this.getActualRevenue(revCalObj);;
      percentageChange = ((revCalObj.Data.TotalRevenue - totalRevenue)/totalRevenue * 100);
    }
    return (percentageChange<0?"":"+")+(new DecimalPipe("en-US").transform(percentageChange,"1.0-0"));
  }

  getDifferentialOccupancy(revCalObj){
    let diff = 0;
    if(revCalObj.Data)
      diff = revCalObj.Data.PaceDiffrential;
    return (diff<0?"":"+")+(diff);
  }

  getActualRevPar(data){
    let actualData = 0;
    if(data && data.ForecastData && data.ForecastData.Pace && data.ForecastData.Pace.length > 0){
      let dateDiff = Utilities.dateDifference(this.today,data.Date);
      let paceIdx = 0;
      if(dateDiff<0) dateDiff = 0;
      let pace = data.ForecastData.Pace.find(p => p.DaysOut <= dateDiff);
      if(pace) actualData = pace.RevPar;
    }
    return actualData;
  }

  getActualOccupancy(data){
    let actualData = 0;
    if(data && data.ForecastData && data.ForecastData.Pace && data.ForecastData.Pace.length > 0){
      let dateDiff = Utilities.dateDifference(this.today,data.Date);
      let paceIdx = 0;
      if(dateDiff<0) dateDiff = 0;
      let pace = data.ForecastData.Pace.find(p => p.DaysOut <= dateDiff);
      if(pace) actualData = pace.Occupancy;
    }
    return actualData;
  }

  getActualARR(data){
    let actualData = 0;
    if(data && data.ForecastData && data.ForecastData.Pace && data.ForecastData.Pace.length > 0){
      let dateDiff = Utilities.dateDifference(this.today,data.Date);
      let paceIdx = 0;
      if(dateDiff<0) dateDiff = 0;
      let pace = data.ForecastData.Pace.find(p => p.DaysOut <= dateDiff);
      if(pace) actualData = pace.ARR;
    }
    return actualData;
  }

  getActualRevenue(data){
    let actualData = 0;
    if(data && data.ForecastData && data.ForecastData.Pace && data.ForecastData.Pace.length > 0){
      let dateDiff = Utilities.dateDifference(this.today,data.Date);
      let paceIdx = 0;
      if(dateDiff<0) dateDiff = 0;
      let pace = data.ForecastData.Pace.find(p => p.DaysOut <= dateDiff);
      if(pace) actualData = pace.Revenue;
    }
    return actualData;
  }
}
