import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAppConfig } from './app-config.model';

@Injectable()

export class AppConfigService {
  //sstatic settings: IAppConfig;
  theme;
  watermarkCSS;
  service_uri;
  airbnb_auth_url;
  version;
  logo;
  name;
  appConfig : IAppConfig;
  constructor(private http: HttpClient) { }
  loadAppConfig() {
    const jsonFile = `assets/config/config.json`;
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((response: IAppConfig) => {
        this.appConfig = response;
        resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
      });
    });
  }
}

