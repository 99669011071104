import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Address, HotelCriteria } from 'mantras-api';

@Component({
    selector: 'competitormanagementcriteria',
    templateUrl: './competitormanagementcriteria.component.html',
    styleUrls: ['./competitormanagementcriteria.component.css']
})
export class CompetitorManagementCriteriaComponent implements OnInit {
    channels = [];
    @Output('onsearch') onChange: EventEmitter<any>;
    @Output('onHotelNameSearch') onHotelNameChange: EventEmitter<any>;
    @Output('selectedChannelList') selectedChannelList: EventEmitter<any>;
    @Output('clearCache') clearCache: EventEmitter<any>;

    city ;
    country;
    selectedChannel = [];
    address;
    channelCodeList;
    hotelName;
    constructor(private messageService: MessageService) {
        this.onChange = new EventEmitter();
        this.onHotelNameChange = new EventEmitter();
        this.selectedChannelList = new EventEmitter();
        this.clearCache = new EventEmitter();

        this.channels.push({ label: "Agoda", value: 'AGD' });
        this.channels.push({ label: "Booking.com", value: 'BDC' });
        this.channels.push({ label: "ClearTrip", value: 'CLT' });
        this.channels.push({ label: "Expedia", value: 'EXP' });
        this.channels.push({ label: "GoIbibo", value: 'GIB' });
        this.channels.push({ label: "Yatra.com", value: 'YAT' });
        // this.channels.push({ label: "", value: "" });
        // this.channels.push({ label: "", value: "" });
    }

    ngOnInit() {

    }
    onHotelNameSearch() {
        this.onHotelNameChange.emit(this.hotelName);
    }

    onSearch() {
        if (this.buildCriteria()) {
            this.onChange.emit(this.address);
        }
        else {
            return;
        }
    }

    public buildCriteria() {
        let returnVal = false;
        this.address = new Address();
        if (this.city && this.city != "") {
            this.address.City = this.city;
            returnVal = true;
        }
        if (this.country && this.country != "") {
            this.address.Country = this.country;
            returnVal = true;
        }
        if (this.city == undefined || this.country == undefined || this.city == "" || this.country == "" ||
            this.country == null || this.city == null) {
            this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'Error', detail: 'City & Country are mandatory requirements for this operation' });
            return false;
        }

        if (this.selectedChannel && this.selectedChannel.length > 0) {
            this.selectedChannelList.emit(this.selectedChannel);
        }
        return returnVal;
    }

    onClear() {
        this.selectedChannel = null;
        this.city = null;
        this.country = null;
        this.hotelName = null;
        this.clearCache.emit();
    }

}
