import { Component, OnInit, HostListener, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../ui-services/storage.service';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { HotelManagementService } from '../ui-services/hotelmanagement.service';
import { UserService } from '../ui-services/user.service';
import { MessageService } from 'primeng/primeng';
import { environment } from '../../environments/environment';

@Component({
  selector: 'property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.css']
})
export class PropertyComponent implements OnInit, AfterViewInit {

  propertyMenuItems;
  isReadOnly = false;
  hasSystemAccess = false;
  isLoadInventoryDialogVisible = false;
  loadInventorySelectedValue = "NextSixtyDays";
  dateRanges = [];
  channelTypes = [];
  selectedChanneltypes = [];
  hotelId;
  overrideManualEdits = "false";
  isMaximojo: boolean = true;

  @ViewChild('propertyDetails') propertyDetails;
  @ViewChild('roomTypeComp') roomTypeComp;
  @ViewChild('ratePlanComp') ratePlanComp;
  @ViewChild('taxPolicyComp') taxPolicyComp;
  @ViewChild('hotelChannelComp') hotelChannelComp;
  @ViewChild('competitorComp') competitorComp;
  @ViewChild('mantrasExchangeComp') mantrasExchangeComp;
  constructor(private router: Router, private storageService: StorageService, private service: HotelManagementService,private userService:UserService,private messageService: MessageService,private cdr: ChangeDetectorRef) {
    this.hasSystemAccess = Utilities.checkSystemAccess(this.storageService.get(StorageService.currentUser).DomainContext.Domains);
    this.hotelId = this.storageService.get(StorageService.currentUser).HotelContext.HotelId
    this.isReadOnly=userService.isReadOnly();

    this.isMaximojo = environment.vendor === 'MAXIMOJO'; 
  }

  ngOnInit() {
    this.setHeight();
    if (this.propertyDetails){
      this.propertyDetails.setHeight();
    }
  }

  ngAfterViewInit(): void {

  }

  loadMenu() {
    this.propertyMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.refresh() },
      { title: 'Load Inventory', label: 'Load Inventory', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.loadInventory() }
    ];
    return this.propertyMenuItems;
  }

  setup() {

  }

  loadInventory() {
    let today = new Date();
    let endDate = new Date(today.getFullYear(),today.getMonth(),today.getDate(),0,0,0,0);
    let startDate = new Date(today.getFullYear(),today.getMonth(),today.getDate(),0,0,0,0);
    endDate.setMonth(endDate.getMonth() + 2);
    this.dateRanges = [startDate, endDate];

    this.channelTypes = [];
    this.selectedChanneltypes = [];
    let hotelChannel = this.service.findHotelChannels(this.hotelId);
    hotelChannel.then(res =>
      res.forEach(ch => {
        if (ch.IsActive == true)
          this.channelTypes.push({ label: Utilities.findId(Utilities.channelTypes, ch.ChannelCode, ch.ChannelCode), value: ch.ChannelCode });
      }));
    this.isLoadInventoryDialogVisible = true;
  }
  onLoadInventory(event) {
    this.messageService.add({ severity: 'success', summary: 'Load Inventory',detail:'Load Inventory Started' });
    let today = new Date();
    let endDate = new Date(today.getFullYear(),today.getMonth(),today.getDate(),0,0,0,0);
    let startDate = new Date(today.getFullYear(),today.getMonth(),today.getDate(),0,0,0,0);
    if (this.loadInventorySelectedValue == "NextSixtyDays") {
      endDate.setMonth(endDate.getMonth() + 2);
    }
    if (this.loadInventorySelectedValue == "NextYear") {
      startDate = new Date(new Date().getFullYear() + 1, 0, 1,0,0,0,0);
      endDate = new Date(new Date().getFullYear() + 1, 11, 31,0,0,0,0);
    }
    if (this.loadInventorySelectedValue == "Custom") {
      startDate = this.dateRanges[0];
      endDate = (this.dateRanges.length == 2 && this.dateRanges[1] != null)?this.dateRanges[1]:this.dateRanges[0];
    }
    this.selectedChanneltypes.push("MAX");
    let response = this.service.lodInventory(this.hotelId, this.selectedChanneltypes, Utilities.convertToUTC(startDate), Utilities.convertToUTC(endDate), this.overrideManualEdits == "true");
    response.then(res => {
      this.messageService.add({ severity: 'success', summary: 'Success',detail:'Inventory Loaded Successfully' });
    })
    .catch(err => {
      this.messageService.add({ severity: 'error', summary: 'Failed',detail:'Load Inventory Failed' });
    })

    this.isLoadInventoryDialogVisible = false;
  }
  export() {

  }

  onClose() {
    this.router.navigate(["/settings"]);
  }

  async refresh() {
    //Load General Tab
    this.propertyDetails.loadData(true);
    //Load Competitor
    if (this.competitorComp)
      this.competitorComp.onRefresh(event);
    //load Room & RatePlan
    if (this.roomTypeComp)
      this.roomTypeComp.onRefresh(event);
    if (this.ratePlanComp)
      this.ratePlanComp.onRefresh(event);
    //Load TaxPolicies
    if (this.taxPolicyComp)
      this.taxPolicyComp.onRefresh(event);
    //Load Hotel Channels
    if (this.hotelChannelComp)
      this.hotelChannelComp.onRefresh(event);
    //Load Mantras eXchange tab.
    if (this.mantrasExchangeComp)
      this.mantrasExchangeComp.loadData();
  }
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true,true,false,false));
  }
  refreshMenuItems(event,src=null){
    console.log(event);
    var source = src;
    var menuItems = [];
    if(event){
      source = event[0]['source'];
      menuItems = event[1];
    }
    console.log(source);
    if(source == 'propertyDetails'){
      this.propertyMenuItems = this.loadMenu();
      if(menuItems){
        menuItems.forEach(item => {
          this.propertyMenuItems.push(item);
        });
        //To swap array elements. Here this syntax is used to take 'Close' menuitem at the end of array. 
        //[this.propertyMenuItems[2],this.propertyMenuItems[this.propertyMenuItems.length-1]] = [this.propertyMenuItems[this.propertyMenuItems.length-1],this.propertyMenuItems[2]];    
      }
    }
    if(source != 'propertyDetails'){
      this.propertyMenuItems = [];
      menuItems.forEach(item => {
        this.propertyMenuItems.push(item);
      });
    }
    this.cdr.detectChanges();
  }
  
  handleChange(e){
    try{
      var index = e.index;
      if(index == 0)  this.propertyDetails.getMenuItemData();
      if(index == 1)  this.roomTypeComp.getMenuItemData();
      if(index == 2)  this.ratePlanComp.getMenuItemData();
      if(index == 3)  this.taxPolicyComp.getMenuItemData();
      if(index == 4)  this.hotelChannelComp.getMenuItemData();
      if(index == 5)  this.competitorComp.getMenuItemData();
      if(index == 6)  this.mantrasExchangeComp.getMenuItemData();
    }catch(e){

    }
  }

}
