import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AppService, DomainProfileCriteria, UserCriteria, ExceptionCriteria2, DateRange } from 'mantras-api';
import { StorageService } from '../ui-services/storage.service';
import { AuthenticationService } from '../ui-services/authentication.service';
import { MessageService } from 'primeng/api';
import { LoadingService } from '../ui-services/loading.service';

@Component({
  selector: 'exceptionscriteria',
  templateUrl: './exceptionscriteria.component.html',
  styleUrls: ['./exceptionscriteria.component.css']
})
export class ExceptionsCriteriaComponent implements OnInit {
  freeText;
  exceptionCriteriaDateTo;
  exceptionCriteriaDateFrom;
  sessionId;
  method;
  selectedUsers = [];
  domains = [];
  users = [];
  selectedDomains;
  
  exceptionCriteria = new ExceptionCriteria2();
  constructor(private storageService: StorageService,private loadingService: LoadingService, private authenticationService: AuthenticationService, private mantras: AppService, private messageService: MessageService) {
    this.onChange = new EventEmitter();
   
   }

   @Output('onsearch') onChange: EventEmitter<any>;
  async ngOnInit() {
    let startDate = new Date();
    this.exceptionCriteriaDateFrom = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
    this.exceptionCriteriaDateTo = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 0);
 
    this.domains = [];
    //let domainCriteria = new DomainProfileCriteria();
    let domainList = await this.authenticationService.findDomains(null,true);
    domainList.forEach(domain => {
        if (domain.Id != "System")
          this.domains.push({ label: domain.Name, value: domain.Id });
      }); 
  }

  onSearch() {
    if (!this.buildCriteria()) {
      this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'Error', detail: 'Please enter some criteria' });
    }
    else {
      this.onChange.emit(this.exceptionCriteria);
    }
  }

  public buildCriteria() {
    let returnVal = false;
    this.exceptionCriteria = new ExceptionCriteria2();
    if (this.selectedDomains && this.selectedDomains.length > 0) {
      this.exceptionCriteria.DomainIdList = [];
      for (let index = 0; index < this.selectedDomains.length; index++) {
        const element = this.selectedDomains[index];
        this.exceptionCriteria.DomainIdList.push(element);
      }
      returnVal = true;
    }
    else {
      this.exceptionCriteria.DomainIdList = null;
    }
    if (this.selectedUsers && this.selectedUsers.length > 0) {
      this.exceptionCriteria.UserIdList = [];
      for (let index = 0; index < this.selectedUsers.length; index++) {
        const element = this.selectedUsers[index];
        this.exceptionCriteria.UserIdList.push(element);
      }
      returnVal = true;
    }
    else {
      this.exceptionCriteria.UserIdList = null;
    }
    if (this.method && this.method != "") {
      this.exceptionCriteria.Method = this.method;
      returnVal = true;
    }
    else {
      this.exceptionCriteria.Method = null;
    }
    if (this.sessionId && this.sessionId != "") {
      this.exceptionCriteria.SessionIdList = [];
      this.exceptionCriteria.SessionIdList = this.sessionId.split(',', 500);
      returnVal = true;
    }
    else {
      this.exceptionCriteria.SessionIdList = null;
    }
  
    if (this.exceptionCriteriaDateFrom != null && this.exceptionCriteriaDateTo != null) {
      this.exceptionCriteria.Period = new DateRange();
      let startDate = new Date(this.exceptionCriteriaDateFrom.getFullYear(), this.exceptionCriteriaDateFrom.getMonth(), this.exceptionCriteriaDateFrom.getDate(),
        this.exceptionCriteriaDateFrom.getHours(), this.exceptionCriteriaDateFrom.getMinutes(), this.exceptionCriteriaDateFrom.getSeconds());
      let endDate = new Date(this.exceptionCriteriaDateTo.getFullYear(), this.exceptionCriteriaDateTo.getMonth(), this.exceptionCriteriaDateTo.getDate(),
        this.exceptionCriteriaDateTo.getHours(), this.exceptionCriteriaDateTo.getMinutes(), this.exceptionCriteriaDateTo.getSeconds());
      this.exceptionCriteria.Period.Start = startDate;
      this.exceptionCriteria.Period.End = endDate;
      returnVal = true;
    }
    else {
      this.exceptionCriteria.Period = null;
    }

    if (this.freeText && this.freeText != "") {
      this.exceptionCriteria.Text = this.freeText;
      returnVal = true;
    }
    else {
      this.exceptionCriteria.Text = null;
    }
    return returnVal;
  }

  bindCriteria(criteria) {
    if (criteria.Period) {
      this.exceptionCriteriaDateFrom = criteria.Period.Start;
      this.exceptionCriteriaDateTo = criteria.Period.End;
    }
    this.method = criteria.Method;
    this.selectedDomains = criteria.DomainIdList;
    this.selectedUsers = criteria.UserIdList;
    this.sessionId = criteria.SessionIdList;
    
  }

  async onChangeDomain(event)  {
    //To fetch user list from domain  
    if (this.selectedDomains.length < 1) {
      this.users = [];
      this.selectedUsers = [];
      return;
    }    
    let userCriteria = new UserCriteria();
    this.users = [];
    this.selectedUsers = [];
    this.selectedDomains.forEach(async domainId => {
      userCriteria.DomainId = domainId;
      let response = await this.mantras.FindDomainUsers(userCriteria);
      response.forEach(user => {
        if(!this.users.includes(user))
        this.users.push({ label: user.FirstName + user.LastName, value: user.Id });
      });
    });
  }

  onClear() {
    this.selectedDomains = [];
    this.selectedUsers = [];
    this.method = null;
    this.sessionId = [];
    this.freeText = null;
  }


}
