import { Component, OnInit, ViewChild, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { StorageService } from '../ui-services/storage.service';
import { MenuItem, MessageService } from 'primeng/primeng';
import { Router } from '@angular/router';
import { BookingAnalyticsCriteria, BookingTrend } from 'mantras-api';
import { DateRange } from 'mantras-api';
import { Utilities } from '../ui-services/utilities';
import { GridOptions } from 'ag-grid';
import { AnalyticsService } from '../ui-services/analytics.service';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { EventManagementService } from '../ui-services/eventmanagement.service';
import { LoadingService } from '../ui-services/loading.service';

import * as jQuery from 'jquery';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';

@Component({
  selector: 'trendanalytics',
  templateUrl: './trendanalytics.component.html',
  styleUrls: ['./trendanalytics.component.css']
})
export class TrendanalyticsComponent implements OnInit, AfterViewInit,OnDestroy {
  bookingCriteria = new BookingAnalyticsCriteria();
  hotels = [];
  channels = [];
  status = [];
  rateformat = [];
  bookingStatus;
  creationDateRange=[];
  selectedCreatedDateFrom: Date;
  selectedCreatedDateTo: Date;
  checkInDateRange;
  selectedCheckinFrom: Date;
  selectedCheckinTo: Date;
  filteredChannels = [];
  criteraChannels = [];
  items: MenuItem[];
  trendAnalyticsMenuItems: MenuItem[];
  columnDefs: any[];
  rowData: any[];
  rowGroupPanelShow: any;
  gridOptions: GridOptions;
  icons;
  rowSelection: any;

  grouping = 1;
  groupingOptions;
  isResultsRendered = false;
  subscription: Subscription;
  @ViewChild("criteriaDiv") criteriaDiv;
  @ViewChild("trendChart") trendChart;

  options = [];
  selectedView = {label:"Graph View",value:1};
  trendOptions;
  trendData;
  filteredOn = 0;
  filterOptionsMap;
  filterSubscription: Subscription;
  trendAnalyticsData = [];
  chartHeight="400px";
  constructor(private storageService:StorageService,private router:Router, private analyticsService:AnalyticsService,
    private eventManager:EventManagementService, private loadingService:LoadingService,private messageService:MessageService ) { 
    let domains = this.storageService.get(StorageService.currentUser).DomainContext.Domains;
    domains.forEach(domain => {
      if(domain.DomainHotels){
        domain.DomainHotels.forEach(domainHotel => {
          this.hotels.push({ label: domainHotel.Name, value: domainHotel.Id });
        });
      }
    });
    Utilities.channelTypes.forEach(ch => {
      this.channels.push({ label: ch.Name, value: ch.Id });
    });

    this.status.push({ label: "All", value: null });
    this.status.push({ label: "Provisional", value: 1 });
    this.status.push({ label: "Confirmed", value: 2 });
    this.status.push({ label: "Cancelled", value: 3 });

    this.groupingOptions = [{label: "Created Date", value:1},{label: "Checkin Date", value:2}];
    let nowDate = new Date();
    this.selectedCreatedDateTo = Utilities.getUTCDate(nowDate);
    nowDate.setMonth(nowDate.getMonth()-6);
    nowDate.setDate(nowDate.getDate()+1);
    this.selectedCreatedDateFrom = Utilities.getUTCDate(nowDate);
    this.creationDateRange.push(this.selectedCreatedDateFrom);
    this.creationDateRange.push(this.selectedCreatedDateTo);
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    this.filteredChannels = [];
    hotelContext.ChannelCodes.forEach(channel => {
      this.filteredChannels.push({ value: channel, label: Utilities.findId(Utilities.channelTypes, channel, channel) })
    });
    this.criteraChannels = hotelContext.ChannelCodes;
    this.items = [{label: "Analytics",icon:'fa fa-line-chart', routerLink:["/analytics"]},
    {label: "Trend Analytics", routerLink:["/analytics/trendanalytics"]}];

    this.columnDefs = [
      { headerName: "", cellClass: 'gridcol', showRowGroup: true, width: 50, menuTabs: [] },
      { headerName: "Channel", field: "ChannelCode", enableRowGroup: true, valueFormatter: formatChannelType, width: 100, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Month", field: "Date", enableRowGroup: true, valueFormatter: formatDate, width: 100, menuTabs: [], cellClass: 'gridcol'},
      { headerName: "LOS (Avg)", field: "AverageLOS", enableRowGroup: true, width: 50, menuTabs: [], cellClass: 'gridcol rightalign' },
      { headerName: "Adv Purchase (Avg)", field: "AverageAdvancePurchase", valueFormatter: formatNumber, enableRowGroup: true, width: 100, menuTabs: [], cellClass: 'gridcol rightalign' },
      { headerName: "Nights (Avg)", field: "AverageRoomNights", width: 50, menuTabs: [], enableRowGroup: true, cellClass: 'gridcol rightalign' },
      { headerName: "Net Rate (Avg)", field: "AverageDailyNetAmount", valueFormatter: formatNumber, width: 100, menuTabs: [], enableRowGroup: true, cellClass: 'gridcol rightalign' },
      { headerName: "Total Nights", field: "TotalRoomNights", enableRowGroup: true, width: 50, menuTabs: [], cellClass: 'gridcol rightalign' },
      { headerName: "Total Revenue", field: "TotalRevenue", valueFormatter: formatNumber, enableRowGroup: true, width: 100, menuTabs: [], cellClass: 'gridcol rightalign' },
      { headerName: "", menuTabs: [] }
    ];

    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    this.rowGroupPanelShow = "always";
    this.rowSelection = "single";

    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      showToolPanel: false,
      localeText: { noRowsToShow: "No records(s) found" }
    };

    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
    });
    this.renderMenu();

    this.options = [{label:"Graph View",value:1},{label:"Grid View",value:0}];
    this.filterOptionsMap = { 0: {label:"Total Revenue",propName:"TotalRevenue"}, 
      1: {label:"Avg. Daily Net Rate",propName:"AverageDailyNetAmount"}, 
      2: {label:"Avg. Adv. Purchase",propName:"AverageAdvancePurchase"}, 
      3: {label:"Avg. LOS",propName:"AverageLOS"}, 
      4: {label:"Total Room Nights",propName:"TotalRoomNights"}, 
      5: {label:"Avg. Room Nights",propName:"AverageRoomNights"}};
  }

  renderMenu(){
    this.trendAnalyticsMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onSearch() },
      { title: 'Export to excel', label: 'Export', icon: 'action-bar-menu-icon fa fa-file-excel-o', disabled:!this.isResultsRendered, command: (event) => this.onExport()},
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
    ];
  }

  ngOnInit() {
  }

  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }

  onGridSizeChanged($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
  }

  buildCriteria() {
    let returnVal=false;
    this.bookingCriteria = new BookingAnalyticsCriteria();
    if (!this.bookingCriteria.HotelIds) {
      let hotelId = this.storageService.get(StorageService.currentUser).HotelContext.HotelId;
      this.bookingCriteria.HotelIds = [];
      this.bookingCriteria.HotelIds.push(hotelId);
    }

    if (this.creationDateRange) {
      this.bookingCriteria.CreateDate = new DateRange();
      let end = new Date(this.creationDateRange[1] == null ? this.creationDateRange[0] : this.creationDateRange[1]);
      end.setDate(end.getDate() + 1);
      this.bookingCriteria.CreateDate.End = (end);
      this.bookingCriteria.CreateDate.Start = (new Date(this.creationDateRange[0]));
      returnVal=true;
    }
    
    if (this.checkInDateRange) {
      this.bookingCriteria.InDates = new DateRange();
      let endDate = new Date(this.checkInDateRange[1] == null ? this.checkInDateRange[0] : this.checkInDateRange[1]);
      endDate.setDate(endDate.getDate() + 1);
      console.log(endDate);
      this.bookingCriteria.InDates.Start = Utilities.convertToUTC(this.checkInDateRange[0]);
      this.bookingCriteria.InDates.End = new Date(endDate);
      returnVal=true;
    }

    if (this.criteraChannels && this.criteraChannels.length > 0) {
      if(this.criteraChannels.length != this.filteredChannels.length)
        this.bookingCriteria.ChannelCodes = this.criteraChannels;
      else
        this.bookingCriteria.ChannelCodes = null;
    }

    if(this.bookingStatus != null && this.bookingStatus != undefined)
      this.bookingCriteria.Status = this.bookingStatus;

    this.bookingCriteria.GroupingOptions = this.grouping;
    return returnVal;
  }

  async onSearch(){
    if(!this.buildCriteria()){
      this.messageService.add({key:'error', severity: 'error',life:2000, summary: 'Error',detail:'Please enter Booking Dates or Checkin Dates' });
    }else{
    this.isResultsRendered = false;
    let analyticsResponse = await this.analyticsService.getBookingTrends(this.bookingCriteria).then(response =>{
      if(response.length > 0) this.isResultsRendered = true;
      this.trendAnalyticsData = response;
      this.gridOptions.api.setRowData(response);
      this.populateGraphView(response);
      this.onGridReady(this);
      return response;
    });
    this.renderMenu();
    this.setHeight();
  }
  }

  onExport(){
    this.analyticsService.exportBookingTrends(this.bookingCriteria);
  }

  onClose(){
    this.router.navigate(["/analytics"]);
  }

  criteriaDivHeight = 95;
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    if(this.criteriaDiv.nativeElement.offsetHeight != 0)
      this.criteriaDivHeight = this.criteriaDiv.nativeElement.offsetHeight; 
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true,false,false,false,(this.showCriteria?this.criteriaDivHeight:0)));
    jQuery('.mainsection').css('height',  Utilities.getScrollHeight(true,false,false,false,(this.showCriteria?this.criteriaDivHeight:0)));
    this.chartHeight = String(Utilities.getScrollHeight(true,false,false,false,(this.showCriteria?this.criteriaDivHeight:0)))+"px";
    if(this.trendChart) this.trendChart.refresh();
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }
  @ViewChild('analyticsFilter') analyticsFilter;
  onViewChange(){
    this.isFilterEnabled = (this.selectedView.value == 1 && this.trendAnalyticsData.length > 0);
    this.analyticsFilter.setupAnalyticsFilter({
      isInTrendAnalytics:true, showFilter: (this.selectedView.value == 1 && this.trendAnalyticsData.length > 0), selectedTrendField: this.filteredOn, channelTypes: this.channelTypes ,selectedChannelTypes :this.selectedChannelTypes});
  }
  onAnalyticsFilterChange(filterData){
    this.filteredOn = filterData["selectedTrendField"];
    this.selectedChannelTypes = filterData["selectedChannelTypes"];
    this.populateGraphView(this.trendAnalyticsData);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  backgroundColor = Utilities.backgroundColor;
  selectedChannelTypes;
  channelTypes = [];
  populateGraphView(trendData:BookingTrend[]) {
    let labels = [];
    let dataSet = [];
    this.channelTypes = [];
    let datePipe = new DatePipe('en-US');
    let channelGrouping = new Map();

    if (trendData && trendData.length > 0) {
      for (let i = 0; i < trendData.length; i++) {
        let trend = trendData[i];
        if(labels.indexOf(trend.Date) < 0)
          labels.push(trend.Date);
        let channelDateGroup = channelGrouping.get(trend.ChannelCode);
        if(channelDateGroup == null) {
          channelDateGroup = new Map();
          channelGrouping.set(trend.ChannelCode,channelDateGroup);
          this.channelTypes.push(trend.ChannelCode);
        }
        channelDateGroup.set(trend.Date,trend[this.filterOptionsMap[this.filteredOn].propName]); 
      }
      if (labels.length > 0) {
        labels.sort(Utilities.date_sort_asc);
        let index = 1
        let allData = new Array(labels.length);
        let allDataCount = new Array(labels.length);
        let finalAverageData = new Array(labels.length);
        let finalSumData = new Array(labels.length);
        if(this.selectedChannelTypes == null || this.selectedChannelTypes == undefined)
          this.selectedChannelTypes = this.channelTypes;
        if(channelGrouping.size > 0){
          for (let key of Array.from(channelGrouping.keys())) {
            let channelData = new Array<Number>(labels.length);
            let channelDataGroup = channelGrouping.get(key);
            if(channelDataGroup.size > 0){
              for (let date of Array.from(channelDataGroup.keys())) {
                let idx = labels.indexOf(date)
                channelData[idx]=channelDataGroup.get(date);
                allData[idx] = (allData[idx]?allData[idx]:0) + channelDataGroup.get(date);
                allDataCount[idx] = (allDataCount[idx]?allDataCount[idx]:0) + 1
              }
            }
            if(this.selectedChannelTypes.indexOf(key) >= 0)
            dataSet.push({label:Utilities.findId(Utilities.channelTypes,key,key),data:channelData,borderColor: this.backgroundColor[index],backgroundColor: this.backgroundColor[index], fill:false});
            index++;
          }
          let ctr = 0;
          allData.forEach(data => {
            if(allDataCount[ctr] > 0){
              finalSumData[ctr] = data;
              finalAverageData[ctr] = data/allDataCount[ctr];
            }
            ctr++;
          });
          dataSet.push({label:"Average",data:finalAverageData,borderColor: "#000000",backgroundColor: "#000000", borderDash:[2,3], fill:false});
          dataSet.push({label:"Sum",data:finalSumData,borderColor: this.backgroundColor[index],backgroundColor: this.backgroundColor[index], borderDash:[3,4], fill:false});
        }
      }
      this.trendData = {};
      this.trendData["labels"] = this.formatLabel(labels);
      this.trendData["datasets"] = dataSet;
      this.trendOptions = {
        title: {
          display: true,
          text: this.filterOptionsMap[this.filteredOn].label,
          fontSize: 15
        },
        legend: {
          position: 'bottom'
        }
      };
    }
    this.onViewChange()
  }

  formatLabel(labels){
    let formatedLabel = [];
    labels.forEach(dateLabel => {
      formatedLabel.push(formatDate({value:dateLabel}));
    });
    return formatedLabel;
  }
  
  showCriteria = true;
  hideShowCriteria(){
    this.showCriteria = !this.showCriteria;
    this.setHeight();
  }

  isFilterEnabled = false;
  showFilter = false;
  hideShowFilterCriteria(){
    if(this.isFilterEnabled)
      this.showFilter = !this.showFilter;;
  }
  onClear(){
    this.criteraChannels = this.storageService.get(StorageService.currentUser).HotelContext.ChannelCodes;
    this.creationDateRange = null;
    this.checkInDateRange = null;
    this.grouping = 1;
    this.bookingStatus = null;
  }
}

function formatDate(param) {
  return new DatePipe('en-US').transform(param.value, "MMM-yy");
}

function formatChannelType(param) {
  return Utilities.findId(Utilities.channelTypes, param.value, param.value);
}

function formatNumber(param) {
  return parseFloat(param.value).toFixed(2);
}