import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ButtonModule, MenuItem, AccordionModule, Accordion, Fieldset, MessageService } from 'primeng/primeng';
import { DatePipe } from '@angular/common';
import { StorageService } from '../ui-services/storage.service';
import { Subscription } from 'rxjs';
import { UsageComponent } from '../usage/usage.component';
import { AuthenticationService } from '../ui-services/authentication.service';
import { DomainHotel, BookingCriteria, AppService, UserCriteria, DomainProfileCriteria, UsageCriteria2, UsageType, DateRange } from 'mantras-api';
import { Response } from '@angular/http/src/static_response';
import { Utilities } from '../ui-services/utilities';
import { LoadingService } from '../ui-services/loading.service';
import { ThrowStmt } from '@angular/compiler';



@Component({
  selector: 'usagecriteria',
  templateUrl: './usagecriteria.component.html',
  styleUrls: ['./usagecriteria.component.css']
})
export class UsageCriteriaComponent implements OnInit {
  startDate: any;
  endDate: any;
  sessionId;
  errorsOnly;
  selectedType;
  users = [];
  defaultDate: string;
  usage: UsageComponent;
  usageCriteriaDateFrom: Date;
  usageCriteriaDateTo;
  selectedDomains;
  domains = [];
  selectedUsers;
  subscription: Subscription;
  hotelContext;
  usageCriteria = new UsageCriteria2();
  selectedUser = [];
  types = Utilities.typeUsage;
  selectedMethod: any;
  methods = [];

  @Output('onsearch') onChange: EventEmitter<any>;
  constructor(private storageService: StorageService, private loadingService: LoadingService, private messageService: MessageService, private authenticationService: AuthenticationService, private mantras: AppService) {
    this.onChange = new EventEmitter();
  }

  onSearch() {
    if (!this.buildCriteria()) {
      this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'Error', detail: 'Please enter some criteria' });
    }
    else {
      this.onChange.emit(this.usageCriteria);
    }
  }



  public buildCriteria() {
    let returnVal = false;
    this.usageCriteria = new UsageCriteria2();
    if (this.selectedDomains && this.selectedDomains.length > 0) {
      this.usageCriteria.DomainIdList = [];
      for (let index = 0; index < this.selectedDomains.length; index++) {
        const element = this.selectedDomains[index];
        this.usageCriteria.DomainIdList.push(element);
      }
      returnVal = true;
    }
    if (this.selectedUsers && this.selectedUsers.length > 0) {
      this.usageCriteria.UserIdList = [];
      for (let index = 0; index < this.selectedUsers.length; index++) {
        const element = this.selectedUsers[index];
        this.usageCriteria.UserIdList.push(element);
      }
      returnVal = true;
    }
    if (this.selectedType != undefined && this.selectedType >= 0) {
      this.usageCriteria.Type = this.selectedType;
      returnVal = true;
    }
    else {
      this.usageCriteria.Type = null;
    }
    if (this.selectedMethod && this.selectedMethod != "") {
      this.usageCriteria.Method = this.selectedMethod;
      returnVal = true;
    } else {
      this.usageCriteria.Method = null;
    }
    if (this.sessionId && this.sessionId != "") {
      this.usageCriteria.SessionIdList = [];
      this.usageCriteria.SessionIdList = this.sessionId.split(',', 500);
      returnVal = true;
    }
    else {
      this.usageCriteria.SessionIdList = null;
    }
    if (this.errorsOnly) {
      this.usageCriteria.OnlyErrors = this.errorsOnly;
      returnVal = true;
    }
    else {
      this.usageCriteria.OnlyErrors = false;
    }
    if (this.usageCriteriaDateFrom != null && this.usageCriteriaDateTo != null) {
      this.usageCriteria.Period = new DateRange();
      this.startDate = new Date(this.usageCriteriaDateFrom.getFullYear(), this.usageCriteriaDateFrom.getMonth(), this.usageCriteriaDateFrom.getDate(),
        this.usageCriteriaDateFrom.getHours(), this.usageCriteriaDateFrom.getMinutes(), this.usageCriteriaDateFrom.getSeconds());
      this.endDate = new Date(this.usageCriteriaDateTo.getFullYear(), this.usageCriteriaDateTo.getMonth(), this.usageCriteriaDateTo.getDate(),
        this.usageCriteriaDateTo.getHours(), this.usageCriteriaDateTo.getMinutes(), this.usageCriteriaDateTo.getSeconds());
      this.usageCriteria.Period.Start = this.startDate;
      this.usageCriteria.Period.End = this.endDate;
      returnVal = true;
    }
    else {
      this.usageCriteria.Period = null;
    }
    return returnVal;
  }

  bindCriteria(criteria) {
    if (criteria.Period) {
      this.usageCriteriaDateFrom = criteria.Period.Start;
      this.usageCriteriaDateTo = criteria.Period.End;
    }
    this.selectedType = criteria.Type;
    this.selectedMethod = criteria.Method;
    this.selectedDomains = criteria.DomainIdList;
    this.selectedUsers = criteria.UserIdList;
    this.sessionId = criteria.SessionIdList;
    this.errorsOnly = criteria.OnlyErrors;
  }

  onClear() {
    this.selectedDomains = [];
    this.selectedUsers = [];
    this.selectedType = 2;
    this.selectedMethod = null;
    this.sessionId = [];
    this.errorsOnly = false;
  }


  async ngOnInit() {
    this.domains = [];
    //let domainCriteria = new DomainProfileCriteria();
    let domainList = await this.authenticationService.findDomains(null, true);
    domainList.forEach(domain => {
      if (domain.Id != "System")
        this.domains.push({ label: domain.Name, value: domain.Id });
    });

    let startDate = new Date();
    this.usageCriteriaDateFrom = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
    this.usageCriteriaDateTo = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 0);
    this.selectedType = 2;
    this.onChangeType();
  }

  async onChangeDomain(event) {
    //To fetch user list from domain   
    if (this.selectedDomains.length < 1) {
      this.users = [];
      this.selectedUsers = [];
      return;
    }
    let userCriteria = new UserCriteria();
    this.selectedUsers = [];
    this.users = [];
    this.selectedDomains.forEach(async domainId => {
      userCriteria.DomainId = domainId;
      let response = await this.mantras.FindDomainUsers(userCriteria);
      response.forEach(user => {
        if (!this.users.includes(user))
          this.users.push({ label: user.FirstName + user.LastName, value: user.Id });
      });
    });
  }

  onChangeType() {
    //this.selectedMethod = "All";
    this.methods = [];
    Utilities.methodUsage.forEach(meth => {
      if (this.selectedType == meth.type_id)
        this.methods.push({ label: meth.name, value: meth.value, id: meth.type_id });
    });
  }

}
