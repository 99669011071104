import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { SyncLogCriteria, BulkRateCalendarItems, SyncOption } from 'mantras-api';
import { StorageService } from './storage.service';
import { RateCalendarCriteria, AppService } from 'mantras-api';
import { Utilities } from './utilities';
import { LoadingService } from './loading.service';
import { TaskbarService } from './taskbar.service';
import { RoomPricingModel } from 'mantras-api';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from './authentication.service';
import { HotelManagementService } from './hotelmanagement.service';
import { AppConfigService } from '../app-config.service';

@Injectable()
export class RatecalendarService {

  //mantras:AppService;
  rrpFilter: RRPCellFilter;
  maxOccupancy;
  constructor(private mantras:AppService,private http: Http, private storageService: StorageService, private utilities:Utilities,
    private loadingService:LoadingService,private appConfService:AppConfigService,private taskService:TaskbarService, private authenticationService:AuthenticationService,
    private hotelMgmtService:HotelManagementService) {
      Utilities.initApp(mantras,appConfService);
      this.rrpFilter = new RRPCellFilter()
      this.rrpFilter.setToDefault();
      this.maxOccupancy = 3;
  }

  getRateCalendar() {
      console.log("Inside the getRateCalendar.");
    return this.http.get('/assets/static/ratecalendar.json')
        .toPromise().then((response: Response) => {
            // login successful if there's a jwt token in the response
            let rateCalendar = response.json();
            console.log(JSON.stringify(rateCalendar))
            return rateCalendar;
        });
    }

    getRateCalendarData(rateCalCriteria: RateCalendarCriteria) {
        console.log("Inside the getRateCalendar.");
        let roomRatePlans;
        //return this.http.get('/assets/static/channeltyperesp/master.json')
        return this.loadingService.interceptor(this.mantras.findRateCalendar(rateCalCriteria).then
          (response => {
              return response;
        }));
    }

    //Need to get the Hotel Rooms and Rateplans. '
    //Run a loop on the room an rate plans and generate a collection for each channelType
    extractRoomRatePlans(response, rateCalCriteria:RateCalendarCriteria,displayDates,remoteResponse,filterRooms?:any[],filterRatePlans?:any[],totalHotelAvailability?:any[]){
        let roomRatePlans= this.storageService.get(StorageService.userHotelContext).RoomRatePlans;
        let rooms= this.storageService.get(StorageService.userHotelContext).RoomTypes;
        rooms = rooms.sort(Utilities.sortByMutipleFields("sortOrder","Name"));
        let ratePlans = this.storageService.get(StorageService.userHotelContext).RatePlans;
        ratePlans = ratePlans.sort(Utilities.sortByMutipleFields("sortOrder","Name"));
        let domainHotel = this.authenticationService.getDomainHotel(rateCalCriteria.HotelId);
        let hotelChannel = this.authenticationService.getHotelChannels(rateCalCriteria.HotelId);
        let rrp: Map<any,any>  = new Map<any,any>();
        let oneDay = 1000*60*60*24;
        let numberOfDays = Math.round((rateCalCriteria.StayDates.End.getTime() - rateCalCriteria.StayDates.Start.getTime())/oneDay)+1;
        console.log("Number of days: " + numberOfDays);
        rateCalCriteria.ChannelCodes.forEach(channelType =>{
            //Check the channelType is Master (MAX)
            let mapRooms = new Map();
            let mapRatePlans = new Map();
            //Sorting to be done on Room and Rate Plan sort order.
            for(let room of rooms){
                for(let rate of ratePlans){
                    let roomRatePlan = roomRatePlans.find(rrp => (rrp.RoomId == room.Id && rrp.RatePlanId == rate.Id));
                    if(!roomRatePlan) continue;
                    let roomId = roomRatePlan.RoomId;
                    let ratePlanId = roomRatePlan.RatePlanId;
                    let pricingModel = domainHotel.PricingModel;
                    let maxOccupancy = room.MaxOccupancy;
                    let baseOccupancy = room.BaseOccupancy;
                    let occupancyLinking = false;
                    let linkedOccupancies = [];
                    let roomRatePlanMap = null;
                    //Rate plan level linking
                    if (rate.LinkedId) occupancyLinking = true;
                    //Room level linking
                    if (room.LinkedId) occupancyLinking = true;
                    if(roomRatePlan.LinkedOccupancy && roomRatePlan.LinkedOccupancy.length){
                        linkedOccupancies = roomRatePlan.LinkedOccupancy;
                    }
                    //Room and RatePlan filters
                    if(filterRatePlans && filterRatePlans.indexOf(ratePlanId) < 0){
                        continue;
                    }
                    if(filterRooms && filterRooms.indexOf(roomId) < 0){
                        continue;
                    }
                    let ratePlanName = "";
                    let roomName = "";
                    let found = true;
                    let channelRooms;
                    let channelRatePlans;
                    let hotelChannelData;
                    if(channelType != "MAX"){
                        found = false;
                        if(mapRooms.get(roomId) == null){
                            let chRooms = this.findRoomChannel(rooms,roomId,channelType);
                            mapRooms.set(roomId,(chRooms)?chRooms:"");
                        }
                        channelRooms = mapRooms.get(roomId);
                        if(channelRooms != "")
                        {
                            found = true;
                            roomId = channelRooms[0].Id;
                            roomName = channelRooms[0].Name;
                        }
                        if(mapRatePlans.get(ratePlanId) == null){
                            let chRatePlans = this.findRatePlanChannel(ratePlans,ratePlanId,channelType);
                            mapRatePlans.set(ratePlanId,(chRatePlans)?chRatePlans:"");
                        }
                        channelRatePlans = mapRatePlans.get(ratePlanId);
                        if(found && channelRatePlans != ""){
                            ratePlanId = channelRatePlans[0].Id;
                            ratePlanName = channelRatePlans[0].Name;
                        }
                    }
                    //Set BaseOccupancy, MaxOccupancy and PricingModel for Channel Type
                    if(found && channelType != "MAX"){
                        if(hotelChannel && hotelChannel.length >0){
                            hotelChannelData = hotelChannel.find(o => o.ChannelCode == channelType);
                            if(hotelChannelData && hotelChannelData != null){
                                pricingModel = hotelChannelData.PricingModel;
                                if(hotelChannelData.RoomRatePlanPricing && hotelChannelData.RoomRatePlanPricing.length > 0){
                                    let rrpp = hotelChannelData.RoomRatePlanPricing.find(rrpp => rrpp.RoomId == roomId && rrpp.RatePlanId == ratePlanId);
                                    if(rrpp) maxOccupancy = rrpp.MaxOccupancy;
                                }
                                roomRatePlanMap = hotelChannelData.RoomRatePlanMap;
                            }
                        }
                    }
                    if(found){
                        let array = new Array();
                        let gcd = new GridCellData();
                        gcd.roomTypeId = roomId;
                        gcd.ratePlanId = ratePlanId;
                        gcd.masterRoomTypeId = room.Id;
                        gcd.masterRatePlanId = rate.Id;
                        gcd.roomName = roomName;
                        gcd.ratePlanName = ratePlanName;
                        gcd.roomRatePlans = array;
                        gcd.roomRatePlansRemote = new Array();
                        gcd.channelType = channelType;
                        gcd.pricingModel = pricingModel;
                        gcd.baseOccupancy = baseOccupancy;
                        gcd.maxOccupancy = maxOccupancy;
                        gcd.occupancyLinking = occupancyLinking;
                        gcd.linkedOccupancies = linkedOccupancies;
                        if(channelType != "MAX" && (channelRooms.length >= 1 || channelRatePlans.length >= 1)){
                            for(let idx =0;idx < channelRooms.length; idx++){
                                for(let co = 0; co < channelRatePlans.length; co++){
                                    //if(idx == 0 && co == 0) continue;
                                    if(roomRatePlanMap &&
                                        roomRatePlanMap[channelRooms[idx].Id+"_"+channelRatePlans[co].Id] == room.Id+"_"+rate.Id){
                                        roomId = channelRooms[idx].Id;
                                        roomName = channelRooms[idx].Name;
                                        ratePlanId = channelRatePlans[co].Id;
                                        ratePlanName = channelRatePlans[co].Name;
                                        if(hotelChannelData && hotelChannelData != null && idx == 0 && co == 0){
                                            if(hotelChannelData.RoomRatePlanPricing && hotelChannelData.RoomRatePlanPricing.length > 0){
                                                let rrpp = hotelChannelData.RoomRatePlanPricing.find(rrpp => rrpp.RoomId == roomId && rrpp.RatePlanId == ratePlanId);
                                                if(rrpp) maxOccupancy = rrpp.MaxOccupancy;
                                                else maxOccupancy = room.MaxOccupancy;
                                            }
                                        }
                                        let roomGCD = gcd.clone();
                                        roomGCD.roomTypeId = roomId;
                                        roomGCD.ratePlanId = ratePlanId;
                                        roomGCD.roomRatePlans = new Array();
                                        roomGCD.roomRatePlansRemote = new Array();
                                        roomGCD.roomName = roomName;
                                        roomGCD.ratePlanName = ratePlanName;
                                        roomGCD.maxOccupancy = maxOccupancy;
                                        rrp.set(channelType+"::"+roomId+"::"+ratePlanId,roomGCD);
                                    }
                                }
                            }
                        } else {
                            rrp.set(channelType+"::"+roomId+"::"+ratePlanId,gcd);
                        }
                    }
                }
            }
        });
        response.forEach(element => {
            let index = displayDates.map(Number).indexOf(+Utilities.getUTCDate(new Date(element.StayDate)));
            let totalAvail = new Map();
            element.RoomRatePlans.forEach(roomRatePlan => {
                //This totalHotelAvailability is to store the total hotel availability for property which inventory is stord at room level.
                // Check the room is not closed.
                if(totalHotelAvailability && element.ChannelCode == "MAX" && roomRatePlan && !domainHotel.IsInventoryAtRatePlan){
                    let aval = totalAvail.get(roomRatePlan.RoomTypeId);
                    //Don't add availability if  RRP is closed and closed out is at not at rate plan level.
                    if((!roomRatePlan.IsClosed && !domainHotel.IsCloseOutAtRatePlan) || (domainHotel.IsCloseOutAtRatePlan))
                    totalAvail.set(roomRatePlan.RoomTypeId,(aval && aval > roomRatePlan.Availability)? aval:roomRatePlan.Availability);
                }
                let gcd = rrp.get(element.ChannelCode+"::"+roomRatePlan.RoomTypeId+"::"+roomRatePlan.RatePlanId);
                if(gcd && gcd.roomRatePlans && index < numberOfDays)
                    gcd.roomRatePlans[index]=roomRatePlan;
            });
            if(totalHotelAvailability && totalAvail.size > 0 && index < numberOfDays){
                Array.from(totalAvail.values()).forEach( val =>
                    totalHotelAvailability[index] = val+(totalHotelAvailability[index]?totalHotelAvailability[index]:0)
                );
            }
        });
        remoteResponse.forEach(element => {
            let index = displayDates.map(Number).indexOf(+Utilities.getUTCDate(new Date(element.StayDate)));
            element.RoomRatePlans.forEach(roomRatePlan => {
                let gcd = rrp.get(element.ChannelCode+"::"+roomRatePlan.RoomTypeId+"::"+roomRatePlan.RatePlanId);
                if(gcd && gcd.roomRatePlans && index < numberOfDays)
                    gcd.roomRatePlansRemote[index]=roomRatePlan;
            });
        });
        return rrp;
    }

    getSortedStayDates(response){
        let stayDates = Array();
        response.forEach(rrp => {
            let date = Utilities.getUTCDate(new Date(rrp.StayDate));
            if(stayDates.map(Number).indexOf(+date) < 0)
                stayDates.push(date);
        });
        return stayDates.sort(Utilities.date_sort_asc);
    }

    saveRateCalendar(rrpItems, channelTypes, manualOverride){
        return this.loadingService.interceptor(this.mantras.saveMasterRateCalendar(rrpItems,channelTypes,manualOverride).then
        (response => {
                return response;
        }));
    }

    findRateCalendarSyncLog(syncLogCriteria: SyncLogCriteria){
        return this.loadingService.interceptor(this.mantras.findRateCalendarSyncLog(syncLogCriteria).then
        (response => {
                if(response && response.length > 0)
                response.forEach(syncLog => {
                    syncLog["GroupHeader"] = this.getGrouping(syncLog.SyncTimestamp)
                });
                return response.reverse();
        }));
    }

    getGrouping(logTimeStamp) {
        let currDate = new Date();
        let stayDate = new Date(logTimeStamp);
        let numberOfDays = Utilities.dateDifference(stayDate, currDate)
        if (currDate.getDate() == stayDate.getDate() && currDate.getMonth() == stayDate.getMonth() && currDate.getFullYear() == stayDate.getFullYear())
          return "Today";
        else if (numberOfDays < 7)
          return new DatePipe('en-US').transform(stayDate, "EEEE");
        else if (numberOfDays < 61)
          return (currDate.getMonth() == stayDate.getMonth()) ? "This Month" : new DatePipe('en-US').transform(stayDate, "MMMM");
        else if (numberOfDays < 366 && (currDate.getFullYear() == stayDate.getFullYear()))
          return "This Year";
        else
          return "Year: " + stayDate.getFullYear();
      }

    bulkEditRateCalendar(hotelId,dateRanges,bulkRates,channelTypes){
        let bulkRateCalItems = new BulkRateCalendarItems();
        bulkRateCalItems.BulkRates = bulkRates;
        bulkRateCalItems.DateRanges = dateRanges;
        return this.loadingService.interceptor(this.mantras.bulkUpdateRateCalendar(hotelId, [bulkRateCalItems], channelTypes).then
        (response => {
                return response;
        }));
    }

    reCalculate(hotelId,srcChannel,destChannels,dateRange,manualOverride){
        return this.loadingService.interceptor(this.mantras.recalculate(hotelId,srcChannel,destChannels,dateRange.Start,dateRange.End,manualOverride).then
        (response => {
            return true;
        }).catch(error => {
            this.loadingService.showErrorDialog(error);
            return false;
        }),true);
    }

    syncRateCalendar(hotelId, domainId, dateRange, syncMode, manualOverride, channelTypes){
        //rateCalCriteria
        let rateCalCriteria = new RateCalendarCriteria();
        rateCalCriteria.HotelId = hotelId;
        rateCalCriteria.DomainId = domainId;
        rateCalCriteria.ChannelCodes = channelTypes;
        let syncOption = SyncOption.OneWay;
        if(syncMode == "twoway"){
            if(manualOverride)
                syncOption = SyncOption.TwoWayForced;
            else
                syncOption = SyncOption.TwoWay;
        } else if(manualOverride) {
            syncOption = SyncOption.OneWay_Forced;
        }
        rateCalCriteria.StayDates = dateRange;
        return this.loadingService.interceptor(this.mantras.syncRateCalendar(rateCalCriteria,syncOption).then
        (response => {
                this.taskService.addTasks(response,{Start:rateCalCriteria.StayDates.Start,End:rateCalCriteria.StayDates.End});
                return response;
        }).catch(error =>{
            this.loadingService.showErrorDialog(error);
            return error;
        }));
    }

    bulkUpdateRateCalendarTask(hotelId, bulkRateItems,channelCodes,doSync){
        return this.loadingService.interceptor(
            this.mantras.bulkUpdateRateCalendarTask(hotelId, bulkRateItems,channelCodes,doSync)
            .then(response => {
                let taskIds : string[]=[response];
                let  now = new Date();
                this.taskService.addTasks( taskIds,{Start:now,End:now});
                return response;})
            .catch(error =>  {
                this.loadingService.showErrorDialog(error);
                return error;})
            );
    }

    public findRoomChannel(collection:any, searchKey:string, channelType:string){
        let channels = Utilities.findObject(collection,searchKey);
        if(channels && channels.ChannelRooms){
            //let channelRoom = channels.ChannelRooms.find(o => o.ChannelCode === channelType)
            let channelRooms = channels.ChannelRooms.filter(o => o.ChannelCode === channelType);
            if(channelRooms){
                return channelRooms;
            }
        }
        return null;
    }
    public findRatePlanChannel(collection:any, searchKey:string, channelType:string){
        let channels = Utilities.findObject(collection,searchKey);
        if(channels && channels.ChannelRatePlans){
            //let channelRatePlan = channels.ChannelRatePlans.find(o => o.ChannelCode === channelType);
            let channelRatePlans = channels.ChannelRatePlans.filter(o => o.ChannelCode === channelType);
            if(channelRatePlans){
                return channelRatePlans;
            }
        }
        return null;
    }

    public resetRRPCellFilter(domainHotel,hotelChannel,hotelContext,viewFilter?){
        let rrpCellFilter = new RRPCellFilter();
        if(!viewFilter || viewFilter.length == 0){
            rrpCellFilter.setToDefault();
            viewFilter = [];
        }
        if(viewFilter.length == 0 || (viewFilter.length >0 && (viewFilter.indexOf("perDay") >= 0 || viewFilter.indexOf("occupancy") >= 0))){
            let pricingModel = domainHotel.PricingModel;
            let maxOccupancy = 0;
            if(hotelContext.RoomTypes && hotelContext.RoomTypes.length > 0){
                hotelContext.RoomTypes.forEach(room => {
                    if(maxOccupancy < room.MaxOccupancy) maxOccupancy = room.MaxOccupancy;
                });
            }
            //Setup the Master Hotel room maxOccupancy.
            this.maxOccupancy = maxOccupancy;
            //Parse Only active channel Types present in the HotelContext.
            let channels = hotelContext.ChannelCodes;
            if(hotelChannel && hotelChannel.length > 0){
                hotelChannel.forEach(hc => {
                    if(channels.indexOf(hc.ChannelCode) >= 0){
                        if(hc.pricingModel != domainHotel.PricingModel) pricingModel = RoomPricingModel.None;
                        if(hc.RoomRatePlanPricing && hc.RoomRatePlanPricing.length > 0){
                            hc.RoomRatePlanPricing.forEach(rrpp => {
                                if(maxOccupancy < rrpp.MaxOccupancy) maxOccupancy = rrpp.MaxOccupancy;
                            });
                        }
                    }
                });
            }
            if(pricingModel != RoomPricingModel.PerOccupancyPricing)
            rrpCellFilter.perDay = (this.rrpFilter.perDay)?this.rrpFilter.perDay:false;
            if(pricingModel != RoomPricingModel.PerDayPricing && (viewFilter.length > 0 && viewFilter.indexOf("occupancy")>=0)|| viewFilter.length == 0){
                for(let c=0;c < maxOccupancy; c++){
                    if(c >= this.rrpFilter.occupancy.length) rrpCellFilter.occupancy.push(false);
                    if(c < this.rrpFilter.occupancy.length)
                        rrpCellFilter.occupancy[c] = this.rrpFilter.occupancy[c];
                    else if(c < 2) rrpCellFilter.occupancy[c] = true;
                }
            }
        }
        if(viewFilter.length > 0){
            viewFilter.forEach(field => {
                if(field != "occupancy" && field != "perDay") rrpCellFilter[field] = true;
            });
        } else {
            Object.keys(rrpCellFilter).map((field) => {
                if(field != "occupancy") rrpCellFilter[field] = (this.rrpFilter[field])?this.rrpFilter[field]:false;
            });
        }
        this.rrpFilter = rrpCellFilter;
    }

    getRateCalendarSnapshot(hotelId,dateRange){
        return this.mantras.GetRateCalendarSnapshot(hotelId,dateRange.Start,dateRange.End).then
        (response => {
            return response;
        });
    }

    exportRateCalendar(exportOption, exportCriteria:RateCalendarCriteria){
        if(exportOption == "local" || exportOption == "remote"){
            exportCriteria.IsRemote = exportOption == "remote";
            return this.loadingService.interceptor(this.mantras.exportRateCalendar(exportCriteria).then
            (response => {
                return Utilities.saveToFileSystem(response,"ExportRateCalendar.xlsx");
            }),true);
        } else {
            return this.loadingService.interceptor(this.mantras.exportRateCalendarSyncLog(exportCriteria).then
            (response => {
                return Utilities.saveToFileSystem(response,"ExportRateCalendarSyncLogs.xlsx");
            }),true);
        }
    }

    async getChannelTypes(filterChannels:string[] = []){
        let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
        let channelTypes = [];
        let channels:string[] = hotelContext.ChannelCodes;
        let domainChannels = [];
        let hotelChannels = [];
        const xchgChannelCodes: string[] = ["XBE","XB1","XB2","XB3","XB4","XB5","XCG"];
        const hasXchgChannelTypes: boolean = channels.some(c => xchgChannelCodes.some(x => c === x));
        //if(channels.indexOf("XBE")>=0||channels.indexOf("XB1") >= 0||channels.indexOf("XB2") >= 0||channels.indexOf("XB3") >= 0||channels.indexOf("XB4") >= 0||
        //channels.indexOf("XB5") >= 0||channels.indexOf("XCG") >= 0){
        if (hasXchgChannelTypes){
            domainChannels = await this.hotelMgmtService.getDomainChannels();
            hotelChannels = await this.hotelMgmtService.findHotelChannels(hotelContext.HotelId);
        }
        channels.forEach(element => {
            let label = Utilities.findId(Utilities.channelTypes,element,element);
            let channelName = "";
            if(filterChannels.length ==0 || (filterChannels.length > 0 && filterChannels.indexOf(element) >= 0)){
                if(element == "XBE" || element == "XB1" ||element == "XB2" || element == "XB3" || element == "XB4"|| element == "XB5" || element == "XCG"){
                let dcs = domainChannels.filter(dc=> dc.ChannelCode == element);
                let domainChannel = null;
                if(dcs != undefined || dcs != null){
                    if(dcs.length == 1) domainChannel = dcs[0];
                    else {
                    let hotelChannel = hotelChannels.find(hc => hc.ChannelCode == element);
                    domainChannel = dcs.find(d => d.Id == hotelChannel.ChannelId);
                    }
                }
                if(domainChannel && element != "XCG") {
                    channelName = domainChannel.Config["BookingEngine"];
                    let be = Utilities.xchangeBECodes.find(be=> be.value == channelName);
                    if(be) channelName = be.label;
                }
                if(domainChannel && element == "XCG") {
                    channelName = domainChannel.Config["PMSCode"];
                    let pc = Utilities.PMSCodes.find(pc=> pc.value == channelName);
                    if(pc) channelName = pc.label;
                }
                if(channelName && channelName.length > 0) label += "("+channelName+")";
                }
                channelTypes.push({label:label,value:element});
            }
        });
        channelTypes.sort(Utilities.sort_label_desc);
        return channelTypes;
    }
}

export class RRPCellFilter{
    perDay: boolean;
    occupancy: boolean[] = [];
    availability: boolean;
    soldRooms: boolean;
    totalInventory: boolean;
    perExtraPerson: boolean;
    extraChildren: boolean;
    minStay: boolean;
    maxStay: boolean;
    // minStayArr: boolean;
    // maxStayArr: boolean;
    minAdvPurchaseDays:boolean;
    maxAdvPurchaseDays:boolean;
    CTA: boolean;
    CTD: boolean;

    setToDefault(){
        this.perDay = true;
        this.occupancy = [true,true,false];
        this.availability = true;
        this.soldRooms = false;
        this.totalInventory = false;
        this.perExtraPerson = false;
        this.extraChildren = false;
        this.minStay = false;
        this.maxStay = false;
        // this.minStayArr = false;
        // this.maxStayArr = false;
        this.minAdvPurchaseDays=false;
        this.maxAdvPurchaseDays=false;
        this.CTA = false;
        this.CTD = false;
    }
}

export class GridCellData{
    channelType: string;
    roomTypeId: string;
    ratePlanId: string;
    ratePlanName: string;
    masterRoomTypeId: string;
    masterRatePlanId: string;
    roomName: string;
    roomRatePlans: any[];
    roomRatePlansRemote: any[];
    roomRatePlanFields: string = "all";
    pricingModel: number;
    baseOccupancy: number;
    maxOccupancy: number;
    occupancyLinking: boolean;
    linkedOccupancies: any[];

    clone(){
        let newObj = new GridCellData();
        newObj.channelType = ""+this.channelType;
        newObj.roomTypeId = ""+this.roomTypeId;
        newObj.ratePlanId = ""+this.ratePlanId;
        newObj.masterRoomTypeId = ""+this.masterRoomTypeId;
        newObj.masterRatePlanId = ""+this.masterRatePlanId;
        newObj.ratePlanName = ""+this.ratePlanName;
        newObj.roomName = ""+this.roomName;
        newObj.roomRatePlanFields = ""+this.roomRatePlanFields;
        newObj.roomRatePlans = this.roomRatePlans;
        newObj.roomRatePlansRemote = this.roomRatePlansRemote;
        newObj.pricingModel = this.pricingModel;
        newObj.baseOccupancy = this.baseOccupancy;
        newObj.maxOccupancy = this.maxOccupancy;
        newObj.occupancyLinking = this.occupancyLinking;
        newObj.linkedOccupancies = this.linkedOccupancies;
        return newObj;
    }
}
