import { Component, OnInit, HostListener, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { StorageService } from '../ui-services/storage.service';
import { Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/primeng';
import { GridOptions } from 'ag-grid';
import { UserCriteria, User, DomainUser } from 'mantras-api';
import { UserService } from '../ui-services/user.service';
import { UserdetailsComponent } from './userdetails.component';
import { Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';
import * as jQuery from 'jquery';
import { Utilities } from '../ui-services/utilities';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';
import { AuthenticationService } from '../ui-services/authentication.service';

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  isCurrentDomainIsSystem: boolean = false;
  selectedDomain: any;
  usersMenuItems: MenuItem[];
  columnDefs: any[];
  rowData: any[];
  gridOptions: GridOptions;
  rowSelection: any;
  rowGroupPanelShow: any;
  domainUsers: User[];

  showDetailPage = false;
  icons;
  isReadOnly = false;
  subscription: Subscription;
  showLoadUsers = false;
  mantrasDirectUserCheckbox = false;
  exhangeUserCheckbox = false;
  exhangeBeUserCheckbox = false;
  exchangeOptions = [];
  exchangeBeOptions = [];
  exchangeValue = 0;
  exchangeBeValue = null;
  loadUsers = [];
  domainContext;
  isSystemUser = false;
  loadUsersRequestArray = [];
  constructor(private resolver: ComponentFactoryResolver,
    private storageService: StorageService,
    private userService: UserService,
    private messageService: MessageService,
    private router: Router,
    private service: UserService,
    private authService: AuthenticationService) {
    this.isReadOnly = service.isReadOnly();
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    this.domainContext = storageService.get(StorageService.userDomainContext);
    this.columnDefs = [
      { headerName: "Login ID", field: "Id", tooltipField: "Id", width: 120, menuTabs: [], cellClass: 'gridcol', sort: "asc" },
      { headerName: "Name", field: "FirstName", tooltipField: "Name", width: 120, menuTabs: [], cellClass: 'gridcol', valueFormatter: formatUserName },
      { headerName: "Roles", field: "Roles", tooltipField: "Roles", width: 300, menuTabs: [], cellClass: 'gridcol', valueFormatter: formatRoles },
      { headerName: "Is Active", field: "IsActive", width: 50, menuTabs: [], cellClass: 'gridcol', cellRenderer: params => { return `<input type='checkbox' readonly onclick='return false;' ${params.value ? 'checked' : ''} value=${params.value ? 1 : 0}/>`; } },
      { headerName: "Description", field: "Description", tooltipField: "Description", width: 120, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "", width: 30, menuTabs: [] }
    ];

    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      showToolPanel: false,
      localeText: { noRowsToShow: "No user(s) found" }
    };
    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;

    function formatUserName(param) {
      let firstName = param.value;
      let lastName = param.data.LastName;
      return firstName + " " + lastName;
    }
    function isActive(param) {
      return param.value;
    }
    function formatRoles(param) {
      let roles = param.value;
      let role = "";
      if (roles && roles.length >= 1) {
        roles.forEach(element => {
          //Exclude  Administrators role.
          if (element != "Administrators" &&
            element != "Property Manager" &&
            element != "Revenue Manager" &&
            element != "Rate Manager")
            role += "," + element;
        });
        return role.substr(1);
      }
    }
    let currentUser = this.storageService.get(StorageService.currentUser);
        this.selectedDomain = currentUser.DomainContext.CurrentDomainId;
        if (this.selectedDomain == "System") {
          this.isCurrentDomainIsSystem = true;
        } else {
          this.isCurrentDomainIsSystem = false;
        }

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();        
      });
  }

  async findDomainUsers() {
    let criteria = new UserCriteria();
    let currUser = this.storageService.get(StorageService.currentUser);
    criteria.DomainId = currUser.DomainContext.CurrentDomainId;
    //Check if user has Admins Access to manage other users
    let hasAdminAccess = this.service.checkAccess("Admins");
    if (!hasAdminAccess) criteria.Id = currUser.User.Id;
    let response = await this.service.findDomainUsers(criteria).then(
      async response => {
        this.domainUsers = response;
        this.gridOptions.api.setRowData(response);
        this.gridOptions.api.sizeColumnsToFit();
        this.onGridReady(this);
      });
  }

  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
    let setSelected = false;
    this.gridOptions.api.forEachNode(node => {
      if (!node.group && !setSelected) { node.setSelected(true); this.onRowClick(node); setSelected = true; }
    });
    if (!setSelected) this.loadMenu();
    //this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }
  onGridSizeChanged($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
  }
  ngOnInit() {
    this.isSystemUser = this.authService.isSystemUser();
    this.loadMenu();
    this.findDomainUsers();
  }

  loadMenu() {
    this.usersMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o',visible:this.userService.checkAccess("Admins"), disabled: this.isReadOnly || this.userService.checkAccess("Admins"), command: (event) => this.createNew() },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o',visible:this.userService.checkAccess("Admins"), disabled: this.userService.checkAccess("Admins"), command: (event) => this.open(event) },
      { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o',visible:this.userService.checkAccess("Admins"), disabled: true, command: (event) => this.confirmDelete(event, true) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
      { title: 'Load Users', label: 'Load Users', icon: 'action-bar-menu-icon fa fa-user', disabled: this.isReadOnly, visible: (!this.isCurrentDomainIsSystem && this.isSystemUser), command: (event) => this.onLoadUsers() }
    ];
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true));
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  onRowClick(params) {
    if (params.data) {
      let disabledDelete = false;
      if (params.data.Id.toLowerCase() == this.storageService.get(StorageService.loginUserId)) disabledDelete = true;
      this.usersMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
        { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o',visible:this.userService.checkAccess("Admins"), disabled: this.isReadOnly, command: (event) => this.createNew() },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o',visible:this.userService.checkAccess("Admins"), command: (event) => this.open(event) },
        { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o',visible:this.userService.checkAccess("Admins"), disabled: this.isReadOnly || disabledDelete, command: (event) => this.confirmDelete(event, true) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
        { title: 'Load Users', label: 'Load Users', icon: 'action-bar-menu-icon fa fa-user', disabled: this.isReadOnly, visible: this.isSystemUser && !this.isCurrentDomainIsSystem, command: (event) => this.onLoadUsers() }
      ];
    }
  }

  onClickGridRow(params) {
    if(!this.userService.checkAccess("Admins"))
    {
      return this.messageService.addAll([{severity:'warn', summary: 'Warn Message', detail:'Only Administrators see other user`s information'}]);
    }
    if (params.data)
      this.createNew(params.data);
  }

  //Refresh Promotion Grid
  onRefresh(event) {
    this.findDomainUsers();
  }
  //OnClose
  onClose() {
    this.router.navigate(["/settings"]);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    //this.filterSubscription.unsubscribe();
  }

  //Create New Promotion
  @ViewChild('containerUserDetail', { read: ViewContainerRef }) containerUserDetail: ViewContainerRef;
  userDetailsComp;
  createNew(data?) {
    this.containerUserDetail.clear();
    let factory = this.resolver.resolveComponentFactory(UserdetailsComponent);
    this.userDetailsComp = <UserdetailsComponent>this.containerUserDetail.createComponent(factory).instance;
    if (data) this.userDetailsComp.user = data;
    else this.userDetailsComp.user = new DomainUser();
    this.userDetailsComp.loadData();
    this.userDetailsComp.onClose.subscribe(this.onDetailsClose);
    this.showDetailPage = true;
    this.userDetailsComp.setHeight();
  }
  //Open Promotion
  open(event) {
    this.createNew(this.gridOptions.api.getSelectedRows()[0]);
  }
  //Delete Promotion
  @ViewChild('showConfirmationDialog') showConfirmationDialog;
  showDeleteConfirmation = false;
  deleteId;
  confirmDelete(params, fromGrid?) {
    if (!fromGrid) {
      if (params.node.data) {
        this.deleteId = params.node.data.Id;
        this.showDeleteConfirmation = true;
      }
    } else {
      let selectedRow = this.gridOptions.api.getSelectedRows();
      if (selectedRow && selectedRow.length > 0) {
        this.deleteId = selectedRow[0].Id;
        this.showDeleteConfirmation = true;
      }
    }
  }

  closeDialog(event) {
    this.deleteId = null;
    this.showDeleteConfirmation = false;
    this.setHeight();
  }

  async delete(event) {
    this.showDeleteConfirmation = false;
    if (this.deleteId != null) {
      let finalResponse = await this.service.deleteDomainUser(this.deleteId).then(
        localresp => { return localresp; });
    }
    this.onRefresh(event);
  }

  onDetailsClose = (event): void => {
    this.showDetailPage = false;
    if (event) this.onRefresh(event);
    else this.setHeight();
  }

  onLoadUsers() {
    this.showLoadUsers = true;
    this.mantrasDirectUserCheckbox = false;
    this.exhangeUserCheckbox = false;
    this.exhangeBeUserCheckbox = false;
    this.exchangeValue = 0;
    this.exchangeBeValue = null;
    this.loadUsers = [];
    this.loadUsersRequestArray = [];
    this.exchangeOptions = this.getExchangeOptions();
    this.exchangeBeOptions = this.getExchangeBeOptions();
  }

  addMantrasDirectUser() {
    const user = new DomainUser();
    user.DomainId = this.domainContext.CurrentDomainId;
    user.IsActive = true;
    user.Roles = [];
    user.Roles.push('ReadOnlyUser');
    user.FirstName = 'MantrasDirect';
    user.LastName = 'User';
    user.Id = 'mantrasdirect@maximojo.com';
    this.loadUsers.push(user);
  }
  addExchangeUser(exchangeValue) {
    const exchangeList = this.getExchangeOptions();
    for (const exchange of exchangeList) {
      if (exchange.value === exchangeValue) {
        const user = new DomainUser();
        user.DomainId = this.domainContext.CurrentDomainId;
        user.IsActive = true;
        user.Roles = [];
        user.Roles.push('ReadOnlyUser');
        user.FirstName = exchange.FirstName;
        user.LastName = exchange.LastName;
        user.Id = exchange.Id;
        this.loadUsers.push(user);
        break;
      }
    }
  }
  addExchangeBeUser(exchangeBeValue) {
    const exchangeList = this.getExchangeBeOptions();
    for (const exchange of exchangeList) {
      if (exchangeBeValue.indexOf(exchange.value) >= 0) {
        const user = new DomainUser();
        user.DomainId = this.domainContext.CurrentDomainId;
        user.IsActive = true;
        user.Roles = [];
        user.Roles.push('ReadOnlyUser');
        user.FirstName = exchange.FirstName;
        user.LastName = exchange.LastName;
        user.Id = exchange.Id;
        this.loadUsers.push(user);
      }
    }
  }
  onSaveUsers() {
    this.loadUsersRequestArray = new Array();
    this.loadUsers = new Array();
    if (this.mantrasDirectUserCheckbox) {
      this.addMantrasDirectUser();
    }
    if (this.exhangeUserCheckbox && this.exchangeValue > 0) {
      this.addExchangeUser(this.exchangeValue);
    }
    if (this.exhangeBeUserCheckbox && this.exchangeBeValue !== null) {
      this.addExchangeBeUser(this.exchangeBeValue);
    }
    for (const user of this.loadUsers) {
      this.loadUsersRequestArray.push(this.service.saveDomainUser(user));
    }
    forkJoin(this.loadUsersRequestArray).subscribe(results => {
      this.findDomainUsers();
      this.showLoadUsers = false;
    });
  }

  onCloseLoadUsers() {
    this.showLoadUsers = false;
  }
  getExchangeOptions() {
    return [
      { label: 'None', value: 0 },
      { label: 'Hotelogix', value: 1, Id: 'hotelogix@maximojo.com', FirstName: 'Hotelogix', LastName: 'PMS' },
      { label: 'IDS', value: 2, Id: 'idsdatahub@maximojo.com', FirstName: 'IDS', LastName: 'PMS' },
      { label: 'Inn Key', value: 3, Id: 'innkeypms@maximojo.com', FirstName: 'Inn Key', LastName: 'PMS' },
      { label: 'Lucid', value: 4, Id: 'lucid@maximojo.com', FirstName: 'Lucid', LastName: 'PMS' },
      { label: 'Prologic', value: 5, Id: 'prologic@maximojo.com', FirstName: 'Prologic', LastName: 'PMS' },
      { label: 'Tripvillas', value: 6, Id: 'tripvillas@maximojo.com', FirstName: 'Tripvillas', LastName: 'PMS' },
      { label: 'WinHMS', value: 7, Id: 'winhms@maximojo.com', FirstName: 'WinHMS', LastName: 'PMS' },
    ];
  }

  getExchangeBeOptions() {
    return [
      { label: 'Direcet BE', value: 1, Id: 'directbooking@maximojo.com', FirstName: 'Direcet', LastName: 'BE' },
      { label: 'Goomo', value: 2, Id: 'goomo@maximojo.com', FirstName: 'Goomo', LastName: 'BE' },
      { label: 'GTDC', value: 3, Id: 'gtdc@maximojo.com', FirstName: 'GTDC', LastName: 'BE' },
      { label: 'Mi Stay', value: 4, Id: 'mistay@maximojo.com', FirstName: 'Mi Stay', LastName: 'BE' },
      { label: 'One Fine Rate', value: 5, Id: 'ofr@maximojo.com', FirstName: 'One Fine Rate', LastName: 'BE' },
      { label: 'Paytm', value: 6, Id: 'paytm@maximojo.com', FirstName: 'Paytm', LastName: 'BE' },
      { label: 'ResAvenue BE', value: 7, Id: 'avenue@maximojo.com', FirstName: 'ResAvenue', LastName: 'BE' },
      { label: 'Reznext', value: 8, Id: 'reznext@maximojo.com', FirstName: 'Reznext', LastName: 'BE' },
      { label: 'Simplotel', value: 9, Id: 'simplotel@maximojo.com', FirstName: 'Simplotel', LastName: 'BE' },
      { label: 'Tech Marvel', value: 10, Id: 'techmarvel@maximojo.com', FirstName: 'Tech Marvel', LastName: 'BE' },
      { label: 'Travel Spice', value: 11, Id: 'travelspice@maximojo.com', FirstName: 'Travel Spice', LastName: 'BE' },
      { label: 'TripAdvisor', value: 12, Id: 'tripconnect@tripadvisor.com', FirstName: 'TripAdvisor', LastName: 'OTA' },
      { label: 'Ease My trip', value: 13, Id: 'emt@maximojo.com', FirstName: 'Ease My Trip', LastName: 'BE' },
      { label: 'Ramoji', value: 14, Id: 'ramoji@maximojo.com', FirstName: 'Ramoji', LastName: 'BE' },
      { label: 'HappyEasyGo', value: 15, Id: 'heg@maximojo.com', FirstName: 'HappyEasyGo', LastName: 'BE' }
    ];
  }

  filteredResponse;
  searchText(event) {
    this.filteredResponse = [];
    var query = event.target.value.toLowerCase();
    let searchItems = this.domainUsers;
    if (query == "") this.filteredResponse = searchItems;
    else {
      searchItems.forEach(searchItem => {
        let isMatch = false;
        let searchString = JSON.stringify(searchItem);
        isMatch = searchString.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        if (isMatch) this.filteredResponse.push(searchItem);
      });
    }
    this.gridOptions.api.setRowData(this.filteredResponse);
    this.gridOptions.api.sizeColumnsToFit();
    this.onGridReady(this);
    this.setHeight();
  }
}
