import { Injectable } from '@angular/core';
import { Utilities } from './utilities';

@Injectable()
export class DataProviderService {
  yearsPushed = [];
  constructor() { }

  getRevenueChartData(){
    let chartData = [];
    let startDate = new Date();
    startDate.setDate(startDate.getDate()-365);
    for(let i=0;i<365;i++){
      chartData.push(this.getRevenuePerDay(new Date(startDate)));
      startDate.setDate(startDate.getDate()+1);
    }
    return chartData;
  }

  getRevenuePerDay(date){
    let channelTypes = [
      { Name: 'Agoda', Id: 0 },
      { Name: 'Booking', Id: 1 },
      { Name: 'Cleartrip', Id: 2 },
      { Name: 'Expedia', Id: 3 },
      { Name: 'Goibibo', Id: 4 },
      { Name: 'MMT', Id: 5 },
      { Name: 'Yatra', Id: 6 }];
    let selectedChannels = [];
    let data = {};
    data["date"]=date;
    let totalRevenuePerDay = 0;
    for(let j=0;j<5;j++){//Select 5 channels
      let flag = false;
      do{
        let channelIndex = Math.floor(Math.random() * 7);
        if(selectedChannels.indexOf(channelIndex)>-1){
          channelIndex = Math.floor(Math.random() * 7);
        }else{
          selectedChannels.push(channelIndex);
          let channelName = channelTypes.find(channel => channel.Id == channelIndex).Name;
          let otaRevenue = Math.floor(Math.random()*(6000-2000+1)+2000)
          totalRevenuePerDay+=otaRevenue;
          data[channelName] = otaRevenue;//Math.floor(Math.random()*(max-min+1)+min);
          flag=true;
        }
      }while(flag==false);
    }
    data["Total"]=totalRevenuePerDay;
    return data;
  }

  getMonthlyRevenue(){
    let chartData = [];
    this.yearsPushed = [];
    let startDate = new Date();
    startDate.setDate(startDate.getDate()-365);
    for(let i=0;i<12;i++){
      chartData.push(this.getRevenuePerMonth(new Date(startDate.getFullYear(),startDate.getMonth(),startDate.getDate(),0,0,0)));
      startDate.setMonth(startDate.getMonth()+1);
    }
    return chartData;
  }
  getRevenuePerMonth(date){
    let year = date.getFullYear().toString();
    let yearPush;
    if(this.yearsPushed.indexOf(year)>-1){
      yearPush = '';
    }else{
      this.yearsPushed.push(year);
      yearPush = year.substr(-2);
    }
    let channelTypes = [
      { Name: 'Agoda', Id: 0 },
      { Name: 'Booking', Id: 1 },
      { Name: 'Cleartrip', Id: 2 },
      { Name: 'Expedia', Id: 3 },
      { Name: 'Goibibo', Id: 4 },
      { Name: 'MMT', Id: 5 },
      { Name: 'Yatra', Id: 6 }];
    let selectedChannels = [];
    let data = {};
    data["month"]=Utilities.getMonthName(date.getMonth(),'short')+' '+yearPush;
    let totalRevenuePerDay = 0;
    for(let j=0;j<7;j++){//Select 5 channels
      let flag = false;
      do{
        let channelIndex = Math.floor(Math.random() * 7);
        if(selectedChannels.indexOf(channelIndex)>-1){
          channelIndex = Math.floor(Math.random() * 7);
        }else{
          selectedChannels.push(channelIndex);
          let channelName = channelTypes.find(channel => channel.Id == channelIndex).Name;
          let otaRevenue = Math.floor(Math.random()*(60000-20000+1)+20000);
          totalRevenuePerDay+=otaRevenue;
          data[channelName] = otaRevenue;//Math.floor(Math.random()*(max-min+1)+min);
          flag=true;
        }
      }while(flag==false);
    }
    data["Total"]=totalRevenuePerDay;
    return data;
  }

  getRateInventoryData(){
    let chartData = [];
    let startDate = new Date();
    for(let i=0;i<15;i++){
      chartData.push(this.getRateInv(new Date(startDate)));
      startDate.setDate(startDate.getDate()+1);
    }
    return chartData;
  }
  getRateInv(date){
    let data = {};
    data["date"] = date;
    data["totalInventory"] = Math.floor(Math.random()*(60-50+1)+50);
    data["soldRooms"] = Math.floor(Math.random()*(data["totalInventory"]-0+1)+0);
    data["currentRate"] = Math.floor(Math.random()*(5000-4000+1)+4000);
    return data;
  }
  getInvSnapshotData(){
    let chartData = [];
    let startDate = new Date();
    for(let i=0;i<7;i++){
      chartData.push(this.getInvSnapshot(new Date(startDate)));
      startDate.setDate(startDate.getDate()+1);
    }
    return chartData;
  }
  getInvSnapshot(date){
    let data = {};
    data["date"] = date;
    data["Deluxe"] = Math.floor(Math.random()*(40+1));
    data["Superior"] = Math.floor(Math.random()*(40+1));
    data["Standard"] = Math.floor(Math.random()*(40+1));
    data["Executive Suite"] = Math.floor(Math.random()*(20+1));
    return data;
  }
}
