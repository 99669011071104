import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ApplicationModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule, MatInputModule} from '@angular/material';
import { MenuModule, ButtonModule, InputTextModule, PasswordModule, PanelModule, MessagesModule, MenubarModule, DataTableModule, DropdownModule, ToolbarModule, OverlayPanelModule, CheckboxModule, DialogModule, CalendarModule, SlideMenuModule, ContextMenuModule, FieldsetModule, MultiSelectModule, RadioButtonModule, BlockUIModule, ConfirmDialogModule, ConfirmationService, ProgressBarModule, TabViewModule, GrowlModule, AccordionModule, SharedModule, ChartModule, AutoCompleteModule, BreadcrumbModule, TriStateCheckboxModule, RatingModule, LightboxModule, ListboxModule, FileUploadModule, SliderModule, CarouselModule, InputSwitchModule } from 'primeng/primeng';
import { ToastModule } from 'primeng/toast'
import { SidebarModule } from 'primeng/components/sidebar/sidebar'
import { AppComponent } from './app.component';
import { Routes, RouterModule,RouteReuseStrategy } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { BookingsComponent } from './bookings/bookings.component';
import { RatecalendarComponent } from './ratecalendar/ratecalendar.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { SettingsComponent } from './settings/settings.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { FielderrorsComponent } from './components/fielderrors/fielderrors.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthGuard } from './ui-services/auth.guard';
import { AuthenticationService } from './ui-services/authentication.service';
import { CustomReuseStrategy } from './ui-services/reuse-routes';
import { HttpModule, BaseRequestOptions } from '@angular/http';
import { HeaderComponent } from './components/header/header.component';

//Ag Grid Imports:
import "ag-grid-enterprise";
import { AgGridModule } from 'ag-grid-angular';
import { CustomGroupRenderer } from './bookings/group.renderer.component';
import { RatecalendarService } from './ui-services/ratecalendar.service';
import { BestAvailableRateComponent } from './ratecalendar/bestavailablerate.component';
import { RateCalendarEditorComponent } from './ratecalendar/ratecalendarEditor.component';
import { RatecalendarviewsComponent } from './ratecalendarviews/ratecalendarviews.component';
import { StorageService } from './ui-services/storage.service';
import { RoomRatePlanFieldsComponent } from './ratecalendarviews/roomrateplanfields.component';
import { RoomRatePlanFieldComponent } from './ratecalendarviews/roomrateplanfield.component';
import { RoomRatePlanFieldsEditorComponent } from './ratecalendarviews/roomrateplanfieldsEditor.component';
import { RoomRatePlanFieldEditorComponent } from './ratecalendarviews/roomrateplanfieldEditor.component';
import { Utilities } from './ui-services/utilities';
import { GroupingLabelComponent } from './ratecalendarviews/groupinglabel.component';
import { APP_BASE_HREF, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BulkeditComponent } from './components/bulkedit/bulkedit.component';
import { RcfilterComponent } from './components/filters/rcfilter/rcfilter.component';
import { EventManagementService } from './ui-services/eventmanagement.service';
import { BookingfilterComponent } from './components/filters/bookingfilter/bookingfilter.component';
import { CheckboxgroupComponent } from './components/checkboxgroup/checkboxgroup.component';
import { LoadingService } from './ui-services/loading.service';
import { BookingCriteriaComponent } from './bookings/bookingcriteria.component';
import { TaskbarService } from './ui-services/taskbar.service';
import { ProgressBarRendererComponent } from './home/progressbar.renderer.component';
import { MessageService } from 'primeng/components/common/messageservice';
import { UnsavedChangesGuard } from './ui-services/unsavedchanges.guard';
import { BookingDetails } from './bookings/bookingdetails.component';
import { PromotionService } from './ui-services/promotion.service';
import { PromotionDetailsComponent } from './promotions/promotiondetails.component';
import { TableWidgetComponent } from './components/table-widget/table-widget.component';
import { PromotionfilterComponent } from './components/filters/promotionfilter/promotionfilter.component';
import { UserService } from './ui-services/user.service';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { PivotviewerComponent } from './components/pivotviewer/pivotviewer.component';
import { DatexPipe } from './components/transformer/datexpipe.transformer';
import { BookinganalyticsComponent } from './bookinganalytics/bookinganalytics.component';
import { TrendanalyticsComponent } from './trendanalytics/trendanalytics.component';
import { CompetitiontrackingComponent } from './competitiontracking/competitiontracking.component';
import { AnalyticsService } from './ui-services/analytics.service';
import { BookingService } from './ui-services/booking.service';
import { AnalyticsfilterComponent } from './components/filters/analyticsfilter/analyticsfilter.component';
import { UsersComponent } from './users/users.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertdetailsComponent } from './alerts/alertdetails.component';
import { AlertsService } from './ui-services/alerts.service';
import { UserdetailsComponent } from './users/userdetails.component';
import { PropertyComponent } from './property/property.component';
import { PropertydetailsComponent } from './property/propertydetails.component';
import { HotelManagementService } from './ui-services/hotelmanagement.service';
import { RoomtypesComponent } from './property/roomtypes/roomtypes.component';
import { RoomtypedetailsComponent } from './property/roomtypes/roomtypedetails.component';
import { RateplansComponent } from './property/rateplans/rateplans.component';
import { RateplandetailsComponent } from './property/rateplans/rateplandetails.component';
import { TaxpoliciesComponent } from './property/taxpolicies/taxpolicies.component';
import { TaxpolicydetailsComponent } from './property/taxpolicies/taxpolicydetails.component';
import { CompetitorsComponent } from './property/competitors/competitors.component';
import { HotelchannelsComponent } from './property/hotelchannels/hotelchannels.component';
import { HotelchanneldetailsComponent } from './property/hotelchannels/hotelchanneldetails.component';
import { MantrasxchangeComponent } from './property/mantrasxchange/mantrasxchange.component';
import { DomainchannelsComponent } from './domainchannels/domainchannels.component';
import { DomainchanneldetailsComponent } from './domainchannels/domainchanneldetails.component';
import { RaterulesComponent } from './raterules/raterules.component';
import { RateruledetailsComponent } from './raterules/rateruledetails.component';
import { GraphslidervariationComponent } from './graphslidervariation/graphslidervariation.component';
import { AppService, MantrasApiModule } from 'mantras-api';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LZStringModule, LZStringService } from 'ng-lz-string';
import { NgImageSliderModule } from 'ng-image-slider';
import { RmsComponent } from './rms/rms.component';
import { RmsService } from './ui-services/rms.service';
import { GapanalysisComponent } from './rms/gapanalysis/gapanalysis.component';
import { RmsfilterComponent } from './components/filters/rmsfilter/rmsfilter.component';
import { ProgressbarComponent } from './components/progressbar/progressbar.component';
import { ProgressbarComponent3d } from './components/3dBar/progressbar3d.component';
import { NotificationService } from './ui-services/notification.service';
import { NotificationComponent } from './notification/notification.component';
import { RevenuetoolsComponent } from './rms/revenuetools/revenuetools.component';
import { RevenueoperationsComponent } from './rms/revenueoperations/revenueoperations.component';
import { RevenueplanningComponent } from './rms/revenueplanning/revenueplanning.component';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';
import { RevenuecalcardComponent } from './components/revenuecalcard/revenuecalcard.component';
import { DetailsComponent } from './components/details/details.component';
import { NotificationNewComponent } from './notification/notification-new/notification-new.component';
import { NotificationCardCollapsedComponent } from './notification/notification-card-collapsed/notification-card-collapsed.component';
import { NotificationCardExpandedComponent } from './notification/notification-card-expanded/notification-card-expanded.component';
import { NotificationCardDetailedComponent } from './notification/notification-card-detailed/notification-card-detailed.component';
import { RevenuecardcircularComponent } from './components/revenuecardcircular/revenuecardcircular.component';
import { NotificationsettingsComponent } from './notificationsettings/notificationsettings.component';
import { Pivotviewer2Component } from './components/pivotviewer2/pivotviewer2.component';
import { ChartpocComponent } from './chartpoc/chartpoc.component';
import { HighchartsComponent } from './chartpoc/highcharts/highcharts.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { AmchartsComponent } from './chartpoc/amcharts/amcharts.component';
import { DevLabComponent } from './dev-lab/dev-lab.component';
import { AnalysisComponent } from './chartpoc/analysis/analysis.component';
import { CompetitorChartComponent } from './dev-lab/competitor-chart/competitor-chart.component';
import { DataProviderService } from './ui-services/data-provider.service';
import { DashboardChartsComponent } from './dev-lab/dashboard-charts/dashboard-charts.component';
import { AutomationruleComponent } from './automationrule/automationrule.component';
import { AutomationruledetailComponent } from './automationrule/automationruledetail.component';
import { UsageComponent } from './usage/usage.component';
import { UsageCriteriaComponent } from './usage/usagecriteria.component';
import { DomainsComponent } from './domains/domains.component';
import { DomainsCriteriaComponent } from './domains/domainscriteria.component';
import { ChannelCatalogComponent } from './channelcatalog/channelcatalog.component';
import { ChannelCatalogDetailsComponent } from './channelcatalog/channelcatalogdetails.component';
import { DomainsDetailsComponent } from './domains/domainsdetails.component';
import { HotelCatalogComponent } from './hotelcatalog/hotelcatalog.component';
import { HotelCatalogCriteriaComponent } from './hotelcatalog/hotelcatalogcriteria.component';
import { HotelCatalogDetailsComponent } from './hotelcatalog/hotelcatalogdetails.component';
import { ExceptionsComponent } from './exceptions/exceptions.component';
import { ExceptionsCriteriaComponent } from './exceptions/exceptionscriteria.component';
import { ExceptionsDetailsComponent } from './exceptions/exceptionsdetails.component';
import { CommonService } from './ui-services/common.service';
import { TasksComponent } from './tasks/tasks.component';
import { TasksCriteriaComponent } from './tasks/taskscriteria.component';
import { TasksDetailsComponent } from './tasks/tasksdetails.component';
import {TableModule} from 'primeng/table';
import { UsersManagementComponent } from './usersmanagement/usersmanagement.component';
import { UsersManagementCriteriaComponent } from './usersmanagement/usersmanagementcriteria.component';
import { UsersManagementDetailsComponent } from './usersmanagement/usersmanagementdetails.component';
import { BackuprestoreComponent } from './backuprestore/backuprestore.component';
import { CompetitorManagementComponent } from './competitormanagement/competitormanagement.component';
import { CompetitorManagementCriteriaComponent } from './competitormanagement/competitormanagementcriteria.component';
import { GlobalErrorHandler } from './ui-services/globalErrorHandler.service';
import { ServerErrorInterceptor } from './ui-services/servererrorinterceptor.service';
import { APP_INITIALIZER } from '@angular/core';
import { AppConfigService } from './app-config.service';




const appRoutes: Routes = [
  {
    path: "", component: HomeComponent, canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/dashboard", canActivate: [AuthGuard], pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard] },
      { path: "bookings", component: BookingsComponent, canActivate: [AuthGuard] },
      { path: "analytics", component: AnalyticsComponent, canActivate: [AuthGuard] },
      { path: "settings", component: SettingsComponent, canActivate: [AuthGuard] },
      { path: "rms", component: RmsComponent, canActivate: [AuthGuard] },
      { path: "devLab", component: DevLabComponent, canActivate: [AuthGuard]},
      { path: "promotions", component: PromotionsComponent, canActivate: [AuthGuard] },
      { path: "ratecalendar", component: RatecalendarviewsComponent, canActivate: [AuthGuard], canDeactivate: [UnsavedChangesGuard] },
      { path: "analytics/bookinganalytics", component: BookinganalyticsComponent, canActivate: [AuthGuard] },
      { path: "analytics/trendanalytics", component: TrendanalyticsComponent, canActivate: [AuthGuard] },
      { path: "analytics/competitiontracking", component: CompetitiontrackingComponent, canActivate: [AuthGuard] },
      { path: "settings/users", component: UsersComponent, canActivate: [AuthGuard] },
      { path: "settings/alerts", component: AlertsComponent, canActivate: [AuthGuard] },
      { path: "settings/raterules", component: RaterulesComponent, canActivate: [AuthGuard] },
      { path: "settings/property", component: PropertyComponent, canActivate: [AuthGuard] },
      { path: "settings/domainchannels", component: DomainchannelsComponent, canActivate: [AuthGuard] },
      { path: "settings/channelcatalog", component: ChannelCatalogComponent, canActivate: [AuthGuard] },      
      { path: "settings/notificationsettings", component: NotificationsettingsComponent, canActivate: [AuthGuard] },
      { path: "settings/automationrules", component: AutomationruleComponent, canActivate: [AuthGuard] },
      { path: "settings/usage", component: UsageComponent, canActivate: [AuthGuard] },
      { path: "settings/tasks", component: TasksComponent, canActivate: [AuthGuard] },
      { path: "settings/exception", component: ExceptionsComponent, canActivate: [AuthGuard] },
      { path: "settings/domains", component: DomainsComponent, canActivate: [AuthGuard] }, 
      { path: "settings/hotelCatalog", component: HotelCatalogComponent, canActivate: [AuthGuard] }, 
      { path: "settings/usersManagement", component: UsersManagementComponent, canActivate: [AuthGuard] },
      { path: "settings/competitorManagement", component: CompetitorManagementComponent, canActivate: [AuthGuard] },
      { path: "settings/backuprestore", component: BackuprestoreComponent, canActivate: [AuthGuard] },       
      { path: "rms/revenueoperations", component: RevenueoperationsComponent, canActivate: [AuthGuard] },
      { path: "rms/revenueplanning", component: RevenueplanningComponent, canActivate: [AuthGuard] },
      { path: "rms/revenuetools/gapanalysis", component: GapanalysisComponent, canActivate: [AuthGuard] },
      { path: "rms/revenuetools", component: RevenuetoolsComponent, canActivate: [AuthGuard] },
      { path: "rms/revenuetools/channelissuetracker", component: ComingsoonComponent, canActivate: [AuthGuard] },
      { path: "rms/revenuetools/importbookings", component: ComingsoonComponent, canActivate: [AuthGuard] },
      { path: "rms/notification", component: ComingsoonComponent, canActivate: [AuthGuard] },
      { path: "rms/notification", component: ComingsoonComponent, canActivate: [AuthGuard] },
      { path: "devLab/chartPoc", component:ChartpocComponent,canActivate: [AuthGuard]},
      { path: "devLab/competitorchart", component:CompetitorChartComponent,canActivate: [AuthGuard]},
      { path: "devLab/dashboardchart", component:DashboardChartsComponent,canActivate: [AuthGuard]}
    ]
  },
  { path: "login", component: LoginComponent },
  { path: "**", redirectTo: '' },
];
export function initializeApp(appConfig: AppConfigService) {
  return () =>{ 
    return appConfig.loadAppConfig();}
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    AnalyticsComponent,
    BookingsComponent,
    BookingCriteriaComponent,
    BookingDetails,
    RatecalendarComponent,
    PromotionsComponent,
    SettingsComponent,
    LoginComponent,
    SidenavComponent,
    FielderrorsComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    CustomGroupRenderer,
    BestAvailableRateComponent,
    RateCalendarEditorComponent,
    RatecalendarviewsComponent,
    RoomRatePlanFieldsComponent,
    RoomRatePlanFieldComponent,
    RoomRatePlanFieldsEditorComponent,
    RoomRatePlanFieldEditorComponent,
    GroupingLabelComponent,
    BulkeditComponent,
    RcfilterComponent,
    BookingfilterComponent,
    CheckboxgroupComponent,
    TableWidgetComponent,
    ProgressBarRendererComponent,
    PromotionDetailsComponent,
    PromotionfilterComponent,
    TooltipComponent,
    DatexPipe,
    PivotviewerComponent,
    BookinganalyticsComponent,
    TrendanalyticsComponent,
    CompetitiontrackingComponent,
    AnalyticsfilterComponent,
    UsersComponent,
    AlertsComponent,
    AlertdetailsComponent,
    UserdetailsComponent,
    PropertyComponent,
    PropertydetailsComponent,
    RoomtypesComponent,
    RoomtypedetailsComponent,
    RateplansComponent,
    RateplandetailsComponent,
    TaxpoliciesComponent,
    TaxpolicydetailsComponent,
    CompetitorsComponent,
    HotelchannelsComponent,
    HotelchanneldetailsComponent,
    MantrasxchangeComponent,
    DomainchannelsComponent,
    DomainchanneldetailsComponent,
    RaterulesComponent,
    RateruledetailsComponent,
    GraphslidervariationComponent,
    RmsComponent,
    GapanalysisComponent,
    RmsfilterComponent,
    RevenueoperationsComponent,
    ProgressbarComponent,
    ProgressbarComponent3d,
    RevenuetoolsComponent,
    RevenueplanningComponent,
    NotificationComponent,
    ComingsoonComponent,
    RevenuecalcardComponent,
    DetailsComponent,
    NotificationNewComponent,
    NotificationCardCollapsedComponent,
    NotificationCardExpandedComponent,
    NotificationCardDetailedComponent,
    RevenuecardcircularComponent,
    NotificationsettingsComponent,
    Pivotviewer2Component,
    ChartpocComponent,
    HighchartsComponent,
    AmchartsComponent,
    DevLabComponent,
    AnalysisComponent,
    CompetitorChartComponent,
    DashboardChartsComponent,
    AutomationruleComponent,
    AutomationruledetailComponent,
    UsageComponent,
    UsageCriteriaComponent,
    TasksComponent,
    TasksCriteriaComponent,
    DomainsComponent ,
    DomainsDetailsComponent,
    DomainsCriteriaComponent,
    ChannelCatalogComponent,
    ChannelCatalogDetailsComponent,
    HotelCatalogComponent,
    HotelCatalogCriteriaComponent,
    HotelCatalogDetailsComponent,
    ExceptionsComponent,
    ExceptionsCriteriaComponent,
    ExceptionsDetailsComponent,
    TasksDetailsComponent,
    UsersManagementComponent,
    UsersManagementCriteriaComponent,
    UsersManagementDetailsComponent,
    BackuprestoreComponent,
    CompetitorManagementComponent,
    CompetitorManagementCriteriaComponent
   ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule, 
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    FieldsetModule,
    MultiSelectModule,
    ToolbarModule,
    ContextMenuModule,
    MenuModule,
    DropdownModule,
    ToastModule,
    RouterModule.forRoot(appRoutes),
    ButtonModule,
    InputTextModule,
    RadioButtonModule,
    CheckboxModule,
    PasswordModule,
    PanelModule,
    DialogModule,
    HttpModule,
    MessagesModule,
    MenubarModule,
    OverlayPanelModule,
    DataTableModule,
    CalendarModule,
    BlockUIModule,
    ConfirmDialogModule,
    ProgressBarModule,
    TabViewModule,
    GrowlModule,
    AccordionModule,
    SharedModule,
    ChartModule,
    AutoCompleteModule,
    BreadcrumbModule,
    TriStateCheckboxModule,
    SidebarModule,
    RatingModule,
    LightboxModule,
    ListboxModule,
    FileUploadModule,
    SliderModule,
    MantrasApiModule,
    LZStringModule,
    CarouselModule,
    InputSwitchModule,
    AgGridModule.withComponents([CustomGroupRenderer, BestAvailableRateComponent, RateCalendarEditorComponent,
      RoomRatePlanFieldsComponent, RoomRatePlanFieldComponent, RoomRatePlanFieldsEditorComponent, RoomRatePlanFieldEditorComponent,
      GroupingLabelComponent, ProgressBarRendererComponent]),
    NgImageSliderModule,
    HighchartsChartModule,
    TableModule
  ],
  providers: [AuthGuard,
    AuthenticationService,
    BaseRequestOptions,
    RatecalendarService,
    StorageService,
    Utilities,
    EventManagementService,
    LoadingService,
    TaskbarService,
    ConfirmationService,
    MessageService,
    UnsavedChangesGuard,
    PromotionService,
    UserService,
    AnalyticsService,
    BookingService,
    AlertsService,
    HotelManagementService,
    LZStringService,
    NotificationService,
    RmsService,
    DataProviderService,
    CommonService,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {provide: RouteReuseStrategy, useClass: CustomReuseStrategy},
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    AppConfigService,
    { provide: APP_INITIALIZER,
         useFactory: initializeApp,
         deps: [AppConfigService], 
         multi: true }
  ],
  entryComponents: [
    PromotionDetailsComponent, 
    AlertdetailsComponent, 
    RateruledetailsComponent, 
    UserdetailsComponent, 
    RoomtypedetailsComponent, 
    RateplandetailsComponent, 
    DomainchanneldetailsComponent, 
    ChannelCatalogDetailsComponent,
    HotelchanneldetailsComponent, 
    TaxpolicydetailsComponent, 
    AutomationruledetailComponent,
    DomainsDetailsComponent,
    HotelCatalogDetailsComponent,
    ExceptionsDetailsComponent,
    TasksDetailsComponent,
    UsersManagementDetailsComponent,
    HeaderComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
