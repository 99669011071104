import { Component, OnInit, EventEmitter, Output, HostListener, Input } from '@angular/core';
import { AuthenticationService } from '../ui-services/authentication.service';
import { HotelInfo, Address, GeoCode, Phone } from 'mantras-api';
import { MenuItem, MessageService } from 'primeng/api';
import * as jQuery from 'jquery';
import { Utilities } from '../ui-services/utilities';
import { HotelManagementService } from '../ui-services/hotelmanagement.service';
import { RatingModule } from 'primeng/rating';

@Component({
  selector: 'hotelcatalogdetails',
  templateUrl: './hotelcatalogdetails.component.html',
  styleUrls: ['./hotelcatalogdetails.component.css']
})
export class HotelCatalogDetailsComponent implements OnInit {
  val: number;
  selectedCurrency;
  phone: any;
  hotelCatalogMenuItems: MenuItem[];
  addressPlaceholder: string;
  Address;
  latitude;
  longitude;
  currency = [];
  street; country; city; state; zipcode;
  isNew = false;
  //selectedCurrency = ;
  @Input() hotelcatalogdetails = new HotelInfo();
  @Output('onClose') onClose = new EventEmitter<boolean>();
  constructor(private hotelService: HotelManagementService, private messageService: MessageService) {
    Utilities.currencies.forEach(curr => {
      this.currency.push({ label: curr.Code + "(" + curr.Symbol + ")" + "-" + curr.Currency + "(" + curr.Country + ")", value: curr.Code });
    });
  }

  ngOnInit() { }
  loadMenu() {
    this.hotelCatalogMenuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', command: (event) => this.onSave(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) },
    ];
  }

  name;
  loadData(data) {
    if (data.Id == null) {
      this.hotelcatalogdetails = new HotelInfo();
      this.hotelcatalogdetails.Address = new Address();
      this.hotelcatalogdetails.Address.GeoCode = new GeoCode();
      this.hotelcatalogdetails.Phone = new Phone();
      this.isNew = true;
      this.latitude = 0;
      this.longitude = 0;
      this.addressPlaceholder = "Street" + "\n" +
        "City" + "\t" + "State" + "\n" +
        "Country" + "\t" + "ZipCode";
      this.selectedCurrency = "INR";
    } else {
      if (this.hotelcatalogdetails.Address.GeoCode) {
        if (this.hotelcatalogdetails.Address.GeoCode.Latitude)
          this.latitude = this.hotelcatalogdetails.Address.GeoCode.Latitude;
        else this.latitude = "";
        if (this.hotelcatalogdetails.Address.GeoCode.Longitude)
          this.longitude = this.hotelcatalogdetails.Address.GeoCode.Longitude;
        else this.longitude = "";
      }
      else {
        this.latitude = "";
        this.longitude = "";
      }
      this.selectedCurrency = "INR";
      //this.name = this.hotelcatalogdetails.Name;
      if (this.hotelcatalogdetails.Address.Street == null) this.hotelcatalogdetails.Address.Street = "";
      if (this.hotelcatalogdetails.Address.City == null) this.hotelcatalogdetails.Address.City = "";
      if (this.hotelcatalogdetails.Address.State == null) this.hotelcatalogdetails.Address.State = "";
      if (this.hotelcatalogdetails.Address.Country == null) this.hotelcatalogdetails.Address.Country = "";
      if (this.hotelcatalogdetails.Address.ZipCode == null) this.hotelcatalogdetails.Address.ZipCode = "";
      this.Address = this.hotelcatalogdetails.Address.Street + "\n" +
        this.hotelcatalogdetails.Address.City + "\t" + this.hotelcatalogdetails.Address.State + "\n" +
        this.hotelcatalogdetails.Address.Country + "\t" + this.hotelcatalogdetails.Address.ZipCode;
      this.phone = (this.hotelcatalogdetails.Phone && this.hotelcatalogdetails.Phone.Number) ? this.hotelcatalogdetails.Phone.Number : this.phone;

    }
    this.loadMenu();
  }

  isFromComp = false;
  fromCompFunc() {
    this.isFromComp = true;
  }

  // refreshIfSave() {
  //   if (this.closeOnSave){
  //     return true;
  //   }
  // }

  closeOnSave = false;
  async onSave(event) {
    if (this.hotelcatalogdetails.Address.Country == null && this.hotelcatalogdetails.Address.State == null) {
      this.messageService.add({ severity: 'warning', summary: 'Warning', detail: "Country is mandatory field" });
      return;
    }
    if(this.isNew){
    let idFormat = this.hotelcatalogdetails.Address.Country + Utilities.generateGUID();
    this.hotelcatalogdetails.Id = idFormat;
    }
    var resp = await this.hotelService.saveHotel(this.hotelcatalogdetails).then(result => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: "Saved successfully." });
    });
    this.closeOnSave = true;
    this.onCancel(this.hotelcatalogdetails);
  }

  onCancel(event?) {
    this.onClose.emit(event);
    this.closeOnSave = false;
  }

  showAddress = false;
  editAddress() {
    this.street = this.hotelcatalogdetails.Address.Street;
    this.country = this.hotelcatalogdetails.Address.Country;
    this.city = this.hotelcatalogdetails.Address.City;
    this.state = this.hotelcatalogdetails.Address.State;
    this.zipcode = this.hotelcatalogdetails.Address.ZipCode;
    this.showAddress = true;
  }

  saveAddress() {
    this.hotelcatalogdetails.Address.Street = this.street;
    this.hotelcatalogdetails.Address.Country = this.country;
    this.hotelcatalogdetails.Address.City = this.city;
    this.hotelcatalogdetails.Address.State = this.state;
    this.hotelcatalogdetails.Address.ZipCode = this.zipcode;
    this.Address = this.hotelcatalogdetails.Address.Street + "\n" +
      this.hotelcatalogdetails.Address.City + "\t" + this.hotelcatalogdetails.Address.State + "\n" +
      this.hotelcatalogdetails.Address.Country + "\t" + this.hotelcatalogdetails.Address.ZipCode;
    this.showAddress = false;
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true, false, false, false, 30));
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }
}