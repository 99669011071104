import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Channel, Credentials } from 'mantras-api';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { StorageService } from '../ui-services/storage.service';
import { HotelManagementService } from '../ui-services/hotelmanagement.service';
import { ContactInfo, Phone } from 'mantras-api';
import { ViewChild } from '@angular/core';


@Component({
  selector: 'app-channelcatalogdetails',
  templateUrl: './channelcatalogdetails.component.html',
  styleUrls: ['./channelcatalogdetails.component.css']
})
export class ChannelCatalogDetailsComponent implements OnInit {
  channelTypeDisableFlag = false;
  //showValidationDialogueBox: boolean;
  channelCode: string;
  Newchannel: Channel;
  moreSettings: any;
  shopperUrl: any;
  shopperType: any;
  shopperAssembly: any;
  xmlurl: any;
  provider: any;
  assembly: any;
  @Input() channel = new Channel();
  @Output('onClose') onClose = new EventEmitter<boolean>();
  ccMenuItems = [];
  channelType = [];
  errorMsg = "";
  systemChannels;
  email;
  phone;
  hotelNumber;
  cookies;
  bookingEngine;
  pmsCode;
  showXmlCreds = false;
  bookingEngineLists = [];
  winHMSBookingURL;
  gtaQualifier;
  apiKey;
  hotelUrl;
  accessToken;
  url;
  bnbExistingListingIds = "";
  isNew = false;

  constructor(private storageService: StorageService, private hotelMgmtService: HotelManagementService) {
    // Utilities.channelTypes.forEach(element => {
    //   this.channelType.push({ label: element.Name, value: element.Id })
    // });

  }

  ngOnInit() {
    this.loadMenu();
  }

  loadData(data) {  
    this.hotelMgmtService.getSystemChannels(false).then(sysChannels => {
      this.channelType = [];
        Utilities.channelTypes.forEach(element => {
          let variable = sysChannels.find( sc => sc.ChannelCode == element.Id);
          if(this.channel.Id != null){
            if(data.ChannelCode == element.Id){
            this.channelType.push({ label: element.Name, value: element.Id });
            this.channelTypeDisableFlag = true;
            }
          }
          if(!variable && this.channel.Id == null){
          this.channelTypeDisableFlag = false;
            this.channelType.push({ label: element.Name, value: element.Id });
          }
      });
      this.channelType.sort(Utilities.sort_label_asc);
    });

    this.bookingEngineLists = Utilities.xchangeBECodes;

    if (this.channel.Id == null) {
      this.channel = new Channel();
      //this.channel.ChannelCode = this.channelType.Id;
      this.channel.Name = null;
      this.channel.Config ={};
      this.channel.Credentials = new Credentials();
      this.channel.XmlCredentials = new Credentials();
      this.channel.DomainId = this.storageService.get(StorageService.userDomainContext).CurrentDomainId;
      this.channel.IsActive = true;
      this.isNew = true;
    }
    this.loadMenu();
  }

  loadMenu() {
    this.ccMenuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', command: (event) => this.onSave(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) },
    ];
  }

  onCancel(event?) {
    this.onClose.emit(event);
  }

  @ViewChild('validationDialogueBox') validationDialogueBox;
  showValidationDialogueBox = false;
  
  onSave(event) {
    if(this.channel.Config.Assembly == undefined || this.channel.Config.Provider == undefined){
      this.showValidationDialogueBox= true;
      return;
    }
    if (!this.channel.Config) 
    this.channel.Config = {};
    if(this.channel.ChannelCode == undefined) 
    this.channel.ChannelCode = this.channelType[0].value;
    this. hotelMgmtService.saveDomainChannel(this.channel).then(result => {
      this.storageService.remove("SYSTEMCHANNELS");
      this.onCancel(this.channel);
      });
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true));
  }

  closeDialog(event) {
    //this.deleteId = null;
    this.showValidationDialogueBox= false;
    //this.setHeight();
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

}
