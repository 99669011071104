import { Component, OnInit, AfterViewInit } from '@angular/core';
import { StorageService } from '../../ui-services/storage.service';
import { BookingService } from '../../ui-services/booking.service';
import { AuthenticationService } from '../../ui-services/authentication.service';
import { AnalyticsService } from '../../ui-services/analytics.service';
import { Utilities } from '../../ui-services/utilities'
import { BookingCriteria, Range, DateRange } from 'mantras-api';
import { forkJoin } from 'rxjs';
//AM charts imports
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/animated";
import { chart } from 'highcharts';

//am4core.useTheme(am4themes_animated);
//am4core.useTheme(am4themes_kelly);

function am4themes_myTheme(target) {
  if (target instanceof am4core.ColorSet) {
    target.list = [
      am4core.color("#1BA68D"),
      am4core.color("#E7DA4F"),
      am4core.color("#E77624"),
      am4core.color("#DF3520"),
      am4core.color("#64297B"),
      am4core.color("#232555")
    ];
  }
}

//am4core.useTheme(am4themes_myTheme);


@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html',
  styleUrls: ['./analysis.component.css']
})
export class AnalysisComponent implements OnInit {

  bookingcriteria: BookingCriteria;
  bookings;
  competitors;
  competitorResponse;
  pieChartData = [];
  hotelContext;
  hotelInfo;
  channels = ["AGD"];
  competitorGraphData;
  constructor(private storageService: StorageService,
    private bookingService: BookingService,
    private authenticationService: AuthenticationService,
    private analyticsService: AnalyticsService) {
    this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
  }

  ngOnInit() {
    let domainHotel = this.authenticationService.getDomainHotel(this.hotelContext.HotelId);
    this.competitors = domainHotel.CompetitorHotels;
    //TODO: This is to remove the target hotel id which is duplicated in the list.
    if (this.competitors && this.competitors.length >= 2) {
      //Check first and last index hotel id and remove last if its same.
      if (this.competitors[0] == this.competitors[this.competitors.length - 1]) {
        this.competitors.pop();
      }
    }
  }
  ngAfterViewInit() {
    this.bookingcriteria = this.createBookingCriteria();
    this.getBookings();
    this.getHotels();
  }
  async getHotels() {
    this.hotelInfo = await this.analyticsService.findHotels(this.competitors);
    this.getCompetitorData();
  }
  // getCompetitorData(){
  //   let requestArray = [];
  //   let channelTypes = this.channels;
  //   let hotelId = [];
  //   hotelId.push(this.hotelContext.HotelId);
  //   let date = new Date();
  //   let dateRange = new DateRange();
  //   dateRange.Start = new Date(date);
  //   let endDate = new Date(date);
  //   endDate.setDate(endDate.getDate()+14);
  //   dateRange.End = endDate;
  //   let response =[];
  //   //Fetching Data for each channel check before it exist
  //   for (let channelType of channelTypes) {
  //      // let req = this.analyticsService.findCompetitorRates(hotelId,channelType,dateRange,this.competitors);
  //       let req = this.analyticsService.findChannelRates(hotelId,dateRange,null,null);
  //       requestArray.push(req);
  //   }

  //   if (requestArray.length > 0) {
  //     forkJoin(requestArray).subscribe(results => {
  //       console.log("Parallel Request: ", results);
  //       for (let idx = 0; idx < results.length; idx++) {
  //         let resp = results[idx];
  //         if (resp && resp != null) {
  //           if (resp instanceof Array) {
  //             response = response.concat(resp);
  //           }
  //         }
  //       }
  //       this.competitorResponse = response;
  //       this.competitorGraphData = this.processChannelRates();
  //       this.renderCompetitorGraph();
  //     });
  //   }
  // }

  getCompetitorData() {
    let requestArray = [];
    //let channelTypes = this.channels;
    let hotelId = [];
    hotelId.push(this.hotelContext.HotelId);
    this.hotelInfo.forEach(element => {
      hotelId.push(element.Id);
    });
    let date = new Date();
    let dateRange = new DateRange();
    dateRange.Start = new Date(date);
    let endDate = new Date(date);
    endDate.setDate(endDate.getDate() + 14);
    dateRange.End = endDate;
    let response = [];
    //Fetching Data for each channel check before it exist
    let req = this.analyticsService.findChannelRates(hotelId, dateRange, null, null);
    requestArray.push(req);

    if (requestArray.length > 0) {
      forkJoin(requestArray).subscribe(results => {
        console.log("Parallel Request: ", results);
        for (let idx = 0; idx < results.length; idx++) {
          let resp = results[idx];
          if (resp && resp != null) {
            if (resp instanceof Array) {
              response = response.concat(resp);
            }
          }
        }
        this.competitorResponse = response;
        this.competitorGraphData = this.processChannelRates();
        this.renderCompetitorGraph();
      });
    }
  }

  createBookingCriteria() {
    let criteria = new BookingCriteria();
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 10);
    let endDate = new Date();
    criteria.BookingDates = new Range<Date>();
    criteria.DomainIds = [this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId];
    criteria.HotelIds = [this.storageService.get(StorageService.currentUser).HotelContext.HotelId];
    criteria.BookingDates.Start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
    criteria.BookingDates.End = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 0);
    return criteria;
  }

  async getBookings() {
    let response = await this.bookingService.findBookings(this.bookingcriteria).then(
      async response => {
        this.bookings = response;
        this.setPieChartData(this.bookings);
        this.getPieChart();
      });
  }
  processChannelRates() {
    let hotelCompetitorData = [];
    this.competitors.forEach(competitor => {
      hotelCompetitorData.push({ id: competitor, data: this.getHotelData(competitor) })
    });
    return hotelCompetitorData;
  }
  getHotelData(hotelId) {
    let data = [];
    this.competitorResponse.forEach(response => {
      if (response.HotelId == hotelId) {
        data.push({ date: new Date(response.StayDate), Rate: response.Rate });
      }
    });
    return data;
  }
  setPieChartData(bookings) {
    if (this.bookings) {
      console.log(bookings)
      this.bookings.forEach(element => {
        let isPresent = false;
        if (this.pieChartData.length > 0) {
          this.pieChartData.forEach(chartData => {
            if (chartData.OTA == element.Channel) {
              chartData["Room Nights"] += element.Rooms.length;
              isPresent = true;
            }
          });
        }
        if (isPresent == false) {
          this.pieChartData.push({ "OTA": element.Channel, "Room Nights": element.Rooms.length });
        }

      });
    }
  }
  getPieChart() {
    // Create chart instance
    let chart = am4core.create("pieChart2", am4charts.PieChart);
    chart.data = this.pieChartData;
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "Room Nights";
    pieSeries.dataFields.category = "OTA";
    // Add a legend
    chart.legend = new am4charts.Legend();
  }

  renderCompetitorGraph() {
    var chart = am4core.create("lineChartWithGaps2", am4charts.XYChart);
    chart.paddingRight = 20;
    chart.data = [];
    let date = new Date();
    date.setDate(date.getDate() - 1);
    let i = 0;
    for (i = 0; i < 14; i++) {
      date.setDate(date.getDate() + 1);
      chart.data.push({ date: new Date(date) });
    }
    this.competitorGraphData.forEach(competitor => {
      let id = competitor.id;
      competitor.data.forEach(dateRatePair => {
        if (dateRatePair.Rate > 0) {
          chart.data.forEach(data => {
            if (data.date.getDate() == dateRatePair.date.getDate()) {
              data[competitor.id] = dateRatePair.Rate;
            }
          });
        }
      });
    });

    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;

    // Create value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Rate"
      ;
    this.competitorGraphData.forEach(competitor => {
      // Create series
      var series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = competitor.id;
      series.dataFields.dateX = "date";
      series.name = this.findHotelName(competitor.id);
      series.strokeWidth = 3;
      series.tensionX = 0.8;
      series.bullets.push(new am4charts.CircleBullet());
      series.tooltipText = `{valueY}`;
      series.connect = false;
    });


    /* Create a cursor */
    chart.cursor = new am4charts.XYCursor();
    chart.legend = new am4charts.Legend();

  }
  findHotelName(hotelId: string) {
    let name = Utilities.findId(this.hotelInfo, hotelId, hotelId);
    if (hotelId == this.hotelContext.HotelId) {
      name += " (You)";
    }
    return name;
  }
}
