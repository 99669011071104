import { Component, HostListener} from '@angular/core';
import { LoadingService } from './ui-services/loading.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { Message } from 'primeng/primeng';
import { environment } from '../environments/environment';
import { AfterViewInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { StorageService } from './ui-services/storage.service';
import { Utilities } from './ui-services/utilities';
import { AppConfigService } from './app-config.service';
//import { is } from '@amcharts/amcharts4/.internal/themes/ITheme';
import * as $itheme from "@amcharts/amcharts4/.internal/themes/ITheme";
declare var fs: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  msgs:Message[] = [];
  errorMsg = this.loadingService.errorMessage;
  oldConsoleLog = null;


  constructor(public loadingService:LoadingService, private messageService:MessageService,
    private storageService:StorageService,private appConfigService:AppConfigService){
    this.setLogger();
    //Default Theme of the user
    let theme = storageService.get(StorageService.userTheme,true);
    if(theme != null && theme != undefined)
      Utilities.loadCSS("theme-style","assets/theme-"+theme+".css");
    else if(appConfigService.appConfig.theme){
      Utilities.loadCSS("theme-style","assets/theme-"+appConfigService.appConfig.theme+".css");
    }else
      Utilities.loadCSS("theme-style","assets/theme-"+environment.theme+".css");


  }
  setLogger(){
    if(this.appConfigService.appConfig.production){
      this.oldConsoleLog = console.log;
      window['console']['log'] = function(){};
    } else
    if(environment.production){
      this.oldConsoleLog = console.log;
      window['console']['log'] = function(){};
    } else {
      if(this.oldConsoleLog == null) return;
      window['console']['log'] = this.oldConsoleLog;
    }
  }

  ngAfterViewInit(): void {
    let body = document.getElementsByTagName("body")[0];
    if(this.appConfigService.appConfig.watermarkCSS != null || this.appConfigService.appConfig.watermarkCSS != ""){
      body.classList.add("watermark-"+this.appConfigService.appConfig.watermarkCSS);
    }else if(environment.watermarkCSS != null || environment.watermarkCSS != ""){
      body.classList.add("watermark-"+environment.watermarkCSS);
    }
    let elems = document.getElementsByClassName("mainloadercenter");
    if(elems) elems[0].setAttribute("style","display:none");
  }

  cancelLoading(){
    this.loadingService.cancelLoading();
    return false;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
      this.storageService.persistData();
  }
}

//This is to suppress console logging
//Reference: https://stackoverflow.com/questions/1215392/how-to-quickly-and-conveniently-disable-all-console-log-statements-in-my-code
// console.log = function() {}
