import { Component, OnInit, ComponentFactoryResolver, HostListener, ViewContainerRef, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { Subscription, interval } from 'rxjs';
import { Router } from '@angular/router';
import { AppService, TaskCriteria, TaskCriteria2, DateRange, TaskStatus, Task, TaskProgressEvent, TaskProgressEventType } from 'mantras-api';
import { MessageService, MenuItem } from 'primeng/api';
import { CommonService } from '../ui-services/common.service';
import { StorageService } from '../ui-services/storage.service';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { DatePipe } from '@angular/common';
import { TasksDetailsComponent } from './tasksdetails.component';
import { saveAs } from 'file-saver/dist/FileSaver';


@Component({
  selector: 'tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {
  filterValue;
  timeOptions = [{ label: "Last 30 mins", value: '30' }, { label: 'Last 1 Hour', value: '1' }, { label: 'Last 3 Hours', value: '3' },
  { label: 'Last 6 Hours', value: '6' }, { label: 'Last 24 Hours', value: '24' }];
  showEventLog = false;
  showReqResp = false; showTaskDetails = false;
  startTime; statusMessage;
  endTime; response; statusSuccess;
  status; request; statusError;
  detailsVisibility;
  sttrace = false;
  elspime;
  accord = false;
  columnDefs: any[];
  icons;
  gridOptions: GridOptions;
  rowData: any[];
  rowSelection: any;
  subscription: Subscription;
  isDetailsPage: boolean;
  rowGroupPanelShow: any;
  defaultGroupSortComparator;
  tasksMenuItems: MenuItem[];
  domainInfos; tasksDetails;
  hotelInfos;
  detailCellRendererParams; defaultColDef;
  detailCellRenderer;
  detailRowHeight;
  groupDefaultExpanded;
  expanded;
  frameworkComponents;
  selectedTask = new Task();
  tasksDetailsMap = new Map<string, object>();
  @ViewChild('filterValueUpdt') filterValueUpdt;
  JsonToXml = require('jsontoxml');


  constructor(private messageService: MessageService, private mantras: AppService, private router: Router, private commService: CommonService, private storageService: StorageService, private resolver: ComponentFactoryResolver) {
    this.columnDefs = [
      { headerName: "Name", cellRenderer: 'agGroupCellRenderer',lockPosition:'true', field: "Name",headerTooltip:"Name", width: 90, enableRowGroup: true, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Start Time", field: "StartTime",headerTooltip:"Start Time", width: 100, enableRowGroup: true, menuTabs: [], cellClass: 'gridcol', valueFormatter: StartTimeFormatter, sort: 'desc' },
      { headerName: "End Time", field: "EndTime",headerTooltip:"End Time", width: 100, enableRowGroup: true, menuTabs: [], cellClass: 'gridcol', valueFormatter: EndTime, },
      { headerName: "Duration(sec)", field: "Duration",headerTooltip:"Duration", width: 50, menuTabs: [], cellClass: 'gridcol', valueFormatter: ElaspsedTime },
      { headerName: "Domain", field: "DomainId",headerTooltip:"Domain", width: 80, enableRowGroup: true, menuTabs: [], cellClass: 'gridcol',valueGetter: valueGetterDomainName },
      { headerName: "Hotel", field: "HotelId",headerTooltip:"Hotel", width: 80, enableRowGroup: true, menuTabs: [], cellClass: 'gridcol',valueGetter:valueGetterHotelName },
      { headerName: "Channel", field: "ChannelCode",headerTooltip:"Channel", width: 60, enableRowGroup: true, menuTabs: [], cellClass: 'gridcol', valueFormatter: ChannelName, valueGetter: valueGetterChannelName, keyCreator: keyCreatorChannelName },
      { headerName: "User Id", field: "UserId",headerTooltip:"User Id", width: 80, enableRowGroup: true, menuTabs: [], cellClass: 'gridcol',valueGetter: valueGetterUser },
      { headerName: "Progress(%)", field: "Progress",headerTooltip:"Progress", width: 50, enableRowGroup: true, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Status", field: "Status",headerTooltip:"Status", width: 50, enableRowGroup: true, menuTabs: [], cellClass: 'gridcol', valueFormatter: Status },
    ];
    this.defaultColDef = { flex: 1 };
    this.detailCellRenderer = 'myDetailCellRenderer';
    this.frameworkComponents = { myDetailCellRenderer: TasksDetailsComponent };
    this.rowGroupPanelShow = "always";

    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: 0,
      rowSelection: this.rowSelection,
      masterDetail: true,
      detailCellRendererParams: this.detailCellRendererParams,
      detailCellRenderer: this.detailCellRenderer,
      detailRowHeight: this.detailRowHeight,
      frameworkComponents: this.frameworkComponents,
      suppressRowClickSelection: false,
      localeText: { noRowsToShow: "No Task(s) found" },
      sortingOrder: ["desc", "asc"],
      suppressContextMenu: true,
    };
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;
    this.gridOptions.context = { thisComponent: this };

    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }

    this.defaultGroupSortComparator = function (nodeA, nodeB) {
      if (nodeA.key < nodeB.key) {
        return -1;
      } else if (nodeA.key > nodeB.key) {
        return 1;
      } else {
        return 0;
      }
    };

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });
  }

  @ViewChild('taskscriteria', { read: ViewContainerRef }) taskscriteria;
  async onSearchCriteria(criteria?: TaskCriteria) {
    if (!criteria) {
      criteria = this.createTaskCriteria();
      this.taskscriteria._data.componentView.component.bindCriteria(criteria);
    }
    //this.criteriaUsage = criteria;
    this.domainInfos = new Map<string, string>();
    this.hotelInfos = new Map<string, string>();
    await this.commService.FindTasks(criteria, this.domainInfos, this.hotelInfos).then(tasksList => {
      this.tasksDetails = tasksList;
      this.gridOptions.api.setRowData(tasksList);
      this.gridOptions.api.sizeColumnsToFit();
      this.onGridReady(this);
      this.setHeight();
      // if (tasksList.length > 0)
      //   this.showCriteria = false;
    });
    this.loadMenu();
    this.tasksDetailsMap.clear();
  }

  createTaskCriteria() {
    let criteria = new TaskCriteria();
    criteria.RunDate = new DateRange();
    let startDate = new Date();
    criteria.RunDate.Start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
    criteria.RunDate.End = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 0);
    return criteria;
  }

  ngOnInit() {
    this.loadMenu();
  }


  loadMenu() {
    this.tasksMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
    ];
  }

  onRefresh(event) {
    this.filterValue = null;
    let criteria = new TaskCriteria();
    if (this.taskscriteria._data.componentView.component.buildCriteria()) {
      criteria = this.taskscriteria._data.componentView.component.taskCriteria;
    }
    this.onSearchCriteria(criteria);
  }

  onSearch(event) {
    this.onSearchCriteria(event);
  }


  onCriteriaChange() {
    let criteria = new TaskCriteria();
    if (this.taskscriteria._data.componentView.component.buildCriteria()) {
      criteria = this.taskscriteria._data.componentView.component.taskCriteria;
    }
    criteria.RunDate = new DateRange();
    let startDate: Date = new Date();
    let endDate: Date = new Date();
    let todaysDate: Date = new Date();
    todaysDate = new Date(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate(),
      todaysDate.getHours(), todaysDate.getMinutes(), todaysDate.getSeconds(), todaysDate.getMilliseconds());
    endDate.setDate(todaysDate.getDate())
    endDate.setHours(todaysDate.getHours())
    endDate.setMinutes(todaysDate.getMinutes() + 1)
    endDate.setSeconds(todaysDate.getSeconds())
    endDate.setMilliseconds(todaysDate.getMilliseconds());
    criteria.RunDate.End = endDate;
    startDate = new Date(todaysDate);
    if (this.filterValue["value"] != '30') {
      startDate.setDate(todaysDate.getDate());
      startDate.setHours(todaysDate.getHours() - parseInt(this.filterValue.value));
      startDate.setMinutes(todaysDate.getMinutes());
      startDate.setSeconds(todaysDate.getSeconds());
      startDate.setMilliseconds(todaysDate.getMilliseconds());
      criteria.RunDate.Start = startDate;
    }
    else {
      startDate.setDate(todaysDate.getDate());
      startDate.setHours(todaysDate.getHours());
      startDate.setMinutes(todaysDate.getMinutes() - parseInt(this.filterValue.value));
      startDate.setSeconds(todaysDate.getSeconds());
      startDate.setMilliseconds(todaysDate.getMilliseconds());
      criteria.RunDate.Start = startDate;
    }

    this.taskscriteria._data.componentView.component.bindCriteria(criteria);
    this.onSearchCriteria(criteria);
    this.filterValueUpdt.updateSelectedOption(null);
  }

  onClose() {
    this.router.navigate(["/settings"]);
  }

  requestResponseObj; eventLog; eventLogFlag; timeStamp; eventLogType; notRunning; taskID
  taskDetailsMap = new Map<string, object>();
  async onLinkClickEvent(details, taskObject) {
    this.taskID = taskObject.Id;
    if (this.taskDetailsMap.has(taskObject.Id)) {
      this.taskDetailsMap.get(taskObject.Id);
    }
    else {
      await this.commService.getTaskDetails(taskObject.Id).then(resp => {
        this.selectedTask = resp;
        this.startTime = StartTimeFormatter(resp);
        this.endTime = EndTime(resp);
        this.status = Status(resp);
        this.elspime = ElaspsedTime(resp);
        if (this.selectedTask.Context['Args'])
          this.request = Utilities.formatXml(this.selectedTask.Context['Args'], '');
        if (this.selectedTask.Tags['ReturnValue'])
          this.response = Utilities.formatXml(this.selectedTask.Tags['ReturnValue'], '');
        else
          this.response = "Empty";
        if (this.selectedTask.Status == 2) {
          this.statusMessage = "This task ran to its completion without any errors";
          if (!this.selectedTask.Exceptions)
            this.selectedTask.Exceptions = "Empty";
          if (this.selectedTask.EventLog.length > 0 && this.selectedTask.EventLog != undefined && this.selectedTask.EventLog != null) {
            this.eventLogType = TaskProgressEventType[resp.EventLog[0]['Type']]
            this.timeStamp = new DatePipe('en-US').transform(resp.EventLog[0]['Timestamp'], 'dd-MMM-y, hh:mm:ss aa');
            this.eventLog = this.selectedTask.EventLog;
          }
          this.detailsVisibility = "Visible"
          this.statusSuccess = true;
          this.statusError = false;
        }
        else if (this.selectedTask.Status == 4) {
          this.statusMessage = this.selectedTask.Exceptions;
          this.statusError = true;
          this.statusSuccess = false;
        }
        this.taskDetailsMap.set(this.selectedTask.Id, this.selectedTask);
      });
    }
    if (details == "EventLog") {
      if (this.selectedTask.Status == 1) {
        this.notRunning = false;
      }
      else {
        this.notRunning = true;
      }
      this.showEventLog = true;
    }
    if (details == "ReqResp")
      this.showReqResp = true;
    if (details == "TaskDet") {
      this.accord = false;
      this.showTaskDetails = true;
    }
    if (details == "stakTraceTr") {
      this.sttrace = true;
    }
    if (details == "stakTraceFls") {
      this.sttrace = false;
    }
    if (details == "Save") {
      this.saveFile();
    }
  }

  eventLogRefresh() {
    this.commService.getTaskDetails(this.taskID).then(resp => {
      if (resp.EventLog.length > 0 && resp.EventLog != undefined && resp.EventLog != null) {
        this.eventLogFlag = true;
        this.eventLogType = TaskProgressEventType[resp.EventLog[0]['Type']]
        this.timeStamp = new DatePipe('en-US').transform(resp.EventLog[0]['Timestamp'], 'dd-MMM-y, hh:mm:ss aa');
        this.eventLog = resp.EventLog;
      }
    })
  }
  subsc: Subscription;
  autoRefresh(event) {
    if (event) {
      this.subsc = interval(5000).subscribe(tick => {
        this.eventLogRefresh();
      });
    }
    if (!event) {
      this.subsc.unsubscribe();
    }
  }

  eventLogDialogClose() {
    this.showEventLog = false;
    this.subsc.unsubscribe();

  }


  saveFile() {
    let byteChars = "<Task>" + this.JsonToXml(JSON.stringify(this.selectedTask)) + "</Task>";
    let byteNumbers = new Array(byteChars.length);
    for (var i = 0; i < byteChars.length; i++) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/xml' });
    saveAs(blob, "task.log");
  }

  ReqResp;
  saveFileReqResp() {
    this.ReqResp = "<Request>" + this.request + "</Request>" + "\n" + "<Response>" + this.response + "</Response>";
    let byteChars = this.JsonToXml(JSON.stringify(this.ReqResp));
    let byteNumbers = new Array(byteChars.length);
    for (var i = 0; i < byteChars.length; i++) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/xml' });

    saveAs(blob, "taskReqResp.log");
  }


  GetKeysTags(): string[] {
    if (this.selectedTask.Tags != undefined && this.selectedTask.Tags != null)
      return Object.keys(this.selectedTask.Tags);
    else
      return [];
  }

  GetKeysContext(): string[] {
    if (this.selectedTask.Context != undefined && this.selectedTask.Context != null)
      return Object.keys(this.selectedTask.Context);
    else
      return [];
  }

  GetKeysExceptions(): string[] {
    if (this.selectedTask.Exception != undefined && this.selectedTask.Exception != null && this.selectedTask.Exception.length > 0)
      return Object.keys(this.selectedTask.Exception[0]);
    else
      return [];
  }

  GetKeysEventLog(): string[] {
    if (this.selectedTask.EventLog != undefined && this.selectedTask.EventLog != null && this.selectedTask.EventLog.length > 0)
      return Object.keys(this.selectedTask.EventLog[0]);
    else
      return [];
  }

  showCriteria = true;
  hideShowCriteria() {
    this.showCriteria = !this.showCriteria;
    this.setHeight();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    if(this.showCriteria){
      jQuery('.gridHeight').css('height', (Utilities.getScrollHeight(true))-120);
      }else{
      jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true));
      }
  }

  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }
  onGridSizeChanged($event) {
    this.gridOptions.api.sizeColumnsToFit();
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // Duration(params) {
  //   var date = new DatePipe('en-US');
  //   return date.transform(params, 'dd-MMM-y, hh:mm:ss aa');
  // }

  selectedRow = null
  onRowDoubleClicked(param) {
    if (this.selectedRow != null) {
      this.selectedRow.setExpanded(false);
    }
    if (param.node != null && param.node.group == false) {
      this.selectedRow = param.node;
      this.selectedRow.setSelected(true);
      this.selectedRow.setExpanded(true);
    }
  }

  filteredResponse;
  searchText(event) {
    this.filteredResponse = [];
    var query = event.target.value.toLowerCase();
    let searchItems = this.tasksDetails;
    if (query == "") this.filteredResponse = searchItems;
    else {
      searchItems.forEach(searchItem => {
        let isMatch = false;
        let searchString = JSON.stringify(searchItem);
        isMatch = searchString.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        if (isMatch) this.filteredResponse.push(searchItem);
      });
    }
    this.gridOptions.api.setRowData(this.filteredResponse);
    this.gridOptions.api.sizeColumnsToFit();
    this.onGridReady(this);
    this.setHeight();
  }
}

function StartTimeFormatter(params) {
  if (!params.data) {
    var date = new DatePipe('en-US');
    return date.transform(params.StartTime, 'dd-MMM-y, hh:mm:ss aa');
  } else {
    var date = new DatePipe('en-US');
    return date.transform(params.data.StartTime, 'dd-MMM-y, hh:mm:ss aa');
  }
}

function EndTime(params) {
  if (!params.data) {
    var date = new DatePipe('en-US');
    return date.transform(params.EndTime, 'dd-MMM-y, hh:mm:ss aa');
  } else {
    var date = new DatePipe('en-US');
    return date.transform(params.data.EndTime, 'dd-MMM-y, hh:mm:ss aa');
  }
}

function Status(params) {
  if (!params.data)
    return TaskStatus[params.value];
  else
    return TaskStatus[params.data.Status];
}

function formatHotelName(params) {
  return (params.value != undefined && params.value != null) ? params.value : "";
}

function ChannelName(params) {
  if (params.value == undefined || params.value == null) return "";
  else return Utilities.findId(Utilities.channelTypes, params.value, "");
}

function ElaspsedTime(params) {
  let seconds = 1000;
  if (!params.data) {
    if (params.EndTime && params.StartTime) {
      var endTime = new Date(params.EndTime)
      var startTime = new Date(params.StartTime);
      return Math.round((endTime.getTime() - startTime.getTime()) / seconds);
    }
    else {
      return 0;
    }
  }
  else {
    if (params.data.EndTime && params.data.StartTime) {
      var endTime = new Date(params.data.EndTime)
      var startTime = new Date(params.data.StartTime);
      return Math.round((endTime.getTime() - startTime.getTime()) / seconds);
    }
    else {
      return 0;
    }
  }

}

function keyCreatorChannelName(params) {
  if (params.value == null || params.value == undefined) return "";
  return params.value;
}

function valueGetterChannelName(params) {
  if (params.data && params.data.ChannelCode != null && params.data.ChannelCode != null)
    return params.data.ChannelCode
  else
    return "";
}

function valueGetterStartDate(params) {
  let currDate = new Date();
  if (params.data && params.data.StartTime) {
    let startDate = new Date(params.data.StartTime);
    let numberOfDays = Utilities.dateDifference(startDate, currDate)
    if (currDate.getDate() == startDate.getDate() && currDate.getMonth() == startDate.getMonth() && currDate.getFullYear() == startDate.getFullYear())
      return "Today";
    else if (numberOfDays < 7)
      return new DatePipe('en-US').transform(startDate, "EEEE");
    else if (numberOfDays < 61)
      return (currDate.getMonth() == startDate.getMonth()) ? "This Month" : new DatePipe('en-US').transform(startDate, "MMMM");
    else if (numberOfDays < 366 && (currDate.getFullYear() == startDate.getFullYear()))
      return "This Year";
    else
      return "Year: " + startDate.getFullYear();
  }
}

function valueGetterEndDate(params) {
  let currDate = new Date();
  if (params.data && params.data.EndTime) {
    let endDate = new Date(params.data.EndTime);
    let numberOfDays = Utilities.dateDifference(endDate, currDate)
    if (currDate.getDate() == endDate.getDate() && currDate.getMonth() == endDate.getMonth() && currDate.getFullYear() == endDate.getFullYear())
      return "Today";
    else if (numberOfDays < 7)
      return new DatePipe('en-US').transform(endDate, "EEEE");
    else if (numberOfDays < 61)
      return (currDate.getMonth() == endDate.getMonth()) ? "This Month" : new DatePipe('en-US').transform(endDate, "MMMM");
    else if (numberOfDays < 366 && (currDate.getFullYear() == endDate.getFullYear()))
      return "This Year";
    else
      return "Year: " + endDate.getFullYear();
  }
}

function valueGetterDomainName(params) {
  if (params.data && params.data.DomainId != null && params.data.DomainId != null)
    return params.data.DomainId;
  else
    return "";
}

function valueGetterHotelName(params) {
  if (params.data && params.data.HotelId != null && params.data.HotelId != null)
    return params.data.HotelId;
  else
    return "";
}

function valueGetterUser(params) {
  if (params.data && params.data.UserId != null && params.data.UserId != null)
    return params.data.UserId;
  else
    return "";
}

