import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { StorageService } from './storage.service';
import { AppService, HotelSearchCriteria, DomainProfileCriteria, StayInfo } from 'mantras-api';
import { ServiceProxy } from 'mantras-api/lib/ServiceProxy';
import { BookingCriteria, HotelBooking, BookingConfirmation } from 'mantras-api';
import { DomainContext } from 'mantras-api';
import { Utilities } from './utilities';
import { LoadingService } from './loading.service';
import { DatePipe } from '@angular/common';
import { TaskbarService } from './taskbar.service';
import { DatexPipe } from '../components/transformer/datexpipe.transformer';
import * as moment from 'moment';
import { AppConfigService } from '../app-config.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class BookingService {
    //mantras: AppService;
    statusMap = { 0: "UnSpecified", 1: "Provisional", 2: "Confirmed", 3: "Cancelled", 4: "Modified", 5: "WaitingList", 6: "NoShow" };
    paymentStatusMap = { 0: "Prepaid", 1: "Pay@Hotel", 2: "Direct" };

    constructor(private mantras: AppService, private http: Http, private storageService: StorageService, private utilities: Utilities,
        private loadingService: LoadingService,private appConfService:AppConfigService, private taskService: TaskbarService) {
        Utilities.initApp(mantras,appConfService);
    }

    findBookings(criteria: BookingCriteria) {
        return this.loadingService.interceptor(this.mantras.findBookings(criteria)
            .then(async result => {
                let newBookings = [];
                let currentUser = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
                let hotelInfos = new Map<string, string>(); //Indexed Collection
                let domainInfos = new Map<string, string>();

                if (currentUser == "System" && result.length > 0) {
                    let domainIds = [];
                    let hotelIds = [];
                    result.forEach(element => {
                        if (!hotelIds.includes(element.HotelId))
                            hotelIds.push(element.HotelId);
                        if (!domainIds.includes(element.DomainId))
                            domainIds.push(element.DomainId);
                    });

                    let domainCriteria = new DomainProfileCriteria();
                    domainCriteria.Ids = domainIds;
                    await this.mantras.FindDomains(domainCriteria).then(response => {
                        response.forEach(resp => {
                            domainInfos.set(resp.Id, resp.Name)
                        });
                    });

                    let hotelCriteria = new HotelSearchCriteria();
                    hotelCriteria.HotelIds = hotelIds;
                    await this.mantras.FindHotelInfo(hotelCriteria).then(response => {
                        response.forEach(resp => {
                            hotelInfos.set(resp.Id, resp.Name)
                        });
                    });
                    result.forEach(r => {
                        let newBooking = this.transformBooking(r);
                        if (newBooking) {
                            newBooking.Hotel = hotelInfos.get(r.HotelId);
                            newBooking.Domain = domainInfos.get(r.DomainId);
                            newBookings.push(newBooking);
                        }
                    });
                } else {
                    result.forEach(r => {
                        let newBooking = this.transformBooking(r);
                        if (newBooking)
                            newBookings.push(newBooking);
                    });
                }

                return newBookings;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }
    findBookingsForDashboard(criteria: BookingCriteria) {
        return this.mantras.findBookings(criteria)
            .then(async result => {
                let newBookings = [];
                result.forEach(r => { let newBooking = this.transformBooking(r); if (newBooking) newBookings.push(newBooking); });
                return newBookings;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            });
    }
    async getHotelChannels(hotelId: string) {
        let hotelChannels = [];
        hotelChannels = await this.loadingService.interceptor(this.mantras.getHotelChannels(hotelId)).then(async result => {
            result.forEach(hc => {
                if (hc.IsActive)
                    hotelChannels.push(hc.ChannelCode);
            })
            return hotelChannels;
        }).catch(error => {
            this.loadingService.showErrorDialog(error);
            return error;
        });
        return hotelChannels;
    }

    async syncBookings(hotelId: string, start: Date, end: Date, channelTypes: string[]) {
        let taskIds: string[];
        taskIds = [];
        channelTypes.forEach(async ct => {
            let result = await this.mantras.syncbookings(hotelId, start, end, ct).then(result => {
                this.taskService.addTasks([result], { Start: start, End: end })
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                return null;
            });
            if (result) {
                taskIds.push(result);
            }
        });
    }

    confirmBookings(hotelId: string, channelType: string, confirmations: BookingConfirmation[]) {
        return this.loadingService.interceptor(this.mantras.confirmBookings(hotelId, channelType, confirmations)
            .then(async result => {
                this.taskService.addTasks([result])
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }
    saveFile(criteria: BookingCriteria) {
        this.mantras.exportBookings(criteria).then(response => {
            Utilities.saveToFileSystem(response, "ExportBooking.xlsx");
        });
    }

    SendBookingVoucher(bookingIds) {
        return this.loadingService.interceptor(this.mantras.sendBookingVoucher(bookingIds)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    transformBooking(booking: HotelBooking): FormattedBooking {
        try {
            var guestCount = 0;
            var roomCount = 0;
            if (booking.StayInfo != null) {
                if (booking.StayInfo.Rooms) {
                    booking.StayInfo.Rooms.forEach(c => {
                        guestCount = guestCount + c.Adults;
                        guestCount = guestCount + c.Children;
                    })
                }
                else {
                    if (booking.Rooms.length > 0 && booking.Rooms != null) {
                        booking.Rooms.forEach(r => {
                            r.Rates.forEach(rr => {
                                if (r.Occupants && rr.StayDate == booking.StayInfo.InDate) {
                                    guestCount += r.Occupants.Adults;
                                    guestCount += r.Occupants.Children;
                                }
                            })
                        });
                    }
                }
            }
            else {
                if (booking.Rooms.length > 0 && booking.Rooms != null) {
                    booking.Rooms.forEach(r => {
                        r.Rates.forEach(rr => {
                            if (r.Occupants) {
                                guestCount += r.Occupants.Adults;
                                guestCount += r.Occupants.Children;
                            }
                        })
                    });
                }
            }
            if (booking.Rooms.length > 0 && booking.StayInfo) {
                booking.Rooms.forEach(r => {
                    r.Rates.forEach(rr => {
                        if (rr.StayDate == booking.StayInfo.InDate) {
                            roomCount++;
                        }
                    })
                });
            }
            let transformedBooking = new FormattedBooking();
            transformedBooking.Booking = booking;
            transformedBooking.Hotel = Utilities.findHotelNameById(booking.HotelId, booking.DomainId, this.storageService);
            transformedBooking.Id = booking.Id;
            transformedBooking.Domain = booking.DomainId;
            transformedBooking.Status = this.statusMap[booking.Status];
            let vendorCode = booking.ChannelCode;
            if (booking.ChannelCode == "GIB") {
                let tmpVendorCode = this.checkVendorCode(booking.Tags);
                if (tmpVendorCode) vendorCode = tmpVendorCode;
            }
            transformedBooking.Channel = Utilities.findId(Utilities.channelTypes, vendorCode, "UnSpecified");
            var date = new DatePipe('en-US');
            //date.transform(params.TimeStamp, 'dd-MMM-y, hh:mm:ss aa');
            transformedBooking.CreatedOn = date.transform(this.localizeDateStr(booking.CreatedOn.toLocaleString()), 'dd-MMM-y, hh:mm:ss aa');
            transformedBooking.PaymentStatus = this.paymentStatusMap[booking.PaymentType];
            if (booking.Tags) {
                if(booking.Tags["Timestamp"])
                    transformedBooking.LastSyncedOn = date.transform(this.localizeDateStr(Utilities.convertToUTC(moment(booking.Tags["Timestamp"], "DD-MMM-YYYY hh:mm:ss").toDate())), 'dd-MMM-y, hh:mm:ss aa');
                transformedBooking.PushedToPMS = booking.Tags["PortedStatus"];
                if (booking.Tags["RetrievedTimestamp"])
                    transformedBooking.PortAt = date.transform(this.localizeDateStr(Utilities.convertToUTC(moment(booking.Tags["RetrievedTimestamp"], "DD-MMM-YYYY hh:mm:ss").toDate())), 'dd-MMM-y, hh:mm:ss aa');
                transformedBooking.Notes = booking.Tags["N-Notes"];
            }
            transformedBooking.Tags = [];
            if (booking.Tags) {
                let tags = booking.Tags;
                Object.keys(tags).map((field) => {
                    if (field != "Timestamp" && field != "PortedStatus" && field != "RetrievedTimestamp" && field != "N-Notes") {
                        transformedBooking.Tags.push({ 'key': field, 'value': tags[field] });
                    }
                })
            }


            transformedBooking.BasicDetails = new BasicDetails();
            if (booking.StayInfo) {
                transformedBooking.BasicDetails.CheckIn = new DatePipe('en-US').transform(Utilities.getUTCDate(new Date(booking.StayInfo.InDate)), "dd-MMM-yyyy");
                transformedBooking.BasicDetails.Checkout = new DatePipe('en-US').transform(Utilities.getUTCDate(new Date(booking.StayInfo.OutDate)), "dd-MMM-yyyy");
                transformedBooking.BasicDetails.Duration = Utilities.dateDifference(new Date(booking.StayInfo.InDate), new Date(booking.StayInfo.OutDate)) - 1;
            }
            else{
                booking.StayInfo = new StayInfo();
                transformedBooking.BasicDetails.CheckIn = "-";
                transformedBooking.BasicDetails.Checkout = "-";
                transformedBooking.BasicDetails.Duration = 0;
            }
            transformedBooking.BasicDetails.GuestCount = guestCount;
            transformedBooking.BasicDetails.RoomCount = roomCount

            transformedBooking.GuestDetails = new GuestDetails();
            if (booking.ContactInfo) {
                transformedBooking.GuestDetails.Email = booking.ContactInfo.Email != null ? booking.ContactInfo.Email : "--";
                if (booking.ContactInfo.Phones && booking.ContactInfo.Phones != null && booking.ContactInfo.Phones.length > 0)
                    transformedBooking.GuestDetails.Phone = booking.ContactInfo.Phones[0].Number
                else transformedBooking.GuestDetails.Phone = "--";
                transformedBooking.GuestDetails.Name = booking.ContactInfo.LastName == null ? booking.ContactInfo.FirstName : booking.ContactInfo.FirstName + " " + booking.ContactInfo.LastName;
            }
            transformedBooking.CardDetails = new CardDetails();
            if (booking.CreditCard && booking.CreditCard != null) {
                transformedBooking.CardDetails.BillingAddress = booking.CreditCard.BillingAddress == null ? "--" : booking.CreditCard.BillingAddress.Street + ", " + booking.CreditCard.BillingAddress.City;
                transformedBooking.CardDetails.Expiration = booking.CreditCard.Expiration == null ? "--" : new DatePipe('en-US').transform(Utilities.getUTCDate(new Date(booking.CreditCard.Expiration)), "MM-yyyy");
                transformedBooking.CardDetails.Name = booking.CreditCard.Name == null ? "--" : booking.CreditCard.Name;
                transformedBooking.CardDetails.Number = booking.CreditCard.Number == null ? "--" : booking.CreditCard.Number;
                transformedBooking.CardDetails.Type = booking.CreditCard.Type == null ? "--" : booking.CreditCard.Type;
                transformedBooking.CardDetails.ValidationCode = booking.CreditCard.ValidationCode == null ? "--" : booking.CreditCard.ValidationCode;
            }
            else {
                transformedBooking.CardDetails.BillingAddress = "--"
                transformedBooking.CardDetails.Expiration = "--"
                transformedBooking.CardDetails.Name = "--";
                transformedBooking.CardDetails.Number = "--";
                transformedBooking.CardDetails.Type = "--";
                transformedBooking.CardDetails.ValidationCode = "--";
            }
            transformedBooking.TotalAmt = new Rates();
            transformedBooking.TotalAmt.NetAmt = 0;
            transformedBooking.TotalAmt.SellAmt = 0;
            transformedBooking.TotalAmt.Commission = 0;
            transformedBooking.TotalAmt.TaxAmt = 0;
            transformedBooking.TotalAmt.ExtraCharges = 0;
            transformedBooking.TotalAmt.Total = 0;
            if (booking.Rooms.length > 0 && booking.Rooms != null) {
                booking.Rooms.forEach(r => {
                    if (r.TotalAmounts) {
                        transformedBooking.TotalAmt.NetAmt += r.TotalAmounts.NetAmount;
                        transformedBooking.TotalAmt.SellAmt += r.TotalAmounts.SellAmount;
                        transformedBooking.TotalAmt.Commission += r.TotalAmounts.Commision
                        transformedBooking.TotalAmt.TaxAmt += r.TotalAmounts.TaxAmount
                        if (r.TotalAmounts.ExtraCharges) {
                            let extraCharges = r.TotalAmounts.ExtraCharges;
                            Object.keys(extraCharges).map((field) => {
                                transformedBooking.TotalAmt.ExtraCharges += extraCharges[field];
                            });
                        }
                        transformedBooking.TotalAmt.Total += r.TotalAmounts.TotalAmount
                    }
                });
            }
            transformedBooking.TotalAmt.NetAmt = Math.round(transformedBooking.TotalAmt.NetAmt * 100) / 100;
            transformedBooking.TotalAmt.SellAmt = Math.round(transformedBooking.TotalAmt.SellAmt * 100) / 100;
            transformedBooking.TotalAmt.Commission = Math.round(transformedBooking.TotalAmt.Commission * 100) / 100;
            transformedBooking.TotalAmt.TaxAmt = Math.round(transformedBooking.TotalAmt.TaxAmt * 100) / 100;
            transformedBooking.TotalAmt.ExtraCharges = Math.round(transformedBooking.TotalAmt.ExtraCharges * 100) / 100;
            transformedBooking.TotalAmt.Total = Math.round(transformedBooking.TotalAmt.Total * 100) / 100;
            transformedBooking.Rooms = [];
            let taxOnnet: number = 0;
            if (booking.Rooms.length > 0 && booking.Rooms != null) {
                booking.Rooms.forEach(r => {
                    var room = new Rooms();
                    if (r.GuestDetails) {
                        room.GuestDetails = new GuestDetails();
                        room.GuestDetails.Adults = (r.Occupants) ? r.Occupants.Adults : 0;
                        room.GuestDetails.Children = (r.Occupants) ? r.Occupants.Children : 0;
                        room.GuestDetails.Email = r.GuestDetails.Email != null ? r.GuestDetails.Email : "--";
                        if (r.GuestDetails.FirstName == null && r.GuestDetails.LastName == null)
                            room.GuestDetails.Name = transformedBooking.GuestDetails.Name;
                        else room.GuestDetails.Name = r.GuestDetails.LastName == null ? r.GuestDetails.FirstName : r.GuestDetails.FirstName + " " + r.GuestDetails.LastName;
                    }
                    room.DayRate = [];
                    room.TotalAmt = new Rates();
                    room.TotalAmt.NetAmt = room.TotalAmt.SellAmt = room.TotalAmt.Commission = room.TotalAmt.TaxAmt = room.TotalAmt.Total = 0;
                    room.TotalAmt.ExtraCharges = 0.0;
                    r.Rates.forEach(rate => {
                        var dayrate = new Rates();
                        dayrate.StayDate = new DatePipe('en-US').transform(Utilities.getUTCDate(new Date(rate.StayDate)), "dd-MMM");
                        dayrate.NetAmt = Math.round(rate.AmountItem.NetAmount * 100) / 100;
                        dayrate.SellAmt = Math.round(rate.AmountItem.SellAmount * 100) / 100;
                        dayrate.Commission = Math.round(rate.AmountItem.Commision * 100) / 100;
                        dayrate.TaxAmt = Math.round(rate.AmountItem.TaxAmount * 100) / 100;
                        if (rate.AmountItem.TotalAmount != 0)
                            dayrate.Total = Math.round(rate.AmountItem.TotalAmount * 100) / 100;
                        else {
                            if (dayrate.SellAmt != 0)
                                dayrate.Total = Math.round((dayrate.SellAmt + dayrate.TaxAmt) * 100) / 100;
                            else dayrate.Total = Math.round((dayrate.NetAmt + dayrate.Commission + dayrate.TaxAmt) * 100) / 100
                        }
                        dayrate.ExtraCharges = 0.0;
                        if (rate.AmountItem.ExtraCharges && !Utilities.isEmptyObject(rate.AmountItem.ExtraCharges)) {
                            let dayRate_extracharges = rate.AmountItem.ExtraCharges;
                            Object.keys(dayRate_extracharges).map((field) => {
                                dayrate.ExtraCharges += dayRate_extracharges[field];
                            });
                            room.TotalAmt.ExtraCharges += dayrate.ExtraCharges;
                        }
                        else {
                            dayrate.ExtraCharges = 0.0;
                        }
                        room.DayRate.push(dayrate);
                    });
                    if (r.TotalAmounts) {
                        room.TotalAmt.NetAmt += r.TotalAmounts.NetAmount;
                        room.TotalAmt.SellAmt += r.TotalAmounts.SellAmount;
                        room.TotalAmt.Commission += r.TotalAmounts.Commision;
                        room.TotalAmt.TaxAmt += r.TotalAmounts.TaxAmount;
                        room.TotalAmt.Total += r.TotalAmounts.TotalAmount;
                    }
                    room.RoomId = r.RoomId;
                    room.RoomName = r.RoomName != null ? r.RoomName : r.RoomId;
                    room.RateplanName = r.RatePlanName != null ? r.RatePlanName : r.RatePlanId;
                    room.RatePlanId = r.RatePlanId;
                    room.Tags = [];
                    if (r.Tags) {
                        let tags = r.Tags;
                        Object.keys(tags).map((field) => {
                            room.Tags.push({ 'key': field, 'value': tags[field] });
                            if (field == "Tax_on_Net") taxOnnet += parseInt(tags[field]);
                        })
                    }
                    transformedBooking.Rooms.push(room);
                });
            }
            else {
                var room = new Rooms();
                room.GuestDetails = new GuestDetails();
                room.GuestDetails.Adults = 0;
                room.GuestDetails.Children = 0;
                room.GuestDetails.Email = "--";
                room.GuestDetails.Name = "--";
               var dayRate = new Rates();
                dayRate.OTAToHotel = 0;
                dayRate.StayDate = "--";
                dayRate.Total = 0;
                dayRate.SellAmt  = 0;
                dayRate.TaxAmt  = 0;
                dayRate.Commission  = 0;
                dayRate.ExtraCharges  = 0;
                dayRate.NetAmt  = 0;
                room.TotalAmt = new Rates();
                room.TotalAmt.Total = 0;
                room.TotalAmt.SellAmt=0;
                room.TotalAmt.NetAmt=0;
                room.TotalAmt.TaxAmt=0;
                room.TotalAmt.Commission=0;
                room.TotalAmt.ExtraCharges=0;
                room.RoomId = "--";
                room.RatePlanId = "--";
                room.RoomName = "--";
                transformedBooking.Rooms.push(room);
            }
            transformedBooking.RoomName = (transformedBooking.Rooms && transformedBooking.Rooms.length > 0) ? transformedBooking.Rooms[0].RoomName : transformedBooking.Rooms[0].RoomId;
            transformedBooking.RoomName += (transformedBooking.Rooms && transformedBooking.Rooms.length > 1) ? "..." : "";
            var tax = +taxOnnet;
            if (Utilities.isTaxOnSellOta(booking.ChannelCode)) {
                transformedBooking.TotalAmt.OTAToHotel = transformedBooking.TotalAmt.NetAmt + transformedBooking.TotalAmt.TaxAmt + transformedBooking.TotalAmt.ExtraCharges;
            }
            else transformedBooking.TotalAmt.OTAToHotel = transformedBooking.TotalAmt.NetAmt + (tax) + transformedBooking.TotalAmt.ExtraCharges;
            transformedBooking.TotalAmt.OTAToHotel = Math.round(transformedBooking.TotalAmt.OTAToHotel * 100) / 100;

            if (booking.Tags && booking.Tags["BookingVendorName"]) {
                if (booking.Tags["BookingVendorName"] == "MakeMyTrip")
                    transformedBooking.TotalAmt.OTAToHotel = Math.ceil(transformedBooking.TotalAmt.OTAToHotel)
            }
            return transformedBooking;
        } catch (ex) {
            console.error("Error in transforming  booking: " + booking.Id + " ", ex);
            return null;
        }
    }

    localizeDateStr(date_to_convert_str) {
        return new Date(new DatexPipe().transform(date_to_convert_str, "DD-MMM-YYYY HH:mm:ss"));
    }
    checkVendorCode(tags) {
        if (tags && tags["BookingVendorName"])
            if (tags["BookingVendorName"] == "MakeMyTrip") return "MMT";
            else return "GIB";
    }
}
export class FormattedBooking {
    Booking: HotelBooking;
    Hotel: string;
    Id: string;
    Domain: String;
    Status: string;
    Channel: string;
    CreatedOn: string;
    PaymentStatus: string;
    LastSyncedOn: string;
    PushedToPMS: string;
    PortAt: string;
    Notes: string;
    BasicDetails: BasicDetails;
    GuestDetails: GuestDetails;
    CardDetails: CardDetails;
    TotalAmt: Rates;
    Rooms: Rooms[];
    RoomName: string;
    Tags: any[];
    Code: string;

}
export class BasicDetails {
    CheckIn: string;
    Checkout: string;
    Duration: number;
    RoomCount: number;
    GuestCount: number;
}
export class GuestDetails {
    Name: string;
    Phone: string;
    Email: string;
    Adults: number;
    Children: number;
}
export class CardDetails {
    Name: string;
    Type: string;
    Number: string;
    Expiration: string;
    ValidationCode: string;
    BillingAddress: string;
}
export class Rates {
    NetAmt: number;
    StayDate: string;
    Commission: number;
    SellAmt: number;
    TaxAmt: number;
    ExtraCharges: number;
    Total: number;
    OTAToHotel: number;
}
export class Rooms {
    GuestDetails: GuestDetails;
    DayRate: Rates[];
    TotalAmt: Rates;
    RoomId: string;
    RoomName: string;
    RateplanName: string;
    RatePlanId: string;
    Tags: any[];
}

