import { Injectable } from "@angular/core";
import { AppService } from "mantras-api";
import { LoadingService } from "./loading.service";
import { Utilities } from "./utilities";
import { Range } from "mantras-api";
import { BookingStatus } from "mantras-api";
import { AlertRuleCriteria, v5AlertRuleBase, RateRuleCriteria, RateRule } from "mantras-api";
import { AppConfigService } from "../app-config.service";

@Injectable()
export class AlertsService {
    //mantras: AppService;
    constructor(private mantras:AppService,private utilities: Utilities, private loadingService: LoadingService,
        private appConfService:AppConfigService) {
        Utilities.initApp(mantras,appConfService);
    }

    findAlertRules(alertCriteria:AlertRuleCriteria) {
        console.log("Inside the FindAlertRule.");
        let response = this.loadingService.interceptor(this.mantras.findAlertRules(alertCriteria).then
          (response => {
              return response;
        }),true);
        return response;
    }

    deleteAlertRules(alertCriteria:AlertRuleCriteria) {
        console.log("Inside the DeleteAlertRule.");
        let response = this.loadingService.interceptor(this.mantras.deleteAlertRules(alertCriteria).then
          (response => {
              return response;
        }),true);
        return response;
    }

    saveAlertRule(alertRuleBase: v5AlertRuleBase){
        console.log("Inside the DeleteAlertRule.");
        let response = this.loadingService.interceptor(this.mantras.saveAlertRules(alertRuleBase.HotelId,alertRuleBase).then
          (response => {
              return response;
        }),true);
        return response;
    }

    findRateRules(rateCriteria:RateRuleCriteria) {
        console.log("Inside the FindRateRule.");
        let response = this.loadingService.interceptor(this.mantras.findRateRules(rateCriteria).then
          (response => {
              return response;
        }),true);
        return response;
    }

    deleteRateRules(rateCriteria:RateRuleCriteria) {
        console.log("Inside the DeleteRateRule.");
        let response = this.loadingService.interceptor(this.mantras.deleteRateRules(rateCriteria).then
          (response => {
              return response;
        }),true);
        return response;
    }

    saveRateRule(rateRule: RateRule){
        console.log("Inside the DeleteRateRule.");
        let response = this.mantras.saveRateRules(rateRule.HotelId,rateRule).then
          (response => {
              return response;
        });
        return response;
    }
}

export enum AlertRuleType{
    HotelAvailabilityRule=0,
    HotelBookingRule=1,
    ChannelRateRule=2
}

export class v5ActionType {
    static SendEmail=["SendNotification","Indigo.Optimus.Alerts.Helper"];
    static ChargeCard=["ChargeCard","Indigo.Optimus.Alerts.Helper"];
    static PullCompRate=["PullCompRates","Indigo.Optimus.Alerts.Helper"];
}

export class NotificationTemplate {
    static EmailTemplate = "Indigo.Optimus.Managers.AlertBaseNotification";
    static BookingTemplate = "Indigo.Optimus.Managers.BookingNotification";
    static TransactionTemplate = "Indigo.Optimus.Managers.TransactionEmail";
}