import { Component, OnInit, ViewChild, ComponentFactoryResolver, HostListener } from '@angular/core';
import { GridOptions, ColDef } from 'ag-grid';
import { RatecalendarService, RRPCellFilter, GridCellData } from '../ui-services/ratecalendar.service';
import { DatePipe } from '@angular/common';
import { StorageService } from '../ui-services/storage.service';
import { RateCalendarEditorComponent } from '../ratecalendar/ratecalendarEditor.component';
import { ActivatedRoute, Params } from '@angular/router';
import { RoomRatePlanFieldsComponent } from './roomrateplanfields.component';
import { RoomRatePlanFieldComponent } from './roomrateplanfield.component';
import { RoomRatePlanFieldsEditorComponent } from './roomrateplanfieldsEditor.component';
import { RateCalendarCriteria, SyncLogCriteria, RateFormat } from 'mantras-api';
import { DateRange } from 'mantras-api';
import { GroupingLabelComponent } from './groupinglabel.component';
import { Subscription, forkJoin } from 'rxjs';
import { RoomRatePlanFieldEditorComponent } from './roomrateplanfieldEditor.component';
import { MenuItem, Dropdown, OverlayPanel, Dialog, DataTable } from 'primeng/primeng';
import { Utilities } from '../ui-services/utilities';
import { EventManagementService } from '../ui-services/eventmanagement.service';
import { UnsavedChangesGuard } from '../ui-services/unsavedchanges.guard';
import { AuthenticationService } from '../ui-services/authentication.service';
import { RoomPricingModel } from 'mantras-api';
import { LoadingService } from '../ui-services/loading.service';
import { DatexPipe } from '../components/transformer/datexpipe.transformer';
import { UserService } from '../ui-services/user.service';
import { TaskbarService } from '../ui-services/taskbar.service';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-ratecalendarviews',
  templateUrl: './ratecalendarviews.component.html',
  styleUrls: ['./ratecalendarviews.component.css']
})
export class RatecalendarviewsComponent implements OnInit {
  @ViewChild('bulkOptions') bulkOptions: Dialog;
  @ViewChild('saveOptions') saveOptions: Dialog;
  @ViewChild('syncHistoryLogs') syncHistoryLogs: Dialog;
  //Static Defualt Views
  views = [
    { grouping: ["channelType", "roomTypeId", "ratePlanId"], column: "roomRatePlanFields", filter: [] },
    { grouping: ["roomRatePlanFields", "masterRoomTypeId", "masterRatePlanId"], column: "channelType", filter: [] },
    { grouping: ["roomTypeId", "ratePlanId"], column: "roomRatePlanFields", filter: [{ key: "roomRatePlanFields", value: ["availability"] }, { key: "channelType", value: ["MAX"] }] },
    { grouping: ["roomTypeId", "ratePlanId"], column: "roomRatePlanFields", filter: [{ key: "roomRatePlanFields", value: ["perDay"] }, { key: "channelType", value: ["MAX"] }] }];

  rcviews = [
    { label: 'Standard View', value: '0' },
    { label: 'Availability View', value: '2' },
    { label: 'Rate View', value: '3' },
    { label: 'Rate Parity View', value: '1' }
  ];
  selectedrcView = { label: 'Standard View', value: '0' };

  //Rate Calendar Menu Item
  rateCalMenuItems: MenuItem[];
  gridOptions: GridOptions;
  columnDefs: any[] = Array();
  rateCalendarData: any[] = Array();
  rowData: any[] = Array();
  rateCalCriteria: RateCalendarCriteria;
  viewId = 0;
  rrpFilter: RRPCellFilter;
  subscription: Subscription;
  rawRateCalendarResponseData;
  modifiedRateCalendarResponseData;
  fetchData = true;
  navigationDate = new Date();
  icons;
  showSaveRateCalendarDialog = false;
  selectedChannelTypes = [];
  availableChannelTypes = [];
  sourceChannelTypes = [];
  destinationChannelTypes = [];
  filterChannelTypes = ["MAX"];
  filterRooms;
  filterRatePlans;
  domainHotel;
  hotelChannel;
  isReadOnly = false;
  taskCompleteNotificationSubscription: Subscription;
  manualRefresh = false;
  showExport = false;
  yearRange;
  reCalSyncYearRange;
  inventoryOnly = false;
  getColumnMenuItems;
  displayDays = 9;
  constructor(private activatedRoute: ActivatedRoute,
    private rateCalendarService: RatecalendarService,
    private storageService: StorageService, private resolver: ComponentFactoryResolver,
    private eventManager: EventManagementService, private unsavedchangesGuard: UnsavedChangesGuard,
    private authenticationService: AuthenticationService, private loadingService: LoadingService,
    private userService: UserService, private taskbarService: TaskbarService) {

    this.rrpFilter = rateCalendarService.rrpFilter;
    //Initializing the GridOptions
    this.gridOptions = <GridOptions>{
      enableSorting: false,
      animateRows: true,
      groupDefaultExpanded: -1,
      rowHeight: 24,
      groupUseEntireRow: true,
      singleClickEdit: true,
      groupRowRendererParams: { suppressCount: true },
      groupRowInnerRendererFramework: GroupingLabelComponent,
      suppressDragLeaveHidesColumns: true,
      localeText: { noRowsToShow: "No rates found. Please load rates or pull from extranet" }
    };
    //Highlight first group row 
    this.gridOptions.getRowClass = function (params) {
      if (params.node.group && params.node.level == 0) {
        return 'group-background';
      }
    }
    this.gridOptions.suppressLoadingOverlay = true;
    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }

    //Hotel Context Change Event Subscription
    this.subscription = storageService.userContextListener$.subscribe(
      async userContext => {
        this.rateCalendarData = Array();
        this.remoteChannelData = new Map();
        this.localChannelData = new Map();
        this.modifiedChannelData = new Map();
        this.rowData = Array();
        this.filterRooms = null;
        this.filterRatePlans = null;
        this.filterChannelTypes = ['MAX'];
        let hotelId = this.storageService.get(StorageService.userHotelContext).HotelId;
        this.domainHotel = this.authenticationService.getDomainHotel(hotelId);
        this.hotelChannel = await this.authenticationService.getHotelChannels(hotelId);
        this.isReadOnly = this.userService.isReadOnly();
        this.manualRefresh = false;
        this.loadMenu();
        this.applyViewFilter();
        this.getRateCalendar();
      });

    this.taskCompleteNotificationSubscription = taskbarService.taskCompleteListener$.subscribe(
      tasks => {
        let taskStatus = this.taskbarService.getTaskStatus(tasks, TaskbarService.RateCalendar);
        if (taskStatus) {
          this.isDirty();
          if (this.modifiedRRP.length == 0)
            this.onRefresh();
          else
            this.manualRefresh = true;
        }
      }
    );
    //Set the current class context (this) in gridOption context needed for context menu events.
    this.gridOptions.context = { thisComponent: this };
    this.isReadOnly = userService.isReadOnly();

    var dt = new Date();
    this.reCalSyncYearRange = "" + dt.getFullYear();
    dt.setMonth(dt.getMonth() - 6);
    this.yearRange = "" + dt.getFullYear();
    dt.setFullYear(dt.getFullYear() + 5, dt.getMonth() + 6);
    this.yearRange += ":" + dt.getFullYear();
    this.reCalSyncYearRange += ":" + dt.getFullYear();

    this.getColumnMenuItems = function getMainMenuItems(params) {
      console.log("Column Id", params.column.getId());
      var columnMenuItems = []
      columnMenuItems.push({
        name: "Bulk Edit (Pre-filled Master RRP)",
        action: function (option) {
          //var newParam = params;
          params.context.thisComponent.showBulkEdit(params);
        }
      });
      return columnMenuItems;
    }
  }

  //#region Angular Component Lifecycle Methods
  async ngOnInit() {
    //Initializing Menu Items 
    this.loadMenu();
    // subscribe to router event
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params['viewid'])
        this.viewId = Number.parseInt(params['viewid']);
      console.log("View Id: " + this.viewId);
    });
    let hotelId = this.storageService.get(StorageService.userHotelContext).HotelId;
    let domainId = this.storageService.get(StorageService.userDomainContext).CurrentDomainId;
    if (this.storageService.reloadHotelContextRequired) {
      await this.authenticationService.switchDomain(domainId, hotelId);
    }
    this.inventoryOnly = this.userService.isInventoryOnly();
    this.domainHotel = this.authenticationService.getDomainHotel(hotelId);
    this.hotelChannel = await this.authenticationService.getHotelChannels(hotelId);
  }

  loadMenu() {
    this.rateCalMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.showConfirmationDialog(event, 'onRefresh') },
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', command: (event) => this.showSaveDialog(event), disabled: this.isReadOnly },
      { title: 'Export to excel', label: 'Export', icon: 'action-bar-menu-icon fa fa-file-excel-o', disabled: !this.showExport, command: (event) => this.showExportDialog(event) },
      { title: 'Sync', label: 'Sync', icon: 'action-bar-menu-icon fa icon ion-md-sync', command: (event) => this.showConfirmationDialog(event, 'showSyncDialog') },
      { title: 'Bulk Edit', label: 'Bulk Edit', icon: 'action-bar-menu-icon fa fa-pencil-square-o', disabled: this.isReadOnly, command: (event) => this.showConfirmationDialog(event, 'showBulkEdit') },
      { title: 'Re-Calculate', label: 'Re-Calculate', icon: 'action-bar-menu-icon fa fa-calculator', disabled: this.isReadOnly, command: (event) => this.showConfirmationDialog(event, 'showReCalculate') }
    ];
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.taskCompleteNotificationSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  //#endregion Angular Component Lifecycle Methods

  onGridReady(params) {
    this.applyViewFilter();
    this.getRateCalendar();
    this.gridOptions.api.sizeColumnsToFit();
  }

  onGridSizeChanged(params) {
    this.gridOptions.api.sizeColumnsToFit();
  }

  @ViewChild('rateCalendarFilter') rateCalendarFilter;
  applyViewFilter() {
    let filterChannels = this.getViewFilterByFieldName("channelType");
    let rrpFieldsFilter = this.getViewFilterByFieldName("roomRatePlanFields");
    if (filterChannels && filterChannels.length > 0)
      this.filterChannelTypes = filterChannels;
    this.rateCalendarService.resetRRPCellFilter(this.domainHotel, this.hotelChannel, this.storageService.get(StorageService.userHotelContext), rrpFieldsFilter);
    this.rrpFilter = this.rateCalendarService.rrpFilter;
    this.rateCalendarFilter.setupRcFilter({ filterChannels: filterChannels, rrpFilter: this.rrpFilter });
  }

  getViewFilterByFieldName(fieldType) {
    let filters = this.views[this.viewId].filter;
    if (filters && filters.length > 0) {
      let filter = filters.find(o => o.key == fieldType);
      if (filter) return filter.value;
    }
    return [];
  }

  onRcFilterChange(filterData) {
    this.rateCalendarData = Array();
    this.rowData = Array();
    this.filterRooms = filterData.Rooms;
    this.filterRatePlans = filterData.RatePlans;
    this.rrpFilter = filterData.RRPFilter;
    if (JSON.stringify(this.filterChannelTypes) != JSON.stringify(filterData.ChannelTypes)) {
      this.filterChannelTypes = filterData.ChannelTypes;
      this.getRateCalendar();
    } else {
      this.getRateCalendar();
    }
  }

  remoteChannelData = new Map();
  localChannelData = new Map();
  modifiedChannelData = new Map();
  async getRateCalendar() {
    // Building Rate Calendar Criteria
    //Default
    this.rateCalCriteria = this.buildRateCalendarCriteria(this.filterChannelTypes);
    let criteria = JSON.parse(JSON.stringify(this.rateCalCriteria));
    let response = [];
    let remoteResponse = [];
    let requestArray = [];
    let requestRef = [];
    //Fetching Data for each channel check before it exist
    for (let channelType of this.rateCalCriteria.ChannelCodes) {
      if (!this.localChannelData.has(channelType)) {
        criteria.ChannelCodes = [channelType];
        criteria.IsRemote = false;
        let req = this.rateCalendarService.getRateCalendarData(criteria);
        requestArray.push(req);
        requestRef.push({ channelType: channelType, isRemote: false });
      } else {
        response = response.concat(this.modifiedChannelData.get(channelType));
        if (channelType == "MAX") remoteResponse = remoteResponse.concat(this.localChannelData.get(channelType));
      }
      if (!this.remoteChannelData.has(channelType) && channelType != "MAX") {
        criteria.ChannelCodes = [channelType];
        criteria.IsRemote = true;
        let req = this.rateCalendarService.getRateCalendarData(criteria);
        requestArray.push(req);
        requestRef.push({ channelType: channelType, isRemote: true });
      } else if (channelType != "MAX") {
        remoteResponse = remoteResponse.concat(this.remoteChannelData.get(channelType));
      }
    }
    //Parallel request for each channel types.
    // Populate the local, remote and modified channel type array.
    //Adding Master in remoteChannelData to display outofsync.
    if (requestArray.length > 0) {
      forkJoin(requestArray).subscribe(results => {
        console.log("Parallel Request: ", results);
        for (let idx = 0; idx < requestRef.length; idx++) {
          let channelType = requestRef[idx].channelType;
          let isRemote = requestRef[idx].isRemote;
          let resp = results[idx];
          if (resp && resp != null) {
            if (resp instanceof Array) {
              if (isRemote) {
                this.remoteChannelData.set(channelType, resp);
                remoteResponse = remoteResponse.concat(resp);
              } else {
                this.localChannelData.set(channelType, JSON.parse(JSON.stringify(resp)));
                this.modifiedChannelData.set(channelType, resp);
                response = response.concat(resp);
                if (channelType == "MAX") remoteResponse = remoteResponse.concat(this.localChannelData.get("MAX"));
              }
            } else {
              if (!isRemote) {
                this.localChannelData.set(channelType, []);
                this.modifiedChannelData.set(channelType, []);
              } else {
                this.remoteChannelData.set(channelType, []);
              }
            }
          }
        }
        this.processRateCalendar(response, remoteResponse);
      });
    } else {
      this.processRateCalendar(response, remoteResponse);
    }
  }

  //This need to be maintained since required for closeOut and invetory at rateplan level config. 
  displayDates;
  pinnedTopRowData = [];
  totalHotelAvailability = [];
  processRateCalendar(response, remoteResponse) {
    this.rateCalendarData = Array();
    this.showExport = response.length > 0;
    this.domainHotel = this.authenticationService.getDomainHotel(this.storageService.get(StorageService.userHotelContext).HotelId);
    this.loadMenu();
    this.displayDates = this.rateCalendarService.getSortedStayDates(response);
    this.totalHotelAvailability = [];
    let data: Map<any, any> = this.rateCalendarService.extractRoomRatePlans(response, this.rateCalCriteria, this.displayDates, remoteResponse, this.filterRooms, this.filterRatePlans, this.totalHotelAvailability);
    if (this.totalHotelAvailability.length > 0)
      this.gridOptions.api.setPinnedTopRowData([this.totalHotelAvailability]);
    else
      this.gridOptions.api.setPinnedTopRowData(null);
    let isGroupingOnRRPFields = this.views[this.viewId].grouping.find((key) => key === "roomRatePlanFields");
    for (let groupKey of Array.from(data.keys())) {
      //Filter Blank entries
      if (data.get(groupKey).roomRatePlans.length > 0) {
        if (!isGroupingOnRRPFields) {
          this.rateCalendarData.push(data.get(groupKey));
        } else {
          let rawGCD: GridCellData = data.get(groupKey);
          let pricingModel = rawGCD.pricingModel;
          let maxOccupancy = rawGCD.maxOccupancy;
          Object.keys(this.rrpFilter).map((field) => {
            if ((field == "perDay" && pricingModel != RoomPricingModel.PerOccupancyPricing) ||
              (field != "perDay" && field != "occupancy")) {
              if (this.rrpFilter[field]) {
                let gcd: GridCellData = rawGCD.clone();
                gcd.roomRatePlanFields = field;
                this.rateCalendarData.push(gcd);
              }
            } else if (field == "occupancy" && pricingModel != RoomPricingModel.PerDayPricing) {
              for (let i = 0; i < this.rrpFilter.occupancy.length; i++) {
                if (i < maxOccupancy && this.rrpFilter.occupancy[i]) {
                  let gcd: GridCellData = rawGCD.clone();
                  gcd.roomRatePlanFields = field + i;
                  this.rateCalendarData.push(gcd);
                }
              }
            }
          });
        }
      }
    }

    this.columnDefs = [];
    //Add Default Group column
    this.columnDefs.push({ headerName: "", showRowGroup: true, menuTabs: [], width: 50 });
    //Grouped Column Feilds 
    this.views[this.viewId].grouping.forEach(element => {
      //For availability view and Inventory is at Room Level
      if (element == "ratePlanId" && this.viewId == 2 && !this.domainHotel.IsInventoryAtRatePlan) {
        //Do nothing
      } else {
        this.columnDefs.push({
          headerName: element, field: element, width: 50,
          rowGroup: true, hide: true, enableValue: false, menuTabs: []
        });
      }
    });

    //Filter the Data on Room Level for Availability View and Inventory at Rate Plan Level
    if (this.viewId == 2 && !this.domainHotel.IsInventoryAtRatePlan) {
      var gridFilterData = [];
      var roomIds = [];
      this.rateCalendarData.forEach(ele => {
        if (roomIds.indexOf(ele.roomTypeId) < 0) {
          gridFilterData.push(ele);
          roomIds.push(ele.roomTypeId);
        }
      });
      this.rateCalendarData = gridFilterData;
    }
    //Rendering column field
    let cellRenderFwk, cellEditorFwk;
    if (this.views[this.viewId].column) {
      let columnDef: ColDef = {
        headerName: "", field: this.views[this.viewId].column, width: 150,
        rowGroup: false, hide: false, enableValue: false, menuTabs: []
      };
      if (this.views[this.viewId].column == "roomRatePlanFields") {
        cellRenderFwk = RoomRatePlanFieldsComponent;
        cellEditorFwk = RoomRatePlanFieldsEditorComponent;
        columnDef.cellRendererFramework = RoomRatePlanFieldsComponent;
      } else {
        cellRenderFwk = RoomRatePlanFieldComponent;
        cellEditorFwk = RoomRatePlanFieldEditorComponent;
        columnDef.cellRendererFramework = GroupingLabelComponent;
      }
      this.columnDefs.push(columnDef);
    }

    //Run a loop on the rrp and push the headers in the columnDefs.
    let datePipe = new DatePipe('en-US')
    if (this.displayDates) {
      let index = 0;
      this.displayDates.forEach(stayDate => {
        let dateColumn = (stayDate) ? datePipe.transform(stayDate, 'EEE dd MMM') : '';
        this.columnDefs.push({
          headerName: dateColumn, headerTooltip: dateColumn, field: "roomRatePlans", colId: index++, menuTabs: ["generalMenuTab"], width: 150,
          cellRendererFramework: cellRenderFwk, editable: true, cellEditorFramework: cellEditorFwk
        });
      });
    }

    let count = 1;
    let field = "";
    if (this.views[this.viewId].column == "roomRatePlanFields") {
      count = this.getFieldCount(this.rrpFilter);
      field = "roomRatePlanFields";
    }
    this.gridOptions.getRowHeight = function (params) {
      if (params.node.group || params.node.rowPinned == "top") {
        return 24;
      } else {
        console.log("Enable Fields Count: " + count);
        if (field != "") {
          let computedHeight = Utilities.getRRPFieldCount(params.context.thisComponent.rrpFilter, params.data.pricingModel, params.data.maxOccupancy);
          return 20 * computedHeight;
        } else {
          return 20 * count;
        }
      }
    };
    //Filter Rate Plan
    this.gridOptions.api.setColumnDefs(this.columnDefs);
    this.gridOptions.api.setRowData(this.rateCalendarData);
    this.gridOptions.api.sizeColumnsToFit();
  }

  getFieldCount(rrpFilter: RRPCellFilter): number {
    let enableFieldCount = 0;
    Object.keys(rrpFilter).map((key) => {
      if ((key == "perDay" && this.domainHotel.PricingModel != RoomPricingModel.PerOccupancyPricing) ||
        (key != "perDay" && key != "occupancy")) {
        if (rrpFilter[key]) {
          enableFieldCount++;
        }
      } else if (key == "occupancy" && this.domainHotel.PricingModel != RoomPricingModel.PerDayPricing) {
        let occupancy = rrpFilter[key];
        occupancy.forEach(val => {
          if (val) enableFieldCount++;
        });
      }
    });
    return enableFieldCount;
  }

  //Build RateCalendar Criteria 
  buildRateCalendarCriteria(channelTypes?: any[], startDate?: Date, endDate?: Date) {
    let rateCalCriteria = new RateCalendarCriteria();
    rateCalCriteria.DomainId = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    rateCalCriteria.HotelId = this.storageService.get(StorageService.currentUser).HotelContext.HotelId;
    if (!channelTypes)
      channelTypes = ["MAX"]; //SET TO DEFAULT IF NOT PASSED
    rateCalCriteria.ChannelCodes = channelTypes;
    rateCalCriteria.StayDates = new DateRange();
    if (!startDate) {
      startDate = new Date(Date.UTC(this.navigationDate.getFullYear(), this.navigationDate.getMonth(), this.navigationDate.getDate()));
    }
    if (!endDate) {
      endDate = new Date(Date.UTC(this.navigationDate.getFullYear(), this.navigationDate.getMonth(), this.navigationDate.getDate()));
      endDate.setUTCDate(endDate.getUTCDate() + this.displayDays);
    }
    rateCalCriteria.StayDates.Start = startDate;
    rateCalCriteria.StayDates.End = endDate;
    return rateCalCriteria;
  }

  //Refresh
  async onRefresh() {
    let hotelId = this.storageService.get(StorageService.userHotelContext).HotelId;
    if (this.storageService.reloadHotelContextRequired) {
      let domainId = this.storageService.get(StorageService.userDomainContext).CurrentDomainId;
      await this.authenticationService.switchDomain(domainId, hotelId);
    }
    this.domainHotel = this.authenticationService.getDomainHotel(hotelId);
    this.rateCalendarData = Array();
    this.rowData = Array();
    this.localChannelData = new Map();
    this.remoteChannelData = new Map();
    this.modifiedChannelData = new Map();
    this.manualRefresh = false;
    this.getRateCalendar();
  }

  //Save
  async onSave($event, isSync) {
    this.showSaveRateCalendarDialog = false;
    console.log("Selected Channeltypes: " + this.selectedChannelTypes);
    console.log("Objects modified: ", this.modifiedRRP);
    if (this.modifiedRRP.length == 0) return;
    let saveSuccess = false;
    //TODO: Use the modifiedSaveRRP object which has manual edit source set.
    //let response = await this.rateCalendarService.saveRateCalendar(this.modifiedRRP, this.selectedChannelTypes, true).then(
    let response = await this.rateCalendarService.saveRateCalendar(this.modifiedSaveRRP, this.selectedChannelTypes, true).then(
      async (response) => {
        saveSuccess = true;
        return response;
      });
    if (isSync && saveSuccess) {
      let syncResponse = await this.rateCalendarService.syncRateCalendar(this.rateCalCriteria.HotelId, this.rateCalCriteria.DomainId,
        this.rateCalCriteria.StayDates, "twoway", false, this.selectedChannelTypes).then(
          async response => {
            return response;
          });
    }
    if (saveSuccess) this.onRefresh();
  }

  changeView(dropdown: Dropdown) {
    //this.columnDefs = Array();
    this.rateCalendarData = Array();
    this.rowData = Array();
    this.viewId = dropdown.selectedOption.value.value;
    this.rateCalendarService.rrpFilter.setToDefault();
    this.applyViewFilter();
    this.getRateCalendar();
  }

  modifiedRRP = new Array();
  modifiedSaveRRP = new Array();
  async showSaveDialog(event) {
    this.gridOptions.api.stopEditing();
    console.log("Editing stopped");
    this.modifiedRRP = new Array();
    this.modifiedSaveRRP = new Array();
    this.isDirty();
    if (this.modifiedRRP.length > 0) {
      let channelTypes = this.storageService.get(StorageService.currentUser).HotelContext.ChannelCodes;
      this.availableChannelTypes = await this.rateCalendarService.getChannelTypes();
      this.availableChannelTypes.sort(Utilities.sort_label_asc);
      this.selectedChannelTypes = channelTypes;
      this.showSaveRateCalendarDialog = true;
    } else {
      this.loadingService.showDialog("No modification's found in rate calendar to save.");
    }
  }

  dateChange(event) {
    if (this.navigationDate.getDate() != this.rateCalCriteria.StayDates.Start.getDate() ||
      this.navigationDate.getDay() != this.rateCalCriteria.StayDates.Start.getDay() ||
      this.navigationDate.getMonth() != this.rateCalCriteria.StayDates.Start.getMonth() ||
      this.navigationDate.getFullYear() != this.rateCalCriteria.StayDates.Start.getFullYear())
      this.onRefresh();
  }

  revertDate(event) {
    this.navigationDate = this.rateCalCriteria.StayDates.Start;
  }

  //FIX: Added Method to capture event needed in context menu copy right and left operation.
  cellEvent;
  captureEvent(params) {
    this.cellEvent = params.event;
  }

  //Grid Context Menu
  getContextMenuItems = function getContextMenuItems(params, event?) {
    if (params.value) {
      let cellData = params.value[params.column.colDef.colId];
      if (!cellData) return;
      console.log("Is Closed: ", cellData.IsClosed);
      var result = [];
      if (cellData.ChannelCode == "MAX") {
        result.push({
          name: "Availability Closed",
          checked: cellData.IsClosed,
          action: function () {
            let cellData = params.value[params.column.colDef.colId];
            console.log("Availability Closed", params.value[params.column.colDef.colId].IsClosed);
            cellData.IsClosed = !cellData.IsClosed;
            params.context.thisComponent.closeOutAtRatePlan(params);
            params.api.redrawRows({});
          }
        });
        result.push("separator");
        result.push({
          name: "Lock Rate Rule Update",
          title: "Rate rule will not be applied when checked.",
          checked: cellData.RateMgrLock,
          action: function () {
            let cellData = params.value[params.column.colDef.colId];
            console.log("Lock Rate", params.value[params.column.colDef.colId].RateMgrLock);
            cellData.RateMgrLock = !cellData.RateMgrLock;
            //TODO: Need to check if need to update
            params.api.redrawRows({});
          }
        });
        result.push("separator");
      }
      result.push({
        name: "History",
        action: function () {
          params.context.thisComponent.showHistory(params);
        },
        icon: '<span class="fa fa-history" />'
      });
      if (!params.context.thisComponent.isReadOnly && cellData.ChannelCode == "MAX")
        result.push({
          name: "Bulk Edit",
          action: function () {
            params.context.thisComponent.showBulkEdit(params);
          },
          icon: '<span class="fa fa-fw custom-edit" />'
        });
      //TODO: event object reference is not getting set for Firefox browser.
      try {
        if (!event) event = params.context.thisComponent.cellEvent;
        console.log("Event Object: ", event);
        if (cellData.ChannelCode == "MAX" && event) {
          let fieldName;
          if (params.node.data && params.node.data.roomRatePlanFields != "all") {
            fieldName = params.node.data.roomRatePlanFields;
            if (fieldName == "soldRooms" || fieldName == "totalInventory") fieldName = "";
          } else {
            let targetEvent = (event.target || event.srcElement);
            fieldName = targetEvent["name"];
          }
          let occupancyLinking = false;
          if (fieldName && (fieldName == "perDay" || fieldName.startsWith("occupancy") || fieldName == "extraPerson" || fieldName == "perExtraPerson" || fieldName == "extraChildren")) {
            if (params.node.data) {
              occupancyLinking = params.node.data.occupancyLinking;
              let linkedOccupancies = params.node.data.linkedOccupancies;
              if (!occupancyLinking && fieldName.startsWith("occupancy")) {
                let index = parseInt(fieldName.substring(9)) + 1;
                if (linkedOccupancies && linkedOccupancies.length) {
                  if (linkedOccupancies.indexOf(index) >= 0)
                    occupancyLinking = true;
                }
              }
            }
            occupancyLinking = occupancyLinking || params.context.thisComponent.inventoryOnly;
          }
          if (fieldName && fieldName != "" && !occupancyLinking) {
            result.push("separator");
            if (params.column.colDef.colId < params.value.length - 1) {
              result.push({
                name: "Copy Right",
                action: function () {
                  params.context.thisComponent.copyRight(params, fieldName);
                },
                icon: '<span class="fa fa-hand-o-right" />'
              });
            }
            if (params.column.colDef.colId != 0) {
              result.push({
                name: "Copy Left",
                action: function () {
                  params.context.thisComponent.copyLeft(params, fieldName);
                },
                icon: '<span class="fa fa-hand-o-left" />'
              });
            }
          }
        }
      } catch (e) {
        //Supress Exception 
        console.log("Params Event", params.event);
        console.log("Exception Occurred: ", e);
      }
      return result;
    }
    return "";
  }
  copyRight(params, fieldName) {
    let copyIndex = params.column.colDef.colId;
    let fromIndex = params.column.colDef.colId + 1;
    let toIndex = params.value.length - 1;
    if (fromIndex <= toIndex)
      this.copy(params, fieldName, copyIndex, fromIndex, toIndex);
  }
  copyLeft(params, fieldName) {
    let copyIndex = params.column.colDef.colId;
    let fromIndex = 0;
    let toIndex = params.column.colDef.colId - 1;
    if (fromIndex <= toIndex)
      this.copy(params, fieldName, copyIndex, fromIndex, toIndex);
  }

  copy(params, fieldName, index, fromIndex, toIndex) {
    params.api.stopEditing();
    let fromRRP = params.value[index];
    for (let i = fromIndex; i <= toIndex; i++) {
      let toRRP = params.value[i];
      if (toRRP && toRRP != null) {
        if (fieldName == "availability") {
          toRRP.Availability = fromRRP.Availability;
          toRRP.IsClosed = fromRRP.IsClosed;
          this.updateCloseOutAndAvailabilityAtRatePlan(toRRP.RoomTypeId, i, fromRRP.Availability, fromRRP.IsClosed);
        }
        if (fieldName == "minStay") { toRRP.MinStay = fromRRP.MinStay; }
        if (fieldName == "maxStay") { toRRP.MaxStay = fromRRP.MaxStay; }
        if (fieldName == "minAdvPurchaseDays") { toRRP.MinAdvPurchaseDays = fromRRP.MinAdvPurchaseDays; }
        if (fieldName == "maxAdvPurchaseDays") { toRRP.MaxAdvPurchaseDays = fromRRP.MaxAdvPurchaseDays; }
        if (fieldName == "CTA") { toRRP.CTA = fromRRP.CTA; }
        if (fieldName == "CTD") { toRRP.CTD = fromRRP.CTD; }
        if (fromRRP.RoomRate && toRRP.RoomRate) {
          if (fieldName == "perDay") { toRRP.RoomRate.PerDay = fromRRP.RoomRate.PerDay }
          if (fieldName.startsWith("occupancy")) {
            let idx = parseInt(fieldName.substring(9)) + 1;
            let value;
            if (fromRRP.RoomRate.PerOccupancy) {
              value = fromRRP.RoomRate.PerOccupancy[idx];
            }
            if (value || value == 0) {
              if (!toRRP.RoomRate.PerOccupancy) toRRP.RoomRate.PerOccupancy = {};
              toRRP.RoomRate.PerOccupancy[idx] = value;
            }
          }
          if (fieldName == "perExtraPerson") { toRRP.RoomRate.PerExtraPerson = fromRRP.RoomRate.PerExtraPerson }
          //TODO:EXTRACHILD
          if (fieldName == "extraChildren") {
            let value;
            if (fromRRP.RoomRate.ExtraChildren && fromRRP.RoomRate.ExtraChildren[1])
              value = fromRRP.RoomRate.ExtraChildren[1];
            if (value || value == 0) {
              if (!toRRP.RoomRate.ExtraChildren) toRRP.RoomRate.ExtraChildren = {};
              toRRP.RoomRate.ExtraChildren[1] = value;
            }
          }
        }
      }
    }
    params.api.redrawRows({});
  }

  //Sync Logs and Related Methods
  syncLogs = [];
  syncLogChannelType;
  syncLogRoomType;
  syncLogRatePlan;
  syncLogDate;
  syncLogsOptions = <GridOptions>{
    enableSorting: true,
    animateRows: true,
    groupDefaultExpanded: -1,
    enableColResize: true,
    rowHeight: 24,
    groupUseEntireRow: true,
    suppressDragLeaveHidesColumns: true,
    localeText: { noRowsToShow: "No history found" },
    suppressContextMenu: true,
    getRowClass: function (params) {
      if (params.node.group && params.node.level == 0) {
        return 'group-background';
      }
    }
  };
  onSyncLogsGridReady(params) {
    this.syncLogsOptions.api.sizeColumnsToFit();
  }

  onSyncLogsGridSizeChanged(params) {
    this.syncLogsOptions.api.sizeColumnsToFit();
  }
  showSyncHistoryDialog = false;
  async showHistory(params) {
    let cellData = params.value[params.column.colDef.colId];
    let syncLogCriteria = new SyncLogCriteria();
    syncLogCriteria.DomainId = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    syncLogCriteria.HotelId = this.storageService.get(StorageService.currentUser).HotelContext.HotelId;
    if (cellData.ChannelCode != "MAX") {
      let channel = this.hotelChannel.find(o => o.ChannelCode == cellData.ChannelCode);
      if (channel) syncLogCriteria.HotelId = channel.ChannelHotelId;
    }
    syncLogCriteria.ChannelCode = cellData.ChannelCode;
    syncLogCriteria.RatePlans = [cellData.RatePlanId];
    syncLogCriteria.RoomTypes = [cellData.RoomTypeId];
    syncLogCriteria.Format = RateFormat.Hotel;
    syncLogCriteria.sourceName = "local";
    let stayDate = new Date(Date.UTC(this.navigationDate.getFullYear(), this.navigationDate.getMonth(), this.navigationDate.getDate()));
    stayDate.setDate(stayDate.getDate() + parseInt(params.column.colDef.colId));
    syncLogCriteria.StayDate = new DateRange({ Start: stayDate, End: stayDate });
    this.syncLogsOptions.api.setColumnDefs(this.getSyncLogsColumnDef(params.node.data.pricingModel));
    this.syncLogsOptions.api.setRowData(await this.rateCalendarService.findRateCalendarSyncLog(syncLogCriteria));
    this.onSyncLogsGridReady(null);
    let hotelContext = this.storageService.get(StorageService.userHotelContext);
    this.syncLogChannelType = Utilities.findId(Utilities.channelTypes, cellData.ChannelCode, cellData.ChannelCode);
    if (cellData.ChannelCode != "MAX") {
      this.syncLogRoomType = this.getRoomTypeLabel(params.node.data.masterRoomTypeId) + "{" + params.node.data.roomName + "}";
      this.syncLogRatePlan = this.getRatePlanLabel(params.node.data.masterRatePlanId) + "{" + params.node.data.ratePlanName + "}";
    } else {
      this.syncLogRoomType = Utilities.findId(hotelContext.RoomTypes, cellData.RoomTypeId);
      this.syncLogRatePlan = Utilities.findId(hotelContext.RatePlans, cellData.RatePlanId);
    }
    this.syncLogDate = new DatePipe('en-US').transform(stayDate, 'EEE dd MMM');
    this.showSyncHistoryDialog = true;
  }

  public getRatePlanLabel(fieldId) {
    let hotelContext = this.storageService.get(StorageService.userHotelContext);
    return Utilities.findId(hotelContext.RatePlans, fieldId);
  }

  public getRoomTypeLabel(fieldId) {
    let hotelContext = this.storageService.get(StorageService.userHotelContext);
    return Utilities.findId(hotelContext.RoomTypes, fieldId);
  }

  getSyncLogsColumnDef(pricingModel) {
    let syncColDef = new Array();
    syncColDef.push({ field: "", width: 50, pinned: "left", menuTabs: [] });
    syncColDef.push({ field: "GroupHeader", hide: true, rowGroup: true, menuTabs: [] });
    syncColDef.push({
      headerName: "Timestamp", width: 400, cellClass: 'gridcol', field: "SyncTimestamp", pinned: "left", sort: "desc", menuTabs: [], valueFormatter: function (data) {
        if (data.value) return new DatexPipe().transform(data.value, "DD-MMM-YY hh:mm:ss a");
      }
    });
    syncColDef.push({ headerName: "User Name", headerTooltip: "User Name", width: 300, cellClass: 'gridcol', field: "RoomRatePlans.0.Tags.EditUser", tooltipField: "RoomRatePlans.0.Tags.EditUser", pinned: "left", valueFormatter: formatEmail, menuTabs: [] });
    syncColDef.push({ headerName: "Source", headerTooltip: "Source", cellClass: 'gridcol', field: "RoomRatePlans.0.Source", menuTabs: [], tooltipField: "RoomRatePlans.0.Source" });
    if (pricingModel != RoomPricingModel.PerOccupancyPricing)
      syncColDef.push({ headerName: "Per Day", headerTooltip: "Per Day", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.RoomRate.PerDay", valueFormatter: formatNumber, menuTabs: [] });
    syncColDef.push({ headerName: "Extra Person", headerTooltip: "Extra Person", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.RoomRate.PerExtraPerson", valueFormatter: formatNumber, menuTabs: [] });
    if (pricingModel != RoomPricingModel.PerDayPricing) {
      syncColDef.push({ headerName: "Single", headerTooltip: "Single", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.RoomRate.PerOccupancy.1", valueFormatter: formatNumber, menuTabs: [] });
      syncColDef.push({ headerName: "Double", headerTooltip: "Double", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.RoomRate.PerOccupancy.2", valueFormatter: formatNumber, menuTabs: [] });
      syncColDef.push({ headerName: "Triple", headerTooltip: "Triple", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.RoomRate.PerOccupancy.3", valueFormatter: formatNumber, menuTabs: [] });
    }
    syncColDef.push({ headerName: "Extra Children", headerTooltip: "Extra Children", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.RoomRate.ExtraChildren.1", valueFormatter: formatNumber, menuTabs: [] });
    syncColDef.push({ headerName: "Availability", headerTooltip: "Availability", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.Availability", menuTabs: [] });
    syncColDef.push({ headerName: "Is Closed", headerTooltip: "Is Closed", cellClass: 'gridcol', field: "RoomRatePlans.0.IsClosed", valueFormatter: formatBoolean, menuTabs: [] });
    syncColDef.push({ headerName: "Sold", headerTooltip: "Sold", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.Sold", width: 100, menuTabs: [] });
    syncColDef.push({ headerName: "Adv. Purchase", headerTooltip: "Adv. Purchase", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.AdvPurchase", menuTabs: [] });
    syncColDef.push({ headerName: "Min Stay", headerTooltip: "Minimum Stay", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.MinStay", menuTabs: [] });
    syncColDef.push({ headerName: "Max Stay", headerTooltip: "Maximum Stay", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.MaxStay", menuTabs: [] });
    syncColDef.push({ headerName: "Rate Lock", headerTooltip: "Rate Manager Lock", cellClass: 'gridcol', field: "RoomRatePlans.0.RateMgrLock", valueFormatter: formatBool, menuTabs: [] });
    syncColDef.push({ headerName: "Min Advance Purchase", headerTooltip: "Min Advance Purchase", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.MinAdvPurchaseDays", menuTabs: [] });
    syncColDef.push({ headerName: "Max Advance Purchase", headerTooltip: "Max Advance Purchase", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.MaxAdvPurchaseDays", menuTabs: [] });
    syncColDef.push({ headerName: "CTA", headerTooltip: "Closed to Arrival", cellClass: 'gridcol', field: "RoomRatePlans.0.CTA", width: 100, valueFormatter: formatBool, menuTabs: [] });
    syncColDef.push({ headerName: "CTD", headerTooltip: "Closed to Departure", cellClass: 'gridcol', field: "RoomRatePlans.0.CTD", width: 100, valueFormatter: formatBool, menuTabs: [] });
    // syncColDef.push({ headerName: "Min Stay Arr", headerTooltip: "Minimum Stay Arrival", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.MinStayArrival", menuTabs: [] });
    // syncColDef.push({ headerName: "Max Stay Arr", headerTooltip: "Maximum Stay Arrival", cellClass: 'gridcol rightalign', field: "RoomRatePlans.0.MaxStayArrival", menuTabs: [] });
    return syncColDef;
  }

  @ViewChild('bulkEdit') bulkEditComponent;
  @ViewChild('rcmenu') rcmenu;
  //Bulk Edit handing 
  showBulkEditDialog = false;
  showBulkEdit(params?) {
    this.bulkEditComponent.rrpInstance = null;
    if (params && params.value)
      this.bulkEditComponent.rrpInstance = [params.value[params.column.colDef.colId]];
    else if (params && params.context.thisComponent.rateCalendarData && params.context.thisComponent.rateCalendarData.length > 0) {
      var rrpInst = [];
      params.context.thisComponent.rateCalendarData.forEach(gcd => {
        if (gcd.channelType == "MAX") {
          rrpInst.push(gcd.roomRatePlans[params.column.colDef.colId]);
        }
      })
      if (rrpInst.length > 0) this.bulkEditComponent.rrpInstance = rrpInst;
    }
    this.bulkEditComponent.ngOnInit();
    this.bulkEditComponent.ngAfterViewInit();
    this.showBulkEditDialog = true;
  }

  handleOnClose(refresh) {
    this.showBulkEditDialog = false;
    if (refresh) {
      this.onRefresh();
    }
  }

  //Re Calculate Methods and variables
  showReCalcRateCalendarDialog = false;
  sourceChannel = { label: "Master", value: "MAX" }; // Master
  destinationChannels = [];
  rangeDates = [];
  rangeSelectionMinDate = new Date();
  overrideManualEdit: boolean;
  reCalErrorMsg = "";
  @ViewChild('reCalcOptions') reCalcOptions;
  async onReCalculate(event) {
    this.reCalErrorMsg = "";
    if (!this.sourceChannel || !this.destinationChannels || this.destinationChannels.length == 0) {
      this.reCalErrorMsg = "Please select the required fields";
      return;
    }
    let dateRange = new DateRange();
    this.rangeDates[0].setHours(0, 0, 0, 0);
    dateRange.Start = Utilities.convertToUTC(this.rangeDates[0]);
    if (this.rangeDates.length == 1)
      dateRange.End = dateRange.Start;
    else
      dateRange.End = (this.rangeDates[1] != null) ? Utilities.convertToUTC(this.rangeDates[1]) : dateRange.Start;

    this.showReCalcRateCalendarDialog = false;
    let response = await this.rateCalendarService.reCalculate(this.rateCalCriteria.HotelId, this.sourceChannel.value, this.destinationChannels, dateRange, this.overrideManualEdit).then(
      async (response) => { return response; });
    if (response)
      this.onRefresh();
  }

  onSourceChange(event) {
    //Adding Master manually
    this.destinationChannelTypes = [];
    this.sourceChannelTypes.forEach(element => {
      if (element.value != this.sourceChannel.value)
        this.destinationChannelTypes.push({ value: element.value, label: element.label });
    });
    let index = this.destinationChannels.indexOf(this.sourceChannel.value);
    if (index >= 0) {
      this.destinationChannels.splice(index, 1);
    }
  }

  async showReCalculate() {
    let channelTypes = this.storageService.get(StorageService.currentUser).HotelContext.ChannelCodes;
    this.sourceChannel = { label: "Master", value: "MAX" };
    this.destinationChannels = [];
    this.sourceChannelTypes = [];
    this.destinationChannelTypes = await this.rateCalendarService.getChannelTypes();
    this.overrideManualEdit = false;

    this.sourceChannelTypes = JSON.parse(JSON.stringify(this.destinationChannelTypes));
    //Manually pushing Master
    this.sourceChannelTypes.push({ value: 'MAX', label: Utilities.findId(Utilities.channelTypes, "MAX") });
    this.destinationChannelTypes.reverse();
    this.sourceChannelTypes.reverse();
    //Set the date Range for Recalculate
    let todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);
    todaysDate = Utilities.getUTCDate(todaysDate);
    if (Utilities.dateDifference(Utilities.getUTCDate(this.rateCalCriteria.StayDates.Start), todaysDate) > 1) {
      this.rangeDates = [todaysDate];
      let endDate = new Date();
      endDate.setHours(0, 0, 0, 0);
      endDate = Utilities.getUTCDate(endDate);
      endDate.setDate(endDate.getDate() + this.displayDays);
      this.rangeDates.push(endDate);
    } else {
      this.rangeDates = [Utilities.getUTCDate(this.rateCalCriteria.StayDates.Start), Utilities.getUTCDate(this.rateCalCriteria.StayDates.End)];
    }
    this.showReCalcRateCalendarDialog = true;
  }
  onDateRangeChange(event) {
    if (!this.rangeDates || this.rangeDates.length == 0) {
      this.rangeDates = [this.rateCalCriteria.StayDates.Start, this.rateCalCriteria.StayDates.End];
    }
  }
  //Re Calculate end

  //Sync Calendar start
  showSyncRateCalendarDialog;
  syncErrorMsg = null;
  syncModeOption;
  overrideSyncManualEdit;
  syncChannelTypes = [];
  selectedSyncChannels = [];
  isForceUpdate = false;
  async showSyncDialog(event) {
    let channelTypes = this.storageService.get(StorageService.currentUser).HotelContext.ChannelCodes;
    this.syncModeOption = null;
    this.overrideSyncManualEdit = false;
    this.syncErrorMsg = "";
    this.syncChannelTypes = await this.rateCalendarService.getChannelTypes();
    this.syncChannelTypes.sort(Utilities.sort_label_asc);
    this.selectedSyncChannels = [];
    //Set the date Range for Recalculate
    //User should be allowed to sync today and future dates.
    let todaysDate = Utilities.getUTCDate(new Date());
    //todaysDate = new Date(Date.UTC(todaysDate.getFullYear(), todaysDate.getMonth(), todaysDate.getDate())); 
    if (Utilities.dateDifference(Utilities.getUTCDate(this.rateCalCriteria.StayDates.Start), todaysDate) > 1) {
      this.rangeDates = [todaysDate];
      let endDate = Utilities.getUTCDate(new Date());
      endDate.setDate(endDate.getDate() + this.displayDays);
      this.rangeDates.push(endDate);
    } else {
      this.rangeDates = [Utilities.getUTCDate(this.rateCalCriteria.StayDates.Start), Utilities.getUTCDate(this.rateCalCriteria.StayDates.End)];
    }
    this.showSyncRateCalendarDialog = true;
  }
  //Sync Grid
  async onSync($event) {
    if (!this.syncModeOption || this.syncModeOption == "") {
      this.syncErrorMsg = "Please choose Sync mode";
      return;
    }
    if (!this.selectedSyncChannels || this.selectedSyncChannels.length == 0) {
      this.syncErrorMsg = "Please select channels";
      return;
    }
    let dateRange = new DateRange();
    this.rangeDates[0].setHours(0, 0, 0, 0);
    dateRange.Start = Utilities.convertToUTC(this.rangeDates[0]);
    if (this.rangeDates.length == 1)
      dateRange.End = dateRange.Start;
    else
      dateRange.End = (this.rangeDates[1] != null) ? Utilities.convertToUTC(this.rangeDates[1]) : dateRange.Start;
    this.showSyncRateCalendarDialog = false;
    let listRange = [];
    while (Utilities.dateDifference(dateRange.Start, dateRange.End) > 90) {
      let newRange = new DateRange();
      newRange.Start = new Date(dateRange.Start);
      newRange.End = new Date(dateRange.Start)
      newRange.End.setMonth(newRange.End.getMonth() + 3);
      newRange.End.setDate(newRange.End.getDate() - 1);
      if (dateRange.End <= newRange.End) break;
      dateRange.Start = new Date(newRange.End);
      listRange.push(newRange);
      dateRange.Start.setDate(dateRange.Start.getDate() + 1);
    }
    listRange.push(dateRange);
    listRange.forEach(async range => {
      let response = await this.rateCalendarService.syncRateCalendar(this.rateCalCriteria.HotelId, this.rateCalCriteria.DomainId,
        range, this.syncModeOption, (this.syncModeOption == "twoway") ? this.isForceUpdate : this.overrideSyncManualEdit, this.selectedSyncChannels).then(
          async response => {
            return response;
          });
    })
  }

  //This is to block navigation. called by unsavedchanges.guard
  public isDirty() {
    this.gridOptions.api.stopEditing();
    let modified = new Array();
    let modifiedSaved = new Array();
    let channelType = "MAX";
    let localData = this.localChannelData.get(channelType);
    if (localData && localData != null) {
      let modifiedData = this.modifiedChannelData.get(channelType);
      for (let i = 0; i < localData.length; i++) {
        if (!Utilities.compare(localData[i], modifiedData[i])) {
          modified.push(modifiedData[i]);
          modifiedSaved.push(JSON.parse(JSON.stringify(modifiedData[i])));
          for (let j = 0; j < modifiedData[i].RoomRatePlans.length; j++) {
            if (!Utilities.compare(localData[i].RoomRatePlans[j], modifiedData[i].RoomRatePlans[j])) {
              modifiedSaved[modifiedSaved.length - 1].RoomRatePlans[j].Source = "Manual";
            }
          }
        }
      }
    }
    this.modifiedRRP = modified;
    this.modifiedSaveRRP = modifiedSaved;
    return (modified.length > 0);
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true));
  }

  showConfirmationDialog(event, successMethodName, failMethodName?) {
    let proceed = this.unsavedchangesGuard.canDeactivate(this);
    if (typeof proceed == "boolean") {
      if (proceed) {
        this[successMethodName]();
      }
    } else {
      proceed.subscribe((value) => {
        if (value == true) {
          this[successMethodName]();
        } else {
          if (failMethodName)
            this[failMethodName]();
        }
      });
    }
  }

  //Check Inventory at rateplan level
  cellEditorValueChange(params) {
    if (params && params.data.channelType == "MAX") {
      let newValue = params.newValue[params.colDef.colId];
      if (!this.domainHotel.IsInventoryAtRatePlan) {
        let rId = newValue.RoomTypeId;
        let modifData = this.modifiedChannelData.get("MAX");
        let modifDate = this.displayDates[params.colDef.colId];
        let rrpData = modifData.find(o => Utilities.date_sort_asc(Utilities.getUTCDate(new Date(o.StayDate)), modifDate) == 0);
        if (rrpData) {
          rrpData.RoomRatePlans.forEach(rrp => {
            if (rrp.RoomTypeId == rId && rrp.Availability != newValue.Availability) {
              rrp.Availability = newValue.Availability;
            }
          });
        }
      }
    }
  }

  closeOutAtRatePlan(params) {
    if (params && params.value[params.column.colDef.colId].ChannelCode == "MAX") {
      let newValue = params.value[params.column.colDef.colId];
      if (!this.domainHotel.IsCloseOutAtRatePlan) {
        let rId = newValue.RoomTypeId;
        let modifData = this.modifiedChannelData.get("MAX");
        let modifDate = this.displayDates[params.column.colDef.colId];
        let rrpData = modifData.find(o => Utilities.date_sort_asc(Utilities.getUTCDate(new Date(o.StayDate)), modifDate) == 0);
        if (rrpData) {
          rrpData.RoomRatePlans.forEach(rrp => {
            if (rrp.RoomTypeId == rId && rrp.IsClosed != newValue.IsClosed) {
              rrp.IsClosed = newValue.IsClosed;
            }
          });
        }
      }
    }
  }

  updateCloseOutAndAvailabilityAtRatePlan(roomTypeId, index, availability, closeOut) {
    if (!this.domainHotel.IsCloseOutAtRatePlan || !this.domainHotel.IsInventoryAtRatePlan) {
      let modifData = this.modifiedChannelData.get("MAX");
      let modifDate = this.displayDates[index];
      let rrpData = modifData.find(o => Utilities.date_sort_asc(Utilities.getUTCDate(new Date(o.StayDate)), modifDate) == 0);
      if (rrpData) {
        rrpData.RoomRatePlans.forEach(rrp => {
          if (!this.domainHotel.IsCloseOutAtRatePlan && rrp.RoomTypeId == roomTypeId) {
            rrp.IsClosed = closeOut;
          }
          if (!this.domainHotel.IsInventoryAtRatePlan && rrp.RoomTypeId == roomTypeId) {
            rrp.Availability = availability;
          }
        });
      }
    }
  }

  showExportRateCalendarDialog = false;
  exportOption = "local";
  async showExportDialog(event) {
    let channelTypes = this.storageService.get(StorageService.currentUser).HotelContext.ChannelCodes;
    this.exportOption = "local";
    this.syncChannelTypes = await this.rateCalendarService.getChannelTypes();
    this.syncChannelTypes.push({ value: 'MAX', label: Utilities.findId(Utilities.channelTypes, "MAX") });
    this.syncChannelTypes.reverse();

    this.selectedSyncChannels = [];
    //Set the date Range for Recalculate
    this.rangeDates = [Utilities.getUTCDate(this.rateCalCriteria.StayDates.Start), Utilities.getUTCDate(this.rateCalCriteria.StayDates.End)];
    this.showExportRateCalendarDialog = true;
  }

  //Export RateCalendar
  async onExport(event) {
    let dateRange = new DateRange();
    this.rangeDates[0].setHours(0, 0, 0, 0);
    dateRange.Start = Utilities.convertToUTC(this.rangeDates[0]);
    if (this.rangeDates.length == 1)
      dateRange.End = dateRange.Start;
    else
      dateRange.End = (this.rangeDates[1] != null) ? Utilities.convertToUTC(this.rangeDates[1]) : dateRange.Start;
    this.showSyncRateCalendarDialog = false;
    let exportCriteria = new RateCalendarCriteria();
    exportCriteria.DomainId = this.rateCalCriteria.DomainId;
    exportCriteria.HotelId = this.rateCalCriteria.HotelId;
    exportCriteria.ChannelCodes = this.selectedSyncChannels;
    exportCriteria.StayDates = dateRange;
    let response = await this.rateCalendarService.exportRateCalendar(this.exportOption, exportCriteria);
    this.showExportRateCalendarDialog = false;
  }

  isFilterEnabled = true;
  showFilter = false;
  hideShowFilterCriteria() {
    if (this.isFilterEnabled)
      this.showFilter = !this.showFilter;
  }

  @ViewChild('navDate') navDate;
  dateFormat = new DatePipe('en-US');
  prevWeek(event) {
    this.navigationDate.setDate(this.navigationDate.getDate() - (this.displayDays + 1));
    this.navDate.inputFieldValue = this.dateFormat.transform(new Date(this.navigationDate), "dd-MMM-yyyy");
    this.navDate.defaultDate = this.navigationDate;
    this.navDate.ngOnInit();
    this.onRefresh();
  }

  nextWeek(event) {
    this.navigationDate.setDate(this.navigationDate.getDate() + this.displayDays + 1);
    this.navDate.inputFieldValue = this.dateFormat.transform(new Date(this.navigationDate), "dd-MMM-yyyy");
    this.navDate.defaultDate = this.navigationDate;
    this.navDate.ngOnInit();
    this.onRefresh();
  }
}

function getGrouping(params: any) {
  let currDate = new Date();
  let stayDate = new Date(params.value);
  let numberOfDays = Utilities.dateDifference(stayDate, currDate)
  if (currDate.getDate() == stayDate.getDate() && currDate.getMonth() == stayDate.getMonth() && currDate.getFullYear() == stayDate.getFullYear())
    return "Today";
  else if (numberOfDays < 7)
    return new DatePipe('en-US').transform(stayDate, "EEEE");
  else if (numberOfDays < 61)
    return (currDate.getMonth() == stayDate.getMonth()) ? "This Month" : new DatePipe('en-US').transform(stayDate, "MMMM");
  else if (numberOfDays < 366 && (currDate.getFullYear() == stayDate.getFullYear()))
    return "This Year";
  else
    return "Year: " + stayDate.getFullYear();
}

function getDateGetter(params: any) {
  if (params.data)
    return params.data.SyncTimestamp;
}

function formatNumber(params: any) {
  if (params.value != null && params.value) return parseFloat(params.value).toFixed(2);
  else return "-";
}

function formatEmail(params: any) {
  if (params.value != null && params.value.toLowerCase() === "operations@maximojo.com") {
    return "System";
  } else {
    return params.value;
  }
}

function formatBoolean(params: any) {
  if (params.value) return "Yes";
  else return "No";
}

function formatBool(params: any) {
  if (params.value) return "True";
  else return "False";
}