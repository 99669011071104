import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { UserCriteria, DomainUser, Feedback } from "mantras-api";
import { LoadingService } from "./loading.service";
import { AppService } from "mantras-api";
import * as SHA1 from 'sha1';
import * as Crypt from 'crypt';
import { Utilities } from "./utilities";
import { AppConfigService } from "../app-config.service";

@Injectable()
export class UserService {
    //mantras: AppService;
    constructor(private mantras:AppService,private appConfService:AppConfigService,private storageService:StorageService,private loadingService: LoadingService,private utilities: Utilities){
        Utilities.initApp(mantras,appConfService);
    }
    READ_ONLY = "ReadOnlyUser";
    isReadOnly(){
        let userContext = this.storageService.get(StorageService.userContext);
        if(userContext && userContext.Roles && userContext.Roles.length > 0){
            return (userContext.Roles.indexOf(this.READ_ONLY) >= 0);
        }
        return false;
    }
    INVENTORY_ONLY = "InventoryOnly";
    isInventoryOnly(){
        let userContext = this.storageService.get(StorageService.userContext);
        if(userContext && userContext.Roles && userContext.Roles.length > 0){
            return (userContext.Roles.indexOf(this.INVENTORY_ONLY) >= 0);
        }
        return false;
    }

    checkAccess(field: string){
        if(field === 'settings') field='Control Panel';
        if(field === 'ratecalendar') field='Rate Calendar';
        field = field.charAt(0).toUpperCase()+field.slice(1,field.length);
        if(field === 'Dashboard') return true;  //No role defined for dashboard.

        let userContext = this.storageService.get(StorageService.userContext);
        if(userContext && userContext.Roles && userContext.Roles.length > 0){
        
            return (userContext.Roles.indexOf('Admins')>=0 || userContext.Roles.indexOf(field) >= 0);
            
        }
        return false;
    }
    findDomainUsers(criteria:UserCriteria){
        return this.loadingService.interceptor(this.mantras.FindDomainUsers(criteria)
        .then(async result => {
            return result;
        }).catch(error => {
            this.loadingService.showErrorDialog(error);
            throw error;
        }), true);
    }

    saveDomainUser(user:DomainUser){
        return this.loadingService.interceptor(this.mantras.SaveDomainUser(user)
        .then(async result => {
            return result;
        }).catch(error => {
            this.loadingService.showErrorDialog(error);
            throw error;
        }), true);
    }

    deleteDomainUser(userId:string){
        return this.loadingService.interceptor(this.mantras.DeleteDomainUser(userId)
        .then(async result => {
            return result;
        }).catch(error => {
            this.loadingService.showErrorDialog(error);
            throw error;
        }), true);
    }

    changePassword(userId:string, password: string){
        let passwordToValidate = Utilities.stringToBytes(password);
        let encryptedPassword = Crypt.bytesToBase64(SHA1(passwordToValidate,{"asBytes":"true"}));
        return this.loadingService.interceptor(this.mantras.ChangePassword(userId,encryptedPassword)
        .then(async result => {
            return result;
        }).catch(error => {
            this.loadingService.showErrorDialog(error);
            throw error;
        }), true);
    }

    saveFeedback(feedback:Feedback){
        return this.loadingService.interceptor(this.mantras.saveFeedback(feedback)
        .then(async result => {
            return result;
        }).catch(error => {
            this.loadingService.showErrorDialog(error);
            throw error;
        }), true);
    }
}

export class UserDomains 
{
    userid:string;
    firstname:string;
    lastname:string;
    inActiveDomain:number;
    isactive:boolean;
    DomainUsers:DomainUser[];
}