import { AppService, DateRange } from "mantras-api";
import { RRPCellFilter } from "./ratecalendar.service";
import { RoomPricingModel } from "mantras-api";
import { environment } from "../../environments/environment";
import { saveAs } from 'file-saver/dist/FileSaver';
import { StorageService } from './storage.service';
import { DatePipe } from '@angular/common'
import { AppConfigService } from "../app-config.service";
import { config } from "rxjs";
export class Utilities {

    //UTF16 String to Bytes conversion.
    public static stringToBytes(str) {
        for (var bytes = [], i = 0; i < str.length; i++)
            bytes = bytes.concat([str.charCodeAt(i) & 0xff, str.charCodeAt(i) / 256 >>> 0]);
        return bytes;
    }

    public static bytesToString(bytes) {
        for (var chars = [], i = 0; i < bytes.length;)
            chars.push((bytes[i++]) | (bytes[i++] * 256 << 0));
        return String.fromCharCode.apply(null, chars);
    }

    public static findId(collection: any, searchKey: string, defaultValue?: string) {
        let obj = collection.find(o => o.Id === searchKey);
        return (obj) ? obj.Name : defaultValue;
    }

    //This method is to find the matching field and return property value of an object.
    public static findByName(collection: any, searchId: string, fieldName?: string, defaultValue?: string) {
        let obj = collection.find(o => o.Id === searchId);
        if (!fieldName) fieldName = 'Name';
        return (obj) ? obj[fieldName] : defaultValue;
    }

    public static findObject(collection: any, searchKey: string) {
        let obj = collection.find(o => o.Id === searchKey);
        return obj;
    }

    public static compare(object1, object2) {
        if (JSON.stringify(object1) === JSON.stringify(object2)) {
            return true;
        } else {
            return false;
        }
    }

    public static checkUpdatedField(actual, modified, type?) {
        if (type != 'boolean') {
            if (actual == null || actual == "undefined") actual = 0;
            //Added Negative value check.
            if (actual != modified && !(parseFloat(actual.toString()).toFixed(2) === modified) && modified >= 0) {
                actual = (modified == '') ? 0 : parseFloat(modified);
            }
        } else {
            if (actual != modified)
                actual = modified;
        }
        return actual;
    }

    public static isEmptyObject(object) {
        if (!object) return true;
        return JSON.stringify(object) === JSON.stringify({});
    }

    public static dateDifference(startDate, endDate) {
        let oneDay = 1000 * 60 * 60 * 24;
        return Math.round((endDate.getTime() - startDate.getTime()) / oneDay) + 1;
    }
    public static daysDifference(startDate, endDate) {
        //returns -1 for yesterday,0 for today,+1 for tomorrow
        endDate.setHours(23, 59, 59, 0);
        var diff = endDate.getTime() - startDate.getTime();
        return Math.floor(diff / (1000 * 3600 * 24));

    }
    public static getMonthName(monthNumber, type?) {
        let monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        let monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if (type && type == "short") {
            return monthsShort[monthNumber];
        }
        return monthsLong[monthNumber];
    }
    public static getDayName(dayNumber, type?) {
        var daysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        if (type == "short") return daysShort[dayNumber]
        return daysLong[dayNumber];
    }
    public static timeDifference(startDate, endDate) {
        let seconds = 1000 * 60;
        // console.log(Math.round((endDate.getTime() - startDate.getTime()) / seconds));
        return Math.round((endDate.getTime() - startDate.getTime()) / seconds);
    }
    public static findHotelNameById(hotelId: string, domainId: string, storageService, defaultValue?: string) {
        let hotelName = defaultValue;
        if (domainId && hotelId) {
            let domains = storageService.get(StorageService.userDomainContext).Domains;
            let domain = this.findObject(domains, domainId);
            if (domain && domain.DomainHotels) {
                let hotel = this.findObject(domain.DomainHotels, hotelId);
                if (hotel && hotel.Name)
                    hotelName = hotel.Name;
            }
        }
        return hotelName;
    }

    public static findMasterRoomType(channelRoomTypeId, channelType, collection) {
        return collection.find(o => o.ChannelRooms.find(c => c.ChannelCode === channelType && c.Id === channelRoomTypeId) != null);
    }

    public static findMasterRateType(channelRoomTypeId, channelType, collection) {
        return collection.find(o => o.c.find(c => c.ChannelCode === channelType && c.Id === channelRoomTypeId) != null);
    }

    public static date_sort_asc = function (date1, date2) {
        // This is a comparison function that will result in dates being sorted in
        // ASCENDING order. As you can see, JavaScript's native comparison operators
        // can be used to compare dates. This was news to me.
        if (date1 > date2) return 1;
        if (date1 < date2) return -1;
        return 0;
    }

    public static sort_label_asc = function (obj1, obj2) {
        // This is a comparison function that will result in dates being sorted in
        // ASCENDING order. As you can see, JavaScript's native comparison operators
        // can be used to compare dates. This was news to me.
        if (obj1.label.toLowerCase() > obj2.label.toLowerCase()) return 1;
        if (obj1.label.toLowerCase() < obj2.label.toLowerCase()) return -1;
        return 0;
    }

    public static sort_label_desc = function (obj1, obj2) {
        // This is a comparison function that will result in dates being sorted in
        // ASCENDING order. As you can see, JavaScript's native comparison operators
        // can be used to compare dates. This was news to me.
        if (obj1.label.toLowerCase() > obj2.label.toLowerCase()) return -1;
        if (obj1.label.toLowerCase() < obj2.label.toLowerCase()) return 1;
        return 0;
    }

    public static sortByMutipleFields(...args: string[]) {
        // takes a list of arguments representing sort fields
        // if preceded by a ! then reverse order (!lastName for example)
        // returns a function that works in Array.sort()
        return (a: any, b: any): number => {
            for (var index = 0; index < args.length; index++) {
                let fieldLeft: string;
                let fieldRight: string;
                if (args[index].startsWith('!')) {
                    fieldLeft = b[args[index].slice(1)];
                    fieldRight = a[args[index].slice(1)];
                } else {
                    fieldLeft = a[args[index]];
                    fieldRight = b[args[index]];
                }
                if (fieldLeft > fieldRight) { return 1 };
                if (fieldLeft < fieldRight) { return -1 };
            };
            return 0;
        }
    }
    public static sortByDisplayOrder(collectionName, keyname) {
        // takes a list of arguments representing sort fields
        // if preceded by a ! then reverse order (!lastName for example)
        // returns a function that works in Array.sort()
        return (a: any, b: any): number => {
            let fieldLeft: string;
            let fieldRight: string;
            if (a[collectionName] && a[collectionName][keyname]) fieldLeft = a[collectionName][keyname];
            if (b[collectionName] && b[collectionName][keyname]) fieldRight = b[collectionName][keyname];
            if (fieldLeft > fieldRight) { return 1 };
            if (fieldLeft < fieldRight) { return -1 };
            return 0;
        }
    }
    public static generateGUID() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
            s4() + '-' + s4() + s4() + s4();
    }

    public static generateUUID() {
        let d = new Date().getTime();
        let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    public mantras: AppService;
    public appConfig: AppConfigService;
    public getApp() {
        if (!this.mantras) {
            this.mantras = new AppService(null);
            if (this.appConfig.service_uri) {
                let settings = new Map([["baseUrl", this.appConfig.service_uri]]);
                this.mantras.init(settings);
            }
            else if(environment.service_uri){
                let settings = new Map([["baseUrl", environment.service_uri]]);
                this.mantras.init(settings);
            }
        }
        return this.mantras;
    }


    public static initApp(appService: AppService,appConfService : AppConfigService) {
        if(appConfService.appConfig.service_uri){
            let settings = new Map([["baseUrl", appConfService.appConfig.service_uri]]);
        appService.init(settings);
        }
       else if(environment.service_uri) {
        let settings = new Map([["baseUrl", environment.service_uri]]);
        appService.init(settings);
       }
    }

    public static convertDecimalToDaysOfWeek(decimalDow: number) {
        let selectedDow = ["0", "1", "2", "3", "4", "5", "6"];
        if (decimalDow >= 127) {
            return selectedDow;
        } else {
            let dow = decimalDow.toString(2);
            let computedDow = [];
            for (let idx = dow.length - 1, c = 0; idx >= 0; idx--, c++) {
                if (dow.charAt(idx) === "1") {
                    computedDow.push(c.toString());
                }
            }
            return computedDow;
        }
    }
    public static convertDaysOfWeekToDecimal(daysOfWeek: any[]) {
        let decimaldow = 0;
        daysOfWeek.forEach(dow => {
            decimaldow += Math.pow(2, parseInt(dow));
        });
        return decimaldow;
    }

    public static convertToUTC(currDate) {
        return new Date(currDate.getTime() - currDate.getTimezoneOffset() * 60000);
    }

    public static getUTCDate(localDate: Date) {
        return new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate());
    }

    public static isDateInRange(dateToCheck: Date, dateRange: DateRange, checkDOW: boolean = false) {
        let inRange = new Date(dateRange.Start) >= dateToCheck && new Date(dateRange.End) <= dateToCheck;
        if (inRange && checkDOW)
            inRange = (dateRange.DaysOfWeek & dateToCheck.getDay()) == dateToCheck.getDay();
        return inRange;
    }

    public static getRRPFieldCount(rrpFilter: RRPCellFilter, pricingModel: number, maxOccupancy: number): number {
        let enableFieldCount = 0;
        Object.keys(rrpFilter).map((key) => {
            if ((key == "perDay" && pricingModel != RoomPricingModel.PerOccupancyPricing) ||
                (key != "perDay" && key != "occupancy")) {
                if (rrpFilter[key]) {
                    enableFieldCount++;
                }
            } else if (key == "occupancy" && pricingModel != RoomPricingModel.PerDayPricing) {
                if (maxOccupancy > 0) {
                    let occupancy = rrpFilter[key];
                    let occCounter = 1;
                    occupancy.forEach(val => {
                        if (val && occCounter <= maxOccupancy) enableFieldCount++;
                        occCounter++;
                    });
                }
            }
        });
        return enableFieldCount;
    }

    public static saveToFileSystem(response, fileName) {
        //Ref Link: https://stackoverflow.com/questions/23451726/saving-binary-data-as-file-using-javascript-from-a-browser
        // convert base64 string to byte array
        let byteChars = atob(response);
        let byteNumbers = new Array(byteChars.length);
        for (var i = 0; i < byteChars.length; i++) {
            byteNumbers[i] = byteChars.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        saveAs(blob, fileName);
    }

    public static checkSystemAccess(domains) {
        let d = domains.find(domain => domain.Name != null && domain.Name.toLowerCase() === "system");
        if (d) return true;
        else return false;
    }

    //Loading CSS Files
    public static loadCSS(id, url) {
        //Remove old css before loading again.
        if (document.getElementById(id)) document.getElementById(id).remove();
        let node = document.createElement('link');
        node.setAttribute("id", id);
        node.type = 'text/css';
        node.rel = 'stylesheet';
        node.setAttribute("href", url);
        document.head.appendChild(node);
        return node;
    }

    public static findCurrencySymbol(code: string) {
        let currency = this.currencies.find(x => x.Code == code);
        if (currency) return currency.Symbol;
        else return code;
    }

    public static doesChannelSupportXmlCreds(channelCode: string) {
        if (Utilities.channelsWithXmlCreds.includes(channelCode)) return true;
        return false;
    }
    public static isTaxOnSellOta(otacode) {
        if (this.taxOnSellOtas.includes(otacode)) return true;
        else return false;
    }
    public static toDateString(timestamp1: Date, timestamp2: Date) {
        let date1: any = timestamp1;
        let date2: any = timestamp2;
        let diff = Math.abs(Math.floor((Date.parse(date1) - Date.parse(date2)) / (1000 * 60)));
        if (diff < 59) {
            return diff + ' minutes ago.';
        } else if (diff < 1439) {
            return Math.floor(diff / 60) + ' hours ago.';
        } else if (diff < 43199) {
            let days = Math.floor(diff / (60 * 24));
            if (days < 7) {
                return days + ' days ago.';
            }
            else if (days < 14) {
                return '1 week ago.';
            }
            else if (days < 21) {
                return '2 weeks ago.';
            }
            else if (days < 28) {
                return '3 weeks ago.';
            }
            else {
                return '4 weeks ago.';
            }

        } else return new DatePipe('en-US').transform(Utilities.getUTCDate(new Date(date1)), "MMM dd, h:mm a");
        // return date1.getMonth()+1+'/'+date1.getDate()+'/'+date1.getFullYear();
    }

    public static getChannelTypes(ignoreArray, source) {
        let collection = Object.assign([], Utilities.channelTypes)
        if (source == 'competitor') collection.push({ Name: 'Agoda', Id: 'AGD' });
        let index = 0;
        collection.forEach(c => {
            // console.log(ignoreArray.indexOf(c['Id']));
            if (ignoreArray.indexOf(c['Id']) >= 0) {
                collection.splice(index, 1);
            }
            index++;
        }
        );
        return collection;
    }

    public static formatXml(xml, tab) { // tab = optional indent value, default is tab (\t)
        var formatted = '', indent = '';
        tab = tab || '\t';
        xml.split(/>\s*</).forEach(function (node) {
            if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
            formatted += indent + '<' + node + '>\r\n';
            if (node.match(/^<?\w[^>]*[^\/]$/)) indent += tab;              // increase indent
        });
        return formatted.substring(1, formatted.length - 3);
    }

    public static json2xml(json) {
        var a = JSON.parse(json)
        var c = document.createElement("root");
        var t = function (v) {
            return {}.toString.call(v).split(' ')[1].slice(0, -1).toLowerCase();
        };
        var f = function (f, c, a, s) {
            c.setAttribute("type", t(a));
            if (t(a) != "array" && t(a) != "object") {
                if (t(a) != "null") {
                    c.appendChild(document.createTextNode(a));
                }
            } else {
                for (var k in a) {
                    var v = a[k];
                    if (k == "__type" && t(a) == "object") {
                        c.setAttribute("__type", v);
                    } else {
                        if (t(v) == "object") {
                            var ch = c.appendChild(document.createElementNS(null, s ? "item" : k));
                            f(f, ch, v);
                        } else if (t(v) == "array") {
                            var ch = c.appendChild(document.createElementNS(null, s ? "item" : k));
                            f(f, ch, v, true);
                        } else {
                            var va = document.createElementNS(null, s ? "item" : k);
                            if (t(v) != "null") {
                                va.appendChild(document.createTextNode(v));
                            }
                            var ch = c.appendChild(va);
                            ch.setAttribute("type", t(v));
                        }
                    }
                }
            }
        };
        f(f, c, a, t(a) == "array");
        return c.outerHTML;
    }

    public static durationPeriod(duration) {
        let start = 0;
        let end = 1
        let durTime = []
        if (duration == "today") {
            let today = new Date();
            durTime[start] = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
            durTime[end] = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 0);
        } else if (duration == "yesterday") {
            let today = new Date();
            let yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            durTime[start] = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0);
            durTime[end] = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 0);
        } else if (duration == "thisweek") {
            let today = new Date();
            let week = new Date(today);
            week.setDate(today.getDate() - today.getDay());
            durTime[start] = new Date(week.getFullYear(), week.getMonth(), week.getDate(), 0, 0, 0);
            durTime[end] = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 0);
        } else if (duration == "lastweek") {
            let today = new Date();
            let lastWeekStart = new Date(today);
            lastWeekStart.setDate((today.getDate() - 7) - today.getDay());
            let lastWeekEnd = new Date(today);
            lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
            durTime[start] = new Date(lastWeekStart.getFullYear(), lastWeekStart.getMonth(), lastWeekStart.getDate(), 0, 0, 0);
            durTime[end] = new Date(lastWeekEnd.getFullYear(), lastWeekEnd.getMonth(), lastWeekEnd.getDate(), 23, 59, 0);
        } else if (duration == "thismonth") {
            let today = new Date();
            let startMonth = new Date(today);
            startMonth.setDate(1);
            durTime[start] = new Date(startMonth.getFullYear(), startMonth.getMonth(), startMonth.getDate(), 0, 0, 0);
            durTime[end] = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 0);
        } else if (duration == "lastmonth") {
            let today = new Date();
            let lastMonthStart = new Date(today);
            lastMonthStart.setDate(1);
            lastMonthStart.setMonth(today.getMonth() - 1);
            let lastMonthEnd = new Date(today);
            today.setDate(1);
            lastMonthEnd.setDate(today.getDate() - 1);
            lastMonthEnd.setMonth(today.getMonth() - 1);
            durTime[start] = new Date(lastMonthStart.getFullYear(), lastMonthStart.getMonth(), lastMonthStart.getDate(), 0, 0, 0);
            durTime[end] = new Date(lastMonthEnd.getFullYear(), lastMonthEnd.getMonth(), lastMonthEnd.getDate(), 23, 59, 0);
        } else if (duration == "thisyear") {
            let today = new Date();
            let startMonth = new Date(today);
            startMonth.setMonth(0);
            startMonth.setDate(1);
            durTime[start] = new Date(startMonth.getFullYear(), startMonth.getMonth(), startMonth.getDate(), 0, 0, 0);
            durTime[end] = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 0);
        }
        else if (duration == "lastyear") {
            let today = new Date();
            let lastYearStart = new Date(today);
            lastYearStart.setMonth(0);
            lastYearStart.setDate(1);
            lastYearStart.setFullYear(today.getFullYear() - 1);
            let lastYearEnd = new Date(today);
            lastYearEnd.setMonth(11);
            lastYearEnd.setDate(31);
            lastYearEnd.setFullYear(today.getFullYear() - 1);
            durTime[start] = new Date(lastYearStart.getFullYear(), lastYearStart.getMonth(), lastYearStart.getDate(), 0, 0, 0);
            durTime[end] = new Date(lastYearEnd.getFullYear(), lastYearEnd.getMonth(), lastYearEnd.getDate(), 23, 59, 0);
        }
        return durTime;
    }

    public static clone(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    public static getScrollHeight(isMenu = false, isTabs = false, isFooter = false, isSecondMenu = false, additionalHeight = 0) {
        return window.innerHeight - 48 - (isMenu ? 41 : 0) - (isTabs ? 34 : 0) - (isFooter ? 20 : 0) - (isSecondMenu ? 40 : 0) - additionalHeight;
    }

    public static channelsWithXmlCreds = ["APL", "DIT", "EXP", "GKY", "HBK", "HSC", "HBD", "H4U", "HTR", "OBZ", "REZ", "SPD", "XBE", "XB1", "XB2", "XB3", "XB4", "XB5"];

    public static backgroundColor = ["#42A5F5", "#9CCC65", "#0000FF", "#9172b3", "#7cb971", "#3d922e", "#175684", "#eac643", "#74de6d",
        "#D8D8D8", "#44546A", "#4472CA", "#ED7D31", "#A5A5A5", "#595959", "#7030A0", "#002060", "#C00000", "#FF0000", "#FFC000", "#FFFF00",
        "#92D050", "#00B050", "#00B0F0", "#0070C0"];

    public static xchangeBECodes = [{ label: "Sterling", value: "STR" },
    { label: "Hotelzify", value: "HZY" },
    { label: "Simplotel", value: "SMP" },
    { label: "ResAvenue", value: "AVE" },
    { label: "Hotel Dekho", value: "HDK" },
    { label: "GTDC", value: "GTDC" },
    { label: "Shiril", value: "SRL" },
    { label: "TechMarvel", value: "TMRL" },
    { label: "Direct BE", value: "DirectBE" },
    { label: "PayTM", value: "PAYTM" },
    { label: "Travel Spice", value: "TRAVELSPICE" },
    { label: "RezNext", value: "REZNEXT" },
    { label: "MiStay", value: "MISTAY" },
    { label: "One Fine Rate", value: "OFR" },
    { label: "Ease My Trip", value: "EMT" },
    { label: "Ramoji", value: "RAMOJI" },
    { label: "HappyEasyGo", value: "HEG" },
    { label: "Ticket Banado", value: "TBD" },
    ];

    public static PMSCodes = [
    { label: "Hotelogix", value: "HXPMS" },
    { label: "WinHMS", value: "WHMS" },
    { label: "Inn Key", value: "INNKEYPMS" },
    { label: "Protel", value: "ProtelPMS" },
    { label: "IDS", value: "DataHubPMS" },
    { label: "MiniCal", value: "MiniCalPMS" },
    { label: "Prologic", value: "PROLOGIC" },
    { label: "Neovision", value: "NeovisionPMS" },
    { label: "Cogwave", value: "CogwavePMS" },
    { label: "Prizmatec", value: "PRIZMATEC" },
    { label: "LevotelPMS", value: "LevotelPMS" },
    { label: "HotlaPMS", value: "HotlaPMS" },
    { label: "Sanguine", value: "SANGUINEPMS" }];

    public static TicketStatus = [{ label: "New", value: 1 }, { label: "Assigned", value: 2 }, { label: "Fixed", value: 3 }, { label: "Resolved", value: 4 }, { label: "Closed", value: 5 }, { label: "Reopened", value: 6 }];

    public static rrpFieldsMap = {
        "perDay": "Per Day", "singleOccupancy": "Single", "doubleOccupancy": "Double",
        "tripleOccupancy": "Triple", "availability": "Availability", "soldRooms": "Sold Rooms", "totalInventory": "Total Inventory",
        "perExtraPerson": "Extra Person", "minStay": "Min Stay", "maxStay": "Max Stay", "minAdvPurchaseDays": "Min Advance Purchase", "maxAdvPurchaseDays": "Max Advance Purchase", "CTD": "CTD", "CTA": "CTA",
        // "minStayArr": "Min Stay Arrival","maxStayArr": "Max Stay Arrival",
        "occupancy0": "Single", "occupancy1": "Double", "occupancy2": "Triple", "occupancy3": "Quadruple", "occupancy4": "Penta",
        "occupancy5": "Hexa", "occupancy6": "Hepta", "occupancy7": "Octa", "occupancy8": "Nona", "occupancy9": "Deca", "extraChildren": "Extra Children"
    };

    public static taxOnSellOtas = ["GIB", "MMT", "TLG", "VID", "CLT", "XBE", "XB1", "XB2", "XB3", "XB4", "XB5", "MXM"];

    static paymentStatus = [
        { Name: 'Prepaid', Id: 0 },
        { Name: 'Pay@Hotel', Id: 1 },
        { Name: 'Direct', Id: 2 }
    ];
    static statusMap = [
        { Name: "UnSpecified", Id: 0 },
        { Name: "Provisional", Id: 1 },
        { Name: "Confirmed", Id: 2 },
        { Name: "Cancelled", Id: 3 },
        { Name: "Modified", Id: 4 },
        { Name: "WaitingList", Id: 5 },
        { Name: "NoShow", Id: 6 }
    ];
    static channelTypes = [
        { Name: 'AADX', Id: 'ADX' },
        // { Name: 'Agoda', Id: 'AGD' },
        { Name: 'Agoda', Id: 'AGO' },
        { Name: 'Airbnb', Id: 'ANB' },
        { Name: 'Asiatravel.com', Id: 'AHC' },
        { Name: 'Atrapalo', Id: 'APL' },
        { Name: 'BestDay', Id: 'BSD' },
        { Name: 'Booking.com', Id: 'BDC' },
        { Name: 'BookIt', Id: 'BIC' },
        { Name: 'BudgetPlaces', Id: 'EGD' },
        { Name: 'CentralR', Id: 'CTR' },
        { Name: 'ClearTrip', Id: 'CLT' },
        { Name: 'ConfirmedRooms', Id: 'CMR' },
        { Name: 'CTrip', Id: 'CTP' },
        { Name: 'Custom BE', Id: 'CBE' },
        { Name: 'DatsIt', Id: 'DIT' },
        { Name: 'DOTW', Id: 'DTW' },
        { Name: 'eGlobe', Id: 'EGB' },
        { Name: 'eGlobe_v3', Id: 'EG3' },
        { Name: 'EaseMyTrip', Id: 'EMT' },
        { Name: 'ETours', Id: 'ETR' },
        { Name: 'Expedia', Id: 'EXP' },
        { Name: 'EZeeGo1', Id: 'EZG' },
        { Name: 'Fabhres', Id: 'FBR' },
        { Name: 'FastBooking.com', Id: 'FBD' },
        { Name: 'Findmystay', Id: 'FMS' },
        { Name: 'GlobeKey', Id: 'GKY' },
        { Name: 'GoIbibo', Id: 'GIB' },
        { Name: 'GoogleHotels', Id: 'GOH' },
        { Name: 'GTA', Id: 'GTA' },
        { Name: 'HappyEasyGo', Id: 'HEG' },
        { Name: 'Hobse', Id: 'HOB' },
        { Name: 'HostelBookers', Id: 'HBK' },
        { Name: 'HostelsClub', Id: 'HSC' },
        { Name: 'HostelWorld.Com', Id: 'HWL' },
        { Name: 'HotelBeds', Id: 'HBD' },
        { Name: 'HotelClub', Id: 'HTL' },
        { Name: 'Hotel.De', Id: 'HDE' },
        { Name: 'HotelPlanner', Id: 'HPL' },
        { Name: 'Hotels4u', Id: 'H4U' },
        { Name: 'HotelsCombined', Id: 'HCB' },
        { Name: 'HotelTravel', Id: 'HTR' },
        { Name: 'HotelTrip', Id: 'HTP' },
        { Name: 'HotelWebsite', Id: 'HWS' },
        { Name: 'Hotelzify', Id: 'HZY' },
        { Name: 'Hotelzon', Id: 'HOZ' },
        { Name: 'HotWire', Id: 'HWR' },
        { Name: 'HRS', Id: 'HRS' },
        { Name: 'HyperGuest', Id: 'HYG' },
        { Name: 'IBCHotels', Id: 'IBC' },
        { Name: 'Iescape', Id: 'ISC' },
        { Name: 'InstantWorldBooking', Id: 'IWB' },
        { Name: 'IRCTC', Id: 'IRC' },
        { Name: 'ITDCBE', Id: 'ITD' },
        { Name: 'Jetsetter', Id: 'JST' },
        { Name: 'LateRooms', Id: 'LRM' },
        { Name: 'Lucid', Id: 'LUC' },
        { Name: 'MakeMyTrip', Id: 'MMT' },
        { Name: 'Master', Id: 'MAX' },
        { Name: 'MantrasDirect', Id: 'MXM' },
        { Name: 'MetGlobal', Id: 'MTG' },
        { Name: 'MobiKwik', Id: 'MKK' },
        { Name: 'MrAndMrsSmith', Id: 'MMS' },
        { Name: 'OneFineRate', Id: 'OFR' },
        { Name: 'Orbitz', Id: 'OBZ' },
        { Name: 'Ostrovok', Id: 'OST' },
        { Name: 'Pegasus', Id: 'PGS' },
        { Name: 'Protel', Id: 'PRO' },
        { Name: 'ReconlineGDS', Id: 'REC' },
        { Name: 'RezTrip', Id: 'REZ' },
        { Name: 'Shawman', Id: 'SHW' },
        { Name: 'SimpleBooking', Id: 'SBO' },
        { Name: 'Simplotel', Id: 'SMP' },
        { Name: 'Splendia', Id: 'SPD' },
        { Name: 'Stayzilla', Id: 'STZ' },
        { Name: 'SuperBreak', Id: 'SPB' },
        { Name: 'Synxis', Id: 'SYX' },
        { Name: 'Synxis_v1', Id: 'SY1' },
        { Name: 'Sysotel', Id: 'STL' },
        { Name: 'TabletHotels', Id: 'TBH' },
        { Name: 'ThinkHotels.com', Id: 'THK' },
        { Name: 'Tourico', Id: 'TCO' },
        { Name: 'TransHotel', Id: 'THL' },
        { Name: 'Travco', Id: 'TRC' },
        { Name: 'TravelGuru', Id: 'TLG' },
        { Name: 'TravelMasti', Id: 'TRM' },
        { Name: 'Travelocity', Id: 'TRG' },
        { Name: 'TravelRepublic', Id: 'TRP' },
        { Name: 'Travelsurf', Id: 'TRS' },
        { Name: 'TripAdvisor', Id: 'TAD' },
        { Name: 'TripConnect', Id: 'TCN' },
        { Name: 'TrustIntl', Id: 'TVY' },
        { Name: 'Tripvillas', Id: 'TPA' },
        { Name: 'UnSpecified', Id: 'USP' },
        { Name: 'Via.com', Id: 'VID' },
        { Name: 'VirtualTourist', Id: 'VIT' },
        { Name: 'WebMantra', Id: 'WEB' },
        { Name: 'WinHMS', Id: 'WMS' },
        { Name: 'Wotif.com', Id: 'WTF' },
        { Name: 'Xchange', Id: 'XCG' },
        { Name: 'XchangeBE1', Id: 'XB1' },
        { Name: 'XchangeBE2', Id: 'XB2' },
        { Name: 'XchangeBE3', Id: 'XB3' },
        { Name: 'XchangeBE4', Id: 'XB4' },
        { Name: 'XchangeBE5', Id: 'XB5' },
        { Name: 'XchangeBE', Id: 'XBE' },
        { Name: 'Yatra.com', Id: 'YAT' }
    ];

    static currencies = [
        { Country: "United Arab Emirates", Code: "AED", Symbol: "د.إ", Currency: "United Arab Emirates Dirham" },
        { Country: "Afghanistan", Code: "AFN", Symbol: "AFN", Currency: "Afghanistani Afghani" },
        { Country: "Albania", Code: "ALL", Symbol: "ALL", Currency: "Albanian Lek" },
        { Country: "Armenia", Code: "AMD", Symbol: "դր", Currency: "Armenian Dram" },
        { Country: "Netherlands Antilles", Code: "ANG", Symbol: "NAƒ", Currency: "Netherlands Antillean Gulden" },
        { Country: "Angola", Code: "AOA", Symbol: "Kz", Currency: "Angolan Kwanza" },
        { Country: "Argentina", Code: "ARS", Symbol: "AR$", Currency: "Argentine Peso" },
        { Country: "Australia", Code: "AUD", Symbol: "AU$", Currency: "Australian Dollar" },
        { Country: "Aruba", Code: "AWG", Symbol: "ƒ", Currency: "Aruban Florin" },
        { Country: "Azerbaijan", Code: "AZN", Symbol: "ман", Currency: "Azerbaijani Manat" },
        { Country: "Bosnia & Herzegovina", Code: "BAM", Symbol: "KM", Currency: "Bosnia-Herzegovina Convertible Mark" },
        { Country: "Barbados", Code: "BBD", Symbol: "Bds$", Currency: "Barbados Dollar" },
        { Country: "Bangladesh", Code: "BDT", Symbol: "৳", Currency: "Bangladeshi Taka" },
        { Country: "Bulgaria", Code: "BGN", Symbol: "лв", Currency: "Bulgarian Lev" },
        { Country: "Bahrain", Code: "BHD", Symbol: "ب.د", Currency: "Bahraini Dinar" },
        { Country: "Burundi", Code: "BIF", Symbol: "FBu", Currency: "Burundian Franc" },
        { Country: "Bermuda", Code: "BMD", Symbol: "BD$", Currency: "Bermuda Dollar" },
        { Country: "Brunei", Code: "BND", Symbol: "B$", Currency: "Brunei Dollar" },
        { Country: "Bolivia", Code: "BOB", Symbol: "Bs", Currency: "Bolivian Boliviano" },
        { Country: "Brazil", Code: "BRL", Symbol: "R$", Currency: "Brazilian Real" },
        { Country: "Bahamas", Code: "BSD", Symbol: "BS$", Currency: "Bahamian Dollar" },
        { Country: "Bhutan", Code: "BTN", Symbol: "Nu", Currency: "Bhutanese Ngultrum" },
        { Country: "Botswana", Code: "BWP", Symbol: "P", Currency: "Botswana Pula" },
        { Country: "Belarus", Code: "BYR", Symbol: "Br", Currency: "Belarusian Ruble" },
        { Country: "Belize", Code: "BZD", Symbol: "BZ$", Currency: "Belize Dollar" },
        { Country: "Canada", Code: "CAD", Symbol: "CA$", Currency: "Canadian Dollar" },
        { Country: "Congo (Dem. Rep.)", Code: "CDF", Symbol: "CDFr", Currency: "Congolese franc" },
        { Country: "Switzerland", Code: "CHF", Symbol: "CHF", Currency: "Swiss Franc" },
        { Country: "Chile", Code: "CLP", Symbol: "CL$", Currency: "Chilean Peso" },
        { Country: "China", Code: "CNY", Symbol: "CN¥", Currency: "Chinese Yuan Renminbi" },
        { Country: "Colombia", Code: "COP", Symbol: "Col$", Currency: "Colombian Peso" },
        { Country: "Costa Rica", Code: "CRC", Symbol: "₡", Currency: "Costa Rican Colon" },
        { Country: "Cuba", Code: "CUC", Symbol: "CU$", Currency: "Cuban Convertible Peso" },
        { Country: "Cape Verde", Code: "CVE", Symbol: "CV$", Currency: "Cape Verde Escudo" },
        { Country: "Cyprus", Code: "CYP", Symbol: "CY£", Currency: "Cypriot Pound" },
        { Country: "Czech Republic", Code: "CZK", Symbol: "Kč", Currency: "Czech Koruna" },
        { Country: "Djibouti", Code: "DJF", Symbol: "Fdj", Currency: "Djiboutian Franc" },
        { Country: "Denmark", Code: "DKK", Symbol: "ø", Currency: "Danish Krone" },
        { Country: "Dominican Republic", Code: "DOP", Symbol: "RD$", Currency: "Dominican Peso" },
        { Country: "Algeria", Code: "DZD", Symbol: "دج", Currency: "Algerian Dinar" },
        { Country: "Estonia", Code: "EEK", Symbol: "KR", Currency: "Estonian Kroon" },
        { Country: "Egypt", Code: "EGP", Symbol: "ج.م", Currency: "Egyptian Pound" },
        { Country: "Eritrea", Code: "ERN", Symbol: "Nfk", Currency: "Eritrean Nakfa" },
        { Country: "Ethiopia", Code: "ETB", Symbol: "Br", Currency: "Ethiopian Birr" },
        { Country: "Europe", Code: "EUR", Symbol: "€", Currency: "European Euro" },
        { Country: "Fiji", Code: "FJD", Symbol: "FJ$", Currency: "Fiji Dollar" },
        { Country: "Falkland Islands (Malvinas)", Code: "FKP", Symbol: "FK£", Currency: "Falkland pound" },
        { Country: "UK", Code: "GBP", Symbol: "UK£", Currency: "United Kingdom Pound Sterling" },
        { Country: "Georgia", Code: "GEL", Symbol: "GEL", Currency: "Georgian Lari" },
        { Country: "Ghana", Code: "GHC", Symbol: "₵", Currency: "Ghanaian Cedi" },
        { Country: "Gibraltar", Code: "GIP", Symbol: "GI£", Currency: "Gibraltar Pound" },
        { Country: "Gambia", Code: "GMD", Symbol: "D", Currency: "Gambian Dalasi" },
        { Country: "Guinea", Code: "GNF", Symbol: "FG", Currency: "Guinean Franc" },
        { Country: "Guatemala", Code: "GTQ", Symbol: "Q", Currency: "Guatemalan Quetzal" },
        { Country: "Guyana", Code: "GYD", Symbol: "GY$", Currency: "Guyanese Dollar" },
        { Country: "Hong Kong", Code: "HKD", Symbol: "HK$", Currency: "Hong Kong Dollar" },
        { Country: "Honduras", Code: "HNL", Symbol: "L", Currency: "Honduran Lempira" },
        { Country: "Croatia (Hrvatska)", Code: "HRK", Symbol: "HRK", Currency: "Croatian Kuna" },
        { Country: "Haiti", Code: "HTG", Symbol: "G", Currency: "Haitian Gourde" },
        { Country: "Hungary", Code: "HUF", Symbol: "Ft", Currency: "Hungarian Forint" },
        { Country: "Indonesia", Code: "IDR", Symbol: "Rp", Currency: "Indonesian Rupiah" },
        { Country: "Israel", Code: "ILS", Symbol: "₪", Currency: "Israeli New Sheqel" },
        { Country: "India", Code: "INR", Symbol: "₹", Currency: "Indian Rupee" },
        { Country: "Iraq", Code: "IQD", Symbol: "ع.د", Currency: "Iraqi Dinar" },
        { Country: "Iran", Code: "IRR", Symbol: "ريال", Currency: "Iranian Rial" },
        { Country: "Iceland", Code: "ISK", Symbol: "ISK", Currency: "Icelandic Krona" },
        { Country: "Jamaica", Code: "JMD", Symbol: "JA$", Currency: "Jamaican Dollar" },
        { Country: "Jordan", Code: "JOD", Symbol: "JD", Currency: "Jordanian Dinar" },
        { Country: "Japan", Code: "JPY", Symbol: "JP¥", Currency: "Japanese Yen" },
        { Country: "Kenya", Code: "KES", Symbol: "KSh", Currency: "Kenyan Shilling" },
        { Country: "Kyrgyzstan", Code: "KGS", Symbol: "KGS", Currency: "Kyrgyzstani Som" },
        { Country: "Cambodia", Code: "KHR", Symbol: "៛", Currency: "Cambodian Riel" },
        { Country: "Comoros", Code: "KMF", Symbol: "KMF", Currency: "Comorian Franc" },
        { Country: "North Korea", Code: "KPW", Symbol: "₩", Currency: "North Korean Won" },
        { Country: "South Korea", Code: "KRW", Symbol: "₩", Currency: "Korean Won" },
        { Country: "Kuwait", Code: "KWD", Symbol: "د.ك", Currency: "Kuwaiti Dinar" },
        { Country: "Cayman Islands", Code: "KYD", Symbol: "CI$", Currency: "Cayman Islands Dollar" },
        { Country: "Kazakhstan", Code: "KZT", Symbol: "KZT", Currency: "Kazakhstani Tenge" },
        { Country: "Laos", Code: "LAK", Symbol: "₭", Currency: "Lao Kip" },
        { Country: "Lebanon", Code: "LBP", Symbol: "ل.ل", Currency: "Lebanese Pound" },
        { Country: "Sri Lanka", Code: "LKR", Symbol: "LK₨", Currency: "Sri Lankan Rupee" },
        { Country: "Liberia", Code: "LRD", Symbol: "L$", Currency: "Liberian Dollar" },
        { Country: "Lesotho", Code: "LSL", Symbol: "L", Currency: "Lesotho Loti" },
        { Country: "Lithuania", Code: "LTL", Symbol: "Lt", Currency: "Lithuanian Litas" },
        { Country: "Latvia", Code: "LVL", Symbol: "Ls", Currency: "Latvian Lats" },
        { Country: "Libya", Code: "LYD", Symbol: "ل.د", Currency: "Libyan Dinar" },
        { Country: "Morocco", Code: "MAD", Symbol: "د.م", Currency: "Moroccan Dirham" },
        { Country: "Moldova", Code: "MDL", Symbol: "MDL", Currency: "Moldovan Leu" },
        { Country: "Madagascar", Code: "MGA", Symbol: "MGA", Currency: "Malagasy Ariary" },
        { Country: "Macedonia", Code: "MKD", Symbol: "MKD", Currency: "Macedonian Denar" },
        { Country: "Myanmar (Burma)", Code: "MMK", Symbol: "K", Currency: "Myanmar Kyat" },
        { Country: "Mongolia", Code: "MNT", Symbol: "₮", Currency: "Mongolian Tugrik" },
        { Country: "Macau", Code: "MOP", Symbol: "MO$", Currency: "Macanese Pataca" },
        { Country: "Mauritania", Code: "MRO", Symbol: "UM", Currency: "Mauritanian Ouguiya" },
        { Country: "Malta", Code: "MTL", Symbol: "Lm", Currency: "Maltese Lira" },
        { Country: "Mauritius", Code: "MUR", Symbol: "MU₨", Currency: "Mauritian Rupee" },
        { Country: "Maldives", Code: "MVR", Symbol: "MRf", Currency: "Maldives Rufiyaa" },
        { Country: "Malawi", Code: "MWK", Symbol: "MK", Currency: "Malawian Kwacha" },
        { Country: "Mexico", Code: "MXN", Symbol: "Mex$", Currency: "Mexican Peso" },
        { Country: "Malaysia", Code: "MYR", Symbol: "RM", Currency: "Malaysian Ringgit" },
        { Country: "Mozambique", Code: "MZN", Symbol: "MTn", Currency: "Mozambican Metical" },
        { Country: "Namibia", Code: "NAD", Symbol: "N$", Currency: "Namibian Dollar" },
        { Country: "Nigeria", Code: "NGN", Symbol: "₦", Currency: "Nigerian Naira" },
        { Country: "Nicaragua", Code: "NIO", Symbol: "C$", Currency: "Nicaraguan Córdoba" },
        { Country: "Norway", Code: "NOK", Symbol: "øre", Currency: "Norwegian Krone" },
        { Country: "Nepal", Code: "NPR", Symbol: "NP₨", Currency: "Nepalese Rupee" },
        { Country: "New Zealand", Code: "NZD", Symbol: "NZ$", Currency: "New Zealand Dollar" },
        { Country: "Oman", Code: "OMR", Symbol: "ر.ع", Currency: "Omani Rial" },
        { Country: "Panama", Code: "PAB", Symbol: "PAB", Currency: "Panamanian Balboa" },
        { Country: "Peru", Code: "PEN", Symbol: "S./", Currency: "Peruvian Nuevo Sol" },
        { Country: "Papua New Guinea", Code: "PGK", Symbol: "K", Currency: "Papua New Guinea Kina" },
        { Country: "Philippines", Code: "PHP", Symbol: "₱", Currency: "Philippine Peso" },
        { Country: "Pakistan", Code: "PKR", Symbol: "PKRs", Currency: "Pakistan Rupee" },
        { Country: "Poland", Code: "PLN", Symbol: "zł", Currency: "Polish Zloty" },
        { Country: "Paraguay", Code: "PYG", Symbol: "₲", Currency: "Paraguay Guarani" },
        { Country: "Qatar", Code: "QAR", Symbol: "ر.ق", Currency: "Qatari Riyal" },
        { Country: "Romania", Code: "RON", Symbol: "ROL", Currency: "Romanian Leu" },
        { Country: "Serbia", Code: "RSD", Symbol: "дин", Currency: "Serbian Dinar" },
        { Country: "Russia", Code: "RUB", Symbol: "RUруб", Currency: "Russian Ruble" },
        { Country: "Rwanda", Code: "RWF", Symbol: "RF", Currency: "Rwandan Franc" },
        { Country: "Saudi Arabia", Code: "SAR", Symbol: "ر.س", Currency: "Saudi Arabian Riyal" },
        { Country: "Solomon Islands", Code: "SBD", Symbol: "SI$", Currency: "Solomon Islands Dollar" },
        { Country: "Seychelles", Code: "SCR", Symbol: "SRe", Currency: "Seychelles Rupee" },
        { Country: "Sudan", Code: "SDD", Symbol: "£Sd", Currency: "Sudanese Dinar" },
        { Country: "Sweden", Code: "SEK", Symbol: "kr", Currency: "Swedish Krona" },
        { Country: "Singapore", Code: "SGD", Symbol: "S$", Currency: "Singapore Dollar" },
        { Country: "St Helena", Code: "SHP", Symbol: "SH£", Currency: "St Helena Pound" },
        { Country: "Slovakia", Code: "SKK", Symbol: "Sk", Currency: "Slovak Koruna" },
        { Country: "Sierra Leone", Code: "SLL", Symbol: "Le", Currency: "Sierra Leonean Leone" },
        { Country: "Somalia", Code: "SOS", Symbol: "Sh", Currency: "Somali Shilling" },
        { Country: "Suriname", Code: "SRD", Symbol: "SR$", Currency: "Suriname Dollar" },
        { Country: "Sao Tome & Principe", Code: "STD", Symbol: "Db", Currency: "Sao Tome Dobra" },
        { Country: "Syria", Code: "SYP", Symbol: "S£", Currency: "Syrian Pound" },
        { Country: "Swaziland", Code: "SZL", Symbol: "SZL", Currency: "Swazi Lilangeni" },
        { Country: "Thailand", Code: "THB", Symbol: "฿", Currency: "Thai Baht" },
        { Country: "Tajikistan", Code: "TJS", Symbol: "TJS", Currency: "Tajikistan Somoni" },
        { Country: "Turkmenistan", Code: "TMM", Symbol: "m", Currency: "Turkmenistani Manat" },
        { Country: "Tunisia", Code: "TND", Symbol: "د.ت", Currency: "Tunisian Dinar" },
        { Country: "Tonga", Code: "TOP", Symbol: "PT$", Currency: "Tongan Pa'Anga" },
        { Country: "Turkey", Code: "TRY", Symbol: "YTL", Currency: "Turkish New Lira" },
        { Country: "Trinidad & Tobago", Code: "TTD", Symbol: "TT$", Currency: "Trinidad and Tobago Dollar" },
        { Country: "Taiwan", Code: "TWD", Symbol: "NT$", Currency: "New Taiwan Dollar" },
        { Country: "Tanzania", Code: "TZS", Symbol: "TSh", Currency: "Tanzanian Shilling" },
        { Country: "Ukraine", Code: "UAH", Symbol: "₴", Currency: "Ukrainian Hryvnia" },
        { Country: "Uganda", Code: "UGX", Symbol: "USh", Currency: "Ugandan Shilling" },
        { Country: "USA", Code: "USD", Symbol: "$", Currency: "United States Dollar" },
        { Country: "Uruguay", Code: "UYU", Symbol: "UR$", Currency: "Uruguayan peso" },
        { Country: "Uzbekistan", Code: "UZS", Symbol: "UZS", Currency: "Uzbekistani Som" },
        { Country: "Venezuela", Code: "VEB", Symbol: "Bs", Currency: "Venezuelan Bolivar" },
        { Country: "Vietnam", Code: "VND", Symbol: "₫", Currency: "Viet Nam Dong" },
        { Country: "Vanuatu", Code: "VUV", Symbol: "Vt", Currency: "Vanuatu Vatu" },
        { Country: "Samoa", Code: "WST", Symbol: "WS$", Currency: "Samoan Tala" },
        { Country: "Cameroon", Code: "XAF", Symbol: "franc", Currency: "Central African CFA" },
        { Country: "Antigua & Barbuda", Code: "XCD", Symbol: "EC$", Currency: "East Caribbean Dollar" },
        { Country: "West Africa", Code: "XOF", Symbol: "franc", Currency: "West African CFA" },
        { Country: "French Pacific", Code: "XPF", Symbol: "CFP", Currency: "French Pacific Franc" },
        { Country: "Yemen", Code: "YER", Symbol: "YER", Currency: "Yemeni Rial" },
        { Country: "South Africa", Code: "ZAR", Symbol: "SAR", Currency: "South African Rand" },
        { Country: "Zambia", Code: "ZMK", Symbol: "ZK", Currency: "Zambian Kwacha" },
        { Country: "Zimbabwe", Code: "ZWD", Symbol: "Z$", Currency: "Zimbabwean Dollar" }
    ];

    static timeZone = [
        { value: "Dateline Standard Time", label: "(UTC-12:00) International Date Line West" },
        { value: "UTC-11", label: "(UTC-11:00) Coordinated Universal Time-11" },
        { value: "Hawaiian Standard Time", label: "(UTC-10:00) Hawaii" },
        { value: "Alaskan Standard Time", label: "(UTC-09:00) Alaska" },
        { value: "Pacific Standard Time (Mexico)", label: "(UTC-08:00) Baja California" },
        { value: "Pacific Standard Time", label: "(UTC-08:00) Pacific Time (US &amp; Canada)" },
        { value: "US Mountain Standard Time", label: "(UTC-07:00) Arizona" },
        { value: "Mountain Standard Time (Mexico)", label: "(UTC-07:00) Chihuahua, La Paz, Mazatlan" },
        { value: "Mountain Standard Time", label: "(UTC-07:00) Mountain Time (US &amp; Canada)" },
        { value: "Central America Standard Time", label: "(UTC-06:00) Central America" },
        { value: "Central Standard Time", label: "(UTC-06:00) Central Time (US &amp; Canada)" },
        { value: "Central Standard Time (Mexico)", label: "(UTC-06:00) Guadalajara, Mexico City, Monterrey" },
        { value: "Canada Central Standard Time", label: "(UTC-06:00) Saskatchewan" },
        { value: "SA Pacific Standard Time", label: "(UTC-05:00) Bogota, Lima, Quito" },
        { value: "Eastern Standard Time", label: "(UTC-05:00) Eastern Time (US &amp; Canada)" },
        { value: "US Eastern Standard Time", label: "(UTC-05:00) Indiana (East)" },
        { value: "Venezuela Standard Time", label: "(UTC-04:30) Caracas" },
        { value: "Paraguay Standard Time", label: "(UTC-04:00) Asuncion" },
        { value: "Atlantic Standard Time", label: "(UTC-04:00) Atlantic Time (Canada)" },
        { value: "Central Brazilian Standard Time", label: "(UTC-04:00) Cuiaba" },
        { value: "SA Western Standard Time", label: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan" },
        { value: "Newfoundland Standard Time", label: "(UTC-03:30) Newfoundland" },
        { value: "E. South America Standard Time", label: "(UTC-03:00) Brasilia" },
        { value: "SA Eastern Standard Time", label: "(UTC-03:00) Cayenne, Fortaleza" },
        { value: "Argentina Standard Time", label: "(UTC-03:00) Buenos Aires" },
        { value: "Greenland Standard Time", label: "(UTC-03:00) Greenland" },
        { value: "Montevideo Standard Time", label: "(UTC-03:00) Montevideo" },
        { value: "Bahia Standard Time", label: "(UTC-03:00) Salvador" },
        { value: "Pacific SA Standard Time", label: "(UTC-04:00) Santiago" },
        { value: "UTC-02", label: "(UTC-02:00) Coordinated Universal Time-02" },
        { value: "Mid-Atlantic Standard Time", label: "(UTC-02:00) Mid-Atlantic - Old" },
        { value: "Morocco Standard Time", label: "(UTC) Casablanca" },
        { value: "UTC", label: "(UTC) Coordinated Universal Time" },
        { value: "GMT Standard Time", label: "(UTC) Dublin, Edinburgh, Lisbon, London" },
        { value: "Greenwich Standard Time", label: "(UTC) Monrovia, Reykjavik" },
        { value: "W. Europe Standard Time", label: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
        { value: "Central Europe Standard Time", label: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
        { value: "Romance Standard Time", label: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris" },
        { value: "Central European Standard Time", label: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
        { value: "W. Central Africa Standard Time", label: "(UTC+01:00) West Central Africa" },
        { value: "Namibia Standard Time", label: "(UTC+01:00) Windhoek" },
        { value: "Jordan Standard Time", label: "(UTC+03:00) Amman" },
        { value: "GTB Standard Time", label: "(UTC+02:00) Athens, Bucharest" },
        { value: "Middle East Standard Time", label: "(UTC+02:00) Beirut" },
        { value: "Egypt Standard Time", label: "(UTC+02:00) Cairo" },
        { value: "Syria Standard Time", label: "(UTC+02:00) Damascus" },
        { value: "E. Europe Standard Time", label: "(UTC+02:00) E. Europe" },
        { value: "South Africa Standard Time", label: "(UTC+02:00) Harare, Pretoria" },
        { value: "FLE Standard Time", label: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
        { value: "Turkey Standard Time", label: "(UTC+02:00) Istanbul" },
        { value: "Israel Standard Time", label: "(UTC+02:00) Jerusalem" },
        { value: "Libya Standard Time", label: "(UTC+02:00) Tripoli" },
        { value: "Arabic Standard Time", label: "(UTC+03:00) Baghdad" },
        { value: "Arab Standard Time", label: "(UTC+03:00) Kuwait, Riyadh" },
        { value: "E. Africa Standard Time", label: "(UTC+03:00) Nairobi" },
        { value: "Iran Standard Time", label: "(UTC+03:30) Tehran" },
        { value: "Arabian Standard Time", label: "(UTC+04:00) Abu Dhabi, Muscat" },
        { value: "Azerbaijan Standard Time", label: "(UTC+04:00) Baku" },
        { value: "Mauritius Standard Time", label: "(UTC+04:00) Port Louis" },
        { value: "Georgian Standard Time", label: "(UTC+04:00) Tbilisi" },
        { value: "Caucasus Standard Time", label: "(UTC+04:00) Yerevan" },
        { value: "Afghanistan Standard Time", label: "(UTC+04:30) Kabul" },
        { value: "West Asia Standard Time", label: "(UTC+05:00) Ashgabat, Tashkent" },
        { value: "Pakistan Standard Time", label: "(UTC+05:00) Islamabad, Karachi" },
        { value: "India Standard Time", label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
        { value: "Sri Lanka Standard Time", label: "(UTC+05:30) Sri Jayawardenepura" },
        { value: "Nepal Standard Time", label: "(UTC+05:45) Kathmandu" },
        { value: "Central Asia Standard Time", label: "(UTC+06:00) Astana" },
        { value: "Bangladesh Standard Time", label: "(UTC+06:00) Dhaka" },
        { value: "Myanmar Standard Time", label: "(UTC+06:30) Yangon (Rangoon)" },
        { value: "SE Asia Standard Time", label: "(UTC+07:00) Bangkok, Hanoi, Jakarta" },
        { value: "China Standard Time", label: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
        { value: "Singapore Standard Time", label: "(UTC+08:00) Kuala Lumpur, Singapore" },
        { value: "W. Australia Standard Time", label: "(UTC+08:00) Perth" },
        { value: "Taipei Standard Time", label: "(UTC+08:00) Taipei" },
        { value: "Ulaanbaatar Standard Time", label: "(UTC+08:00) Ulaanbaatar" },
        { value: "Tokyo Standard Time", label: "(UTC+09:00) Osaka, Sapporo, Tokyo" },
        { value: "Korea Standard Time", label: "(UTC+09:00) Seoul" },
        { value: "Cen. Australia Standard Time", label: "(UTC+09:30) Adelaide" },
        { value: "AUS Central Standard Time", label: "(UTC+09:30) Darwin" },
        { value: "E. Australia Standard Time", label: "(UTC+10:00) Brisbane" },
        { value: "AUS Eastern Standard Time", label: "(UTC+10:00) Canberra, Melbourne, Sydney" },
        { value: "West Pacific Standard Time", label: "(UTC+10:00) Guam, Port Moresby" },
        { value: "Tasmania Standard Time", label: "(UTC+10:00) Hobart" },
        { value: "Magadan Standard Time", label: "(UTC+12:00) Magadan" },
        { value: "Central Pacific Standard Time", label: "(UTC+11:00) Solomon Is., New Caledonia" },
        { value: "New Zealand Standard Time", label: "(UTC+12:00) Auckland, Wellington" },
        { value: "UTC+12", label: "(UTC+12:00) Coordinated Universal Time+12" },
        { value: "Fiji Standard Time", label: "(UTC+12:00) Fiji" },
        { value: "Kamchatka Standard Time", label: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old" },
        { value: "Tonga Standard Time", label: "(UTC+13:00) Nuku'alofa" },
        { value: "Samoa Standard Time", label: "(UTC+13:00) Samoa" }];

    static brandName = ["Classic International Hotels", "B&B.com", "Joie de Vivre", "Resinter", "University Centre Hotel", "Indian Hotel Reservations System", "HOTUSA Hotels",
        "Outrigger Hotels Hawaii", "Active Hotels", "Design Hotels", "Taj Hotels, Resorts & Palaces", "Vacation Resorts International", "Hawthorn Suites", "InnQuest Client", "Vienna Group of Hotels",
        "Red Roof Inns", "InnPoints Worldwide", "Indigo", "Historic Inns", "Sokos", "Independent Hotels", "Rica Hotels", "None: Bed and Breakfast Association of M", "Teleservice Reservations",
        "La Casa Del Camino", "SUTTON PLACE", "MACDONALD HOTEL GRP", "Rafael Hoteles", "National Nine", "Killington Resort", "Malmaison Hotels", "Sceptre Hospitality", "Danish Inns",
        "National Reservation Bureau", "Swallow Hotels", "Queens Hotels", "Sestante Net", "Abba Hotel Group", "Atel Hotels", "Rendezvous Hotels International", "Cosytel",
        "Best Western International", "RCI Hotels", "Travelodge UK", "Husa", "Abotel", "Great Hotels of the World", "Hilton International", "Interlink", "Sierra Suites Hotels", "AC HOTELES",
        "Outrigger Hotels", "Hot Key International", "Fairmont Hotels", "ACC-Nifos", "Wyndham Garden", "Jackson Hole Resort Association", "Achat Hotels", "The Regency Hotel Group",
        "Jolly Hotel Group", "SynXis� Hotels and Resorts", "Adam's Mark Hotels & Resorts", "RYDGES HOTELS", "Leptos Calypso Hotels", "Wellesley Inn & Suites Group", "Parthenon",
        "NORLANDIA HOTELS", "Sea Splash Resort", "Fitzpatrick Hotels", "Prima Hotels", "Exclusive Hotels", "Days Inn", "Pacific Plaza Hotels"]

    static mealPlans = [{ label: "None", value: null }, { label: "All Inclusive (AP)", value: "AP" }, { label: "Breakfast (CP)", value: "CP" }, { label: "Room Only (EP)", value: "EP" }, { label: "Breafast, Lunch, Dinner(MAP)", value: "MAP" }];

    static hotelAmenities = [{ label: "24-hour front desk", value: "24-hour front desk" },
    { label: "24-hour room service", value: "24-hour room service" },
    { label: "24-hour security", value: "24-hour security" },
    { label: "Adjoining rooms", value: "Adjoining rooms" },
    { label: "Air conditioning", value: "Air conditioning" },
    { label: "Airline desk", value: "Airline desk" },
    { label: "ATM/Cash machine", value: "ATM/Cash machine" },
    { label: "Baby sitting", value: "Baby sitting" },
    { label: "BBQ/Picnic area", value: "BBQ/Picnic area" },
    { label: "Bilingual staff", value: "Bilingual staff" },
    { label: "Bookstore", value: "Bookstore" },
    { label: "Boutiques/stores", value: "Boutiques/stores" },
    { label: "Brailed elevators", value: "Brailed elevators" },
    { label: "Business library", value: "Business library" },
    { label: "Car rental desk", value: "Car rental desk" },
    { label: "Casino", value: "Casino" },
    { label: "Check cashing policy", value: "Check cashing policy" },
    { label: "Check-in kiosk", value: "Check-in kiosk" },
    { label: "Cocktail lounge", value: "Cocktail lounge" },
    { label: "Coffee shop", value: "Coffee shop" },
    { label: "Coin operated laundry", value: "Coin operated laundry" },
    { label: "Concierge desk", value: "Concierge desk" },
    { label: "Concierge floor", value: "Concierge floor" },
    { label: "Conference facilities", value: "Conference facilities" },
    { label: "Courtyard", value: "Courtyard" },
    { label: "Currency exchange", value: "Currency exchange" },
    { label: "Desk with electrical outlet", value: "Desk with electrical outlet" },
    { label: "Doctor on call", value: "Doctor on call" },
    { label: "Door man", value: "Door man" },
    { label: "Driving range", value: "Driving range" },
    { label: "Drugstore/pharmacy", value: "Drugstore/pharmacy" },
    { label: "Duty free shop", value: "Duty free shop" },
    { label: "Elevators", value: "Elevators" },
    { label: "Executive floor", value: "Executive floor" },
    { label: "Exercise gym", value: "Exercise gym" },
    { label: "Express check-in", value: "Express check-in" },
    { label: "Express check-out", value: "Express check-out" },
    { label: "Family plan", value: "Family plan" },
    { label: "Florist", value: "Florist" },
    { label: "Folios", value: "Folios" },
    { label: "Free airport shuttle", value: "Free airport shuttle" },
    { label: "Free parking", value: "Free parking" },
    { label: "Free transportation", value: "Free transportation" },
    { label: "Game room", value: "Game room" },
    { label: "Gift/News stand", value: "Gift/News stand" },
    { label: "Hairdresser/barber", value: "Hairdresser/barber" },
    { label: "Accessible facilities", value: "Accessible facilities" },
    { label: "Health club", value: "Health club" },
    { label: "Heated pool", value: "Heated pool" },
    { label: "Housekeeping - daily", value: "Housekeeping - daily" },
    { label: "Housekeeping - weekly", value: "Housekeeping - weekly" },
    { label: "Ice machine", value: "Ice machine" },
    { label: "Indoor parking", value: "Indoor parking" },
    { label: "Indoor pool", value: "Indoor pool" },
    { label: "Jacuzzi", value: "Jacuzzi" },
    { label: "Jogging track", value: "Jogging track" },
    { label: "Kennels", value: "Kennels" },
    { label: "Laundry/Valet service", value: "Laundry/Valet service" },
    { label: "Liquor store", value: "Liquor store" },
    { label: "Live entertainment", value: "Live entertainment" },
    { label: "Massage services", value: "Massage services" },
    { label: "Nightclub", value: "Nightclub" },
    { label: "Off-Site parking", value: "Off-Site parking" },
    { label: "On-Site parking", value: "On-Site parking" },
    { label: "Outdoor parking", value: "Outdoor parking" },
    { label: "Outdoor pool", value: "Outdoor pool" },
    { label: "Package/Parcel services", value: "Package/Parcel services" },
    { label: "Parking", value: "Parking" },
    { label: "Photocopy center", value: "Photocopy center" },
    { label: "Playground", value: "Playground" },
    { label: "Pool", value: "Pool" },
    { label: "Poolside snack bar", value: "Poolside snack bar" },
    { label: "Public address system", value: "Public address system" },
    { label: "Ramp access", value: "Ramp access" },
    { label: "Recreational vehicle parking", value: "Recreational vehicle parking" },
    { label: "Restaurant", value: "Restaurant" },
    { label: "Room service", value: "Room service" },
    { label: "Safe deposit box", value: "Safe deposit box" },
    { label: "Sauna", value: "Sauna" },
    { label: "Security", value: "Security" },
    { label: "Shoe shine stand", value: "Shoe shine stand" },
    { label: "Shopping mall", value: "Shopping mall" },
    { label: "Solarium", value: "Solarium" },
    { label: "Spa", value: "Spa" },
    { label: "Sports bar", value: "Sports bar" },
    { label: "Steam bath", value: "Steam bath" },
    { label: "Storage space", value: "Storage space" },
    { label: "Sundry/Convenience store", value: "Sundry/Convenience store" },
    { label: "Technical concierge", value: "Technical concierge" },
    { label: "Theatre desk", value: "Theatre desk" },
    { label: "Tour/sightseeing desk", value: "Tour/sightseeing desk" },
    { label: "Translation services", value: "Translation services" },
    { label: "Travel agency", value: "Travel agency" },
    { label: "Truck parking", value: "Truck parking" },
    { label: "Valet cleaning", value: "Valet cleaning" },
    { label: "Dry cleaning", value: "Dry cleaning" },
    { label: "Valet parking", value: "Valet parking" },
    { label: "Vending machines", value: "Vending machines" },
    { label: "Video tapes", value: "Video tapes" },
    { label: "Wakeup service", value: "Wakeup service" },
    { label: "Wheelchair access", value: "Wheelchair access" },
    { label: "Whirlpool", value: "Whirlpool" },
    { label: "Multilingual staff", value: "Multilingual staff" },
    { label: "Wedding services", value: "Wedding services" },
    { label: "Banquet facilities", value: "Banquet facilities" },
    { label: "Bell staff/porter", value: "Bell staff/porter" },
    { label: "Beauty shop/salon", value: "Beauty shop/salon" },
    { label: "Complimentary self service laundry", value: "Complimentary self service laundry" },
    { label: "Direct dial telephone", value: "Direct dial telephone" },
    { label: "Female traveler room/floor", value: "Female traveler room/floor" },
    { label: "Pharmacy", value: "Pharmacy" },
    { label: "Stables", value: "Stables" },
    { label: "120 AC", value: "120 AC" },
    { label: "120 DC", value: "120 DC" },
    { label: "220 AC", value: "220 AC" },
    { label: "Accessible parking", value: "Accessible parking" },
    { label: "220 DC", value: "220 DC" },
    { label: "Barbeque grills", value: "Barbeque grills" },
    { label: "Women's clothing", value: "Women's clothing" },
    { label: "Men's clothing", value: "Men's clothing" },
    { label: "Children's clothing", value: "Children's clothing" },
    { label: "Shops and commercial services", value: "Shops and commercial services" },
    { label: "Video games", value: "Video games" },
    { label: "Sports bar open for lunch", value: "Sports bar open for lunch" },
    { label: "Sports bar open for dinner", value: "Sports bar open for dinner" },
    { label: "Room service - full menu", value: "Room service - full menu" },
    { label: "Room service - limited menu", value: "Room service - limited menu" },
    { label: "Room service - limited hours", value: "Room service - limited hours" },
    { label: "Valet same day dry cleaning", value: "Valet same day dry cleaning" },
    { label: "Body scrub", value: "Body scrub" },
    { label: "Body wrap", value: "Body wrap" },
    { label: "Public area air conditioned", value: "Public area air conditioned" },
    { label: "Efolio available to company", value: "Efolio available to company" },
    { label: "Individual Efolio available", value: "Individual Efolio available" },
    { label: "Video review billing", value: "Video review billing" },
    { label: "Butler service", value: "Butler service" },
    { label: "Complimentary in-room coffee or tea", value: "Complimentary in-room coffee or tea" },
    { label: "Complimentary buffet breakfast", value: "Complimentary buffet breakfast" },
    { label: "Complimentary cocktails", value: "Complimentary cocktails" },
    { label: "Complimentary coffee in lobby", value: "Complimentary coffee in lobby" },
    { label: "Complimentary continental breakfast", value: "Complimentary continental breakfast" },
    { label: "Complimentary full american breakfast", value: "Complimentary full american breakfast" },
    { label: "Dinner delivery service from local restaurant", value: "Dinner delivery service from local restaurant" },
    { label: "Complimentary newspaper delivered to room", value: "Complimentary newspaper delivered to room" },
    { label: "Complimentary newspaper in lobby", value: "Complimentary newspaper in lobby" },
    { label: "Complimentary shoeshine", value: "Complimentary shoeshine" },
    { label: "Evening reception", value: "Evening reception" },
    { label: "Front desk", value: "Front desk" },
    { label: "Grocery shopping service available", value: "Grocery shopping service available" },
    { label: "Halal food available", value: "Halal food available" },
    { label: "Kosher food available", value: "Kosher food available" },
    { label: "Limousine service", value: "Limousine service" },
    { label: "Managers reception", value: "Managers reception" },
    { label: "Medical Facilities Service", value: "Medical Facilities Service" },
    { label: "Telephone jack adaptor available", value: "Telephone jack adaptor available" },
    { label: "All-inclusive meal plan", value: "All-inclusive meal plan" },
    { label: "Buffet breakfast", value: "Buffet breakfast" },
    { label: "Communal bar area", value: "Communal bar area" },
    { label: "Continental breakfast", value: "Continental breakfast" },
    { label: "Full meal plan", value: "Full meal plan" },
    { label: "Full american breakfast", value: "Full american breakfast" },
    { label: "Meal plan available", value: "Meal plan available" },
    { label: "Modified american meal plan", value: "Modified american meal plan" },
    { label: "Food and beverage outlets", value: "Food and beverage outlets" },
    { label: "Bar/Lounge", value: "Bar/Lounge" },
    { label: "Barber shop", value: "Barber shop" },
    { label: "Video checkout", value: "Video checkout" },
    { label: "Onsite laundry", value: "Onsite laundry" },
    { label: "24-hour food & beverage kiosk", value: "24-hour food & beverage kiosk" },
    { label: "Concierge lounge", value: "Concierge lounge" },
    { label: "Parking fee managed by hotel", value: "Parking fee managed by hotel" },
    { label: "Transportation", value: "Transportation" },
    { label: "Breakfast served in restaurant", value: "Breakfast served in restaurant" },
    { label: "Lunch served in restaurant", value: "Lunch served in restaurant" },
    { label: "Dinner served in restaurant", value: "Dinner served in restaurant" },
    { label: "Full service housekeeping", value: "Full service housekeeping" },
    { label: "Limited service housekeeping", value: "Limited service housekeeping" },
    { label: "High speed internet access for laptop in public areas", value: "High speed internet access for laptop in public areas" },
    { label: "Wireless internet connection in public areas", value: "Wireless internet connection in public areas" },
    { label: "Additional services/amenities/facilities on property", value: "Additional services/amenities/facilities on property" },
    { label: "Transportation services - local area", value: "Transportation services - local area" },
    { label: "Transportation services - local office", value: "Transportation services - local office" },
    { label: "DVD/video rental", value: "DVD/video rental" },
    { label: "Parking lot", value: "Parking lot" },
    { label: "Parking deck", value: "Parking deck" },
    { label: "Street side parking", value: "Street side parking" },
    { label: "Cocktail lounge with entertainment", value: "Cocktail lounge with entertainment" },
    { label: "Cocktail lounge with light fare", value: "Cocktail lounge with light fare" },
    { label: "Motorcycle parking", value: "Motorcycle parking" },
    { label: "Phone services", value: "Phone services" },
    { label: "Ballroom", value: "Ballroom" },
    { label: "Bus parking", value: "Bus parking" },
    { label: "Children's play area", value: "Children's play area" },
    { label: "Children's nursery", value: "Children's nursery" },
    { label: "Disco", value: "Disco" },
    { label: "Early check-in", value: "Early check-in" },
    { label: "Locker room", value: "Locker room" },
    { label: "Non-smoking rooms (generic)", value: "Non-smoking rooms (generic)" },
    { label: "Train access", value: "Train access" },
    { label: "Aerobics instruction", value: "Aerobics instruction" },
    { label: "Baggage hold", value: "Baggage hold" },
    { label: "Bicycle rentals", value: "Bicycle rentals" },
    { label: "Dietician", value: "Dietician" },
    { label: "Late check-out available", value: "Late check-out available" },
    { label: "Pet-sitting services", value: "Pet-sitting services" },
    { label: "Prayer mats", value: "Prayer mats" },
    { label: "Sports trainer", value: "Sports trainer" },
    { label: "Turndown service", value: "Turndown service" },
    { label: "DVDs/videos - children", value: "DVDs/videos - children" },
    { label: "Bank", value: "Bank" },
    { label: "Lobby coffee service", value: "Lobby coffee service" },
    { label: "Banking services", value: "Banking services" },
    { label: "Stairwells", value: "Stairwells" },
    { label: "Pet amenities available", value: "Pet amenities available" },
    { label: "Exhibition/convention floor", value: "Exhibition/convention floor" },
    { label: "Long term parking", value: "Long term parking" },
    { label: "Children not allowed", value: "Children not allowed" },
    { label: "Children welcome", value: "Children welcome" },
    { label: "Courtesy car", value: "Courtesy car" },
    { label: "Hotel does not provide pornographic films/TV", value: "Hotel does not provide pornographic films/TV" },
    { label: "Hotspots", value: "Hotspots" },
    { label: "Free high speed internet connection", value: "Free high speed internet connection" },
    { label: "Internet services", value: "Internet services" },
    { label: "Pets allowed", value: "Pets allowed" },
    { label: "Gourmet highlights", value: "Gourmet highlights" },
    { label: "Catering services", value: "Catering services" },
    { label: "Complimentary breakfast", value: "Complimentary breakfast" },
    { label: "Business center", value: "Business center" },
    { label: "Business services", value: "Business services" },
    { label: "Secured parking", value: "Secured parking" },
    { label: "Racquetball", value: "Racquetball" },
    { label: "Snow sports", value: "Snow sports" },
    { label: "Tennis court", value: "Tennis court" },
    { label: "Water sports", value: "Water sports" },
    { label: "Child programs", value: "Child programs" },
    { label: "Golf", value: "Golf" },
    { label: "Horseback riding", value: "Horseback riding" },
    { label: "Oceanfront", value: "Oceanfront" },
    { label: "Beachfront", value: "Beachfront" },
    { label: "Hair dryer", value: "Hair dryer" },
    { label: "Ironing board", value: "Ironing board" },
    { label: "Heated guest rooms", value: "Heated guest rooms" },
    { label: "Toilet", value: "Toilet" },
    { label: "Parlor", value: "Parlor" },
    { label: "Video game player", value: "Video game player" },
    { label: "Thalassotherapy", value: "Thalassotherapy" },
    { label: "Private dining for groups", value: "Private dining for groups" },
    { label: "Hearing impaired services", value: "Hearing impaired services" },
    { label: "Carryout breakfast", value: "Carryout breakfast" },
    { label: "Deluxe continental breakfast", value: "Deluxe continental breakfast" },
    { label: "Hot continental breakfast", value: "Hot continental breakfast" },
    { label: "Hot breakfast", value: "Hot breakfast" },
    { label: "Private pool", value: "Private pool" },
    { label: "Connecting rooms", value: "Connecting rooms" },
    { label: "Data port", value: "Data port" },
    { label: "Exterior corridors", value: "Exterior corridors" },
    { label: "Gulf view", value: "Gulf view" },
    { label: "Accessible rooms", value: "Accessible rooms" },
    { label: "High speed internet access", value: "High speed internet access" },
    { label: "Interior corridors", value: "Interior corridors" },
    { label: "High speed wireless", value: "High speed wireless" },
    { label: "Kitchenette", value: "Kitchenette" },
    { label: "Private bath or shower", value: "Private bath or shower" },
    { label: "Fire safety compliant", value: "Fire safety compliant" },
    { label: "Welcome drink", value: "Welcome drink" },
    { label: "Boarding pass print-out available", value: "Boarding pass print-out available" },
    { label: "Printing services available", value: "Printing services available" },
    { label: "All public areas non-smoking", value: "All public areas non-smoking" },
    { label: "Meeting rooms", value: "Meeting rooms" },
    { label: "Movies in room", value: "Movies in room" },
    { label: "Secretarial service", value: "Secretarial service" },
    { label: "Snow skiing", value: "Snow skiing" },
    { label: "Water skiing", value: "Water skiing" },
    { label: "Fax service", value: "Fax service" },
    { label: "Great room", value: "Great room" },
    { label: "Lobby", value: "Lobby" },
    { label: "Multiple phone lines billed separately", value: "Multiple phone lines billed separately" },
    { label: "Umbrellas", value: "Umbrellas" },
    { label: "Gas station", value: "Gas station" },
    { label: "Grocery store", value: "Grocery store" },
    { label: "24-hour coffee shop", value: "24-hour coffee shop" },
    { label: "Airport shuttle service", value: "Airport shuttle service" },
    { label: "Luggage service", value: "Luggage service" },
    { label: "Piano Bar", value: "Piano Bar" },
    { label: "VIP security", value: "VIP security" },
    { label: "Complimentary wireless internet", value: "Complimentary wireless internet" },
    { label: "Concierge breakfast", value: "Concierge breakfast" },
    { label: "Same gender floor", value: "Same gender floor" },
    { label: "Children programs", value: "Children programs" },
    { label: "Building meets local, state and country building codes", value: "Building meets local, state and country building codes" },
    { label: "Internet browser On TV", value: "Internet browser On TV" },
    { label: "Newspaper", value: "Newspaper" },
    { label: "Parking - controlled access gates to enter parking area", value: "Parking - controlled access gates to enter parking area" },
    { label: "Hotel safe deposit box (not room safe box)", value: "Hotel safe deposit box (not room safe box)" },
    { label: "Storage space available – fee", value: "Storage space available – fee" },
    { label: "Type of entrances to guest rooms", value: "Type of entrances to guest rooms" },
    { label: "Beverage/cocktail", value: "Beverage/cocktail" },
    { label: "Cell phone rental", value: "Cell phone rental" },
    { label: "Coffee/tea", value: "Coffee/tea" },
    { label: "Early check in guarantee", value: "Early check in guarantee" },
    { label: "Food and beverage discount", value: "Food and beverage discount" },
    { label: "Late check out guarantee", value: "Late check out guarantee" },
    { label: "Room upgrade confirmed", value: "Room upgrade confirmed" },
    { label: "Room upgrade on availability", value: "Room upgrade on availability" },
    { label: "Shuttle to local businesses", value: "Shuttle to local businesses" },
    { label: "Shuttle to local attractions", value: "Shuttle to local attractions" },
    { label: "Social hour", value: "Social hour" },
    { label: "Video billing", value: "Video billing" },
    { label: "Welcome gift", value: "Welcome gift" },
    { label: "Hypoallergenic rooms", value: "Hypoallergenic rooms" },
    { label: "Room air filtration", value: "Room air filtration" },
    { label: "Smoke-free property", value: "Smoke-free property" },
    { label: "Water purification system in use", value: "Water purification system in use" },
    { label: "Poolside service", value: "Poolside service" },
    { label: "Clothing store", value: "Clothing store" },
    { label: "Electric car charging stations", value: "Electric car charging stations" },
    { label: "Office rental", value: "Office rental" },
    { label: "Piano", value: "Piano" },
    { label: "Incoming fax", value: "Incoming fax" },
    { label: "Outgoing fax", value: "Outgoing fax" },
    { label: "Semi-private space", value: "Semi-private space" },
    { label: "Loading dock", value: "Loading dock" },
    { label: "Baby kit", value: "Baby kit" },
    { label: "Children's breakfast", value: "Children's breakfast" },
    { label: "Cloakroom service", value: "Cloakroom service" },
    { label: "Coffee lounge", value: "Coffee lounge" },
    { label: "Events ticket service", value: "Events ticket service" },
    { label: "Late check-in", value: "Late check-in" },
    { label: "Limited parking", value: "Limited parking" },
    { label: "Outdoor summer bar/café", value: "Outdoor summer bar/café" },
    { label: "No parking available", value: "No parking available" },
    { label: "Beer garden", value: "Beer garden" },
    { label: "Garden lounge bar", value: "Garden lounge bar" },
    { label: "Summer terrace", value: "Summer terrace" },
    { label: "Winter terrace", value: "Winter terrace" },
    { label: "Roof terrace", value: "Roof terrace" },
    { label: "Beach bar", value: "Beach bar" },
    { label: "Helicopter service", value: "Helicopter service" },
    { label: "Ferry", value: "Ferry" },
    { label: "Tapas bar", value: "Tapas bar" },
    { label: "Café bar", value: "Café bar" },
    { label: "Snack bar", value: "Snack bar" }];

    static OTAPartners = [{ value: "BOKSLT", label: "Book by slot" },
    { value: "COXKNG", label: "Cox &amp; Kings" },
    { value: "ESMYTP", label: "Ease My Trip" },
    { value: "EZEGO1", label: "EzeeGo1" },
    { value: "FMYSTY", label: "FindMyStay" },
    { value: "ISTYNW", label: "IStayNow" },
    { value: "LITINT", label: "Little Internet" },
    { value: "NEARBY", label: "Near Buy" },
    { value: "OTLEAP", label: "Invader Tech" },
    { value: "PAYTM", label: "PayTM" },
    { value: "QUINCY", label: "Quinchy" },
    { value: "STYBZR", label: "Stay Bazar" },
    { value: "TARMND", label: "Tamarind Global" },
    { value: "TROSOL", label: "TrooSol" },
    { value: "RAILYT", label: "Rail Yatri" },
    { value: "ITDCBE", label: "ITDC" }];

    static bookingEngineCodes = [
        { value: null, label: "None" },
        { value: "EXT-BE", label: "Individual" },
        { value: "CLARKS", label: "Clarks Inn Group" },
        { value: "GODWIN", label: "Godwin Group of Hotels" },
        { value: "SAROVAR", label: "Sarovar Group" },
        { value: "Vesta", label: "Vesta Hotels & Resorts" },
    ]

    static paymentGatewayCodes = [
        { value: "airpay", label: "Airpay" },
        { value: "payu", label: "PayU" },
        { value: "paytm", label: "Paytm" }
    ]

    static roomAmenities = [
        { label: "Adjoining rooms", value: "Adjoining rooms" },
        { label: "Air conditioning", value: "Air conditioning" },
        { label: "Alarm clock", value: "Alarm clock" },
        { label: "All news channel", value: "All news channel" },
        { label: "AM/FM radio", value: "AM/FM radio" },
        { label: "Baby listening device", value: "Baby listening device" },
        { label: "Balcony/Lanai/Terrace", value: "Balcony/Lanai/Terrace" },
        { label: "Barbeque grills", value: "Barbeque grills" },
        { label: "Bath tub with spray jets", value: "Bath tub with spray jets" },
        { label: "Bathrobe", value: "Bathrobe" },
        { label: "Bathroom amenities", value: "Bathroom amenities" },
        { label: "Bathroom telephone", value: "Bathroom telephone" },
        { label: "Bathtub", value: "Bathtub" },
        { label: "Bathtub only", value: "Bathtub only" },
        { label: "Bathtub/shower combination", value: "Bathtub/shower combination" },
        { label: "Bidet", value: "Bidet" },
        { label: "Bottled water", value: "Bottled water" },
        { label: "Cable television", value: "Cable television" },
        { label: "Coffee/Tea maker", value: "Coffee/Tea maker" },
        { label: "Color television", value: "Color television" },
        { label: "Computer", value: "Computer" },
        { label: "Connecting rooms", value: "Connecting rooms" },
        { label: "Converters/ Voltage adaptors", value: "Converters/ Voltage adaptors" },
        { label: "Copier", value: "Copier" },
        { label: "Cordless phone", value: "Cordless phone" },
        { label: "Cribs", value: "Cribs" },
        { label: "Data port", value: "Data port" },
        { label: "Desk", value: "Desk" },
        { label: "Desk with lamp", value: "Desk with lamp" },
        { label: "Dining guide", value: "Dining guide" },
        { label: "Direct dial phone number", value: "Direct dial phone number" },
        { label: "Dishwasher", value: "Dishwasher" },
        { label: "Double beds", value: "Double beds" },
        { label: "Dual voltage outlet", value: "Dual voltage outlet" },
        { label: "Electrical current voltage", value: "Electrical current voltage" },
        { label: "Ergonomic chair", value: "Ergonomic chair" },
        { label: "Extended phone cord", value: "Extended phone cord" },
        { label: "Fax machine", value: "Fax machine" },
        { label: "Fire alarm", value: "Fire alarm" },
        { label: "Fire alarm with light", value: "Fire alarm with light" },
        { label: "Fireplace", value: "Fireplace" },
        { label: "Free toll free calls", value: "Free toll free calls" },
        { label: "Free calls", value: "Free calls" },
        { label: "Free credit card access calls", value: "Free credit card access calls" },
        { label: "Free local calls", value: "Free local calls" },
        { label: "Free movies/video", value: "Free movies/video" },
        { label: "Full kitchen", value: "Full kitchen" },
        { label: "Grab bars in bathroom", value: "Grab bars in bathroom" },
        { label: "Grecian tub", value: "Grecian tub" },
        { label: "Hairdryer", value: "Hairdryer" },
        { label: "High speed internet connection", value: "High speed internet connection" },
        { label: "Interactive web TV", value: "Interactive web TV" },
        { label: "International direct dialing", value: "International direct dialing" },
        { label: "Internet access", value: "Internet access" },
        { label: "Iron", value: "Iron" },
        { label: "Ironing board", value: "Ironing board" },
        { label: "Whirpool / Jacuzzi", value: "Whirpool / Jacuzzi" },
        { label: "King bed", value: "King bed" },
        { label: "Kitchen", value: "Kitchen" },
        { label: "Kitchen supplies", value: "Kitchen supplies" },
        { label: "Kitchenette", value: "Kitchenette" },
        { label: "Knock light", value: "Knock light" },
        { label: "Laptop", value: "Laptop" },
        { label: "Large desk", value: "Large desk" },
        { label: "Large work area", value: "Large work area" },
        { label: "Laundry basket/clothes hamper", value: "Laundry basket/clothes hamper" },
        { label: "Loft", value: "Loft" },
        { label: "Microwave", value: "Microwave" },
        { label: "Minibar", value: "Minibar" },
        { label: "Modem", value: "Modem" },
        { label: "Modem jack", value: "Modem jack" },
        { label: "Multi-line phone", value: "Multi-line phone" },
        { label: "Newspaper", value: "Newspaper" },
        { label: "Non-smoking", value: "Non-smoking" },
        { label: "Notepads", value: "Notepads" },
        { label: "Office supplies", value: "Office supplies" },
        { label: "Oven", value: "Oven" },
        { label: "Pay per view movies on TV", value: "Pay per view movies on TV" },
        { label: "Pens", value: "Pens" },
        { label: "Phone in bathroom", value: "Phone in bathroom" },
        { label: "Plates and bowls", value: "Plates and bowls" },
        { label: "Pots and pans", value: "Pots and pans" },
        { label: "Prayer mats", value: "Prayer mats" },
        { label: "Printer", value: "Printer" },
        { label: "Private bathroom", value: "Private bathroom" },
        { label: "Queen bed", value: "Queen bed" },
        { label: "Recliner", value: "Recliner" },
        { label: "Refrigerator", value: "Refrigerator" },
        { label: "Refrigerator with ice maker", value: "Refrigerator with ice maker" },
        { label: "Remote control television", value: "Remote control television" },
        { label: "Rollaway bed", value: "Rollaway bed" },
        { label: "Safe", value: "Safe" },
        { label: "Scanner", value: "Scanner" },
        { label: "Separate closet", value: "Separate closet" },
        { label: "Separate modem line available", value: "Separate modem line available" },
        { label: "Shoe polisher", value: "Shoe polisher" },
        { label: "Shower only", value: "Shower only" },
        { label: "Silverware/utensils", value: "Silverware/utensils" },
        { label: "Sitting area", value: "Sitting area" },
        { label: "Smoke detectors", value: "Smoke detectors" },
        { label: "Smoking", value: "Smoking" },
        { label: "Sofa bed", value: "Sofa bed" },
        { label: "Speaker phone", value: "Speaker phone" },
        { label: "Stereo", value: "Stereo" },
        { label: "Stove", value: "Stove" },
        { label: "Tape recorder", value: "Tape recorder" },
        { label: "Telephone", value: "Telephone" },
        { label: "Telephone for hearing impaired", value: "Telephone for hearing impaired" },
        { label: "Telephones with message light", value: "Telephones with message light" },
        { label: "Toaster oven", value: "Toaster oven" },
        { label: "Trouser/Pant press", value: "Trouser/Pant press" },
        { label: "Turn down service", value: "Turn down service" },
        { label: "Twin bed", value: "Twin bed" },
        { label: "Vaulted ceilings", value: "Vaulted ceilings" },
        { label: "VCR movies", value: "VCR movies" },
        { label: "VCR player", value: "VCR player" },
        { label: "Video games", value: "Video games" },
        { label: "Voice mail", value: "Voice mail" },
        { label: "Wake-up calls", value: "Wake-up calls" },
        { label: "Water closet", value: "Water closet" },
        { label: "Water purification system", value: "Water purification system" },
        { label: "Wet bar", value: "Wet bar" },
        { label: "Wireless internet connection", value: "Wireless internet connection" },
        { label: "Wireless keyboard", value: "Wireless keyboard" },
        { label: "Adaptor available for telephone PC use", value: "Adaptor available for telephone PC use" },
        { label: "Air conditioning individually controlled in room", value: "Air conditioning individually controlled in room" },
        { label: "Bathtub &whirlpool separate", value: "Bathtub &whirlpool separate" },
        { label: "Telephone with data ports", value: "Telephone with data ports" },
        { label: "CD  player", value: "CD  player" },
        { label: "Complimentary local calls time limit", value: "Complimentary local calls time limit" },
        { label: "Extra person charge for rollaway use", value: "Extra person charge for rollaway use" },
        { label: "Down/feather pillows", value: "Down/feather pillows" },
        { label: "Desk with electrical outlet", value: "Desk with electrical outlet" },
        { label: "ESPN available", value: "ESPN available" },
        { label: "Foam pillows", value: "Foam pillows" },
        { label: "HBO available", value: "HBO available" },
        { label: "High ceilings", value: "High ceilings" },
        { label: "Marble bathroom", value: "Marble bathroom" },
        { label: "List of movie channels available", value: "List of movie channels available" },
        { label: "Pets allowed", value: "Pets allowed" },
        { label: "Oversized bathtub", value: "Oversized bathtub" },
        { label: "Shower", value: "Shower" },
        { label: "Sink in-room", value: "Sink in-room" },
        { label: "Soundproofed room", value: "Soundproofed room" },
        { label: "Storage space", value: "Storage space" },
        { label: "Tables and chairs", value: "Tables and chairs" },
        { label: "Two-line phone", value: "Two-line phone" },
        { label: "Walk-in closet", value: "Walk-in closet" },
        { label: "Washer/dryer", value: "Washer/dryer" },
        { label: "Weight scale", value: "Weight scale" },
        { label: "Welcome gift", value: "Welcome gift" },
        { label: "Spare electrical outlet available at desk", value: "Spare electrical outlet available at desk" },
        { label: "Non-refundable charge for pets", value: "Non-refundable charge for pets" },
        { label: "Refundable deposit for pets", value: "Refundable deposit for pets" },
        { label: "Separate tub and shower", value: "Separate tub and shower" },
        { label: "Entrance type to guest room", value: "Entrance type to guest room" },
        { label: "Ceiling fan", value: "Ceiling fan" },
        { label: "CNN available", value: "CNN available" },
        { label: "Electrical adaptors available", value: "Electrical adaptors available" },
        { label: "Buffet breakfast", value: "Buffet breakfast" },
        { label: "Accessible room", value: "Accessible room" },
        { label: "Closets in room", value: "Closets in room" },
        { label: "DVD player", value: "DVD player" },
        { label: "Mini-refrigerator", value: "Mini-refrigerator" },
        { label: "Separate line billing for multi-line phone", value: "Separate line billing for multi-line phone" },
        { label: "Self-controlled heating/cooling system", value: "Self-controlled heating/cooling system" },
        { label: "Toaster", value: "Toaster" },
        { label: "Analog data port", value: "Analog data port" },
        { label: "Collect calls", value: "Collect calls" },
        { label: "International calls", value: "International calls" },
        { label: "Carrier access", value: "Carrier access" },
        { label: "Interstate calls", value: "Interstate calls" },
        { label: "Intrastate calls", value: "Intrastate calls" },
        { label: "Local calls", value: "Local calls" },
        { label: "Long distance calls", value: "Long distance calls" },
        { label: "Operator-assisted calls", value: "Operator-assisted calls" },
        { label: "Credit card access calls", value: "Credit card access calls" },
        { label: "Calling card calls", value: "Calling card calls" },
        { label: "Toll free calls", value: "Toll free calls" },
        { label: "Universal AC/DC adaptors", value: "Universal AC/DC adaptors" },
        { label: "Bathtub seat", value: "Bathtub seat" },
        { label: "Canopy/poster bed", value: "Canopy/poster bed" },
        { label: "Cups/glassware", value: "Cups/glassware" },
        { label: "Entertainment center", value: "Entertainment center" },
        { label: "Family/oversized room", value: "Family/oversized room" },
        { label: "Hypoallergenic bed", value: "Hypoallergenic bed" },
        { label: "Hypoallergenic pillows", value: "Hypoallergenic pillows" },
        { label: "Lamp", value: "Lamp" },
        { label: "Meal included - breakfast", value: "Meal included - breakfast" },
        { label: "Meal included - continental breakfast", value: "Meal included - continental breakfast" },
        { label: "Meal included - dinner", value: "Meal included - dinner" },
        { label: "Meal included - lunch", value: "Meal included - lunch" },
        { label: "Shared bathroom", value: "Shared bathroom" },
        { label: "Telephone TDD/Textphone", value: "Telephone TDD/Textphone" },
        { label: "Water bed", value: "Water bed" },
        { label: "Extra adult charge", value: "Extra adult charge" },
        { label: "Extra child charge", value: "Extra child charge" },
        { label: "Extra child charge for rollaway use", value: "Extra child charge for rollaway use" },
        { label: "Meal included:  full American breakfast", value: "Meal included:  full American breakfast" },
        { label: "Futon", value: "Futon" },
        { label: "Murphy bed", value: "Murphy bed" },
        { label: "Tatami mats", value: "Tatami mats" },
        { label: "Single bed", value: "Single bed" },
        { label: "Annex room", value: "Annex room" },
        { label: "Free newspaper", value: "Free newspaper" },
        { label: "Honeymoon suites", value: "Honeymoon suites" },
        { label: "Complimentary high speed internet in room", value: "Complimentary high speed internet in room" },
        { label: "Maid service", value: "Maid service" },
        { label: "PC hook-up in room", value: "PC hook-up in room" },
        { label: "Satellite television", value: "Satellite television" },
        { label: "VIP rooms", value: "VIP rooms" },
        { label: "Cell phone recharger", value: "Cell phone recharger" },
        { label: "DVR player", value: "DVR player" },
        { label: "iPod docking station", value: "iPod docking station" },
        { label: "Media center", value: "Media center" },
        { label: "Plug & play panel", value: "Plug & play panel" },
        { label: "Satellite radio", value: "Satellite radio" },
        { label: "Video on demand", value: "Video on demand" },
        { label: "Exterior corridors", value: "Exterior corridors" },
        { label: "Gulf view", value: "Gulf view" },
        { label: "Accessible room", value: "Accessible room" },
        { label: "Interior corridors", value: "Interior corridors" },
        { label: "Mountain view", value: "Mountain view" },
        { label: "Ocean view", value: "Ocean view" },
        { label: "High speed internet access fee", value: "High speed internet access fee" },
        { label: "High speed wireless", value: "High speed wireless" },
        { label: "Premium movie channels", value: "Premium movie channels" },
        { label: "Slippers", value: "Slippers" },
        { label: "First nighters' kit", value: "First nighters' kit" },
        { label: "Chair provided with desk", value: "Chair provided with desk" },
        { label: "Pillow top mattress", value: "Pillow top mattress" },
        { label: "Feather bed", value: "Feather bed" },
        { label: "Duvet", value: "Duvet" },
        { label: "Luxury linen type", value: "Luxury linen type" },
        { label: "International channels", value: "International channels" },
        { label: "Pantry", value: "Pantry" },
        { label: "Dish-cleaning supplies", value: "Dish-cleaning supplies" },
        { label: "Double vanity", value: "Double vanity" },
        { label: "Lighted makeup mirror", value: "Lighted makeup mirror" },
        { label: "Upgraded bathroom amenities", value: "Upgraded bathroom amenities" },
        { label: "VCR player available at front desk", value: "VCR player available at front desk" },
        { label: "Instant hot water", value: "Instant hot water" },
        { label: "Outdoor space", value: "Outdoor space" },
        { label: "Hinoki tub", value: "Hinoki tub" },
        { label: "Private pool", value: "Private pool" },
        { label: "High Definition (HD) Flat Panel Television  - 32 inches or greater", value: "High Definition (HD) Flat Panel Television  - 32 inches or greater" },
        { label: "Room windows open", value: "Room windows open" },
        { label: "Bedding type unknown or unspecified", value: "Bedding type unknown or unspecified" },
        { label: "Full bed", value: "Full bed" },
        { label: "Round bed", value: "Round bed" },
        { label: "TV", value: "TV" },
        { label: "Child rollaway", value: "Child rollaway" },
        { label: "DVD player available at front desk", value: "DVD player available at front desk" },
        { label: "Video game player", value: "Video game player" },
        { label: "Video game player available at front desk", value: "Video game player available at front desk" },
        { label: "Dining room seats", value: "Dining room seats" },
        { label: "Full size mirror", value: "Full size mirror" },
        { label: "Mobile/cellular phones", value: "Mobile/cellular phones" },
        { label: "Movies", value: "Movies" },
        { label: "Multiple closets", value: "Multiple closets" },
        { label: "Plates/glassware", value: "Plates/glassware" },
        { label: "Safe large enough to accommodate a laptop", value: "Safe large enough to accommodate a laptop" },
        { label: "Bed linen thread count", value: "Bed linen thread count" },
        { label: "Blackout curtain", value: "Blackout curtain" },
        { label: "Bluray player", value: "Bluray player" },
        { label: "Device with mp3", value: "Device with mp3" },
        { label: "No adult channels or adult channel lock", value: "No adult channels or adult channel lock" },
        { label: "Non-allergenic room", value: "Non-allergenic room" },
        { label: "Pillow type", value: "Pillow type" },
        { label: "Seating area with sofa/chair", value: "Seating area with sofa/chair" },
        { label: "Separate toilet area", value: "Separate toilet area" },
        { label: "Web enabled", value: "Web enabled" },
        { label: "Widescreen TV", value: "Widescreen TV" },
        { label: "Other data connection", value: "Other data connection" },
        { label: "Phoneline billed separately", value: "Phoneline billed separately" },
        { label: "Separate tub or shower", value: "Separate tub or shower" },
        { label: "Video games", value: "Video games" },
        { label: "Roof ventilator", value: "Roof ventilator" },
        { label: "Children's playpen", value: "Children's playpen" },
        { label: "Plunge pool", value: "Plunge pool" },
        { label: "Down comforter", value: "Down comforter" },
        { label: "Egyptian cotton sheets", value: "Egyptian cotton sheets" },
        { label: "Espresso maker", value: "Espresso maker" },
        { label: "Free toiletries", value: "Free toiletries" },
        { label: "Frette Italian Sheets", value: "Frette Italian Sheets" },
        { label: "Housekeeping", value: "Housekeeping" },
        { label: "Hydromassage Showerhead", value: "Hydromassage Showerhead" },
        { label: "In-room childcare", value: "In-room childcare" },
        { label: "In-room massage available", value: "In-room massage available" },
        { label: "Individually decorated", value: "Individually decorated" },
        { label: "Individually furnished", value: "Individually furnished" },
        { label: "iPad", value: "iPad" },
        { label: "LCD / Plasma TV", value: "LCD / Plasma TV" },
        { label: "LED TV", value: "LED TV" },
        { label: "Living room", value: "Living room" },
        { label: "Memory foam mattress", value: "Memory foam mattress" },
        { label: "Patio", value: "Patio" },
        { label: "Premium bedding", value: "Premium bedding" },
        { label: "Private spa tub", value: "Private spa tub" },
        { label: "Rainfall showerhead", value: "Rainfall showerhead" },
        { label: "Second bathroom", value: "Second bathroom" },
        { label: "Shared / Communal kitchen", value: "Shared / Communal kitchen" },
        { label: "Tablet computer", value: "Tablet computer" },
        { label: "Weekly housekeeping", value: "Weekly housekeeping" },
        { label: "Yard", value: "Yard" },
        { label: "Essentials", value: "Essentials" },
        { label: "Heater", value: "Heater" },
        { label: "Hangers", value: "Hangers" },
        { label: "Washer", value: "Washer" },
        { label: "Dryer", value: "Dryer" },
        { label: "Private entrance", value: "Private entrance" },
        { label: "Lock on bedroom door", value: "Lock on bedroom door" },
        { label: "Shampoo", value: "Shampoo" },
        { label: "Extra pillows and blankets", value: "Extra pillows and blankets" },
        { label: "Ethernet connection", value: "Ethernet connection" },
        { label: "Pocket wifi", value: "Pocket wifi" },
        { label: "Cooking basics. Pots and pans, oil, salt and pepper", value: "Cooking basics. Pots and pans, oil, salt and pepper" },
        { label: "Paid parking off premises", value: "Paid parking off premises" },
        { label: "Paid parking on premises", value: "Paid parking on premises" },
        { label: "EV charger", value: "EV charger" },
        { label: "Single level home", value: "Single level home" },
        { label: "Garden or backyard", value: "Garden or backyard" },
        { label: "Beach essentials", value: "Beach essentials" },
        { label: "Long term stays allowed", value: "Long term stays allowed" },
        { label: "Cleaning before checkout", value: "Cleaning before checkout" },
        { label: "Fire extinguisher", value: "Fire extinguisher" },
        { label: "Carbon monoxide detector", value: "Carbon monoxide detector" },
        { label: "First aid kit", value: "First aid kit" },
        { label: "Lake access", value: "Lake access" },
        { label: "Ski in/Ski out", value: "Ski in/Ski out" },
        { label: "Waterfront", value: "Waterfront" },
        { label: "Baby bath", value: "Baby bath" },
        { label: "Baby monitor", value: "Baby monitor" },
        { label: "Babysitter recommendations", value: "Babysitter recommendations" },
        { label: "Changing table", value: "Changing table" },
        { label: "Children’s books and toys", value: "Children’s books and toys" },
        { label: "Children’s dinnerware", value: "Children’s dinnerware" },
        { label: "Fireplace guards", value: "Fireplace guards" },
        { label: "Game console", value: "Game console" },
        { label: "High chair", value: "High chair" },
        { label: "Outlet covers", value: "Outlet covers" },
        { label: "Pack ’n Play/travel crib", value: "Pack ’n Play/travel crib" },
        { label: "Room-darkening shades", value: "Room-darkening shades" },
        { label: "Stair gates", value: "Stair gates" },
        { label: "Table corner guards", value: "Table corner guards" },
        { label: "Window guards", value: "Window guards" },
        { label: "Wide hallway clearance", value: "Wide hallway clearance" },
        { label: "Step-free access to home", value: "Step-free access to home" },
        { label: "Well-lit path to entrance", value: "Well-lit path to entrance" },
        { label: "Wide doorway to home", value: "Wide doorway to home" },
        { label: "Flat path to front door", value: "Flat path to front door" },
        { label: "Disabled parking spot", value: "Disabled parking spot" },
        { label: "Step-free access to bedroom", value: "Step-free access to bedroom" },
        { label: "Wide clearance to bed", value: "Wide clearance to bed" },
        { label: "Wide doorway to bedroom", value: "Wide doorway to bedroom" },
        { label: "Accessible-height bed", value: "Accessible-height bed" },
        { label: "Electric profiling bed", value: "Electric profiling bed" },
        { label: "Step-free access to bathroom", value: "Step-free access to bathroom" },
        { label: "Fixed grab bars for shower", value: "Fixed grab bars for shower" },
        { label: "Fixed grab bars for toilet", value: "Fixed grab bars for toilet" },
        { label: "Accessible-height toilet", value: "Accessible-height toilet" },
        { label: "No step between the shower and bathroom floor", value: "No step between the shower and bathroom floor" },
        { label: "Chair available for shower", value: "Chair available for shower" },
        { label: "Wide doorway to bathroom", value: "Wide doorway to bathroom" },
        { label: "Bathtub with shower chair", value: "Bathtub with shower chair" },
        { label: "Wide clearance to shower, toilet", value: "Wide clearance to shower, toilet" },
        { label: "Handheld shower head", value: "Handheld shower head" },
        { label: "Step-free access to common space", value: "Step-free access to common space" },
        { label: "Wide entryway to common space", value: "Wide entryway to common space" },
        { label: "Mobile hoist", value: "Mobile hoist" },
        { label: "Pool with pool hoist", value: "Pool with pool hoist" },
        { label: "Ceiling hoist", value: "Ceiling hoist" }];

    static RoomTypeCategories = [{ label: "Entire Home", value: 0 },
    { label: "Private Room", value: 1 },
    { label: "Shared Room", value: 2 }];

    static RoomCategories = [{ label: "Apartment", value: 0 }, { label: "Bunglow", value: 1 }, { label: "Cabin", value: 2 },
    { label: "Cottage", value: 4 }, { label: "Dormitory", value: 5 }, { label: "Double room", value: 6 }, { label: "Family", value: 7 },
    { label: "Houseboat", value: 8 }, { label: "Mobile Home", value: 9 }, { label: "Quadruple", value: 10 }, { label: "Single", value: 11 },
    { label: "Studio", value: 12 }, { label: "Suite", value: 13 }, { label: "Tent", value: 14 }, { label: "Triple", value: 15 },
    { label: "Twin", value: 16 }, { label: "Villa", value: 17 }];

    static HotelType = [{ label: "Apartment Hotel", supportedroomtypes: [1, 2], value: 0 },
    { label: "Apartment", supportedroomtypes: [0, 1, 2], value: 1 },
    { label: "Barn", supportedroomtypes: [0, 1, 2], value: 2 },
    { label: "BNB", supportedroomtypes: [1, 2], value: 3 },
    { label: "Boat", supportedroomtypes: [0, 1, 2], value: 4 },
    { label: "Boutique Hotel", supportedroomtypes: [1, 2], value: 5 },
    { label: "Bungalow", supportedroomtypes: [0, 1, 2], value: 6 },
    { label: "Cabin", supportedroomtypes: [0, 1, 2], value: 7 },
    { label: "Campsite", supportedroomtypes: [0], value: 8 },
    { label: "Casa Particular", supportedroomtypes: [0, 1, 2], value: 9 },
    { label: "Castle", supportedroomtypes: [0, 1, 2], value: 10 },
    { label: "Cave", supportedroomtypes: [0, 1, 2], value: 11 },
    { label: "Chalet", supportedroomtypes: [0, 1, 2], value: 12 },
    { label: "Condominium", supportedroomtypes: [0, 1, 2], value: 13 },
    { label: "Cottage", supportedroomtypes: [0, 1, 2], value: 14 },
    { label: "Cycladic_house", supportedroomtypes: [0, 1, 2], value: 15 },
    { label: "Dammuso", supportedroomtypes: [0, 1, 2], value: 16 },
    { label: "Dome house", supportedroomtypes: [0, 1, 2], value: 17 },
    { label: "Earth house", supportedroomtypes: [0, 1, 2], value: 18 },
    { label: "Farm Stay", supportedroomtypes: [0, 1, 2], value: 19 },
    { label: "Guest Suite", supportedroomtypes: [0, 1, 2], value: 20 },
    { label: "Guesthouse", supportedroomtypes: [0, 1, 2], value: 21 },
    { label: "Heritage Hotel", supportedroomtypes: [1, 2], value: 22 },
    { label: "Hostel", supportedroomtypes: [1, 2], value: 23 },
    { label: "Hotel", supportedroomtypes: [1, 2], value: 24 },
    { label: "House", supportedroomtypes: [0, 1, 2], value: 25 },
    { label: "Houseboat", supportedroomtypes: [0, 1, 2], value: 26 },
    { label: "Hut", supportedroomtypes: [0, 1, 2], value: 27 },
    { label: "Igloo", supportedroomtypes: [0, 1, 2], value: 28 },
    { label: "Island", supportedroomtypes: [0, 1, 2], value: 29 },
    { label: "Lighthouse", supportedroomtypes: [0, 1, 2], value: 30 },
    { label: "Lodge", supportedroomtypes: [1, 2], value: 31 },
    { label: "Lloft", supportedroomtypes: [0, 1, 2], value: 32 },
    { label: "Minsu", supportedroomtypes: [0, 1, 2], value: 33 },
    { label: "Pension", supportedroomtypes: [0, 1, 2], value: 34 },
    { label: "Plane", supportedroomtypes: [0, 1, 2], value: 35 },
    { label: "Resort", supportedroomtypes: [1, 2], value: 36 },
    { label: "Rv", supportedroomtypes: [0, 1, 2], value: 37 },
    { label: "Ryokan", supportedroomtypes: [1, 2], value: 38 },
    { label: "Aerviced Apartment", supportedroomtypes: [0, 1, 2], value: 39 },
    { label: "Shepherds Hut", supportedroomtypes: [0, 1, 2], value: 40 },
    { label: "Tent", supportedroomtypes: [0, 1, 2], value: 41 },
    { label: "Tiny House", supportedroomtypes: [0, 1, 2], value: 42 },
    { label: "Tipi", supportedroomtypes: [0, 1, 2], value: 43 },
    { label: "Townhouse", supportedroomtypes: [0, 1, 2], value: 44 },
    { label: "Train", supportedroomtypes: [0, 1, 2], value: 45 },
    { label: "Treehouse", supportedroomtypes: [0, 1, 2], value: 46 },
    { label: "Trullo", supportedroomtypes: [0, 1, 2], value: 47 },
    { label: "Villa", supportedroomtypes: [0, 1, 2], value: 48 },
    { label: "Windmill", supportedroomtypes: [0, 1, 2], value: 49 },
    { label: "Yurt", supportedroomtypes: [0, 1, 2], value: 50 }];

    static typeUsage = [{ label: "Common", value: 2, type_id: 2 },
    { label: "Hotel Service", value: 0, type_id: 0 },
    { label: "Provider", value: 1, type_id: 1 },
    { label: "REST Service", value: 3, type_id: 3 }];

    static methodUsage =
        [
            { name: "All", value: null, type_id: 1 },
            { name: "Confirm Bookings", value: "ConfirmBookings", type_id: 1 },
            { name: "Sync Bookings", value: "GetBookings", type_id: 1 },
            { name: "Sync Channel Hotel Info", value: "GetChannelHotelInfo", type_id: 1 },
            { name: "Sync Rate Calendar (GET)", value: "GetRateCalendar", type_id: 1 },
            { name: "Sync Rate Calendar (PUT)", value: "SyncRateCalendar", type_id: 1 },
            { name: "Verify", value: "Verify", type_id: 1 },

            { name: "All", value: null, type_id: 3 },

            { name: "All", value: null, type_id: 0 },
            { name: "Audit Booking", value: "http://maximojo.com/Hotels/IHotelManagement/AuditBookings", type_id: 0 },
            { name: "Backup", value: "http://maximojo.com/Application/IApplication/Backup", type_id: 0 },
            { name: "Bulk Update Rate Calendar", value: "http://maximojo.com/Hotels/IHotelManagement/BulkUpdateRateCalendar", type_id: 0 },
            { name: "Close", value: "http://maximojo.com/Hotels/IHotelManagement/Close", type_id: 0 },
            { name: "Confirm Bookings", value: "http://maximojo.com/Hotels/IHotelManagement/ConfirmBookings", type_id: 0 },
            { name: "Copy Channel Rate Calendar", value: "http://maximojo.com/Hotels/IHotelManagement/CopyChannelRateCalendar", type_id: 0 },
            { name: "Copy Rate Calendar", value: "http://maximojo.com/Hotels/IHotelManagement/CopyRateCalendar", type_id: 0 },
            { name: "Delete Application Provider", value: "http://maximojo.com/Application/IApplication/DeleteApplicationProvider", type_id: 0 },
            { name: "Delete Bookings", value: "http://maximojo.com/Hotels/IHotelManagement/DeleteBookings", type_id: 0 },
            { name: "Delete Domain", value: "http://maximojo.com/Application/IApplication/DeleteDomain", type_id: 0 },
            { name: "Delete Domain Hotel", value: "http://maximojo.com/Hotels/IHotelManagement/DeleteDomainHotel", type_id: 0 },
            { name: "Delete Domain Channel", value: "http://maximojo.com/Application/IApplication/DeleteDomainChannel", type_id: 0 },
            { name: "Delete Exceptions", value: "http://maximojo.com/Application/IApplication/DeleteExceptions", type_id: 0 },
            { name: "Delete Hotel Channel", value: "http://maximojo.com/Hotels/IHotelManagement/DeleteHotelChannel", type_id: 0 },
            { name: "Delete Promotion", value: "http://maximojo.com/Hotels/IHotelManagement/DeletePromotion", type_id: 0 },
            { name: "Delete Rate Calendar Sync Log", value: "http://maximojo.com/Hotels/IHotelManagement/DeleteRateCalendarSyncLog", type_id: 0 },
            { name: "Delete Rate Plan", value: "http://maximojo.com/Hotels/IHotelManagement/DeleteRatePlan", type_id: 0 },
            { name: "Delete Rate Season", value: "http://maximojo.com/Hotels/IHotelManagement/DeleteRateSession", type_id: 0 },
            { name: "Delete Room Rate Plan", value: "http://maximojo.com/Hotels/IHotelManagement/DeleteRoomRatePlan", type_id: 0 },
            { name: "Delete Room Type", value: "http://maximojo.com/Hotels/IHotelManagement/DeleteRoomType", type_id: 0 },
            { name: "Delete Tax Policy", value: "http://maximojo.com/Hotels/IHotelManagement/DeleteTaxPolicy", type_id: 0 },
            { name: "Delete Usage", value: "http://maximojo.com/Application/IApplication/DeleteUsage", type_id: 0 },
            { name: "Export Bookings", value: "http://maximojo.com/Application/IApplication/ExportBookings", type_id: 0 },
            { name: "Export Catalog", value: "http://maximojo.com/Application/IApplication/ExportCatalog", type_id: 0 },
            { name: "Export Competitor Rates", value: "http://maximojo.com/Application/IApplication/ExportCompetitorRates", type_id: 0 },
            { name: "Export Property", value: "http://maximojo.com/Application/IApplication/ExportProperty", type_id: 0 },
            { name: "Export Rate Calendar", value: "http://maximojo.com/Application/IApplication/ExportRateCalendar", type_id: 0 },
            { name: "Export Rate Calendar Sync Log", value: "http://maximojo.com/Application/IApplication/ExportRateCalendarSyncLog", type_id: 0 },
            { name: "Export Rate Calendar Sync Report", value: "http://maximojo.com/Application/IApplication/ExportRateCalendarSyncReport", type_id: 0 },
            { name: "Find Bookings", value: "http://maximojo.com/Hotels/IHotelManagement/FindBookings", type_id: 0 },
            { name: "Find Bookings2", value: "http://maximojo.com/Hotels/IHotelManagement/FindBookings2", type_id: 0 },
            { name: "Find Channel Hotel Info", value: "http://maximojo.com/Application/IApplication/FindChannelHotelInfo", type_id: 0 },
            { name: "Find Channel Hotel Info by hotel", value: "http://maximojo.com/Application/IApplication/FindChannelHotelInfobyhotel", type_id: 0 },
            { name: "Find Competitor Rates", value: "http://maximojo.com/Hotels/IHotelManagement/FindCompetitorRates", type_id: 0 },
            { name: "Find Domain Channels", value: "http://maximojo.com/Application/IApplication/FindDomainChannels", type_id: 0 },
            { name: "Find Domain Hotel", value: "http://maximojo.com/Hotels/IHotelManagement/FindDomainHotel", type_id: 0 },
            { name: "Find Domain Hotel Info", value: "http://maximojo.com/Application/IApplication/FindDomainHotelInfo", type_id: 0 },
            { name: "Find Exceptions", value: "http://maximojo.com/Application/IApplication/FindExceptions", type_id: 0 },
            { name: "Find Exceptions2", value: "http://maximojo.com/Application/IApplication/FindExceptions2", type_id: 0 },
            { name: "Find Hotel Channel Hotel Info", value: "http://maximojo.com/Application/IApplication/FindHotelChannelHotelInfo", type_id: 0 },
            { name: "Find Hotel Channels", value: "http://maximojo.com/Hotels/IHotelManagement/FindHotelChannels", type_id: 0 },
            { name: "Find Promotions", value: "http://maximojo.com/Hotels/IHotelManagement/FindPromotions", type_id: 0 },
            { name: "Find Rate Calendar", value: "http://maximojo.com/Hotels/IHotelManagement/FindRateCalendar", type_id: 0 },
            { name: "Find Rate Calendar Sync Log", value: "http://maximojo.com/Hotels/IHotelManagement/FindRateCalendarSyncLog", type_id: 0 },
            { name: "Find Rate Plan", value: "http://maximojo.com/Hotels/IHotelManagement/FindRatePlan", type_id: 0 },
            { name: "Find Rate Session", value: "http://maximojo.com/Hotels/IHotelManagement/FindRateSeassion", type_id: 0 },
            { name: "Find Room Rate Plans", value: "http://maximojo.com/Hotels/IHotelManagement/FindRoomRatePlans", type_id: 0 },
            { name: "Find Room Types", value: "http://maximojo.com/Hotels/IHotelManagement/FindRoomTypes", type_id: 0 },
            { name: "Find Tax Policy", value: "http://maximojo.com/Hotels/IHotelManagement/FindTaxPolicy", type_id: 0 },
            { name: "Find Usage", value: "http://maximojo.com/Application/IApplication/FindUsage", type_id: 0 },
            { name: "Find Usage2", value: "http://maximojo.com/Application/IApplication/FindUsage2", type_id: 0 },
            { name: "Fix Inventory", value: "http://maximojo.com/Hotels/IHotelManagement/FixInventory", type_id: 0 },
            { name: "Get All Channel Hotel Mappings", value: "http://maximojo.com/Hotels/IHotelManagement/GetAllChannelHotelMappings", type_id: 0 },
            { name: "Get Application  Database Status", value: "http://maximojo.com/Application/IApplication/GetApplicationDatabaseStatus", type_id: 0 },
            { name: "Get Application Provider", value: "http://maximojo.com/Application/IApplication/GetApplicationProvider", type_id: 0 },
            { name: "Get Currency Exchange Rate", value: "http://maximojo.com/Application/IApplication/GetCurrencyExchangeRate", type_id: 0 },
            { name: "Get Domain Channels", value: "http://maximojo.com/Application/IApplication/GetDomainChannels", type_id: 0 },
            { name: "Get Domain Hotel", value: "http://maximojo.com/Application/IApplication/GetDomainHotel", type_id: 0 },
            { name: "Get Domain Hotel Channels", value: "http://maximojo.com/Application/IApplication/GetDomainHotelChannels", type_id: 0 },
            { name: "Get Domain Hotel Info", value: "http://maximojo.com/Application/IApplication/GetDomainHotelInfo", type_id: 0 },
            { name: "Get Domain Hotels", value: "http://maximojo.com/Application/IApplication/GetDomainHotels", type_id: 0 },
            { name: "Get Hotel Info", value: "http://maximojo.com/Application/IApplication/GetHotelInfo", type_id: 0 },
            { name: "Get Promotion History", value: "http://maximojo.com/Hotels/IHotelManagement/GetPromotionHistory", type_id: 0 },
            { name: "Get System Channels", value: "http://maximojo.com/Application/IApplication/GetSystemChannels", type_id: 0 },
            { name: "Get Usage Details", value: "http://maximojo.com/Application/IApplication/GetUsageDetails", type_id: 0 },
            { name: "Get User Domain Channels", value: "http://maximojo.com/Application/IApplication/Get User Domain Channels", type_id: 0 },
            { name: "Get User Domain Hotel Channels", value: "http://maximojo.com/Application/IApplication/GetUserDomainHotelChannels", type_id: 0 },
            { name: "Get User Domain Hotels", value: "http://maximojo.com/Application/IApplication/GetUserDomainHotels", type_id: 0 },
            { name: "Get User Domain Hotel Summary", value: "http://maximojo.com/Application/IApplication/GetUserDomainHotelSummary", type_id: 0 },
            { name: "Import Catalog", value: "http://maximojo.com/Application/IApplication/ImportCatalog", type_id: 0 },
            { name: "Import Property", value: "http://maximojo.com/Application/IApplication/ImportProperty", type_id: 0 },
            { name: "Import Rate Calendar", value: "http://maximojo.com/Application/IApplication/ImportRateCalendar", type_id: 0 },
            { name: "Initialize", value: "http://maximojo.com/Application/IApplication/Initialize", type_id: 0 },
            { name: "Load Rate Calendar", value: "http://maximojo.com/Hotels/IHotelManagement/LoadRateCalendar", type_id: 0 },
            { name: "Open", value: "http://maximojo.com/Application/IApplication/Open", type_id: 0 },
            { name: "Push Booking", value: "http://maximojo.com/Hotels/IHotelManagement/PushBooking", type_id: 0 },
            { name: "Restore", value: "http://maximojo.com/Application/IApplication/Restore", type_id: 0 },
            { name: "Save Application Provider", value: "http://maximojo.com/Application/IApplication/SaveApplicationProvider", type_id: 0 },
            { name: "Save Bookings", value: "http://maximojo.com/Hotels/IHotelManagement/SaveBookings", type_id: 0 },
            { name: "Save Bulk Rate Calendar", value: "http://maximojo.com/Hotels/IHotelManagement/SaveBulkRateCalendar", type_id: 0 },
            { name: "Save Channel Rate Calendar", value: "http://maximojo.com/Hotels/IHotelManagement/SaveChannelRateCalendar", type_id: 0 },
            { name: "Save Domain Channels", value: "http://maximojo.com/Application/IApplication/SaveDomainChannels", type_id: 0 },
            { name: "Save Domain Hotel", value: "http://maximojo.com/Hotels/IHotelManagement/SaveDomainHotel", type_id: 0 },
            { name: "Save Hotel Channel", value: "http://maximojo.com/Hotels/IHotelManagement/SaveHotelChannel", type_id: 0 },
            { name: "Save Master Rate Calendar", value: "http://maximojo.com/Hotels/IHotelManagement/SaveMasterRateCalendar", type_id: 0 },
            { name: "Save P M S Rate Calendar", value: "http://maximojo.com/Hotels/IHotelManagement/SavePMSRateCalendar", type_id: 0 },
            { name: "Save Promotions", value: "http://maximojo.com/Hotels/IHotelManagement/SavePromotions", type_id: 0 },
            { name: "Save Rate Plans", value: "http://maximojo.com/Hotels/IHotelManagement/SaveRatePlans", type_id: 0 },
            { name: "Save Rate Session", value: "http://maximojo.com/Hotels/IHotelManagement/SaveRateSession", type_id: 0 },
            { name: "Save Room Rate Plans", value: "http://maximojo.com/Hotels/IHotelManagement/SaveRoomRatePlans", type_id: 0 },
            { name: "Save Room Types", value: "http://maximojo.com/Hotels/IHotelManagement/SaveRoomTypes", type_id: 0 },
            { name: "Save Tax Policy", value: "http://maximojo.com/Hotels/IHotelManagement/SaveTaxPolicy", type_id: 0 },
            { name: "Send Booking Voucher", value: "http://maximojo.com/Hotels/IHotelManagement/SendBookingVoucher", type_id: 0 },
            { name: "Send Mail", value: "http://maximojo.com/Hotels/IHotelManagement/SendMail", type_id: 0 },
            { name: "Setup Hotel", value: "http://maximojo.com/Hotels/IHotelManagement/SetupHotel", type_id: 0 },
            { name: "Switch Domain Context", value: "http://maximojo.com/Hotels/IHotelManagement/SwitchDomainContext", type_id: 0 },

            { name: "All", value: null, type_id: 2 },
            { name: "Appprove Domain", value: "Indigo.Common.Directory/IDirectory/AppproveDomain", type_id: 2 },
            { name: "Change Password", value: "Indigo.Common.Directory/IDirectory/ChangePassword", type_id: 2 },
            { name: "Delete Domain", value: "Indigo.Common.Directory/IDirectory/DeleteDomain", type_id: 2 },
            { name: "Delete Domain User", value: "Indigo.Common.Directory/IDirectory/DeleteDomainUser", type_id: 2 },
            { name: "Delete Exceptions", value: "Indigo.Common.Directory/IDirectory/DeleteExceptions", type_id: 2 },
            { name: "Delete Role", value: "Indigo.Common.Directory/IDirectory/DeleteRole", type_id: 2 },
            { name: "Delete Session", value: "Indigo.Common.Directory/IDirectory/DeleteSession", type_id: 2 },
            { name: "Delete Session Log", value: "Indigo.Common.Directory/IDirectory/DeleteSessionLog", type_id: 2 },
            { name: "Delete Usage", value: "Indigo.Common.Directory/IDirectory/DeleteUsage", type_id: 2 },
            { name: "Delete User", value: "Indigo.Common.Directory/IDirectory/DeleteUser", type_id: 2 },
            { name: "Export", value: "Indigo.Common.Directory/IDirectory/Export", type_id: 2 },
            { name: "Export Domain", value: "Indigo.Common.Directory/IDirectory/ExportDomain", type_id: 2 },
            { name: "Find Domain Catalog", value: "Indigo.Common.Directory/IDirectory/FindDomainCatalog", type_id: 2 },
            { name: "Find Domain Users", value: "Indigo.Common.Directory/IDirectory/FindDomainUsers", type_id: 2 },
            { name: "Find Domains", value: "Indigo.Common.Directory/IDirectory/FindDomains", type_id: 2 },
            { name: "Find Exceptions", value: "Indigo.Common.Directory/IDirectory/FindExceptions", type_id: 2 },
            { name: "Find Exceptions2", value: "Indigo.Common.Directory/IDirectory/FindExceptions2", type_id: 2 },
            { name: "Find Sessions", value: "Indigo.Common.Directory/IDirectory/FindSessions", type_id: 2 },
            { name: "Find Usage", value: "Indigo.Common.Directory/IDirectory/FindUsage", type_id: 2 },
            { name: "Find Usage2", value: "Indigo.Common.Directory/IDirectory/FindUsage2", type_id: 2 },
            { name: "Find Users", value: "Indigo.Common.Directory/IDirectory/FindUsers", type_id: 2 },
            { name: "Get Claims", value: "Indigo.Common.Directory/IDirectory/GetClaims", type_id: 2 },
            { name: "Get Current Domain", value: "Indigo.Common.Directory/IDirectory/GetCurrentDomain", type_id: 2 },
            { name: "Get Current User", value: "Indigo.Common.Directory/IDirectory/GetCurrentUser", type_id: 2 },
            { name: "Get Domain Users", value: "Indigo.Common.Directory/IDirectory/GetDomainUsers", type_id: 2 },
            { name: "Get Roles", value: "Indigo.Common.Directory/IDirectory/GetRoles", type_id: 2 },
            { name: "Get Service Authoriation Policy", value: "Indigo.Common.Directory/IDirectory/GetServiceAuthoriationPolicy", type_id: 2 },
            { name: "Get Session", value: "Indigo.Common.Directory/IDirectory/GetSession", type_id: 2 },
            { name: "Get System Claims", value: "Indigo.Common.Directory/IDirectory/GetSystemClaims", type_id: 2 },
            { name: "Get Usage Details", value: "Indigo.Common.Directory/IDirectory/GetUsageDetails", type_id: 2 },
            { name: "Get User Domain Profiles", value: "Indigo.Common.Directory/IDirectory/GetUserDomainProfiles", type_id: 2 },
            { name: "Get User Domains", value: "Indigo.Common.Directory/IDirectory/GetUserDomains", type_id: 2 },
            { name: "Import", value: "Indigo.Common.Directory/IDirectory/Import", type_id: 2 },
            { name: "Import Domain", value: "Indigo.Common.Directory/IDirectory/Import Domain", type_id: 2 },
            { name: "Is Valid Domain Id", value: "Indigo.Common.Directory/IDirectory/IsValidDomainId", type_id: 2 },
            { name: "Login", value: "Indigo.Common.Directory/IDirectory/Login", type_id: 2 },
            { name: "Logout", value: "Indigo.Common.Directory/IDirectory/Logout", type_id: 2 },
            { name: "Register Domain", value: "Indigo.Common.Directory/IDirectory/RegisterDomain", type_id: 2 },
            { name: "Reset Password Confirm", value: "Indigo.Common.Directory/IDirectory/ResetPasswordConfirm", type_id: 2 },
            { name: "Reset Password Request", value: "Indigo.Common.Directory/IDirectory/ResetPasswordRequest", type_id: 2 },
            { name: "Save Domain", value: "Indigo.Common.Directory/IDirectory/SaveDomain", type_id: 2 },
            { name: "Save Domain User", value: "Indigo.Common.Directory/IDirectory/SaveDomainUser", type_id: 2 },
            { name: "Save Role", value: "Indigo.Common.Directory/IDirectory/SaveRole", type_id: 2 },
            { name: "Save User", value: "Indigo.Common.Directory/IDirectory/SaveUser", type_id: 2 },
            { name: "Set Service Authorization Policy", value: "Indigo.Common.Directory/IDirectory/SetServiceAuthorizationPolicy", type_id: 2 },
            { name: "Set System Claims", value: "Indigo.Common.Directory/IDirectory/SetSystemClaims", type_id: 2 },
            { name: "Switch Domain Context", value: "Indigo.Common.Directory/IDirectory/SwitchDomainContext", type_id: 2 },
            { name: "Switch Session Context", value: "Indigo.Common.Directory/IDirectory/SwitchSessionContext", type_id: 2 },
            { name: "System Initialize", value: "Indigo.Common.Directory/IDirectory/SystemInitialize", type_id: 2 },
        ];

    static tasksName = [
        { name: "All", value: null },
        { name: "Confirm Bookings", value: "ConfirmBookings" },
        { name: "Disable Promotions", value: "DisablePromotions" },
        { name: "Get Promotions", value: "GetPromotions" },
        { name: "Process Bookings", value: "SyncBookings" },
        { name: "Process Channel Rates", value: "ProcessChannelRates" },
        { name: "Process Mailbox", value: "SyncMailbox" },
        { name: "Sync Bookings", value: "GetBookings" },
        { name: "Sync Channel Data", value: "GetChannelHotelInfo" },
        { name: "Sync Common Inventory", value: "SyncCommonInventory" },
        { name: "Sync Competitor Hotels", value: "SyncChannelHotels" },
        { name: "Sync Competitor Mapping", value: "GenerateMapping" },
        { name: "Sync Competitor Rates", value: "SyncCompetitorRateCalendar" },
        { name: "Sync Promotions", value: "SyncPromotions" },
        { name: "Sync Rate Calendar(GET) ", value: "GetRateCalendar" },
        { name: "Sync Rate Calendar(PUT) ", value: "SyncRateCalendar" },
    ]
}
