import { Component, OnInit, ViewChild, HostListener, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DataTableModule, SharedModule, MessageService } from 'primeng/primeng';
import { FormattedBooking, BookingService } from '../ui-services/booking.service';
import { DashboardService } from '../ui-services/dashboard.services';
import { DatePipe } from '@angular/common';
import { Utilities } from '../ui-services/utilities';
import { StorageService } from '../ui-services/storage.service';
import { AfterViewInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { Subscription } from 'rxjs';
import { TableWidgetComponent } from '../components/table-widget/table-widget.component';

import * as jQuery from 'jquery';
import { AuthenticationService } from '../ui-services/authentication.service';
import { AnalyticsService, ChannelBookingData } from '../ui-services/analytics.service';
import { BookingAnalyticsCriteria, DateRange } from 'mantras-api';
import { RatecalendarService } from '../ui-services/ratecalendar.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [DashboardService, BookingService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit, AfterViewInit {
  todaysArrival: FormattedBooking[];
  todaysBooking: FormattedBooking[];
  todaysArrivalField = [{ field: "GuestDetails.Name", headerName: "Guest Name", width: 170 }, { field: "CreatedOn", headerName: "Created On", width: 210 }, { field: "BasicDetails.Duration", headerName: "Night(s)", width: 70 }, { field: "Channel", headerName: "Channel", width: 120 }, { field: "Rooms.0.RoomName", headerName: "RoomType" }]
  todaysBookingsField = [{ field: "GuestDetails.Name", headerName: "Guest Name", width: 170 }, { field: "BasicDetails.CheckIn", headerName: "Check In", width: 150 }, { field: "BasicDetails.Duration", headerName: "Night(s)", width: 80 }, { field: "Rooms.0.RoomName", headerName: "RoomType" }, { field: "Channel", headerName: "Channel", width: 100 }]
  rateInventory;
  chartOption;
  occupancies;
  message;
  selectedCurrMonthDate;
  selectedNextMonthDate;
  currentInvSnapshotDate;
  showLoadingOverlay_todaysArrival;
  showLoadingOverlay_todaysBookings;
  subscription: Subscription;
  blockingMessage = null;
  disableInvSnapshotNext = false;
  disableInvSnapshotPrev = false;
  arrivalWidgetHeader;
  arrivalsDate;
  dashboardMenuItems;
  data: any;
  duration = [];
  reCalSyncYearRange;
  @ViewChild('widgetTodaysArrivals') widgetTodaysArrivals;
  @ViewChild('widgetTodaysBookings') widgetTodaysBookings;
  constructor(private dashBoardService: DashboardService,private messageService: MessageService, private bookingService: BookingService, public rateCalendarService: RatecalendarService,
    private storageService: StorageService, private analyticsService: AnalyticsService, private cdr: ChangeDetectorRef, private authService: AuthenticationService) {
    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        this.loadDashboard();
      });
    this.onChangeDurationForPieChart();

    this.duration.push({ label: "Today", value: "today" });
    this.duration.push({ label: "Yesterday", value: "yesterday" });
    this.duration.push({ label: "This Week", value: "thisweek" });
    this.duration.push({ label: "Last Week", value: "lastweek" });
    this.duration.push({ label: "This Month", value: "thismonth" });
    this.duration.push({ label: "Last Month", value: "lastmonth" });
    this.duration.push({ label: "This Year", value: "thisyear" });
    this.duration.push({ label: "Last Year", value: "lastyear" });
    this.duration.push({ label: "Custom", value: "custom" });

  }

  ngOnInit() {
    let currDate = new Date();
    this.currentInvSnapshotDate = new Date();
    this.selectedCurrMonthDate = new Date(currDate.getUTCFullYear(), currDate.getUTCMonth(), 1);
    this.selectedNextMonthDate = new Date(currDate.getUTCFullYear(), currDate.getUTCMonth() + 1, 1);
    console.log(this.message);
    this.arrivalWidgetHeader = "Today's Arrivals";
    this.arrivalsDate = new Date();
    this.loadMenu();
    // this.onChangeDurationForPieChart();
  }
  loadMenu() {
    this.dashboardMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.loadDashboard() }
    ];
  }

  channelCodesBookingCount;
  channelLabels;
  showPieChart() {
    this.data = {
      labels: this.channelLabels,
      datasets: [
        {
          data: this.channelCodesBookingCount,
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "pink",
            "red",
            "white"
          ],
          hoverBackgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "pink",
            "red",
            "white"
          ]
        }]
    };
  }

  rangeDates = [];
  chCodeObj; listDataObj;
  bookingDateRange;
  showCustomCalendar;
  editCal = false;
  selectedDuration = "thisweek";
  availableChannelTypes; durationDays = [];
  bookingCriteria = new BookingAnalyticsCriteria();
  async onChangeDurationForPieChart() {
    this.durationDays = [];
    this.bookingCriteria.HotelIds = [this.storageService.get(StorageService.currentUser).HotelContext.HotelId];
    this.bookingCriteria.CreateDate = new DateRange();
    if (this.selectedDuration != "custom") {
      this.durationDays = Utilities.durationPeriod(this.selectedDuration);
      this.bookingCriteria.CreateDate.Start = this.durationDays[0];
      this.bookingCriteria.CreateDate.End = this.durationDays[1];
      this.rangeDates = [this.bookingCriteria.CreateDate.Start, this.bookingCriteria.CreateDate.End];
      this.getReport();
    }
    else{
      this.messageService.add({key:'approve' ,severity: 'info', life: 2000, summary: 'Information', detail: 'Please select date range from calendar' });
    }
  }

  onChangeDurationCustom() {
    if (!this.rangeDates || this.rangeDates.length == 0) {
      this.rangeDates = [this.bookingCriteria.CreateDate.Start, this.bookingCriteria.CreateDate.End];
    }
    else{
    this.selectedDuration="custom";
    this.bookingCriteria.HotelIds = [this.storageService.get(StorageService.currentUser).HotelContext.HotelId];
    this.bookingCriteria.CreateDate = new DateRange();
    this.rangeDates[0].setHours(0, 0, 0, 0);
    this.rangeDates[1].setHours(23, 59, 0, 0);
    this.bookingCriteria.CreateDate.Start = this.rangeDates[0];
    this.bookingCriteria.CreateDate.End = this.rangeDates[1];
    // console.log("Start",this.bookingCriteria.CreateDate.Start);
    // console.log("End",this.bookingCriteria.CreateDate.End);
    this.getReport();
    }
  }

  async getReport() {
    await this.analyticsService.getBookingAnalyticsReportPie(this.bookingCriteria).then(async response => {
      this.chCodeObj = [];
      this.listDataObj = [];
      var channelBookingDataObj;
      for (let i = 0; i < response.length; i++) {
        let element = response[i];
        let index = this.chCodeObj.indexOf(element.ChannelCode);
        if (index >= 0) {
          channelBookingDataObj = this.listDataObj[index];
          channelBookingDataObj.Count++;
        } else {
          channelBookingDataObj = new ChannelBookingData();
          channelBookingDataObj.DataObjectList = [];
          channelBookingDataObj.ChannelCode = element.ChannelCode;
          channelBookingDataObj.Count++;
          this.chCodeObj.push(element.ChannelCode);
          this.listDataObj.push(channelBookingDataObj);
        }
      }
      this.availableChannelTypes = await this.rateCalendarService.getChannelTypes();
      if (this.listDataObj.length > 0) {
        this.channelCodesBookingCount = [];
        this.channelLabels = [];
        this.listDataObj.forEach(element => {
          this.channelCodesBookingCount.push(element.Count);
          let channelName = this.availableChannelTypes.find(ch => ch.value === element.ChannelCode);
          if (channelName) {
            this.channelLabels.push(channelName.label + "(" + element.Count + ")");
          } else {
            this.channelLabels.push(Utilities.findId(Utilities.channelTypes, element.ChannelCode) + "(" + element.Count + ")");
          }
        });
      } else {
        this.channelCodesBookingCount = [];
        this.channelLabels = [];
        this.channelCodesBookingCount = [1];
        this.channelLabels = ['No bookings found'];
      }
      this.showPieChart();
    });
  }


  @ViewChild('showBlockingMessage') showBlockingMessage;
  showBlockingMessageDialog = false;
  async loadDashboard() {
    this.widgetTodaysArrivals.tabledata = this.dashBoardService.getTodaysArrival();
    this.widgetTodaysArrivals.onGridReady();

    this.widgetTodaysBookings.tabledata = this.dashBoardService.getTodaysBookings();
    this.widgetTodaysBookings.onGridReady();

    this.dashBoardService.getRateCalendarInventory().then(resp => {
      this.rateInventory = this.parseInventory(resp);
    }).catch(error => {
      this.rateInventory = null;
    });

    await this.dashBoardService.getRateCalendarOccupancy().then(async resp => {
      this.occupancies = resp;
      this.cdr.detectChanges();
    }).catch(error => {
      this.occupancies = null;
    });

  }
  onClickOk() {
    this.showBlockingMessageDialog = false;
  }
  ngAfterViewInit(): void {
    this.loadDashboard();
    this.setHeight();
  }

  backgroundColor = Utilities.backgroundColor;
  parseInventory(resp) {
    let graphData;
    let labels = [];
    let dataSet = [];
    let datePipe = new DatePipe('en-US');
    let rooms = this.storageService.get(StorageService.currentUser).HotelContext.RoomTypes
    let roomIndex = 0
    let maxInventory = 0;
    if (resp && resp.length > 0) {
      for (let i = 0; i < resp.length; i++) {
        let rcSnapshot = resp[i];
        labels.push(datePipe.transform(new Date(rcSnapshot.StayDate), "dd MMM"));
        if (rcSnapshot.RooomSummaries.length > 0) {
          rcSnapshot.RooomSummaries.forEach(roomSummary => {
            if (roomSummary) {
              let summary = dataSet.find(data => data.Id == roomSummary.RoomID);
              if (!summary) {
                let label = Utilities.findId(rooms, roomSummary.RoomID.toString(), roomSummary.RoomID.toString());
                summary = { Id: roomSummary.RoomID, label: label, backgroundColor: this.backgroundColor[roomIndex], data: new Array(resp.length) };

                dataSet.push(summary);
                roomIndex++;
              }
              summary.data[i] = roomSummary.Inventory;
              if (maxInventory < roomSummary.Inventory) {
                maxInventory = roomSummary.Inventory;
              }
            }
          });
        }
      }
      graphData = {};
      graphData["labels"] = labels;
      graphData["datasets"] = dataSet;

      this.chartOption = {
        title: {
          display: false,
          text: 'Inventory Snapshot',
          fontSize: 15
        },
        legend: {
          position: 'bottom'
        },
        scales: {
          yAxes: [{
            ticks: {
              precision: 0,
              beginAtZero: true,
              suggestedMax: maxInventory + 2
            }
          }]
        }
      };
    }

    return graphData;
  }

  isCalendarLoaded = false;


  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.dashboardHeight').css('height', Utilities.getScrollHeight(true));
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.cdr.detach();
  }
  formatTodaysArrival(data) {
    let tempBookingArray: FormattedBooking[] = [];
    let footer = 0;
    if (data && data.length > 0) {
      data.forEach(element => {
        if (element.Status != "Cancelled") {
          tempBookingArray.push(element);
          footer += element.BasicDetails.RoomCount;
        }
      });
      this.todaysArrival = tempBookingArray;
      this.widgetTodaysArrivals.tabledata = tempBookingArray;
      this.widgetTodaysArrivals.footer = "Total Rooms: " + footer.toString();
    }
  }
  formatTodaysBookings(data) {
    let tempBookingArray: FormattedBooking[] = [];
    let footer = 0;
    if (data && data.length > 0) {
      data.forEach(element => {
        if (element.Status != "Cancelled") {
          tempBookingArray.push(element);
          footer += element.TotalAmt.NetAmt;
        }
      });
      this.todaysBooking = tempBookingArray;
      this.widgetTodaysBookings.footer = "Total Revenue(Net Amount in " + data[0].Booking.Currency + "): " + footer.toFixed(2);
    } else {
      this.widgetTodaysBookings.footer = "";
    }
  }

  getOccupancy(date) {
    if (date.otherMonth) return 0;
    let occupancy = this.occupancies.find(occupancy => Utilities.date_sort_asc(new Date(occupancy.StayDate), new Date(Date.UTC(date.year, date.month, date.day, 0, 0, 0, 0))) == 0);
    if (occupancy) {
      if (parseFloat(occupancy.Occupancy) == Infinity) return 0;
      return (occupancy.Occupancy > 100) ? 100 : occupancy.Occupancy.toFixed(2);
    } else return 0;
  }
  async onClickNextMonthOccupancy() {
    let currDate = this.selectedCurrMonthDate;
    this.selectedCurrMonthDate = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 1);
    this.selectedNextMonthDate = new Date(currDate.getFullYear(), currDate.getMonth() + 2, 1);
    await this.dashBoardService.getRateCalendarOccupancy(this.selectedCurrMonthDate).then(async resp => {
      this.occupancies = resp;
      this.cdr.detectChanges();
    }).catch(error => {
      this.occupancies = null;
    });
  }
  async onNavigateOccupancyPercentage(choice) {
    let currDate = this.selectedCurrMonthDate;
    if (choice == 'next') {
      this.selectedCurrMonthDate = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 1);
      this.selectedNextMonthDate = new Date(currDate.getFullYear(), currDate.getMonth() + 2, 1);
    }
    else {
      this.selectedCurrMonthDate = new Date(currDate.getFullYear(), currDate.getMonth() - 1, 1);
      this.selectedNextMonthDate = new Date(currDate.getFullYear(), currDate.getMonth(), 1);
    }
    await this.dashBoardService.getRateCalendarOccupancy(this.selectedCurrMonthDate).then(async resp => {
      this.occupancies = resp;
      this.cdr.detectChanges();
    }).catch(error => {
      this.occupancies = null;
    });
  }
  onNavigateInvSnapshot(choice) {
    if (choice == 'next') this.currentInvSnapshotDate.setDate(this.currentInvSnapshotDate.getDate() + 7);
    else this.currentInvSnapshotDate.setDate(this.currentInvSnapshotDate.getDate() - 7);
    this.dashBoardService.getRateCalendarInventory(this.currentInvSnapshotDate).then(resp => {
      if (resp.length == 0) {
        if (choice == 'next') this.disableInvSnapshotNext = true;
        if (choice == 'prev') this.disableInvSnapshotPrev = true;
      }
      else {
        this.disableInvSnapshotPrev = false;
        this.disableInvSnapshotNext = false;
        this.rateInventory = this.parseInventory(resp);
      }
      this.cdr.detectChanges();
    }).catch(error => {
      this.rateInventory = null;
    });
  }
  onNavigateClick(data) {
    let date = this.arrivalsDate;
    let today = new Date();
    if (data['buttonClicked'] == "prev") date.setDate(date.getDate() - 1);
    if (data['buttonClicked'] == "next") date.setDate(date.getDate() + 1);
    console.log(Utilities.daysDifference(today, date))
    let daysDiff = Utilities.daysDifference(today, date);
    if (daysDiff == -1) {
      this.arrivalWidgetHeader = "Yesterday's Arrivals";
    }
    else if (daysDiff == 0) {
      this.arrivalWidgetHeader = "Today's Arrivals";
    }
    else if (daysDiff == 1) {
      this.arrivalWidgetHeader = "Tomorrow's Arrivals";
    }
    else {
      this.arrivalWidgetHeader = "Arrivals on " + date.getDate() + " " + Utilities.getMonthName(date.getMonth());
    }
    this.getArrivals(date);
  }
  getArrivals(date) {
    this.widgetTodaysArrivals.tabledata = this.dashBoardService.getTodaysArrival(date);
    this.widgetTodaysArrivals.onGridReady();
  }
}