import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import { Utilities } from "./utilities";
import { TaskbarService } from "./taskbar.service";
import { LoadingService } from "./loading.service";
import { AppService, Promotion } from "mantras-api";
import { PromotionCriteria } from "mantras-api";
import { AppConfigService } from "../app-config.service";

@Injectable()
export class PromotionService {

    //mantras:AppService;
    public static promotionStatus = ["Active", "Inactive", "Expired", "Corrupted"];
    public static promotionTypes = ["Basic Promotion", "Complex Promotion", "Min Stay Promotion", "Early Bird Promotion", "Last Minute Promotion", "Other Promotion"];
    public static promotionActions = ["Created", "Modified", "Published", "Retrieved", "Deleted"];

    public static supportedChannelTypes = ["AGO", "AGD", "BDC", "EXP", "GIB", "TLG", "MXM"];

    constructor(private mantras: AppService, private storageService: StorageService, private utilities: Utilities,
        private loadingService: LoadingService, private taskService: TaskbarService,private appConfService:AppConfigService) {
        Utilities.initApp(mantras, appConfService);
    }

    findPromotions(promotionCriteria: PromotionCriteria, isBackground: boolean): Promise<Promotion[]> {
        if (!isBackground)
            return this.loadingService.interceptor(this.mantras.findPromotions(promotionCriteria).then
                (response => {
                    return response;
                }));
        else
            return this.mantras.findPromotions(promotionCriteria).then
                (response => {
                    return response;
                });
    }

    findPromotionHistory(promotionLogCriteria) {
        return this.loadingService.interceptor(this.mantras.findPromotionHistory(promotionLogCriteria).then
            (response => {
                return response;
            }), true);
    }

    SavePromotions2(hotelId,promotions,tgtSource) {
        return this.loadingService.interceptor(this.mantras.savePromotions2(hotelId,[promotions],tgtSource).then
            (response => {
                return response;
            }), true);
    }

    saveAndPublishPromotion(hotelId, promotion, channelTypes) {
        return this.loadingService.interceptor(this.mantras.savePromotions(hotelId, [promotion], channelTypes).then
            (async response => {
                let promoList = [promotion];
                if (!promotion.Id && !promotion.MasterPromotionId && promotion.ChannelCode == "MAX") {
                    let promoCriteria = new PromotionCriteria();
                    promoCriteria.HotelId = hotelId;
                    promoCriteria.DomainId = promotion.DomainId;
                    promoCriteria.MasterPromotionIdList = response;
                    promoCriteria.ChannelCode = "MAX";
                    promoCriteria.Islocal = true;
                    promoList = await this.mantras.findPromotions(promoCriteria).then(promos => { return promos; });
                }
                channelTypes.forEach(channelType => {
                    this.mantras.putPromotions(hotelId, promoList, channelType).then(syncId => {
                        this.taskService.addTasks([syncId]);
                    });
                });
                return response;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                return error;
            }));
    }

    deletePromotion(hotelId, promotionId) {
        return this.loadingService.interceptor(this.mantras.deletePromotion(hotelId, promotionId).then
            (response => {
                return response;
            }));
    }

    disablePromotion(hotelId, promoObj) {
        return this.loadingService.interceptor(this.mantras.disablePromotions(hotelId, [promoObj], promoObj.ChannelCode).then
            (response => {
                this.taskService.addTasks([response]);
                return response;
            }));
    }

    getPromotion(hotelId, channelTypes) {
        channelTypes.forEach(channelType => {
            this.mantras.getPromotions(hotelId, channelType).then(syncId => {
                this.taskService.addTasks([syncId]);
            });
        });
    }

    putPromotion(hotelId, promotions, channelTypes) {
        channelTypes.forEach(channelType => {
            this.mantras.putPromotions(hotelId, promotions, channelType).then(syncId => {
                this.taskService.addTasks([syncId]);
            });
        });
    }
}

export class discountTypesExpedia {
    value: number;
    memberOnlyAdditionalValue: number;
    applicableNight: number;
    isRecurring: boolean;
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
    sunday: number;
    type: promoType;
    unit: number;
}

export declare enum promoType {
    SingleDiscount = 2,
    DayOfWeekDiscount = 0,
    MultiNightDiscount = 1
}