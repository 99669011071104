import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/primeng';
import { StorageService } from '../../ui-services/storage.service';
import { Utilities } from '../../ui-services/utilities';
import { UserService } from '../../ui-services/user.service';
import { HotelManagementService } from '../../ui-services/hotelmanagement.service';
import * as jQuery from 'jquery';

@Component({
  selector: 'mm-revenueplanning',
  templateUrl: './revenueplanning.component.html',
  styleUrls: ['./revenueplanning.component.css']
})
export class RevenueplanningComponent implements OnInit, AfterViewInit {

  breadCrumbItems;
  menuItems;
  isReadOnly = false;
  hasSystemAccess = false;
  constructor(private router: Router, private storageService: StorageService, private service: HotelManagementService,private userService:UserService,private messageService: MessageService) {
    this.hasSystemAccess = Utilities.checkSystemAccess(this.storageService.get(StorageService.currentUser).DomainContext.Domains);
    this.isReadOnly=userService.isReadOnly();
    this.loadMenu();
  }

  ngOnInit() {
    //this.setHeight();
  }

  ngAfterViewInit(): void {
    // if (this.propertyDetails)
    //   this.propertyDetails.setHeight();
  }

  loadMenu() {
    this.menuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.refresh() },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
    ];
  }

  setup() {

  }

  onClose() {
    this.router.navigate(["/rms/revenueplanning"]);
  }

  async refresh() {
    // //Load General Tab
    // this.propertyDetails.loadData(true);
    // //Load Competitor
    // if (this.competitorComp)
    //   this.competitorComp.onRefresh(event);
    // //load Room & RatePlan
    // if (this.roomTypeComp)
    //   this.roomTypeComp.onRefresh(event);
    // if (this.ratePlanComp)
    //   this.ratePlanComp.onRefresh(event);
    // //Load TaxPolicies
    // if (this.taxPolicyComp)
    //   this.taxPolicyComp.onRefresh(event);
    // //Load Hotel Channels
    // if (this.hotelChannelComp)
    //   this.hotelChannelComp.onRefresh(event);
    // //Load Mantras eXchange tab.
    // if (this.mantrasExchangeComp)
    //   this.mantrasExchangeComp.loadData();
  }
  
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true));
  }

}
