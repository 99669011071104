import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorsService } from './errors.service';
import { LoggingService } from './logging.service';
import { ErrorNotificationService } from './errornotification.service';

// import { LoggingService } from './services/logging.service';
// import { ErrorService } from './services/error.service';
// import { NotificationService } from './services/notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    constructor(private injector: Injector) { }

    handleError(error: Error | HttpErrorResponse) {

        const errorService = this.injector.get(ErrorsService);
        const logger = this.injector.get(LoggingService);
        // const notifier = this.injector.get(ErrorNotificationService);

        let message;
        let stackTrace;
        let errorType;

        if (error instanceof HttpErrorResponse) {
            // Server Error
            message = errorService.getServerMessage(error);
            stackTrace = errorService.getServerStack(error);
            errorType = "Server";
            // notifier.showError(message);
        } else {
            // Client Error
            message = errorService.getClientMessage(error);
            stackTrace = errorService.getClientStack(error);
            errorType = "Client";
            // notifier.showError(message);
        }

        // Always log errors
        logger.logError(message, stackTrace,errorType);

        console.error(error);
    }
}