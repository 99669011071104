import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { ConfirmationService } from "primeng/components/common/api";
import { Observable ,  Observer } from "rxjs";

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<any> {
    constructor(private confirmationService: ConfirmationService) {
    }

    canDeactivate(component) {
        // Allow navigation if the form is unchanged
        try{
            if (!component.isDirty()) {
                return true;
            }
        }catch(ex){
            
        }   

        return Observable.create((observer: Observer<boolean>) => {
            this.confirmationService.confirm({
                message: 'You have unsaved changes. Are you sure you want to leave this page?',
                accept: () => {
                    observer.next(true);
                    observer.complete();
                },
                reject: () => {
                    observer.next(false);
                    observer.complete();
                }
            });
        });
    }
}