import { Component, OnInit,OnChanges, Input, Output, ViewChild,EventEmitter, AfterViewChecked, ElementRef, AfterViewInit, ChangeDetectorRef, Renderer,HostListener } from '@angular/core';
import { NotificationService } from '../../ui-services/notification.service';
import { Router } from '@angular/router';
import * as jQuery from 'jquery';
import {SelectItem} from 'primeng/api';
import {MessageService} from 'primeng/api';
import { Utilities } from '../../ui-services/utilities';
import { AuthenticationService } from '../../ui-services/authentication.service';
import { StorageService } from '../../ui-services/storage.service';
interface Option{
  name: string;
  code: string;
}
@Component({
  selector: 'app-notification-new',
  templateUrl: './notification-new.component.html',
  styleUrls: ['./notification-new.component.css']
})
export class NotificationNewComponent implements OnInit {
  @Input() display;
  @Output() displayValueChange = new EventEmitter();
  @Output() notificaionCountUpdated = new EventEmitter();
  notificationDialogVisibility=false;
  notifications;
  clickedIndex=-1;
  notificationSort: SelectItem[];
  notificationOptions: SelectItem[];
  selectedOption:Option;
  notificationIndex=0;
  notificationCount=0;
  createTask=-1;
  taskDate:Date;
  showDetailedView=false;
  showConsolidated=false;
  clickedChild=-1;
  displayExpandedDialog=false;
  currentActionDetails;
  constructor(
    private router: Router, 
    private notificationService:NotificationService,
    private cdr:ChangeDetectorRef,
    private messageService: MessageService,
    private authService:AuthenticationService,
    private storageService:StorageService,
    private renderer:Renderer) {
    this.notificationSort = [
      {label:'Latest on top', value:{id:1, name: 'Latest on top', code: 'LT'}},
      {label:'Due date', value:{id:2, name: 'Due Date', code: 'DD'}},
      {label:'Priority', value:{id:3, name: 'Priority', code: 'PR'}}
  ];
    this.notificationOptions = [
      {label:'Convert to Task', value:{id:1, name: 'Convert to Task', code: 'CT'}},
      {label:'Cancel', value:{id:2, name: 'Cancel', code: 'CA'}}
  ];
   }
  ngOnInit() {
    this.showConsolidated=true;
    this.notifications = this.notificationService.getData();
    this.notificationCount = this.notificationService.getCount();
    this.notificaionCountUpdated.emit(this.notificationCount);
  }
  ngAfterViewInit(){
    this.setHeight();
  }
  onNotificationClick(i){
    this.notificationDialogVisibility = true;
    this.notificationIndex=i;
  }
  onNotificationOk($event){
    this.notificationDialogVisibility=false;
    let notification = this.notificationService.getData()[this.notificationIndex];
    if(notification.action == "rateCalendar"){
      this.router.navigate(["/ratecalendar"]);
    }
    if(notification.action == "bookings"){
      this.router.navigate(["/bookings"]);
    }
    console.log(notification);
  }
  onCloseNotification($event,i){
    console.log(event);
    this.notifications = this.notificationService.isClosed(i);
    this.notificationCount -=1;
    this.notificaionCountUpdated.emit(this.notificationCount);
  }
  onApproveNotification($event,i){
    this.messageService.add({key:'approve',severity:'success',life:3000, summary:'Notification approved', detail:'This notification is approved successfully.',data:i});
    console.log(event);
    this.notifications = this.notificationService.isApproved(i);
    this.router.navigate( ['ratecalendar/1/2'] );
    this.notificationCount -=1;
    this.notificaionCountUpdated.emit(this.notificationCount);
  }
  onRejectNotification($event,i){
    this.messageService.add({key:'reject',severity:'warn',sticky:true, summary:'Reject Notification?', detail:'This will reject the notification permanently.',data:i});
    console.log(event);
  }
  onRejectConfirm(i){
    this.notifications = this.notificationService.isRejected(i);
    this.notificationCount -=1;
    this.notificaionCountUpdated.emit(this.notificationCount);
    this.messageService.clear()
  }
  onClickNo(){
    this.messageService.clear();
  }
  onSlidebarHide(){
    this.createTask=-1;
    this.display=false;
    this.displayValueChange.emit();
  }
  getColor(n){
    if(n.severity == "high") return 'red';
    if(n.severity == "medium") return 'orange';
    if(n.severity == "low") return 'yellow';
    if(n.severity == "error") return 'blue';
    console.log(n);
  }
  displayShowMore(message){
    if(message.length>70) return 'inline'
    else return 'none';
  }
  getMessage(message){
    return message.substring(0,70);
  }
  notificationClearBar(selection){
    if(selection=='reminder' || selection=='info' || selection=='task'){
      return true;
    }
    else
      return false;
  }
  clearAllNotifications(){
    if(this.notificationCount>0){
      this.messageService.add({key:'clear-all',severity:'warn',sticky:true, summary:'Clear all Notifications?', detail:'This will reject all the notifications permanently.'});
    }else{
      this.messageService.add({key:'error',severity:'error',life:3000, summary:'No notifications', detail:'There are no notifications to clear.'});
    }
  }
  onClearAllConfirm(){
    this.notifications.forEach(element => {
      element.isSeen="0";
    });
    this.notificationCount=0;
    this.notificaionCountUpdated.emit(this.notificationCount);
    this.cdr.detectChanges();
    this.messageService.clear();
  }
  onClickShowMore(){
    this.showDetailedView=true;
    this.showConsolidated=false;
  }
  onClickShowLess(){
    this.showDetailedView=false;
    setTimeout(()=> this.showConsolidated=true,300)
    this.clickedIndex=-1;
  }
  onClickLatestOnTop(){
    this.onClickShowLess();
    var tempArray = [];
    tempArray = this.notifications.sort((val1, val2)=> {return <any>new Date(val1.timestamp) - <any>new Date(val2.timestamp)});
    this.notifications = tempArray;
  }
  onClickSortByDueDate(){
    this.onClickShowLess();
    var tempArray = [];
    tempArray = this.notifications.sort((val1, val2)=> {return <any>new Date(val1.dueTimestamp) - <any>new Date(val2.dueTimestamp)});
    this.notifications = tempArray;
  }
  onClickSortByPriority(){
    this.onClickShowLess();
    var tempArray = [];
    this.notifications.forEach(element => {
      if(element.severity == 'high')  tempArray.push(element);
    });
    this.notifications.forEach(element => {
      if(element.severity == 'medium')  tempArray.push(element);
    });
    this.notifications.forEach(element => {
      if(element.severity == 'low')  tempArray.push(element);
    });
    this.notifications.forEach(element => {
      if(element.severity == 'error')  tempArray.push(element);
    });
    this.notifications = tempArray;
  }
  onDropdownChange(event){
    this.createTask = -1;
    this.onClickShowLess();
    var tempArray = [];
    if(event.value['code']=="LT")
    {
      tempArray = this.notifications.sort((val1, val2)=> {return <any>new Date(val2.timestamp) - <any>new Date(val1.timestamp)});
    }
    if(event.value['code']=="PR")
    {
      this.notifications.forEach(element => {
        if(element.severity == 'high')  tempArray.push(element);
      });
      this.notifications.forEach(element => {
        if(element.severity == 'medium')  tempArray.push(element);
      });
      this.notifications.forEach(element => {
        if(element.severity == 'low')  tempArray.push(element);
      });
      this.notifications.forEach(element => {
        if(element.severity == 'error')  tempArray.push(element);
      });
    }
    if(event.value['code']=="DD"){
      tempArray = this.notifications.sort((val1, val2)=> {return <any>new Date(val1.dueTimestamp) - <any>new Date(val2.dueTimestamp)});
    }
    //change index after sort
    let count=0;
    tempArray.forEach((temp)=>{
      temp.index = count;
      count++;
    }
    );
    this.notifications = tempArray;
     
    console.log(event);
  }
  selectedAction = null;
  onTaskDropdownChange(event,i){
    if(event.value['code']=="CT")
    {
      this.createTask=i;
    }
    if(event.value['code']=="CA")
    {
      this.createTask=-1;
    }
    this.selectedAction = null;
  }
  getNotificationTimestamp(notificationTimestamp){
    let serverTimestamp = this.authService.getTimestamp();
    return Utilities.toDateString(new Date(notificationTimestamp),new Date(serverTimestamp));
  }
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.notification-list').css('height', windowHeight-105);
  }
  onClickNotificationTitle(i){
  if(this.showDetailedView){
    this.clickedIndex=i;
  }
}
  isTask(n){
    if(n.type == "Channel Follow-up Alert") return true;
    else return false;
}
saveTask(){
console.log(this.taskDate);   
}
onChildClick(i){
  this.clickedChild = i;
  console.log("Notification clicked "+i);
}
onOpenDialog($event){
  this.displayExpandedDialog = true;
  console.log($event);
  this.currentActionDetails = $event;
}
}
