import { Component, OnInit, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { Channel, Credentials, HotelMapping } from 'mantras-api';
import { HotelManagementService } from '../ui-services/hotelmanagement.service';
import { StorageService } from '../ui-services/storage.service';
import { Utilities } from '../ui-services/utilities';
import { ContactInfo, Phone } from 'mantras-api';
import { environment } from '../../environments/environment';

import * as jQuery from 'jquery';
import { UserService } from '../ui-services/user.service';
import { CommonService } from '../ui-services/common.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppConfigService } from '../app-config.service';

@Component({
  selector: 'domainchanneldetails',
  templateUrl: './domainchanneldetails.component.html',
  styleUrls: ['./domainchanneldetails.component.css']
})
export class DomainchanneldetailsComponent implements OnInit {

  @Input() channel = new Channel();
  @Output('onClose') onClose = new EventEmitter<boolean>();
  isReadOnly = false;
  errorMsg = "";
  dcMenuItems = [];
  hotelContext;

  systemChannels;
  email;
  phone;
  hotelNumber;
  cookies;
  bookingEngine;
  pmsCode;
  showXmlCreds = false;
  bookingEngineLists = [];
  winHMSBookingURL;
  gtaQualifier;
  apiKey;
  hotelUrl;
  accessToken;
  url;
  bnbExistingListingIds = "";
  isNew = false;
  pmsCodes = Utilities.PMSCodes;
  enableMapping = false;
  hostelWorldUrls = [{ label: "webresint", value: "https://inbox.webresint.com/inbox/" }, { label: "hostelworld", value: "https://inbox.hostelworld.com/inbox/" }];
  hasSystemAccess = false;
  constructor(private storageService: StorageService, private messageService: MessageService, private hotelMgmtService: HotelManagementService, private userService: UserService,
    private commonService: CommonService, private confirmationService: ConfirmationService, private appConfig: AppConfigService) {
    this.isReadOnly = userService.isReadOnly();
    this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    this.hasSystemAccess = Utilities.checkSystemAccess(this.storageService.get(StorageService.currentUser).DomainContext.Domains);
  }

  ngOnInit() {
  }

  loadData() {
    this.hotelMgmtService.getSystemChannels().then(sysChannels => {
      this.systemChannels = [];
      sysChannels.forEach(sysChannel => {
        if (sysChannel.IsActive) this.systemChannels.push({ label: Utilities.findId(Utilities.channelTypes, sysChannel.ChannelCode, sysChannel.ChannelCode), value: sysChannel.ChannelCode });
      });
      this.systemChannels.sort(Utilities.sort_label_asc);
    });

    this.bookingEngineLists = Utilities.xchangeBECodes;

    if (this.channel.Id == null) {
      this.channel.Credentials = new Credentials();
      this.channel.XmlCredentials = new Credentials();
      this.channel.DomainId = this.storageService.get(StorageService.userDomainContext).CurrentDomainId;
      this.channel.IsActive = true;
      this.isNew = true;
    }
    this.hotelNumber = (this.channel.Config) ? this.channel.Config["HotelNumber"] : this.hotelNumber;
    this.bookingEngine = (this.channel.Config) ? this.channel.Config["BookingEngine"] : "";
    this.pmsCode = (this.channel.Config) ? this.channel.Config["PMSCode"] : "";
    this.winHMSBookingURL = this.channel.Config ? this.channel.Config["BookingUrl"] : "";
    this.cookies = this.channel.Config ? this.channel.Config["CustomCookies"] : "";
    this.gtaQualifier = this.channel.Config ? this.channel.Config["Qualifier"] : "";
    this.apiKey = this.channel.Config ? this.channel.Config["APIKey"] : "";
    this.hotelUrl = this.channel.Config ? this.channel.Config["HotelUrl"] : "";
    this.accessToken = this.channel.Config ? this.channel.Config["AccessToken"] : "";
    this.url = this.channel.Config ? this.channel.Config["Url"] : "";
    this.bnbExistingListingIds = this.channel.Config ? this.channel.Config["ExistingListing"] : "";
    this.showXmlCreds = Utilities.doesChannelSupportXmlCreds(this.channel.ChannelCode);
    this.email = this.channel.Contact ? this.channel.Contact.Email : this.email;
    this.phone = (this.channel.Contact && this.channel.Contact.Phones) ? this.channel.Contact.Phones[0].Number : this.phone;

    //Mapping Menu:
    if (!this.isNew) {
      this.enableMapping = this.channel.ChannelCode == "LUC" || (this.channel.ChannelCode == "XCG" && this.pmsCode == "PROLOGIC");
    }
    this.loadMenu();
  }

  loadMenu() {
    if (this.isNew) {
      this.dcMenuItems = [
        { title: 'Verify & Save', label: 'Verify & Save', icon: 'action-bar-menu-icon fa fa-floppy-o', disabled: this.isReadOnly, command: (event) => this.onSave(event) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) },
        { title: 'Authenticate', label: 'Authenticate', icon: 'action-bar-menu-icon fa fa-user-o', disabled: !(this.channel.Id != null && this.channel.ChannelCode == "ANB") || this.isReadOnly, command: (event) => this.openLink() }];
    } else {
      this.dcMenuItems = [
        { title: 'Verify', label: 'Verify', icon: 'action-bar-menu-icon fa fa-key', disabled: this.isReadOnly, command: (event) => this.onVerify(event) },
        { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', disabled: this.isReadOnly, command: (event) => this.onSave(event) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) },
        { title: 'Authenticate', label: 'Authenticate', icon: 'action-bar-menu-icon fa fa-user-o', disabled: !(this.channel.Id != null && this.channel.ChannelCode == "ANB") || this.isReadOnly, command: (event) => this.openLink() },
        { title: 'Manage Mapping', label: 'Manage Mapping', icon: 'action-bar-menu-icon fa fa-random', visible: this.hasSystemAccess && this.enableMapping, disabled: (!this.enableMapping || this.isReadOnly), command: (event) => this.showMapping() }];
    }
  }
  onCancel(event?) {
    this.onClose.emit(event);
  }

  onSave(event, isVerified = false) {
    //console.log("AfterSave",this.channel);
    let verifyResult = this.onVerify(event, this.isNew || isVerified);
    if ((this.isNew && verifyResult) || (!this.isNew && verifyResult))
      this.hotelMgmtService.saveDomainChannel(this.channel).then(result => {
        this.onCancel(this.channel);
      });
  }

  async onVerify(event, doVerification = true) {
    //console.log("BeforeSave",this.channel);
    if (this.channel.Name == "" || !this.channel.Name) {
      this.errorMsg = "Please set Alias.";
      return;
    }
    if (this.channel.Credentials.UserId == "" || !this.channel.Credentials.UserId) {
      this.errorMsg = "Please set Credentials Login Id.";
      return;
    }
    this.errorMsg = "";
    if (this.email && this.email != null || this.phone && this.phone != null) {
      if (!this.channel.Contact) this.channel.Contact = new ContactInfo();
      this.channel.Contact.Email = this.email;
      this.channel.Contact.Phones = [];
      this.channel.Contact.Phones = [{ Number: this.phone, Type: "0" }];
    }
    if (this.hotelNumber != null && this.hotelNumber) {
      if (!this.channel.Config) this.channel.Config = new Map<string, string>();
      this.channel.Config["HotelNumber"] = this.hotelNumber;
    }

    if (this.cookies != null && this.cookies) {
      if (!this.channel.Config) this.channel.Config = new Map<string, string>();
      this.channel.Config["CustomCookies"] = this.cookies;
    }

    if ((this.channel.ChannelCode == 'XBE') || (this.channel.ChannelCode == 'XB1') || (this.channel.ChannelCode == 'XB2') || (this.channel.ChannelCode == 'XB3') || (this.channel.ChannelCode == 'XB4') || (this.channel.ChannelCode == 'XB5')) {
      if (!this.channel.Config) this.channel.Config = new Map<string, string>();
      this.channel.Config["BookingEngine"] = this.bookingEngine;
    }

    if (this.channel.ChannelCode == 'XCG') {
      if (!this.channel.Config) this.channel.Config = new Map<string, string>();
      this.channel.Config["PMSCode"] = this.pmsCode;
    }

    if (this.channel.ChannelCode == "WMS" && this.winHMSBookingURL) {
      if (!this.channel.Config) this.channel.Config = new Map<string, string>();
      this.channel.Config["BookingUrl"] = this.winHMSBookingURL;
    }
    if (this.channel.ChannelCode == "GTA" && this.gtaQualifier) {
      if (!this.channel.Config) this.channel.Config = new Map<string, string>();
      this.channel.Config["Qualifier"] = this.gtaQualifier;
    }
    if (this.channel.ChannelCode == "EGB" && this.apiKey && this.hotelUrl) {
      if (!this.channel.Config) this.channel.Config = new Map<string, string>();
      this.channel.Config["APIKey"] = this.apiKey;
      this.channel.Config["HotelUrl"] = this.hotelUrl;
    }

    if (this.channel.ChannelCode == "GIB" && this.accessToken) {
      if (!this.channel.Config) this.channel.Config = new Map<string, string>();
      this.channel.Config["AccessToken"] = this.accessToken;
    }
    if (this.channel.ChannelCode == "HWL" && this.apiKey && this.url) {
      if (!this.channel.Config) this.channel.Config = new Map<string, string>();
      this.channel.Config["APIKey"] = this.apiKey;
      this.channel.Config["Url"] = this.url;
    }
    if (this.channel.ChannelCode == "ANB") {
      this.channel.Config["ExistingListing"] = this.bnbExistingListingIds;
    }
    let isVerified = true;
    if (doVerification) {
      isVerified = await this.hotelMgmtService.verifyDomainChannel(this.channel).then(result => {
        if (result == 'true') {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Verified' });
        }
        if (result == 'false') {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Not Verified' });
        }
        return result && result == 'true' ? true : false;
      });
    }
    return isVerified;
  }


  openLink() {
    // Sample URL define in environment
    //airbnb_auth_url: https://secure.maximojo.com/mantrasdirect-stag/mantrasdirect/authorize?domainId={domainId}&channelId={channelId}
    if (this.appConfig.appConfig.airbnb_auth_url) {
      let url = this.appConfig.appConfig.airbnb_auth_url;
      let sysChannel = this.systemChannels.find(sys => sys.ChannelCode === this.channel.ChannelCode);
      url = url.replace("{domainId}", encodeURIComponent(this.channel.DomainId));
      url = url.replace("{channelId}", encodeURIComponent(this.channel.Id));
      window.open(url, '_blank');
    }
    else if (environment.airbnb_auth_url) {
      let url = environment.airbnb_auth_url;
      let sysChannel = this.systemChannels.find(sys => sys.ChannelCode === this.channel.ChannelCode);
      url = url.replace("{domainId}", encodeURIComponent(this.channel.DomainId));
      url = url.replace("{channelId}", encodeURIComponent(this.channel.Id));
      window.open(url, '_blank');
    }
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true));
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  showPassword(inputField, button) {
    if (inputField.type == "password") {
      inputField.type = "text";
    } else {
      inputField.type = "password";
    }
  }
  onChangeChannelType(event) {
    let channelType = event.value;
    this.showXmlCreds = Utilities.doesChannelSupportXmlCreds(channelType);
  }

  channelHotelMappings: Mapping[];
  showMappingDialog = false;
  domainHotels = [];

  async showMapping() {
    var domains = this.storageService.get(StorageService.userDomainContext).Domains
    var domain = Utilities.findObject(domains, this.channel.DomainId);
    var hotelIds = [];
    this.channelHotelMappings = new Array<Mapping>();
    this.domainHotels = [{ label: "None", value: null }];
    if (domain && this.hotelNumber) {
      domain.DomainHotels.forEach(domainHotel => {
        this.domainHotels.push({ label: domainHotel.Name, value: domainHotel.Id });
        hotelIds.push(domainHotel.Id);
      });
      let channelIds = this.hotelNumber.split(",");
      let hotelMappings = await this.commonService.GetHotelMapping(this.channel.ChannelCode, channelIds);
      channelIds.forEach(cId => {
        let hotelMapping = hotelMappings.find(hm => hm.ChannelHotelId == cId);
        let hotelId = null
        if (hotelMapping) hotelId = hotelMapping.HotelId;
        var m = new Mapping();
        m.ChannelHotelId = cId; m.HotelId = hotelId;
        this.channelHotelMappings.push(m);
      });
      this.showMappingDialog = true;
    }
  }

  closeDialog(e) {
    this.showMappingDialog = false;
  }

  async SaveMapping(e) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to save the mapping?',
      accept: async () => {
        var mappings = [];
        this.channelHotelMappings.forEach(async chm => {
          if (chm.HotelId == null) {
            //await this.commonService.DeleteMapping(null, this.channel.ChannelCode, chm.ChannelHotelId);
          } else {
            var hotelMapping = new HotelMapping();
            hotelMapping.ChannelCode = this.channel.ChannelCode;
            hotelMapping.ChannelHotelId = chm.ChannelHotelId;
            hotelMapping.HotelId = chm.HotelId;
            mappings.push(hotelMapping);
          }
        });
        if (mappings.length > 0) {
          await this.commonService.SaveMapping(mappings);
        }
        this.showMappingDialog = false;
      },
      reject: () => {
        //Do Nothing
      }
    });
  }
}
export class Mapping {
  ChannelHotelId;
  HotelId;
}
