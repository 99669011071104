import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { RoomTypeDetail, RoomRatePlanDetail, LinkedValues } from 'mantras-api';
import { HotelManagementService } from '../../ui-services/hotelmanagement.service';
import { StorageService } from '../../ui-services/storage.service';
import { RoomRate } from 'mantras-api';
import { Utilities } from '../../ui-services/utilities';
import * as jQuery from 'jquery';
import { UserService } from '../../ui-services/user.service';

@Component({
  selector: 'roomtypedetails',
  templateUrl: './roomtypedetails.component.html',
  styleUrls: ['./roomtypedetails.component.css']
})
export class RoomtypedetailsComponent implements OnInit {

  @Input() roomType: RoomTypeDetail = new RoomTypeDetail();
  @Input() roomTypeList: any = [];
  @Output('onClose') onClose = new EventEmitter<boolean>();
  isReadOnly = false;
  valueTypes = [{ label: "Amount", value: 0 }, { label: "Percent", value: 1 }];
  occLinkingValue = [{ label: "Amount", value: 0 }, { label: "Percent", value: 1 }];
  errorMsg = "";
  roomtypeMenuItems = [];
  hotelContext;
  activeRatePlans = [];
  inActiveRatePlans = [];
  roomTypes = [];
  selectAllRrps = false;
  sortOrder: number = 0;
  roomRatePlans: RoomRatePlanDetail[];
  simplifiedRrps: SimplifiedRrp[] = [];
  showOccupancyLinkingDialog = false;
  occupancies = [];
  rrp: SimplifiedRrp;
  domainId: string;
  ChannelTypeList = ['ANB','BDC']; 
  allChanelTypes = [];
  syncChannelType = [];
  @Output() dataEmitter : EventEmitter<any> = new EventEmitter();
  constructor(private storageService: StorageService, private hotelMgmtService: HotelManagementService,private userService: UserService) {
    this.isReadOnly = userService.isReadOnly();
    this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    this.domainId = this.storageService.get(StorageService.userDomainContext).CurrentDomainId;
    this.roomTypes = [];
  }

  ngOnInit() {
    this.roomtypeMenuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', disabled: this.isReadOnly, command: (event) => this.onSave() },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }];
      this.ChannelTypeList.forEach(channel => {
        this.allChanelTypes.push({ value: channel, label: Utilities.findId(Utilities.channelTypes, channel, channel) });
      });
  }

  async loadData() {
    this.roomTypes.push({ label: "None", value: null });
    if (this.roomTypeList) {
      this.roomTypeList.forEach(rT => {
        if (this.roomType.Id != rT.Id)
          this.roomTypes.push({ label: rT.Name, value: rT.Id });
      });
    }
    if (!this.roomType.BaseOccupancy) this.roomType.BaseOccupancy = 1
    if (!this.roomType.DefaultAvailability) this.roomType.DefaultAvailability = 0;
    if (!this.roomType.Id) {
      this.roomType.Id = Utilities.generateGUID();
      this.roomType.IsActive = true;
    }
    if (!this.roomType.MaxOccupancy || this.roomType.MaxOccupancy == 0) this.roomType.MaxOccupancy = 2;
    if (!this.roomType.ChannelCode) this.roomType.ChannelCode = "MAX";
    this.occupancies = Array.from(Array(this.roomType.MaxOccupancy - this.roomType.BaseOccupancy), (x, i) => i + this.roomType.BaseOccupancy);
    this.hotelMgmtService.findRatePlans(this.hotelContext.HotelId).then(ratePlans => {
      this.activeRatePlans = [];
      this.inActiveRatePlans = [];
      ratePlans.forEach(ratePlan => {
        if (ratePlan.IsActive) this.activeRatePlans.push(ratePlan);
        else this.inActiveRatePlans.push(ratePlan);
      });
    });
    await this.hotelMgmtService.findRoomRatePlans(this.hotelContext.HotelId).then(resp => {
      this.roomRatePlans = resp;
    });
    if (this.roomType.Tags) {
      this.sortOrder = this.roomType.Tags["Display Order"];
    }
    if ((!this.roomType.Tags) || (!this.roomType.Tags["Display Order"])) {
      this.sortOrder = 0;
    }
    this.getMenuItemData();
    this.simplifyRrp();
    this.setHeight();
  }

  onCancel(event?) {
    this.onClose.emit(event);
  }

  onSave() {
    if (!this.roomType.Tags) this.roomType.Tags = new Map<string, string>();
    this.roomType.Tags["Display Order"]= this.sortOrder.toString();
    
    //Convert simplified(custom) rrp to RoomRatePlan.
    this.convertSimplifiedrrpToRrp();

    //add rack rate to roomtype object
    this.fillRackinRoom();

    if ((!this.roomType.Name) || (!this.roomType.Code) || (this.roomType.Name == "") || (this.roomType.Code == "")) {
      this.errorMsg = "Room Name or Room Code cannot be empty";
      return;
    }
    this.roomType.DomainId = this.domainId;
    this.hotelMgmtService.saveRoomTypes(this.hotelContext.HotelId, [this.roomType], "Roomtypes").then(result => { 
      this.hotelMgmtService.saveRoomRatePlan(this.hotelContext.HotelId, this.roomRatePlans);
      this.onCancel(true); 
    });
  }
  fillRackinRoom() {
    let rack = this.roomRatePlans.filter(r => r.Id === (this.roomType.Id + "_" + this.hotelContext.HotelId))[0];
    // if(!this.roomType.RackRate)
    //   this.roomType.RackRate = new RoomRate();
    if (rack) {
      this.roomType.RackRate = rack.DailyRate;
    }
  }

  convertSimplifiedrrpToRrp() {
    this.simplifiedRrps.forEach(srrp => {
      let syncChannelsStr:string="";
      let isRrpActive = this.roomRatePlans.filter(r => r.Id === srrp.Id);
      //if('SyncChannels' in srrp){ 
      if(srrp.SyncChannels){
        srrp.SyncChannels.forEach(channel => {
          syncChannelsStr+=channel+",";
        });
      }
      if(syncChannelsStr.length != 0) syncChannelsStr = syncChannelsStr.slice(0,-1);//Remove last character
      if (srrp.IsActive && isRrpActive.length == 0) {
        let newRrp = new RoomRatePlanDetail();
        newRrp.DailyRate = new RoomRate();
        newRrp.DailyRate.PerDay = srrp.PerDay;
        newRrp.DailyRate.PerExtraPerson = srrp.ExtraPerson;
        newRrp.DailyRate.ExtraChildren = new Object();
        newRrp.DailyRate.ExtraChildren[1] = srrp.ExtraChild;
        newRrp.DailyRate.PerOccupancy = new Object();
        if(srrp.Single && srrp.Single != null) newRrp.DailyRate.PerOccupancy[1] = srrp.Single;
        if(srrp.Double && srrp.Double != null) newRrp.DailyRate.PerOccupancy[2] = srrp.Double;
        if(srrp.Triple && srrp.Triple != null) newRrp.DailyRate.PerOccupancy[3] = srrp.Triple;
        if(srrp.Quad && srrp.Quad != null) newRrp.DailyRate.PerOccupancy[4] = srrp.Quad;
        if(srrp.Penta && srrp.Penta != null) newRrp.DailyRate.PerOccupancy[5] = srrp.Penta;
        if(srrp.Hexa && srrp.Hexa != null) newRrp.DailyRate.PerOccupancy[6] = srrp.Hexa;
        if(srrp.Hepta && srrp.Hepta != null) newRrp.DailyRate.PerOccupancy[7] = srrp.Hepta;
        if(srrp.Octa && srrp.Octa != null) newRrp.DailyRate.PerOccupancy[8] = srrp.Octa;
        if(srrp.Nona && srrp.Nona != null) newRrp.DailyRate.PerOccupancy[9] = srrp.Nona;
        if(srrp.Deca && srrp.Deca != null) newRrp.DailyRate.PerOccupancy[10] = srrp.Deca;
        
        newRrp.DomainId = this.domainId;
        newRrp.HotelId = this.hotelContext.HotelId;
        newRrp.Id = this.roomType.Id + "_" + srrp.RatePlanId;
        newRrp.OccupancyLinking = srrp.OccupancyLinking;
        newRrp.RatePlanId = srrp.RatePlanId;
        newRrp.RoomTypeId = this.roomType.Id;
        newRrp.ChannelCode = "MAX";
        if(newRrp.Tags == null || !newRrp.Tags) newRrp.Tags = {};
        newRrp.Tags["SyncRRP"]=syncChannelsStr;
        this.roomRatePlans.push(newRrp);
      }
      else if (srrp.IsActive && srrp.Id) {
        var requiredRrp = this.roomRatePlans.filter(r => r.Id == srrp.Id)[0];
        requiredRrp.DailyRate.PerDay = srrp.PerDay;
        requiredRrp.DailyRate.PerExtraPerson = srrp.ExtraPerson;
        requiredRrp.DailyRate.ExtraChildren = new Object();
        requiredRrp.DailyRate.ExtraChildren[1] = srrp.ExtraChild;
        //if (!requiredRrp.DailyRate.PerOccupancy) 
        requiredRrp.DailyRate.PerOccupancy = new Object();
        if(srrp.Single && srrp.Single != null) requiredRrp.DailyRate.PerOccupancy[1] = srrp.Single;
        if(srrp.Double && srrp.Double != null) requiredRrp.DailyRate.PerOccupancy[2] = srrp.Double;
        if(srrp.Triple && srrp.Triple != null) requiredRrp.DailyRate.PerOccupancy[3] = srrp.Triple;
        if(srrp.Quad && srrp.Quad != null) requiredRrp.DailyRate.PerOccupancy[4] = srrp.Quad;
        if(srrp.Penta && srrp.Penta != null) requiredRrp.DailyRate.PerOccupancy[5] = srrp.Penta;
        if(srrp.Hexa && srrp.Hexa != null) requiredRrp.DailyRate.PerOccupancy[6] = srrp.Hexa;
        if(srrp.Hepta && srrp.Hepta != null) requiredRrp.DailyRate.PerOccupancy[7] = srrp.Hepta;
        if(srrp.Octa && srrp.Octa != null) requiredRrp.DailyRate.PerOccupancy[8] = srrp.Hepta;
        if(srrp.Nona && srrp.Nona != null) requiredRrp.DailyRate.PerOccupancy[9] = srrp.Nona;
        if(srrp.Deca && srrp.Deca != null) requiredRrp.DailyRate.PerOccupancy[10] = srrp.Deca;
        requiredRrp.ChannelCode = "MAX";

        requiredRrp.DomainId = this.domainId;
        requiredRrp.HotelId = this.hotelContext.HotelId;
        requiredRrp.OccupancyLinking = srrp.OccupancyLinking
        requiredRrp.RatePlanId = srrp.RatePlanId;
        requiredRrp.RoomTypeId = this.roomType.Id;
        if(requiredRrp.Tags == null || !requiredRrp.Tags) requiredRrp.Tags = {};
        requiredRrp.Tags["SyncRRP"]=syncChannelsStr;
        this.roomRatePlans.filter(r => r.Id == srrp.Id)[0] = requiredRrp;
      }
      else if (!srrp.IsActive && this.roomRatePlans.filter(r => r.Id == srrp.Id)[0]) {
        let data = this.roomRatePlans.filter(r => r.Id == srrp.Id)[0];
        var index = this.roomRatePlans.indexOf(data);
        if (index !== -1) this.roomRatePlans.splice(index, 1);
        this.hotelMgmtService.deleteRoomRatePlan(this.hotelContext.HotelId, this.roomType.Id, srrp.RatePlanId);
      }
    });
  }
  simplifyRrp() {

    if (this.roomRatePlans) {
      this.simplifiedRrps = [];
      var roomId = this.roomType.Id;
      let roomRrps = this.roomRatePlans.filter(rrrp => rrrp.RoomTypeId === roomId);
      let rack = this.roomRatePlans.filter(rrp => rrp.RatePlanId === rrp.HotelId && rrp.RoomTypeId === roomId);
      if (!rack[0]) {
        let rackNew = new RoomRatePlanDetail();
        rackNew.RatePlanId = this.hotelContext.HotelId;
        rackNew.OccupancyLinking = new Map<number, LinkedValues>();
        rackNew.DailyRate = new RoomRate();
        rackNew.RoomTypeId = this.roomType.Id;
        rackNew.HotelId = this.hotelContext.HotelId;
        rackNew.Id = this.roomType.Id + "_" + this.hotelContext.HotelId;
        rackNew.OccupancyLinking = new Map<number, LinkedValues>();
        rack[0] = rackNew;
        this.roomRatePlans.push(rackNew);

      }
      this.activeRatePlans.forEach(arp => {
        var roomRp = roomRrps.filter(rrrp => rrrp.RatePlanId == arp.Id);
        if (roomRp.length > 0) {
          var sRrp = new SimplifiedRrp();
          sRrp.RatePlanName = arp.Name;
          sRrp.RatePlanId = arp.Id;
          sRrp.PerDay = roomRp[0].DailyRate.PerDay;
          sRrp.Single = (roomRp[0].DailyRate.PerOccupancy && roomRp[0].DailyRate.PerOccupancy[1])?roomRp[0].DailyRate.PerOccupancy[1]:undefined;
          sRrp.Double = (roomRp[0].DailyRate.PerOccupancy && roomRp[0].DailyRate.PerOccupancy[2])?roomRp[0].DailyRate.PerOccupancy[2]:undefined;
          sRrp.Triple = (roomRp[0].DailyRate.PerOccupancy && roomRp[0].DailyRate.PerOccupancy[3])?roomRp[0].DailyRate.PerOccupancy[3]:undefined;
          sRrp.Quad = (roomRp[0].DailyRate.PerOccupancy && roomRp[0].DailyRate.PerOccupancy[4])?roomRp[0].DailyRate.PerOccupancy[4]:undefined;
          sRrp.Penta = (roomRp[0].DailyRate.PerOccupancy && roomRp[0].DailyRate.PerOccupancy[5])?roomRp[0].DailyRate.PerOccupancy[5]:undefined;
          sRrp.Hexa = (roomRp[0].DailyRate.PerOccupancy && roomRp[0].DailyRate.PerOccupancy[6])?roomRp[0].DailyRate.PerOccupancy[6]:undefined;
          sRrp.Hepta = (roomRp[0].DailyRate.PerOccupancy && roomRp[0].DailyRate.PerOccupancy[7])?roomRp[0].DailyRate.PerOccupancy[7]:undefined;
          sRrp.Octa = (roomRp[0].DailyRate.PerOccupancy && roomRp[0].DailyRate.PerOccupancy[8])?roomRp[0].DailyRate.PerOccupancy[8]:undefined;
          sRrp.Nona = (roomRp[0].DailyRate.PerOccupancy && roomRp[0].DailyRate.PerOccupancy[9])?roomRp[0].DailyRate.PerOccupancy[9]:undefined;
          sRrp.Deca = (roomRp[0].DailyRate.PerOccupancy && roomRp[0].DailyRate.PerOccupancy[10])?roomRp[0].DailyRate.PerOccupancy[10]:undefined;

          sRrp.ExtraPerson = roomRp[0].DailyRate.PerExtraPerson;
          if(roomRp[0].DailyRate.ExtraChildren)
            sRrp.ExtraChild = roomRp[0].DailyRate.ExtraChildren[1];
          sRrp.SyncChannels = roomRp[0].Tags?roomRp[0].Tags["SyncRRP"]?roomRp[0].Tags["SyncRRP"].toString().split(","):[]:[];
          if (roomRp[0].OccupancyLinking) {
            sRrp.IsDoubleLinked = (this.roomType.BaseOccupancy < 2 && this.roomType.MaxOccupancy >= 2 && roomRp[0].OccupancyLinking[2]) ? true : false;
            sRrp.IsTripleLinked = (this.roomType.BaseOccupancy < 3 && this.roomType.MaxOccupancy >= 3 && roomRp[0].OccupancyLinking[3]) ? true : false;
            sRrp.IsQuadLinked = (this.roomType.BaseOccupancy < 4 && this.roomType.MaxOccupancy >= 4 && roomRp[0].OccupancyLinking[4]) ? true : false;
            sRrp.IsPentaLinked = (this.roomType.BaseOccupancy < 5 && this.roomType.MaxOccupancy >= 5 && roomRp[0].OccupancyLinking[5]) ? true : false;
            sRrp.IsHexaLinked = (this.roomType.BaseOccupancy < 6 && this.roomType.MaxOccupancy >= 6 && roomRp[0].OccupancyLinking[6]) ? true : false;
            sRrp.IsHeptaLinked = (this.roomType.BaseOccupancy < 7 && this.roomType.MaxOccupancy >= 7 && roomRp[0].OccupancyLinking[7]) ? true : false;
            sRrp.IsOctaLinked = (this.roomType.BaseOccupancy < 8 && this.roomType.MaxOccupancy >= 8 && roomRp[0].OccupancyLinking[8]) ? true : false;
            sRrp.IsNonaLinked = (this.roomType.BaseOccupancy < 9 && this.roomType.MaxOccupancy >= 9 && roomRp[0].OccupancyLinking[9]) ? true : false;
            sRrp.IsDecaLinked = (this.roomType.BaseOccupancy < 10 && this.roomType.MaxOccupancy >= 10 && roomRp[0].OccupancyLinking[10]) ? true : false;

            sRrp.LinkingText = "";
            for (let key in roomRp[0].OccupancyLinking) {
              sRrp.LinkingText += key + " is linked to " + ((+key) - 1) + " by " + roomRp[0].OccupancyLinking[key].Value + "\n";
            }
          }
          sRrp.Id = roomRp[0].Id;
          sRrp.IsActive = true;
          sRrp.OccupancyLinking = roomRp[0].OccupancyLinking;
          sRrp.IsRatePlanLinked = arp.LinkedRatePlanId != null;
          this.simplifiedRrps.push(sRrp);
        }
        else {
          var sRrp = new SimplifiedRrp();
          sRrp.RatePlanName = arp.Name;
          sRrp.RatePlanId = arp.Id;
          sRrp.Id = roomId + "_" + arp.Id;
          if (!sRrp.OccupancyLinking) sRrp.OccupancyLinking = new Map<number, LinkedValues>();
          if (sRrp.RatePlanId == this.hotelContext.HotelId) sRrp.IsActive = true;
          if (rack[0] && rack[0].DailyRate && rack[0].DailyRate.PerOccupancy) {
            sRrp.PerDay = rack[0].DailyRate.PerDay;
            sRrp.Single = rack[0].DailyRate.PerOccupancy[1];
            sRrp.Double = rack[0].DailyRate.PerOccupancy[2];
            sRrp.Triple = rack[0].DailyRate.PerOccupancy[3];
            sRrp.Quad = rack[0].DailyRate.PerOccupancy[4];
            sRrp.Penta = rack[0].DailyRate.PerOccupancy[5];
            sRrp.Hexa = rack[0].DailyRate.PerOccupancy[6];
            sRrp.Hepta = rack[0].DailyRate.PerOccupancy[7];
            sRrp.Octa = rack[0].DailyRate.PerOccupancy[8];
            sRrp.Nona = rack[0].DailyRate.PerOccupancy[9];
            sRrp.Deca = rack[0].DailyRate.PerOccupancy[10];
            sRrp.ExtraPerson = rack[0].DailyRate.PerExtraPerson;
            if(rack[0].DailyRate.ExtraChildren)
            sRrp.ExtraChild = rack[0].DailyRate.ExtraChildren[1];
          }
          sRrp.LinkingText = "";
          this.simplifiedRrps.push(sRrp);
        }

      });
    }
    this.sortRrps()
  }
  OnselectAll() {
    this.simplifiedRrps.forEach(srrp => {
      srrp.IsActive = this.selectAllRrps;
    });
  }
  sortRrps() {
    let srrps = this.simplifiedRrps;
    let rack = this.simplifiedRrps.filter(r => r.RatePlanId === this.hotelContext.HotelId)[0];
    let sortedRrp: SimplifiedRrp[];
    sortedRrp = new Array();
    srrps.splice(srrps.indexOf(rack), 1);
    srrps.sort((a, b) => a.RatePlanName > b.RatePlanName ? -1 : a.RatePlanName < b.RatePlanName ? 1 : 0);
    srrps.push(rack);
    srrps.reverse();
    this.simplifiedRrps = srrps;

  }
  checkSelectAll(rrpId) {
    this.selectAllRrps = this.simplifiedRrps.every(function (srrp: any) {
      return srrp.IsActive == true;
    })

  }
  showOccLinking(event) {
    this.occupancies = Array.from(Array(this.roomType.MaxOccupancy - this.roomType.BaseOccupancy), (x, i) => i + this.roomType.BaseOccupancy);
    let roomRrps = this.simplifiedRrps.filter(rrrp => rrrp.Id === event);
    this.rrp = roomRrps[0];
    this.occupancies.forEach(occ => {
      if (!this.rrp.OccupancyLinking) this.rrp.OccupancyLinking = new Map<number, LinkedValues>();
      if (this.rrp.OccupancyLinking[occ + 1] == null)
        this.rrp.OccupancyLinking[occ + 1] = new LinkedValues();
      this.rrp.OccupancyLinking[occ + 1].Type = 0;
    });
    this.showOccupancyLinkingDialog = true;
  }

  onSetOccLinking() {
    for (let occ = this.roomType.BaseOccupancy; occ < this.roomType.MaxOccupancy; occ++) {
      if(!this.rrp) continue;
      if (!this.rrp.OccupancyLinking) this.rrp.OccupancyLinking = new Map<number, LinkedValues>();
      if (this.rrp.OccupancyLinking[occ + 1] == null || this.rrp.OccupancyLinking[occ + 1].Value == null) {
        {
          delete this.rrp.OccupancyLinking[occ + 1];
          if ((occ + 1) == 2) { this.rrp.IsDoubleLinked = false; }
          if ((occ + 1) == 3) { this.rrp.IsTripleLinked = false; }
          if ((occ + 1) == 4) { this.rrp.IsQuadLinked = false; }
          if ((occ + 1) == 5) { this.rrp.IsPentaLinked = false; }
          if ((occ + 1) == 6) { this.rrp.IsHexaLinked = false; }
          if ((occ + 1) == 7) { this.rrp.IsHeptaLinked = false; }
          if ((occ + 1) == 8) { this.rrp.IsOctaLinked = false; }
          if ((occ + 1) == 9) { this.rrp.IsNonaLinked = false; }
          if ((occ + 1) == 10) { this.rrp.IsDecaLinked = false; }

        }
      }
      else {
        if ((occ + 1) == 2) { this.rrp.Double = this.rrp.Single + this.rrp.OccupancyLinking[occ + 1].Value; this.rrp.IsDoubleLinked = true; }
        if ((occ + 1) == 3) { this.rrp.Triple = this.rrp.Double + this.rrp.OccupancyLinking[occ + 1].Value; this.rrp.IsTripleLinked = true; }
        if ((occ + 1) == 4) { this.rrp.Quad = this.rrp.Triple + this.rrp.OccupancyLinking[occ + 1].Value; this.rrp.IsQuadLinked = true; }
        if ((occ + 1) == 5) { this.rrp.Penta = this.rrp.Quad + this.rrp.OccupancyLinking[occ + 1].Value; this.rrp.IsPentaLinked = true; }
        if ((occ + 1) == 6) { this.rrp.Hexa = this.rrp.Penta + this.rrp.OccupancyLinking[occ + 1].Value; this.rrp.IsHexaLinked = true; }
        if ((occ + 1) == 7) { this.rrp.Hepta = this.rrp.Hexa + this.rrp.OccupancyLinking[occ + 1].Value; this.rrp.IsHeptaLinked = true; }
        if ((occ + 1) == 8) { this.rrp.Octa = this.rrp.Hepta + this.rrp.OccupancyLinking[occ + 1].Value; this.rrp.IsOctaLinked = true; }
        if ((occ + 1) == 9) { this.rrp.Nona = this.rrp.Octa + this.rrp.OccupancyLinking[occ + 1].Value; this.rrp.IsNonaLinked = true; }
        if ((occ + 1) == 10) { this.rrp.Deca = this.rrp.Nona + this.rrp.OccupancyLinking[occ + 1].Value; this.rrp.IsDecaLinked = true; }


      }
    }
    this.simplifiedRrps.filter(rrrp => rrrp.Id === this.rrp.Id)[0] = this.rrp;
    this.showOccupancyLinkingDialog = false;
  }
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.roomTypeDetailsSection').css('height', Utilities.getScrollHeight(true,true,false,false));
  }

  getMenuItemData(){
    this.roomtypeMenuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', disabled: this.isReadOnly, command: (event) => this.onSave() },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }];
      let menuData = [];
      menuData.push({'source':'roomTypesDetail'});
      menuData.push(this.roomtypeMenuItems);
      this.dataEmitter.emit(menuData);
  }

  BaseOMaxOChange(){
    //update rrplist
    this.occupancies = Array.from(Array(this.roomType.MaxOccupancy - this.roomType.BaseOccupancy), (x, i) => i + this.roomType.BaseOccupancy);
    this.simplifyRrp();
    this.onSetOccLinking();
  }

  UpdateRRPList(){
    //Update the simplified RRPs occupancyLinking set flag.
    if(this.roomType.LinkedRoomId && this.roomType.LinkedRoomId != null){
      let linkedRoom = this.roomTypes.find(rt => rt.Id == this.roomType.LinkedRoomId);
      if(linkedRoom){
        //Update the SimplifiedRRP Object
      }
    }
  }

  onRateChange(activeRRP,occIndex){
    if(activeRRP){
      while(occIndex < this.roomType.MaxOccupancy)
      {
        let occLink = activeRRP.OccupancyLinking[++occIndex];
        if(occLink){
          switch(occIndex){
            case 2: activeRRP.Double = activeRRP.Single + occLink.Value; break;
            case 3: activeRRP.Triple = activeRRP.Double + occLink.Value; break;
            case 4: activeRRP.Quad = activeRRP.Triple + occLink.Value; break;
            case 5: activeRRP.Penta = activeRRP.Quad + occLink.Value; break;
            case 6: activeRRP.Hexa = activeRRP.Penta + occLink.Value; break;
            case 7: activeRRP.Hepta = activeRRP.Hexa + occLink.Value; break;
            case 8: activeRRP.Octa = activeRRP.Hepta + occLink.Value; break;
            case 9: activeRRP.Nona = activeRRP.Octa + occLink.Value; break;
            case 10: activeRRP.Deca = activeRRP.Nona + occLink.Value; break;

          }
        }
      }
      //Check Occupancy Linkage set.
    }
  }

}
class SimplifiedRrp {
  RatePlanName: string;
  PerDay: number;
  ExtraPerson: number;
  ExtraChild: number;
  Single: number;
  Double: number;
  Triple: number;
  Quad: number;
  Penta: number;
  Hexa: number;
  Hepta: number;
  Octa: number;
  Nona: number;
  Deca: number;
  IsActive: boolean;
  IsDoubleLinked: boolean;
  IsTripleLinked: boolean;
  IsQuadLinked: boolean;
  IsPentaLinked: boolean;
  IsHexaLinked: boolean;
  IsHeptaLinked: boolean;
  IsOctaLinked: boolean;
  IsNonaLinked: boolean;
  IsDecaLinked: boolean;
  IsRatePlanLinked: boolean;
  LinkingText: string;
  RoomTypeId: string;
  RatePlanId: string;
  Id: string;
  SyncChannels: Array<string>;
  OccupancyLinking: Map<number, LinkedValues>
}

