import { Injectable } from '@angular/core';
import { AppService, ErrorInfo } from 'mantras-api';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  constructor(private storageService: StorageService, public mantras: AppService) {

  }
  logError(message: string, stack: string ,errorType : string) {
    // Send errors to be saved here
    // The console.log is only for testing this example.
    let currentTime = new Date();
    let currentUser = this.storageService.get(StorageService.currentUser);
    if(!currentUser) return;
    let sessionId = this.storageService.get(StorageService.currentUser).Id;
    let domainId = currentUser.DomainContext.CurrentDomainId;
    let loginUserContext = this.storageService.get(StorageService.loggedInUser);
    if (domainId != "System") {
      var hotelId = loginUserContext.HotelContext.HotelId;
    }
    else {
      hotelId = null;
    }

    // console.log('Time' + currentTime);
    // console.log('User Id', currentUser.User.Id);
    // console.log('Session Id', sessionId);
    // console.log('Domain Id', domainId);
    // console.log('Hotel Id', hotelId);
    // console.log('Type of error');
    //  console.log('Error Message: ' + message);
    //  console.log('Error Message Stack: ' + stack);
     

    var errorInfo = new ErrorInfo();
    errorInfo.ErrorTime = currentTime;
    errorInfo.UserId = currentUser.User.Id;
    errorInfo.SessionId = sessionId;
    errorInfo.DomainId = domainId;
    errorInfo.HotelId = hotelId;
    errorInfo.ErrorType = errorType;
    errorInfo.ErrorMessage = message;
    errorInfo.ErrorDetails = stack;

    let errorInfoArray = [];
    errorInfoArray.push(errorInfo)


    this.mantras.SendErrorLogs(errorInfoArray);


  }
}