import { Component, OnInit, ViewChild, ViewContainerRef, HostListener, ComponentFactoryResolver } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/primeng';
import { GridOptions } from 'ag-grid';
import { DomainProfileCriteria, DomainProfile, DomainStatus, HotelCriteria, HotelInfo, HotelSearchCriteria, DomainHotel, ContactInfo } from 'mantras-api';
import { AuthenticationService } from '../ui-services/authentication.service';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { Router } from '@angular/router';
import { StorageService } from '../ui-services/storage.service';
import { DomainsDetailsComponent } from '../domains/domainsdetails.component';
import { Subscription } from 'rxjs';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';
import { Response } from '@angular/http/src/static_response';
import { UserService } from '../ui-services/user.service';
import { HotelManagementService } from '../ui-services/hotelmanagement.service';
import { HeaderComponent } from '../components/header/header.component';

@Component({
  selector: 'app-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.css']
})
export class DomainsComponent implements OnInit {
  selectedRow: {};
  activationFlag: boolean;
  domainProfile: any;
  subscription: Subscription;
  selectedDomainHeader: any;
  currentUser: any;
  filteredDomains;
  domainsMenuItems: MenuItem[];
  columnDefs: any[];
  icons;
  rowGroupPanelShow: any;
  rowData: any[];
  rowSelection: any;
  gridOptions: GridOptions;
  isDetailsPage = false;
  selectedDomains: DomainProfile;
  domainCriteria: DomainProfileCriteria;
  domainStatus: DomainStatus;
  isDomainsFound = false;

  constructor(private authService: AuthenticationService, private userService: UserService, private router: Router,
    private storageService: StorageService, private resolver: ComponentFactoryResolver,
    private hotelMgmtService: HotelManagementService, private messageService: MessageService) {
    this.columnDefs = [
      { headerName: "Name", field: "Name", headerTooltip: "", cellClass: 'gridcol', showRowGroup: true, width: 200, menuTabs: [], sort: 'asc' },
      { headerName: "Licenses", field: "Preferences.Licences", headerTooltip: "Licenses", width: 200, enableRowGroup: true, menuTabs: [], tooltipField: "Hotel", cellClass: 'gridcol' },
      { headerName: "Vendor", field: "Preferences.Vendor", headerTooltip: "Vendor", width: 80, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Is Active", field: "Status", headerTooltip: "Status", width: 70, menuTabs: [], cellClass: 'gridcol', valueFormatter: Status },
    ];
    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    //this.rowGroupPanelShow = "always";
    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      localeText: { noRowsToShow: "No domain(s) found" },
      sortingOrder: ["desc", "asc"]
    };
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });

    function Status(params) {
      return DomainStatus[params.data.Status];
    }
  }

  ngOnInit() {
    this.loadMenu();
  }

  loadMenu() {
    this.domainsMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', command: (event) => this.createNew(event) },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', disabled: this.listLength == 0, command: (event) => this.open(event) },
      { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', visible: this.userService.checkAccess("Admins"), command: (event) => this.confirmDelete(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
      { title: 'Deactivate', label: 'Deactivate', icon: 'action-bar-menu-icon icon fa fa-random', command: (event) => this.deactivate(event) },
      { title: 'Add Property', label: 'Add Property', icon: 'action-bar-menu-icon icon fa fa-plus-square-o', disabled: this.listLength == 0, command: (event) => this.onAddPropery(event) },
      { title: 'Manage Property', label: 'Manage Property', icon: 'action-bar-menu-icon icon fa fa-folder-open-o', disabled: this.listLength == 0, command: (event) => this.manageProperty(event) }
    ];
  }

  onRowClick(params) {
    true
    if (params.data) {
      if (params.data.Status == 3) {
        this.activationFlag = false;
      }
      else {
        this.activationFlag = true;
      }
      //let disabledDelete = false;
      // if (params.data.Id && params.data.Id.toLowerCase() == this.storageService.get(StorageService.loginUserId)) disabledDelete = true;
      this.domainsMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
        { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', command: (event) => this.createNew() },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
        { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', visible: this.userService.checkAccess("Admins"), command: (event) => this.confirmDelete(event, true) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
        { title: 'Deactivate', label: 'Deactivate', icon: 'action-bar-menu-icon fa fa-random', visible: !this.activationFlag, command: (event) => this.deactivate(event) },
        { title: 'Activate', label: 'Activate', icon: 'action-bar-menu-icon icon fa fa-random', visible: this.activationFlag, command: (event) => this.activate(event) },
        { title: 'Add Property', label: 'Add Property', icon: 'action-bar-menu-icon icon fa fa-plus-square-o', command: (event) => this.onAddPropery(event) },
        { title: 'Manage Property', label: 'Manage Property', icon: 'action-bar-menu-icon icon fa fa-folder-open-o', command: (event) => this.manageProperty(event) }
      ];
    }
  }

  onSearch(event) {
    this.onSearchCriteria(event);
  }

  oldCriteria;
  listLength = 0;
  searchList;
  @ViewChild('domainscriteria', { read: ViewContainerRef }) domainscriteria: ViewContainerRef;
  async onSearchCriteria(criteria?: DomainProfileCriteria) {
    if (!criteria) {
      this.filteredDomains = [];
      let domainCriteria = new DomainProfileCriteria();
      this.filteredDomains = await this.authService.findDomainProfiles(domainCriteria).then(domainList => {
        this.listLength = domainList.length;
        this.searchList = domainList;
        this.gridOptions.api.setRowData(domainList);
        this.gridOptions.api.sizeColumnsToFit();
        this.onGridReady(this);
        this.setHeight();
       // if (domainList.length > 1) { this.showCriteria = false; }
      });
    } else {
      this.oldCriteria = criteria;
      this.domainProfile = await this.authService.findDomainProfiles(criteria).then(domainList => {
        this.listLength = domainList.length;
        this.searchList = domainList;
        this.gridOptions.api.setRowData(domainList);
        this.gridOptions.api.sizeColumnsToFit();
        this.onGridReady(this);
        this.setHeight();
       // if (domainList.length > 1) { this.showCriteria = false; }
      });
    }
    this.loadMenu();
  }

  onRefresh(event) {
    this.onSearchCriteria(event);
  }

  //Create New Domain
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  detailComponent;
  isNew = false;
  createNew(data?) {
    this.container.clear();
    let factory = this.resolver.resolveComponentFactory(DomainsDetailsComponent);
    this.detailComponent = <DomainsDetailsComponent>this.container.createComponent(factory).instance;
    if (data && data.Id) {
      this.detailComponent.domainProfile = data;
      this.isNew = false;
    } else {
      this.detailComponent.domainProfile = new DomainProfile();
      this.detailComponent.domainProfile.AdminContact = [];
      this.isNew = true;
    }
    this.detailComponent.loadData(data.Id);
    this.detailComponent.onClose.subscribe(this.onDetailsClose);
    this.isDetailsPage = true;
    this.detailComponent.setHeight();
  }

  onDetailsClose = async (event) => {
    this.isDetailsPage = false;
  }

  //open Channel
  open($event) {
    this.createNew(this.gridOptions.api.getSelectedRows()[0]);
  }

  //TODO
  async delete(event) {
    this.showDeleteConfirmation = false;
    if (this.deleteId != null) {
      await this.authService.deleteDomain(this.deleteId);
    }
    this.onRefresh(event);
  }

  @ViewChild('showConfirmationDialog') showConfirmationDialog;
  showDeleteConfirmation = false;
  deleteId;
  confirmDelete(params, fromGrid?) {
    if (!fromGrid) {
      if (params.node.data) {
        this.deleteId = params.node.data.Id;
        this.showDeleteConfirmation = true;
      }
    } else {
      this.selectedRow = this.gridOptions.api.getSelectedRows();
      if (this.selectedRow) {
        this.deleteId = this.selectedRow[0].Id;
        this.showDeleteConfirmation = true;
      }
    }
  }
  closeDialog(event) {
    this.deleteId = null;
    this.showDeleteConfirmation = false;
    this.setHeight();
  }


  onClose() {
    this.router.navigate(["/settings"]);
  }

  async deactivate(event) {
    this.selectedRow = this.gridOptions.api.getSelectedRows();
    this.selectedRow[0].Status = 4;
    await this.authService.saveDomain(this.selectedRow[0]);
    this.onRefresh(this.selectedRow[0]);
    this.activationFlag = true;
  }

  async activate(event) {
    this.selectedRow = this.gridOptions.api.getSelectedRows();
    this.selectedRow[0].Status = 3;
    await this.authService.saveDomain(this.selectedRow[0]);
    this.onRefresh(this.selectedRow[0]);
    this.activationFlag = false;
  }

  //#region add property
  hotels = [];
  isAddButtonDisabled = true;
  criteria = new HotelCriteria();
  dialogGridOptionsAP = <GridOptions>{
    enableSorting: true,
    animateRows: true,
    groupDefaultExpanded: -1,
    rowHeight: 24,
    suppressDragLeaveHidesColumns: true,
    localeText: { noRowsToShow: "No Property Found." },
    suppressContextMenu: true,
  };

  showAddCompDialog = false;
  selectedDomain; addPropertyHeader
  async onAddPropery(params) {
    this.hotels = [];
    this.selectedDomain = this.gridOptions.api.getSelectedRows()[0];
    this.addPropertyHeader = "Add Property to" + " " + this.selectedDomain.Name;
    this.dialogGridOptionsAP.api.setColumnDefs(this.addPropertyDialogColumnDef());
    this.dialogGridOptionsAP.api.setRowData(this.hotels);
    this.showAddCompDialog = true;
    this.onDialogGridReady(null);
  }

  addPropertyDialogColumnDef() {
    let promoColDef = new Array();
    promoColDef.push({ headerName: "Property Name", cellClass: 'gridcol', width: 375, tooltipField: "Name", field: "Name", menuTabs: [] });
    promoColDef.push({ headerName: "Street", cellClass: 'gridcol', width: 375, tooltipField: "Street", field: "Address.Street", menuTabs: [] });
    promoColDef.push({ headerName: "City", cellClass: 'gridcol', width: 375, tooltipField: "City", field: "Address.City", menuTabs: [] });
    promoColDef.push({ headerName: "Country", cellClass: 'gridcol', width: 375, tooltipField: "Country", field: "Address.Country", menuTabs: [] });
    return promoColDef;
  }

  competitors = [];
  async onSearchProperty() {
    let hotelInfo;
    if (this.criteria.City || this.criteria.FreeText)
      hotelInfo = await this.hotelMgmtService.findHotels(this.criteria, true);
    if (hotelInfo) {
      this.hotels = [];
      hotelInfo.forEach(element => {
        if (!this.competitors.includes(element.Id)) {
          this.hotels.push(element)
        }
      });
    }

    this.dialogGridOptionsAP.api.setColumnDefs(this.addPropertyDialogColumnDef());
    this.dialogGridOptionsAP.api.setRowData(this.hotels);
    this.onDialogGridReady(null);
  }
  onGridSelectionChanged() {
    var selectedRows = this.dialogGridOptionsAP.api.getSelectedRows();
    this.isAddButtonDisabled = selectedRows.length === 0;
  }
  onDialogGridReady(event) {
    this.dialogGridOptionsAP.api.sizeColumnsToFit();
  }

  onPromoLogsGridSizeChanged(evnet) {
    this.dialogGridOptionsAP.api.sizeColumnsToFit();
  }

  domainHotel = new DomainHotel();
  async saveCompetitor(event) {
    let properties = [];
    this.domainHotel = new DomainHotel();
    let selectedHotel = this.dialogGridOptionsAP.api.getSelectedRows()[0];
    this.domainHotel.DomainId = this.selectedDomain.Id;
    this.domainHotel.HotelId = selectedHotel.Id;
    let curr = Utilities.currencies.find(cur => cur.Country === selectedHotel.Address.Country);
    if (curr) { this.domainHotel.Currency = curr.Code; }
    else { this.domainHotel.Currency = "INR"; }
    this.domainHotel.OutOfSyncThreshold = 0;
    this.domainHotel.MinRate = 0;
    this.domainHotel.MaxRate = 0;
    this.domainHotel.IsInventoryAtRatePlan = false;
    this.domainHotel.IsCloseOutAtRatePlan = false;
    this.domainHotel.PricingModel = 0;
    this.domainHotel.RateType = 1;
    this.domainHotel.TaxType = 0;
    this.domainHotel.IsCIPEnabled = true;
    this.domainHotel.PushBookingToPMS = false;
    this.domainHotel.CIPBookingList = ["Confirmed", "Modified"];
    this.domainHotel.DisabledAutoSyncChannels = [];
    this.domainHotel.PMSMailSetting = [""];
    this.domainHotel.CompetitorHotels = [];
    this.domainHotel.AdminContact = new ContactInfo();
    this.domainHotel.AdminContact['Email'] = null;
    this.domainHotel.BillingContact = null;
    this.domainHotel.BlockingDate = null;
    this.domainHotel.tags = { "BkgStatusForInvChg": "Confirmed,Modified" };

    await this.hotelMgmtService.saveDomainHotel(this.domainHotel).then(resp => {
      // this.authService.switchDomain("System","System");
      let factory = this.resolver.resolveComponentFactory(HeaderComponent);
      let headerComponent = <HeaderComponent>this.container.createComponent(factory).instance;
      headerComponent.populateHotelList();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: "Property Added Successfully." });
    }).catch(error => {
      this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'error', detail: "You don't have access to delete. Please contact property Admin." });
    }),
      this.showAddCompDialog = false;
  }
  //#endregion

  //#region Manage Property
  dialogGridOptionsMP = <GridOptions>{
    enableSorting: true,
    animateRows: true,
    groupDefaultExpanded: -1,
    rowHeight: 24,
    suppressDragLeaveHidesColumns: true,
    localeText: { noRowsToShow: "No Property Found." },
    suppressContextMenu: true,
  };

  managePropertyDialogColumnDef() {
    let promoColDef = new Array();
    promoColDef.push({ headerName: "Property Name", cellClass: 'gridcol', width: 375, tooltipField: "Name", field: "Name", menuTabs: [] });
    promoColDef.push({ headerName: "Street", cellClass: 'gridcol', width: 375, tooltipField: "Street", field: "Address.Street", menuTabs: [] });
    promoColDef.push({ headerName: "City", cellClass: 'gridcol', width: 375, tooltipField: "City", field: "Address.City", menuTabs: [] });
    promoColDef.push({ headerName: "Country", cellClass: 'gridcol', width: 375, tooltipField: "Country", field: "Address.Country", menuTabs: [] });
    return promoColDef;
  }

  managePropertyDetails = false;
  managePropertyHeader;
  managedHotels = [];
  async manageProperty(event) {
    this.managedHotels = [];
    let hotelCriteria = new HotelSearchCriteria();
    let compPropDetails = this.gridOptions.api.getSelectedRows()[0];
    hotelCriteria.DomainIds = [compPropDetails.Id];
    let hotelInfoDetails = await this.authService.findHotelInfo(hotelCriteria);
    if (hotelInfoDetails) {
      hotelInfoDetails.forEach(element => {
        this.managedHotels.push(element);
      });
    }
    this.managePropertyHeader = "Manage Property of" + " " + compPropDetails.Name + " "+ "("+hotelInfoDetails.length + ")";
    this.dialogGridOptionsMP.api.setColumnDefs(this.managePropertyDialogColumnDef());
    this.managePropertyDetails = true;
    this.dialogGridOptionsMP.api.setRowData(this.managedHotels);
    this.onDialogGridReady(null);
  }

  onGridSelectionChangedMP() {
    var selectedRows = this.dialogGridOptionsMP.api.getSelectedRows();
    this.isAddButtonDisabled = selectedRows.length === 0;
  }
  onDialogGridReadyMP(event) {
    this.dialogGridOptionsMP.api.sizeColumnsToFit();
  }

  onPromoLogsGridSizeChangedMP(evnet) {
    this.dialogGridOptionsMP.api.sizeColumnsToFit();
  }

  async deleteProperty() {
    var selectedRow = this.dialogGridOptionsMP.api.getSelectedRows()[0];
    //let hotelId = JSON.stringify(selectedRow.Id);
    await this.hotelMgmtService.deleteDomainHotel(selectedRow.DomainId,selectedRow.Id).then(res => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: "Hotel Deleted Successfully" });
      var hl = this.managedHotels.find(h=> h.Id === selectedRow.Id);
      var index = this.managedHotels.indexOf(hl);
      this.managedHotels.splice(index,1);
      this.dialogGridOptionsMP.api.setRowData(this.managedHotels);
    }).catch(error => {
      this.messageService.add({ key: 'error', severity: 'error', life: 5000, summary: 'error', detail: "You don't have access to delete hotel. Please contact property Admin." });
    });
  }


  //#endregion Manage Property 

  onClickGridRow(params) {
    if (params.data)
      this.createNew(params.data);
  }

  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    //this.rowGroupPanelShow = "always";
    //this.isDetailsPage = false;
    this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));
    this.gridOptions.suppressDragLeaveHidesColumns = true;
    //this.setHeight();
  }
  onGridSizeChanged($event) {
    this.gridOptions.api.sizeColumnsToFit();
    //this.rowGroupPanelShow = "always";
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true));
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showCriteria = true;
  hideShowCriteria() {
    this.showCriteria = !this.showCriteria;
    this.setHeight();
  }

  filteredResponse;
  searchText(event) {
    this.filteredResponse = [];
    var query = event.target.value.toLowerCase();
    let searchItems = this.searchList;
    if (query == "") this.filteredResponse = searchItems;
    else {
      searchItems.forEach(searchItem => {
        let isMatch = false;
        let searchString = JSON.stringify(searchItem);
        isMatch = searchString.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        if (isMatch) this.filteredResponse.push(searchItem);
      });
    }
    this.gridOptions.api.setRowData(this.filteredResponse);
    this.gridOptions.api.sizeColumnsToFit();
    this.onGridReady(this);
    this.setHeight();
  }

}
