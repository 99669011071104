import { Component, OnInit, HostListener, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { StorageService } from '../ui-services/storage.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/primeng';
import { GridOptions } from 'ag-grid';
import { Subscription } from 'rxjs';
import { HotelManagementService } from '../ui-services/hotelmanagement.service';
import { DomainchanneldetailsComponent } from './domainchanneldetails.component';
import { RoomTypeDetail, Channel } from 'mantras-api';
import { Utilities } from '../ui-services/utilities';

import * as jQuery from 'jquery';
import { UserService } from '../ui-services/user.service';
import { LoadingService } from '../ui-services/loading.service';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';
import { TaskbarService } from '../ui-services/taskbar.service';

@Component({
  selector: 'domainchannels',
  templateUrl: './domainchannels.component.html',
  styleUrls: ['./domainchannels.component.css']
})
export class DomainchannelsComponent implements OnInit {

  hotelchannelsMenuItems: MenuItem[];
  columnDefs: any[];
  rowData: any[];
  gridOptions: GridOptions;
  rowSelection: any;
  rowGroupPanelShow: any;
  domainChannels;
  isSyncDisabled = false;

  showDetailPage = false;
  icons;
  isReadOnly = false;
  subscription: Subscription;
  hotelContext;
  domainContext;
  taskCompleteNotificationSubscription: Subscription;
  constructor(private resolver: ComponentFactoryResolver, private storageService: StorageService, private router: Router,
    private hotelMgmtService: HotelManagementService,private userService:UserService, private taskbarService:TaskbarService) {
      this.isReadOnly = userService.isReadOnly();
    this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    this.columnDefs = [
      { headerName: "Alias", field: "Name", tooltipField: "Name", width: 120, menuTabs: [], cellClass: 'gridcol', },
      { headerName: "Name", field: "ChannelCode", tooltipField: "ChannelCode", width: 120, menuTabs: [], cellClass: 'gridcol', valueFormatter: channelTypeFormatter,sort: "asc" },
      { headerName: "Contact Email", field: "Contact.Email", tooltipField: "Contact.Email", width: 50, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Last Sync Time", field: "LastSyncTime", valueFormatter: formatLastSync, tooltipField: "LastSyncTime", width: 100, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Is Active", field: "IsActive", width: 50, menuTabs: [], cellClass: 'gridcol', cellRenderer: params => { return `<input type='checkbox' readonly onclick='return false;' ${params.value ? 'checked' : ''} value=${params.value ? 1 : 0}/>`; } },
      { headerName: "", width: 30, menuTabs: [] }
    ];

    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      showToolPanel: false,
      rowDeselection: true,
      localeText: { noRowsToShow: "No channels(s) found" }
    };
    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;

    function formatLastSync(param) {
      var date = new Date(param.value);
      return date.toLocaleString();
    }
    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });
    this.domainContext = this.storageService.get(StorageService.currentUser).DomainContext;

    this.taskCompleteNotificationSubscription = taskbarService.taskCompleteListener$.subscribe(
      tasks => {
        let taskStatus = this.taskbarService.getTaskStatus(tasks, "getchannelhotelinfo");
        if (taskStatus) {
          let hotelId = this.storageService.get(StorageService.userHotelContext).HotelId;
          this.hotelMgmtService.findChannelHotelInfo(this.domainContext.CurrentDomainId,null,true,true);
          this.hotelMgmtService.findChannelHotelInfo(this.domainContext.CurrentDomainId,hotelId,true,true);
        }
      }
    );
  }
  async findDomainChannels(bforced = false) {

    let response = await this.hotelMgmtService.getDomainChannels(bforced).then(
      async response => {
        this.domainChannels = response;
        this.gridOptions.api.setRowData(response);
        this.gridOptions.api.sizeColumnsToFit();
        this.onGridReady(this);
      });
  }

  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
    let setSelected = false;
    this.gridOptions.api.forEachNode(node => {
      if (!node.group && !setSelected) { node.setSelected(true); this.onRowClick(node); setSelected = true; }
    });
    if (!setSelected) this.loadMenu();
    //this.gridOptions.api.forEachNode(node => node.rowIndex ? 0 : node.setSelected(true));
    this.gridOptions.suppressDragLeaveHidesColumns = true;
    if (this.gridOptions.api.getSelectedRows().length = 0) {
      this.isSyncDisabled = false;
    }
  }
  onGridSizeChanged($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
  }
  ngOnInit() {
    this.loadMenu();
    this.findDomainChannels();
  }

  loadMenu() {
    this.hotelchannelsMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', disabled: true, command: (event) => this.open(event) },
      { title: 'Sync', label: 'Sync', icon: 'action-bar-menu-icon fa icon ion-md-sync', disabled: true, command: (event) => this.onSync() },
      { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: true, command: (event) => this.confirmDelete(event, true) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
    ];
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true));
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  onRowClick(params) {true
    if (params.data) {
      let disabledDelete = false;
      if(this.gridOptions.api.getSelectedRows().length == 0)this.isSyncDisabled = true;
      else this.isSyncDisabled = false;
      if (params.data.Id && params.data.Id.toLowerCase() == this.storageService.get(StorageService.loginUserId)) disabledDelete = true;
      this.hotelchannelsMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
        { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
        { title: 'Sync', label: 'Sync', icon: 'action-bar-menu-icon fa icon ion-md-sync',disabled:this.isSyncDisabled || this.isReadOnly, command: (event) => this.onSync() },
        { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o',disabled: disabledDelete || this.isReadOnly, command: (event) => this.confirmDelete(event, true) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
      ];
    }
  }

  onClickGridRow(params) {
    if (params.data)
      this.createNew(params.data);
  }

  //Refresh Domain Channel Grid
  onRefresh(event) {
    this.findDomainChannels(true);
  }
  //OnClose
  onClose() {
    this.router.navigate(["/settings"]);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    //this.filterSubscription.unsubscribe();
    this.taskCompleteNotificationSubscription.unsubscribe();
  }

  //Create New RoomTypes
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  detailComponent;
  isNew = false;
  createNew(data?) {
    this.container.clear();
    let factory = this.resolver.resolveComponentFactory(DomainchanneldetailsComponent);
    this.detailComponent = <DomainchanneldetailsComponent>this.container.createComponent(factory).instance;
    if (data) {
      this.detailComponent.channel = data;
      this.isNew = false;
    } else {
      this.detailComponent.channel = new Channel();
      this.isNew = true;
    }
    this.detailComponent.loadData();
    this.detailComponent.onClose.subscribe(this.onDetailsClose);
    this.showDetailPage = true;
    this.detailComponent.setHeight();
  }
  //Open Promotion
  open(event) {
    this.createNew(this.gridOptions.api.getSelectedRows()[0]);
  }
  //Delete Promotion
  @ViewChild('showConfirmationDialog') showConfirmationDialog;
  showDeleteConfirmation = false;
  deleteId;
  confirmDelete(params, fromGrid?) {
    if (!fromGrid) {
      if (params.node.data) {
        this.deleteId = params.node.data.Id;
        this.showDeleteConfirmation = true;
      }
    } else {
      let selectedRow = this.gridOptions.api.getSelectedRows();
      if (selectedRow && selectedRow.length > 0) {
        this.deleteId = selectedRow[0].Id;
        this.showDeleteConfirmation = true;
      }
    }
  }

  closeDialog(event) {
    this.deleteId = null;
    this.showDeleteConfirmation = false;
    this.setHeight();
  }

  async delete(event) {
    this.showDeleteConfirmation = false;
    if (this.deleteId != null) {
      let finalResponse = await this.hotelMgmtService.deleteDomainChannel(this.deleteId).then(
        localresp => { return localresp; });
    }
    this.onRefresh(event);
  }

  onDetailsClose = async (event) => {
    this.showDetailPage = false;
    if (event) {
      await this.findDomainChannels(event);
      if(this.isNew){
        let ch = this.domainChannels.find(d => d.ChannelCode == event.ChannelCode && d.Name == event.Name 
          && d.Credentials.UserId == event.Credentials.UserId);
        if(ch){
          if (ch.IsActive) this.hotelMgmtService.getChannelHotelInfo(ch, null);
        }
      }
    }
    this.setHeight();
  }

  onSync() {
    var selectedChannels = this.gridOptions.api.getSelectedRows();
    if (selectedChannels) {
      selectedChannels.forEach(channel => {
        if (channel.IsActive)
          this.hotelMgmtService.getChannelHotelInfo(channel, null);
      });
    }
  }
  selectAll() {
    this.gridOptions.api.selectAll();
  }

  filteredResponse;
  searchText(event) {
    this.filteredResponse = [];
    var query = event.target.value.toLowerCase();
    let searchItems = this.domainChannels;
    if (query == "") this.filteredResponse = searchItems;
    else {
      searchItems.forEach(searchItem => {
        let isMatch = false;
        let searchString = JSON.stringify(searchItem);
        isMatch = searchString.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        if(!isMatch){
          let channelType = Utilities.findId(Utilities.channelTypes, searchItem.ChannelCode, searchItem.ChannelCode);
          isMatch = (channelType.indexOf(query) >= 0);
        }
        if (isMatch) this.filteredResponse.push(searchItem);
      });
    }
    this.gridOptions.api.setRowData(this.filteredResponse);
    this.gridOptions.api.sizeColumnsToFit();
    this.onGridReady(this);
    this.setHeight();
  }
}



function channelTypeFormatter(param) {
  return Utilities.findId(Utilities.channelTypes, param.value, param.value);
}
