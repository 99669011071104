import { Component, OnInit, EventEmitter, Output, HostListener, Input, ViewChild } from '@angular/core';
import { DomainsComponent } from '../domains/domains.component';
import { DomainProfileCriteria, DomainProfile, ContactInfo, DomainStatus, Phone, Address, AppService, UserCriteria } from 'mantras-api';
import { AuthenticationService } from '../ui-services/authentication.service';
import { MenuItem } from 'primeng/primeng';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { UserService } from '../ui-services/user.service';
import { StorageService } from '../ui-services/storage.service';
import { ucs2 } from 'punycode';
import {ToastModule} from 'primeng/toast';
import { MessageService } from 'primeng/components/common/messageservice';


@Component({
  selector: 'domainsdetails',
  templateUrl: './domainsdetails.component.html',
  styleUrls: ['./domainsdetails.component.css']
})
export class DomainsDetailsComponent implements OnInit {
  validationMsg: string;
  licensesValues;
  PMSMailSetting;
  billingInfoChkFlag = false;
  selectedLicenses = [];
  selectedDomains: any;
  accManager = [];
  addressPlaceholder: string;
  compChennelsNewFlag = false;
  licenses = [];
  selectedCompetitorChannels = [];
  competitorChannelsList = [];
  addressTextAdmin;
  addressTextBilling;
  selectedBillingCk = false;
  partnerId: string;
  billingPhone;
  selectedAccManager;
  adminPhone;
  // Vendor;
  vendor = [];
  Firstname: string;
  Email: string;
  Name: string;
  domains: DomainsComponent;
  //domainCriteria = new DomainProfileCriteria();
  filteredDomains: any[] = new Array();
  domainsMenuItems: MenuItem[];
  //domainsProfile = new DomainProfile();
  isNew = false;

  @Input() domainProfile = new DomainProfile();
  @Output('onClose') onClose = new EventEmitter<boolean>();

  constructor(private authService: AuthenticationService, private storageService: StorageService, private mantras: AppService ,private messageService: MessageService) {
    //this.onClose = new EventEmitter();
    this.vendor.push({ label: 'MAXIMOJO', value: 'MAXIMOJO' }, { label: 'HOTEL COMMERCE', value: 'HOTEL COMMERCE' })
    this.competitorChannelsList.push({ label: 'Agoda', value: 'Agoda' }, { label: 'Booking.com', value: 'BookingDotCom' }, { label: 'ClearTrip', value: 'ClearTrip' },
      { label: 'Expedia', value: 'Expedia' }, { label: 'goIbibo', value: 'goIbibo' }, { label: 'MakeMyTrip', value: 'MakeMyTrip' }, { label: 'Yatra.com', value: 'YatraDotCom' });
    this.licenses.push({ label: 'Revenue Mantra', value: 'Revenue Mantra' }, { label: 'Analytic Mantra', value: 'Analytic Mantra' })
    if(!this.domainProfile.Preferences) this.domainProfile.Preferences = {};
  }

  ngOnInit() {
    this.loadMenu();
  }

  loadMenu() {
    this.domainsMenuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', command: (event) => this.onSave(event) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) },
    ];
  }


  async loadData(selectedDomainId) {
    //this.domainProfile.AdminContact.Phones = [];
    this.addressPlaceholder = "Street" + "\n" +
        "City" + "\t" + "State" + "\n" +
        "Country" + "\t" + "ZipCode";
      //this.domainProfile.Preferences = "";
    if (selectedDomainId == null) {
      this.domainProfile = new DomainProfile();
      this.domainProfile.AdminContact = new ContactInfo();
      this.domainProfile.BillingContact = new ContactInfo();
      this.domainProfile.AdminContact.MailingAddress = new Address();
      this.domainProfile.BillingContact.MailingAddress = new Address();
      this.domainProfile.Id = Utilities.generateGUID();
      this.domainProfile.Status = 3;
      this.domainProfile.Type = 5;
      this.domainProfile.Currency = "INR";
      this.domainProfile.Preferences = {};
      this.domainProfile.Preferences["Vendor"] = "MAXIMOJO";
      this.PMSMailSetting = "";
      this.selectedCompetitorChannels = ['Agoda', 'BookingDotCom', 'ClearTrip', 'Expedia', 'goIbibo', 'MakeMyTrip', 'YatraDotCom'];
      this.selectedLicenses = ['Revenue Mantra', 'Analytic Mantra'];
      this.compChennelsNewFlag = true;
      this.isNew = true;
      let userCriteria = new UserCriteria();
      userCriteria.DomainId = "System";
      let response = await this.mantras.FindDomainUsers(userCriteria);
      response.forEach(user => {
        if (user.Roles.includes("Account Supervisor") && user.IsActive)  {
          this.accManager.push({ label: user.Id, value: user.Id });
        }
      });
    }
    else {
      this.partnerId = this.domainProfile.Preferences["Partner Id"];
      this.PMSMailSetting = this.domainProfile.Preferences.PMSMailSetting;
      if(this.domainProfile.Preferences["CompetitorChannels"])
        this.selectedCompetitorChannels = this.domainProfile.Preferences["CompetitorChannels"].split(", ");
      if(this.domainProfile.Preferences["Licences"])
      this.selectedLicenses = this.domainProfile.Preferences["Licences"].split(", ");
      if(this.domainProfile.AdminContact && this.domainProfile.AdminContact.MailingAddress){
      this.addressTextAdmin = this.domainProfile.AdminContact.MailingAddress.Street + "\n" +
        this.domainProfile.AdminContact.MailingAddress.City + "\t" + this.domainProfile.AdminContact.MailingAddress.State + "\n" +
        this.domainProfile.AdminContact.MailingAddress.Country + "\t" + this.domainProfile.AdminContact.MailingAddress.ZipCode;
      } else {
        this.domainProfile.AdminContact = new ContactInfo();
        this.domainProfile.AdminContact.MailingAddress = new Address();
      }
      if(this.domainProfile.BillingContact && this.domainProfile.BillingContact.MailingAddress){
      this.addressTextBilling = this.domainProfile.BillingContact.MailingAddress.Street + "\n" +
        this.domainProfile.BillingContact.MailingAddress.City + "\t" + this.domainProfile.BillingContact.MailingAddress.State + "\n" +
        this.domainProfile.BillingContact.MailingAddress.Country + "\t" + this.domainProfile.BillingContact.MailingAddress.ZipCode;
      } else {
        this.domainProfile.BillingContact = new ContactInfo();
        this.domainProfile.BillingContact.MailingAddress = new Address();
      }
    }
    this.adminPhone = (this.domainProfile.AdminContact && this.domainProfile.AdminContact.Phones) ? this.domainProfile.AdminContact.Phones[0].Number : this.adminPhone;
    this.billingPhone = (this.domainProfile.BillingContact && this.domainProfile.BillingContact.Phones) ? this.domainProfile.BillingContact.Phones[0].Number : this.billingPhone;
    this.loadMenu();
  }

  async onSave(event) {
    this.domainProfile.Preferences['Licences'] = this.selectedLicenses.toString().replace(/,/g, ", ");
    this.domainProfile.Preferences['CompetitorChannels'] = this.selectedCompetitorChannels.toString().replace(/,/g, ", ");
    this.domainProfile.Preferences['Partner Id'] = this.partnerId;
    this.domainProfile.Preferences.Vendor == "MAXIMOJO";

    // if(this.domainProfile.Preferences.Vendor == "MAXIMOJO")
    // {
    //   this.domainProfile.Preferences.Vendor=null;
    // }
     if (this.domainProfile.Name == undefined) {
      this.validationMsg = " Please insert data for Name";
      this.showValidationDialogueBox = true;
      return;
    }
    if(this.domainProfile.Preferences['Licences'] == ""){
      this.validationMsg = "Please select atleast one Licenses Purchased";
      this.showValidationDialogueBox = true;
      return;
    }
    if(this.isNew)
    {
      if (this.selectedAccManager == undefined) {
        this.validationMsg = " Please insert data for Account Manager";
        this.showValidationDialogueBox = true;
        return;
      }
    }
    if(this.domainProfile.AdminContact.Email == undefined)
    {
      this.validationMsg = " Please insert data for Email Id of Admin Contact";
      this.showValidationDialogueBox = true;
      return;
    }
    if(!this.domainProfile.Preferences['Licences'].includes("Analytic Mantra"))
    {
      this.domainProfile.Preferences['CompetitorChannels'] = "";
    }
    if (this.isNew) {
      await this.authService.registerDomain(this.domainProfile,this.selectedAccManager);
      await this.authService.approveDomain(this.domainProfile.Id).then(result => {
        {this.messageService.addAll([{severity:'success', summary:'Success', detail:"Saved successfully."}]);}
        this.onCancel(this.domainProfile);
      });
    } else {
      this.authService.saveDomain(this.domainProfile).then(result => {
        {this.messageService.addAll([{severity:'success', summary:'Success', detail:"Updated successfully."}]);}
        this.onCancel(this.domainProfile);
      });
    }
  }

  onCancel(event?) {
    this.onClose.emit(event);
  }

  @ViewChild('validationDialogueBox') validationDialogueBox;
  showValidationDialogueBox = false;

  closeDialog(event) {
    this.showValidationDialogueBox = false;
    //this.setHeight();
  }


  billingInfoChk() {
    if (this.selectedBillingCk == true) {
      if (this.domainProfile.AdminContact)
        this.domainProfile.BillingContact = this.domainProfile.AdminContact;
      if (this.addressTextAdmin)
        this.addressTextBilling = Utilities.clone(this.addressTextAdmin);
      if (this.adminPhone)
        this.billingPhone =this.adminPhone;
      this.billingInfoChkFlag = true;
    }
    else {
      this.billingInfoChkFlag = false;
    }
  }

  street;
  country;
  state;
  zipcode;
  city;
  showAddressDialogAdmin = false;
  showAddressDialogBilling = false;
  editAddressAdmin() {
    this.street = this.domainProfile.AdminContact.MailingAddress.Street;
    this.country = this.domainProfile.AdminContact.MailingAddress.Country;
    this.city = this.domainProfile.AdminContact.MailingAddress.City;
    this.state = this.domainProfile.AdminContact.MailingAddress.State;
    this.zipcode = this.domainProfile.AdminContact.MailingAddress.ZipCode;
    this.showAddressDialogAdmin = true;
  }
  editAddressBilling() {
    this.street = this.domainProfile.BillingContact.MailingAddress.Street;
    this.country = this.domainProfile.BillingContact.MailingAddress.Country;
    this.city = this.domainProfile.BillingContact.MailingAddress.City;
    this.state = this.domainProfile.BillingContact.MailingAddress.State;
    this.zipcode = this.domainProfile.BillingContact.MailingAddress.ZipCode;
    this.showAddressDialogBilling = true;
  }

  saveAddressAdmin() {
    this.domainProfile.AdminContact.MailingAddress.Street = this.street;
    this.domainProfile.AdminContact.MailingAddress.Country = this.country;
    this.domainProfile.AdminContact.MailingAddress.City = this.city;
    this.domainProfile.AdminContact.MailingAddress.State = this.state;
    this.domainProfile.AdminContact.MailingAddress.ZipCode = this.zipcode;
    this.addressTextAdmin = this.domainProfile.AdminContact.MailingAddress.Street + "\n" +
      this.domainProfile.AdminContact.MailingAddress.City + "\t" + this.domainProfile.AdminContact.MailingAddress.State + "\n" +
      this.domainProfile.AdminContact.MailingAddress.Country + "\t" + this.domainProfile.AdminContact.MailingAddress.ZipCode;
    this.showAddressDialogAdmin = false;
    if(this.billingInfoChkFlag == true)
    {
     this.billingInfoChk();
    }
  }
  saveAddressBilling() {
    this.domainProfile.BillingContact.MailingAddress.Street = this.street;
    this.domainProfile.BillingContact.MailingAddress.Country = this.country;
    this.domainProfile.BillingContact.MailingAddress.City = this.city;
    this.domainProfile.BillingContact.MailingAddress.State = this.state;
    this.domainProfile.BillingContact.MailingAddress.ZipCode = this.zipcode;
    this.addressTextAdmin = this.domainProfile.BillingContact.MailingAddress.Street + "\n" +
      this.domainProfile.BillingContact.MailingAddress.City + "\t" + this.domainProfile.BillingContact.MailingAddress.State + "\n" +
      this.domainProfile.BillingContact.MailingAddress.Country + "\t" + this.domainProfile.BillingContact.MailingAddress.ZipCode;
    this.showAddressDialogBilling = false;
  }




  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true, false, false, false, 30));
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }
}
