import { Component, OnInit, HostListener, ViewChild, ViewContainerRef, ComponentFactoryResolver,Output,EventEmitter } from '@angular/core';
import { StorageService } from '../../ui-services/storage.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/primeng';
import { GridOptions } from 'ag-grid';
import { Subscription } from 'rxjs';
import { HotelManagementService } from '../../ui-services/hotelmanagement.service';
import { HotelchanneldetailsComponent } from './hotelchanneldetails.component';
import { Utilities } from '../../ui-services/utilities';
import { HotelChannel } from 'mantras-api';

import * as jQuery from 'jquery';
import { UserService } from '../../ui-services/user.service';
import { CustomReuseStrategy } from '../../ui-services/reuse-routes';

@Component({
  selector: 'hotelchannels',
  templateUrl: './hotelchannels.component.html',
  styleUrls: ['./hotelchannels.component.css']
})
export class HotelchannelsComponent implements OnInit {
  breadCrumbItems: MenuItem[];
  hotelchannelsMenuItems: MenuItem[];
  columnDefs: any[];
  rowData: any[];
  gridOptions: GridOptions;
  rowSelection: any;
  rowGroupPanelShow: any;
  domainId = "";
  rowClassRules;


  showDetailPage = false;
  icons;
  isReadOnly = false;
  subscription: Subscription;
  hotelContext;
  domainContext;
  hotelChannels;
  @Output() onChangeMenuItems: EventEmitter<any>  = new EventEmitter();
  constructor(private resolver: ComponentFactoryResolver, private storageService: StorageService, private router: Router,
    private hotelMgmtService: HotelManagementService,private userService:UserService) {
      this.isReadOnly = userService.isReadOnly();
      this.breadCrumbItems = [{ label: "Settings",icon:'fa fa-wrench', routerLink: ["/settings"] },
      { label: "Hotel Channels", routerLink: ["/settings/hotelchannels"] }];
    this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    this.domainId = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    this.columnDefs = [
      { headerName: "Status", field: "IsValidMapping", tooltipField: "IsValidMapping", width:40, menuTabs: [], cellClass: 'gridcol', valueFormatter: comupteMapping },
      { headerName: "Name", field: "ChannelCode", tooltipField: "ChannelCode", width: 120, menuTabs: [], cellClass: 'gridcol', sort: "asc", valueFormatter: channelTypeFormatter },
      { headerName: "Margin", field: "SellMargin", tooltipField: "SellMargin", width: 40, menuTabs: [], cellClass: 'gridcol rightalign',valueFormatter:fixToTwoDecimal },
      { headerName: "Rate Type", field: "RateType", tooltipField: "RateType", width: 40, menuTabs: [], cellClass: 'gridcol',valueFormatter:rateTypeFormatter },
      { headerName: "Availability", field: "AvailabilityRatio", tooltipField: "AvailabilityRatio", width: 40, menuTabs: [], cellClass: 'gridcol rightalign',valueFormatter:formatAvailabilityRatio },
      { headerName: "Tax %", field: "Tax", width: 50, menuTabs: [], cellClass: 'gridcol rightalign',valueFormatter:fixToTwoDecimal },
      { headerName: "Tax Type", field: "TaxType", width: 40, valueFormatter: taxTypeFormatter, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Pricing Model", field: "PricingModel", valueFormatter: pricingModelFormatter, tooltipField: "PricingModel", width: 80, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Is Active", field: "IsActive", width: 50, menuTabs: [], cellClass: 'gridcol', cellRenderer: params => { return `<input type='checkbox' readonly onclick='return false;' ${params.value ? 'checked' : ''} value=${params.value ? 1 : 0}/>`; } },
      { headerName: "", width: 250, menuTabs: [] }
    ];

    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      showToolPanel: false,
      localeText: { noRowsToShow: "No channel(s) found" }
    };
    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;
    this.gridOptions.rowClassRules = {
      "unmapped_highlighted": function(param){
        if(param.data != null)return !param.data.IsValidMapping;
        }
    }
    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });
    this.domainContext = this.storageService.get(StorageService.currentUser).DomainContext;
    function taxTypeFormatter(param) {
      if (param.value == 1) return "Inclusive";
      else return "Exclusive";
    }
    function pricingModelFormatter(param) {
      if (param.value == 2) return "Per Occupancy Pricing";
      else return "Per Day Pricing";
    }
    function fixToTwoDecimal(params){
      if (params.value) {
        return parseFloat(params.value).toFixed(2);
      }
    }
    function formatAvailabilityRatio(params){
      if(params.value){
        return (parseFloat(params.value) * 100).toFixed(2);
      }
    }
    function rateTypeFormatter(param){
      if(param.value == 1) return "Sell Rate";
      else return "Net Rate";
    }
    this.rowClassRules = {
      "unmapped": "data.IsValidMapping == true"
    }
  }
  async findHotelChannels(bforce = false) {

    let response = await this.hotelMgmtService.findHotelChannels(this.hotelContext.HotelId, bforce).then(
      async response => {
        this.hotelChannels = response;
        this.gridOptions.api.setRowData(response);
        this.gridOptions.api.sizeColumnsToFit();
        this.onGridReady(this);
      });
  }

  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
    let setSelected = false;
    this.gridOptions.api.forEachNode(node => {
      if (!node.group && !setSelected) { node.setSelected(true); this.onRowClick(node); setSelected = true; }
    });
    if (!setSelected) this.loadMenu();
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }
  onGridSizeChanged($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
  }
  ngOnInit() {
    this.loadMenu();
    this.findHotelChannels();
  }

  loadMenu() {
    this.hotelchannelsMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', disabled: true, command: (event) => this.open(event) },
      { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: true, command: (event) => this.confirmDelete(event, true) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
    ];
    this.getMenuItemData();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true,true,false,false));
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  onRowClick(params) {
    if (params.data) {
      let disabledDelete = false;
      if (params.data.Id && params.data.Id.toLowerCase() == this.storageService.get(StorageService.loginUserId)) disabledDelete = true;
      this.hotelchannelsMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
        { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
        { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: true, command: (event) => this.confirmDelete(event, true) },
        //{ title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: this.isReadOnly || disabledDelete, command: (event) => this.confirmDelete(event, true) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
      ];
      this.getMenuItemData();
    }
  }

  onClickGridRow(params) {
    if (params.data)
      this.createNew(params.data);
  }

  //Refresh Promotion Grid
  onRefresh(event) {
    this.findHotelChannels(event);
    this.hotelMgmtService.findChannelHotelInfo(this.domainId, this.hotelContext.HotelId, event,true);
  }
  //OnClose
  onClose() {
    this.router.navigate(["/settings"]);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  //Create New RoomTypes
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  detailComponent;
  async createNew(data?) {
    this.container.clear();
    let unmapped = await this.hotelMgmtService.findChannelHotelInfo(this.domainId, null);
    if(!unmapped) unmapped = [];
    let chiForHotel = await this.hotelMgmtService.findChannelHotelInfo(this.domainId, this.hotelContext.HotelId);
    chiForHotel.forEach(chi => {
      let matching = unmapped.find(umap => umap.ChannelHotelId == chi.ChannelHotelId && umap.ChannelCode == chi.ChannelCode && umap.ChannelId == chi.ChannelId);
      if(matching) { unmapped.splice(unmapped.indexOf(matching),1) };
      unmapped.push(chi);
    });
    let domainChannels = await this.hotelMgmtService.getDomainChannels();
    let channelsOptions = [];

    unmapped.forEach(unmap => {
      if(this.hotelChannels.filter(hc => hc.ChannelCode == unmap.ChannelCode).length == 0){
        let dc = domainChannels.find(d => d.ChannelCode == unmap.ChannelCode && d.Id == unmap.ChannelId);
          let cT = Utilities.findId(Utilities.channelTypes, unmap.ChannelCode, unmap.ChannelCode);
          if(dc && cT){
            channelsOptions.push({ label: cT + "-" + dc.Name, value: dc.Id });
          }
      }
    });
    if ((!channelsOptions || channelsOptions.length == 0) && !data) {
      this.hotelchannelsMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(true) },
        { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
        { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: true, command: (event) => this.confirmDelete(event, true) },
        // { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: this.isReadOnly, command: (event) => this.confirmDelete(event, true) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
      ];
      this.noChannelsToMapped = true;
      return;
    }
    await this.hotelMgmtService.findRoomRatePlans(this.hotelContext.HotelId);
    let factory = this.resolver.resolveComponentFactory(HotelchanneldetailsComponent);
    this.detailComponent = <HotelchanneldetailsComponent>this.container.createComponent(factory).instance;
    if (data) this.detailComponent.hotelChannel = data;
    else this.detailComponent.hotelChannel = new HotelChannel();
    this.detailComponent.dataEmitter.subscribe($event => {
      this.refreshDetailMenuItems($event)
    });
    this.detailComponent.loadData();
    this.detailComponent.onClose.subscribe(this.onDetailsClose);
    this.showDetailPage = true;
    this.detailComponent.setHeight();
  }

  open(event) {
    this.createNew(this.gridOptions.api.getSelectedRows()[0]);
  }

  @ViewChild('showConfirmationDialog') showConfirmationDialog;
  showDeleteConfirmation = false;
  deleteId;
  confirmDelete(params, fromGrid?) {
    if (!fromGrid) {
      if (params.node.data) {
        this.deleteId = params.node.data.ChannelCode;
        this.showDeleteConfirmation = true;
      }
    } else {
      let selectedRow = this.gridOptions.api.getSelectedRows();
      if (selectedRow && selectedRow.length > 0) {
        this.deleteId = selectedRow[0].ChannelCode;
        this.showDeleteConfirmation = true;
      }
    }
  }
  @ViewChild('showNoChannelDialog') showNoChannelsDialog;
  noChannelsToMapped = false;

  closeDialog(event) {
    this.deleteId = null;
    this.showDeleteConfirmation = false;
    this.setHeight();
  }
  closeNoChannelsDialog() {
    this.noChannelsToMapped = false;
    this.setHeight();
  }
  async delete(event) {
    this.showDeleteConfirmation = false;
    if (this.deleteId != null) {
      let finalResponse = await this.hotelMgmtService.deleteHotelChannel(this.hotelContext.HotelId, this.deleteId, this.domainId).then(
        localresp => { return localresp; });
    }
    this.onRefresh(true);
  }

  onDetailsClose = (event): void => {
    this.showDetailPage = false;
    this.getMenuItemData()
    if (event) this.onRefresh(event);
    else this.setHeight();
  }
  refreshDetailMenuItems(event){
    this.onChangeMenuItems.emit(event);
  }
  getMenuItemData(){
    if(this.showDetailPage){
      this.detailComponent.getMenuItemData();
    }else{
      let data = [];
      data.push({'source':'hotelChannels'});
      data.push(this.hotelchannelsMenuItems);
      this.onChangeMenuItems.emit(data);
    }
  }
}

function comupteMapping(param) {
  if (param.value) return "Mapped";
  else return "Un-Mapped";
}

function channelTypeFormatter(param) {
  return Utilities.findId(Utilities.channelTypes, param.value, param.value);
}