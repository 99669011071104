import { Component, OnInit, EventEmitter, Output, Input, HostListener, ViewChild, ViewContainerRef, ViewChildren, AfterViewInit } from '@angular/core';
import { RateRule, ComparatorValues } from 'mantras-api';
import { StorageService } from '../ui-services/storage.service';
import { Utilities } from '../ui-services/utilities';
import { AuthenticationService } from '../ui-services/authentication.service';
import { AnalyticsService } from '../ui-services/analytics.service';
import { AlertsService } from '../ui-services/alerts.service';
import { RoomRate } from 'mantras-api';
import { DateRange } from 'mantras-api';
import { LoadingService } from '../ui-services/loading.service';

import * as jQuery from 'jquery';
import { UserService } from '../ui-services/user.service';

@Component({
  selector: 'rateruledetails',
  templateUrl: './rateruledetails.component.html',
  styleUrls: ['./rateruledetails.component.css']
})
export class RateruledetailsComponent implements OnInit, AfterViewInit {

  @Input() rateRule : RateRule;
  @Output('onClose') onClose = new EventEmitter<boolean>();
  errorMsg = "";
  valueTypes = [{label:"Amount", value:0},{label:"Percent",value:1}];
  roomTypes;
  ratePlans;
  // startDate;
  // endDate;
  stayDates;
  isReadOnly = false;
  rateRuleMenuItems;
  hotelContext;
  startStayDate = Utilities.getUTCDate(new Date());
  endStayDate = Utilities.getUTCDate(new Date());
  domainHotel;
  maxOccupancy = 0;
  selectedDaysOfWeek = ['0','1','2','3','4','5','6'];
  constructor(private storageService:StorageService, private alertService: AlertsService, private loadingService: LoadingService,
    private authenticationService: AuthenticationService, private analyticsService: AnalyticsService,private userService:UserService) { 
    this.hotelContext = this.storageService.get(StorageService.userHotelContext);
    this.isReadOnly = userService.isReadOnly();
  }

  async ngOnInit() {
    this.rateRuleMenuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o', disabled:this.isReadOnly,command: (event) => this.onSave(event)},
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }];
      this.ratePlans = [{label:"All",value:null}];
      this.hotelContext.RatePlans.forEach(element => {
        this.ratePlans.push({label:element.Name,value:element.Id});
      });
      this.roomTypes = [{label:"All",value:null}];
      this.hotelContext.RoomTypes.forEach(element => {
        this.roomTypes.push({label:element.Name,value:element.Id});
        if(element.MaxOccupancy > this.maxOccupancy) this.maxOccupancy = element.MaxOccupancy;
      });

      this.domainHotel = this.authenticationService.getDomainHotel(this.hotelContext.HotelId);
      this.setHeight();
  }

  @HostListener('window:resize') setHeight(){
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true));
  }

  loadData(){
    this.stayDates = [];
    if(this.rateRule.Id != null && this.rateRule.Id != undefined){
      if(this.rateRule.StayDates.Start && this.rateRule.StayDates.Start.toString() != "0001-01-01T00:00:00Z")
        this.stayDates[0] = Utilities.getUTCDate(new Date(this.rateRule.StayDates.Start));
      if(this.rateRule.StayDates.End && this.rateRule.StayDates.End.toString() != "0001-01-01T00:00:00Z")
        this.stayDates[1] = Utilities.getUTCDate(new Date(this.rateRule.StayDates.End));
      this.selectedDaysOfWeek = Utilities.convertDecimalToDaysOfWeek(this.rateRule.StayDates["DaysOfWeek"]);
    } else {
      this.rateRule.IsEnabled = true;
      this.rateRule.IsDelta = true;
      this.rateRule.UpdateOnCancellation = true;
      this.selectedDaysOfWeek = ['0','1','2','3','4','5','6'];
      this.addRow();
    }
    this.delta = this.rateRule.IsDelta == true?"true":"false";
    this.nonContinouseUpdate = this.rateRule.NonContinuousUpdates == true?"true":"false";
    this.cancellation = this.rateRule.UpdateOnCancellation == true?"true":"false";
    this.hotelLevelInventory = this.rateRule.HotelLevelInventory == true? "true":"false";
  }

  // @ViewChild('rateEndDate') rateEndDate;
  // onStartDateChange(rateStartDate){
  //   this.rateEndDate.minDate = rateStartDate.value;
  //   if(Utilities.dateDifference(this.rateEndDate,rateStartDate.value)>0) this.rateEndDate = rateStartDate.value;
  //   this.rateEndDate.ngOnInit();
  // }

  onCancel(event?){
    this.onClose.emit(event);
  }

  onSave(event){
    if(!this.validateForm()) return; 
    if(!this.rateRule.StayDates) this.rateRule.StayDates = new DateRange();
    this.rateRule.StayDates.Start = Utilities.convertToUTC(this.stayDates[0]);
    this.rateRule.StayDates.End = Utilities.convertToUTC(this.stayDates[1]);
    this.rateRule.StayDates.DaysOfWeek = Utilities.convertDaysOfWeekToDecimal(this.selectedDaysOfWeek);
    this.rateRule.DomainId = this.domainHotel.DomainId;
    this.rateRule.HotelId = this.hotelContext.HotelId;
    for(let idx = 0; idx < this.rateRule.UpdateRules.length;){
      let compVal = this.rateRule.UpdateRules[idx];
      if(!compVal.Inventory || compVal.Inventory < 0){
        this.rateRule.UpdateRules.splice(idx,1);
      } else if(this.rateRule.IsDelta && !(compVal.RateDelta != null && compVal.RateDelta != undefined && Math.abs(compVal.RateDelta) >= 0)) {
        this.rateRule.UpdateRules.splice(idx,1);
      } else if(!this.rateRule.IsDelta && !this.validateRoomRate(compVal.Rates)){
        this.rateRule.UpdateRules.splice(idx,1);
      } else {
        idx++;
      }
    }
    console.log("Rate Rule", this.rateRule);
    this.alertService.saveRateRule(this.rateRule).then(
      resp => { 
        this.onCancel(true);
      }
    ).catch(error => {
      this.loadingService.showErrorDialog(error);
    });
  }
  
  validateRoomRate(roomRate){
    let isValid = false;
    if(this.domainHotel.PricingModel != 2) if(roomRate.PerDay > 0) isValid = true; else if(roomRate.PerDay == null) roomRate.PerDay = undefined;
    if(this.domainHotel.PricingModel != 1 && this.maxOccupancy >= 1){ if(roomRate.PerOccupancy["1"] > 0) isValid = true; else if(roomRate.PerOccupancy["1"] == null) roomRate.PerOccupancy["1"] = undefined; }
    if(this.domainHotel.PricingModel != 1 && this.maxOccupancy >= 2){ if(roomRate.PerOccupancy["2"] > 0) isValid = true; else if(roomRate.PerOccupancy["2"] == null) roomRate.PerOccupancy["2"] = undefined; }
    if(this.domainHotel.PricingModel != 1 && this.maxOccupancy >= 3){ if(roomRate.PerOccupancy["3"] > 0) isValid = true; else if(roomRate.PerOccupancy["3"] == null) roomRate.PerOccupancy["3"] = undefined; }
    if(this.domainHotel.PricingModel != 1 && this.maxOccupancy >= 4){ if(roomRate.PerOccupancy["4"] > 0) isValid = true; else if(roomRate.PerOccupancy["4"] == null) roomRate.PerOccupancy["4"] = undefined; }
    if(this.domainHotel.PricingModel != 1 && this.maxOccupancy >= 5){ if(roomRate.PerOccupancy["5"] > 0) isValid = true; else if(roomRate.PerOccupancy["5"] == null) roomRate.PerOccupancy["5"] = undefined; }
    if(this.domainHotel.PricingModel != 1 && this.maxOccupancy >= 6){ if(roomRate.PerOccupancy["6"] > 0) isValid = true; else if(roomRate.PerOccupancy["6"] == null) roomRate.PerOccupancy["6"] = undefined; }
    if(this.domainHotel.PricingModel != 1 && this.maxOccupancy >= 7){ if(roomRate.PerOccupancy["7"] > 0) isValid = true; else if(roomRate.PerOccupancy["7"] == null) roomRate.PerOccupancy["7"] = undefined; }
    if(this.domainHotel.PricingModel != 1 && this.maxOccupancy >= 8){ if(roomRate.PerOccupancy["8"] > 0) isValid = true; else if(roomRate.PerOccupancy["8"] == null) roomRate.PerOccupancy["8"] = undefined; }
    if(this.domainHotel.PricingModel != 1 && this.maxOccupancy >= 9){ if(roomRate.PerOccupancy["9"] > 0) isValid = true; else if(roomRate.PerOccupancy["9"] == null) roomRate.PerOccupancy["9"] = undefined; }
    if(this.domainHotel.PricingModel != 1 && this.maxOccupancy >= 10){ if(roomRate.PerOccupancy["10"] > 0) isValid = true; else if(roomRate.PerOccupancy["10"] == null) roomRate.PerOccupancy["10"] = undefined; }
    if(roomRate.ExtraChildren["1"] == null) roomRate.ExtraChildren["1"] = undefined;
    if(roomRate.PerExtraPerson == null) roomRate.PerExtraPerson = undefined;
    return isValid;
  }

  validateForm(){
    this.errorMsg = "";
    if(this.rateRule.Name == ""){
      this.errorMsg = "Please set the Rate rule name.";
      return false;
    }
    if(!this.stayDates || this.stayDates.length == 0){
      this.errorMsg = "Start/End date must be set and Start date must be smaller than End date.";
      return false;
    }
    if(this.selectedDaysOfWeek.length == 0){
      this.errorMsg = "Please select atleast one day of week.";
      return false;
    }
    if(this.rateRule.IsDelta && (this.rateRule.MinRate > this.rateRule.MaxRate)){
      this.errorMsg = "Please select Minimum Rate lesser than Maximum Rate.";
      return false;
    }
    if(!this.rateRule.UpdateRules || this.rateRule.UpdateRules.length == 0){
      this.errorMsg = "Please add atleast 1 valid Rate rule.";
      return false;
    }
    let isValid = false;
    this.rateRule.UpdateRules.forEach(compValue => {
      if(compValue.Inventory > 0){
        if(this.rateRule.IsDelta && compValue.RateDelta != null && compValue.RateDelta != undefined && Math.abs(compValue.RateDelta) >= 0){
          isValid = true;
        } else if(!this.rateRule.IsDelta && compValue.Rates){
          isValid = isValid || this.validateRoomRate(compValue.Rates);
        }
      }
    });
    if(!isValid){
      this.errorMsg ="Please set atleast 1 valid entry in Rates";
      return false;
    }
    return this.errorMsg == "";
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  //#region Add/Remove Rates
  removeRow(rowIndex){
    this.rateRule.UpdateRules.splice(rowIndex,1);
  }
  addRow(){
    if(!this.rateRule.UpdateRules) this.rateRule.UpdateRules = [];
    let compVal = new ComparatorValues()
    compVal.Rates = new RoomRate();
    compVal.Rates.PerOccupancy = {};
    compVal.Rates.ExtraChildren = {};
    this.rateRule.UpdateRules.push(compVal);
  }
  //#endregion Add/Remove Rates

  delta;
  nonContinouseUpdate;
  cancellation;
  hotelLevelInventory;
  onDeltaChange(value){
    this.rateRule.IsDelta = value;
  }

  onContinuousChange(value){
    this.rateRule.NonContinuousUpdates = value;
  }

  onCancellationChange(value){
    this.rateRule.UpdateOnCancellation = value;
  }

  onInventoryFlagChange(value){
    this.rateRule.HotelLevelInventory = value;
  }
}