import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mm-revenuecalcard',
  templateUrl: './revenuecalcard.component.html',
  styleUrls: ['./revenuecalcard.component.css']
})

// Refrence link of the control : 
// https://webdesign.tutsplus.com/tutorials/how-to-build-a-css-only-semi-circle-donut-chart--cms-26997
export class RevenuecalcardComponent implements OnInit {

  @Input("currentrev") currentRev:number;
  @Input("targetrev") targetRev:number;
  @Input("colorrev") colorRev;
  @Input("currentocc") currentOcc:number;
  @Input("targetocc") targetOcc:number;
  @Input("colorocc") colorOcc;
  @Input("centerlabel") centerLabel;
  @Input("centersublabel") centerSubLabel;
  @Input("rightlabel") rightLabel;
  @Input("leftlabel") leftLabel;
  //@Input("minval") minval =0;
  @Input("prefix") prefix = "";
  @Input("style") style;
  @Input("width") width;

  currRevPercent;
  targetRevPercent;
  remainingRevPercent;
  currOccPercent;
  targetOccPercent;
  remainingOccPercent;

  centerlabel
  constructor() { 
  }

  ngOnInit() {
    this.populateDonut();
  }
  populateDonut(){
    if(this.currentRev < this.targetRev){
      this.currRevPercent = (this.currentRev/this.targetRev)*100/150.0*180.0;
      this.targetRevPercent = 100/150.0*180.0;
      //this.remainingRevPercent = 99/150*180.0;
    } else {
      //this.currRevPercent = 99/150.0*180.0;
      this.targetRevPercent = 100/150.0*180.0;
      if(this.currentRev/this.targetRev < 1.5)
        this.currRevPercent = this.currentRev/this.targetRev*100/150.0*180.0;
      else
        this.currRevPercent = 180; 
    }

    if(this.currentOcc < this.targetOcc){
      this.currOccPercent = (this.currentOcc/this.targetOcc)*100/150.0*180.0;
      this.targetOccPercent = 100/150.0*180.0;
      //this.remainingOccPercent = 99/150*180.0;
    } else {
      //this.currOccPercent = 99/150.0*180.0;
      this.targetOccPercent = 100/150.0*180.0;
      if(this.currentOcc/this.targetOcc < 1.5)
        this.currOccPercent = this.currentOcc/this.targetOcc*100/150.0*180.0;
      else
        this.currOccPercent = 180; 
    }
  }
}
