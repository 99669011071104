import { Component, OnInit, HostListener, ViewChild, ViewContainerRef, ComponentFactoryResolver,Output,EventEmitter } from '@angular/core';
import { StorageService } from '../../ui-services/storage.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/primeng';
import { GridOptions } from 'ag-grid';
import { Subscription } from 'rxjs';
import { HotelManagementService } from '../../ui-services/hotelmanagement.service';
import { RoomtypedetailsComponent } from './roomtypedetails.component';
import { RoomTypeDetail } from 'mantras-api';
import { RoomRatePlan } from 'mantras-api';
import { Utilities } from '../../ui-services/utilities';

import * as jQuery from 'jquery';
import { UserService } from '../../ui-services/user.service';
import { CustomReuseStrategy } from '../../ui-services/reuse-routes';

@Component({
  selector: 'roomtypes',
  templateUrl: './roomtypes.component.html',
  styleUrls: ['./roomtypes.component.css']
})
export class RoomtypesComponent implements OnInit {
  breadCrumbItems: MenuItem[];
  roomtypesMenuItems: MenuItem[];
  columnDefs: any[];
  rowData: any[];
  gridOptions: GridOptions;
  rowSelection: any;
  rowGroupPanelShow: any;
  roomTypes;
  roomRatePlans: RoomRatePlan[];

  showDetailPage = false;
  icons;
  isReadOnly = false;
  subscription: Subscription;
  roomTypesSaveSubscription: Subscription;
  hotelContext;
  domainContext;
  @Output() onChangeMenuItems: EventEmitter<any>  = new EventEmitter();
  constructor(private resolver: ComponentFactoryResolver, private storageService: StorageService, private router: Router,
    private hotelMgmtService: HotelManagementService,private userService:UserService) {
      this.isReadOnly = userService.isReadOnly();
    this.breadCrumbItems = [{ label: "Settings",icon:'fa fa-wrench', routerLink: ["/settings"] },
    { label: "Room Types", routerLink: ["/settings/roomtypes"] }];
    this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    this.columnDefs = [
      { headerName: "Name", field: "Name", tooltipField: "Name", width: 120, menuTabs: [], cellClass: 'gridcol'},
      { headerName: "Code", field: "Code", tooltipField: "Code", width: 120, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Availability", field: "DefaultAvailability", tooltipField: "DefaultAvailability", width: 50, menuTabs: [], cellClass: 'gridcol rightalign' },
      { headerName: "Base Occupancy", field: "BaseOccupancy", tooltipField: "BaseOccupancy", width: 100, menuTabs: [], cellClass: 'gridcol rightalign' },
      { headerName: "Max Occupancy", field: "MaxOccupancy", tooltipField: "MaxOccupancy", width: 100, menuTabs: [], cellClass: 'gridcol rightalign' },
      { headerName: "Per Day", field: "RackRate", width: 80, menuTabs: [], cellClass: 'gridcol rightalign', valueFormatter: renderPerDay },
      { headerName: "Extra Person", field: "RackRate", width: 80, menuTabs: [], cellClass: 'gridcol rightalign', valueFormatter: renderExtraPerson },
      // { headerName: "Sort Order", field: "Tags", valueFormatter: getSortOrder, tooltipField: "Sort Order", width: 80, menuTabs: [], cellClass: 'gridcol rightalign',hide:true,sort:"asc" },
      { headerName: "Is Active", field: "IsActive", width: 50, menuTabs: [], cellClass: 'gridcol', cellRenderer: params => { return `<input type='checkbox' readonly onclick='return false;' ${params.value ? 'checked' : ''} value=${params.value ? 1 : 0}/>`; } },
      { headerName: "", width: 30, menuTabs: [] }
    ];

    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      showToolPanel: false,
      localeText: { noRowsToShow: "No room(s) found" }
    };
    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });
    this.roomTypesSaveSubscription = storageService.roomtypeContextListener$.subscribe(event => {
      if (event != "Roomtypes") {
        this.findRoomTypes();
      }
    })
    function getSortOrder(params) {
      console.log(params)
      if (params.value && Object.keys(params.value).length != 0) {
        return params.value["Display Order"];
      }
      else return 0;
    }
    this.domainContext = this.storageService.get(StorageService.currentUser).DomainContext;
  }
  async findRoomTypes(force = false) {

    let response = await this.hotelMgmtService.findRoomTypes(this.hotelContext.HotelId, force)
    this.roomTypes = response;
    if(!this.roomTypes) this.roomTypes = [];
    this.roomTypes = this.roomTypes.sort(Utilities.sortByMutipleFields("Name"));
    this.roomTypes = this.roomTypes.sort(Utilities.sortByDisplayOrder("Tags", "Display Order"));
    this.gridOptions.api.setRowData(response);
    this.gridOptions.api.sizeColumnsToFit();
    this.onGridReady(this);
 }
  async findRoomRatePlans(force = false) {
    let response = await this.hotelMgmtService.findRoomRatePlans(this.hotelContext.HotelId, force);
    this.roomRatePlans = response;

  }
  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
    let setSelected = false;
    this.gridOptions.api.forEachNode(node => {
      if (!node.group && !setSelected) { node.setSelected(true); this.onRowClick(node); setSelected = true; }
    });
    if (!setSelected) this.loadMenu();
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }
  onGridSizeChanged($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
  }
  ngOnInit() {
    this.loadMenu();
    this.findRoomTypes();
  }

  loadMenu() {
    this.roomtypesMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', disabled: true, command: (event) => this.open(event) },
      { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: true, command: (event) => this.confirmDelete(event, true) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
    ];
    this.getMenuItemData();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true,true,false,false));
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  onRowClick(params) {
    if (params.data) {
      let disabledDelete = false;
      if (params.data.Id.toLowerCase() == this.storageService.get(StorageService.loginUserId)) disabledDelete = true;
      this.roomtypesMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
        { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
        { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: this.isReadOnly || disabledDelete, command: (event) => this.confirmDelete(event, true) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
      ];
      this.getMenuItemData();
    }
  }

  onClickGridRow(params) {
    if (params.data)
      this.createNew(params.data);
  }

  //Refresh Promotion Grid
  onRefresh(event) {
    this.findRoomTypes(true);
    this.findRoomRatePlans(true);
  }
  //OnClose
  onClose() {
    this.router.navigate(["/settings"]);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    this.roomTypesSaveSubscription.unsubscribe();
  }

  //Create New RoomTypes
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  detailComponent;

  createNew(data?) {
    this.container.clear();
    let factory = this.resolver.resolveComponentFactory(RoomtypedetailsComponent);
    this.detailComponent = <RoomtypedetailsComponent>this.container.createComponent(factory).instance;
    if (data) this.detailComponent.roomType =  Utilities.clone(data) as RoomTypeDetail;
    else this.detailComponent.roomType = new RoomTypeDetail();
    this.detailComponent.roomTypeList = this.roomTypes;
    this.detailComponent.dataEmitter.subscribe($event => {
      this.refreshDetailMenuItems($event)
    });
    this.detailComponent.loadData();
    this.detailComponent.onClose.subscribe(this.onDetailsClose);
    this.showDetailPage = true;
    this.detailComponent.setHeight();
  }
  //Open Promotion
  open(event) {
    this.createNew(this.gridOptions.api.getSelectedRows()[0]);
  }
  //Delete Promotion
  @ViewChild('showConfirmationDialog') showConfirmationDialog;
  showDeleteConfirmation = false;
  deleteId;
  confirmDelete(params, fromGrid?) {
    if (!fromGrid) {
      if (params.node.data) {
        this.deleteId = params.node.data.Id;
        this.showDeleteConfirmation = true;
      }
    } else {
      let selectedRow = this.gridOptions.api.getSelectedRows();
      if (selectedRow && selectedRow.length > 0) {
        this.deleteId = selectedRow[0].Id;
        this.showDeleteConfirmation = true;
      }
    }
  }

  closeDialog(event) {
    this.deleteId = null;
    this.showDeleteConfirmation = false;
    this.setHeight();
  }

  async delete(event) {
    this.showDeleteConfirmation = false;
    if (this.deleteId != null) {
      let finalResponse = await this.hotelMgmtService.deleteRoomTypes(this.hotelContext.HotelId, this.deleteId).then(
        localresp => { return localresp; });
    }
    this.onRefresh(event);
  }

  onDetailsClose = (event): void => {
    this.showDetailPage = false;
    this.getMenuItemData();
    if (event) this.onRefresh(event);
    else this.setHeight();
  }
  refreshDetailMenuItems(event){
    this.onChangeMenuItems.emit(event);
  }
  getMenuItemData(){
    if(this.showDetailPage){
      this.detailComponent.getMenuItemData();
    } else {
      let data = [];
      data.push({'source':'roomTypes'});
      data.push(this.roomtypesMenuItems);
      this.onChangeMenuItems.emit(data);
    }
  }
}

function renderExtraPerson(param) {
  if (param.value && param.value.PerExtraPerson) return param.value.PerExtraPerson;
  else return "";
}

function renderPerDay(param) {
  if (param.value && param.value.PerDay) return param.value.PerDay;
  else return "";
}
