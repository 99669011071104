import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class EventManagementService {
    
    constructor() { }

    private filterSource = new Subject<any>();
    // Observable string streams
    filterChangeListener$ = this.filterSource.asObservable();

    filterChange(filterData) {
        this.filterSource.next(filterData);
    }

    //Setup Filter based on Data
    private setFilterSource = new Subject<any>();
    
    setFilterListener$ = this.setFilterSource.asObservable();

    setupFilter(filterSetup) {
        this.setFilterSource.next(filterSetup);
    }

    private sideNavChangeSource = new Subject<any>();

    sideNavChangeListener$ = this.sideNavChangeSource.asObservable();

    sideNavChange(sideNavChangeData){
        this.sideNavChangeSource.next(sideNavChangeData);
    }

    private loadBreadCrumbsSource = new Subject<any>();
    // Observable string streams
    loadBreadCrumbsListener$ = this.loadBreadCrumbsSource.asObservable();

    breadCrumbsChange(data) {
        this.loadBreadCrumbsSource.next(data);
    }
}