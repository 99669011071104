import { Component, OnInit, HostListener } from '@angular/core';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
@Component({
  selector: 'mm-comingsoon',
  templateUrl: './comingsoon.component.html',
  styleUrls: ['./comingsoon.component.css']
})
export class ComingsoonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.mainsection').css('height', Utilities.getScrollHeight(true));
  }
}
