import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mm-3dprogressbar',
  templateUrl: './progressbar3d.component.html',
  styleUrls: ['./progressbar3d.component.css']
})
export class ProgressbarComponent3d implements OnInit {

  constructor() { }

  @Input("current") current:number;
  @Input("target") target:number;
  @Input("label") label;
  @Input("color") color;
  @Input("minval") minval =0;
  @Input("prefix") prefix = "";
  @Input("style") style;
  currPercent;
  tarPercent;
  currPercenttop;
  width;
  ngOnInit() {
    if(this.current>this.target){
      this.currPercent = 80;
      this.tarPercent = (this.target*100/(this.current*1.2)).toFixed(2);
      this.currPercenttop = this.currPercent-5;
    } else {
      this.tarPercent = 80;
      this.currPercent = (this.current*100/(this.target*1.2)).toFixed(2);

      this.currPercenttop = (this.currPercent < 5)?0:this.currPercent-5;
    }
  }

  SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

  abbreviateNumber(number){

    // what tier? (determines SI symbol)
    let tier = Math.log10(number) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier == 0){
      if(number % 0 == 0)
        return number.toFixed(0);
      else if((parseFloat(number.toFixed(1))*10) % 10 == 0)
        return number.toFixed();
      else return number.toFixed(2);
    }
    // get suffix and determine scale
    let suffix = this.SI_SYMBOL[tier];
    let scale = Math.pow(10, tier * 3);

    // scale the number
    let scaled = number / scale;

    // format number and add suffix
    //Skip Zeros:
    if((parseFloat(scaled.toFixed(1))*10) % 10 == 0)
      return scaled.toFixed(0) + suffix;
    else 
      return scaled.toFixed(1) + suffix;
  }

  computeTop(){
    this.ngOnInit();
    return "calc("+this.currPercent+"% - 5px)";
  }
  computeBottom(){
    //this.ngOnInit();
    return this.currPercent+"%";
  }

  computeTargetWidth(){
    let numStr = this.target.toFixed(0);
    return "calc("+this.tarPercent +"% - " + (numStr.length/2*6) +"px)";
  }
}
