import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DomainsComponent } from '../domains/domains.component';
import { DomainProfileCriteria, DomainStatus } from 'mantras-api';
import { AuthenticationService } from '../ui-services/authentication.service';

@Component({
  selector: 'domainscriteria',
  templateUrl: './domainscriteria.component.html',
  styleUrls: ['./domainscriteria.component.css']
})
export class DomainsCriteriaComponent implements OnInit {
  selectedStatus: '0';
  status= [];
  domains:DomainsComponent;
  domainCriteria = new DomainProfileCriteria();
  filteredDomains: any[] = new Array();
  domainId: string;
  domainName: string;

  @Output('onsearch') onChange: EventEmitter<any>;

  constructor(private authService: AuthenticationService,) { 
    this.status.push({label: "All",value : 0})
    this.status.push({ label: "Pending", value: 1 });
    this.status.push({ label: "Rejected", value: 2 });
    this.status.push({ label: "Active", value: 3 });
    this.status.push({ label: "Inactive", value: 4 }); 
    this.onChange = new EventEmitter();
}

  ngOnInit() {
  }


public buildCriteria() {
    let returnVal = false;
    this.domainCriteria = new DomainProfileCriteria();
    if (this.selectedStatus && this.selectedStatus!='0') {
      this.domainCriteria.Status =  parseInt(this.selectedStatus);
      returnVal = true;
    }
    if (this.domainId && this.domainId != "") {
      //this.domainCriteria = new DomainProfileCriteria();
      this.domainCriteria.Ids = [];
      this.domainCriteria.Ids = this.domainId.split(',', 500);
      returnVal = true;
    }
    else {
      this.domainCriteria.Ids = null;
    }
    if (this.domainName && this.domainName != "") {
      //this.domainCriteria = new DomainProfileCriteria();
      this.domainCriteria.Name = this.domainName;   
      returnVal = true;
    }
    else {
      this.domainCriteria.Name = null;
    }
    return returnVal;
}

onClear(){
  this.domainId = null;
  this.domainName = null;
  this.selectedStatus = '0';
}

   onSearch(){
    if(this.buildCriteria()){
      this.onChange.emit(this.domainCriteria);
    }
    else{
   this.onChange.emit();
    }
  }

}
