import { Component, OnInit, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { StorageService } from '../ui-services/storage.service';
import { MenuItem, MessageService } from 'primeng/primeng';
import { Router } from '@angular/router';
import { DateRange, SyncLogCriteria } from 'mantras-api';
import { Utilities } from '../ui-services/utilities';
import { GridOptions } from 'ag-grid';
import { AnalyticsService, CompetitorGridData } from '../ui-services/analytics.service';
import { DatePipe } from '@angular/common';
import { Subscription, forkJoin } from 'rxjs';
import { EventManagementService } from '../ui-services/eventmanagement.service';
import { AuthenticationService } from '../ui-services/authentication.service';
import { DatexPipe } from '../components/transformer/datexpipe.transformer';
import * as jQuery from 'jquery';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';


@Component({
  selector: 'competitiontracking',
  templateUrl: './competitiontracking.component.html',
  styleUrls: ['./competitiontracking.component.css']
})
export class CompetitiontrackingComponent implements OnInit, AfterViewInit {
  channels = ["AGD", "BDC", "CLT", "EXP", "GIB", "MMT", "YAT"];
  filteredChannels = [];
  filteredCompetitors = [];
  criteraChannels = [];
  items: MenuItem[];
  trendAnalyticsMenuItems: MenuItem[];
  columnDefs: any[];
  rowData: any[];
  rowGroupPanelShow: any;
  gridOptions: GridOptions;
  icons;
  rowSelection: any;
  rowClassRules;
  grouping = 1;
  groupingOptions;
  isResultsRendered = false;
  subscription: Subscription;
  @ViewChild("trendChart") trendChart;

  options = [];
  selectedView = { label: "Graph view", value: 2 };
  competitorGraphOption;
  competitorGraphData;
  filteredOn = 0;
  filterOptionsMap;
  filterSubscription: Subscription;
  competitorData = [];
  navigationDate = Utilities.getUTCDate(new Date());
  displayDates;
  competitors;
  hotelInfo;
  hotelContext;
  chartHeight = "300px"
  constructor(private storageService: StorageService, private messageService: MessageService, private router: Router, private analyticsService: AnalyticsService,
    private authenticationService: AuthenticationService) {
    let domains = this.storageService.get(StorageService.currentUser).DomainContext.Domains;
    this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    this.items = [{ label: "Analytics", icon: 'fa fa-line-chart', routerLink: ["/analytics"] },
    { label: "Competitor Analytics", routerLink: ["/analytics/competitiontracking"] }];

    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }

    this.rowClassRules = {
      "current_hotel_highlight": function (params) {
        if (params.data && params.data != null)
          return params.data.HotelId == params.context.thisComponent.hotelContext.HotelId;
      }
    }

    this.gridOptions = <GridOptions>{
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      showToolPanel: false,
      localeText: { noRowsToShow: "No records(s) found" },
      rowClassRules: this.rowClassRules,
      context: { thisComponent: this }
    };

    //Highlight first group row 
    this.gridOptions.getRowClass = function (params) {
      if (params.node.group && params.node.level == 0) {
        return 'group-background';
      }
    }

    this.gridOptions.suppressLoadingOverlay = true;
    //Set the current class context (this) in gridOption context
    this.gridOptions.context = { thisComponent: this };

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });
    this.renderMenu();

    this.options = [{ label: "Graph view", value: 2 }, { label: "Hotel view", value: 0 }, { label: "Channel view", value: 1 }, { label: "Price Analysis View", value: 3 },];

    let domainHotel = this.authenticationService.getDomainHotel(this.hotelContext.HotelId);
    this.competitors = domainHotel.CompetitorHotels;
    //TODO: This is to remove the target hotel id which is duplicated in the list.
    if (this.competitors && this.competitors.length >= 2) {
      //Check first and last index hotel id and remove last if its same.
      if (this.competitors[0] == this.competitors[this.competitors.length - 1]) {
        this.competitors.pop();
      }
    }
  }

  getColumnDef() {
    this.columnDefs = [
      { headerName: "", cellClass: 'gridcol', showRowGroup: true, width: 50, menuTabs: [] },
      { headerName: "Hotel", field: "HotelName", tooltipField: "HotelName", rowGroup: this.selectedView.value == 0, hide: this.selectedView.value == 0, enableRowGroup: true, width: 100, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Channel", field: "ChannelCode", tooltipField: "ChannelCode", rowGroup: this.selectedView.value == 1, hide: this.selectedView.value == 1, enableRowGroup: true, valueFormatter: formatChannelType, width: 100, menuTabs: [], cellClass: 'gridcol' }
      // { headerName: "Page", field: "PageNumber", enableRowGroup: true, valueFormatter: calculateAverageCount, width: 50, menuTabs: [], cellClass: 'gridcol', comparator: pageComparator },
      // { headerName: "Rank", field: "Rank", enableRowGroup: true, valueFormatter: calculateAverageCount, width: 50, menuTabs: [], cellClass: 'gridcol', comparator: rankComparator }
    ];
    //Run a loop on the rrp and push the headers in the columnDefs.
    if (this.displayDates) {
      let datePipe = new DatePipe('en-US')
      let index = 0;
      this.displayDates.forEach(stayDate => {
        let dateColumn = (stayDate) ? datePipe.transform(stayDate, 'EEE dd MMM') : '';
        this.columnDefs.push({
          headerName: dateColumn, 
          field: "Rates." + index, 
          colId: index++, 
          valueFormatter: formatNumber, 
          width: 75, 
          menuTabs: [], 
          cellClass: 'gridcol  rightalign'
        });
      });
    }
    this.columnDefs.push({ headerName: "", menuTabs: [], cellClass: 'gridcol',width: 30 });
    if (this.selectedView.value == 0)
      this.columnDefs[2].sort = "asc";
    else
      this.columnDefs[1].sort = "asc";
  }

  renderMenu() {
    this.trendAnalyticsMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.getCompetitorData() },
      { title: 'Export to excel', label: 'Export', icon: 'action-bar-menu-icon fa fa-file-excel-o', command: (event) => this.ShowExportDialog() },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
    ];
  }

  ngOnInit() {
  }

  async onGridReady($event) {
    this.hotelInfo = await this.analyticsService.findHotels(this.competitors);
    this.getCompetitorData();
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }

  onGridSizeChanged($event) {
  }
  competitorResponse;
  competitorMap;

  getCompetitorData() {
    let requestArray = [];
    //let channelTypes = this.channels;
    let hotelId = [];
    hotelId.push(this.hotelContext.HotelId);
    this.hotelInfo.forEach(element => {
      hotelId.push(element.Id);
    });
    let dateRange = new DateRange();
    dateRange.Start = this.navigationDate;
    let endDate = new Date(this.navigationDate);
    endDate.setDate(endDate.getDate() + 14);
    dateRange.End = endDate;
    let response = [];
    //Fetching Data for each channel check before it exist
    let req = this.analyticsService.findChannelRates(hotelId, dateRange, null, null);
    requestArray.push(req);

    if (requestArray.length > 0) {
      forkJoin(requestArray).subscribe(results => {
        console.log("Parallel Request: ", results);
        for (let idx = 0; idx < results.length; idx++) {
          let resp = results[idx];
          if (resp && resp != null) {
            if (resp instanceof Array) {
              response = response.concat(resp);
            }
          }
        }
        this.competitorResponse = response;
        this.processCompetitorData();
      });
    }
  }


  dateChange() {
    this.firstLoad = true;
    this.getCompetitorData();
  }

  firstLoad = true;
  hotelNames = new Map<string, string>();
  processCompetitorData() {
    this.competitorData = [];
    this.filteredCompetitors = []
    this.selectedCompetitors = [];
    this.channelTypes = [];
    this.filteredChannels = [];
    if (this.competitorResponse) {
      this.displayDates = this.getSortedStayDates(this.competitorResponse);
      console.log("Display Dates:", this.displayDates);
      this.competitorMap = new Map();
      this.competitorResponse.forEach(comp => {
        if (comp.Rate != 0 || comp.PageNumber != 0 || comp.Rank != 0) {
          let gridCompData = this.competitorMap.get(comp.HotelId + "::" + comp.ChannelCode);
          if (gridCompData == null || gridCompData == undefined) {
            gridCompData = new CompetitorGridData();
            gridCompData.ChannelCode = comp.ChannelCode;
            gridCompData.HotelId = comp.HotelId;
            if (this.hotelNames.get(comp.HotelId))
              gridCompData.HotelName = this.hotelNames.get(comp.HotelId);
            else {

              this.hotelNames.set(comp.HotelId, this.findHotelName(comp.HotelId));
              gridCompData.HotelName = this.hotelNames.get(comp.HotelId)
            }
            gridCompData.Rates = new Array(this.displayDates.length);
            gridCompData.Count = 0;
            gridCompData.PageNumber = 0;
            gridCompData.Rank = 0;
            this.competitorMap.set(comp.HotelId + "::" + comp.ChannelCode, gridCompData);
            this.competitorData.push(gridCompData);
            if (this.channelTypes.indexOf(comp.ChannelCode) < 0) {
              this.channelTypes.push(comp.ChannelCode);
              this.filteredChannels.push({ label: Utilities.findId(Utilities.getChannelTypes(['AGO'], 'competitor'), comp.ChannelCode, comp.ChannelCode), value: comp.ChannelCode });
              //console.log(this.filteredChannels);
            }
            if (this.selectedCompetitors.indexOf(comp.HotelId) < 0) {
              this.selectedCompetitors.push(comp.HotelId);
              this.filteredCompetitors.push({ value: comp.HotelId, label: this.findHotelName(comp.HotelId) });
            }
          }
          gridCompData.PageNumber += comp.PageNumber;
          gridCompData.Rank += comp.Rank;
          gridCompData.Count++;
          gridCompData.Rates[this.displayDates.map(Number).indexOf(+new Date(comp.StayDate))] = comp.Rate;
        }
      });
      if (this.competitorData.length > 0)
        this.competitorData = this.competitorData.sort(Utilities.sortByMutipleFields("HotelName", "ChannelCode"));
      if (this.firstLoad) {
        this.selectedChannelTypes = this.channelTypes;
        this.selectedChannelCode = this.channelTypes[0];
        this.firstLoad = false;
        this.filteredCompetitors.sort(Utilities.sort_label_asc);
      }

      this.isFilterEnabled = true;
      this.onViewChange();
    } else {
      this.isFilterEnabled = false;
    }
  }

  bindCompetitorData(param?) {
    if (!param) {
      this.getColumnDef();
      this.gridOptions.api.setColumnDefs(this.columnDefs);
      let filteredData = this.filteredCompetitorData();
      this.gridOptions.api.setRowData(filteredData);
    } else {
      param.getColumnDef();
      param.gridOptions.api.setColumnDefs(param.columnDefs);
      let filteredData = param.filteredCompetitorData();
      param.gridOptions.api.setRowData(filteredData);
    }

  }

  filteredCompetitorData() {
    let filteredData = []
    this.competitorData.forEach(compData => {
      if (this.selectedChannelTypes.indexOf(compData.ChannelCode) >= 0 && this.selectedCompetitors.indexOf(compData.HotelId) >= 0)
        filteredData.push(compData);
    });
    return filteredData;
  }

  getSortedStayDates(response) {
    let stayDates = Array();
    response.forEach(compData => {
      let date = new Date(compData.StayDate);
      if (stayDates.map(Number).indexOf(+date) < 0)
        stayDates.push(date);
    });
    return stayDates.sort(Utilities.date_sort_asc);
  }

  onExport(event) {
    let dateRange = new DateRange();
    dateRange.Start = Utilities.convertToUTC(this.rangeDates[0]);
    if (this.rangeDates.length == 1)
      dateRange.End = dateRange.Start;
    else
      dateRange.End = (this.rangeDates[1] != null) ? Utilities.convertToUTC(this.rangeDates[1]) : dateRange.Start;
    this.showExportDialog = false;
    this.analyticsService.exportCompetitorRates(this.hotelContext.HotelId, this.selectedChannels, dateRange.Start, dateRange.End);
  }

  onClose() {
    this.router.navigate(["/analytics"]);
  }

  @HostListener('window:resize') setHeight() {
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true));
    jQuery('.chartHeight').css('height', Utilities.getScrollHeight(true));
    this.chartHeight = String(Utilities.getScrollHeight(true)) + "px";
    if (this.trendChart) this.trendChart.refresh();
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  @ViewChild('analyticsFilter') analyticsFilter;
  onViewChange() {
    this.analyticsFilter.setupAnalyticsFilter({
      isInTrendAnalytics: false,
      isInCompetitorAnalytics: true,
      isGraphView: this.selectedView.value == 2,
      channelTypes: this.channelTypes,
      competitors: this.filteredCompetitors,
      hotelInfo: this.hotelInfo,
      selectedChannelTypes: this.selectedChannelTypes,
      selectedCompetitors: this.selectedCompetitors,
      selectedChannelCode: this.selectedChannelCode
    });
    if (this.selectedView.value < 2)
      this.bindCompetitorData();
    else if (this.selectedView.value == 2)
      window.setTimeout(this.populateGraphView, 500, this);
    else
      window.setTimeout(this.populateAverageGraphView, 500, this);
  }

  onAnalyticsFilterChange(filterData) {
    this.selectedCompetitors = filterData["selectedCompetitors"];
    if (this.selectedView.value < 2) {
      this.selectedChannelTypes = filterData["selectedChannelTypes"];
      this.bindCompetitorData();
    } else if (this.selectedView.value == 2) {
      this.selectedChannelCode = filterData["selectedChannelCode"];
      this.populateGraphView();
    } else {
      this.selectedChannelTypes = filterData["selectedChannelTypes"];
      this.populateAverageGraphView();
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  backgroundColor = Utilities.backgroundColor;
  selectedChannelTypes = [];
  selectedCompetitors;
  channelTypes = [];
  selectedChannelCode;
  populateGraphView(param?) {
    let that = this;
    if (param) that = param;
    let labels = [];
    let dataSet = [];
    if (that.competitorData && that.competitorData.length > 0) {
      let index = 0;
      that.competitors.forEach(comp => {
        let compData = that.competitorMap.get(comp + "::" + that.selectedChannelCode);
        if (that.selectedCompetitors.indexOf(comp) >= 0 && compData && compData != null) {
          if (compData.Rates && compData.Rates.length > 0) {
            let backColor;
            if (compData.HotelId == that.hotelContext.HotelId) {
              backColor = "#000000";
              dataSet.push({ label: compData.HotelName, data: compData.Rates, borderColor: backColor, backgroundColor: backColor, borderDash: [2, 3], fill: false });
            } else {
              backColor = that.backgroundColor[index];
              dataSet.push({ label: compData.HotelName, data: compData.Rates, borderColor: backColor, backgroundColor: backColor, fill: false });
            }
          }
        }
        index++;
      });
      that.competitorGraphData = {};
      that.competitorGraphData["labels"] = that.formatLabel(that.displayDates);
      that.competitorGraphData["datasets"] = dataSet;
      that.competitorGraphOption = {
        title: {
          display: true,
          text: Utilities.findId(Utilities.getChannelTypes(['AGO'], 'competitor'), that.selectedChannelCode),
          fontSize: 15
        },
        legend: {
          position: 'bottom'
        }
      };
    }
  }
  populateAverageGraphView(param?) {
    let that = this;
    if (param) that = param;
    let labels = [];
    let dataSet = [];
    let averageGraphView = new Map<string, any[]>()
    if (that.competitorData && that.competitorData.length > 0) {
      let index = 0;
      that.competitorData.forEach(compData => {
        if (that.selectedChannelTypes.indexOf(compData.ChannelCode) >= 0 && that.selectedCompetitors.indexOf(compData.HotelId) >= 0) {
          let graphData = averageGraphView.get(compData.HotelId);
          if (!graphData) {
            averageGraphView.set(compData.HotelId, JSON.parse(JSON.stringify(compData.Rates)));
          } else {
            that.setLowestRates(graphData, compData.Rates);
          }
        }
      });
      let averageRates = new Array(that.displayDates.length);
      let avgRates = new Array(that.displayDates.length);
      let backColor;
      averageGraphView.forEach((value: any[], key: string) => {
        if (key != that.hotelContext.HotelId) {
          for (let c = 0; c < value.length; c++) {
            if (value[c] && value[c] > 0) {
              averageRates[c] ? averageRates[c].push(value[c]) : averageRates[c] = [value[c]];
              avgRates[c] = (avgRates[c] ? avgRates[c] : 0) + value[c];
            }
          }
        }
        if (key == that.hotelContext.HotelId) {
          backColor = "#000000";
          dataSet.push({ label: that.hotelNames.get(key), data: value, borderColor: backColor, backgroundColor: backColor, fill: false });
        } else {
          backColor = that.backgroundColor[index];
          dataSet.push({ label: that.hotelNames.get(key), data: value, borderColor: backColor, backgroundColor: backColor, borderDash: [2, 3], fill: false });
          index++;
        }
      });
      for (let i = 0; i < avgRates.length; i++) {
        if (averageRates[i] && averageRates[i].length > 0)
          avgRates[i] = (avgRates[i] / averageRates[i].length).toFixed(2);
      }
      backColor = "#FF0000";
      dataSet.push({ label: "Average", data: avgRates, borderColor: backColor, backgroundColor: backColor, fill: false });
      that.competitorGraphData = {};
      that.competitorGraphData["labels"] = that.formatLabel(that.displayDates);
      that.competitorGraphData["datasets"] = dataSet;
      that.competitorGraphOption = {
        title: {
          display: true,
          text: "Analysing the Competitors",
          fontSize: 15
        },
        legend: {
          position: 'bottom'
        }
      };
    }
  }

  setLowestRates(graphData, rates) {
    for (let i = 0; i < graphData.length; i++) {
      if (rates[i] && rates[i] > 0) {
        if (graphData[i]) {
          graphData[i] = graphData[i] < rates[i] ? graphData[i] : rates[i];
        } else {
          graphData[i] = rates[i];
        }
      }
    }
  }

  formatLabel(labels) {
    let formatedLabel = [];
    labels.forEach(dateLabel => {
      formatedLabel.push(formatDate({ value: dateLabel }));
    });
    return formatedLabel;
  }

  findHotelName(hotelId: string) {
    let name = Utilities.findId(this.hotelInfo, hotelId, hotelId);
    if (hotelId == this.hotelContext.HotelId) {
      name += " (You)";
    }
    return name;
  }

  showExportDialog = false;
  selectedChannels = [];
  rangeDates;
  ShowExportDialog() {
    this.selectedChannels = [];
    let endDate = Utilities.getUTCDate(this.navigationDate);
    endDate.setDate(endDate.getDate() + 14);
    this.rangeDates = this.rangeDates = [this.navigationDate, endDate];
    this.showExportDialog = true;
  }

  isFilterEnabled = false;
  showFilter = false;
  hideShowFilterCriteria() {
    if (this.isFilterEnabled)
      this.showFilter = !this.showFilter;
  }

  @ViewChild('navDate') navDate;
  dateFormat = new DatePipe('en-US');
  prevWeek(event) {
    this.navigationDate.setDate(this.navigationDate.getDate() - 14);
    this.navDate.inputFieldValue = this.dateFormat.transform(new Date(this.navigationDate), "dd-MMM-yyyy");
    this.navDate.defaultDate = this.navigationDate;
    this.navDate.ngOnInit();
    this.dateChange();
  }

  nextWeek(event) {
    this.navigationDate.setDate(this.navigationDate.getDate() + 14);
    this.navDate.inputFieldValue = this.dateFormat.transform(new Date(this.navigationDate), "dd-MMM-yyyy");
    this.navDate.defaultDate = this.navigationDate;
    this.navDate.ngOnInit();
    this.dateChange();
  }
  showCompetitorHistoryDialog = false;
  rateHistoryChannel;
  rateHistoryHotel;
  rateHistoryDate;
  rateHistoryLastSyncTime;

  //Grid Context Menu
  getContextMenuItems = function getContextMenuItems(params) {
    console.log("Inside ContextMenu", params);
    params.context.thisComponent.checkIfRateURL(params);
    params.context.thisComponent.disableVerify;
    var result;
    if (params.column.colDef.colId >= 0) {
      result = [
        {
          name: "History",
          action: function () {
            params.context.thisComponent.showCompetitorHistory(params);//competitorLogCriteria
            console.log("History Action");
          },
          icon: '<span class="fa fa-history" />'
        },
        {
          name: "Verify",
          disabled: params.context.thisComponent.disableVerify,
          action: function () {
            params.context.thisComponent.verifyRateUrl(params);
            console.log("Verify");
          },
          icon: '<span class="fa fa-check" />'
        }
      ];
    }
    return result;
  }
  competitorLogsGridOptions = <GridOptions>{
    enableSorting: true,
    animateRows: true,
    groupDefaultExpanded: -1,
    enableColResize: true,
    rowHeight: 24,
    groupUseEntireRow: true,
    suppressDragLeaveHidesColumns: true,
    localeText: { noRowsToShow: "No history found" },
    suppressContextMenu: true,
    getRowClass: function (params) {
      if (params.node.group && params.node.level == 0) {
        return 'group-background';
      }
    }
  };

  chUrl: string = '';
  disableVerify = true;
  checkIfRateURL(params) {
    this.chUrl = '';
    let chCode = params.node.data.ChannelCode;
    let hotelID = params.node.data.HotelId;
    let today = new DatexPipe().transform(new Date(), "DD-MMM");
    let selectedDate = params.column.colDef.headerName;
    let selectedFormatedDate = new Date(selectedDate);
    let selectedDateString = new DatexPipe().transform(selectedFormatedDate, "DD-MMM");
    for (let cr of this.competitorResponse) {
      let crStayDate = new DatexPipe().transform(cr.StayDate, "DD-MMM");
      if (cr.ChannelCode == chCode && crStayDate == selectedDateString && crStayDate >= today && hotelID == cr.HotelId) {
        if (cr.RateUrl != '' && cr.RateUrl != null && cr.RateUrl != undefined) {
          this.chUrl = cr.RateUrl;
          this.disableVerify = false;
          break;
        }
      }
      else {
        this.disableVerify = true;
      }
    }
    return this.disableVerify;
  }

  verifyRateUrl() {
    const url = this.chUrl;
    window.open(url, '_blank');
  }

  async showCompetitorHistory(params) {
    params.node.setSelected(true);
    let columnNumber = params.column.colDef.colId;
    console.log(this.navigationDate);
    let rowData = params.node.data;
    console.log(rowData);
    let competitorLogCriteria = new SyncLogCriteria();
    competitorLogCriteria.ChannelCode = rowData.ChannelCode;
    competitorLogCriteria.HotelId = rowData.HotelId;
    competitorLogCriteria.StayDate = new DateRange();
    competitorLogCriteria.StayDate.Start = new Date();
    competitorLogCriteria.StayDate.Start.setUTCDate(this.navigationDate.getUTCDate() + 1);
    competitorLogCriteria.StayDate.Start.setUTCDate(competitorLogCriteria.StayDate.Start.getUTCDate() + columnNumber);
    competitorLogCriteria.StayDate.Start.setHours(5, 30, 0, 0);
    competitorLogCriteria.StayDate.End = competitorLogCriteria.StayDate.Start;
    let competitorData = await this.analyticsService.findChannelSyncLog(competitorLogCriteria);
    this.competitorLogsGridOptions.api.setColumnDefs(this.getCompetitorLogsColumnDef());
    this.competitorLogsGridOptions.api.setRowData(competitorData);
    let lastSyncTime = new Date(Math.max.apply(null, competitorData.map(function (e) {
      return new Date(e.SyncTimestamp);
    })));
    this.setRateHistoryHeader(rowData.ChannelCode, rowData.HotelName, competitorLogCriteria.StayDate.Start, lastSyncTime);
    this.onSyncCompetitorLogsGridReady(null);
    this.showCompetitorHistoryDialog = true;
  }
  getCompetitorLogsColumnDef() {
    let competitorColDef = new Array();
    competitorColDef.push({ field: "", width: 50, pinned: "left", menuTabs: [] });
    competitorColDef.push({ field: "GroupHeader", hide: true, rowGroup: true, menuTabs: [] });
    competitorColDef.push({
      headerName: "Timestamp", width: 100, cellClass: 'gridcol', field: "SyncTimestamp",
      pinned: "left", sort: "desc", menuTabs: [], valueFormatter: function (data) {
        if (data.value) return new DatexPipe().transform(data.value, "DD-MMM-YY hh:mm:ss a");
      }
    });
    competitorColDef.push({ headerName: "Rate", cellClass: 'gridcol rightalign', width: 75, tooltipField: "Rate", field: "Rate", valueFormatter: formatRate, menuTabs: [] });
    // competitorColDef.push({ headerName: "Page Number", cellClass: 'gridcol rightalign', width: 100, tooltipField: "PageNumber", field: "PageNumber", menuTabs: [] });
    // competitorColDef.push({ headerName: "Rank", cellClass: 'gridcol rightalign', width: 75, tooltipField: "Rank", field: "Rank", menuTabs: [] });
    return competitorColDef;
  }

  onSyncCompetitorLogsGridReady(params) {
    this.competitorLogsGridOptions.api.sizeColumnsToFit();
  }

  onSyncCompetitorLogsGridSizeChanged(params) {
    this.competitorLogsGridOptions.api.sizeColumnsToFit();
  }
  setRateHistoryHeader(channel, name, date, lastSyncTime) {
    this.rateHistoryChannel = this.getChannelType(channel);
    this.rateHistoryHotel = name;
    this.rateHistoryDate = date;
    this.rateHistoryLastSyncTime = lastSyncTime;
  }
  getChannelType(channelCode) {
    return Utilities.findId(Utilities.getChannelTypes(['AGO'], 'competitor'), channelCode, channelCode);
  }
}

function formatDate(param) {
  return new DatePipe('en-US').transform(param.value, "EEE dd MMM");
}

function formatChannelType(param) {
  return Utilities.findId(Utilities.getChannelTypes(['AGD'], 'competitor'), param.value, param.value);
}

function formatNumber(param) {
  let val = Math.round(param.value);
  return (val != 0) ? val : "-";
}
function formatRate(param) {
  return Math.round(param.value);
}
function calculateAverageCount(param) {
  return Math.round(param.value / param.data.Count);
}

function pageComparator(data1, data2, node1, node2, isInverted) {
  if (data1 && data2 && node1.data && node2.data) {
    if (Math.round(data1 / node1.data.Count) == Math.round(data2 / node2.data.Count)) {
      return Math.round(node1.data.Rank / node1.data.Count) - Math.round(node2.data.Rank / node2.data.Count);
    }
    return Math.round(data1 / node1.data.Count) - Math.round(data2 / node2.data.Count);
  }
  return data1 - data2;
}

function rankComparator(data1, data2, node1, node2, isInverted) {
  if (data1 && data2 && node1.data && node2.data) {
    if (Math.round(data1 / node1.data.Count) == Math.round(data2 / node2.data.Count)) {
      return Math.round(node1.data.PageNumber / node1.data.Count) - Math.round(node2.data.PageNumber / node2.data.Count);
    }
    return Math.round(data1 / node1.data.Count) - Math.round(data2 / node2.data.Count);
  }
  return data1 - data2;
}
