export enum PromotionViewType { ChannelView = 0, PromotionView = 1 }

export interface HotelContext {
    HotelId: string;
    HotelName: string;
    RoomTypes: RoomType[];
    RatePlans: RatePlan[];
    RoomRatePlans: RoomRatePlan[];
    ChannelCodes: string[];
}

export interface RoomType {
    Id: string;
    Name: string;
    LinkedId: string;
    sortOrder: number;
    MaxOccupancy: number;
    BaseOccupancy: number;
    ChannelRooms: ChannelData[];
}

export interface RatePlan {
    Id: string;
    Name: string;
    LinkedId: string;
    sortOrder: number;
    ChannelRatePlans: ChannelData[];
}

export interface ChannelData {
  Id: string;
  Name: string;
  ChannelCode: string;
}

export interface RoomRatePlan {
    RoomId: string;
    RatePlanId: string;
    LinkedId: string;
    LinkedOccupancy: number[];
}