import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AfterViewInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { GridOptions, ColDef } from 'ag-grid';
import { DashboardService } from '../../ui-services/dashboard.services';

@Component({
  selector: 'mm-table-widget',
  templateUrl: './table-widget.component.html',
  styleUrls: ['./table-widget.component.css']
})
export class TableWidgetComponent implements OnInit, AfterViewInit {
  @Input('topheader') topheader;
  @Input('footer') footer;
  @Input('widgetstyle') widgetStyle;
  @Input('tabledata') tabledata;
  @Input('headerfields') headerfields;
  @Input('localetext') localetext = "No record(s) found.";
  @Input('displayNavigateIcons') displayNavigateIcons;
  @Output('formatData') formatData: EventEmitter<any>;
  @Output('onNavigateClick') onNavigateClick: EventEmitter<any>;

  gridOptions;
  icons;
  columnDef;
  gridData;
  overlayLoadingTemplate;
  constructor(dashboardService: DashboardService) {
    this.formatData = new EventEmitter();
    this.onNavigateClick = new EventEmitter();
    this.overlayLoadingTemplate = '<div><div class="loadersmall"></div></div>'
    this.gridOptions = <GridOptions>{
      enableSorting: true,
      animateRows: true,
      groupDefaultExpanded: -1,
      rowHeight: 24,
      groupUseEntireRow: true,
      singleClickEdit: false,
      groupRowRendererParams: { suppressCount: true },
      suppressDragLeaveHidesColumns: true,
      suppressContextMenu: true,
      overlayLoadingTemplate: this.overlayLoadingTemplate,
    };

    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }

  }

  ngOnInit() {
    this.populateCloumnDef();
  }

  ngAfterViewInit(): void {

  }
  //#region Grid Methods
  onGridReady(event) {
    this.gridOptions.api.showLoadingOverlay();
    this.tabledata.then(data => {
      this.gridData = data;
      this.formatData.emit(data);
      if(data.length == 0) this.footer = "";
      this.gridOptions.api.setColumnDefs(this.columnDef);
      this.gridOptions.api.setRowData(this.gridData);
      this.gridOptions.api.sizeColumnsToFit();
    });
  }

  onGridSizeChanged(event) {

  }

  onClickGridRow(event) {

  }
  
  //#endregion Grid Methods

  populateCloumnDef() {
    this.columnDef = [];
    let rowGrouping = false;
    if (this.headerfields && this.headerfields.length > 0) {
      this.headerfields.forEach(headerField => {
        let colDef = {};
        if (headerField.headerName) { colDef["headerName"] = headerField.headerName; colDef["headerTooltip"] = headerField.headerName; }
        if (headerField.field) { colDef["field"] = headerField.field; colDef["tooltipField"] = headerField.field; }
        if (headerField.rowGroup) { colDef["rowGroup"] = headerField.rowGroup; rowGrouping = true; }
        if (headerField.width) colDef["width"] = headerField.width;
        colDef["cellClass"] = "gridcol";
        colDef["menuTabs"] = [];
        this.columnDef.push(colDef);
      });
      if (rowGrouping) {
        let blankCol = [{ width: 50, cellClass: "gridcol", menuTabs: [] }];
        this.columnDef = blankCol.concat(this.columnDef);
      }
      this.gridOptions.localeText = { noRowsToShow: this.localetext }
    }
  }
  onNavigate(headerTitle,buttonClicked){
    let data = {
      "headerTitle":headerTitle,
      "buttonClicked":buttonClicked
    };
    this.onNavigateClick.emit(data);
  }
}
