import { Injectable } from "@angular/core";
import { AppService } from "mantras-api";
import { StorageService } from "./storage.service";
import { Utilities } from "./utilities";
import { LoadingService } from "./loading.service";
import { BookingAnalyticsCriteria, BookingReportItem } from "mantras-api";
import { DatePipe } from "@angular/common";
import { BookingService } from "./booking.service";
import { BookingStatus } from "mantras-api";
import { DateRange, CompetitorItem, HotelCriteria } from "mantras-api";
import { RatecalendarService } from './ratecalendar.service';
import { AppConfigService } from "../app-config.service";

@Injectable()
export class AnalyticsService {
    //mantras: AppService;
    datePipeTransform = new DatePipe('en-US');
    constructor(private mantras: AppService, private storageService: StorageService, private utilities: Utilities,
        private loadingService: LoadingService, private bookingService: BookingService,private appConfService:AppConfigService, private rcService: RatecalendarService) {
        Utilities.initApp(mantras,appConfService);
    }

    getBookingAnalyticsReport(bookingAnalyticsCriteria: BookingAnalyticsCriteria) {
        console.log("Inside the GetBookingAnalyticsReport.");
        let response = this.loadingService.interceptor(this.mantras.getBookingAnalyticsReport(bookingAnalyticsCriteria).then
            (response => {
                return this.convertToPivotViewerData(response);
            }), true);
        return response;
    }

    getBookingAnalyticsReportPie(bookingAnalyticsCriteria: BookingAnalyticsCriteria) {
        let response = this.mantras.getBookingAnalyticsReport(bookingAnalyticsCriteria).then(response => { return response; });
        return response;
    }

    getBookingTrends(bookingAnalyticsCriteria: BookingAnalyticsCriteria) {
        console.log("Inside the GetBookingAnalyticsReport.");
        return this.loadingService.interceptor(this.mantras.getBookingTrends(bookingAnalyticsCriteria).then
            (response => {
                return response;
            }), true);
    }

    exportBookingAnalyticsReport(bookingAnalyticsCriteria: BookingAnalyticsCriteria) {
        console.log("Inside the GetBookingAnalyticsReport.");
        return this.loadingService.interceptor(this.mantras.exportBookingAnalyticsReport(bookingAnalyticsCriteria).then
            (response => {
                return Utilities.saveToFileSystem(response, "ExportBookingAnalytics.xlsx");
            }), true);
    }

    exportBookingTrends(bookingAnalyticsCriteria: BookingAnalyticsCriteria) {
        console.log("Inside the GetBookingAnalyticsReport.");
        return this.loadingService.interceptor(this.mantras.exportBookingTrends(bookingAnalyticsCriteria).then
            (response => {
                return Utilities.saveToFileSystem(response, "ExportBookingTrends.xlsx");
            }), true);
    }

    convertToBookingAnalyticsData(bookingAnalyticsItems: BookingReportItem[]) {
        let bookingItems = [];
        let bookingAnalyticsResponse;
        let channelCodeImages = ["AGO", "BDC", "CLT", "EXP", "GIB", "MMT", "TAD", "TLG", "VIA", "YAT"];
        bookingAnalyticsItems.forEach(bookingItem => {
            let bookAnaItem = new BookingAnalyticsItem();
            bookAnaItem.Id = bookingItem.BookingId;
            bookAnaItem.Name = Utilities.findId(Utilities.channelTypes, bookingItem.ChannelCode, bookingItem.ChannelCode);
            let bookingStatus = this.bookingService.statusMap[bookingItem.Status];
            //bookAnaItem.Img = "globe.jpg";
            // if(bookingItem.Status == BookingStatus.Confirmed || bookingItem.Status == BookingStatus.Cancelled)
            //     bookAnaItem.Img = bookingStatus.toLocaleLowerCase()+".png";
            // else
            //     bookAnaItem.Img = "other.png";
            if (channelCodeImages.indexOf(bookingItem.ChannelCode) >= 0)
                bookAnaItem.Img = bookingItem.ChannelCode + ".png";
            else
                bookAnaItem.Img = "OTA.png";
            bookAnaItem.Facets = new Facets();
            bookAnaItem.Facets.Facet = new Array();
            bookAnaItem.Facets.Facet.push(this.addFacet("Online Travel Agent", bookAnaItem.Name, "String"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Arrival Day", this.getDateDay(new Date(bookingItem.StayInfo.InDate)), "String"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Booking Day", this.getDateDay(new Date(bookingItem.CreatedOn)), "String"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Length Of Stay (Nights)", bookingItem.LOS, "Number"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Advance Purchase (Days)", bookingItem.AdvancePurchaseDays, "Number"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Net Rate (per Room night)", bookingItem.NetRatePerRoomNight, "Number"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Total Amount", bookingItem.Amounts.TotalAmount, "Number"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Status", this.bookingService.statusMap[bookingItem.Status], "String"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Room Type", bookingItem.RoomName, "String"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Rate Plan", bookingItem.RatePlanName, "String"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Guests", bookingItem.Guests, "Number"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Rooms", bookingItem.Rooms, "Number"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Booking Date", bookingItem.CreatedOn, "DateTime"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Arrival Date", bookingItem.StayInfo.InDate, "DateTime"));
            bookAnaItem.Facets.Facet.push(this.addFacet("Traveler Name", bookingItem.TravellerName.replace(/\"/g, "\\\""), "String"));
            bookingItems.push(bookAnaItem);
        });
        if (bookingItems.length > 0) {
            bookingAnalyticsResponse = {
                "CollectionName": "Booking Analytics Report",
                "FacetCategories": {
                    "FacetCategory": [
                        { "Name": "Online Travel Agent", Type: "String" },
                        { "Name": "Arrival Day", Type: "String" },
                        { "Name": "Booking Day", Type: "String" },
                        { "Name": "Length Of Stay (Nights)", Type: "Number" },
                        { "Name": "Advance Purchase (Days)", Type: "Number" },
                        { "Name": "Net Rate (per Room night)", Type: "Number" },
                        { "Name": "Total Amount", Type: "Number" },
                        { "Name": "Status", Type: "String" },
                        { "Name": "Room Type", Type: "String" },
                        { "Name": "Rate Plan", Type: "String" },
                        { "Name": "Guests", Type: "Number" },
                        { "Name": "Rooms", Type: "Number" },
                        { "Name": "Booking Date", Type: "DateTime" },
                        { "Name": "Arrival Date", Type: "DateTime" },
                        { "Name": "Traveler Name", Type: "String" }]
                },
                "Items": { "Item": bookingItems, "ImgBase": "images" }
            };
        } else {
            // bookingAnalyticsResponse = { "CollectionName": "No Records found",
            // "FacetCategories": {"FacetCategory": []},
            // "Items": { "Item": [{"Id":1,"Img": "globe.jpg","Facets": {"Facet": []}}] }, "ImgBase": "images"};
        }
        return bookingAnalyticsResponse;
    }

    convertToPivotViewerData(bookings: BookingReportItem[]) {
        let bookingItems = [];
        let bookingAnalyticsResponse;
        bookings.forEach(booking => {
            let bookingItem = new BookingAnalyticsItemNew();
            bookingItem.id = booking.BookingId;
            bookingItem.name = Utilities.findId(Utilities.channelTypes, booking.ChannelCode, booking.ChannelCode);
            bookingItem.href = "#";
            bookingItem.facets = new Array();
            bookingItem.facets.push(this.addFacetNew("Online Travel Agent", bookingItem.name, "String"));
            bookingItem.facets.push(this.addFacetNew("Arrival Day", this.getDateDay(new Date(booking.StayInfo.InDate)), "String"));
            bookingItem.facets.push(this.addFacetNew("Booking Day", this.getDateDay(new Date(booking.CreatedOn)), "String"));
            bookingItem.facets.push(this.addFacetNew("Length Of Stay (Nights)", booking.LOS.toString(), "String"));
            bookingItem.facets.push(this.addFacetNew("Advance Purchase (Days)", booking.AdvancePurchaseDays, "Number"));
            bookingItem.facets.push(this.addFacetNew("Net Rate (per Room night)", booking.NetRatePerRoomNight, "Number"));
            bookingItem.facets.push(this.addFacetNew("NetRatePrint", this.prependCurrency(this.numberWithCommas(booking.NetRatePerRoomNight), booking.Currency), "String"));
            bookingItem.facets.push(this.addFacetNew("Total Amount", booking.Amounts.TotalAmount, "Number"));
            bookingItem.facets.push(this.addFacetNew("TotalAmountPrint", this.prependCurrency(this.numberWithCommas(booking.Amounts.TotalAmount), booking.Currency), "String"));
            bookingItem.facets.push(this.addFacetNew("Status", this.bookingService.statusMap[booking.Status], "String"));
            bookingItem.facets.push(this.addFacetNew("Room Type", booking.RoomName, "String"));
            bookingItem.facets.push(this.addFacetNew("Rate Plan", booking.RatePlanName, "String"));
            bookingItem.facets.push(this.addFacetNew("Guests", booking.Guests.toString(), "String"));
            bookingItem.facets.push(this.addFacetNew("Rooms", booking.Rooms.toString(), "String"));
            bookingItem.facets.push(this.addFacetNew("Booking Date", new Date(booking.CreatedOn), "DateTime"));
            bookingItem.facets.push(this.addFacetNew("Arrival Date", Utilities.getUTCDate(new Date(booking.StayInfo.InDate)), "DateTime"));
            bookingItem.facets.push(this.addFacetNew("Formatted Arrival Date", this.getFormattedDate(booking.StayInfo.InDate), "String"));
            bookingItem.facets.push(this.addFacetNew("Traveller Name", booking.TravellerName.replace(/\"/g, "\\\""), "String"));
            bookingItem.facets.push(this.addFacetNew("Image", "/assets/HtmlPivotViewer/icon-images/" + this.getIconImage(bookingItem.name) + ".png", "String"));
            bookingItem.facets.push(this.addFacetNew("Background-color", this.getFacetBackgroundColor(booking.Status), "String"));
            bookingItems.push(bookingItem);
        });
        if (bookingItems.length > 0) {
            bookingAnalyticsResponse = {
                "CollectionName": "Booking Analytics Report",
                "FacetCategories": {
                    "FacetCategory": [
                        { "Name": "Online Travel Agent", Type: "String", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": true },
                        { "Name": "Arrival Day", Type: "String", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": true },
                        { "Name": "Booking Day", Type: "String", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": true },
                        { "Name": "Length Of Stay (Nights)", Type: "String", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": true },
                        { "Name": "Advance Purchase (Days)", Type: "Number", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": true },
                        { "Name": "Net Rate (per Room night)", Type: "Number", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": true },
                        { "Name": "NetRatePrint", Type: "String" },
                        { "Name": "Total Amount", Type: "Number", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": true },
                        { "Name": "TotalAmountPrint", Type: "String" },
                        { "Name": "Status", Type: "String", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": true },
                        { "Name": "Room Type", Type: "String", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": true },
                        { "Name": "Rate Plan", Type: "String", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": true },
                        { "Name": "Guests", Type: "String", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": true },
                        { "Name": "Rooms", Type: "String", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": true },
                        { "Name": "Booking Date", Type: "DateTime", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": false },
                        { "Name": "Arrival Date", Type: "DateTime", "isFilterVisible": true, "isMetaDataVisible": true, "isWordWheelVisible": false },
                        { "Name": "Formatted Arrival Date", Type: "String" },
                        { "Name": "Traveller Name", Type: "String" },
                        { "Name": "Image", Type: "String" },
                        { "Name": "Background-color", Type: "String" }
                    ]
                },
                "Items": { "Item": bookingItems }
            };
        } else {
            bookingAnalyticsResponse = {
                "CollectionName": "No Records found",
                "FacetCategories": { "FacetCategory": [] },
                "Items": { "Item": [] }, "ImgBase": "images"
            };
        }
        return bookingAnalyticsResponse;
    }
    numberWithCommas(x) {
        x = x.toFixed(0);
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
    prependCurrency(rate, currencyCode) {
        return Utilities.findCurrencySymbol(currencyCode) + " " + rate;
    }
    getFormattedDate(dateTime) {
        let date = new Date(dateTime)
        if (dateTime) return date.getDate() + " " + Utilities.getMonthName(date.getMonth(), "short") + ", " + date.getFullYear() + " (" + Utilities.getDayName(date.getDay(), "short") + ")";
        else return dateTime;
    }
    getIconImage(otaName) {
        let availableImages = ["agoda", "booking.com", "cleartrip", "expedia", "goibibo", "hrs", "travelguru"];
        if (availableImages.indexOf(otaName.toLowerCase()) > -1) {
            return otaName.toLowerCase();
        } else return "ota";

    }
    getFacetBackgroundColor(bookingStatus) {
        var color;
        switch (bookingStatus) {
            case 1: color = "255,192,0,1";
                break;
            case 2: color = "0,176,240,1";
                break;
            case 3: color = "255,102,0,1";
                break;
            case 4: color = "240,240,240,1";
                break;
            default: color = "240,240,240,1";
        }
        return color;
    }
    addFacet(key: string, value: any, type: string) {
        let facet = { "Name": key };
        facet[type] = { "Value": value != null ? value : "null" };
        return facet;
    }
    addFacetNew(key: string, value: any, type: string) {
        let facet = { "Name": key, "Value": value != null ? value : "null", "Type": type };
        return facet;
    }

    getDateDay(localDate: Date) {
        return localDate.getDay() + " - " + this.datePipeTransform.transform(localDate, "EEEE");
    }


    async findHotels(competitors: string[]) {
        console.log("Inside the GetCompetitorRates.");
        let response;
        if (competitors.length != 0) {
            let hotelCriteria = new HotelCriteria();
            hotelCriteria.Ids = competitors;
            response = await this.mantras.FindHotels(hotelCriteria).then
                (response => {
                    return response;
                });
        } else {
            return Promise.resolve([]);
        }

        return response;
    }

    findCompetitorRates(hotelId: string, channelCode: string, dateRange: DateRange, competitors: string[]) {
        console.log("Inside the GetCompetitorRates.");
        return this.loadingService.interceptor(this.mantras.findCompetitorRates(hotelId, channelCode, dateRange.Start, dateRange.End).then
            (response => {
                return response;
            }), true);
    }


    findChannelRates(masterHotelIds:string[],dateRange: DateRange, srcChannels:string[],tgtChannels:string[]) {
        console.log("Inside the findChannelRates.");
        return this.loadingService.interceptor(this.mantras.findChannelRates(masterHotelIds, dateRange.Start, dateRange.End,srcChannels,tgtChannels).then
            (response => {
                return response;
            }), true);
    }

    //TODO: This is to generate dummy data
    generateCompetitorRates(hotelId: string, channelCode: string, dateRange: DateRange, competitors: string[]) {
        let startDate = new Date(dateRange.Start);
        let numberOfDays = Utilities.dateDifference(dateRange.Start, dateRange.End);
        let compRates = [];
        for (let i = 0; i < numberOfDays; i++) {
            competitors.forEach(comp => {
                let compRate = new CompetitorItem();
                compRate.HotelId = comp;
                compRate.ChannelCode = channelCode;
                compRate.Rate = Math.floor(1000 + Math.random() * 9000);
                compRate.StayDate = new Date(startDate);
                compRate.PageNumber = Math.floor(1 + Math.random() * 80);
                compRate.Rank = Math.floor(1 + Math.random() * 1000);
                compRates.push(compRate);
            });
            startDate.setDate(startDate.getDate() + 1);
        }
        return compRates;
    }

    exportCompetitorRates(hotelId: string, channelCodes: string[], startdate: Date, endDate: Date) {
        console.log("Inside the Export Comp Rates.");
        return this.loadingService.interceptor(this.mantras.exportCompetitorRates(hotelId, channelCodes, startdate, endDate).then
            (response => {
                return Utilities.saveToFileSystem(response, "ExportCompetitorRates.xlsx");
            }), true);
    }
    findChannelSyncLog(syncLogCriteria) {
        return this.loadingService.interceptor(this.mantras.findChannelSyncLog(syncLogCriteria).then
            (response => {
                if (response && response.length > 0)
                    response.forEach(syncLog => {
                        syncLog["GroupHeader"] = this.rcService.getGrouping(syncLog.SyncTimestamp)
                    });
                return response.reverse();
            }), true);
    }
    getBookingAnalyticsTemplates() {
        return [
            // {
            //   type: "html",
            //   width: 50,
            //   template:
            //       "<div style='border-radius:100px;font-size:3em'>\
            //           <img src=<?facets['Image'][0]?> style='border-radius:100px;width:50px;height:50px'>\
            //       </div>"
            // }, 
            {
                type: "html",
                width: 300,
                template:
                    "<div style='height:100%;padding-left:30px;border:2px solid black;font-size:2em;\
                background: rgba(<?facets['Background-color'][0]?>);\
                border: none;'>\
                      <div class='pivotv-card-title'>\
                      <div style='font-weight:500;display:flex;flex-direction:column;font-size:30px;'><?facets['Online Travel Agent'][0]?></div>\
                      <div style='display:flex;flex-direction:column;position: absolute;right: 10px;'><img src=<?facets['Image'][0]?> style='width:50px;height:50px;border-radius:100px;'></div>\
                      </div>\
                      <br/>\
                      <div style='font-weight:400;'>\
                      <?facets['Formatted Arrival Date'][0]?>\
                      <br/>\
                      <?facets['Length Of Stay (Nights)'][0]?> night<?(facets['Length Of Stay (Nights)'][0] <= 1)?'':'s'?>\
                      <br/>\
                      <?facets['Rooms'][0]?> room<?(facets['Rooms'][0] <= 1)?'':'s'?>\
                      <br/>\
                      <?facets['NetRatePrint'][0]?>\
                      <br/>\
                      </div>\
                </div>"
            },
            {
                type: "html",
                width: 450,
                template:
                    "<div style='height:100%;padding: 0px 30px;border:2px solid black;font-size:2em;\
                background: rgba(<?facets['Background-color'][0]?>);\
                border: none;'>\
                      <div class='pivotv-card-title'>\
                      <div style='font-weight:500;display:flex;flex-direction:column;font-size:45px;'><?facets['Online Travel Agent'][0]?></div>\
                      <div style='display:flex;flex-direction:column;position: absolute;right: 10px;'><img src=<?facets['Image'][0]?> style='width:75px;height:75px;border-radius:100px;'></div>\
                      </div>\
                      <br/>\
                      <div title='<?facets['Traveller Name'][0]?>' class='booking-analytics-traveller-name'><?facets['Traveller Name'][0]?></div>\
                      <br/>\
                      <div style='width:auto;font-weight:400'>\
                      <span class='pivotv-detail-key'>Arrival  </span><span class='pivotv-detail-val'>- <?facets['Formatted Arrival Date'][0]?></span>\
                      <br/>\
                      <span class='pivotv-detail-key'>Stay </span><span class='pivotv-detail-val'>- <?facets['Length Of Stay (Nights)'][0]?> night<?(facets['Length Of Stay (Nights)'][0] <= 1)?'':'s'?></span>\
                      <br/>\
                      <span class='pivotv-detail-key'>Rooms  </span><span class='pivotv-detail-val'>- <?facets['Rooms'][0]?></span>\
                      <br/>\
                      <span class='pivotv-detail-key'>Net Rate  </span><span class='pivotv-detail-val'>- <?facets['NetRatePrint'][0]?></span><span style='font-size:12px;'> Per room night</span>\
                      <br/>\
                      <span class='pivotv-detail-key'>Total  </span><span class='pivotv-detail-val'>- <?facets['TotalAmountPrint'][0]?></span>\
                      <br/>\
                      <span class='pivotv-detail-key'>Status  </span><span class='pivotv-detail-val'>- <?facets['Status'][0]?></span>\
                      <br/>\
                      </div>\
                </div>"
            }
        ];
    }
}

export class BookingAnalyticsItem {
    Id: string;
    Facets: Facets;
    Name: string;
    Img: string;
    Href: string;
    Extension: string;
}

export class BookingAnalyticsItemNew {
    id: string;
    name: string;
    href: string;
    facets: FacetsNew[];
}

export class FacetsNew {
    Name: string;
    Value: any;
}
export class Facets {
    Facet: any[];
}

export class CompetitorGridData {
    ChannelCode: string;
    HotelId: string;
    PageNumber: number;
    Rank: number;
    Rates: any[];
    HotelName: string;
    Count: number;
}

export class ChannelBookingData {
    ChannelCode: string;
    HotelName: string;
    //DataObjectList: BookingReportItem[];
    Count: number = 0;
}