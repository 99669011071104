import { Component, OnInit, HostListener, ViewChild, ViewContainerRef, ElementRef, QueryList, ViewChildren, Input, ComponentFactoryResolver } from '@angular/core';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { HotelCriteria, Address, AppService, HotelMapping, HotelInfo } from 'mantras-api';
import { HotelManagementService } from '../ui-services/hotelmanagement.service';
import { UserService } from '../ui-services/user.service';
import { CommonService, competitorManagementDetails } from '../ui-services/common.service';
import { MenuItem, MessageService } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { HotelCatalogDetailsComponent } from '../hotelcatalog/hotelcatalogdetails.component';
import { Router } from '@angular/router';
import { Dialog } from 'primeng/dialog';
import { StorageService } from '../ui-services/storage.service';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';

@Component({
  selector: 'competitormanagement',
  templateUrl: './competitormanagement.component.html',
  styleUrls: ['./competitormanagement.component.css']
})
export class CompetitorManagementComponent implements OnInit {
  @ViewChild('hotelDetails') hotelDetails: Dialog;
  subscription: Subscription;
  hotelDetailsList = [];
  channelHotelsSelectedForMapping = [];
  currency = [];
  items: MenuItem[];
  compMenuItems: MenuItem[];
  @ViewChild('listOfHotels') listOfHotels;
  constructor(public service: HotelManagementService,private storageService: StorageService, private router: Router, public commService: CommonService, private messageService: MessageService,
    private resolver: ComponentFactoryResolver) {
    Utilities.currencies.forEach(curr => {
      this.currency.push({ label: curr.Code + "(" + curr.Symbol + ")" + "-" + curr.Currency + "(" + curr.Country + ")", value: curr.Code });
    });
    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });

  }

  ngOnInit() {
    this.loadMenuClose();
  }

  loadMenuClose() {
    this.compMenuItems = [
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
    ];
  }

  onClose() {
    this.router.navigate(["/settings"]);
  }

  loadMenu(clickRef) {
    this.items = [
      {
        label: 'Show Mapping',
        icon: 'action-bar-menu-icon fa fa-map-o',
        command: (event) => { this.showMapping(event, clickRef); },
        visible: this.isChannelCodeMax
      },
      {
        label: 'Save Mapping',
        icon: 'action-bar-menu-icon fa fa-floppy-o',
        command: (event) => { this.saveMapping(event); },
        visible: this.masterHotelSelected
      },
      {
        label: 'Delete Hotel',
        icon: 'action-bar-menu-icon fa fa-trash-o',
        command: (event) => { this.deleteHotel(event); },
        visible: this.isChannelCodeMax
      },
      {
        label: 'Delete Mapping',
        icon: 'action-bar-menu-icon fa fa-trash-o',
        command: (event) => { this.deleteMapping(event); },
        visible: this.showDelMapping
      },
      {
        label: 'Add to Catalog',
        icon: 'action-bar-menu-icon fa fa-plus-circle',
        command: (event) => { this.addToCatalog(event); },
        visible: !this.isChannelCodeMax
      }
    ];
  }

  @Input() hotelcatalogdetails = new HotelInfo();
  @ViewChild('hotelcatalogdetails') hotelCatalogDetailsComponent: any;
  @ViewChildren("row", { read: ElementRef }) rowElement: QueryList<ElementRef>;

  channelHotelsInfo = [];
  searchCriteria; tempCity;
  channelDetailsMap = new Map<string, competitorManagementDetails>();
  @ViewChild('competitormanagementcriteria', { read: ViewContainerRef }) competitormanagementcriteria;
  async onSearchCriteria(criteria?: Address) {
    this.hotelDetailsList = [];
    let hotelCriteria = new HotelCriteria();
    let comMngDetails = new competitorManagementDetails();
    var splittedCitiesArray = criteria.City.split(",");
    this.searchCriteria = hotelCriteria;
    if (this.tempCity != criteria.City) { this.channelDetailsMap.clear(); }
    var mapCriteriaMax = "MAX" + "," + criteria.City + "," + criteria.Country;
    if (splittedCitiesArray.length == 1 && this.channelDetailsMap.has(mapCriteriaMax)) {
      this.hotelDetailsList.push(this.channelDetailsMap.get(mapCriteriaMax));
      this.sortChannels();
    } else {
      var channelHotels = [];
      var hotelIds = [];
      var hotelList: any[] = [];
      let requestArrayHotels = [];
      for (let i = 0; i < splittedCitiesArray.length; i++) {
        hotelCriteria.City = splittedCitiesArray[i];
        let reqHotels = this.service.findHotels(hotelCriteria);
        requestArrayHotels.push(reqHotels);
      }
      if (requestArrayHotels.length > 0) {
        forkJoin(requestArrayHotels).subscribe(results => {
          results.forEach(r => {
            hotelList.push.apply(hotelList, r);
          });
          hotelList.forEach(element => {
            let hotelAddress = this.getAddress(element);
            channelHotels.push({ label: element.Name, value: element.Id, address: hotelAddress });
            hotelIds.push(element.Id);
          });
          comMngDetails.hotelList = channelHotels.sort(Utilities.sort_label_asc);
          comMngDetails.channelName = "Master";
          comMngDetails.channelCode = "MAX";
          this.hotelDetailsList.push(comMngDetails);
          this.sortChannels();
          if (splittedCitiesArray.length == 1) {
            this.channelDetailsMap.set(mapCriteriaMax, comMngDetails);
            this.tempCity = criteria.City;
          }
        });
      }
    }
    if (this.selectedChannels.length > 0) {
      this.selectedChannels = this.sortData(this.selectedChannels);
      this.channelHotelsInfo = [];
      this.selectedChannels.forEach(async channelCode => {
        let mapCriteriaChannel = channelCode + "," + criteria.City + "," + criteria.Country;
        if (this.channelDetailsMap.has(mapCriteriaChannel) && splittedCitiesArray.length == 1) {
          this.hotelDetailsList.push(this.channelDetailsMap.get(mapCriteriaChannel));
          //this.sortChannels();
        } else {
          let addCriteria = new Address();
          var channelHotels = [];
          var hotelIds = [];
          var compMngDetails = new competitorManagementDetails();
          let requestArrayChannelHotels = [];
          let channelHotelList = [];
          for (let i = 0; i < splittedCitiesArray.length; i++) {
            addCriteria.City = splittedCitiesArray[i];
            addCriteria.Country = criteria.Country;
            let reqChennelHotels = this.commService.FindChannelHotels(addCriteria, channelCode);
            requestArrayChannelHotels.push(reqChennelHotels);
          }
          if (requestArrayChannelHotels.length > 0) {
            forkJoin(requestArrayChannelHotels).subscribe(results => {
              results.forEach(r => {
                channelHotelList.push.apply(channelHotelList, r);
              });
              this.channelHotelsInfo.push({ channelcode: channelCode, list: channelHotelList });
              channelHotelList.forEach(chList => {
                let hotelAddress = this.getAddress(chList);
                channelHotels.push({ label: chList.Name, value: chList.Id, address: hotelAddress });
                hotelIds.push(chList.Id);
              });
              compMngDetails.hotelList = channelHotels.sort(Utilities.sort_label_asc);
              if (channelCode == "AGD") {
                compMngDetails.channelName = "Agoda";
              } else {
                compMngDetails.channelName = Utilities.findId(Utilities.channelTypes, channelCode);
              }
              compMngDetails.channelCode = channelCode;
              let requestArray = [];
              for (let index = 0; index < hotelIds.length; index += 1000) {
                let splittedHotelIds = hotelIds.slice(index, index + 1000);
                let req = this.commService.GetHotelMapping(channelCode, splittedHotelIds);
                requestArray.push(req);
              }

              if (requestArray.length > 0) {
                forkJoin(requestArray).subscribe(results => {
                  results.forEach(r => {
                    compMngDetails.mappedHotelList.push.apply(compMngDetails.mappedHotelList, r);
                  });
                  this.hotelDetailsList.push(compMngDetails);
                  this.sortChannels();
                  if (splittedCitiesArray.length == 1)
                    this.channelDetailsMap.set(mapCriteriaChannel, compMngDetails);
                });
              }
              if (channelHotelList.length == 0) {
                this.hotelDetailsList.push(compMngDetails);
                this.sortChannels();
                if (splittedCitiesArray.length == 1)
                  this.channelDetailsMap.set(mapCriteriaChannel, compMngDetails);
              }
            });
          }
        }
      });
    }
    this.loadMenuClose();
    this.setHeight();
  }

  sortData(array: Array<number | string>): Array<number | string> {
    return array.sort((a, b) => a < b ? -1 : 1);
  }

  sortChannels() {
    this.hotelDetailsList.sort(function (a, b) {
      var textA = a.channelName.toUpperCase();
      var textB = b.channelName.toUpperCase();
      if (textA == "MASTER") { textA = "AAMASTER" }
      if (textB == "MASTER") { textB = "AAMASTER" }
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  getAddress(details) {
    if (details.Address.Street == null) details.Address.Street = "";
    if (details.Address.City == null) details.Address.City = "";
    if (details.Address.ZipCode == null) details.Address.ZipCode = "";
    if (details.Address.State == null) details.Address.State = "";
    if (details.Address.Country == null) details.Address.Country = "";
    if (details.Address.GeoCode) {
      var hotelAddress = details.Address.Street + "," + details.Address.City + "," +
        details.Address.ZipCode + "," + details.Address.State + "," +
        details.Address.Country +
        "\n" + "Lat/Long :" + details.Address.GeoCode.Latitude + "/" + details.Address.GeoCode.Longitude;
    } else {
      hotelAddress = details.Address.Street + "," + details.Address.City + "," +
        details.Address.ZipCode + "," + details.Address.State + "," +
        details.Address.Country;
    }
    return hotelAddress;
  }

  selectedHotel; isChannelCodeMax = false;
  getMappedHotels(e, channelCode) {
    if (!e.originalEvent.ctrlKey) {
      var selMasterHotelId = null;
      var updatedSel = this.hotelDetailsList.find(cm => cm.channelCode === channelCode);
      if (channelCode == "MAX") {
        selMasterHotelId = updatedSel.selectedHotel;
      } else {
        var mapping = updatedSel.mappedHotelList.find(m => m.ChannelHotelId == updatedSel.selectedHotel);
        if (mapping)
          selMasterHotelId = mapping.HotelId;
      }
      this.hotelDetailsList.forEach(hl => {
        if (hl.channelCode != channelCode) {
          if (hl.channelCode == "MAX") {
            hl.selectedHotel = selMasterHotelId;
            if (selMasterHotelId != null)
              this.setListBoxFocus(selMasterHotelId);
          }
          else {
            if (selMasterHotelId != null) {
              var mapping = hl.mappedHotelList.find(m => m.HotelId == selMasterHotelId);
              if (mapping) {
                hl.selectedHotel = mapping.ChannelHotelId;
                this.setListBoxFocus(hl.selectedHotel);
              }
              else {
                hl.selectedHotel = null;
              }
            } else {
              hl.selectedHotel = null;
            }
          }
        }
      });
    }
  }

  channelMappingDetails = [];
  @ViewChild('showMappingChannels') showMappingChannels;
  async showMapping(e, clickRef) {
    var hotelID = [];
    this.channelMappingDetails = [];
    var masterDetails = this.hotelDetailsList.find(ch => ch.channelCode == "MAX");
    hotelID.push(masterDetails.selectedHotel);
    await this.commService.GetChannelMappings(hotelID).then(resp => {
      resp.forEach(element => {
        if (element.ChannelCode == "AGD") {
          var channelName = "Agoda";
        } else {
          channelName = Utilities.findId(Utilities.channelTypes, element.ChannelCode);
        }
        if (channelName == undefined) {
          channelName = element.ChannelCode;
        }
        let channelHotelId = element.ChannelHotelId;
        if (element.ChannelCode != "MAX") {
          this.channelMappingDetails.push({ ChannelHotelId: channelHotelId, ChannelName: channelName });
        }
      });
    });
    if (this.channelMappingDetails.length > 0) {
      this.showMappingChannels.show(clickRef);
    }
  }

  saveMapping(e) {
    var masterDetails = this.hotelDetailsList.find(ch => ch.channelCode == "MAX")
    if (this.chCodeSelectedByCxtMenu == "MAX") {
      this.hotelDetailsList.forEach(hm => {
        if (hm.channelCode != "MAX") {
          if (hm.selectedHotel != null) {
            var isMapped = hm.mappedHotelList.find(mp => mp.HotelId === masterDetails.selectedHotel)
            if (isMapped) {
              for (let mh of hm.mappedHotelList) {
                if (isMapped.HotelId === mh.HotelId) {
                  hm.mappedHotelList.splice(hm.mappedHotelList.indexOf(mh), 1);
                  this.commService.DeleteMapping(mh.HotelId, mh.ChannelCode, mh.ChannelHotelId);
                  break;
                }
              }
            }
            let hotelMapping = new HotelMapping();
            hotelMapping.ChannelCode = hm.channelCode;
            hotelMapping.ChannelHotelId = hm.selectedHotel;
            hotelMapping.HotelId = masterDetails.selectedHotel;
            hm.mappedHotelList.push(hotelMapping);
            this.commService.SaveMapping([hotelMapping]).then(resp => {
              this.messageService.add({ severity: 'success', summary: 'Success', detail: "Saved Mapping Successfully" });
            })
          }
        }
      });
    }
    else if (this.chCodeSelectedByCxtMenu != "MAX") {
      var chDetails = this.hotelDetailsList.find(ch => ch.channelCode == this.chCodeSelectedByCxtMenu)
      var isMapped = chDetails.mappedHotelList.find(mp => mp.HotelId === masterDetails.selectedHotel)
      if (isMapped) {
        for (let mh of chDetails.mappedHotelList) {
          if (isMapped.HotelId === mh.HotelId) {
            chDetails.mappedHotelList.splice(chDetails.mappedHotelList.indexOf(mh), 1);
            this.commService.DeleteMapping(mh.HotelId, mh.ChannelCode, mh.ChannelHotelId);
            break;
          }
        }
      }
      let hotelMapping = new HotelMapping();
      hotelMapping.ChannelCode = chDetails.channelCode;
      hotelMapping.ChannelHotelId = chDetails.selectedHotel;
      hotelMapping.HotelId = masterDetails.selectedHotel;
      chDetails.mappedHotelList.push(hotelMapping);
      this.commService.SaveMapping([hotelMapping]).then(resp => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: "Saved Mapping Successfully" });
      })
    }
  }

  showAddToCatalog = false;
  isDetailsPage = false;
  detailComponent;
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  showHotelDetails = false;
  latitude; longitude;
  addToCatalog(e) {
    this.container.clear();
    let factory = this.resolver.resolveComponentFactory(HotelCatalogDetailsComponent);
    this.detailComponent = <HotelCatalogDetailsComponent>this.container.createComponent(factory).instance;
    var chDetailsList = this.channelHotelsInfo.find(ch => ch.channelcode === this.chCodeSelectedByCxtMenu);
    this.hotelcatalogdetails = chDetailsList.list.find(hd => hd.Id === this.currentSelectedHotel);
    this.detailComponent.hotelcatalogdetails = this.hotelcatalogdetails;
    this.detailComponent.fromCompFunc();
    this.detailComponent.loadData(this.hotelcatalogdetails);
    this.detailComponent.onClose.subscribe(this.onDetailsClose);
    this.showHotelDetails = true;
  }

  onDetailsClose = async (e) => {
    this.showHotelDetails = false;
    let flag = this.detailComponent.closeOnSave;
    if (flag) {
      this.refreshMaster();
    }
  }

  async deleteHotel(e) {
    await this.service.deleteHotel(this.currentSelectedHotel);
    this.refreshMaster();
  }

  async refreshMaster() {
    await this.service.findHotels(this.searchCriteria).then(hList => {
      var channelHotels = [];
      hList.forEach(element => {
        let hotelAddress = this.getAddress(element);
        channelHotels.push({ label: element.Name, value: element.Id, address: hotelAddress });
      });
      let maxHotel = this.hotelDetailsList.find(hd => hd.channelCode === "MAX");
      maxHotel.hotelList = channelHotels.sort(Utilities.sort_label_asc);
    });
  }

  async deleteMapping(e) {
    var channelDetails = this.hotelDetailsList.find(ch => ch.channelCode === this.chCodeSelectedByCxtMenu);
    var mappedChannelDetails = channelDetails.mappedHotelList.find(mh => mh.ChannelHotelId === this.currentSelectedHotel);
    let hotelId = mappedChannelDetails.HotelId;
    let channelCode = mappedChannelDetails.ChannelCode;
    let channelHotelId = mappedChannelDetails.ChannelHotelId;
    await this.commService.DeleteMapping(hotelId, channelCode, channelHotelId).then(resp => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: "Deleted Mapping Successfully" });
    })
    for (let mh of channelDetails.mappedHotelList) {
      if (hotelId === mh.HotelId) {
        channelDetails.mappedHotelList.splice(channelDetails.mappedHotelList.indexOf(mh), 1);
        break;
      }
    }
    channelDetails.selectedHotel = null;
  }

  setListBoxFocus(hotelId) {
    const el = this.rowElement.find(r => r.nativeElement.getAttribute("id") === hotelId);
    if (el) {
      el.nativeElement.scrollIntoView(true);
    } else { return; }
  }

  selectedChannels;
  channelListDetails(event) {
    this.selectedChannels = event;
  }

  forClearCache(event) {
    this.hotelDetailsList = [];
    this.channelDetailsMap.clear();
  }

  closeChannelBox(ch) {
    if (ch == "MAX") { return; }
    for (let hd of this.hotelDetailsList) {
      if (hd.channelCode == ch) {
        this.hotelDetailsList.splice(this.hotelDetailsList.indexOf(hd), 1);
        break;
      }
    }
  }

  onSearch(event) {
    this.onSearchCriteria(event);
  }

  isMapped(mapList, hotelId) {
    let mapped = false;
    if (mapList && mapList.length > 0) {
      let map = mapList.find(m => m.ChannelHotelId == hotelId);
      if (map) mapped = true;
    }
    if (mapped) return "mapped";
    else return "";
  }

  filterHotelName; hotelCount;
  onHotelNameSearch(event) {
    if (this.hotelDetailsList.length > 0) {
      this.filterHotelName = event;
      this.hotelCount = this.hotelDetailsList.length;
    }
  }
  chCodeSelectedByCxtMenu; masterHotelSelected = false;
  currentSelectedHotel;
  showDelMapping = false;
  @ViewChild('mappingMenu') mappingMenu;
  showMappingConextMenu(e, channelCode, selectedHotelForContexMenu) {
    this.chCodeSelectedByCxtMenu = channelCode;
    this.currentSelectedHotel = selectedHotelForContexMenu;
    if (channelCode == "MAX") {
      this.isChannelCodeMax = true;
    }
    var maxDetails = this.hotelDetailsList.find(ch => ch.channelCode === "MAX")
    if (maxDetails.selectedHotel != null) {
      this.masterHotelSelected = true;
    }
    else {
      this.masterHotelSelected = false;
    }
    var chDetails = this.hotelDetailsList.find(ch => ch.channelCode === channelCode)
    var isMapped = chDetails.mappedHotelList.find(htl => htl.ChannelHotelId === selectedHotelForContexMenu)
    if (isMapped) {
      this.showDelMapping = true;
    }
    else {
      this.showDelMapping = false;
    }
    this.loadMenu(e);
    this.mappingMenu.show(e);
    this.isChannelCodeMax = false;
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.listBoxHeight').css('height', Utilities.getScrollHeight(true));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  handleOnClose(event){
    //do nothing
  }
}
