import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
//AM charts imports
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/animated";
//am4core.useTheme(am4themes_animated);
//am4core.useTheme(am4themes_kelly);
@Component({
  selector: 'app-amcharts',
  templateUrl: './amcharts.component.html',
  styleUrls: ['./amcharts.component.css']
})
export class AmchartsComponent implements OnInit,AfterViewInit{
  private chartXY: am4charts.XYChart;
  constructor(){ }

  ngOnInit() {
  }

  ngAfterViewInit(){
    //ngAfterViewInit method which creates the chart:
    //XY Charts
    // this.getLineChart();
    // this.getLineChartStack();
    // this.getLineSeriesWithGaps();
    // this.getBarPlusLine();
    // this.getLineChartThreeD();
    // this.getLineChartWihMultipleAxes();
    // this.getLineChartNonStandard();
    // this.getColumnBarChart();
    this.getLiveDataChart();
    //Pie Charts
    // this.getPieChart();
    // this.getPieChartDonut();
    // this.getPieChartThreeD();
  }

  getLineChart(){
    let chart = am4core.create("lineChart", am4charts.XYChart);
    chart.data = [{
      "country": "Lithuania",
      "litres": 501.9
    }, {
      "country": "Czech Republic",
      "litres": 301.9
    }, {
      "country": "Ireland",
      "litres": 201.1
    }, {
      "country": "Germany",
      "litres": 165.8
    }, {
      "country": "Australia",
      "litres": 139.9
    }, {
      "country": "Austria",
      "litres": 128.3
    }, {
      "country": "UK",
      "litres": 99
    }, {
      "country": "Belgium",
      "litres": 60
    }, {
      "country": "The Netherlands",
      "litres": 50
    }];
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.title.text = "Countries";
    
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Litres sold (M)";

    let series = chart.series.push(new am4charts.ColumnSeries());
    series.name = "Sales";//we can set series' name, so that it can be nicely represented in Legend and tooltips.
    series.columns.template.tooltipText = "Series: {name}\nCategory: {categoryX}\nValue: {valueY}"; //to specify what is displayed when user hovers over series
    series.columns.template.fill = am4core.color("#104547"); // fill
    series.dataFields.valueY = "litres";
    series.dataFields.categoryX = "country";
  }
  getLineChartStack(){
    // Create chart instance
    var chart = am4core.create("lineChartStacked", am4charts.XYChart);

    chart.marginRight = 400;

    // Add data
    chart.data = [{
      "country": "Lithuania",
      "research": 501.9,
      "marketing": 250,
      "sales": 199
    }, {
      "country": "Czech Republic",
      "research": 301.9,
      "marketing": 222,
      "sales": 251
    }, {
      "country": "Ireland",
      "research": 201.1,
      "marketing": 170,
      "sales": 199
    }, {
      "country": "Germany",
      "research": 165.8,
      "marketing": 122,
      "sales": 90
    }, {
      "country": "Australia",
      "research": 139.9,
      "marketing": 99,
      "sales": 252
    }, {
      "country": "Austria",
      "research": 128.3,
      "marketing": 85,
      "sales": 84
    }, {
      "country": "UK",
      "research": 99,
      "marketing": 93,
      "sales": 142
    }, {
      "country": "Belgium",
      "research": 60,
      "marketing": 50,
      "sales": 55
    }, {
      "country": "The Netherlands",
      "research": 50,
      "marketing": 42,
      "sales": 25
    }];

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.title.text = "Local country offices";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;


    var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Expenditure (M)";

    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "research";
    series.dataFields.categoryX = "country";
    series.name = "Research";
    series.tooltipText = "{name}: [bold]{valueY}[/]";
    series.stacked = true;

    var series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "marketing";
    series2.dataFields.categoryX = "country";
    series2.name = "Marketing";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";
    series2.stacked = true;

    var series3 = chart.series.push(new am4charts.ColumnSeries());
    series3.dataFields.valueY = "sales";
    series3.dataFields.categoryX = "country";
    series3.name = "Sales";
    series3.tooltipText = "{name}: [bold]{valueY}[/]";
    series3.stacked = true;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
  }

  getLineSeriesWithGaps(){
    // Create chart instance
    var chart = am4core.create("lineChartWithGaps", am4charts.XYChart);
    chart.paddingRight = 20;

    // Add data
    chart.data = [{
      "date": new Date(2018, 3, 20),
      "value": 90,
      "value2": 45
    }, {
      "date": new Date(2018, 3, 21),
      "value": 102,
      "value2": 90
    }, {
      "date": new Date(2018, 3, 22)
    }, {
      "date": new Date(2018, 3, 23),
      "value": 125
    }, {
      "date": new Date(2018, 3, 24),
      "value": 55,
      "value2": 90
    }, {
      "date": new Date(2018, 3, 25),
      "value": 81,
      "value2": 60
    }, {
      "date": new Date(2018, 3, 26)
    }, {
      "date": new Date(2018, 3, 27),
      "value": 63,
      "value2": 87
    }, {
      "date": new Date(2018, 3, 28),
      "value": 113,
      "value2": 62
    }];

    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;

    // Create value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    var series1 = chart.series.push(new am4charts.LineSeries());
    series1.dataFields.valueY = "value";
    series1.dataFields.dateX = "date";
    series1.strokeWidth = 3;
    series1.tensionX = 0.8;
    series1.bullets.push(new am4charts.CircleBullet());
    series1.connect = false;

    var series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "value2";
    series2.dataFields.dateX = "date";
    series2.strokeWidth = 3;
    series2.tensionX = 0.8;
    series2.bullets.push(new am4charts.CircleBullet());

  }
  getBarPlusLine(){
        // Create chart instance
    let chart = am4core.create("lineChartWithBar", am4charts.XYChart);

    // Add data
    chart.data = [{
      "country": "Lithuania",
      "litres": 501.9,
      "units": 250
    }, {
      "country": "Czech Republic",
      "litres": 301.9,
      "units": 222
    }, {
      "country": "Ireland",
      "litres": 201.1,
      "units": 170
    }, {
      "country": "Germany",
      "litres": 165.8,
      "units": 122
    }, {
      "country": "Australia",
      "litres": 139.9,
      "units": 99
    }, {
      "country": "Austria",
      "litres": 128.3,
      "units": 85
    }, {
      "country": "UK",
      "litres": 99,
      "units": 93
    }, {
      "country": "Belgium",
      "litres": 60,
      "units": 50
    }, {
      "country": "The Netherlands",
      "litres": 50,
      "units": 42
    }];

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.title.text = "Countries";

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Litres sold (M)";

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "litres";
    series.dataFields.categoryX = "country";
    series.name = "Sales";
    series.columns.template.tooltipText = "Series: {name}\nCategory: {categoryX}\nValue: {valueY}";
    series.columns.template.fill = am4core.color("#104547"); // fill

    var series2 = chart.series.push(new am4charts.LineSeries());
    series2.name = "Units";
    series2.stroke = am4core.color("#CDA2AB");
    series2.strokeWidth = 3;
    series2.dataFields.valueY = "units";
    series2.dataFields.categoryX = "country";

    //Legend
    chart.legend = new am4charts.Legend();

    //Cursor
    chart.cursor = new am4charts.XYCursor();

    //Scrollbars
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarY = new am4core.Scrollbar();

    //Scrollbar with preview
    // let scrollbarX = new am4charts.XYChartScrollbar();
    // scrollbarX.series.push(series);
    // chart.scrollbarX = scrollbarX;

    // hide Scrollbar's container which holds the series:
    // scrollbarX.scrollbarChart.seriesContainer.hide();
  }
  getLineChartThreeD(){
    // Create chart instance
    var chart = am4core.create("lineChartThreeD", am4charts.XYChart3D);

    // Add data
    chart.data = [{
      "country": "Lithuania",
      "litres": 501.9,
      "units": 250
    }, {
      "country": "Czech Republic",
      "litres": 301.9,
      "units": 222
    }, {
      "country": "Ireland",
      "litres": 201.1,
      "units": 170
    }, {
      "country": "Germany",
      "litres": 165.8,
      "units": 122
    }, {
      "country": "Australia",
      "litres": 139.9,
      "units": 99
    }, {
      "country": "Austria",
      "litres": 128.3,
      "units": 85
    }, {
      "country": "UK",
      "litres": 99,
      "units": 93
    }, {
      "country": "Belgium",
      "litres": 60,
      "units": 50
    }, {
      "country": "The Netherlands",
      "litres": 50,
      "units": 42
    }];

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.title.text = "Countries";

    var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Litres sold (M)";

    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "litres";
    series.dataFields.categoryX = "country";
    series.name = "Sales";
    series.tooltipText = "{name}: [bold]{valueY}[/]";

    var series2 = chart.series.push(new am4charts.ColumnSeries3D());
    series2.dataFields.valueY = "units";
    series2.dataFields.categoryX = "country";
    series2.name = "Units";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
  }
  getLineChartWihMultipleAxes(){
    // Create chart instance
    var chart = am4core.create("lineChartMultipleAxes", am4charts.XYChart);

    // Add data
    chart.data = [{
      "country": "Lithuania",
      "litres": 501.9,
      "units": 250000
    }, {
      "country": "Czech Republic",
      "litres": 301.9,
      "units": 222000
    }, {
      "country": "Ireland",
      "litres": 201.1,
      "units": 170000
    }, {
      "country": "Germany",
      "litres": 165.8,
      "units": 122000
    }, {
      "country": "Australia",
      "litres": 139.9,
      "units": 99000
    }, {
      "country": "Austria",
      "litres": 128.3,
      "units": 85000
    }, {
      "country": "UK",
      "litres": 99,
      "units": 93000
    }, {
      "country": "Belgium",
      "litres": 60,
      "units": 50000
    }, {
      "country": "The Netherlands",
      "litres": 50,
      "units": 42000
    }];

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.title.text = "Countries";

    // First value axis
    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "Litres sold (M)";

    // Second value axis
    var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.title.text = "Units sold";
    valueAxis2.renderer.opposite = true;

    // First series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "litres";
    series.dataFields.categoryX = "country";
    series.name = "Sales";
    series.tooltipText = "{name}: [bold]{valueY}[/]";

    // Second series
    var series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = "units";
    series2.dataFields.categoryX = "country";
    series2.name = "Units";
    series2.tooltipText = "{name}: [bold]{valueY}[/]";
    series2.strokeWidth = 3;
    series2.yAxis = valueAxis2;

    // Add legend
    chart.legend = new am4charts.Legend();

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
  }
  getLineChartNonStandard(){
    // Apply chart themes
    //am4core.useTheme(am4themes_animated);

    var chart = am4core.create("lineChartNonStandard", am4charts.XYChart);
    chart.maskBullets = false;

    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

    xAxis.dataFields.category = "weekday";
    yAxis.dataFields.category = "hour";

    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 40;

    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.inversed = true;
    yAxis.renderer.minGridDistance = 30;

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "weekday";
    series.dataFields.categoryY = "hour";
    series.dataFields.value = "value";
    series.sequencedInterpolation = true;
    series.defaultState.transitionDuration = 3000;
    series.columns.template.width = am4core.percent(100);
    series.columns.template.height = am4core.percent(100);

    series.heatRules.push({target:series.columns.template, property:"fill", min:am4core.color("#ffffff"), max:am4core.color("#692155")});

    var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;
    columnTemplate.stroke = am4core.color("#ffffff");
    columnTemplate.tooltipText = "{weekday}, {hour}: {value.workingValue.formatNumber('#.')}";

    // heat legend
    var heatLegend = chart.bottomAxesContainer.createChild(am4charts.HeatLegend);
    heatLegend.width = am4core.percent(100);
    heatLegend.series = series;
    heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
    heatLegend.valueAxis.renderer.minGridDistance = 30;

    // heat legend behavior
    series.columns.template.events.on("over", (event) => {
      handleHover(event.target);
    })

    series.columns.template.events.on("hit", (event) => {
      handleHover(event.target);
    })

    function handleHover(column) {
      if (!isNaN(column.dataItem.value)) {
        heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
      }
      else {
        heatLegend.valueAxis.hideTooltip();
      }
    }

    series.columns.template.events.on("out", (event) => {
      heatLegend.valueAxis.hideTooltip();
    })

    chart.data = [
      {
        "hour": "12pm",
        "weekday": "Sun",
        "value": 2990
      },
      {
        "hour": "1am",
        "weekday": "Sun",
        "value": 2520
      },
      {
        "hour": "2am",
        "weekday": "Sun",
        "value": 2334
      },
      {
        "hour": "3am",
        "weekday": "Sun",
        "value": 2230
      },
      {
        "hour": "4am",
        "weekday": "Sun",
        "value": 2325
      },
      {
        "hour": "5am",
        "weekday": "Sun",
        "value": 2019
      },
      {
        "hour": "6am",
        "weekday": "Sun",
        "value": 2128
      },
      {
        "hour": "7am",
        "weekday": "Sun",
        "value": 2246
      },
      {
        "hour": "8am",
        "weekday": "Sun",
        "value": 2421
      },
      {
        "hour": "9am",
        "weekday": "Sun",
        "value": 2788
      },
      {
        "hour": "10am",
        "weekday": "Sun",
        "value": 2959
      },
      {
        "hour": "11am",
        "weekday": "Sun",
        "value": 3018
      },
      {
        "hour": "12am",
        "weekday": "Sun",
        "value": 3154
      },
      {
        "hour": "1pm",
        "weekday": "Sun",
        "value": 3172
      },
      {
        "hour": "2pm",
        "weekday": "Sun",
        "value": 3368
      },
      {
        "hour": "3pm",
        "weekday": "Sun",
        "value": 3464
      },
      {
        "hour": "4pm",
        "weekday": "Sun",
        "value": 3746
      },
      {
        "hour": "5pm",
        "weekday": "Sun",
        "value": 3656
      },
      {
        "hour": "6pm",
        "weekday": "Sun",
        "value": 3336
      },
      {
        "hour": "7pm",
        "weekday": "Sun",
        "value": 3292
      },
      {
        "hour": "8pm",
        "weekday": "Sun",
        "value": 3269
      },
      {
        "hour": "9pm",
        "weekday": "Sun",
        "value": 3300
      },
      {
        "hour": "10pm",
        "weekday": "Sun",
        "value": 3403
      },
      {
        "hour": "11pm",
        "weekday": "Sun",
        "value": 3323
      },
      {
        "hour": "12pm",
        "weekday": "Mon",
        "value": 3346
      },
      {
        "hour": "1am",
        "weekday": "Mon",
        "value": 2725
      },
      {
        "hour": "2am",
        "weekday": "Mon",
        "value": 3052
      },
      {
        "hour": "3am",
        "weekday": "Mon",
        "value": 3876
      },
      {
        "hour": "4am",
        "weekday": "Mon",
        "value": 4453
      },
      {
        "hour": "5am",
        "weekday": "Mon",
        "value": 3972
      },
      {
        "hour": "6am",
        "weekday": "Mon",
        "value": 4644
      },
      {
        "hour": "7am",
        "weekday": "Mon",
        "value": 5715
      },
      {
        "hour": "8am",
        "weekday": "Mon",
        "value": 7080
      },
      {
        "hour": "9am",
        "weekday": "Mon",
        "value": 8022
      },
      {
        "hour": "10am",
        "weekday": "Mon",
        "value": 8446
      },
      {
        "hour": "11am",
        "weekday": "Mon",
        "value": 9313
      },
      {
        "hour": "12am",
        "weekday": "Mon",
        "value": 9011
      },
      {
        "hour": "1pm",
        "weekday": "Mon",
        "value": 8508
      },
      {
        "hour": "2pm",
        "weekday": "Mon",
        "value": 8515
      },
      {
        "hour": "3pm",
        "weekday": "Mon",
        "value": 8399
      },
      {
        "hour": "4pm",
        "weekday": "Mon",
        "value": 8649
      },
      {
        "hour": "5pm",
        "weekday": "Mon",
        "value": 7869
      },
      {
        "hour": "6pm",
        "weekday": "Mon",
        "value": 6933
      },
      {
        "hour": "7pm",
        "weekday": "Mon",
        "value": 5969
      },
      {
        "hour": "8pm",
        "weekday": "Mon",
        "value": 5552
      },
      {
        "hour": "9pm",
        "weekday": "Mon",
        "value": 5434
      },
      {
        "hour": "10pm",
        "weekday": "Mon",
        "value": 5070
      },
      {
        "hour": "11pm",
        "weekday": "Mon",
        "value": 4851
      },
      {
        "hour": "12pm",
        "weekday": "Tue",
        "value": 4468
      },
      {
        "hour": "1am",
        "weekday": "Tue",
        "value": 3306
      },
      {
        "hour": "2am",
        "weekday": "Tue",
        "value": 3906
      },
      {
        "hour": "3am",
        "weekday": "Tue",
        "value": 4413
      },
      {
        "hour": "4am",
        "weekday": "Tue",
        "value": 4726
      },
      {
        "hour": "5am",
        "weekday": "Tue",
        "value": 4584
      },
      {
        "hour": "6am",
        "weekday": "Tue",
        "value": 5717
      },
      {
        "hour": "7am",
        "weekday": "Tue",
        "value": 6504
      },
      {
        "hour": "8am",
        "weekday": "Tue",
        "value": 8104
      },
      {
        "hour": "9am",
        "weekday": "Tue",
        "value": 8813
      },
      {
        "hour": "10am",
        "weekday": "Tue",
        "value": 9278
      },
      {
        "hour": "11am",
        "weekday": "Tue",
        "value": 10425
      },
      {
        "hour": "12am",
        "weekday": "Tue",
        "value": 10137
      },
      {
        "hour": "1pm",
        "weekday": "Tue",
        "value": 9290
      },
      {
        "hour": "2pm",
        "weekday": "Tue",
        "value": 9255
      },
      {
        "hour": "3pm",
        "weekday": "Tue",
        "value": 9614
      },
      {
        "hour": "4pm",
        "weekday": "Tue",
        "value": 9713
      },
      {
        "hour": "5pm",
        "weekday": "Tue",
        "value": 9667
      },
      {
        "hour": "6pm",
        "weekday": "Tue",
        "value": 8774
      },
      {
        "hour": "7pm",
        "weekday": "Tue",
        "value": 8649
      },
      {
        "hour": "8pm",
        "weekday": "Tue",
        "value": 9937
      },
      {
        "hour": "9pm",
        "weekday": "Tue",
        "value": 10286
      },
      {
        "hour": "10pm",
        "weekday": "Tue",
        "value": 9175
      },
      {
        "hour": "11pm",
        "weekday": "Tue",
        "value": 8581
      },
      {
        "hour": "12pm",
        "weekday": "Wed",
        "value": 8145
      },
      {
        "hour": "1am",
        "weekday": "Wed",
        "value": 7177
      },
      {
        "hour": "2am",
        "weekday": "Wed",
        "value": 5657
      },
      {
        "hour": "3am",
        "weekday": "Wed",
        "value": 6802
      },
      {
        "hour": "4am",
        "weekday": "Wed",
        "value": 8159
      },
      {
        "hour": "5am",
        "weekday": "Wed",
        "value": 8449
      },
      {
        "hour": "6am",
        "weekday": "Wed",
        "value": 9453
      },
      {
        "hour": "7am",
        "weekday": "Wed",
        "value": 9947
      },
      {
        "hour": "8am",
        "weekday": "Wed",
        "value": 11471
      },
      {
        "hour": "9am",
        "weekday": "Wed",
        "value": 12492
      },
      {
        "hour": "10am",
        "weekday": "Wed",
        "value": 9388
      },
      {
        "hour": "11am",
        "weekday": "Wed",
        "value": 9928
      },
      {
        "hour": "12am",
        "weekday": "Wed",
        "value": 9644
      },
      {
        "hour": "1pm",
        "weekday": "Wed",
        "value": 9034
      },
      {
        "hour": "2pm",
        "weekday": "Wed",
        "value": 8964
      },
      {
        "hour": "3pm",
        "weekday": "Wed",
        "value": 9069
      },
      {
        "hour": "4pm",
        "weekday": "Wed",
        "value": 8898
      },
      {
        "hour": "5pm",
        "weekday": "Wed",
        "value": 8322
      },
      {
        "hour": "6pm",
        "weekday": "Wed",
        "value": 6909
      },
      {
        "hour": "7pm",
        "weekday": "Wed",
        "value": 5810
      },
      {
        "hour": "8pm",
        "weekday": "Wed",
        "value": 5151
      },
      {
        "hour": "9pm",
        "weekday": "Wed",
        "value": 4911
      },
      {
        "hour": "10pm",
        "weekday": "Wed",
        "value": 4487
      },
      {
        "hour": "11pm",
        "weekday": "Wed",
        "value": 4118
      },
      {
        "hour": "12pm",
        "weekday": "Thu",
        "value": 3689
      },
      {
        "hour": "1am",
        "weekday": "Thu",
        "value": 3081
      },
      {
        "hour": "2am",
        "weekday": "Thu",
        "value": 6525
      },
      {
        "hour": "3am",
        "weekday": "Thu",
        "value": 6228
      },
      {
        "hour": "4am",
        "weekday": "Thu",
        "value": 6917
      },
      {
        "hour": "5am",
        "weekday": "Thu",
        "value": 6568
      },
      {
        "hour": "6am",
        "weekday": "Thu",
        "value": 6405
      },
      {
        "hour": "7am",
        "weekday": "Thu",
        "value": 8106
      },
      {
        "hour": "8am",
        "weekday": "Thu",
        "value": 8542
      },
      {
        "hour": "9am",
        "weekday": "Thu",
        "value": 8501
      },
      {
        "hour": "10am",
        "weekday": "Thu",
        "value": 8802
      },
      {
        "hour": "11am",
        "weekday": "Thu",
        "value": 9420
      },
      {
        "hour": "12am",
        "weekday": "Thu",
        "value": 8966
      },
      {
        "hour": "1pm",
        "weekday": "Thu",
        "value": 8135
      },
      {
        "hour": "2pm",
        "weekday": "Thu",
        "value": 8224
      },
      {
        "hour": "3pm",
        "weekday": "Thu",
        "value": 8387
      },
      {
        "hour": "4pm",
        "weekday": "Thu",
        "value": 8218
      },
      {
        "hour": "5pm",
        "weekday": "Thu",
        "value": 7641
      },
      {
        "hour": "6pm",
        "weekday": "Thu",
        "value": 6469
      },
      {
        "hour": "7pm",
        "weekday": "Thu",
        "value": 5441
      },
      {
        "hour": "8pm",
        "weekday": "Thu",
        "value": 4952
      },
      {
        "hour": "9pm",
        "weekday": "Thu",
        "value": 4643
      },
      {
        "hour": "10pm",
        "weekday": "Thu",
        "value": 4393
      },
      {
        "hour": "11pm",
        "weekday": "Thu",
        "value": 4017
      },
      {
        "hour": "12pm",
        "weekday": "Fri",
        "value": 4022
      },
      {
        "hour": "1am",
        "weekday": "Fri",
        "value": 3063
      },
      {
        "hour": "2am",
        "weekday": "Fri",
        "value": 3638
      },
      {
        "hour": "3am",
        "weekday": "Fri",
        "value": 3968
      },
      {
        "hour": "4am",
        "weekday": "Fri",
        "value": 4070
      },
      {
        "hour": "5am",
        "weekday": "Fri",
        "value": 4019
      },
      {
        "hour": "6am",
        "weekday": "Fri",
        "value": 4548
      },
      {
        "hour": "7am",
        "weekday": "Fri",
        "value": 5465
      },
      {
        "hour": "8am",
        "weekday": "Fri",
        "value": 6909
      },
      {
        "hour": "9am",
        "weekday": "Fri",
        "value": 7706
      },
      {
        "hour": "10am",
        "weekday": "Fri",
        "value": 7867
      },
      {
        "hour": "11am",
        "weekday": "Fri",
        "value": 8615
      },
      {
        "hour": "12am",
        "weekday": "Fri",
        "value": 8218
      },
      {
        "hour": "1pm",
        "weekday": "Fri",
        "value": 7604
      },
      {
        "hour": "2pm",
        "weekday": "Fri",
        "value": 7429
      },
      {
        "hour": "3pm",
        "weekday": "Fri",
        "value": 7488
      },
      {
        "hour": "4pm",
        "weekday": "Fri",
        "value": 7493
      },
      {
        "hour": "5pm",
        "weekday": "Fri",
        "value": 6998
      },
      {
        "hour": "6pm",
        "weekday": "Fri",
        "value": 5941
      },
      {
        "hour": "7pm",
        "weekday": "Fri",
        "value": 5068
      },
      {
        "hour": "8pm",
        "weekday": "Fri",
        "value": 4636
      },
      {
        "hour": "9pm",
        "weekday": "Fri",
        "value": 4241
      },
      {
        "hour": "10pm",
        "weekday": "Fri",
        "value": 3858
      },
      {
        "hour": "11pm",
        "weekday": "Fri",
        "value": 3833
      },
      {
        "hour": "12pm",
        "weekday": "Sat",
        "value": 3503
      },
      {
        "hour": "1am",
        "weekday": "Sat",
        "value": 2842
      },
      {
        "hour": "2am",
        "weekday": "Sat",
        "value": 2808
      },
      {
        "hour": "3am",
        "weekday": "Sat",
        "value": 2399
      },
      {
        "hour": "4am",
        "weekday": "Sat",
        "value": 2280
      },
      {
        "hour": "5am",
        "weekday": "Sat",
        "value": 2139
      },
      {
        "hour": "6am",
        "weekday": "Sat",
        "value": 2527
      },
      {
        "hour": "7am",
        "weekday": "Sat",
        "value": 2940
      },
      {
        "hour": "8am",
        "weekday": "Sat",
        "value": 3066
      },
      {
        "hour": "9am",
        "weekday": "Sat",
        "value": 3494
      },
      {
        "hour": "10am",
        "weekday": "Sat",
        "value": 3287
      },
      {
        "hour": "11am",
        "weekday": "Sat",
        "value": 3416
      },
      {
        "hour": "12am",
        "weekday": "Sat",
        "value": 3432
      },
      {
        "hour": "1pm",
        "weekday": "Sat",
        "value": 3523
      },
      {
        "hour": "2pm",
        "weekday": "Sat",
        "value": 3542
      },
      {
        "hour": "3pm",
        "weekday": "Sat",
        "value": 3347
      },
      {
        "hour": "4pm",
        "weekday": "Sat",
        "value": 3292
      },
      {
        "hour": "5pm",
        "weekday": "Sat",
        "value": 3416
      },
      {
        "hour": "6pm",
        "weekday": "Sat",
        "value": 3131
      },
      {
        "hour": "7pm",
        "weekday": "Sat",
        "value": 3057
      },
      {
        "hour": "8pm",
        "weekday": "Sat",
        "value": 3227
      },
      {
        "hour": "9pm",
        "weekday": "Sat",
        "value": 3060
      },
      {
        "hour": "10pm",
        "weekday": "Sat",
        "value": 2855
      },
      {
        "hour": "11pm",
        "weekday": "Sat",
        "value": 2625
      }

    ];
  }
  getPieChart(){
    // Create chart instance
    let chart = am4core.create("pieChart", am4charts.PieChart);

    // Add data
    chart.data = [{
      "country": "Lithuania",
      "litres": 501.9
    }, {
      "country": "Czech Republic",
      "litres": 301.9
    }, {
      "country": "Ireland",
      "litres": 201.1
    }, {
      "country": "Germany",
      "litres": 165.8
    }, {
      "country": "Australia",
      "litres": 139.9
    }, {
      "country": "Austria",
      "litres": 128.3
    }, {
      "country": "UK",
      "litres": 99
    }, {
      "country": "Belgium",
      "litres": 60
    }, {
      "country": "The Netherlands",
      "litres": 50
    }];
    
    //Add and configure Series
    //Note - A Pie chart can have more than one PieSeries. To create nested Pie/Donut charts, 
    //we simply add multiple PieSeries. (making sure they have their dataFields configured properly 
    //to point at correct properties in the data)
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    // Add a legend
    chart.legend = new am4charts.Legend();
    //Adds Border on each Slice
    // pieSeries.slices.template.stroke = am4core.color("#4a2abb");
    // pieSeries.slices.template.strokeWidth = 2;
    // pieSeries.slices.template.strokeOpacity = 1;
    
    //Disabling elements
    // pieSeries.labels.template.disabled = true;
    // pieSeries.ticks.template.disabled = true;
    // pieSeries.slices.template.tooltipText = "";

    //Controlling appearance via states 
    // Pie series' slices has already three states pre-created for us:
    // 1."default" state, which is applied when slice is in its initial position.
    // 2."hover" state, which is applied when you hover or touch the slice.
    // 3."active" state, which is applied when you click or tap the slice so it pulls out.

    // let hs = pieSeries.slices.template.states.getKey("hover");
    // hs.properties.scale = 1.2; //Default is 1.1

    // let as = pieSeries.slices.template.states.getKey("active");
    // as.properties.shiftRadius = 0;

    //Changing slice label content
    // pieSeries.labels.template.text = "{category}: {value.value}";

    //Changing tooltip content
    //pieSeries.slices.template.tooltipText = "{category}: {value.value}";

    // Changing legend value
    // chart.legend.valueLabels.template.text = "{value.value}";
  }
  getPieChartDonut(){
    let chart = am4core.create("pieChartDonut", am4charts.PieChart);

  // Add data
    chart.data = [{
      "country": "Lithuania",
      "litres": 501.9
    }, {
      "country": "Czech Republic",
      "litres": 301.9
    }, {
      "country": "Ireland",
      "litres": 201.1
    }, {
      "country": "Germany",
      "litres": 165.8
    }, {
      "country": "Australia",
      "litres": 139.9
    }, {
      "country": "Austria",
      "litres": 128.3
    }, {
      "country": "UK",
      "litres": 99
    }, {
      "country": "Belgium",
      "litres": 60
    }, {
      "country": "The Netherlands",
      "litres": 50
    }];
    chart.innerRadius = am4core.percent(40);
    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    // Add a legend
    chart.legend = new am4charts.Legend();
  }
  getPieChartThreeD(){
    let chart = am4core.create("pieChartThreeD", am4charts.PieChart3D);

  // Add data
    chart.data = [{
      "country": "Lithuania",
      "litres": 501.9
    }, {
      "country": "Czech Republic",
      "litres": 301.9
    }, {
      "country": "Ireland",
      "litres": 201.1
    }, {
      "country": "Germany",
      "litres": 165.8
    }, {
      "country": "Australia",
      "litres": 139.9
    }, {
      "country": "Austria",
      "litres": 128.3
    }, {
      "country": "UK",
      "litres": 99
    }, {
      "country": "Belgium",
      "litres": 60
    }, {
      "country": "The Netherlands",
      "litres": 50
    }];
    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries3D());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    // Add a legend
    chart.legend = new am4charts.Legend();
  }
  getColumnBarChart(){
    let chart = am4core.create("columnBarChart", am4charts.XYChart);

    // Add data
    chart.data = [{
      "year": 2005,
      "income": 23.5,
      "expenses": 18.1
    },{
      "year": 2006,
      "income": 26.2,
      "expenses": 22.8
    },{
      "year": 2007,
      "income": 30.1,
      "expenses": 23.9
    },{
      "year": 2008,
      "income": 29.5,
      "expenses": 25.1
    },{
      "year": 2009,
      "income": 24.6,
      "expenses": 25
    }];

    // Create axes
    let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "year";
    categoryAxis.numberFormatter.numberFormat = "#";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;

    let  valueAxis = chart.xAxes.push(new am4charts.ValueAxis()); 
    valueAxis.renderer.opposite = true;

    // Create series
    function createSeries(field, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "year";
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
      series.columns.template.height = am4core.percent(100);
      series.sequencedInterpolation = true;

      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{valueX}";
      valueLabel.label.horizontalCenter = "left";
      valueLabel.label.dx = 10;
      valueLabel.label.hideOversized = false;
      valueLabel.label.truncate = false;

      let categoryLabel = series.bullets.push(new am4charts.LabelBullet());
      categoryLabel.label.text = "{name}";
      categoryLabel.label.horizontalCenter = "right";
      categoryLabel.label.dx = -10;
      categoryLabel.label.fill = am4core.color("#fff");
      categoryLabel.label.hideOversized = false;
      categoryLabel.label.truncate = false;
    }

    createSeries("income", "Income");
    createSeries("expenses", "Expenses");
  }
  getLiveDataChart(){
    let chart = am4core.create("liveDataChart", am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0;

    chart.padding(0, 0, 0, 0);

    chart.zoomOutButton.disabled = true;

    let data = [];
    let visits = 10;
    let i = 0;

    for (i = 0; i <= 30; i++) {
        visits -= Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
        data.push({ date: new Date().setSeconds(i - 30), value: visits});
    }

    chart.data = data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.dateFormats.setKey("second", "ss");
    dateAxis.periodChangeDateFormats.setKey("second", "[bold]h:mm a");
    dateAxis.periodChangeDateFormats.setKey("minute", "[bold]h:mm a");
    dateAxis.periodChangeDateFormats.setKey("hour", "[bold]h:mm a");
    dateAxis.renderer.inside = true;
    dateAxis.renderer.axisFills.template.disabled = true;
    dateAxis.renderer.ticks.template.disabled = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.interpolationDuration = 500;
    valueAxis.rangeChangeDuration = 500;
    valueAxis.renderer.inside = true;
    valueAxis.renderer.minLabelPosition = 0.05;
    valueAxis.renderer.maxLabelPosition = 0.95;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "value";
    series.interpolationDuration = 500;
    series.defaultState.transitionDuration = 0;
    series.tensionX = 0.8;

    chart.events.on("datavalidated", function () {
        dateAxis.zoom({ start: 1 / 15, end: 1.2 }, false, true);
    });

    dateAxis.interpolationDuration = 500;
    dateAxis.rangeChangeDuration = 500;

    document.addEventListener("visibilitychange", function() {
        if (document.hidden) {
            if (interval) {
                clearInterval(interval);
            }
        }
        else {
            startInterval();
        }
    }, false);

    // add data
    let interval;
    function startInterval() {
        interval = setInterval(function() {
            visits =
                visits + Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 5);
            let lastdataItem = series.dataItems.getIndex(series.dataItems.length - 1);
            chart.addData(
                { date: new Date(lastdataItem.dateX.getTime() + 1000), value: visits },
                1
            );
        }, 1000);
    }

    startInterval();

    // all the below is optional, makes some fancy effects
    // gradient fill of the series
    series.fillOpacity = 1;
    let gradient = new am4core.LinearGradient();
    gradient.addColor(chart.colors.getIndex(0), 0.2);
    gradient.addColor(chart.colors.getIndex(0), 0);
    series.fill = gradient;

    //this makes date axis labels to fade out
    dateAxis.renderer.labels.template.adapter.add("fillOpacity", function (fillOpacity, target) {
        let dataItem = target.dataItem;
        return dataItem.position;
    })

   // need to set this, otherwise fillOpacity is not changed and not set
    dateAxis.events.on("validated", function () {
        am4core.iter.each(dateAxis.renderer.labels.iterator(), function (label) {
            label.fillOpacity = label.fillOpacity;
        })
    })

    //this makes date axis labels which are at equal minutes to be rotated
    // dateAxis.renderer.labels.template.adapter.add("rotation", function (rotation, target) {
    //     let dataItem = target.dataItem;
    //     if (dataItem.dates && dataItem.dates.getTime() == am4core.time.round(new Date(dataItem.dates.getTime()), "minute").getTime()) {
    //         target.verticalCenter = "middle";
    //         target.horizontalCenter = "left";
    //         return -90;
    //     }
    //     else {
    //         target.verticalCenter = "bottom";
    //         target.horizontalCenter = "middle";
    //         return 0;
    //     }
    // })

    //bullet at the front of the line
    let bullet = series.createChild(am4charts.CircleBullet);
    bullet.circle.radius = 5;
    bullet.fillOpacity = 1;
    bullet.fill = chart.colors.getIndex(0);
    bullet.isMeasured = false;

    series.events.on("validated", function() {
        bullet.moveTo(series.dataItems.last.point);
        bullet.validatePosition();
    });
  }
}
// chart.paddingRight = 20;

// let data = [];
// let visits = 10;
// for (let i = 1; i < 366; i++) {
//   visits += Math.round((Math.random() < 0.5 ? 1 : -1) * Math.random() * 10);
//   data.push({ date: new Date(2018, 0, i), name: "name" + i, value: visits });
// }

// chart.data = data;

// let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
// dateAxis.renderer.grid.template.location = 0;

// let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
// valueAxis.tooltip.disabled = true;
// valueAxis.renderer.minWidth = 35;

// let series = chart.series.push(new am4charts.LineSeries());
// series.dataFields.dateX = "date";
// series.dataFields.valueY = "value";

// series.tooltipText = "{valueY.value}";
// chart.cursor = new am4charts.XYCursor();

// let scrollbarX = new am4charts.XYChartScrollbar();
// scrollbarX.series.push(series);
// chart.scrollbarX = scrollbarX;

// this.chartXY = chart;