import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MenuItem, SelectItem, Dropdown, AutoComplete, OverlayPanel, MessageService } from 'primeng/primeng';
import { AuthenticationService } from '../../ui-services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Menu } from 'primeng/components/menu/menu';
import { StorageService } from '../../ui-services/storage.service';
import { Domain, DomainProfileCriteria, UserCriteria, Feedback, HotelContext, DomainContext } from 'mantras-api';
import { Utilities } from '../../ui-services/utilities';
import { AutoCompleteModule } from 'primeng/primeng';
import * as introJs from 'intro.js';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { NotificationService } from '../../ui-services/notification.service';
import { LoadingService } from '../../ui-services/loading.service';
import { EventManagementService } from '../../ui-services/eventmanagement.service';
import { UserService } from '../../ui-services/user.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'mm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  domainSwitched = false;
  hotelId: any;

  hotelDrpFlag: boolean = true;
  @ViewChild('menu') menu: Menu;
  @ViewChild('hotelList') hotelList: Dropdown

  displayNotification = false;
  items: MenuItem[] = [];
  userItems: MenuItem[];
  currentUser: any;
  notificationCount = 0;
  domains = [];
  selectedDomainKey: string;
  hotels = [];
  selectedHotel: string;
  user;
  selectedDomain: any;
  selectedDomainHeader: any;
  selectedUser: any;
  showSwitchUserContextDialog = false;
  filteredDomains: any[] = new Array();
  filteredUsers: any[] = [];
  hasSystemAccess = false;
  isUserSwitched = false;
  switchedUser;
  breadCrumbItems = [];
  currentUserName;
  currentUserId;
  bellClicked = false;
  userIconClick = false;
  displayFeedbackDialog = false;
  typeOfFeedback: string = 'Written';
  feedbackText = "";
  breadCrumbsSubscription: Subscription;
  environment;
  public href: string = "";

  constructor(private router: Router,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private notificationService: NotificationService,
    public loadingService: LoadingService,
    private eventManager: EventManagementService,
    private messageService: MessageService,
    private userService: UserService) {
    this.user = storageService.get(StorageService.loggedInUser).User;
    let tempTheme = this.storageService.get(StorageService.userTheme);
    this.theme = (tempTheme) ? tempTheme : this.theme;
    this.breadCrumbItems = [{ label: "", icon: 'fa fa-home', routerLink: ["/dashboard"] }];
    this.breadCrumbsSubscription = this.eventManager.loadBreadCrumbsListener$.subscribe(
      route => {
        this.loadBreadCrumbs(route);
      }
    );
    this.environment = environment;
    this.populateHotelList();
  }

  populateHotelList() {
    this.ngOnInit();
    this.currentUser = this.storageService.get(StorageService.currentUser);
    if (this.currentUser) {
      console.log("Domains: " + this.currentUser.DomainContext.Domains);
      this.hotels = [];
      this.domains = [];
      this.currentUser.DomainContext.Domains.forEach(domain => {
        if (!this.hasSystemAccess && domain.Name != null && domain.Name.toLowerCase() === "system") {
          this.hasSystemAccess = true;
        }
        //if(domain.Name.toLowerCase() != "system")  
        if (this.isUserSwitched && domain.Id != "System") {
          this.domains.push({ label: domain.Name, value: domain.Id });
        } else {
          this.domains.push({ label: domain.Name, value: domain.Id });
        }
        if (domain.DomainHotels) {
          domain.DomainHotels.forEach(domainHotel => {
            if (this.isUserSwitched && domainHotel.Id != "System") {
              this.hotels.push({ label: domainHotel.Name, value: domain.Id + "::" + domainHotel.Id });
            } else {
              this.hotels.push({ label: domainHotel.Name, value: domain.Id + "::" + domainHotel.Id });
            }
          });
        }
        if (domain.Name.toLowerCase() == "system" && !this.isUserSwitched) {
          this.hotels.push({ label: "System", value: domain.Id + "::" + "System" });
        }
      });
      this.hotels.sort(Utilities.sort_label_asc);

      this.selectedDomainKey = this.currentUser.DomainContext.CurrentDomainId;
      this.selectedDomainHeader = this.currentUser.DomainContext.CurrentDomainId;
      if (this.selectedDomainHeader == "System") {
        //this.currentUser.HotelContext.HotelId = "System";
        this.selectedHotel = this.currentUser.DomainContext.CurrentDomainId + "::System";
      }
      else
        this.selectedHotel = this.currentUser.DomainContext.CurrentDomainId + "::" + this.currentUser.HotelContext.HotelId;
    }
  }

  ngOnInit() {
    this.user = this.storageService.get(StorageService.loggedInUser).User;
    let switchedUserContext = this.storageService.get(StorageService.switchedUser);
    this.isUserSwitched = (switchedUserContext != null && switchedUserContext != undefined);
    this.switchedUser = (this.isUserSwitched) ? switchedUserContext.User : null;
    this.notificationCount = this.notificationService.getCount();
    this.currentUserName = (this.user.FirstName != "" || this.user.LastName != "") ? this.user.FirstName + " " + this.user.LastName : "";
    this.currentUserId = this.user.Id != "" ? this.user.Id : "";
    this.href = window.location.hostname;
    this.loadMenu();
  }

  startIntro() {
    let guide = introJs()
    guide.setOption("showStepNumbers", false);
    guide.setOption("showBullets", false);
    guide.start();
  }

  theme = "minimal";
  @ViewChild("themedialog") themeDialog;
  @ViewChild("op") userMenuBar: OverlayPanel;
  loadMenu() {
    this.userItems = [];
    this.userItems.push({ separator: true });
    if (this.hasSystemAccess)
      this.userItems.push({ label: 'Switch User', icon: 'fa fa-key user-menu-icon', command: (event) => this.showSwitchDialog(event) });
    if (this.isUserSwitched)
      this.userItems.push({ label: 'Switch Back', icon: 'fa fa-exchange', command: (event) => this.showSwitchBack(event) });
    this.userItems.push({ label: 'Theme', icon: 'fa fa-pencil', command: (event) => { this.userMenuBar.hide(); this.themeDialog.show(event); } });
    this.userItems.push({ label: 'Introduction', icon: 'fa fa-info', command: () => this.startIntro() });
    this.userItems.push({ label: 'Feedback', icon: 'fa fa-commenting', command: () => this.showFeedbackDialog() });
    this.userItems.push({ separator: true });
    this.userItems.push({ label: 'Logout', icon: 'fa fa-sign-out', command: (event) => this.logout(event) });
  }

  onThemeChange(value) {
    if (value != null)
      this.storageService.set(StorageService.userTheme, value);
    //else this.storageService.remove(StorageService.userTheme);
    if (value != null && value != undefined)
      Utilities.loadCSS("theme-style", "assets/theme-" + value + ".css");
    else
      Utilities.loadCSS("theme-style", "assets/theme.css");
    this.theme = value;
  }

  logout(event) {
    console.log("Logout method called");
    this.authService.logout();
    //this.router.navigate(['login']);
    window.location.href = "/login.html";
  }


  switchHotel(dropdown: Dropdown) {
    let domainHotelId = dropdown.selectedOption.value;
    let arr = domainHotelId.split("::");
    console.log("Selected value: " + arr);
    //Check If Hotel has changed
    let dId = this.storageService.get(StorageService.userDomainContext).CurrentDomainId;
    if (this.selectedDomainHeader != "System") {
      let hId = this.storageService.get(StorageService.userHotelContext).HotelId;
      //Hotel not changed.
      if (dId == arr[0] && hId == arr[1]) return;
    }
    if (this.isUserSwitched) {
      let switchedUserContext = this.storageService.get(StorageService.switchedUser);
      this.authService.switchSessionUserContex(arr[0], switchedUserContext.User.Id, arr[1]).then(response => {
        console.log("Switch User Domain:", response);
        this.populateHotelList();
        this.showSwitchUserContextDialog = false;
        return response;
      });
    } else {
      this.authService.switchDomain(arr[0], arr[1]).then(result => {
        this.currentUser = this.storageService.get(StorageService.currentUser);
        this.selectedDomainHeader = this.currentUser.DomainContext.CurrentDomainId;
        this.ngOnInit();
      });
    }
  }

  @ViewChild("domainSelection") domainSelection: AutoComplete;
  showSwitchDialog(event) {
    this.selectedDomain = null;
    this.selectedUser = null;
    this.filteredDomains = [];
    this.filteredUsers = [];
    this.showSwitchUserContextDialog = true;
    let input = this.domainSelection.domHandler.findSingle(this.domainSelection.el.nativeElement, 'input');
    input.autofocus = true;
  }

  async filterDomains(event) {
    let query = event.query;
    let domainCriteria = new DomainProfileCriteria();
    domainCriteria.Name = "*" + query + "*";
    this.filteredDomains = await this.authService.findDomains(domainCriteria).then(domainList => {
      console.log("Filterd Domains:", domainList);
      return domainList.filter(function (d) { return d.Id != "System" });
    });
  }

  async filterUsers(event) {
    let query = (event && event.query) ? event.query.toLowerCase() : "";
    let userCriteria = new UserCriteria();
    userCriteria.DomainId = this.selectedDomain.Id;
    userCriteria.IsActive = true;
    this.filteredUsers = await this.authService.findDomainUser(userCriteria).then(userList => {
      console.log("Filtered User:", userList);
      let filteredList = [];
      userList.forEach(user => {
        let userName = ((user.FirstName != "" || user.LastName != "") ? user.FirstName + " " + user.LastName : "");
        if ((user.Id && user.Id.toLowerCase().indexOf(query) >= 0) || (user["FirstName"] && user["FirstName"].toLowerCase().indexOf(query) >= 0) ||
          (user["LastName"] && user["LastName"].toLowerCase().indexOf(query) >= 0) || userName.toLowerCase().indexOf(query) >= 0) {
          user["label"] = userName + " (" + user.Id + ")";
          filteredList.push(user);
        }
      });
      return filteredList;
    })
  }

  switchUser(event) {
    if (this.selectedDomain && this.selectedUser) {
      this.authService.switchSessionUserContex(this.selectedDomain.Id, this.selectedUser.Id).then(response => {
        console.log("Switch User Domain:", response);
        this.isUserSwitched = true;
        this.populateHotelList();
        this.showSwitchUserContextDialog = false;
        return response;
      });
    }
  }

  async showSwitchBack(event) {
    let loginUserContext = this.storageService.get(StorageService.loggedInUser);
    if (loginUserContext && loginUserContext.DomainContext && loginUserContext.HotelContext) {
      this.hotelId = loginUserContext.HotelContext.HotelId;
    } else if (loginUserContext && loginUserContext.DomainContext && loginUserContext.DomainContext.CurrentDomainId == "System") {
      this.hotelId = "System";
    }
    let domainId = loginUserContext.DomainContext.CurrentDomainId;
    let userId = loginUserContext.User.Id;
    let switchUserResp = await this.authService.switchSessionUserContex(domainId, userId, this.hotelId).then(response => {
      this.storageService.remove(StorageService.switchedUser);
      this.isUserSwitched = false;
      this.populateHotelList();
    });
  }
  onClickNotification() {
    this.bellClicked = !this.bellClicked;
    this.displayNotification = true;
    console.log("displayNotification" + this.displayNotification);
  }
  displayValueChanged() {
    this.displayNotification = false;
    this.bellClicked = !this.bellClicked;
  }
  displayNotificationIcon() {
    if (this.authService.isSystemUser()) {
      return 'block';
    }
    else return 'none';
  }
  hotelListOnShow() {

  }
  routeToDashboard() {
    this.router.navigate(['dashboard']).then(onSuccess =>
      this.eventManager.sideNavChange({ 'navigationChange': 'true' })
    );
  }
  userIconClicked() {
    this.userIconClick = !this.userIconClick;
  }
  updateNotificationCount($event) {
    this.notificationCount = $event;
  }
  showFeedbackDialog() {
    this.displayFeedbackDialog = true;
  }
  sendFeedback() {
    let feedback: Feedback = new Feedback();
    let domainContext = this.storageService.get(StorageService.userDomainContext);
    feedback.HotelId = this.storageService.get(StorageService.userHotelContext).HotelName;
    feedback.DomainId = Utilities.findId(domainContext.Domains, domainContext.CurrentDomainId);
    feedback.FeedbackText = this.feedbackText;
    feedback.UserId = this.storageService.get(StorageService.loginUserId);
    this.userService.saveFeedback(feedback).then(result => {
      if (!(result && result.error)) {
        this.feedbackText = "";
        this.messageService.add({ key: 'sent', severity: 'success', life: 2000, summary: 'Feedback Sent', detail: 'Thank you for the feedback.' });
        this.displayFeedbackDialog = false;
      }
    });

  }
  closeFeedbackDialog() {
    this.displayFeedbackDialog = false;
  }
  loadBreadCrumbs(route) {
    switch (route._routerState.url) {
      case "/analytics/bookinganalytics": this.breadCrumbItems = [{ label: 'Analytics', routerLink: ['/analytics'] },
      { label: 'Booking Analytics', routerLink: ['/analytics/bookinganalytics'] }];
        break;
      case "/analytics/trendanalytics": this.breadCrumbItems = [{ label: 'Analytics', routerLink: ['/analytics'] },
      { label: 'Trend Analytics', routerLink: ['/analytics/trendanalytics'] }];
        break;
      case "/analytics/competitiontracking": this.breadCrumbItems = [{ label: 'Analytics', routerLink: ['/analytics'] },
      { label: 'Competition Tracking', routerLink: ['/analytics/competitiontracking'] }];
        break;
      case "/settings/property": this.breadCrumbItems = [{ label: "Settings", routerLink: ["/settings"] },
      { label: "Property", routerLink: ["/settings/property"] }];
        break;
      case "/settings/users": this.breadCrumbItems = [{ label: "Settings", routerLink: ["/settings"] },
      { label: "Users", routerLink: ["/settings/users"] }];
        break;
      case "/settings/domainchannels": this.breadCrumbItems = [{ label: "Settings", routerLink: ["/settings"] },
      { label: "Channels", routerLink: ["/settings/domainchannels"] }];
        break;
      case "/settings/usage": this.breadCrumbItems = [{ label: "Settings", routerLink: ["/settings"] },
      { label: "Usage", routerLink: ["/settings/usage"] }];
        break;
      case "/settings/exception": this.breadCrumbItems = [{ label: "Settings", routerLink: ["/settings"] },
      { label: "Exception", routerLink: ["/settings/exception"] }];
        break;
      case "/settings/domains": this.breadCrumbItems = [{ label: "Settings", routerLink: ["/settings"] },
      { label: "Domains", routerLink: ["/settings/domains"] }];
        break;
      case "/settings/hotelCatalog": this.breadCrumbItems = [{ label: "Settings", routerLink: ["/settings"] },
      { label: "Hotel Catalog", routerLink: ["/settings/hotelCatalog"] }];
        break;
      case "/settings/channelcatalog": this.breadCrumbItems = [{ label: "Settings", routerLink: ["/settings"] },
      { label: "Channel Catalog", routerLink: ["/settings/channelcatalog"] }];
        break;
      case "/settings/tasks": this.breadCrumbItems = [{ label: "Settings", routerLink: ["/settings"] },
      { label: "Tasks", routerLink: ["/settings/tasks"] }];
        break;
        case "/settings/usersManagement": this.breadCrumbItems = [{ label: "Settings", routerLink: ["/settings"] },
        { label: "User Management", routerLink: ["/settings/usersManagement"] }];
          break;

      case "/settings/alerts": this.breadCrumbItems = [{ label: "Settings", routerLink: ["/settings"] },
      { label: "Alerts", routerLink: ["/settings/alerts"] }];
        break;
      case "/settings/raterules": this.breadCrumbItems = [{ label: "Settings", routerLink: ["/settings"] },
      { label: "Rate Rules", routerLink: ["/settings/raterules"] }];
        break;
      case "/settings/backuprestore": this.breadCrumbItems = [{ label: "Settings", routerLink: ["/settings"] },
      { label: "Backup & Restore", routerLink: ["/settings/backuprestore"] }];
        break;
      case "/rms/revenuetools/gapanalysis": this.breadCrumbItems = [{ label: "Revenue Management", routerLink: ["/rms"] },
      { label: "Tools", routerLink: ["/rms/revenuetools"] },
      { label: "Gap Analysis", routerLink: ["/rms/revenuetools/gapanalysis"] }];
        break;
      case "/rms/revenueoperations": this.breadCrumbItems = [{ label: "Revenue Management", routerLink: ["/rms"] },
      { label: "Operations", routerLink: ["/rms/revenueoperations"] }];
        break;
      case "/rms/revenueplanning": this.breadCrumbItems = [{ label: "Revenue Managements", routerLink: ["/rms"] },
      { label: "Planning", routerLink: ["/rms/revenueplanning"] }];
        break;
      case "/rms/revenuetools": this.breadCrumbItems = [{ label: 'Revenue Management', routerLink: ['/rms'] },
      { label: "Tools", routerLink: ["/rms/revenuetools"] }];
        break;
      default: this.breadCrumbItems = [];
    }
  }

  showDomain(event) {
    if (event.ctrlKey) {
      let dId = this.storageService.get(StorageService.userDomainContext).CurrentDomainId;
      alert("Domain ID: " + dId);
    }
  }
  showHotel(event) {
    if (event.ctrlKey) {
      let dId = this.storageService.get(StorageService.userDomainContext).CurrentDomainId;
      if (this.selectedDomainHeader != "System") {
        let hId = this.storageService.get(StorageService.userHotelContext).HotelId;
        alert("Hotel ID: " + hId);
      }
    }
  }
}
