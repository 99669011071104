import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { UserService } from './user.service';
import { Utilities } from './utilities';
import { AuthenticationService } from './authentication.service';
import { EventManagementService } from './eventmanagement.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,private storageService: StorageService,private userService:UserService,private authService:AuthenticationService, private eventManager:EventManagementService) { }
  
      async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let navigate = true;
        if(this.storageService.get(StorageService.lastLoginTime) && this.storageService.get(StorageService.currentUser) &&
        this.storageService.get(StorageService.loggedInUser)){
            console.log("Login Time: "+ this.storageService.get(StorageService.lastLoginTime));
            let loginTime = new Date(this.storageService.get(StorageService.lastLoginTime));
            loginTime.setMinutes(loginTime.getMinutes()+StorageService.expire);
            if(this.authService.isSystemUser()){
                navigate = true;
            } else {
                if(!(route.url[0] === undefined) && route.url[0].path!= 'dashboard'){
                    if(await this.authService.isUserBlocked() || 
                        !this.userService.checkAccess(route.url[0].path)){
                        this.router.navigate(['/dashboard']).then(onSuccess =>
                            this.eventManager.sideNavChange({"navigationChange":"dashboard"})
                        );
                        navigate = false;
                    }
                }
            }
            if(navigate && !(route.url[0] === undefined)) this.eventManager.sideNavChange({"navigationChange":route.url[0].path});
            return navigate;
        }
  
          // not logged in so redirect to login page with the return url
          //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
          window.location.href = "/login.html";
          return false;
      }
}
