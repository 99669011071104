/*This component need a js file which is being loaded during*/
import { Component, OnInit, AfterViewInit, Inject, Renderer2, Input, HostListener } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { Utilities } from '../../ui-services/utilities';
import { Http } from '@angular/http';
import * as jQuery from 'jquery';

@Component({
  selector: 'mm-pivotviewer2',
  templateUrl: './pivotviewer2.component.html',
  styleUrls: ['./pivotviewer2.component.css']
})
export class Pivotviewer2Component implements OnInit {
  @Input() templateList: any[];
  @Input() styles;
  pivotScriptTag;
  constructor(@Inject(DOCUMENT) private _document, private http: Http) {
    this.loadCSS("mm-css-HtmlPivotView", "/assets/HtmlPivotViewer/css/HtmlPivotView.css");
  }

  ngOnInit() {
  }


  //Loading CSS Files
  loadCSS(id, url) {
    //Remove old css before loading again.
    if (document.getElementById(id)) document.getElementById(id).remove();
    let node = document.createElement('link');
    node.setAttribute("id", id);
    node.type = 'text/css';
    node.rel = 'stylesheet';
    node.setAttribute("href", url);
    document.head.appendChild(node);
    return node;
  }

  //Load inline javascript to load pivotview
  loadTextScript(templateList, loadData?: any) {
    let doc = document;
    if (doc.getElementById("mm-ls-main-script")) doc.getElementById("mm-ls-main-script").remove();
    let s = doc.createElement('script');
    s.setAttribute("id", "mm-ls-main-script");
    s.type = `text/javascript`;
    s.text = `
    var viewer = Pivot.init(document.getElementById("theViewer2"));
    Pivot.JsonLoader.load(viewer,JSON.parse('${JSON.stringify(templateList).replace(/\'/g, "\\'")}'), "/assets/HtmlPivotViewer/bookingAnalytics.json",JSON.parse('${JSON.stringify(loadData).replace(/\'/g, "\\'")}'));`;
    doc.head.appendChild(s);
  }

  loadPivotViewerScriptWithData(loadData?) {
    this.loadTextScript(this.templateList, loadData);
  }

  unloadScript(id) {
    if (document.getElementById(id)) document.getElementById(id).remove();
  }

  ngOnDestroy() {
    this.unloadScript("mm-htmlpivotviewer");
  }


}
