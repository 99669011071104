
import { Component, OnInit, HostListener } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { ActivatedRoute, Router } from '@angular/router';
import { EventManagementService } from '../ui-services/eventmanagement.service';
import { StorageService } from '../ui-services/storage.service';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { RmsService } from '../ui-services/rms.service';
import { RMSBookingCriteria, Range } from 'mantras-api';

@Component({
  selector: 'false-rms',
  templateUrl: './rms.component.html',
  styleUrls: ['./rms.component.css']
})
export class RmsComponent implements OnInit {

  items: MenuItem[];
  bookings;
  hasSystemAccess = false;
  constructor(private router:Router,private route: ActivatedRoute, private eventManager:EventManagementService,
  private storageService:StorageService, private rmsService: RmsService) {
    this.items = [{label:'Revenue Management',icon:'fa fa-dollar',routerLink: ['/rms']}];
    this.hasSystemAccess = Utilities.checkSystemAccess(this.storageService.get(StorageService.currentUser).DomainContext.Domains);
  }
  
  async routePage(navigationLink){
    this.router.navigate([navigationLink], {relativeTo: this.route });
  }
  
  ngAfterViewInit(): void {
    this.setHeight();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true));
  }
  ngOnInit() {
  }
}
