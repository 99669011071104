import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef, HostListener, AfterViewInit } from '@angular/core';
import { MenuItem, Menu } from 'primeng/primeng';
import { Router } from '@angular/router';
import { StorageService } from '../../ui-services/storage.service';
import { Utilities } from '../../ui-services/utilities';
import { Subscription } from 'rxjs';
import { RatecalendarService } from '../../ui-services/ratecalendar.service';
import { AuthenticationService } from '../../ui-services/authentication.service';
import { LoadingService } from '../../ui-services/loading.service';
import * as jQuery from 'jquery';
import { UserService } from '../../ui-services/user.service';
import { EventManagementService } from '../../ui-services/eventmanagement.service';
import { environment } from '../../../environments/environment.prod';

@Component({
  selector: 'mm-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit, AfterViewInit {
  hotelId: string;
  currentUser: any;
  selectedDomain: any;
										   

  isSystemUser = false;
  menuItems: MenuItem[] = [];
  miniMenuItems: MenuItem[] = [];
  menuItemsList: MenuItem[] = [];
  isSignedIn: boolean = false;
  hideSideNav: boolean = false;
  disableAllMenuItems: boolean = false;
  userSubscription: Subscription;
  sideNavChangeSubscription: Subscription;
  isBlockingMessageDisplayed = false;
  @ViewChild('bigMenu') bigMenu: Menu;
  @ViewChild('smallMenu') smallMenu: Menu;
  menuItemCollapse;
  currentSelectedSection;
  constructor(public loadingService: LoadingService, private resolver: ComponentFactoryResolver, private viewContainer: ViewContainerRef,
    private router: Router, private storageService: StorageService, private rateCalendarService: RatecalendarService,
    private authenticationService: AuthenticationService, private userService: UserService, private eventMgmt: EventManagementService) {

    //Bind the RRP Filter wrt hotel.
    this.currentUser = this.storageService.get(StorageService.currentUser);
    this.selectedDomain = this.currentUser.DomainContext.CurrentDomainId;
    if (this.selectedDomain != "System") {
      this.hotelId = this.storageService.get(StorageService.userHotelContext).HotelId;
      let domainHotel = this.authenticationService.getDomainHotel(this.hotelId);
      let hotelChannel = this.authenticationService.getHotelChannels(this.hotelId);
      this.rateCalendarService.resetRRPCellFilter(domainHotel, hotelChannel, this.storageService.get(StorageService.userHotelContext));
    }

    this.userSubscription = storageService.userContextListener$.subscribe(
      userContext => {
        this.currentUser = this.storageService.get(StorageService.currentUser);
        this.selectedDomain = this.currentUser.DomainContext.CurrentDomainId;
        if (this.selectedDomain != "System") {
          this.hotelId = this.storageService.get(StorageService.userHotelContext).HotelId;
          let domainHotel = this.authenticationService.getDomainHotel(this.hotelId);
          let hotelChannel = this.authenticationService.getHotelChannels(this.hotelId);
          this.rateCalendarService.resetRRPCellFilter(domainHotel, hotelChannel, this.storageService.get(StorageService.userHotelContext));
        }
        this.isBlockingMessageDisplayed = false;
        this.refreshMenuItems();
        this.showFilterContent(null, this.router.url, this.currentSelectedSection, true);
      });

    this.sideNavChangeSubscription = eventMgmt.sideNavChangeListener$.subscribe(
      sideNavChangeData => {
        if (sideNavChangeData["hideSideNav"] != undefined && sideNavChangeData["hideSideNav"] != this.hideSideNav) {
          this.hideShowSideNav();
        }
        if (sideNavChangeData["navigationChange"] != undefined && sideNavChangeData["navigationChange"] != null) {
          let path = "/" + sideNavChangeData["navigationChange"];
          let menuItem = this.menuItemsList.find((item) => { return path.startsWith(item.routerLink[0]) });
          if (menuItem) {
            let menuIndex = this.menuItemsList.indexOf(menuItem);
            this.initializeMenuItemList(menuIndex);
          }
        }
      }
    )
    this.menuItemCollapse = [{ title: this.hideSideNav ? "Show" : "Hide", icon: 'fa fa-bars', command: (event) => this.hideShowSideNav() }];
    this.initializeMenuItemList();
  }

  isLabVisible(): boolean {
    // 1. Only visible for MaAximojo labels 
    if (environment.vendor !== 'MAXIMOJO') return false;
    // 2. Only for Admins & SystemUsers
    if (!(this.userService.checkAccess("Admins") && !this.isCurrentDomainIsSystem())) return false;

    return environment.name != 'production'
  }

  async initializeMenuItemList(menuIndex?) {
    let isDisabled = false;
    let blockMessage;
    if (this.authenticationService.isSystemUser()) {
      this.isSystemUser = true;
      isDisabled = false;
    } else {
      this.isSystemUser = false;
      isDisabled = await this.isUserBlockedCheck();
      blockMessage = await this.authenticationService.isUserBlockedCount();
    }
    if (blockMessage && !this.isBlockingMessageDisplayed) {
      this.loadingService.showErrorMessage(blockMessage, "Block", "Property Blocked");
      this.isBlockingMessageDisplayed = true;
      return false;
    }
																   
    this.menuItemsList = [
      { label: 'Dashboard', title: 'Dashboard', icon: 'fa fa-tachometer', visible: !this.isCurrentDomainIsSystem(), disabled: false, routerLink: ['/dashboard'], styleClass: menuIndex == 0 ? "menu-selected" : "", command: (event) => { this.showFilterContent(event, '/dashboard', 'Dashboard'); } },
      { label: 'Bookings', title: 'Bookings', icon: 'fa fa-book', disabled: !(this.isSystemUser || !isDisabled), visible: this.userService.checkAccess("Bookings") || this.isCurrentDomainIsSystem(), routerLink: ['/bookings'], styleClass: menuIndex == 1 ? "menu-selected" : "", command: (event) => { this.showFilterContent(event, '/bookings', 'Bookings'); } },
      { label: 'Rate Calendar', title: 'Rate Calendar', icon: 'fa fa-calendar', disabled: !(this.isSystemUser || !isDisabled), visible: (this.userService.checkAccess("Rate Calendar") && !this.isCurrentDomainIsSystem()), routerLink: ['/ratecalendar'], styleClass: menuIndex == 2 ? "menu-selected" : "", command: (event) => { this.showFilterContent(event, '/ratecalendar', 'Rate Calendar'); } },
      { label: 'Promotions', title: 'Promotions', icon: 'fa fa-percent', disabled: !(this.isSystemUser || !isDisabled), visible: (this.userService.checkAccess("Promotions") && !this.isCurrentDomainIsSystem()), routerLink: ['/promotions'], styleClass: menuIndex == 3 ? "menu-selected" : "", command: (event) => { this.showFilterContent(event, '/promotions', 'Promotions'); } },
      { label: 'Analytics', title: 'Analytics', icon: 'fa fa-line-chart', disabled: !(this.isSystemUser || !isDisabled) || this.isCurrentDomainIsSystem(), visible: this.userService.checkAccess("Analytics") || this.isCurrentDomainIsSystem(), routerLink: ['/analytics'], styleClass: menuIndex == 4 ? "menu-selected" : "", command: (event) => { this.showFilterContent(event, '/analytics', 'Analytics'); } },
      { label: 'Settings', title: 'Settings', icon: 'fa fa-cogs', disabled: !(this.isSystemUser || !isDisabled), visible: this.userService.checkAccess("Control Panel") || this.isCurrentDomainIsSystem(), routerLink: ['/settings'], styleClass: menuIndex == 6 ? "menu-selected" : "", command: (event) => { this.showFilterContent(event, '/settings', 'Settings'); } },
      { label: 'Revenue Mgmt.', title: 'Revenue Management', icon: 'fa fa-dollar', disabled: !(this.isSystemUser), visible: this.isLabVisible(), routerLink: ['/rms'], styleClass: menuIndex == 5 ? "menu-selected" : "", command: (event) => { this.showFilterContent(event, '/rms', 'RMS'); } },
      { label: 'Dev Lab', title: 'Dev Lab', icon: 'fa fa-code', disabled: !(this.isSystemUser), visible: this.isLabVisible(), routerLink: ['/devLab'], styleClass: menuIndex == 7 ? "menu-selected" : "", command: (event) => { this.showFilterContent(event, '/devLab', 'Dev Lab'); } },
    ];
    if (menuIndex == undefined) {
      let path = this.router.url;
      let menuItem = this.menuItemsList.find((item) => { return path.startsWith(item.routerLink[0]) });
      menuItem.styleClass = "menu-selected";
    }
    this.menuItems = [];
    this.miniMenuItems = [];
    this.menuItemsList.forEach((item: MenuItem) => {
      this.menuItems.push({ label: item.label, title: item.title, icon: item.icon, command: item.command, styleClass: item.styleClass, disabled: item.disabled, visible: item.visible });
      let miniItem = { title: item.title, icon: item.icon, command: item.command, styleClass: item.styleClass, disabled: item.disabled, visible: item.visible };
      this.miniMenuItems.push(miniItem);
    });
  }

  // check if isCurrentDomainIsSystem(for hiding specific fields)
  isCurrentDomainIsSystem() {
    this.selectedDomain = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    if (this.selectedDomain == "System") {
      return true;
    } else {
      return false;
    }
  }


  ngOnInit() {
    console.log("Side Nav onInit called");
  }
  handleSelected(event) {
    //Remove Class:
    jQuery("li.menu-selected").removeClass('menu-selected');
    this.selectInitialMenuItemBasedOnUrl(false);
  }

  scrollHeight;
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    this.scrollHeight = Utilities.getScrollHeight(false);
  }
  selectInitialMenuItemBasedOnUrl(callShowFilter) {
    let path = this.router.url;
    let menuItem = this.menuItemsList.find((item) => { return path.startsWith(item.routerLink[0]) });
    if (menuItem) {
      let menuIndex = this.menuItemsList.indexOf(menuItem);
      this.bigMenu.model[menuIndex].styleClass = "menu-selected";
      this.smallMenu.model[menuIndex].styleClass = "menu-selected";
      this.bigMenu.containerViewChild.nativeElement.childNodes[0].children[menuIndex].classList.add("menu-selected")
      this.smallMenu.containerViewChild.nativeElement.childNodes[0].children[menuIndex].classList.add("menu-selected")
      if (callShowFilter)
        this.showFilterContent(null, path, menuItem.label);
    }
  }

  ngAfterViewInit(): void {
    this.setHeight();
    this.handleSelected(event);
    this.selectInitialMenuItemBasedOnUrl(true);
  }

  menuIndexForSys() {
    if (this.menuIndex == 1 || this.menuIndex == 6) {
      return true;
    }
  }

  menuIndex;
  async refreshMenuItems() {
    this.menuIndex = 0;
    let routeToDashboard = false;
    let routeToSettings = false;
    let path = this.router.url;
    let menuItem = this.menuItemsList.find((item) => { return path.startsWith(item.routerLink[0]) });
    if (menuItem) this.menuIndex = this.menuItemsList.indexOf(menuItem);

    if (!this.userService.checkAccess(path.slice(1, path.length).split('/')[0]) && !this.isCurrentDomainIsSystem()) {
      this.menuIndex = 0;
      routeToDashboard = true;
    }
    else if (!this.menuIndexForSys() && this.isCurrentDomainIsSystem()) {
      this.menuIndex = 6;
      routeToSettings = true;
      routeToDashboard = false;
    }
    await this.initializeMenuItemList(this.menuIndex);

												
					   
								
								   
		
    if (routeToDashboard)
      this.router.navigate(["/dashboard"]);
    if (routeToSettings)
      this.router.navigate(["/settings"]);
  }

  async isUserBlockedCheck() {
    this.disableAllMenuItems = await this.authenticationService.isUserBlocked();
    console.log(this.disableAllMenuItems);
    return this.disableAllMenuItems;
  }

  hideShowSideNav() {
    if (this.hideSideNav) {
      document.getElementById("sidegutter").style.display = "block";
      document.getElementById("minigutter").style.display = "none";
      document.getElementById("content-body").classList.remove("content-body-nav-close");
      document.getElementById("content-body").classList.add("content-body-nav-open");
    } else {
      document.getElementById("sidegutter").style.display = "none";
      document.getElementById("minigutter").style.display = "block";
      document.getElementById("content-body").classList.remove("content-body-nav-open");
      document.getElementById("content-body").classList.add("content-body-nav-close");
    }
    this.hideSideNav = !this.hideSideNav;
  }

  async showFilterContent(event, routerLink, sectionName, forceReset?: boolean) {
    let navigated = true;
    if (this.router.url != routerLink) {
      //To check if navigation canDeactivate guard has allowed navigation.
      navigated = await this.router.navigate([routerLink]).then(response => { return response; }).catch(error => { return error });
    }
    if (navigated) {
      if (event && event != null) this.handleSelected(event);
      if (this.currentSelectedSection != sectionName || forceReset) {
        this.currentSelectedSection = sectionName;
      }
    }
  }
}