import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Dialog } from 'primeng/dialog';
import { Task, TaskStatus } from 'mantras-api';

@Component({
  selector: 'tasksdetails',
  templateUrl: './tasksdetails.component.html',
  styleUrls: ['./tasksdetails.component.css']
})
export class TasksDetailsComponent implements ICellRendererAngularComp, OnInit {

  //Task detail object to be set on row click.
  task = new Task();
  @Output('onLinkClick') onLinkClick = new EventEmitter();
  gridParams;
  showStakTraceBtn = false;
  errorMsg;
  hideStakTraceBtn = false;
  stackTrace;
  taskStatus;

  // called on init
  agInit(params: any): void {
    this.gridParams = params;
    if (params && params.data)
      this.task = params.data
    if (this.task.Status == 4) {
      this.showStakTraceBtn = true;
    }
    else {
      this.showStakTraceBtn = false;
    }
    this.taskStatus = TaskStatus[this.task.Status];
    if (this.task.Exception != undefined && this.task.Exception != null
      && this.task.Exception.length > 0) {
      this.errorMsg = this.task.Exception[0]["Source"] + "-" + this.task.Exception[0]["Message"];
      this.stackTrace = this.task.Exception[0]["StackTrace"]
    }
    if (this.task.Status == 2) {
      this.errorMsg = "This task ran to its completion without any errors";
    }
  }
  constructor() {
  }

  ngOnInit() {
  }

  refresh(params: any): boolean {
    return false;
  }

  openDetails(details) {
    if (details == "stakTraceTr") {
      this.hideStakTraceBtn = true;
      this.showStakTraceBtn = false;
      this.stackTrace = this.task.Exception[0]["StackTrace"]
    }
    else{
      if (details == "stakTraceFls") {
        this.hideStakTraceBtn = false;
        this.showStakTraceBtn = true;
        return;
      }
      this.gridParams.context.thisComponent.onLinkClickEvent(details, this.task);
    }
    
  }

}

