import { Component, OnInit,EventEmitter,Output } from '@angular/core';
import { StorageService } from '../../../ui-services/storage.service';
import { Utilities } from '../../../ui-services/utilities';
import { AuthenticationService } from '../../../ui-services/authentication.service';
import { EventManagementService } from '../../../ui-services/eventmanagement.service';
import { PromotionService } from '../../../ui-services/promotion.service';

@Component({
  selector: 'mm-promotionfilter',
  templateUrl: './promotionfilter.component.html',
  styleUrls: ['./promotionfilter.component.css']
})
export class PromotionfilterComponent implements OnInit {

  selectedChannelTypes = [];
  selectedRatePlans = [];
  selectedRooms = [];
  selectedrrpFields = [];
  selectedPromotionTypes = [];
  selectedPromotionStatus = [] 
  channelTypes: any[];
  rooms;
  rateplans;
  promotionTypes: any[];
  promotionStatus: any[];
  hotelContext;
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter();
  //domainContext;
  constructor(private storageService: StorageService, private eventManager:EventManagementService,
     private authenticationService:AuthenticationService, private promotionService: PromotionService) { 
       }
      
      ngOnInit() {
      }
      
      handleOnChange(event,field?:string){
        let data = {channelTypes:this.selectedChannelTypes,promotionTypes:this.selectedPromotionTypes,
          promotionStatus:this.selectedPromotionStatus,rooms:this.selectedRooms,ratePlans:this.selectedRatePlans}
          this.onFilterChange.emit(data);
        }
        
    setupPromotionFilter(setupData){
      this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    if(setupData){
      this.selectedChannelTypes = setupData["channelTypes"]; 
      this.channelTypes = [];
      if(this.selectedChannelTypes && this.selectedChannelTypes.length > 0){
        //Setup Channel Types
        let isMasterPresent = this.selectedChannelTypes.indexOf("MAX") >= 0;
        this.selectedChannelTypes.forEach(channel => {
          if(channel != "MAX"){
            this.channelTypes.push({value:channel,label:Utilities.findId(Utilities.channelTypes,channel,channel)});
          }
        });
        if(!isMasterPresent) this.channelTypes.sort(Utilities.sort_label_asc);
        else {
          this.channelTypes.sort(Utilities.sort_label_desc);
          this.channelTypes.push({value:"MAX",label:Utilities.findId(Utilities.channelTypes,"MAX")});
          this.channelTypes.reverse();
        }
      }

      this.selectedRooms = setupData["rooms"];
      this.rooms = [];
      if(this.selectedRooms && this.selectedRooms.length){
        //Setup Rooms
        this.selectedRooms.forEach(roomId => {
          this.rooms.push({value:roomId,label:Utilities.findId(this.hotelContext.RoomTypes,roomId,roomId)});
        });
        this.rooms.sort(Utilities.sort_label_asc);
      }

      this.selectedRatePlans = setupData["ratePlans"];
      this.rateplans = []; 
      if(this.selectedRatePlans && this.selectedRatePlans.length > 0){
        //Setup Rate Plans
        this.selectedRatePlans.forEach(ratePlanId => {
          this.rateplans.push({value:ratePlanId,label:Utilities.findId(this.hotelContext.RatePlans,ratePlanId,ratePlanId)});
        });
        this.rateplans.sort(Utilities.sort_label_asc);
      }

      this.selectedPromotionTypes = setupData["promotionTypes"]; 
      this.promotionTypes =[];
      if(this.selectedPromotionTypes && this.selectedPromotionTypes.length > 0){
        //Setup promotion Types
        this.selectedPromotionTypes.forEach(promoTypeId => {
          this.promotionTypes.push({value:promoTypeId,label:PromotionService.promotionTypes[promoTypeId]});
        });
        this.promotionTypes.sort(Utilities.sort_label_asc);
      }

      this.selectedPromotionStatus = setupData["promotionStatus"];
      this.promotionStatus = [];
      if(this.selectedPromotionStatus && this.selectedPromotionStatus.length > 0){
        //Setup promotion Status
        this.selectedPromotionStatus.forEach(promoStatusId => {
          this.promotionStatus.push({value:promoStatusId,label:PromotionService.promotionStatus[promoStatusId]});
        });
        this.promotionStatus.sort(Utilities.sort_label_asc);
      }
    }
  }
}
