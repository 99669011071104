import { Injectable } from '@angular/core';
import { AppService, DomainProfileCriteria, HotelSearchCriteria, Address, HotelMapping } from 'mantras-api';
import { StorageService } from '../ui-services/storage.service';
import { Utilities } from '../ui-services/utilities';
import { LoadingService } from '../ui-services/loading.service';
import { TaskbarService } from '../ui-services/taskbar.service';
import { UserService } from '../ui-services/user.service';
import { AppConfigService } from '../app-config.service';



@Injectable()
export class CommonService {
    hotels: any;
    hotelId: any;
    selectedDomain: any;
    currentUser: any;
    timestamp = null;
    //mantras: AppService;
    constructor(private mantras: AppService, private storageService: StorageService, private utilities: Utilities
        , private loadingService: LoadingService,private appConfService:AppConfigService, private taskbarService: TaskbarService, private userService: UserService) {
        //this.mantras = appService;
        Utilities.initApp(mantras,appConfService);
    }

    findUsage(usageCriteria,domainInfos) {
        return this.loadingService.interceptor(this.mantras.FindUsage(usageCriteria).then(async response => {
            if(response.length > 0){
            let domainIds = [];
            response.forEach(element => {
                if (!domainIds.includes(element.DomainId))
                    domainIds.push(element.DomainId);
            });
            let domainCriteria = new DomainProfileCriteria();
            domainCriteria.Ids = domainIds;
            await this.mantras.FindDomains(domainCriteria).then(response => {
                response.forEach(resp => {
                    domainInfos.set(resp.Id, resp.Name)
                });
            });
            response.forEach(element => {
                if (element.DomainId != "System")
                    element.DomainId = domainInfos.get(element.DomainId);
            });
        }
            return response;
        }).catch(error => {
            this.loadingService.showErrorDialog(error);
            throw error;
        }), true);
    }

    getUsageDetails(usageId) {
        return this.loadingService.interceptor(this.mantras.GetUsageDetails(usageId)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }


    findException(exceptionCriteria,domainInfos) {
        return this.loadingService.interceptor(this.mantras.FindExceptions(exceptionCriteria).then(async response => {
            if(response.length > 0){
            let domainIds = [];
            response.forEach(element => {
                if (!domainIds.includes(element.DomainId))
                    domainIds.push(element.DomainId);
            });
            let domainCriteria = new DomainProfileCriteria();
            domainCriteria.Ids = domainIds;
            await this.mantras.FindDomains(domainCriteria).then(response => {
                response.forEach(resp => {
                    domainInfos.set(resp.Id, resp.Name)
                });
            });
            response.forEach(element => {
                if (element.DomainId != "System")
                    element.DomainId = domainInfos.get(element.DomainId);
            });
        }
            return response;

        }).catch(error => {
            this.loadingService.showErrorDialog(error);
            throw error;
        }), true);
    }

    FindTasks(tasksCriteria,domainInfos,hotelInfos) {
        return this.loadingService.interceptor(this.mantras.findtasks(tasksCriteria).then(async response => {
            let domainIds = [];
            let hotelIds = [];
            if(response.length > 0){
            response.forEach(element => {
                if (!domainIds.includes(element.DomainId))
                    domainIds.push(element.DomainId);
                    if (!hotelIds.includes(element.HotelId))
                            hotelIds.push(element.HotelId);
            });
            let domainCriteria = new DomainProfileCriteria();
            domainCriteria.Ids = domainIds;
            await this.mantras.FindDomains(domainCriteria).then(response => {
                response.forEach(resp => {
                    domainInfos.set(resp.Id, resp.Name)
                });
            });

            let hotelCriteria = new HotelSearchCriteria();
                    hotelCriteria.HotelIds = hotelIds;
                    if( hotelCriteria.HotelIds.length > 0){
                    await this.mantras.FindHotelInfo(hotelCriteria).then(response => {
                        response.forEach(resp => {
                            hotelInfos.set(resp.Id, resp.Name)
                        });
                    });
                }
            response.forEach(element => {
                if (element.DomainId != "System")
                    element.DomainId = domainInfos.get(element.DomainId);
                if(element.HotelId == null || element.HotelId == undefined)
                    element.HotelId = ""; 
                else 
                    element.HotelId = hotelInfos.get(element.HotelId);
            });
        }
            return response;
        }).catch(error => {
            this.loadingService.showErrorDialog(error);
            throw error;
        }), true);
    }

    getTaskDetails(taskId) {
        return this.loadingService.interceptor(this.mantras.GetTaskDetails(taskId)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    backup(criteria){
        return this.mantras.Backup(criteria)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            });
    }
    FindChannelHotels(location: Address, channelCode: string ,isLoading =false) {  
        return this.loadingService.interceptor(this.mantras.findChannelHotels(location, channelCode)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    GetHotelMapping(channelCode: string , hotelIds : string[]) {
        return this.loadingService.interceptor(this.mantras.getHotelMapping(channelCode,hotelIds)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }
    SaveMapping(hotelMappings: HotelMapping[]){
        let response = this.mantras.saveMapping(hotelMappings)
        .then(response => { return response; });
        return response;
    }

    GetChannelMappings(hotelIds : string[]) {
        return this.loadingService.interceptor(this.mantras.getChannelMappings(hotelIds)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

    DeleteMapping(hotelId : string,channelCode: string, channelHotelId: string) {
        return this.loadingService.interceptor(this.mantras.deleteMapping(hotelId,channelCode,channelHotelId)
            .then(async result => {
                return result;
            }).catch(error => {
                this.loadingService.showErrorDialog(error);
                throw error;
            }), true);
    }

}

export class competitorManagementDetails{
   channelName : string;
   channelCode : string;
   hotelList : any[];
   mappedHotelList : any[] = [];
   selectedHotel:string;
}

