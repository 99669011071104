import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { TaxPolicy, TaxApplicationType, Range } from 'mantras-api';
import * as jQuery from 'jquery';
import { HotelManagementService } from '../../ui-services/hotelmanagement.service';
import { StorageService } from '../../ui-services/storage.service';
import { UserService } from '../../ui-services/user.service';
import { Utilities } from '../../ui-services/utilities';

@Component({
  selector: 'taxpolicydetails',
  templateUrl: './taxpolicydetails.component.html',
  styleUrls: ['./taxpolicydetails.component.css']
})
export class TaxpolicydetailsComponent implements OnInit {

  @Input() taxPolicy: TaxPolicy;
  @Output('onClose') onClose = new EventEmitter<boolean>();
  @Output() dataEmitter : EventEmitter<any> = new EventEmitter();
  hotelContext;
  domainId;
  validityStart;
  validEnd;
  isReadOnly=false;
  isTaxOverriding = false;
  menuItems = [];
  roomTypes = [{ label: "None", value: null }];
  rateplans = [{ label: "None", value: null }];
  occupancies = [{ label: "None", value: null }, { label: 1, value: 1 }, { label: 2, value: 2 }, { label: 3, value: 3 }, { label: 4, value: 4 }, { label: 5, value: 5 },
  { label: 6, value: 6 }, { label: 7, value: 7 }, { label: 8, value: 8 }, { label: 9, value: 9 }, { label: 10, value: 10 }]
  valueType = [{ label: "Amount", value: 0 }, { label: "Percent", value: 1 }];
  constructor(private ihotelManagementService: HotelManagementService, private storageservice: StorageService,private userService:UserService) {
    this.isReadOnly = userService.isReadOnly();
    this.menuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o',disabled:this.isReadOnly, command: (event) => this.onSave() },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }];
    this.hotelContext = this.storageservice.get(StorageService.currentUser).HotelContext;
    this.domainId = this.storageservice.get(StorageService.currentUser).DomainContext.CurrentDomainId;
  }

  async loadData() {
    if (!this.taxPolicy.HotelId) this.taxPolicy.HotelId = this.hotelContext.HotelId;
    var roomtypeObj = this.ihotelManagementService.findRoomTypes(this.taxPolicy.HotelId, false);
    roomtypeObj.then(res => {
      res.forEach(room => {
        this.roomTypes.push({ label: room.Name, value: room.Id });
      });
    })
    var rateplanObj = this.ihotelManagementService.findRatePlans(this.taxPolicy.HotelId, false);
    rateplanObj.then(res => {
      res.forEach(rp => {
        this.rateplans.push({ label: rp.Name, value: rp.Id });
      });
    });
    if (this.taxPolicy.ValidityPeriod) {
      this.validityStart = new Date(this.taxPolicy.ValidityPeriod.Start);
      this.validEnd = new Date(this.taxPolicy.ValidityPeriod.End);
    }
    if (!this.taxPolicy.AmountRange) {
      this.taxPolicy.AmountRange = new Range<number>();
    }
    if(!this.taxPolicy.Id) this.taxPolicy.IsActive = true;
    this.isTaxOverriding = this.taxPolicy.ApplicationType == TaxApplicationType.Ovverride;
    this.getMenuItemData();
  }
  onSave() {
    if (!this.taxPolicy.ValidityPeriod) this.taxPolicy.ValidityPeriod = new Range<Date>();
    this.taxPolicy.ValidityPeriod.Start = this.validityStart;
    this.taxPolicy.ValidityPeriod.End = this.validEnd;

    if (this.isTaxOverriding == true) this.taxPolicy.ApplicationType = TaxApplicationType.Ovverride;
    else this.taxPolicy.ApplicationType = TaxApplicationType.Additional;

    if (!this.taxPolicy.CreatedBy) this.taxPolicy.CreatedBy = this.storageservice.get(StorageService.loginUserId);
    if (!this.taxPolicy.CreatedOn) this.taxPolicy.CreatedOn = new Date();

    this.taxPolicy.LastModifiedBy = this.storageservice.get(StorageService.loginUserId);
    this.taxPolicy.LastModifiedOn = new Date();

    this.taxPolicy.DomainId = this.domainId;
    let taxPolicies:TaxPolicy[] = [];
    taxPolicies.push(this.taxPolicy);
    this.ihotelManagementService.saveTaxpolicies(this.domainId, this.taxPolicy.HotelId,taxPolicies);
    this.onClose.emit(true);
  }
  onCancel(event?) {
    this.onClose.emit(event);
  }

  ngOnInit() {
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.taxpolicydetailsSection').css('height', Utilities.getScrollHeight(true,true,false,false));
  }
  getMenuItemData(){
    this.menuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o',disabled:this.isReadOnly, command: (event) => this.onSave() },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }];
    let menuData = [];
    menuData.push({'source':'roomTypesDetail'});
    menuData.push(this.menuItems);
    this.dataEmitter.emit(menuData);
  }
}
