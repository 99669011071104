import { Component, OnInit, HostListener,ViewChild,Output,EventEmitter } from '@angular/core';
import { AuthenticationService } from '../ui-services/authentication.service';
import { StorageService } from '../ui-services/storage.service';
import { HotelManagementService } from '../ui-services/hotelmanagement.service';
import { HotelCriteria, HotelInfo, Phone } from 'mantras-api';
import { Utilities } from '../ui-services/utilities';
import { DomainHotel } from 'mantras-api';
import { ContactInfo } from 'mantras-api';
import * as jQuery from 'jquery';
import { UserService } from '../ui-services/user.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';

@Component({
  selector: 'propertydetails',
  templateUrl: './propertydetails.component.html',
  styleUrls: ['./propertydetails.component.css']
})
export class PropertydetailsComponent implements OnInit {

  property = new HotelInfo();
  domainHotel = new DomainHotel();
  isReadOnly =false;
  blockDateValue;
  unblockDaysOffset=0;
  unblockDetails="";
  isPropertyBlocked=false;
  showBlockPropertyButton=false;
  isSystemUser=false;
  blockPropertySelectedValue = "NextTenDays";
  isBlockPropertyDialogVisible = false;
  brandNames = [{label:"",value:null}];
  rateTypes = [{label:"Net Rate",value:0},{label:"Sell Rate",value:1}];
  taxTypes = [{label:"Exclusive",value:0},{label:"Inclusive",value:1}];
  currencies = [{label:"",value:null}];
  errorMsg = "";
  propertyDetailsMenuItems;
  hotelContext;
  cipBookingStatus = [{label:"Confirmed",value:"Confirmed"},{label:"Modified",value:"Modified"},{label:"Provisional",value:"Provisional"},{label:"Waiting",value:"Waiting"},{label:"Cancelled",value:"Cancelled"}];
  channelTypes=[];
  disabledAutoSyncChannels=[];
  lat=0;
  long=0;
  addressText
  phone;
  fax;
  //The domain is needed to get OutOfSync Threashold.
  outOfSyncThreshold = 0;
  domainProfile;
  PMSMailSetting = "";
  subscription: Subscription;
  @Output() onChangeMenuItems: EventEmitter<any>  = new EventEmitter();
  constructor(private storageService:StorageService, private authService:AuthenticationService, private hotelMgmtService: HotelManagementService,
    private router: Router, private userService:UserService) { 
    this.hotelContext = storageService.get(StorageService.userHotelContext);
    Utilities.brandName.forEach(brandName => {
      this.brandNames.push({label:brandName,value:brandName});
    });
    this.isReadOnly=userService.isReadOnly();
    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
    });
    this.loadData();
  }

  async loadData(force=false){
    let hotelCriteria = new HotelCriteria();
    hotelCriteria.Ids = [this.hotelContext.HotelId];
    let dh = await this.authService.getDomainHotel(this.hotelContext.HotelId,force)
    //.then(dh => {
      this.domainHotel = dh;
      //TODO: This is currently not getting set in DomainHotel.
      this.outOfSyncThreshold = this.domainHotel.OutOfSyncThreshold;
      if(this.domainHotel.PMSMailSetting && this.domainHotel.PMSMailSetting.length > 0)
        this.PMSMailSetting = this.domainHotel.PMSMailSetting.toString();
    //});
    let channelCodes = this.hotelContext.ChannelCodes;
    this.channelTypes = [];
    channelCodes.forEach(code => {
      this.channelTypes.push({ value: code, label: Utilities.findId(Utilities.channelTypes, code, code) });
    });
    this.currencies = []
    Utilities.currencies.forEach(currency => {
      this.currencies.push({value:currency.Code,label:currency.Code+"("+currency.Symbol+") - "+currency.Currency+" ("+currency.Country+")"});
    });
    this.channelTypes.sort(Utilities.sort_label_asc);
    let resp = await this.hotelMgmtService.findHotels(hotelCriteria,force)
      if(resp.length >= 1)
        this.property = resp[0];
        if(this.property.Address){
          if(this.property.Address.GeoCode){
            this.lat = this.property.Address.GeoCode.Latitude;
            this.long = this.property.Address.GeoCode.Longitude;
          }
          this.addressText = this.property.Address.Street + "\n"+
          this.property.Address.City + "\t" + this.property.Address.State+"\n"+
          this.property.Address.Country +"\t"+ this.property.Address.ZipCode; 
        }
        if(this.property.Phone){
          this.phone = this.property.Phone.Number;
        }
        if(this.property.Fax){
          this.fax = this.property.Fax.Number;
        }
    let domainId = this.storageService.get(StorageService.userDomainContext).CurrentDomainId;
    let response = await this.authService.getDomainProfile(domainId,force)
      this.domainProfile = response;
      if(this.outOfSyncThreshold == 0)
        this.outOfSyncThreshold = this.domainProfile.Preferences[this.hotelContext.HotelId+"OutOfSyncThreshold"];
      console.log("Out Of Sync Threshold :",this.outOfSyncThreshold, this.domainProfile.Preferences);
    this.setHeight();
    
  }
  loadMenu(){
    if(this.domainHotel.BlockingDate!=null)
    this.showBlockPropertyButton = false;
    else
    this.showBlockPropertyButton = true;
    if(this.authService.isSystemUser()){
      this.isSystemUser=true;
      this.getUnblockDaysOffset();
    }   
    else{
      this.isSystemUser=false;
    }
    this.propertyDetailsMenuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o',disabled: this.isReadOnly, command: (event) => this.onSave(event)},
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() },
      { title: 'Block Property', label: 'Block Property',icon: 'action-bar-menu-icon fa fa-ban',visible:this.isSystemUser && this.showBlockPropertyButton,command: (event) => this.blockProperty() },
      { title: 'Unblock Property', label: 'Unblock Property',icon: 'action-bar-menu-icon fa fa-unlock',visible:this.isSystemUser && !this.showBlockPropertyButton,command: (event) => this.unblockProperty() },
      { label: this.unblockDetails, visible:this.isSystemUser && !this.showBlockPropertyButton}
    ];
  }

  ngOnInit() {
    this.loadMenu();
    this.getMenuItemData();
  }

  async onSave(event){
    this.property.Address.GeoCode.Latitude = this.lat;
    this.property.Address.GeoCode.Longitude = this.long;
    if(this.phone) this.property.Phone.Number = this.phone;
    else this.property.Phone = new Phone();
    if(this.fax) this.property.Fax.Number = this.fax;
    else this.property.Fax = new Phone();
    if(!this.domainHotel.AdminContact) this.domainHotel.AdminContact = new ContactInfo();
    this.domainHotel.AdminContact.Email = this.property.Email;
    await this.hotelMgmtService.saveHotel(this.property);
    this.domainHotel.OutOfSyncThreshold = this.outOfSyncThreshold;
    this.domainHotel.PMSMailSetting = null;
    if(this.PMSMailSetting != null || this.PMSMailSetting != ""){
      this.domainHotel.PMSMailSetting = this.PMSMailSetting.split(",");
    }
    this.domainProfile.Preferences[this.hotelContext.HotelId+"OutOfSyncThreshold"] = this.outOfSyncThreshold;
    await this.hotelMgmtService.saveDomainHotel(this.domainHotel);
    await this.authService.saveDomain(this.domainProfile);
    this.loadData();
  }
  getTodaysDate(): Date{
    return new Date();
  }
  blockProperty(){
    this.isBlockPropertyDialogVisible = true;
  }
  async onBlockPropertySave(event){
    let blockingDate = this.authService.getTimestamp() ? this.authService.getTimestamp() : new Date();
    if(this.blockPropertySelectedValue == "NextTenDays"){
      blockingDate.setDate(blockingDate.getDate() + 10);
    }
    if(this.blockPropertySelectedValue == "NextTwentyDays"){
      blockingDate.setDate(blockingDate.getDate() + 20);
    }
    if(this.blockPropertySelectedValue == "Custom"){
      blockingDate = Utilities.convertToUTC(this.blockDateValue);
    }
    this.domainHotel.BlockingDate=blockingDate.toISOString();
    await this.hotelMgmtService.saveDomainHotel(this.domainHotel);
    this.isBlockPropertyDialogVisible=false;
    this.loadData();
  }
  @ViewChild('showConfirmationDialog') showConfirmationDialog;
  showUnblockConfirmation = false;
  unblockProperty(){
    this.showUnblockConfirmation = true;
  }
  async unblock(){
    this.domainHotel.BlockingDate=null;
    await this.hotelMgmtService.saveDomainHotel(this.domainHotel);
    this.showUnblockConfirmation = false;
    this.loadData();
  }
  closeDialog() {
    this.showUnblockConfirmation = false;
    this.setHeight();
  }
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.propertyDetailSection').css('height', Utilities.getScrollHeight(true,true,false,false));
  }
  getUnblockDaysOffset(){
    let serverTimestamp = this.authService.getTimestamp()
    if(this.domainHotel.BlockingDate != null){
     this.unblockDaysOffset = Math.floor((Date.parse((serverTimestamp).toISOString())-Date.parse(this.domainHotel.BlockingDate))/(1000*60*60*24));
     if(this.unblockDaysOffset>0){
      this.unblockDetails = "The property is blocked since "+this.unblockDaysOffset+" days.";
     }else if(this.unblockDaysOffset<0){
      this.unblockDaysOffset = Math.abs(this.unblockDaysOffset);
      this.unblockDetails = "The property will be blocked after "+this.unblockDaysOffset+" days.";
     }else{
      this.unblockDetails = "The property was blocked today";
     }
    }
  }
  street;
  country;
  state;
  zipcode;
  city;
  showAddressDialog = false;
  editAddress(){
    this.street = this.property.Address.Street;
    this.country = this.property.Address.Country;
    this.city = this.property.Address.City;
    this.state = this.property.Address.State;
    this.zipcode = this.property.Address.ZipCode;
    this.showAddressDialog = true;
  }

  saveAddress(){
    this.property.Address.Street = this.street;
    this.property.Address.Country = this.country;
    this.property.Address.City = this.city;
    this.property.Address.State = this.state;
    this.property.Address.ZipCode = this.zipcode; 
    this.addressText = this.property.Address.Street + "\n"+
    this.property.Address.City + "\t" + this.property.Address.State+"\n"+
    this.property.Address.Country +"\t"+ this.property.Address.ZipCode;
    this.showAddressDialog = false;
  }
  getMenuItemData(){
    let data = [];
    data.push({'source':'propertyDetails'});
    data.push(this.propertyDetailsMenuItems);
    this.onChangeMenuItems.emit(data);
  }
  //OnClose
  onClose() {
    this.router.navigate(["/settings"]);
  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
   }
}
