import { Component, OnInit, HostListener, ViewChild, ComponentFactoryResolver, ViewContainerRef, ApplicationRef, Injector } from '@angular/core';
import { GridOptions } from 'ag-grid';
import { StorageService } from '../ui-services/storage.service';
import { DatePipe } from '@angular/common';
import { Utilities } from '../ui-services/utilities';
import { Subscription } from 'rxjs';
import { EventManagementService } from '../ui-services/eventmanagement.service';
import { UserService } from '../ui-services/user.service';
import { RateruledetailsComponent } from './rateruledetails.component';
import { Router } from '@angular/router';
import { AlertsService } from '../ui-services/alerts.service';
import { RateRuleCriteria, RateRule } from 'mantras-api';

import * as jQuery from 'jquery';
import { LoadingService } from '../ui-services/loading.service';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';

@Component({
  selector: 'raterules',
  templateUrl: './raterules.component.html',
  styleUrls: ['./raterules.component.css']
})
export class RaterulesComponent implements OnInit {

  rateRulesMenuItems;
  selectedStatus = '0';
  gridOptions;
  icons;
  columnDef;
  showDetailPage = false;
  selectedRateRule = new RateRule();
  subscription: Subscription;
  channelMasterRoomMapping;
  channelMasterRateTypeMapping;
  taskCompleteNotificationSubscription: Subscription;
  isReadOnly = false;
  valueTypes = [{label:"Amount",value:0},{label:"Percent",value:1}];
  constructor(private resolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector,
    public storageService: StorageService, private router:Router, private alertsService: AlertsService, private eventManager: EventManagementService,
    private userService:UserService) {
    this.gridOptions = <GridOptions>{
      enableSorting: true,
      animateRows: true,
      groupDefaultExpanded: -1,
      rowHeight: 24,
      groupUseEntireRow: true,
      singleClickEdit: true,
      groupRowRendererParams: { suppressCount: true },
      suppressDragLeaveHidesColumns:  true,
      suppressContextMenu: true,
      localeText:{noRowsToShow:"No rate rules configured. Please click on New to create new rate rule"}
    };
    this.gridOptions.suppressLoadingOverlay = true;
    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    //Set the current class context (this) in gridOption context
    this.gridOptions.context = { thisComponent: this };
    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });
    
    this.isReadOnly = userService.isReadOnly();
  }

  ngOnInit() {
    //Initializing Menu Items 
    this.loadMenu();
    this.getRateRules();
  }

  loadMenu(){
    this.rateRulesMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', disabled: true, command: (event) => this.open(event) },
      { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: true, command: (event) => this.confirmDelete(event, true) },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
    ];
  }
  
  rateRulesResponse;
  async getRateRules() {
    //Default
    let rateRuleCriteria = new RateRuleCriteria();
    rateRuleCriteria.DomainId = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    rateRuleCriteria.HotelId = this.storageService.get(StorageService.userHotelContext).HotelId;
    let finalResponse = [];
    finalResponse = await this.alertsService.findRateRules(rateRuleCriteria).then(
      localresp => {
        return localresp;
    });
    this.rateRulesResponse = finalResponse;
    this.renderRateRules();
  }
  
  renderRateRules() {
    this.populateCloumnDef();
    this.gridOptions.api.setColumnDefs(this.columnDef);
    this.gridOptions.api.setRowData(this.rateRulesResponse);
    this.gridOptions.api.sizeColumnsToFit();
    let setSelected = false;
    this.gridOptions.api.forEachNode(node => {
      if (!node.group && !setSelected) { node.setSelected(true); this.onRowClick(node); setSelected = true; }
    });
    if(!setSelected) this.loadMenu();
  }

  populateCloumnDef() {
    this.columnDef = [];
    this.columnDef.push({ headerName: "Name", cellClass: 'gridcol', field: "Name", menuTabs: [] });
    this.columnDef.push({ headerName: "Start Date", cellClass: 'gridcol', field: "StayDates.Start", valueFormatter: formatDate, menuTabs: [] });
    this.columnDef.push({ headerName: "End Date", cellClass: 'gridcol', field: "StayDates.End", valueFormatter: formatDate, menuTabs: [] });
    this.columnDef.push({ headerName: "Room", cellClass: 'gridcol', field: "RoomId", valueFormatter: getRoomName, menuTabs: [] });
    this.columnDef.push({ headerName: "Rate Plan", cellClass: 'gridcol', field: "RatePlanId", valueFormatter: getRatePlanName, menuTabs: [] });
    this.columnDef.push({ headerName: "Delta", cellClass: 'gridcol', field: "IsDelta", menuTabs: [] });
    this.columnDef.push({ headerName: "Type", cellClass: 'gridcol', field: "DeltaType", valueFormatter: getDeltaType, menuTabs: [] });
    this.columnDef.push({ headerName: "Inv. Computation", cellClass: 'gridcol', field: "HotelLevelInventory", valueFormatter: getHotelLevelInventory, menuTabs: [] });
    this.columnDef.push({ headerName: "Enabled", width: 100, cellClass: 'gridcol', field: "IsEnabled", menuTabs: [],cellRenderer: params => {return `<input type='checkbox' readonly onclick='return false;' ${params.value ? 'checked' : ''} value=${params.value?1:0}/>`;}});
    this.columnDef.push({ headerName: "", cellClass: 'gridcol', menuTabs: [], width:20 });
  }

  onGridSizeChanged(event) {
    this.gridOptions.api.sizeColumnsToFit();
  }
  //
  onGridReady(event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.setHeight();
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height', Utilities.getScrollHeight(true,true));
  }

  rateRuleDetailsComp;
  onClickGridRow(params) {
    if (params.data)
      this.createNew(params.data);
  }

  onRowClick(params) {
    if (params.data) {
      this.rateRulesMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
        { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
        { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: this.isReadOnly, command: (event) => this.confirmDelete(event, true) },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
      ];
    }
  }

  //Refresh Promotion Grid
  onRefresh(event) {
    this.getRateRules();
  }
  //OnClose
  onClose(){
    this.router.navigate(["/settings"]);
  }

  //Create New Promotion
  @ViewChild('containerAlertDetail', { read: ViewContainerRef }) containerAlertDetail: ViewContainerRef;
  createNew(data?) {
    this.containerAlertDetail.clear();
    let factory = this.resolver.resolveComponentFactory(RateruledetailsComponent);
    this.rateRuleDetailsComp = <RateruledetailsComponent>this.containerAlertDetail.createComponent(factory).instance;
    if (data) this.rateRuleDetailsComp.rateRule = data;
    else this.rateRuleDetailsComp.rateRule = new RateRule();
    this.rateRuleDetailsComp.loadData();
    this.rateRuleDetailsComp.onClose.subscribe(this.onDetailsClose);
    this.showDetailPage = true;
    this.rateRuleDetailsComp.setHeight();
  }
  //Open RateRule
  open(event) {
    this.createNew(this.gridOptions.api.getSelectedRows()[0]);
  }
  //Delete RateRule
  @ViewChild('showConfirmationDialog') showConfirmationDialog;
  showDeleteConfirmation = false;
  deleteId;
  confirmDelete(params, fromGrid?) {
    if (!fromGrid) {
      if (params.node.data) {
        this.deleteId = params.node.data.Id;
        this.showDeleteConfirmation = true;
      }
    } else {
      let selectedRow = this.gridOptions.api.getSelectedRows();
      if(selectedRow && selectedRow.length > 0){
        this.deleteId = selectedRow[0].Id;
        this.showDeleteConfirmation = true;
      }
    }
  }

  closeDialog(event) {
    this.deleteId = null;
    this.showDeleteConfirmation = false;
    this.setHeight();
  }

  async delete(event) {
    this.showDeleteConfirmation = false;
    if (this.deleteId != null) {
      let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
      let rateRuleCriteria = new RateRuleCriteria();
      rateRuleCriteria.Id = this.deleteId;
      rateRuleCriteria.HotelId = hotelContext.HotelId;
      rateRuleCriteria.DomainId = hotelContext.DomainId;
      let finalResponse = await this.alertsService.deleteRateRules(rateRuleCriteria).then(
        localresp => { return localresp; });
    }
    this.onRefresh(event);
  }

  onDetailsClose = (event): void => {
    this.showDetailPage = false;
    if (event) this.onRefresh(event);
    else this.setHeight();
  }

  getChannelRoom(roomId, channelType) {
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    if (channelType == "MAX") {
      return Utilities.findObject(hotelContext.RoomTypes, roomId);
    } else {
      return Utilities.findMasterRoomType(roomId, channelType, hotelContext.RoomTypes);
    }
  }

  getChannelRatePlan(ratePlanId, channelType) {
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    if (channelType == "MAX") {
      return Utilities.findObject(hotelContext.RatePlans, ratePlanId);
    } else {
      return Utilities.findMasterRateType(ratePlanId, channelType, hotelContext.RatePlans);
    }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
    //this.filterSubscription.unsubscribe();
  }
}
function getRoomName(params) {
  if (params.value) {
    let hotelContext = params.context.thisComponent.storageService.get(StorageService.currentUser).HotelContext;
    let obj = Utilities.findObject(hotelContext.RoomTypes, params.value);
    return obj?obj.Name:"";
  } else return "All";
}
function getRatePlanName(params) {
  if (params.value) {
    let hotelContext = params.context.thisComponent.storageService.get(StorageService.currentUser).HotelContext;
    let obj = Utilities.findObject(hotelContext.RatePlans, params.value);
    return obj?obj.Name:"";
  } else return "All";
}
function getDeltaType(params){
  let obj = params.context.thisComponent.valueTypes.find(x => x.value === params.value)
  return obj?obj.label:"";
}
function formatDate(params) {
  if (params.value) {
    return new DatePipe('en-US').transform(Utilities.getUTCDate(new Date(params.value)), "dd-MMM-yyyy");
  }
}
function getHotelLevelInventory(params){
  return params.value? "Hotel Level":"Room Level";
}

