import { Component, OnInit, EventEmitter, Output, Input, HostListener, ViewChild, ViewContainerRef, ViewChildren, AfterViewInit } from '@angular/core';
import { v5AlertRuleBase, AlertType, RoomsInventoryThreshold, RatePlanRateThreshold } from 'mantras-api';
import { StorageService } from '../ui-services/storage.service';
import { Utilities } from '../ui-services/utilities';
import { v5ActionType, AlertsService, NotificationTemplate } from '../ui-services/alerts.service';
import { Range } from 'mantras-api';
import { Dropdown } from 'primeng/primeng';
import { AuthenticationService } from '../ui-services/authentication.service';
import { AnalyticsService } from '../ui-services/analytics.service';
import * as jQuery from 'jquery';
import { UserService } from '../ui-services/user.service';

@Component({
  selector: 'alert',
  templateUrl: './alertdetails.component.html',
  styleUrls: ['./alertdetails.component.css']
})
export class AlertdetailsComponent implements OnInit, AfterViewInit {

  @Input() alertRule : v5AlertRuleBase;
  @Output('onClose') onClose = new EventEmitter<boolean>();
  errorMsg = "";
  alertTypes = [{label:"Hotel Availability", value:0}/*,{label:"Channel Rate",value:1}*/,{label:"Hotel Booking",value:2}];
  thresholdTypes = [{label:"Inventory",value:0},{label:"Rate",value:1}];
  thresholdType = 0;
  roomTypes;
  ratePlans;
  minimum;
  maximum;
  bookingStatuses = [{value:0, label:"UnSpecified"},{value:1,label:"Provisional"},{value:2, label:"Confirmed"},{value:3, label:"Cancelled"},{value:4, label:"Modified"},{value:5, label:"WaitingList"},{value:6, label:"NoShow"}];
  actionTypes = [{label:"Email Notification",value:0},{label:"Charge Card",value:1},{label:"Authenticate Card",value:2}/*,{label:"Refund",value:3}*/,{label:"Pull Channel Rates",value:4}];
  bookingTypes = [{label:"All",value:null},{label:"Post Paid",value:true },{label:"Pre Paid",value:false}];
  actionType = 0;
  emailids;
  interval = Utilities.getUTCDate(new Date());
  startDate;
  endDate;
  isReadOnly; false;
  alertMenuItems;
  hotelContext;
  selectedRatePlanId;
  competitors;
  channelTypes;
  startStayDate = Utilities.getUTCDate(new Date());
  endStayDate = Utilities.getUTCDate(new Date());
  constructor(private storageService:StorageService, private alertService: AlertsService, 
    private authenticationService: AuthenticationService, private analyticsService: AnalyticsService,
    private userService:UserService) { 
    this.hotelContext = this.storageService.get(StorageService.userHotelContext);
    let channelCodes = this.hotelContext.ChannelCodes;
    this.channelTypes = [];
    channelCodes.forEach(code => {
      this.channelTypes.push({ value: code, label: Utilities.findId(Utilities.channelTypes, code, code) });
    });
    this.channelTypes.sort(Utilities.sort_label_asc);
    let all = [{value:"USP",label:"All"}];
    this.channelTypes = all.concat(this.channelTypes);
    this.isReadOnly = userService.isReadOnly();
  }

  async ngOnInit() {
    this.alertMenuItems = [
      { title: 'Save', label: 'Save', icon: 'action-bar-menu-icon fa fa-floppy-o',disabled: this.isReadOnly, command: (event) => this.onSave(event)},
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) }];
      this.ratePlans = [{label:"All",value:null}];
      this.hotelContext.RatePlans.forEach(element => {
        this.ratePlans.push({label:element.Name,value:element.Id});
      });
      this.roomTypes = [{label:"All",value:null}];
      this.hotelContext.RoomTypes.forEach(element => {
        this.roomTypes.push({label:element.Name,value:element.Id});
      });

      let domainHotel = this.authenticationService.getDomainHotel(this.hotelContext.HotelId);
      let compIds = domainHotel.CompetitorHotels;
      //TODO: This is to remove the target hotel id which is duplicated in the list.
      if(compIds && compIds.length >= 2){
        //Check first and last index hotel id and remove last if its same.
        if(compIds[0] == compIds[compIds.length - 1]){
          compIds.pop();
        }
        this.competitors = [];
        let hotelInfo = await this.analyticsService.findHotels(compIds);
        compIds.forEach(id => {
          this.competitors.push({label:Utilities.findId(hotelInfo,id,id),value:id});
        });
      }
      this.setHeight();
  }

  @HostListener('window:resize') setHeight(){
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true));
  }

  @ViewChildren('rooms') rooms;
  @ViewChildren('rates') rates;
  @ViewChildren('start') starts;
  @ViewChildren('end') ends;
  loadData(){
    if(this.alertRule.Id != null && this.alertRule.Id != undefined){
      if(this.alertRule.Frequency != null){
        let time = this.alertRule.Frequency.toString().split(":");
        this.interval.setHours(parseInt(time[0]),parseInt(time[1]),parseInt(time[2]));
      }
      if(this.alertRule.StartTime && this.alertRule.StartTime.toString() != "0001-01-01T00:00:00Z")
        this.startDate = new Date(this.alertRule.StartTime);
      if(this.alertRule.EndTime && this.alertRule.EndTime.toString() != "0001-01-01T00:00:00Z")
        this.endDate = new Date(this.alertRule.EndTime);
      //Alert Type = HotelAvailRate
      if(this.alertRule.Type == AlertType.HotelRateCalendar){
        let threshold = null;
        if(this.alertRule.InventoryThreshold && this.alertRule.InventoryThreshold.length > 0){
          threshold = this.alertRule.InventoryThreshold;
          this.thresholdType = 0;
        } else if(this.alertRule.RateThreshold && this.alertRule.RateThreshold.length > 0){
          threshold = this.alertRule.RateThreshold;
          this.thresholdType = 1;
        }
      } else if(this.alertRule.Type == AlertType.Booking){
        //AlertType Booking
        if(this.alertRule.ActionType != null){
          if(this.alertRule.ActionType[v5ActionType.ChargeCard[0]] != null){
            this.actionType = 1;
            if(this.alertRule.Params != null && this.alertRule.Params.length > 0){
              if(this.alertRule.Params[0] == "Charge") this.actionType = 1;
              else if(this.alertRule.Params[0] == "Authorize") this.actionType = 2;
              else if(this.alertRule.Params[0] == "Refund") this.actionType = 3;
            }
          }
          else if(this.alertRule.ActionType[v5ActionType.PullCompRate[0]] != null) 
            this.actionType = 4;
        }
        if(this.alertRule.StayDateThreshold){
          let startTime = this.alertRule.StayDateThreshold.Start.toString().split(":");
          let endTime = this.alertRule.StayDateThreshold.End.toString().split(":");
          this.startStayDate.setHours(parseInt(startTime[0]),parseInt(startTime[1]),parseInt(startTime[2]));
          this.endStayDate.setHours(parseInt(endTime[0]),parseInt(endTime[1]),parseInt(endTime[2]));
        }
      } else {
        //AlertType Channel Rates
        if(this.alertRule.ChannelRankThreshold != null){
          this.thresholdType = 0;
          this.minimum = this.alertRule.ChannelRankThreshold.Start;
          this.maximum = this.alertRule.ChannelRankThreshold.End;
        } else if(this.alertRule.ChannelRateThreshold != null){
          this.thresholdType = 1;
          this.minimum = this.alertRule.ChannelRateThreshold.Start;
          this.maximum = this.alertRule.ChannelRateThreshold.End;
        }
      }
      if(this.alertRule.MailTo && this.alertRule.MailTo.length > 0){
        this.emailids = this.alertRule.MailTo.toString();
      }
    } else {
      this.alertRule.Type = AlertType.HotelRateCalendar;
      this.alertRule.IsEnabled = true;
      this.alertRule.HotelId = this.hotelContext.HotelId;
      this.alertRule.DomainId = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
      this.alertRule.ChannelCode = "USP";
    }
  }

  onBookingStartDateChange(event){

  }

  onCancel(event?){
    this.onClose.emit(event);
  }

  onSave(event){
    if(!this.validateForm()) return; 
    if(this.alertRule.Type == AlertType.HotelRateCalendar){
      if(this.componentReference.length > 0 && this.rooms.length > 0 && this.rates.length > 0 && this.starts.length > 0 && this.ends.length > 0){
        let thresholdList = new Array();
        for(let i = 0; i < this.componentReference.length; i++){
          let roomId = this.rooms.toArray()[i].selectedOption.value;
          let ratePlanId = this.rates.toArray()[i].selectedOption.value;
          let min = this.starts.toArray()[i].nativeElement.value;
          let max = this.ends.toArray()[i].nativeElement.value;
          let threshold = (this.thresholdType == 0)?new RoomsInventoryThreshold():new RatePlanRateThreshold();
          threshold.RatePlanId = ratePlanId.value;
          threshold.RoomTypeId = roomId.value;
          let range = new Range<number>();
          range.Start = min;
          range.End = max;
          if((this.thresholdType == 0))
            threshold["InventoryThreshold"] = range;
          else
            threshold["RateThreshold"] = range;
          thresholdList.push(threshold); 
        }
        if(this.thresholdType == 0){
          this.alertRule.InventoryThreshold = thresholdList;
          this.alertRule.RateThreshold = null;
        } else {
          this.alertRule.InventoryThreshold = null;
          this.alertRule.RateThreshold = thresholdList;
        }
      }
      this.alertRule.NotificationTemplate = NotificationTemplate.EmailTemplate;
      let actType:any = {}
      actType[v5ActionType.SendEmail[0]]=v5ActionType.SendEmail[1];
      this.alertRule.ActionType = actType;
      this.alertRule.Params = [];
    } else if(this.alertRule.Type == AlertType.Booking){
      let actType:any = {};
      if(this.actionType == 0){
        actType[v5ActionType.SendEmail[0]]=v5ActionType.SendEmail[1];
        this.alertRule.NotificationTemplate = NotificationTemplate.BookingTemplate;
      } else if(this.actionType == 1) {
        //Action Type : Generate Charge Card
        actType[v5ActionType.ChargeCard[0]]=v5ActionType.ChargeCard[1];
        this.alertRule.Params = ["Charge"];
        this.alertRule.NotificationTemplate = NotificationTemplate.TransactionTemplate;
      } else if(this.actionType == 2) {
        //Action Type : Authorize Card
        actType[v5ActionType.ChargeCard[0]]=v5ActionType.ChargeCard[1];
        this.alertRule.Params = ["Authorize"];
        this.alertRule.NotificationTemplate = NotificationTemplate.BookingTemplate;
      } else if(this.actionType == 3) {
        //Action Type : Refund Card
        actType[v5ActionType.ChargeCard[0]]=v5ActionType.ChargeCard[1];
        this.alertRule.Params = ["Refund"];
        this.alertRule.NotificationTemplate = NotificationTemplate.BookingTemplate;
      } else if(this.actionType == 4) {
        //Action Type : Pull Comp Rates
        actType[v5ActionType.PullCompRate[0]]=v5ActionType.PullCompRate[1];
        this.alertRule.Params = [];
        this.alertRule.NotificationTemplate = NotificationTemplate.BookingTemplate;
      }
      this.alertRule.ActionType = actType;
      if(this.startStayDate != null && this.endStayDate != null){
        this.alertRule.StayDateThreshold = new Range<string>();
        this.alertRule.StayDateThreshold.Start = this.startStayDate.getHours()+":"+this.startStayDate.getMinutes()+":"+this.startStayDate.getSeconds();
        this.alertRule.StayDateThreshold.End = this.endStayDate.getHours()+":"+this.endStayDate.getMinutes()+":"+this.endStayDate.getSeconds();
      }
    } else {
      let range = new Range<number>();
      range.Start = this.minimum;
      range.End = this.maximum;
      if(this.thresholdType == 0)
      this.alertRule.ChannelRankThreshold = range;
      else this.alertRule.ChannelRateThreshold = range;
    }
    this.alertRule.Frequency = this.interval.getHours()+":"+this.interval.getMinutes()+":"+this.interval.getSeconds();
    if(this.startDate == undefined || this.startDate == null){
      this.alertRule.StartTime = new Date("0001-01-01T00:00:00Z");
    } else {
      this.alertRule.StartTime = this.startDate;
    }
    if(this.endDate == undefined || this.endDate == null){
      this.alertRule.EndTime = new Date("0001-01-01T00:00:00Z");
    } else {
      this.alertRule.EndTime = this.endDate;
    }
    if(this.emailids != null){
      this.alertRule.MailTo = this.emailids.split(",");  
    } else {
      this.alertRule.MailTo = null;
    }
    console.log("Object To Save:",this.alertRule);
    this.alertService.saveAlertRule(this.alertRule).then(response =>{
      this.onCancel(true);
    });
  }

  validateForm(){
    this.errorMsg = null;
    if(!this.alertRule.Name || this.alertRule.Name.trim() == "")  this.errorMsg = "Name cannot be blank.";
    if(this.alertRule.Type == AlertType.HotelRateCalendar){
      if(this.componentReference.length == 0) this.errorMsg = "Please configure alteast one threshold.";
      else {
        for(let i = 0; i < this.componentReference.length; i++){
          let min = this.starts.toArray()[i].nativeElement.value;
          let max = this.ends.toArray()[i].nativeElement.value;
          if(min == "" || max == "" || parseInt(min) > parseInt(max))
            this.errorMsg = "Min & Max value are mandatory and min must be less than max.";
        }
      }
    } else if(this.alertRule.Type == AlertType.Booking){
      if(this.startStayDate != null && this.endStayDate != null &&
        (this.endStayDate.getTime() - this.startStayDate.getTime()) < 0){
        this.errorMsg = "Threshold Stay start date must be greater than end date.";
      }
    }
    if(this.startDate && this.endDate && Utilities.dateDifference(this.startDate,this.endDate) < 0)
      this.errorMsg = "Frequency start date must be greater than end date.";

    if(this.emailids == null || this.emailids.trim() == "")
      this.errorMsg = "Please enter atleast one email id.";
    return this.errorMsg == null;
  }

  inventorySelectedRooms;
  inveotorySelectedRatePlans;
  ngAfterViewInit(): void {
    if(this.alertRule.Type == AlertType.HotelRateCalendar){
      let threshold;
      if(this.alertRule.InventoryThreshold && this.alertRule.InventoryThreshold.length > 0) threshold = this.alertRule.InventoryThreshold;
      else if(this.alertRule.RateThreshold && this.alertRule.RateThreshold.length>0) threshold = this.alertRule.RateThreshold;
      if(threshold){
        threshold.forEach(t => {
          this.addThreshold();
          let range = this.thresholdType == 0? t.InventoryThreshold: t.RateThreshold;
          if(this.componentReference.length > 0){
            this.componentReference[this.componentReference.length-1]._view.nodes.forEach(node => {
              if(node && node.renderElement && node.renderElement.nodeName == "P-DROPDOWN"){
                if(node.renderElement.attributes["name"].value == "roomId") {
                  if(t.RoomTypeId != null){
                    let room = this.roomTypes.find(r=> r.value == t.RoomTypeId);
                    node.componentView.component.selectedOption = room;
                    node.componentView.component.value = room;
                  }
                } else if(node.renderElement.attributes["name"].value == "ratePlanId") { 
                  if(t.RatePlanId != null){
                    let ratePlan = this.ratePlans.find(r=> r.value == t.RatePlanId);
                    node.componentView.component.selectedOption = ratePlan;
                    node.componentView.component.value = ratePlan;
                  }
                }
              } else if(node && node.renderElement && node.renderElement.nodeName == "INPUT"){
                if(node.renderElement.attributes["name"].value == "minval") {
                  node.renderElement.value = range.Start;
                } else if(node.renderElement.attributes["name"].value == "maxval") { 
                  node.renderElement.value = range.End;
                }
              }
            });
          }
        });
        if(this.componentReference.length > 0)
          window.setTimeout(this.bindRoomsRatePlans,500,this);
      } else {
        this.addThreshold();
      }
    } 
    this.setHeight();
  }

  bindRoomsRatePlans(compRef){
    if(compRef.rooms.length > 0){
      for(let i = 0; i < compRef.rooms.length; i++){
        console.log("Room:",i,compRef.rooms.toArray()[i].value);
        compRef.rooms.toArray()[i].updateSelectedOption(compRef.rooms.toArray()[i].value);
      }
    }
    if(compRef.rates.length > 0){
      for(let i = 0; i < compRef.rates.length; i++){
        console.log("RatePlans:",i,compRef.rates.toArray()[i].value);
        compRef.rates.toArray()[i].updateSelectedOption(compRef.rates.toArray()[i].value);
      }
    }
  }

  //#region Add/Remove Threshold Handling
  @ViewChild('clone') rateTemplate;
  @ViewChild('container', {read:ViewContainerRef}) tableContainer;
  componentReference = new Array();
  addThreshold(){
    let compRef = this.tableContainer.createEmbeddedView(this.rateTemplate);
    this.componentReference.push(compRef);
    return false; // This is to stop navigation.
  }

  removeThreshold(event){
    let el = (event.target || event.srcElement);
    let clickedComp = el.parentElement.parentElement.parentElement;
    if(clickedComp && this.componentReference.length > 0){
      for(let i=0;i<this.componentReference.length; i++){
        if(this.componentReference[i].rootNodes.indexOf(clickedComp) >= 0){
          let compRef = this.componentReference[i];
          this.componentReference.splice(i,1);
          compRef.destroy();
        }
      }
    }
    return false;
  }
  //#endregion Add/Remove Threshold Handling

}
