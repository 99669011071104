export const environment = {
  production: true,
  name:"production",
  vendor: "MAXIMOJO",
  watermarkCSS: null,
  service_uri: "https://api.platform.maximojo.com",
  airbnb_auth_url: "https://secure.maximojo.com/mantrasdirect/authorize?domainId={domainId}&channelId={channelId}",
  version: require('../../package.json').version,
  theme: "blue",
  logo: "Maximojo",
  privacyLink: "http://www.maximojo.com/privacy/",
  termsOfUseLink: "http://www.maximojo.com/terms-of-use/"
};