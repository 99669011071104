import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { BookingService } from "../ui-services/booking.service";
import { DateRange, BookingCriteria, DomainProfileCriteria, HotelSearchCriteria, HotelInfo, hotel } from 'mantras-api';
import { StorageService } from '../ui-services/storage.service';
import { Utilities } from '../ui-services/utilities';
import { BookingsComponent } from './bookings.component';
import { MessageService, Dropdown } from 'primeng/primeng';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../ui-services/authentication.service';

@Component({
  selector: 'booking-criteria',
  templateUrl: './bookingcriteria.component.html',
  styleUrls: ['./bookingcriteria.component.css'],
  providers: [BookingService],
})

export class BookingCriteriaComponent implements OnInit {
  hotelInfo: any;
  domainsList: any;
  isCurentDomainSystem = false;
  selectedDomainHeader: any;
  domains = [];
  bookings: BookingsComponent;
  hotels = [];
  channels = [];
  bookingCriteria = new BookingCriteria();
  status = [];
  rateformat = [];
  // selectedCreatedDateFrom: Date;
  // selectedCreatedDateTo: Date;
  creationDateRange;
  checkInDateRange;
  stayDateRange;
  selectedCheckinFrom: Date;
  selectedCheckinTo: Date;
  selectedStayFrom: Date;
  selectedStayTo: Date;
  bookingIdStr: string;
  isBookingIdSet = false;
  filteredChannels = [];
  criteraChannels = [];
  subscription: Subscription;
  maxBookingDate: Date;
  @Output('onsearch') onChange: EventEmitter<any>;
  @ViewChild('domainList') domainList: Dropdown

  constructor(private storageService: StorageService, private messageService: MessageService, private authService: AuthenticationService, ) {
    let statusMap = { 0: "UnSpecified", 1: "Provisional", 2: "Confirmed", 3: "Cancelled", 4: "Modified", 5: "WaitingList", 6: "NoShow" };
    this.onChange = new EventEmitter();

    let domains = this.storageService.get(StorageService.currentUser).DomainContext.Domains;
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    this.selectedDomainHeader = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    if (this.selectedDomainHeader != "System") {
      domains.forEach(domain => {
        if (domain.DomainHotels) {
          domain.DomainHotels.forEach(domainHotel => {
            this.hotels.push({ label: domainHotel.Name, value: domainHotel.Id });
          });
        }
      });
    }
    this.getDomainList();

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        this.selectedDomainHeader = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
        if (this.selectedDomainHeader == "System") {
          this.isCurentDomainSystem = true;
          this.hotels = [];
          this.bookingCriteria.HotelIds = [];
          let startDate = new Date();
          this.creationDateRange[0] = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
          this.creationDateRange[1] = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 0);
        } else {
          this.isCurentDomainSystem = false;
          this.hotels = [];
          domains = this.storageService.get(StorageService.currentUser).DomainContext.Domains;
          domains.forEach(domain => {
            if (domain.DomainHotels) {
              domain.DomainHotels.forEach(domainHotel => {
                this.hotels.push({ label: domainHotel.Name, value: domainHotel.Id });
              });
            }
          });
          console.log(this.hotels);
          this.bookingCriteria.HotelIds = [];
          let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
          if (hotelContext != null && hotelContext.HotelId != null)
            this.bookingCriteria.HotelIds.push(hotelContext.HotelId);
          console.log(this.bookingCriteria.HotelIds);
        }
      });

    Utilities.channelTypes.forEach(ch => {
      this.channels.push({ label: ch.Name, value: ch.Id });
    });
    // this.status.push({ label: "All", value: null });
    this.status.push({ label: "UnSpecified", value: 0 });
    this.status.push({ label: "Provisional", value: 1 });
    this.status.push({ label: "Confirmed", value: 2 });
    this.status.push({ label: "Cancelled", value: 3 });
    this.status.push({ label: "Modified", value: 4 });
    this.status.push({ label: "WaitingList", value: 5 });
    this.status.push({ label: "NoShow", value: 6 });

    this.rateformat.push({ label: "Hotel", value: 0 })
    this.rateformat.push({ label: "Channel", value: 1 });
    let criteria = new BookingCriteria();
    criteria.BookingDates = new DateRange();
    criteria.BookingDates.Start = new Date();
    criteria.BookingDates.End = new Date();
    criteria.StayDates = new DateRange();
    criteria.CheckInDates = new DateRange();
    criteria.HotelIds = [];
    criteria.ChannelCodes = [];
    this.criteraChannels = [];
    if (hotelContext != null && hotelContext.HotelId != null)
      criteria.HotelIds.push(hotelContext.HotelId);
    this.bindCriteria(criteria);
  }

  async getDomainList() {
    this.domains = [];
    let domainCriteria = new DomainProfileCriteria();
    this.domainsList = await this.authService.findDomains(domainCriteria).then(domainList => {
      domainList.forEach(domain => {
        if (domain.Id != "System")
          this.domains.push({ label: domain.Name, value: domain.Id });
      });
    });
  }

  async getHotelListBySelectedDomain(dropdown: Dropdown) {
    let selectedDomain = dropdown.value;
    if (selectedDomain.length < 1) {
      this.hotels = [];
      return;
    }
    this.bookingCriteria.HotelIds = [];
    this.hotels = [];
    let hotelSearchCriteria = new HotelSearchCriteria();
    hotelSearchCriteria.DomainIds = selectedDomain;
    this.hotelInfo = await this.authService.findHotelInfo(hotelSearchCriteria);

    this.hotelInfo.forEach(hotel => {
      this.hotels.push({ label: hotel.Name, value: hotel.Id });
      //this.bookingCriteria.HotelIds.push({ label: hotel.Name, value: hotel.Id });
    });
  }


  ngOnInit() {
    this.maxBookingDate = new Date();
    this.selectedDomainHeader = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    if (this.selectedDomainHeader == "System") {
      this.isCurentDomainSystem = true;
      let startDate = new Date();
      this.creationDateRange[0] = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
      this.creationDateRange[1] = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59, 0);
    }
  }
  OnChangeBookingID() {
    if (this.bookingIdStr)
      this.isBookingIdSet = true;
    else this.isBookingIdSet = false;
  }
  bindCriteria(criteria) {
    if (criteria.BookingDates) {
      this.creationDateRange = [];
      this.creationDateRange[0] = criteria.BookingDates.Start;
      this.creationDateRange[1] = criteria.BookingDates.End;
      this.bookingCriteria.HotelIds = criteria.HotelIds;
      this.bookingCriteria.DomainIds = criteria.DomainIds;
      this.bookingIdStr = "";
    }
    this.criteraChannels = [];
  }
  public buildCriteria() {
    let returnVal = false;
    this.selectedDomainHeader = this.storageService.get(StorageService.currentUser).DomainContext.CurrentDomainId;
    if (this.selectedDomainHeader == "System") {
      if (!this.bookingCriteria.DomainIds) {
        this.bookingCriteria.DomainIds = [];
      }
    }
    if (!this.bookingCriteria.HotelIds) {
      let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
      this.bookingCriteria.HotelIds = [];
      if (hotelContext != null && hotelContext.HotelId != null)
        this.bookingCriteria.HotelIds.push(hotelContext.HotelId);
    }
    if (this.creationDateRange != null && this.creationDateRange[0] != null) {
      this.bookingCriteria.BookingDates = new DateRange();
      let end = new Date(this.creationDateRange[1] == null ? this.creationDateRange[0] : this.creationDateRange[1]);
      end.setDate(end.getDate() + 1);
      this.bookingCriteria.BookingDates.End = (end);
      this.bookingCriteria.BookingDates.Start = (new Date(this.creationDateRange[0]));
      returnVal = true;
    }
    else {
      this.bookingCriteria.BookingDates = null;
    }
    if (this.stayDateRange != null && this.stayDateRange[0] != null) {
      this.bookingCriteria.StayDates = new DateRange();
      let endDate = new Date(this.stayDateRange[1] == null ? this.stayDateRange[0] : this.stayDateRange[1]);
      endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 5, 30, 0);
      let startDate = new Date(this.stayDateRange[0].getFullYear(), this.stayDateRange[0].getMonth(), this.stayDateRange[0].getDate(), 5, 30, 0);
      this.bookingCriteria.StayDates.Start = startDate
      this.bookingCriteria.StayDates.End = endDate;
      returnVal = true;
    }
    else {
      this.bookingCriteria.StayDates = null;
    }
    if (this.checkInDateRange != null && this.checkInDateRange[0] != null) {
      this.bookingCriteria.CheckInDates = new DateRange();
      let endDate = new Date(this.checkInDateRange[1] == null ? this.checkInDateRange[0] : this.checkInDateRange[1]);
      endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 5, 30, 0);
      //endDate.setDate(endDate.getDate() + 1);
      let startDate = new Date(this.checkInDateRange[0].getFullYear(), this.checkInDateRange[0].getMonth(), this.checkInDateRange[0].getDate(), 5, 30, 0);
      //startDate.setDate(startDate.getDate() + 1);
      this.bookingCriteria.CheckInDates.Start = startDate
      this.bookingCriteria.CheckInDates.End = endDate;
      returnVal = true;
    }
    else {
      this.bookingCriteria.CheckInDates = null;
    }
    if (this.bookingIdStr && this.bookingIdStr != "") {
      this.bookingCriteria = new BookingCriteria()
      this.bookingCriteria.BookingIds = [];
      this.isBookingIdSet = true;
      this.bookingCriteria.BookingIds = this.bookingIdStr.split(',', 500);
      returnVal = true;
    }
    else {
      this.bookingCriteria.BookingIds = null;
    }
    if (this.criteraChannels && this.criteraChannels.length > 0) {
      this.bookingCriteria.ChannelCodes = [];
      for (let index = 0; index < this.criteraChannels.length; index++) {
        const element = this.criteraChannels[index];
        // this.bookingCriteria.ChannelCodes.push(element.value);
        this.bookingCriteria.ChannelCodes.push(element);
      }
      returnVal = true;
    }
    if (!(this.bookingCriteria.TravellerName == undefined) && this.bookingCriteria.TravellerName.length > 0) returnVal = true;
    return returnVal;
  }
  filterChannels(event) {
    let query = event.query;
    this.filteredChannels = [];
    for (let index = 0; index < this.channels.length; index++) {
      const channel = this.channels[index];
      if (channel.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        this.filteredChannels.push(channel);
      }
    }

  }
  toUtcDate(date: Date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }
  onSearch() {
    if (!this.buildCriteria()) {
      this.messageService.add({ key: 'error', severity: 'error', life: 2000, summary: 'Error', detail: 'Please enter some criteria' });
    } else {
      this.onChange.emit(this.bookingCriteria);
    }
  }
  onClear() {
    this.bookingCriteria.HotelIds = [];
    let hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    if (hotelContext != null && hotelContext.HotelId != null)
      this.bookingCriteria.HotelIds.push(hotelContext.HotelId);
    this.bookingCriteria.DomainIds = [];
    this.criteraChannels = null;
    this.bookingCriteria.BookingStatus = null;
    this.creationDateRange = null;
    this.checkInDateRange = null;
    this.stayDateRange = null;
    this.bookingIdStr = null;
    this.bookingCriteria.TravellerName = null;
    if (this.bookingIdStr)
      this.isBookingIdSet = true;
    else this.isBookingIdSet = false;
  }
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }
}