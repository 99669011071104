import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { EventManagementService } from '../../../ui-services/eventmanagement.service';
import { Utilities } from '../../../ui-services/utilities';
import { Router } from '@angular/router';

@Component({
  selector: 'mm-analyticsfilter',
  templateUrl: './analyticsfilter.component.html',
  styleUrls: ['./analyticsfilter.component.css']
})
export class AnalyticsfilterComponent implements OnInit {

  selectedChannelTypes = [];
  selectedCompetitors = [];
  channelTypes: any[];
  competitors: any[];
  filterOnField = 0;
  selectedTrendField;
  trendAnalyticsFields: any[];
  showFilter = false;
  isInTrendAnalytics = false;
  isInCompetitorAnalytics = false;
  isGraphView = false;
  selectedChannelCode;
  @Output() onFilterChange: EventEmitter<any> = new EventEmitter();
  constructor(private eventManager:EventManagementService, private router : Router) {
    this.trendAnalyticsFields = [{label:"Total Revenue",value:0},
    {label:"Avg. Daily Net Rate",value:1},
    {label:"Avg. Adv. Purchase",value:2},
    {label:"Avg. LOS",value:3},
    {label:"Total Room Nights",value:4},
    {label:"Avg. Room Nights",value:5}];
  }

  ngOnInit() {
  }

  handleOnChange(event, selectedValue){
    let data = {selectedTrendField:selectedValue,
      selectedChannelTypes:this.selectedChannelTypes,
      selectedCompetitors:this.selectedCompetitors,
      selectedChannelCode:selectedValue,
    }
    this.onFilterChange.emit(data);
  }

  setupAnalyticsFilter(setupData){
    if(setupData){
      this.isInTrendAnalytics = setupData["isInTrendAnalytics"];
      this.isInCompetitorAnalytics = setupData["isInCompetitorAnalytics"];
      this.selectedChannelTypes = setupData["selectedChannelTypes"];
      let listChannelTypes = setupData["channelTypes"];
      let competitorList =  setupData["competitors"];
      this.selectedCompetitors =  setupData["selectedCompetitors"];
      this.selectedChannelCode = setupData["selectedChannelCode"];
      this.isGraphView = setupData["isGraphView"];
      let hotelInfo = setupData["hotelInfo"];
      this.channelTypes = [];
      this.competitors =[];
      if(listChannelTypes && listChannelTypes.length > 0){
        //Setup Channel Types
        listChannelTypes.forEach(channel => {
          if(this.isInTrendAnalytics)// In others Agoda Code is AGO
          this.channelTypes.push({value:channel,label:Utilities.findId(Utilities.channelTypes,channel,channel)});
          if(this.isInCompetitorAnalytics)// In competitors Agoda Code is AGD
          this.channelTypes.push({value:channel,label:Utilities.findId(Utilities.getChannelTypes(['AGO'],'competitor'),channel,channel)});
        });
        this.channelTypes.sort(Utilities.sort_label_asc);
      }
      if(competitorList && competitorList.length > 0){
        competitorList.forEach(comp => {
          this.competitors.push(comp);
        });
      }
      this.showFilter = setupData["showFilter"];      
      this.selectedTrendField = setupData["selectedTrendField"];
    }
  }
}
