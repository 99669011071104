import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { StorageService } from '../ui-services/storage.service';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';
import { Router } from '@angular/router';
@Component({
  selector: 'app-chartpoc',
  templateUrl: './chartpoc.component.html',
  styleUrls: ['./chartpoc.component.css']
})
export class ChartpocComponent {
  subscription:Subscription;
  constructor(private storageService:StorageService,
    private router:Router){ 
    this.subscription = this.storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });
  }

  onClose(){
    this.router.navigate(["/devLab"]);
  }

}
