import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-card-expanded',
  templateUrl: './notification-card-expanded.component.html',
  styleUrls: ['./notification-card-expanded.component.css']
})
export class NotificationCardExpandedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
