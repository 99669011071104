import { Component, OnInit, HostListener, ViewChild, ViewContainerRef, ComponentFactoryResolver,Output,EventEmitter } from '@angular/core';
import { StorageService } from '../../ui-services/storage.service';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/primeng';
import { GridOptions } from 'ag-grid';
import { Subscription } from 'rxjs';
import { HotelManagementService } from '../../ui-services/hotelmanagement.service';
import { TaxpolicydetailsComponent } from './taxpolicydetails.component';
import { TaxPolicy } from 'mantras-api';
import { DatePipe } from '@angular/common';
import * as jQuery from 'jquery';
import { TaxPolicyUtils } from './taxpoliciesUtils';
import { UserService } from '../../ui-services/user.service';
import { Utilities } from '../../ui-services/utilities';
import { CustomReuseStrategy } from '../../ui-services/reuse-routes';

@Component({
  selector: 'taxpolicies',
  templateUrl: './taxpolicies.component.html',
  styleUrls: ['./taxpolicies.component.css']
})
export class TaxpoliciesComponent implements OnInit {
  breadCrumbItems: MenuItem[];
  taxpoliciesMenuItems: MenuItem[];
  columnDefs: any[];
  rowData: any[];
  gridOptions: GridOptions;
  rowSelection: any;
  rowGroupPanelShow: any;
  taxPolicies;
  taxUtils;
  defaultTaxPolicies = [];

  showDetailPage = false;
  icons;
  isReadOnly = false;
  subscription: Subscription;
  hotelContext;
  domainContext;
  disableDefaultTaxPolicy = true;
  showCreatePolicyConfirmation = false;
  @Output() onChangeMenuItems: EventEmitter<any>  = new EventEmitter();
  constructor(private resolver: ComponentFactoryResolver, private storageService: StorageService, private router: Router,
    private hotelMgmtService: HotelManagementService, private userService: UserService) {
    this.isReadOnly = userService.isReadOnly();
    this.breadCrumbItems = [{ label: "Settings", icon: 'fa fa-wrench', routerLink: ["/settings"] },
    { label: "Tax Policies", routerLink: ["/settings/taxpolicies"] }];
    this.hotelContext = this.storageService.get(StorageService.currentUser).HotelContext;
    this.columnDefs = [
      { headerName: "Name", field: "Name", tooltipField: "Name", width: 120, menuTabs: [], cellClass: 'gridcol', sort: "asc" },
      { headerName: "Created On", field: "CreatedOn", tooltipField: "CreatedOn", valueFormatter: formatLastSync, width: 100, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Created By", field: "CreatedBy", tooltipField: "CreatedBy", width: 50, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Modified On", field: "LastModifiedOn", tooltipField: "LastModifiedOn", valueFormatter: formatLastSync, width: 100, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Modified By", field: "LastModifiedBy", tooltipField: "LastModifiedBy", width: 100, menuTabs: [], cellClass: 'gridcol' },
      { headerName: "Is Active", field: "IsActive", width: 50, menuTabs: [], cellClass: 'gridcol', cellRenderer: params => { return `<input type='checkbox' readonly onclick='return false;' ${params.value ? 'checked' : ''} value=${params.value ? 1 : 0}/>`; } },
      { headerName: "", width: 30, menuTabs: [] }
    ];

    this.gridOptions = <GridOptions>{
      rowData: this.rowData,
      columnDefs: this.columnDefs,
      enableSorting: true,
      animateRows: true,
      enableColResize: true,
      groupUseEntireRow: true,
      groupDefaultExpanded: -1,
      rowSelection: this.rowSelection,
      suppressRowClickSelection: false,
      showToolPanel: false,
      localeText: { noRowsToShow: "No tax policy found" }
    };
    this.icons = {
      groupContracted: '<i class="fa fa-caret-right" aria-hidden="true"  style="width: 12px;padding-right: 2px"/>',
      groupExpanded: '<i class="fa fa-caret-down" aria-hidden="true" style="width: 12px;padding-right: 2px"/>',
    }
    this.gridOptions.suppressLoadingOverlay = true;
    this.gridOptions.suppressContextMenu = true;

    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });
    this.domainContext = this.storageService.get(StorageService.currentUser).DomainContext;

    function formatLastSync(param) {
      // var date = new Date(param.value);
      // return date.toLocaleString();
      var date = new DatePipe('en-US');
      return date.transform(param.value, 'dd-MMM-y, hh:mm:ss aa');
    }
  }
  async findTaxPolicies(force = false) {

    let response = await this.hotelMgmtService.findTaxPolicies(this.domainContext.CurrentDomainId, this.hotelContext.HotelId, force);
   this.taxPolicies = response;
    if (response && response.length != 0) this.disableDefaultTaxPolicy = true;
    else this.disableDefaultTaxPolicy = false;
    this.gridOptions.api.setRowData(response);
    this.gridOptions.api.sizeColumnsToFit();
    this.onGridReady(this);
  }

  onGridReady($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
    let setSelected = false;
    this.gridOptions.api.forEachNode(node => {
      if (!node.group && !setSelected) { node.setSelected(true); this.onRowClick(node); setSelected = true; }
    });
    if (!setSelected) this.loadMenu();
    this.gridOptions.suppressDragLeaveHidesColumns = true;
  }
  onGridSizeChanged($event) {
    this.gridOptions.api.sizeColumnsToFit();
    this.rowGroupPanelShow = "always";
  }
  ngOnInit() {
    this.loadMenu();
    this.findTaxPolicies();
  }

  loadMenu() {
    this.taxpoliciesMenuItems = [
      { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
      { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
      { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', disabled: true, command: (event) => this.open(event) },
      { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: true, command: (event) => this.confirmDelete(event, true) },
      { title: 'Add Default Policy', label: 'Add Default Policy', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly || this.disableDefaultTaxPolicy, command: (event) => this.onCreateDefaultTaxPolicy() },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
    ];
    this.getMenuItemData();
  }

  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.gridHeight').css('height',  Utilities.getScrollHeight(true,true,false,false));
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  onRowClick(params) {
    if (params.data) {
      let disabledDelete = false;
      if (params.data.Id.toLowerCase() == this.storageService.get(StorageService.loginUserId)) disabledDelete = true;
      this.taxpoliciesMenuItems = [
        { title: 'Refresh', label: 'Refresh', icon: 'action-bar-menu-icon fa icon ion-md-refresh', command: (event) => this.onRefresh(event) },
        { title: 'New', label: 'New', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly, command: (event) => this.createNew() },
        { title: 'Open', label: 'Open', icon: 'action-bar-menu-icon fa fa-folder-open-o', command: (event) => this.open(event) },
        { title: 'Delete', label: 'Delete', icon: 'action-bar-menu-icon fa fa-trash-o', disabled: this.isReadOnly || disabledDelete, command: (event) => this.confirmDelete(event, true) },
        { title: 'Add Default Policy', label: 'Add Default Policy', icon: 'action-bar-menu-icon fa fa-folder-o', disabled: this.isReadOnly || this.disableDefaultTaxPolicy, command: (event) => this.onCreateDefaultTaxPolicy() },
        { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onClose() }
      ];
      this.getMenuItemData();
    }
  }

  onClickGridRow(params) {
    if (params.data)
      this.createNew(params.data);
  }

  onCreateDefaultTaxPolicy() {
    this.taxUtils = new TaxPolicyUtils(this.hotelMgmtService);
    this.defaultTaxPolicies = this.taxUtils.createCountrySpecificTaxPolicy("IN", this.storageService.get(StorageService.loginUserId), this.domainContext.CurrentDomainId, this.hotelContext.HotelId).__zone_symbol__value;
    this.showCreatePolicyConfirmation = true;
  }
  async createDefaultTaxPolicy() {
    await this.taxUtils.saveTaxPolicy(this.domainContext.CurrentDomainId, this.hotelContext.HotelId);
    this.onRefresh(null);
  }
  //Refresh Grid
  onRefresh(event) {
    this.findTaxPolicies(true);
  }
  //OnClose
  onClose() {
    this.router.navigate(["/settings"]);
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }

  //Create New RoomTypes
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  detailComponent;
  createNew(data?) {
    this.container.clear();
    let factory = this.resolver.resolveComponentFactory(TaxpolicydetailsComponent);
    this.detailComponent = <TaxpolicydetailsComponent>this.container.createComponent(factory).instance;
    if (data) this.detailComponent.taxPolicy = data;
    else this.detailComponent.taxPolicy = new TaxPolicy();
    this.detailComponent.dataEmitter.subscribe($event => {
      this.refreshDetailMenuItems($event)
    });
    this.detailComponent.loadData();
    this.detailComponent.onClose.subscribe(this.onDetailsClose);
    this.detailComponent.setHeight();
    this.showDetailPage = true;
  }

  open(event) {
    this.createNew(this.gridOptions.api.getSelectedRows()[0]);
  }

  @ViewChild('showConfirmationDialog') showConfirmationDialog;
  showDeleteConfirmation = false;
  deleteId;
  confirmDelete(params, fromGrid?) {
    if (!fromGrid) {
      if (params.node.data) {
        this.deleteId = params.node.data.Id;
        this.showDeleteConfirmation = true;
      }
    } else {
      let selectedRow = this.gridOptions.api.getSelectedRows();
      if (selectedRow && selectedRow.length > 0) {
        this.deleteId = selectedRow[0].Id;
        this.showDeleteConfirmation = true;
      }
    }
  }
  confirmDefaultLoad() {
    this.showCreatePolicyConfirmation = false;
    this.createDefaultTaxPolicy();
  }
  closeDialog1() {
    this.showCreatePolicyConfirmation = false;
    this.setHeight();
  }
  closeDialog(event) {
    this.deleteId = null;
    this.showDeleteConfirmation = false;
    this.setHeight();
  }

  async delete(event) {
    this.showDeleteConfirmation = false;
    let ids: string[] = [];
    if (this.deleteId != null) {
      ids.push(this.deleteId);
      let finalResponse = await this.hotelMgmtService.deleteTaxPolicies(this.domainContext.CurrentDomainId, this.hotelContext.HotelId, ids).then(
        localresp => { return localresp; });
    }
    this.onRefresh(event);
  }

  onDetailsClose = (event): void => {
    this.showDetailPage = false;
    this.getMenuItemData();
    if (event) this.onRefresh(event);
    else this.setHeight();
  }
  refreshDetailMenuItems(event){
    this.onChangeMenuItems.emit(event);
  }
  getMenuItemData(){
    if(this.showDetailPage){
      this.detailComponent.getMenuItemData();
    }else{
      let data = [];
      data.push({'source':'taxPolicies'});
      data.push(this.taxpoliciesMenuItems);
      this.onChangeMenuItems.emit(data);
    }
  }
}


