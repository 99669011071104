import { Component, OnInit, ViewChild, PipeTransform, Pipe } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { BookingsComponent } from '../bookings/bookings.component';
import { AnalyticsComponent } from '../analytics/analytics.component';
import { SettingsComponent } from '../settings/settings.component';
import { PromotionsComponent } from '../promotions/promotions.component';
import { RatecalendarComponent } from '../ratecalendar/ratecalendar.component';
import { AuthGuard } from '../ui-services/auth.guard';
import { TaskbarService } from '../ui-services/taskbar.service';
import { Dialog, Button } from 'primeng/primeng';
import { Utilities } from '../ui-services/utilities';
import { StorageService } from '../ui-services/storage.service';
import { GridOptions } from 'ag-grid';
import { DatePipe } from '@angular/common';
import { ProgressBarRendererComponent } from './progressbar.renderer.component';
import { Subscription } from 'rxjs';
import { TaskStatus } from 'mantras-api';
import { AfterViewInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { NotificationService } from '../ui-services/notification.service';
import { SWITCH_TEMPLATE_REF_FACTORY__POST_R3__ } from '@angular/core/src/linker/template_ref';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChild('taskDialog') taskDialog: Dialog;
  @ViewChild('detailButton') detailButton: Button;
  hideDiv = false;
  parentWindow = window;
  gridOptions;
  columnDef;
  postLoginMessages;
  displayMessage = false;
  subscription: Subscription;
  exceptions = [];
  showTasksDialog = false;
  selectErrorTab=false;
  constructor(public taskService: TaskbarService, private storageService: StorageService,private notificationServie:NotificationService) {
    this.columnDef = new Array();
    this.columnDef.push({ headerName: "Hotel Name/Alias", field: "HotelName", cellClass: 'gridcol', width: 120, tooltipField: "HotelName", menuTabs: [] });
    this.columnDef.push({ headerName: "Task Name", field: "TaskName", cellClass: 'gridcol', width: 150, tooltipField: "TaskName", menuTabs: [] });
    this.columnDef.push({ headerName: "Progress Bar", field: "Progress", cellClass: 'gridcol', width: 100, cellRendererFramework: ProgressBarRendererComponent, menuTabs: [] });
    this.columnDef.push({ headerName: "Status", field: "StatusText", cellClass: 'gridcol', width: 60, tooltipField: "StatusText", menuTabs: [] });

    this.gridOptions = <GridOptions>{
      enableSorting: true,
      animateRows: true,
      columnDefs: this.columnDef,
      groupDefaultExpanded: -1,
      localeText: { noRowsToShow: "Loading task(s), please wait..." },
      suppressContextMenu: true,
    };

    this.subscription = taskService.taskObserver$.subscribe(
      taskSrv => {
        this.bindData(taskSrv);
      });

  }

  ngOnInit() {
    if(!this.storageService.get(StorageService.postLoginMessageDisplayed)){
      this.notificationServie.getPostLoginMessages().subscribe(
        (data) => {
          console.log(data);
          let messages = this.validateMessages(data);
          this.storageService.set(StorageService.postLoginMessageDisplayed,true);
          if(messages && messages.length > 0) {
            this.postLoginMessages = messages;
            this.displayMessage = true;
          }
        },
        (err) => {
          console.log('error: ',err);
        }
      );
  
    }
  }

  ngAfterViewInit(): void {
    this.bindData(this.taskService);
  }

  btnIcon = "fa fa-angle-double-left";
  showHideDetails() {
    this.hideDiv = !this.hideDiv;
    if (this.hideDiv) {
      this.btnIcon = "fa fa-angle-double-right"
    } else {
      this.btnIcon = "fa fa-angle-double-left"
    }
  }

  closeTaskDialog() {
    this.taskService.onTaskDetailDialogClose();
  }

  onGridReady(params) {
    //this.gridOptions.api.sizeColumnsToFit();
  }
  onGridSizeChanged(params) {
    //this.gridOptions.api.sizeColumnsToFit();
  }

  bindData(taskSrv: TaskbarService) {
    let tasks = [];
    let datePipe = new DatePipe('en-US');
    let exp = [];
    if (taskSrv.tasks) {
      Array.from(taskSrv.tasks.values()).forEach(element => {
        if (element != null)
          tasks.push({ HotelName: this.getHotelName(element), TaskName: this.getTaskName(element), Progress: element.Progress, Status: element.Status, StatusText: this.getStatus(element.Status) });
        if (element && element != null && element.Exceptions != null && element.Exceptions != "") {
          exp.push("Task " + tasks[tasks.length-1].TaskName + " reported error: " + element.Exceptions);
        }
      });
      this.exceptions = exp;
      if(this.exceptions.length>0) this.selectErrorTab = true;
      else this.selectErrorTab = false;
      this.gridOptions.api.setRowData(tasks);
    }
  }

  showTaskDetailDialog() {
    this.taskService.showTaskDialog = true;
    this.bindData(this.taskService);
  }

  getHotelName(task) {
    let hotelName = Utilities.findHotelNameById(task.HotelId, task.DomainId, this.storageService, task.HotelId);
    if (hotelName) return hotelName;
    else return Utilities.findId(Utilities.channelTypes, task.ChannelCode);
  }

  getTaskName(task) {
    if (task) {
      if (task.Name == "GetChannelHotelInfo") {
        return task.Name + " - " + Utilities.findId(Utilities.channelTypes, task.ChannelCode);
      }
      let taskInfo = this.taskService.taskInfos.get(task.Id);
      let datePipe = new DatePipe('en-US');
      let taskName = task.Name + " - " + Utilities.findId(Utilities.channelTypes, task.ChannelCode);
      taskName += taskInfo?" (" + datePipe.transform(taskInfo.Start, 'dd-MMM-yy') + " to " + datePipe.transform(taskInfo.End, 'dd-MMM-yy') + ")":"";
      return taskName;
    }
  }

  getStatus(status) {
    if (status == TaskStatus.Error) { return "Error"; }
    else if (status == TaskStatus.Complete || status == TaskStatus.CompleteWithWarnings) { return "Completed"; }
    else if (status == TaskStatus.Canceled) { return "Cancelled"; }
    else { return "In Progress"; }
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }
  validateMessages(data){
    let tempArray = [];
    data.forEach(element => {
      let notificationEndTimestamp;
      if(element.EndDate) notificationEndTimestamp = element.EndDate;
      else notificationEndTimestamp = element.TimeStamp;
      if(Utilities.timeDifference(new Date(),new Date(notificationEndTimestamp))>=0){
        tempArray.push(element);
      }
    });
    return tempArray;
  }
  confirm(){
    this.displayMessage = false;
  }
}
