import { Component, OnInit, EventEmitter, Output, Input, HostListener } from '@angular/core';
import { AuthenticationService } from '../ui-services/authentication.service';
import { ExceptionItem, ExceptionType } from 'mantras-api';
import { MenuItem } from 'primeng/api';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { string } from '@amcharts/amcharts4/core';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'exceptionsdetails',
  templateUrl: './exceptionsdetails.component.html',
  styleUrls: ['./exceptionsdetails.component.css']
})
export class ExceptionsDetailsComponent implements OnInit {

  @Input('selectedException') selectedException: ExceptionItem = new ExceptionItem();
  @Output('onClose') onClose = new EventEmitter<boolean>();
  exceptionsDetailsMenuItems: MenuItem[];

  @Input('filteredResponse') filteredResponse: any[];

  constructor(private authService: AuthenticationService, ) {
    this.selectedException.Tags = new Map<string, string>();
  }

  ngOnInit() {
    if (!this.selectedException) {
      this.selectedException = new ExceptionItem();
    }
    this.loadMenu();
  }

  loadMenu() {
    this.exceptionsDetailsMenuItems = [
      { title: 'Previous', label: 'Previous', icon: 'action-bar-menu-icon fa fa-arrow-left', disabled: false, command: (event) => this.onPrevious() },
      { title: 'Next', label: 'Next', icon: 'action-bar-menu-icon fa fa-arrow-right', disabled: false, command: (event) => this.onNext() },
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel(false) },
    ];
  }

  onCancel(event?) {
    this.onClose.emit(event);
  }

  onNext() {
    let index = this.filteredResponse.findIndex(x => x.Id == this.selectedException.Id);
    if (index + 1 == this.filteredResponse.length) {
      this.selectedException = new ExceptionItem();
      this.selectedException = this.filteredResponse[0];
    }
    else {
      this.selectedException = new ExceptionItem();
      this.selectedException = this.filteredResponse[index + 1];
    }
  }

  onPrevious() {
    let index = this.filteredResponse.findIndex(x => x.Id == this.selectedException.Id);
    if (index == 0) {
      this.selectedException = new ExceptionItem();
      this.selectedException = this.filteredResponse[this.filteredResponse.length - 1];
    }
    else {
      this.selectedException = new ExceptionItem();
      this.selectedException = this.filteredResponse[index - 1];

    }
  }




  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true));
  }

  ngAfterViewInit(): void {
    this.setHeight();
  }

  Type(params) {
    return ExceptionType[params];
  }

  TimeStamp(params) {
    // var date = new Date(params);
    let datePipe = new DatePipe('en-US');
    return datePipe.transform(params, 'dd-MMM-yyyy, hh:mm:ss aa');
    //return date.toLocaleString([], { hour12: true ,year:"numeric", month : "short",day:"2-digit",hour:"2-digit",minute:"2-digit",second:"2-digit" });
  }
  formatMethod(params) {
    if (params) {
      if (params.includes("/")) {
        return params.split("/").pop();
      } else
        return params;
    }
    else {
      return "--";
    }
  }
  GetKeys(): string[] {
    if (this.selectedException.Tags != undefined || this.selectedException.Tags != null)
      return Object.keys(this.selectedException.Tags);
    else
      return [];
  }
}

