import { Component, OnInit, HostListener } from '@angular/core';
import { CommonService } from '../ui-services/common.service';
import { MessageService } from 'primeng/api';
import { HotelManagementService } from '../ui-services/hotelmanagement.service';
import { AuthenticationService } from '../ui-services/authentication.service';
import { HotelSearchCriteria, BackupCriteria, DateRange } from 'mantras-api';
import { Utilities } from '../ui-services/utilities';
import * as jQuery from 'jquery';
import { Router } from '@angular/router';
import { StorageService } from '../ui-services/storage.service';
import { CustomReuseStrategy } from '../ui-services/reuse-routes';
import { Subscription } from 'rxjs';

@Component({
  selector: 'backuprestore',
  templateUrl: './backuprestore.component.html',
  styleUrls: ['./backuprestore.component.css']
})
export class BackuprestoreComponent implements OnInit {

  domains;
  selectedDomain;
  hotels;
  selectedHotel;
  backupRange;
  backupOptions;
  backupMenuItems;
  selectedOptions:string[];
  minDate;
  servicePath;
  sessionId;
  subscription: Subscription;
  constructor(private commonService:CommonService, private authenticationService:AuthenticationService, private messageService:MessageService, 
    private hotelManagementService:HotelManagementService, private router: Router, private storageService: StorageService) { 
    this.backupOptions = [{label:"Bookings",value:"bookings"},{label:"Rate Calendar",value:"ratecalendar"},{label:"Promotions",value:"promotions"},{label:"Competitor Calendar",value:"compcalendar"},{label:"Sync Logs",value:"synclogs"}];
    this.servicePath = this.hotelManagementService.mantras.proxy["_baseUrl"] + this.hotelManagementService.mantras.proxy["_mantrasSvc"];
    this.sessionId = this.storageService.get(StorageService.currentUser).Id;
    this.subscription = storageService.userContextListener$.subscribe(
      userContext => {
        CustomReuseStrategy.contextSwitched = true;
        this.onClose();
      });

  }

  async ngOnInit() {
    //Load Domains;
    //this.domains = [{label: "Select", value:null}];
    this.domains = [];
    //let domainCriteria = new DomainProfileCriteria();
    let domainList = await this.authenticationService.findDomains(null,true);
    domainList.forEach(domain => {
        if (domain.Id != "System")
          this.domains.push({ label: domain.Name, value: domain.Id });
      });
    this.minDate = new Date();
    this.backupMenuItems = [
      { title: 'Close', label: 'Close', icon: 'action-bar-menu-icon fa icon ion-md-close-circle-outline', command: (event) => this.onCancel() }];
    this.setHeight();
  }

  onCancel(){
    this.router.navigate(["/settings"]);
  }
  backup(e){
    let backupCriteria = new BackupCriteria();
    backupCriteria.Bookings = (this.selectedOptions.indexOf("bookings")>=0);
    backupCriteria.CompetitorCalendar = (this.selectedOptions.indexOf("compcalendar")>=0);
    backupCriteria.CompetitorCalendarSyncLog = (this.selectedOptions.indexOf("synclogs")>=0);
    backupCriteria.Promotions = (this.selectedOptions.indexOf("bookings")>=0);
    backupCriteria.PromotionSyncLog = (this.selectedOptions.indexOf("bookings")>=0);
    backupCriteria.RateCalendar = (this.selectedOptions.indexOf("ratecalendar")>=0);
    backupCriteria.RateCalendarSyncLog = (this.selectedOptions.indexOf("synclogs")>=0);
    backupCriteria.Exceptions = false;
    backupCriteria.Tasks = false;
    backupCriteria.Usage = false;
    backupCriteria.DomainData = true;
    backupCriteria.HotelData = true;
    backupCriteria.DomainId = this.selectedDomain;
    backupCriteria.HotelId = this.selectedHotel;
    backupCriteria.DateRange = new DateRange();
    backupCriteria.DateRange.Start = Utilities.convertToUTC(this.backupRange[0]);
    if(this.backupRange.length > 1)
      backupCriteria.DateRange.End = Utilities.convertToUTC(this.backupRange[1]);
    else
      backupCriteria.DateRange.End = Utilities.convertToUTC(this.backupRange[0]);
    this.messageService.addAll([{severity:'success', summary:'Success', detail:"Backup started."}])
    this.commonService.backup(backupCriteria).then(res =>
      {
        Utilities.saveToFileSystem(res, "Backup.mbk");
        this.messageService.addAll([{severity:'success', summary:'Success', detail:"Backup completed successfully."}]);
      });
  }

  loadHotels(e){
    //fetch hotels list from domain
    let hotelCriteria = new HotelSearchCriteria();
    this.hotels = [];
    this.selectedHotel = null;
    if(this.selectedDomain != null) {
      hotelCriteria.DomainIds = [this.selectedDomain];
      this.authenticationService.findHotelInfo(hotelCriteria).then(response => {
          response.forEach(hotel => {
              this.hotels.push({ label: hotel.Name, value: hotel.Id });
          });
      });
    }
  }

  onClose() {
    this.router.navigate(["/settings"]);
  }

  uploadedFiles: any[] = [];
  disableUpload = false;
  afterUploadFile(event){
    console.log("UploadFile: ", event);
    if(event.xhr.status == 200){
      this.messageService.addAll([{severity:'success', summary:'Success', detail:"Restore completed successfully."}]); 
    }
    this.disableUpload = false;
  }

  onBeforeSendFile(fileEvent){
    this.disableUpload = true;
    this.messageService.addAll([{severity:'success', summary:'Restore started.'}]); 
  }
  
  onUploadError(failEvent){
    this.messageService.addAll([{severity:'error', summary:'Error', detail:"Restore failed."}]); 
    this.disableUpload = false;
  }
  @HostListener('window:resize') setHeight() {
    let windowHeight = window.innerHeight;
    jQuery('.sectionHeight').css('height', Utilities.getScrollHeight(true,true,false,false));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
